import React from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { AddEventIcon } from "@/assets/widgets/add-event-icon.tsx"
import { useGetAllApiaryEventsQuery } from "@/features/events/apiary-events/api/apiary-events-api.ts"
import { EventTable } from "@/features/events/common/components/events-table/events-table.tsx"
import { APIARY_QUERY_PARAMS } from "@/features/events/common/types/apiary-querry-params.ts"
import { APIARY_EVENTS_FILTER_ENUM } from "@/features/events/hive-events/hooks/use-filter-hive-events.ts"
import EmptyContentCreateItemButton from "@/features/home/components/empty-content-create-item-button/empty-content-create-item-button.tsx"
import WidgetCard from "@/features/home/components/widget-card/widget-card.tsx"
import {
  APIARY_EVENTS_ROUTE,
  NEW_APIARY_EVENT_ROUTE,
} from "@/utils/constants/routes.ts"

interface Props {
  apiaryId: string
}

export const ApiaryEventsWidget: React.FC<Props> = ({ apiaryId }) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const addEventNavigationString =
    NEW_APIARY_EVENT_ROUTE +
    (apiaryId ? `?${APIARY_QUERY_PARAMS.APIARY_ID}=${apiaryId}` : "")
  const { data: events } = useGetAllApiaryEventsQuery({ apiary_id: apiaryId })
  const workingEvents = events?.slice(0, 10)
  const addItemGoTo = () => {
    navigate(addEventNavigationString)
  }
  const allEventsForApiaryNavigationString =
    APIARY_EVENTS_ROUTE + `?${APIARY_EVENTS_FILTER_ENUM.APIARY_ID}=${apiaryId}`

  return (
    <WidgetCard
      icon={<AddEventIcon />}
      title={"events"}
      navigateTo={allEventsForApiaryNavigationString}
      {...(events && events.length > 0 && { addItemGoTo })}
    >
      {workingEvents && workingEvents.length > 0 && (
        <>
          {events && events?.length > workingEvents.length && (
            <p>
              {t("lastEvents", {
                count: workingEvents.length,
                total: events?.length,
              })}{" "}
            </p>
          )}
          <EventTable events={workingEvents} />
        </>
      )}
      {!workingEvents ||
        (workingEvents.length === 0 && (
          <EmptyContentCreateItemButton navigateTo={addEventNavigationString} />
        ))}
    </WidgetCard>
  )
}
