export const ES_TRANSLATIONS: Record<string, any> = {
  translation: {
    hello: "¡Hola!",
    featureNotYetImplemented: "Esta funcionalidad aún no está implementada.",
    yes: "Sí",
    no: "No",
    yesterday: "Ayer",
    today: "Hoy",
    tomorrow: "Mañana",
    thisWeek: "Esta semana",
    thisMonth: "Este mes",
    lastMonth: "Mes pasado",
    thisYear: "Este año",
    lastYear: "Año pasado",
    pastYears: "Años anteriores",
    homeTab: "Inicio",
    tasksTab: "Tareas",
    addBottomMenu: "Añadir",
    farmTab: "Mi granja",
    notifications: "Notificaciones",
    notification: "Notificación",
    menu: "Menú",
    accountTab: "Mi cuenta",
    oneSecond: "Un momento...",
    weather: "Clima",
    notes: "Notas",
    addNote: "Añadir una nota",
    newNote: "Nueva nota",
    myNote: "Mi nota",
    placeholderTitle: "Título",
    placeholderText: "Tu texto",
    deleteNote: "Eliminar nota",
    searchNote: "Buscar nota",
    noNotesFound: "No se encontraron notas",
    shareNote: "Compartir nota",
    formatNote: "Formato",
    notesBackConfirmation:
      "Has hecho cambios. ¿Estás seguro de que quieres salir sin guardar?",
    sureWantToLeave: "¿Estás seguro de que quieres salir sin guardar?",
    youMadeChanges: "Has hecho cambios.",
    unsavedChanges: "¡Cambios no guardados!",
    continueWhereYouLeftOff: "Continuar donde lo dejaste",
    youHaveUnsavedChanges:
      "Tienes cambios sin guardar de la sesión anterior. ¿Quieres continuar con estos cambios o descartarlos?",
    youWillLoseChanges:
      "Perderás los cambios no guardados. ¿Quieres continuar?",
    backConfirmation: "¿Estás seguro de que quieres volver a Notas?",
    noteWillBeDeleted: "La nota actual será eliminada.",
    continue: "Continuar",
    leave: "Salir",
    discard: "Descartar",
    delete: "Eliminar",
    stay: "Quedarse",
    save: "Guardar",
    goToNotes: "Ir a notas",
    seeAllNotes: "Ver todas las notas",
    deleteNoteConfirmation: "¿Estás seguro de que quieres eliminar la Nota?",
    deletingNote: "Eliminando nota...",
    noteDeleted: "¡La nota ha sido eliminada!",
    errorDeletingNote: "¡Ha ocurrido un error al eliminar la nota!",
    updatingNote: "Actualizando nota...",
    noteUpdated: "¡La nota ha sido actualizada!",
    errorUpdatingNote: "¡Ha ocurrido un error al actualizar la nota!",
    postingNote: "Creando nota...",
    notePosted: "¡La nota ha sido creada!",
    notePostError: "¡Ha ocurrido un error al crear la nota!",
    noNoteAssociated: "Ninguna nota asociada",
    oneNoteAssociated: "Una nota asociada",
    notesAssociated: "notas asociadas",
    noteContentIsMandatory: "¡El contenido de la nota es obligatorio!",
    seeOtherNotesAssociated: "Ver otras notas asociadas",
    hideOtherNotesAssociated: "Ocultar otras notas asociadas",
    draft: "borrador",
    unsavedChangesIndicator: "cambios no guardados",
    notEnoughData: "No hay suficientes datos para mostrar el gráfico",
    notesWillBeSetAsDraft:
      "Las notas se establecerán como borrador. Puedes volver más tarde para guardar los cambios.",
    noteWasLoadedWithPreviousData:
      "La nota se cargó con datos anteriores. ¿Quieres mantener los datos y continuar?",

    //Tasks

    taskWasLoadedWithPreviousData:
      "La tarea se cargó con datos anteriores. ¿Quieres mantener los datos y continuar?",
    tasks: "Tareas",
    dueTasks: "Tareas Pendientes",
    currentTasks: "Tareas Actuales",
    futureTasks: "Tareas Futuras",
    searchTask: "Buscar Tarea",
    noTasksFound: "No se encontraron tareas",
    newTask: "Nueva Tarea",
    myTask: "Mi Tarea",
    title: "Título",
    description: "Descripción",
    deleteTask: "Eliminar Tarea",
    disinfestation: "Desparasitación",
    treatments: "Tratamientos",
    status: "Estado",
    category: "Categoría",
    chooseCategory: "Elegir Categoría",
    ALL: "Todas las Tareas",
    DONE: "Tareas Completadas",
    TODO: "Tareas Actuales",
    backConfirmationTasks: "¿Estás seguro de que quieres volver a Tareas?",
    backConfirmationSettings:
      "¿Estás seguro de que quieres volver a Configuración?",
    taskWillBeDeleted: "La tarea actual será eliminada.",
    modificationsWillBeLost: "Los cambios se perderán.",
    onTime: "A tiempo",
    before30: "30 min antes",
    beforeHour: "1 hora antes",
    beforeDay: "1 día antes",
    beforeWeek: "1 semana antes",
    daily: "Diario",
    weekly: "Semanal",
    monthly: "Mensual",
    yearly: "Anual",
    Date: "Fecha",
    Hour: "Hora",
    Reminder: "Recordatorio",
    Recurrence: "Recurrencia",
    AssociateNote: "Asociar Nota",
    AssociateAnimal: "Asociar Animal",
    AssociateTask: "Asociar Tarea",
    dashboardCurrentTasks: "Actuales",
    dashboardFutureTasks: "Futuras",
    dashboardFinalizedTasks: "Completadas",
    dashboardOverdueTasks: "Pendientes",
    seeAllTasks: "Ver todas las tareas",
    deleteTaskConfirmation: "¿Estás seguro de que quieres eliminar la Tarea?",
    allCategories: "Todas las categorías",
    noNewTasks: "No tienes nuevas tareas",
    updatingTask: "Actualizando tarea...",
    taskUpdated: "¡La tarea ha sido actualizada!",
    errorUpdatingTask: "¡Ha ocurrido un error al actualizar la tarea!",
    creatingTask: "Creando tarea...",
    taskCreated: "¡La tarea ha sido creada!",
    errorCreatingTask: "¡Ha ocurrido un error al crear la tarea!",
    deletingTask: "Eliminando tarea...",
    taskDeleted: "¡La tarea ha sido eliminada!",
    errorDeletingTask: "¡Ha ocurrido un error al eliminar la tarea!",
    oneTaskAssociated: "Una tarea asociada",
    tasksAssociated: "tareas asociadas",
    taskNotFound: "¡La tarea no fue encontrada!",
    titleRequired: "El título es obligatorio para crear una tarea",
    title5Characters: "El título debe tener al menos 5 caracteres",
    title100Characters: "El título debe tener máximo 100 caracteres",
    addCategory: "Añadir Categoría",
    editCategory: "Editar Categoría",
    selectTaskCategory: "Seleccionar Categoría de Tarea",
    taskWillBeSetAsDraft:
      "La tarea se establecerá como borrador. Puedes volver más tarde para guardar los cambios.",
    taskSetAsDraft: "La tarea ha sido establecida como borrador.",
    addTask: "Añadir Tarea",
    animalWasLoadedWithPreviousData:
      "El animal se cargó con datos anteriores. ¿Quieres mantener los datos y continuar?",
    animalWillBeSetAsDraft:
      "El animal se establecerá como borrador. Puedes volver más tarde para guardar los cambios.",
    ain: "Nº de Crotal",
    myFarm: "Mi granja",
    searchAnimal: "Buscar animal: Nombre, nº crotal, estado",
    outOfExploitation: "Fuera de explotación",
    removeAnimalFromExploitation: "Marcar salida de la granja",
    searchBreed: "Buscar Raza",
    newAnimal: "Añadir Animal",
    addCow: "Añadir Bovino",
    myAnimal: "Mi Animal",
    addGoat: "Añadir Cabra",
    addSheep: "Añadir Oveja",
    addPork: "Añadir Cerdo",
    addBird: "Añadir Ave",
    addMammal: "Añadir Mamífero",
    addBee: "Añadir Colmena",
    statusCows: "Estado Bovinos",
    statusGoats: "Estado Cabras",
    statusSheep: "Estado Ovejas",
    statusPork: "Estado Cerdos",
    statusBirds: "Estado Aves",
    statusBee: "Estado Colmenas",
    statusAnimal: "Estado Animales",
    backConfirmationAnimals: "¿Estás seguro de que quieres volver a Mi granja?",
    animalWillBeDeleted: "El animal actual será eliminado.",
    name: "Nombre",
    surname: "Apellido",
    taskTitleError: "El título es obligatorio para crear una tarea",
    crotal: "Crotal",
    Male: "Macho",
    Female: "Hembra",
    allGenders: "Ambos Sexos",
    sex: "Sexo",
    registryEntryWay: "Modalidad de entrada en el Registro",
    Birth: "Parto",
    Beef: "Carne",
    Meat: "Carne",
    Purchase: "Adquisición",
    Production: "Producción",
    Dairy: "Leche",
    Sale: "Venta",
    Sacrifice: "Sacrificio",
    Transfer: "Transferencia",
    Loss: "Pérdida",
    Death: "Muerte",
    details: "Detalles",
    categories: "Categorías",
    deleteAnimal: "Eliminar animal",
    birthDate: "Fecha de nacimiento",
    entryDate: "Fecha de entrada en explotación",
    ALL_ANIMALS: "Todas las categorías",
    ALL_GENDERS: "Todos los sexos",
    Cows: "Bovinos",
    Cow: "Vaca",
    singleSheep: "Ovino",
    Sheep: "Ovinos",
    FEMALE_SHEEP: "Oveja",
    YOUNG_GOAT: "Cabrito",
    MALE_GOAT: "Macho cabrío",
    FEMALE_GOAT: "Cabra",
    RAM: "Carnero",
    singlePork: "Porcino",
    Pork: "Porcinos",
    MALE_PIG: "Cerdo",
    FEMALE_PIG: "Cerda",
    PIGLET: "Lechón",
    singleGoat: "Caprino",
    Goat: "Caprinos",
    breed: "Raza",
    Lamb: "Cordero",
    Chicken: "Gallina",
    Cock: "Gallo",
    Bee: "Abeja",
    exploitEntryDate: "Fecha de entrada en explotación",
    calves_count: "Partos",
    gestation_count: "Confirmaciones de gestación",
    medical_treatments_count: "Tratamientos",
    insemination_count: "Monta / Inseminaciones",
    lactation_count: "Lactancias",
    nameAnimalError: "* El nombre del animal es obligatorio",
    categoryAnimalError: "* La categoría del animal es obligatoria",
    breedAnimalError: "* La raza del animal es obligatoria",
    crotalAnimalError: "* El nº de crotal del animal es obligatorio",
    sexAnimalError: "* El sexo del animal es obligatorio",
    birthDateAnimalError: "* La fecha de nacimiento del animal es obligatoria",
    entryDateAnimalError: "* La fecha de entrada en explotación es obligatoria",
    registryEntryWayAnimalError:
      "* La modalidad de entrada en el registro es obligatoria",
    productionAnimalError: "* El tipo de producción es obligatorio",
    detailsAnimalError: "* Los detalles del animal son obligatorios",
    exploitEntryDateAnimalError:
      "* La fecha de entrada en explotación es obligatoria",
    fieldError: "* Este campo es obligatorio",
    age: "Edad",
    years: "años",
    year: "año",
    year_uppercase: "Año",
    months: "meses",
    month: "mes",
    and: "y",
    lessThanAMonth: "menos de un mes",
    allAges: "Todas las edades",
    age02cow: "Ternero",
    age23cow: "Novilla",
    age3plusCow: "Madura",
    age510: "5 - 10 años",
    species: "Especies",
    allSpecies: "Todas las Especies",
    Calf: "Ternero",
    Veal: "Novilla",
    mature: "Madura",
    goats: "Cabras",
    ALL_SPECIES: "Todas las Especies",
    heads: "Cabezas",
    addAnimal: "Añadir animal",
    animalSummary: "Resumen Datos Animal",
    animalMother: "Madre del Animal",
    animalFather: "Padre del Animal",
    seeFarm: "Ver mi granja",
    deleteAnimalConfirmation:
      "¿Estás seguro de que quieres eliminar el animal?",
    deletingAnimal: "Eliminando animal...",
    animalDeleted: "¡El animal ha sido eliminado!",
    errorDeletingAnimal: "¡Ha ocurrido un error al eliminar el animal!",
    updatingAnimal: "Actualizando animal...",
    animalUpdated: "¡El animal ha sido actualizado!",
    animalUpdateError: "¡Ha ocurrido un error al actualizar el animal!",
    creatingAnimal: "Creando animal...",
    animalCreated: "¡El animal ha sido creado!",
    animalCreateError: "¡Ha ocurrido un error al crear el animal!",
    allAnimals: "Todos los animales",
    animals: "Animales",
    noAnimalAssociated: "Ningún animal asociado",
    oneAnimalAssociated: "Un animal asociado",
    oneAnimalSelected: "Un animal seleccionado",
    animalsAssociated: "animales asociados",
    seeOtherAnimalsAssociated: "Ver otros animales asociados",
    hideOtherAnimalsAssociated: "Ocultar otros animales asociados",
    animalsSelected: "animales seleccionados",
    ainRequired: "El Nº de Crotal es obligatorio para crear un animal",
    genderRequired: "El sexo es obligatorio para crear un animal",
    birthDateRequired:
      "La fecha de nacimiento es obligatoria para crear un animal",
    entryDateRequired:
      "La fecha de entrada en explotación es obligatoria para crear un animal",
    entryWayIsRequired: "La modalidad de entrada en el registro es obligatoria",
    intendedPurposeIsRequired: "La producción es obligatoria",
    speciesRequired: "La especie es obligatoria para crear un animal",
    breedIsRequired: "La raza es obligatoria para crear un animal",
    entryDateMustBeLaterThanBirthDate:
      "La fecha de entrada debe ser posterior a la fecha de nacimiento",
    birthDateCannotBeInTheFuture:
      "La fecha de nacimiento no puede ser en el futuro",
    entryDateCannotBeInTheFuture:
      "La fecha de entrada no puede ser en el futuro",
    nameFarmRequired: "¡El nombre de la granja es obligatorio!",
    nameMaxLength: "¡El nombre debe tener máximo 100 caracteres!",
    selectAnimalMother: "Seleccionar madre del animal",
    selectAnimalFather: "Seleccionar padre del animal",
    ALL_STATUSES: "Todos los Estados",
    HEAT: "Celo",
    GESTATING: "Gestantes",
    LACTATING: "Lactación",
    ILL: "Para investigar",
    HEALTHY: "Sanas",
    DRY: "Reposo",
    gestationPeriodCalendar: "Calendario de Gestación",
    jurnal: "Diario",
    vocalAssistant: "Asistente Vocal",
    contactTheSpecialist: "Contactar al Especialista",
    contactSpecialist: "Contactar al Especialista",
    voiceAssistant: "Asistente Vocal",
    goodMorning: "Buenos días",
    goodAfternoon: "Buenas tardes",
    goodEvening: "Buenas noches",
    Monday: "Lunes",
    Tuesday: "Martes",
    Wednesday: "Miércoles",
    Thursday: "Jueves",
    Friday: "Viernes",
    Saturday: "Sábado",
    Sunday: "Domingo",
    january: "Enero",
    february: "Febrero",
    march: "Marzo",
    april: "Abril",
    may: "Mayo",
    june: "Junio",
    july: "Julio",
    august: "Agosto",
    september: "Septiembre",
    october: "Octubre",
    november: "Noviembre",
    december: "Diciembre",
    dashboard: "Dashboard",
    allNotifications: "Todas las notificaciones",
    ALL_NOTIFICATIONS: "Todas las notificaciones",
    ALERTS: "Alertas",
    REMINDERS: "Recordatorios",
    INFO: "Información",
    MANDATORY: "Obligatorias",
    allRead: "Todas",
    read: "Leídas",
    unread: "No leídas",
    all: "Todas",
    seeAllNotifications: "Ver todas las notificaciones",
    noNewNotifications: "No tienes notificaciones nuevas",
    notificationWasRead: "¡La notificación ha sido leída!",
    notificationsWereRead: "¡Las notificaciones han sido leídas!",
    updateNotificationError:
      "¡Ha ocurrido un error al actualizar la notificación!",
    updateNotificationsError:
      "¡Ha ocurrido un error al actualizar las notificaciones!",
    notificationError: "¡Ha ocurrido un error en la notificación!",
    updatingNotification: "Actualizando notificación...",
    updatingNotifications: "Actualizando notificaciones...",
    followLink: "Seguir enlace",
    youSureYouWantToReadThemAll:
      "¿Estás seguro de que quieres marcar todas las notificaciones como leídas?",
    markAllAsRead: "Marcar todas como leídas",
    cancel: "Cancelar",
    settings: "Configuración",
    farmProfile: "Perfil de Granja",
    hiveProfile: "Perfil de Colmenar",
    companyInfo: "Datos de la Empresa",
    farmName: "Nombre de la Granja",
    farmNameRequired: "¡El nombre de la Granja es obligatorio!",
    address: "Dirección",
    location: "Ubicación",
    selectLocation: "Ubicación (ej., Edificio A, Zona 4, Corral 2)",
    setFarmImage: "Establecer una nueva fotografía de la granja",
    takePhoto: "Tomar una fotografía",
    choosePhoto: "Elegir una fotografía",
    changePhoto: "Cambiar fotografía",
    deletePhoto: "Eliminar fotografía",
    farmInfo: "Datos de la Empresa",
    addFarmName: "Nombre de la Empresa",
    addCui: "CIF",
    accountSettings: "Configuración de Cuenta",
    myProfile: "Mi Perfil",
    resetPassword: "Restablecer Contraseña",
    notificationPreferences: "Preferencias de Notificaciones",
    email: "Dirección de Correo Electrónico",
    pushNotifications: "Notificaciones Push",
    editPhone: "Editar Teléfono",
    setUserImage: "Establecer una nueva fotografía de perfil",
    inputCurrentPassword: "Introducir Contraseña Actual",
    inputNewPassword: "Introducir Nueva Contraseña",
    confirmNewPassword: "Confirmar Nueva Contraseña",
    information: "Información",
    tutorials: "Tutoriales",
    faq: "Preguntas frecuentes",
    confidentiality: "Política de Confidencialidad",
    termsAndServices: "Términos y Servicios",
    appVersion: "Versión de la Aplicación",
    whatIsFarmApp: "¿Qué es FARM APP?",
    lastUpdate: "Última Actualización",
    yourOpinion: "Tu opinión",
    recommend: "Recomendar",
    likeUs: "Danos Me gusta en Redes Sociales",
    contactUs: "Contáctanos",
    followUs: "Síguenos",
    recommendUsing: "Recomienda la aplicación usando:",
    subject: "Asunto",
    message: "Mensaje",
    signOut: "Cerrar sesión",
    signOutConfirmation: "¿Estás seguro de que quieres salir de la aplicación?",
    deletePhotoConfirmation:
      "¿Estás seguro de que quieres eliminar la fotografía?",
    linkWillBeHere: "El enlace a nuestra aplicación estará aquí",
    farmProfileUpdated: "¡El perfil de la granja ha sido actualizado!",
    userProfileUpdated: "¡El perfil ha sido actualizado!",
    errorOccurred: "¡Ha ocurrido un error!",
    processing: "Procesando...",
    contact: "Contacto",
    lostPassword: "Olvidé mi contraseña",
    password: "Contraseña",
    lostYourPassword: "¿Olvidaste tu contraseña?",
    dontWorryHappensToAllOfUs: "¡No te preocupes, nos pasa a todos!",
    enterEmailResetPassword:
      "Introduce tu dirección de correo electrónico a continuación y te enviaremos un enlace para restablecer tu contraseña.",
    wrongLoginCredentials:
      "¡Dirección de correo electrónico o contraseña incorrecta!",
    login: "Iniciar sesión",
    logingIn: "Iniciando sesión...",
    back: "Atrás",
    welcome: "¡Bienvenido!",
    nameUserRequired: "¡El nombre es obligatorio!",
    lastNameRequired: "¡El apellido es obligatorio!",
    lastNameMaxLength: "El apellido debe tener máximo 100 caracteres",
    emailRequired: "¡La dirección de correo electrónico es obligatoria!",
    phoneNumberRequired: "¡El número de teléfono es obligatorio!",
    send: "Enviar",
    urlCopiedSuccess: "¡El URL ha sido copiado con éxito!",

    // Authentication
    createAccount: "Crear Cuenta",
    creatingAccount: "Creando Cuenta...",
    signupSuccessful: "¡Registro Exitoso!",
    verificationEmailSent: "Se ha enviado un correo de verificación",
    pleaseCheckYourEmail: "Por favor revise su correo para verificar su cuenta",
    passwordResetSuccessful: "¡Restablecimiento de Contraseña Exitoso!",
    yourPasswordHasBeenReset: "Su contraseña ha sido restablecida con éxito",
    passwordChangeSuccessful: "¡Cambio de Contraseña Exitoso!",
    yourPasswordHasBeenChanged: "Su contraseña ha sido cambiada con éxito",
    changePassword: "Cambiar Contraseña",
    changing: "Cambiando...",
    failedToChangePassword: "Error al cambiar la contraseña",
    incorrectCurrentPassword: "La contraseña actual es incorrecta",
    sessionExpired: "Su sesión ha expirado",
    pleaseRefreshAndTryAgain:
      "Por favor, actualice la página e inténtelo de nuevo",
    proceedToLogin: "Proceder al Inicio de Sesión",
    backToLogin: "Volver al Inicio de Sesión",
    passwordsDoNotMatch: "Las contraseñas no coinciden",
    passwordTooShort: "La contraseña debe tener al menos 8 caracteres",
    failedToSignUp: "Error al registrarse",
    failedToResetPassword: "Error al restablecer la contraseña",
    dontHaveAccount: "¿No tiene una cuenta?",
    alreadyHaveAccount: "¿Ya tiene una cuenta?",
    firstName: "Nombre",
    lastName: "Apellido",
    confirmPassword: "Confirmar Contraseña",
    newPassword: "Nueva Contraseña",
    resetYourPassword: "Restablezca Su Contraseña",
    enterNewPassword: "Ingrese su nueva contraseña a continuación",
    passwordResetEmailSent: "Correo de Restablecimiento de Contraseña Enviado",
    checkYourEmailForResetLink:
      "Revise su correo para el enlace de restablecimiento de contraseña",
    fillInDetailsBelow:
      "Complete los detalles a continuación para crear su cuenta",
    invalidResetLink: "Enlace de restablecimiento inválido",
    sending: "Enviando...",
    resetting: "Restableciendo...",

    // Email verification
    verifyingEmail: "Verificando Correo",
    pleaseWait: "Por favor espere...",
    emailVerificationSuccessful: "¡Verificación de Correo Exitosa!",
    youCanNowLogin:
      "Su correo ha sido verificado. Ahora puede iniciar sesión en su cuenta.",
    emailVerificationFailed: "Verificación de Correo Fallida",
    invalidVerificationLink: "Enlace de verificación inválido",
    invalidOrExpiredLink: "El enlace de verificación es inválido o ha expirado",

    // Gestation Period Calendar
    stepByStepGestationEvolution: "Evolución del embarazo etapa por etapa",
    computeProbableBirthDate: "Calcular la fecha probable del parto",
    testNow: "Probar ahora",
    confirm: "Confirmar",
    underDevelopment: "Esta funcionalidad está aún en desarrollo",
    notAvailableInPackage: "Esta funcionalidad no está disponible",
    gestationalCalculator: "Calculadora Gestacional",
    createNewCalendar: "Crear un nuevo calendario",
    generateCalendar: "Generar un calendario",
    saveCalendar: "Guardar el calendario",
    calendarSavedSuccessfully: "¡El calendario ha sido guardado con éxito!",
    animalMustBeFemale:
      "Para crear un calendario gestacional el animal debe ser hembra",
    animalMustBeCow:
      "El calendario gestacional solo está disponible para vacas",
    loadingCalendarPreview: "Cargando vista previa del calendario...",
    previewLoadedSuccessfully: "¡La vista previa se ha cargado con éxito!",
    errorLoadingPreview: "¡Ha ocurrido un error al cargar la vista previa!",
    errorFetchingAnimal: "¡Ha ocurrido un error al obtener el animal!",
    searchCalendar: "Buscar calendario",
    selectAnimal: "Seleccionar Animal",
    gestationalCalendar: "Calendario Gestacional",
    deleteCalendar: "Eliminar Calendario",
    deletingCalendar: "Eliminando calendario...",
    calendarDeletedSuccessfully: "¡El calendario ha sido eliminado con éxito!",
    calendarDeleteError: "¡Ha ocurrido un error al eliminar el calendario!",
    calendarWillBeDeleted: "El calendario actual será eliminado.",
    deleteCalendarConfirmation:
      "¿Estás seguro de que quieres eliminar el calendario?",
    deleteAssocAnimalEvents:
      "¿Quieres eliminar también los eventos asociados al calendario?",
    toCalving: "hasta el parto",
    untilCalvingIntervalEnd: "hasta el final del intervalo",
    days: "días",
    toNextTask: "hasta la siguiente tarea",
    hours: "horas",
    minutes: "minutos",
    nextActivity: "Siguiente Actividad",
    gestationalCalendarText:
      "Calcula la fecha del parto y genera tareas útiles para el período de gestación.",
    calendarPreviewLoadedSuccessfully:
      "¡La vista previa del calendario se ha cargado con éxito!",

    // Finance Widget
    financialJournal: "Diario Financiero",
    searchTransaction: "Buscar Transacción",
    addTransaction: "Añadir Transacción",
    transaction: "Transacción",
    transactions: "Transacciones",
    EXPENSES: "Gastos",
    Outflow: "Gastos",
    INCOMES: "Ingresos",
    Inflow: "Ingresos",
    selectTransactionCategory: "Seleccionar categoría de transacción",
    EU_FOUNDS: "Fondos Europeos",
    PROFIT: "Beneficio",
    SELLING_ANIMALS: "Venta de Animales",
    DONATIONS: "Donaciones",
    SELLING_GOODS: "Venta de Productos",
    EQUIPMENT_ACQUISITION: "Adquisición de Equipos",
    MAINTENANCE: "Mantenimiento",
    ANIMALS_FOOD: "Alimentación Animal",
    TREATMENTS: "Tratamientos",
    TRANSPORTATION: "Transporte",
    VETERINARY: "Servicios Veterinarios",
    SALARIES: "Salarios",
    SOFTWARE_ACQUISITION: "Adquisición de Software",
    ADD_CATEGORY: "Añadir Categoría",
    categoryName: "Nombre de la Categoría",
    loadingCategories: "Cargando categorías...",
    categorySaved: "¡La categoría ha sido guardada!",
    errorCreatingCategory: "¡Ha ocurrido un error al crear la categoría!",
    savingCategory: "Guardando categoría...",
    updatingCategory: "Actualizando categoría...",
    categoryUpdated: "¡La categoría ha sido actualizada!",
    errorUpdatingCategory: "¡Ha ocurrido un error al actualizar la categoría!",
    deleteCategory: "Eliminar Categoría",
    deletingCategory: "Eliminando categoría...",
    categoryDeleted: "¡La categoría ha sido eliminada!",
    errorDeletingCategory: "¡Ha ocurrido un error al eliminar la categoría!",
    transactionHistory: "Historial de Transacciones",
    savingTransaction: "Guardando transacción...",
    transactionSaved: "¡La transacción ha sido guardada!",
    transactionSaveError: "¡Ha ocurrido un error al guardar la transacción!",
    deleteTransactionConfirmation:
      "¿Estás seguro de que quieres eliminar la transacción?",
    deleteTransactionCategoryConfirmation:
      "¿Estás seguro de que quieres eliminar la categoría?",
    transactionCategoryWillBeDeleted:
      "La categoría de transacción será eliminada.",
    transactionWillBeDeleted: "La transacción actual será eliminada.",
    deleteTransaction: "Eliminar Transacción",
    transactionDeleted: "¡La transacción ha sido eliminada!",
    deletingTransaction: "Eliminando transacción...",
    startDate: "Fecha de inicio",
    viewStats: "Ver estadísticas",
    endDate: "Fecha de fin",
    last_week: "Semana pasada",
    last_month: "Mes pasado",
    current_month: "Mes actual",
    last_year: "Año pasado",
    custom: "Período personalizado",
    selectTimePeriod: "Seleccionar período de tiempo",
    all_time: "Todo el período",
    current_week: "Semana actual",
    noTransactionsFound: "Ups, no se encontraron transacciones...",
    noAnimalsFound: "Ups, no se encontraron animales...",
    lastTransactions: "Últimas Transacciones",
    amountRequired: "El monto es obligatorio para crear una transacción",
    dateRequired: "La fecha es obligatoria para crear una transacción",
    categoryRequired: "La categoría es obligatoria para crear una transacción",
    balance: "Balance",
    financialStats: "Estadísticas Financieras",
    weAreSorry: "Lo sentimos...",
    anErrorOccurred: "Ha ocurrido un error.",
    reloadApp: "Recargar la aplicación",
    events: "Eventos",
    addEvent: "Añadir Evento",
    addAnimalExit: "Salida del Animal",
    confirmCalving: "Confirmar Parto",
    calvingConfirmed: "El parto ha sido confirmado",
    selectEventType: "Seleccionar Tipo de Evento",
    selectMedication: "Seleccionar Medicamento",
    Treatment: "Tratamiento",
    Measurement: "Medición",
    Movement: "Movimiento",
    Insemination: "Inseminación",
    NaturalMating: "Apareamiento Natural",
    PregnancyCheckPositive: "Verificación de Gestación Positiva",
    PregnancyCheckNegative: "Verificación de Gestación Negativa",
    HealthCheckPositive: "Control de Salud Positivo",
    HealthCheckNegative: "Control de Salud Negativo",
    HoofHealthCheck: "Control Salud Pezuña",
    HeatDetection: "Detección de Celo",
    Abortion: "Aborto",
    DryOff: "Cese de Lactancia",
    Weaning: "Destete",
    LactationStart: "Inicio de Lactancia",
    LactationEnd: "Fin de Lactancia",
    DietChange: "Cambio de Dieta",
    Shearing: "Esquila",
    HoofTrimming: "Recorte de Pezuñas",
    EarTagging: "Marcaje de Oreja",
    AnimalTransferExitEventType: "Eventos de Salida",
    AnimalSacrifice: "Sacrificio",
    AnimalSale: "Venta de Animal",
    AnimalDeath: "Muerte del Animal",
    AnimalLoss: "Pérdida del Animal",
    AnimalTransferExit: "Transferencia de Salida",
    Castration: "Castración",
    Dehorning: "Descorne",
    VeterinaryVisit: "Visita Veterinaria",
    BehaviorObservation: "Observación de Comportamiento",
    EntryIntoQuarantine: "Entrada en Cuarentena",
    ExitFromQuarantine: "Salida de Cuarentena",
    Isolation: "Aislamiento",
    Vaccination: "Vacunación",
    IllnessTreatment: "Tratamiento de Enfermedad",
    Deworming: "Desparasitación",
    InjuryTreatment: "Tratamiento de Lesión",
    Surgery: "Cirugía",
    PostSurgeryFollowUp: "Seguimiento Post-Operatorio",
    SupplementAdministration: "Administración de Suplemento",
    WeightCheck: "Verificación de Peso",
    BodyConditionScoring: "Evaluación de Condición Corporal",
    Other: "Otros",
    addEventType: "Añadir un nuevo tipo de evento",
    addMedicationType: "Añadir un nuevo tratamiento",
    eventName: "Nombre del Evento",
    medicationName: "Nombre del Tratamiento",
    deleteEventType: "Eliminar tipo de evento",
    fromLocation: "Desde la Ubicación",
    fromPosition: "Desde la Posición",
    toPosition: "A la Posición",
    toLocation: "Hacia la Ubicación",
    departureDate: "Fecha de Salida",
    arrivalDate: "Fecha de Llegada",
    HeightCheck: "Verificación de Altura",
    weight: "Peso",
    otherObservations: "Otras Observaciones",
    generalEventDetails: "Detalles Generales del Evento",
    specificEventDetails: "Detalles Específicos del Evento",
    transactionAmount: "Monto de la Transacción",
    value: "Valor",
    savingEvent: "Guardando evento...",
    eventSaved: "¡El evento ha sido guardado!",
    viewEvent: "Ver evento",
    errorSavingEvent: "¡Ha ocurrido un error al guardar el evento!",
    dosage: "Dosis",
    deleteAnimalEvent: "Eliminar Evento",
    animalEvent: "Evento del Animal",
    deletingAnimalEvent: "Eliminando evento...",
    animalEventDeleted: "¡El evento ha sido eliminado!",
    animalEventDeleteError: "¡Ha ocurrido un error al eliminar el evento!",
    deleteAnimalEventConfirmation:
      "¿Estás seguro de que quieres eliminar el Evento?",
    eventWillBeDeleted: "El evento actual será eliminado.",
    updatingAnimalEvent: "Actualizando evento...",
    animalEventUpdated: "¡El evento ha sido actualizado!",
    animalEventUpdateError: "¡Ha ocurrido un error al actualizar el evento!",
    typeRequired: "El tipo es obligatorio para crear un evento",
    dateRequiredEvent: "La fecha es obligatoria para crear un evento",
    animalRequired: "El animal es obligatorio para crear un evento",
    fromLocationRequired:
      "La ubicación de salida es obligatoria para crear este evento",
    toLocationRequired:
      "La ubicación de llegada es obligatoria para crear este evento",
    valueRequired: "El valor es obligatorio para crear este evento",
    dosageRequired: "La dosis es obligatoria para crear este evento",
    medicationRequired: "El medicamento es obligatorio para crear este evento",
    quantityRequired: "La cantidad es obligatoria para crear este evento",
    positionRequired: "La posición es obligatoria para crear este evento",
    toApiaryRequired:
      "El colmenar de destino es obligatorio para crear este evento",
    TreatmentEventType: "Eventos de Tratamiento",
    MeasurementEventType: "Eventos de Medición",
    MovementEventType: "Eventos de Movimiento",
    ReproductiveEventType: "Eventos de Reproducción",
    OtherEventType: "Otros Eventos",
    searchAnimalEvent: "Buscar Evento del Animal",

    animalEvents: "Eventos de Animales",
    savingEventType: "Guardando tipo de evento...",
    savingMedicationType: "Guardando tipo de tratamiento...",
    eventTypeSaved: "¡El tipo de evento ha sido guardado!",
    medicationTypeSaved: "¡El tipo de tratamiento ha sido guardado!",
    errorCreatingEventType: "Ha ocurrido un error al crear el tipo de evento",
    errorCreatingMedicationType:
      "Ha ocurrido un error al crear el tipo de tratamiento",
    updatingEventType: "Actualizando tipo de evento",
    updatingMedicationType: "Actualizando tipo de tratamiento",
    eventTypeUpdated: "¡Actualización con éxito!",
    medicationTypeUpdated: "¡Actualización con éxito!",
    errorUpdatingEventType:
      "¡Ha ocurrido un error al actualizar el tipo de evento!",
    errorUpdatingMedicationType:
      "¡Ha ocurrido un error al actualizar el tipo de tratamiento!",
    deleteEventTypeConfirmation:
      "¿Estás seguro de que quieres eliminar el tipo de evento?",
    deleteMedicationTypeConfirmation:
      "¿Estás seguro de que quieres eliminar el tipo de tratamiento?",
    eventTypeWillBeDeleted: "El tipo de evento será eliminado.",
    medicationTypeWillBeDeleted: "El tipo de tratamiento será eliminado.",
    deletingEventType: "Eliminando tipo de evento...",
    deletingMedicationType: "Eliminando tipo de tratamiento...",
    eventTypeDeleted: "¡El tipo de evento ha sido eliminado!",
    medicationTypeDeleted: "¡El tipo de tratamiento ha sido eliminado!",
    errorDeletingEventType:
      "Ha ocurrido un error al eliminar el tipo de evento",
    errorDeletingMedicationType:
      "Ha ocurrido un error al eliminar el tipo de tratamiento",
    chooseEventTypeParent: "Seleccionar categoría del evento",
    intelligentAssistant: "Asistente Inteligente",
    yourMessage: "Tu mensaje",
    generalHealthStatus:
      "¿Cuál es el estado general de salud del ganado bovino?",
    immediateMedicalAttention:
      "¿Qué vacas requieren atención médica inmediata?",
    optimizeFeeding:
      "¿Cómo puedo optimizar la alimentación para aumentar la producción de leche?",
    closeToCalving: "¿Qué vacas están próximas al parto?",
    generalHealthStatusPigs:
      "¿Cuál es el estado general de salud del ganado porcino?",
    growthRate:
      "¿Cuál es la tasa de crecimiento de los cerdos en el último mes?",
    pregnantSows: "¿Qué cerdas gestantes requieren atención especial?",
    weaningStatus: "¿Qué lechones están por ser destetados?",
    diseasePreventionStatus:
      "¿Cuál es el estado de las medidas de prevención de enfermedades en el ganado?",
    generalHealthStatusGoat:
      "¿Cuál es el estado general de salud del ganado caprino?",
    milkProduction:
      "¿Cómo se presenta la producción de leche en el último período?",
    pregnantGoats: "¿Qué cabras gestantes requieren monitoreo?",
    kidsHealth: "¿Cuál es el estado de salud de los cabritos?",
    generalHealthStatusSheep:
      "¿Cuál es el estado general de salud del ganado ovino?",
    lambingStatus: "¿Qué ovejas van a parir en el próximo período?",
    lambGrowth: "¿Cuál es la tasa de crecimiento de los corderos?",
    generalHealthStatusBees:
      "¿Cuál es el estado general de salud de las familias de abejas?",
    honeyProduction:
      "¿Cómo se presenta la producción de miel respecto al período anterior?",
    queenStatus: "¿Cuál es el estado de las reinas en las colonias?",
    welcomeToAssistant: "¡Bienvenido al Asistente Inteligente!",
    startConversation:
      "Comienza la conversación con algunas de nuestras sugerencias.",
    loading: "Cargando...",
    UNDER_TREATMENT: "Tratamiento",
    noResultsForAnimal:
      "No se encontraron resultados para el animal especificado.",
    askAssistant: "Pregunta al Asistente",
    virtualAssistant: "Asistente Virtual",
    virtualFarmAssistant: "Asistente Virtual de la Granja",
    farmAdviceWithAI:
      "Consejos e información personalizada para la granja con el nuevo chat de IA integrado.",
    noAnimalsFoundForFilter:
      "No se encontraron animales para el filtro seleccionado",
    gestationCalendars: "Calendarios de gestación",
    activityCalendar: "Calendario de Actividades",
    inseminationDate: "Fecha de inseminación",
    possibleCalvingInterval: "Intervalo probable del parto",
    calvingDate: "Fecha del parto",
    openCalendar: "Abrir Calendario",
    seeEvents: "Mostrar eventos",
    hideEvents: "Ocultar eventos",
    thisFeatureIsNotEnabled: "Este módulo no está activo",
    buyFeature: "Adquirir el módulo",
    noGestationCalendarsPresent: "No tienes ningún calendario de gestación",
    filterByAge: "Filtrar por edad",
    currentInterval: "Intervalo actual",
    resetFilter: "Restablecer filtro",
    quickSelection: "Selección rápida",
    myApiaries: "Mis Colmenares",
    myApiary: "Mi Colmenar",
    myHives: "Mis Colmenas",
    myQueens: "Mis Reinas",
    addApiary: "Añadir Colmenar",
    addHive: "Añadir Colmena",
    isLoading: "Cargando...",
    hiveDetails: "Detalles de Colmena",
    apiaryDetails: "Detalles de Colmenar",
    hiveImage: "Imagen de la Colmena",
    hiveCode: "Código/Nombre único",
    hiveType: "Tipo de colmena",
    queenBirthYear: "Año de nacimiento de la reina",
    queenOrigin: "Origen de la reina",
    queen: "Reina",
    framesNumber: "Número de marcos",
    familyPower: "Fuerza de la familia",
    beeCount: "Número de abejas",
    broodFramesNumber: "Número de marcos con cría",
    honeyFramesNumber: "Número de marcos con miel",
    emptyFramesNumber: "Número de marcos vacíos",
    apiaryName: "Nombre del colmenar",
    apiaryAddress: "Dirección",
    dsvsa: "Nº de registro/autorización sanitaria",
    nameRequired: "¡El nombre es obligatorio!",
    nameMinLength: "¡La longitud mínima del nombre es de 2 caracteres!",
    addressRequired: "¡La dirección es obligatoria!",
    addressMinLength: "¡La longitud mínima de la dirección es de 5 caracteres!",
    addressMaxLength:
      "¡La longitud máxima de la dirección es de 200 caracteres!",
    registrationCodeRequired: "¡El código de registro es obligatorio!",
    registrationCodeFormat:
      "¡El código de registro solo puede contener letras, números y guiones!",
    descriptionMaxLength:
      "¡La longitud máxima de la descripción es de 1000 caracteres!",
    creatingApiary: "Creando colmenar...",
    apiaryCreated: "¡El colmenar ha sido creado!",
    apiaryCreateError: "Ha ocurrido un error al crear el colmenar",
    updatingApiary: "Actualizando colmenar...",
    deletingApiary: "Eliminando colmenar...",
    apiaryDeleted: "El colmenar ha sido eliminado",
    apiaryUpdated: "¡El colmenar ha sido actualizado!",
    apiaryUpdateError: "Ha ocurrido un error al actualizar el colmenar",
    apiaryDeleteError: "Ha ocurrido un error al eliminar el colmenar",
    registrationCode: "Código de registro",
    hivesNumber: "Número de colmenas",
    deleteApiary: "Eliminar colmenar",
    apiaries: "Colmenares",
    hives: "Colmenas",
    deleteApiaryConfirmation:
      "¿Estás seguro de que quieres eliminar el colmenar?",
    apiaryWillBeDeleted:
      "El colmenar, junto con todos los datos asociados, como colmenas, reinas y sus eventos serán eliminados",
    hiveManufacturer: "Fabricante de la colmena",
    creatingHive: "Creando colmena...",
    hiveCreatedSuccessfully: "¡La colmena ha sido creada con éxito!",
    errorCreatingHive: "¡Ha ocurrido un error al crear la colmena!",
    apiary: "Colmenar",
    apiaryRequired: "El colmenar es obligatorio",
    apiaryFormat:
      "El código del colmenar solo puede contener letras, números y guión",
    codeRequired: "El código de la colmena es obligatorio",
    codeFormat:
      "El código de la colmena solo puede contener letras, números y guión",
    codeMinLength: "El código de la colmena debe tener mínimo 2 caracteres",
    codeMaxLength: "El código de la colmena no puede exceder 50 caracteres",
    hiveTypeRequired: "El tipo de colmena es obligatorio",
    invalidHiveType: "El tipo de colmena seleccionado no es válido",
    manufacturerRequired: "El fabricante de la colmena es obligatorio",
    manufacturerMinLength:
      "El nombre del fabricante debe tener mínimo 2 caracteres",
    manufacturerMaxLength:
      "El nombre del fabricante no puede exceder 100 caracteres",
    broodFramesRequired: "El número de marcos con cría es obligatorio",
    broodFramesFormat: "El número de marcos con cría debe ser un número entero",
    broodFramesRange: "El número de marcos con cría debe estar entre 0 y 100",
    honeyFramesRequired: "El número de marcos con miel es obligatorio",
    honeyFramesFormat: "El número de marcos con miel debe ser un número entero",
    honeyFramesRange: "El número de marcos con miel debe estar entre 0 y 100",
    emptyFramesRequired: "El número de marcos vacíos es obligatorio",
    emptyFramesFormat: "El número de marcos vacíos debe ser un número entero",
    emptyFramesRange: "El número de marcos vacíos debe estar entre 0 y 100",
    beeCountRequired: "El número de abejas es obligatorio",
    beeCountFormat: "El número de abejas debe ser un número entero",
    beeCountRange: "El número de abejas debe estar entre 0 y 100000",
    addQueen: "Añadir Reina",
    deleteHive: "Eliminar colmena",
    hiveUpdating: "Actualizando colmena...",
    hiveUpdatedSuccessfully: "¡La colmena ha sido actualizada!",
    errorUpdatingHive: "Ha ocurrido un error al actualizar la colmena",
    deleteHiveConfirmation: "¿Estás seguro de que quieres eliminar la colmena?",
    hiveWillBeDeleted:
      "La colmena, junto con todos los datos asociados, como la reina y sus eventos serán eliminados",
    deletingHive: "Eliminando colmena...",
    deletingQueen: "Eliminando reina...",
    queenDeleted: "¡La reina ha sido eliminada!",
    hiveDeleted: "¡La colmena ha sido eliminada!",
    queenDeleteError: "Ha ocurrido un error al eliminar la reina",
    hiveDeleteError: "Ha ocurrido un error al eliminar la colmena",
    yearOfBirth: "Año de nacimiento",
    queenColor: "Color de la reina",
    "Own Production": "Producción propia",
    Purchased: "Adquisición",
    Blue: "Azul",
    Green: "Verde",
    Red: "Rojo",
    Yellow: "Amarillo",
    Black: "Negro",
    White: "Blanco",
    nameFormat:
      "El nombre de la reina solo puede contener letras, números, espacios y guión",
    yearRequired: "El año es obligatorio",
    yearFormat: "El año debe estar en formato YYYY (ej: 2024)",
    yearRange: "El año debe estar entre los últimos 5 años y el año actual",
    colorRequired: "El color de la reina es obligatorio",
    invalidColor: "El color seleccionado no es válido",
    originRequired: "El origen de la reina es obligatorio",
    invalidOrigin: "El origen seleccionado no es válido",
    creatingQueen: "Creando reina...",
    queenCreated: "¡La reina ha sido creada!",
    queenCreateError: "Ha ocurrido un error al crear la reina",
    hive: "Colmena",
    deleteQueen: "Eliminar reina",
    queenUpdating: "Actualizando reina",
    queenUpdatedSuccessfully: "¡La reina ha sido actualizada!",
    errorUpdatingQueen: "Ha ocurrido un error al actualizar la reina",
    deleteQueenConfirmation: "¿Estás seguro de que quieres eliminar la reina?",
    queenWillBeDeleted:
      "La reina, junto con todos los datos asociados, notas, tareas y eventos serán eliminados",
    bees: "Abejas",
    allApiaries: "Todos los Colmenares",
    searchHive: "Buscar colmena",
    allHiveTypes: "Todos los tipos de colmena",
    apiaryWasSuccessfullyAdded: "¡El colmenar ha sido añadido con éxito!",
    hiveStep:
      "El siguiente paso es añadir colmenas en su nuevo colmenar, ¿desea continuar?",
    hiveWasSuccessfullyAdded: "¡La colmena ha sido añadida con éxito!",
    queenStep:
      "El siguiente paso es añadir la reina en su nueva colmena, ¿desea continuar?",
    noHivesFoundForGivenQuery: "No se encontraron colmenas para su búsqueda",
    noQueensFoundForGivenQuery: "No se encontraron reinas para su búsqueda",
    allQueens: "Todas las Reinas",
    assocApiary: "Asociar Colmenar",
    assocHive: "Asociar Colmena",
    noApiariesAssociated: "No hay colmenares asociados",
    noHivesAssociated: "No hay colmenas asociadas",
    oneApiaryAssociated: "Un colmenar asociado",
    oneHiveAssociated: "Una colmena asociada",
    apiariesAssociated: "colmenares asociados",
    hivesAssociated: "colmenas asociadas",
    generalAnimalWelfare:
      "¿Cuál es el estado general de bienestar de los animales?",
    diseasePreventionPlan:
      "¿Cuál es el plan de prevención de enfermedades para todos los animales?",
    feedingStrategyGeneral:
      "¿Es óptima la estrategia de alimentación para todos los animales?",
    reproductiveHealthGeneral:
      "¿Cuál es la situación de la salud reproductiva en el ganado?",
    udderHealth: "¿Cuál es el estado de salud de la ubre en las vacas?",
    heatDetection: "¿Qué vacas presentan signos de celo?",
    pigletMortality: "¿Cuál es la tasa de mortalidad en lechones?",
    boarPerformance: "¿Cuál es el rendimiento de los verracos reproductores?",
    hoofHealth: "¿Cuál es el estado de las pezuñas en las cabras?",
    pasturePlanning: "¿Es eficiente el plan de pastoreo?",
    flockBehavior: "¿Hay cambios en el comportamiento del rebaño?",
    sheepSorting: "¿Qué ovejas necesitan clasificación para diferentes grupos?",
    hiveStrength: "¿Cuál es la fuerza de las familias de abejas?",
    varoaStatus: "¿Cuál es el nivel de infestación de varroa?",
    nectarSources: "¿Hay suficientes fuentes de néctar en el área?",
    biosecurityMeasures:
      "¿Cuáles son las medidas de bioseguridad implementadas?",
    selectApiaries: "Seleccionar colmenares",
    selectApiary: "Seleccionar colmenar",
    selectHive: "Seleccionar colmena",
    selectHives: "Seleccionar colmenas",
    noResultsFound: "No se encontraron resultados",
    hideOtherApiariesAssociated: "Ocultar otros colmenares asociados",
    hideOtherHivesAssociated: "Ocultar otras colmenas asociadas",
    seeOtherApiariesAssociated: "Ver otros colmenares asociados",
    seeOtherHivesAssociated: "Ver otras colmenas asociadas",
    noApiaryFound: "No se encontró ningún colmenar",
    noQueensAssociated: "No hay reinas asociadas",
    oneQueenAssociated: "Una reina asociada",
    queensAssociated: "reinas asociadas",
    selectQueens: "Seleccionar reinas",
    assocQueen: "Asociar Reina",
    theHive: "La Colmena",
    addApiaryEvent: "Añadir Evento de Colmenar",
    noApiarySelected: "No se ha seleccionado ningún colmenar",
    noHiveSelected: "No se ha seleccionado ninguna colmena",
    PestControl: "Control de Plagas",
    VegetationManagement: "Gestión de Vegetación",
    EquipmentMaintenance: "Mantenimiento de Equipos",
    InfrastructureRepair: "Reparación de Infraestructura",
    ApiaryMaintenance: "Mantenimiento de Colmenar",
    SpringAssessment: "Evaluación de Primavera",
    WinterPreparationCheck: "Verificación de Preparación para Invierno",
    AuthorityInspection: "Inspección de Autoridades",
    OfficialVeterinaryInspection: "Inspección Veterinaria Oficial",
    ApiaryInspection: "Inspección de Colmenar",
    PastoralMovement: "Movimiento Pastoral",
    PermanentLocationChange: "Cambio Permanente de Ubicación",
    ApiaryMovement: "Movimiento de Colmenar",
    personName: "Nombre de la Persona",
    eventTypeRequired: "El tipo de evento es obligatorio",
    eventTypeFormat:
      "El tipo de evento debe contener solo letras, números y guiones",
    personNameMaxLength:
      "El nombre de la persona no puede exceder 255 caracteres",
    dateFormat: "Formato de fecha inválido",
    notesMaxLength: "Las notas no pueden exceder 1000 caracteres",
    creatingApiaryEvent: "Creando evento del colmenar...",
    apiaryEventCreatedSuccessfully:
      "¡El evento del colmenar ha sido creado con éxito!",
    errorCreatingApiaryEvent:
      "Ha ocurrido un error al crear el evento del colmenar",
    apiariesEvents: "Eventos de Colmenares",
    apiaryEvent: "Evento de Colmenar",
    deletingApiaryEvent: "Eliminando evento del colmenar...",
    apiaryEventDeleted: "¡El evento del colmenar ha sido eliminado!",
    apiaryEventDeleteError:
      "Ha ocurrido un error al eliminar el evento del colmenar",
    ApiaryMovementEventType: "Eventos de Movimiento del Colmenar",
    ApiaryInspectionEventType: "Eventos de Inspección del Colmenar",
    ApiaryMaintenanceEventType: "Eventos de Mantenimiento del Colmenar",
    Acacia: "Acacia",
    Linden: "Tilo",
    Sunflower: "Girasol",
    Rapeseed: "Colza",
    "Poly Floral": "Polifloral",
    selectFloraType: "Seleccionar Tipo de Flora",
    apiaryEvents: "Eventos de Colmenar",
    apiaryEventsDescription: "Monitoriza la actividad de tus colmenares",
    hiveEventsDescription: "Monitoriza la actividad de tus colmenas",
    noEventsFound: "No se encontraron eventos",
    departureDateRequired: "La fecha de salida es obligatoria",
    floraTypeRequired: "El tipo de flora es obligatorio",
    partners: "Socios",
    viewOffer: "Ver Oferta",
    allTimes: "Todos los períodos",
    selectTimeFrame: "Seleccionar período",
    dateTo: "Fecha de fin",
    dateFrom: "Fecha de inicio",
    present: "Presente",
    selectDateTo: "Seleccionar fecha de fin",
    selectDateFrom: "Seleccionar fecha de inicio",
    showResults: "Mostrar Resultados",
    hiveEvents: "Eventos de Colmenas",
    queenEvents: "Eventos de Reinas",
    queenEventsDescription: "Monitoriza la actividad de tus reinas",
    allHives: "Todas las Colmenas",
    creatingHiveEvent: "Creando evento de colmena...",
    hiveEventCreatedSuccessfully:
      "¡El evento de la colmena ha sido creado con éxito!",
    errorCreatingHiveEvent:
      "Ha ocurrido un error al crear el evento de la colmena",
    "Hive must have a queen for this operation":
      "Para esta operación la colmena debe tener una reina",
    ColonyDivision: "División de Colonia",
    SwarmPrevention: "Prevención de Enjambrazón",
    RemovingSuper: "Eliminación de Alza",
    AddingSuper: "Adición de Alza",
    AddingFrames: "Adición de Marcos",
    QueenReplacement: "Reemplazo de Reina",
    Feeding: "Alimentación",
    HiveManagement: "Gestión de Colmena",
    WeightMeasurement: "Medición de Peso",
    BroodFramesCount: "Actualización Nº Marcos con Cría",
    HoneyFramesCount: "Actualización Nº Marcos con Miel",
    ColonyStrengthAssessment: "Evaluación de Fuerza de Colonia",
    HiveMeasurement: "Medición de Colmena",
    PreventiveTreatment: "Tratamiento Preventivo",
    DiseaseTreatment: "Tratamiento de Enfermedades",
    NosemaTreatment: "Tratamiento Nosema",
    VarroaTreatment: "Tratamiento Varroa",
    HiveTreatment: "Tratamiento de Colmena",
    WinterCheck: "Verificación de Invierno",
    BroodInspection: "Inspección de Cría",
    QueenInspection: "Inspección de Reina",
    DiseaseCheck: "Verificación de Enfermedades",
    RoutineCheck: "Verificación de Rutina",
    HiveInspection: "Inspección de Colmena",
    TransferToOtherApiary: "Transferencia a Otro Colmenar",
    TransferWithinApiary: "Transferencia dentro del Colmenar",
    HiveMovement: "Movimiento de Colmena",
    hiveEvent: "Evento de Colmena",
    HiveEquipment: "Equipamiento de Colmena",
    AddFrames: "Añadir Marcos",
    RemoveFrames: "Eliminar Marcos",
    AddSuper: "Añadir Alza",
    RemoveSuper: "Eliminar Alza",
    AddBroodBox: "Añadir Caja de Cría",
    RemoveBroodBox: "Eliminar Caja de Cría",
    deletingHiveEvent: "Eliminando evento de la colmena...",
    hiveEventDeleted: "¡El evento de la colmena ha sido eliminado!",
    hiveEventDeleteError:
      "Ha ocurrido un error al eliminar el evento de la colmena",
    deleteHiveEvent: "Eliminar Evento",
    deleteHiveEventConfirmation:
      "¿Estás seguro de que quieres eliminar el evento?",
    hiveEventWillBeDeleted: "El evento actual será eliminado.",
    updatingHiveEvent: "Actualizando evento de la colmena...",
    hiveEventUpdated: "¡El evento de la colmena ha sido actualizado!",
    hiveEventUpdateError:
      "Ha ocurrido un error al actualizar el evento de la colmena",
    HiveMovementEventType: "Eventos de Movimiento de Colmena",
    HiveInspectionEventType: "Eventos de Inspección de Colmena",
    HiveTreatmentEventType: "Eventos de Tratamiento de Colmena",
    HiveMeasurementEventType: "Eventos de Medición de Colmena",
    HiveEquipmentEventType: "Eventos de Equipamiento de Colmena",
    hiveRequired: "¡La colmena es obligatoria!",
    beeKeepingEvents: "Eventos de Apicultura",
    pieces: "piezas",
    grams: "gramos",
    inHivePosition: "Posición en la colmena",
    addNewLocation: "Añadir una nueva ubicación",
    previousLocations: "Ubicaciones anteriores",
    noPreviousLocations: "No existen ubicaciones anteriores",
    addLocation: "Añadir Ubicación",
    currentLocation: "Ubicación actual",
    noCurrentLocation: "No existe ubicación actual",
    currentPosition: "Posición actual",
    currentWeight: "Peso actual",
    broodBoxCount: "Número de cajas de cría",
    superCount: "Número de alzas",
    positionMaxLength: "La longitud máxima de la posición es de 255 caracteres",
    fromAndToPositionMustBeDifferent:
      "La posición de salida y la de llegada deben ser diferentes",
    "More empty frames are needed":
      "Para realizar la operación necesita más marcos vacíos.",
    "No supers available to remove": "No hay alzas disponibles para eliminar",
    "You can't delete more suppers than available":
      "No puede eliminar más alzas de las disponibles",
    "No brood boxes available to remove":
      "No hay cajas de cría disponibles para eliminar",
    "You can't remove more brood boxes than available":
      "No puede eliminar más cajas de cría de las disponibles",
    HiveQueenEventsEventType: "Eventos de Reinas",
    HiveQueenEvents: "Evento de Reina",
    QueenMarking: "Marcado de Reina",
    QueenClipping: "Recorte de Alas de Reina",
    QueenCaging: "Aislamiento de Reina (en jaula)",
    eventInfoDescription: "Información sobre el tipo de evento",
    "Events related to relocating the entire apiary, including permanent location changes and seasonal movements for better foraging opportunities.":
      "Eventos relacionados con la reubicación de todo el colmenar, incluidos cambios permanentes de ubicación y movimientos estacionales para mejores oportunidades de forrajeo.",
    "Official and routine inspections of the apiary as a whole, including regulatory checks and seasonal assessments.":
      "Inspecciones oficiales y rutinarias del colmenar en su conjunto, incluidas verificaciones reglamentarias y evaluaciones estacionales.",
    "Maintenance activities for the apiary infrastructure, equipment, and surrounding environment.":
      "Actividades de mantenimiento de la infraestructura del colmenar, el equipo y el entorno circundante.",
    "Events related to moving individual hives within or between apiaries.":
      "Eventos relacionados con el movimiento de colmenas individuales dentro o entre colmenares.",
    "Regular and specific inspections of individual hives to assess health, strength, and conditions.":
      "Inspecciones regulares y específicas de colmenas individuales para evaluar la salud, la fuerza y las condiciones generales.",
    "Medical and preventive treatments applied to hives for various health conditions.":
      "Tratamientos médicos y preventivos aplicados a las colmenas para diversas condiciones de salud.",
    "Measurements and assessments of various hive parameters and colony strength indicators.":
      "Mediciones y evaluaciones de varios parámetros de la colmena e indicadores de la fuerza de la colonia.",
    "Addition or removal of hive equipment and components.":
      "Adición o eliminación de equipos y componentes de la colmena.",
    "Events related to queen bee management and maintenance.":
      "Eventos relacionados con la gestión y el mantenimiento de la abeja reina.",
    "Permanent relocation of the entire apiary to a new location, requiring updates to official registration.":
      "Reubicación permanente de todo el colmenar a una nueva ubicación, que requiere actualizaciones en el registro oficial.",
    "Temporary relocation of hives to take advantage of specific nectar flows or pollination opportunities.":
      "Reubicación temporal de colmenas para aprovechar flujos específicos de néctar u oportunidades de polinización.",
    "Mandatory veterinary inspection of the apiary for health certification and disease monitoring.":
      "Inspección veterinaria obligatoria del colmenar para la certificación sanitaria y el seguimiento de enfermedades.",
    "Official inspection by beekeeping authorities for compliance with regulations and standards.":
      "Inspección oficial por las autoridades apícolas para verificar el cumplimiento de las regulaciones y estándares.",
    "Assessment of apiary conditions and preparations before winter season.":
      "Evaluación de las condiciones del colmenar y preparativos antes de la temporada de invierno.",
    "Evaluation of apiary condition and colonies after winter, planning for the new season.":
      "Evaluación del estado del colmenar y las colonias después del invierno, planificación para la nueva temporada.",
    "Repairs and maintenance of apiary infrastructure like stands, fencing, or windbreaks.":
      "Reparaciones y mantenimiento de la infraestructura del colmenar como soportes, cercas o cortavientos.",
    "Maintenance of shared apiary equipment such as extractors, tools, or storage facilities.":
      "Mantenimiento de equipos compartidos del colmenar como extractores, herramientas o instalaciones de almacenamiento.",
    "Management of vegetation around hives, including mowing, planting, or removing problematic plants.":
      "Gestión de la vegetación alrededor de las colmenas, incluido el corte, la plantación o la eliminación de plantas problemáticas.",
    "Control of apiary-level pests such as mice, ants, or wasps.":
      "Control de plagas a nivel de colmenar como ratones, hormigas o avispas.",
    "Moving a hive to a different location within the same apiary.":
      "Mover una colmena a una ubicación diferente dentro del mismo colmenar.",
    "Transferring a hive to a different apiary location.":
      "Transferir una colmena a una ubicación de colmenar diferente.",
    "Regular inspection of hive conditions, including population, stores, and overall health.":
      "Inspección regular de las condiciones de la colmena, incluida la población, las reservas y la salud general.",
    "Specific inspection focusing on signs of diseases or parasites.":
      "Inspección específica centrada en signos de enfermedades o parásitos.",
    "Inspection to verify queen presence, performance, and quality.":
      "Inspección para verificar la presencia, el rendimiento y la calidad de la reina.",
    "Detailed inspection of brood patterns, health, and development.":
      "Inspección detallada de los patrones de cría, salud y desarrollo.",
    "Quick inspection during winter to assess colony survival and stores.":
      "Inspección rápida durante el invierno para evaluar la supervivencia de la colonia y las reservas.",
    "Application of treatments specifically targeting Varroa mite infestations.":
      "Aplicación de tratamientos dirigidos específicamente a las infestaciones de ácaros Varroa.",
    "Treatment for Nosema disease in affected colonies.":
      "Tratamiento para la enfermedad de Nosema en colonias afectadas.",
    "Treatment for various bee diseases other than Varroa or Nosema.":
      "Tratamiento para diversas enfermedades de las abejas distintas de Varroa o Nosema.",
    "Preventive treatments applied to maintain colony health.":
      "Tratamientos preventivos aplicados para mantener la salud de la colonia.",
    "Assessment of overall colony strength, including bee population and vitality.":
      "Evaluación de la fuerza general de la colonia, incluida la población de abejas y la vitalidad.",
    "Counting and assessment of frames containing honey stores.":
      "Recuento y evaluación de marcos que contienen reservas de miel.",
    "Measurement of frames containing brood in different stages.":
      "Medición de marcos que contienen cría en diferentes etapas.",
    "Recording the weight of the hive to track honey stores and colony development.":
      "Registro del peso de la colmena para rastrear las reservas de miel y el desarrollo de la colonia.",
    "Adding a honey super to provide more space for honey storage.":
      "Agregar un alza para proporcionar más espacio para el almacenamiento de miel.",
    "Removing a honey super, typically for honey harvesting.":
      "Retirar un alza, generalmente para la cosecha de miel.",
    "Adding new frames to the hive for expansion or replacement.":
      "Agregar nuevos marcos a la colmena para expansión o reemplazo.",
    "Removing frames for maintenance, replacement, or honey extraction.":
      "Retirar marcos para mantenimiento, reemplazo o extracción de miel.",
    "Adding a new brood box to accommodate colony growth.":
      "Agregar una nueva caja de cría para acomodar el crecimiento de la colonia.",
    "Removing a brood box for colony size management or maintenance.":
      "Retirar una caja de cría para la gestión del tamaño de la colonia o mantenimiento.",
    "Marking the queen with a color code for easy identification and age tracking.":
      "Marcar la reina con un código de color para facilitar la identificación y el seguimiento de la edad.",
    "Clipping the queen's wings to prevent swarming and aid in swarm management.":
      "Recortar las alas de la reina para prevenir la enjambrazón y ayudar en la gestión de enjambres.",
    "Temporarily confining the queen in a cage for various management purposes.":
      "Confinar temporalmente a la reina en una jaula para diversos propósitos de gestión.",
    beekeeping: "Apicultura",
    currentChats: "Conversaciones actuales",
    whatCanIHelpWith: "¿En qué puedo ayudarle?",
    recentChats: "Conversaciones recientes",
    suggestedQuestions: "Preguntas sugeridas",
    startChat: "Iniciar conversación",
    viewAllChats: "Ver todas las conversaciones",
    noHivesAvailable: "No hay colmenas disponibles",
    noHive: "Sin colmena",
    noQueen: "Sin reina",
    queenHasNoHiveAssigned: "La reina no tiene ninguna colmena asociada",
    queenHasNoHiveAssignedAndNoAvailableHives:
      "La reina no tiene ninguna colmena asociada y no hay colmenas disponibles",
    removeQueenFromHive: "Eliminar Reina de la Colmena",
    removeQueenFromHiveConfirmation:
      "¿Estás seguro de que quieres eliminar la reina de la colmena?",
    removingQueen: "Eliminando reina...",
    queenRemovedFromHive: "¡La reina ha sido eliminada de la colmena!",
    errorRemovingQueenFromHive:
      "Ha ocurrido un error al eliminar la reina de la colmena",
    hiveHasNoQueenAndQueensAvailable:
      "La colmena no tiene ninguna reina asociada y hay reinas disponibles",
    hiveHasNoQueenAndNoQueensAvailable:
      "La colmena no tiene ninguna reina asociada y no hay reinas disponibles",
    queenHasNoHiveAndHivesAvailable:
      "La reina no tiene ninguna colmena asociada y hay colmenas disponibles",
    removingThread: "Eliminando conversación...",
    threadRemoved: "¡La conversación ha sido eliminada!",
    errorRemovingThread: "Ha ocurrido un error al eliminar la conversación",
    removeThread: "Eliminar Conversación",
    deleteThreadConfirmation:
      "¿Estás seguro de que quieres eliminar la conversación?",
    allThreads: "Todas las Conversaciones",
    messageRateLimitExceeded: "Se ha excedido el límite de mensajes.",
    limitWillBeRenewedIn: "El límite se renovará en",
    messageSendError: "Ha ocurrido un error al enviar el mensaje",
    youStillHave: "Todavía tienes",
    messagesLeftThisMonth: "mensajes disponibles este mes",
    messageLeftThisMonth: "mensaje disponible este mes",
    "Medical and preventive treatments applied to animals for various health conditions and maintenance.":
      "Tratamientos médicos y preventivos aplicados a los animales para diversas condiciones de salud y mantenimiento.",
    "Recording and tracking of various animal metrics and physical parameters.":
      "Registro y seguimiento de diversas métricas y parámetros físicos de los animales.",
    "Events related to animal movement between locations, including quarantine and isolation.":
      "Eventos relacionados con el movimiento de animales entre ubicaciones, incluida la cuarentena y el aislamiento.",
    "Events related to breeding, pregnancy, birth, and reproductive health management.":
      "Eventos relacionados con la reproducción, gestación, parto y gestión de la salud reproductiva.",
    "Events marking the permanent departure of animals from the facility.":
      "Eventos que marcan la salida permanente de animales de la instalación.",
    "Miscellaneous animal management events including routine care and observations.":
      "Eventos diversos de gestión animal, incluidos el cuidado rutinario y las observaciones.",
    "Planned end of life for research or other approved purposes.":
      "Fin de vida planificado para investigación u otros propósitos aprobados.",
    "Transfer of animal ownership through commercial transaction.":
      "Transferencia de propiedad del animal mediante transacción comercial.",
    "Recording of natural or unexpected death of an animal.":
      "Registro de muerte natural o inesperada de un animal.",
    "Documentation of missing or unaccounted for animals.":
      "Documentación de animales desaparecidos o no contabilizados.",
    "Administration of vaccines for disease prevention.":
      "Administración de vacunas para la prevención de enfermedades.",
    "Medical intervention for diagnosed diseases or conditions.":
      "Intervención médica para enfermedades o condiciones diagnosticadas.",
    "Administration of antiparasitic medications for internal parasite control.":
      "Administración de medicamentos antiparasitarios para el control de parásitos internos.",
    "Medical care for physical injuries or trauma.":
      "Atención médica para lesiones físicas o trauma.",
    "Surgical procedures performed on animals.":
      "Procedimientos quirúrgicos realizados en animales.",
    "Monitoring and care following surgical procedures.":
      "Monitoreo y cuidado después de procedimientos quirúrgicos.",
    "Provision of additional nutrients or dietary supplements.":
      "Provisión de nutrientes adicionales o suplementos dietéticos.",
    "Recording of animal body weight for monitoring growth and health.":
      "Registro del peso corporal del animal para monitorear el crecimiento y la salud.",
    "Assessment of animal's physical condition and fat reserves.":
      "Evaluación de la condición física y las reservas de grasa del animal.",
    "Movement of animals between different locations or facilities.":
      "Movimiento de animales entre diferentes ubicaciones o instalaciones.",
    "Placement of animals in quarantine for health monitoring.":
      "Colocación de animales en cuarentena para monitoreo de salud.",
    "Release of animals from quarantine after health clearance.":
      "Liberación de animales de la cuarentena después de la autorización sanitaria.",
    "Separation of animals for health or management purposes.":
      "Separación de animales por razones de salud o gestión.",
    "Artificial insemination procedure for breeding.":
      "Procedimiento de inseminación artificial para reproducción.",
    "Recording of natural breeding events.":
      "Registro de eventos de reproducción natural.",
    "Confirmation of pregnancy through testing.":
      "Confirmación de gestación mediante pruebas.",
    "Documentation of negative pregnancy test results.":
      "Documentación de resultados negativos de prueba de embarazo.",
    "Observation and recording of estrus signs.":
      "Observación y registro de signos de estro.",
    "Documentation of birthing events and outcomes.":
      "Documentación de eventos de parto y resultados.",
    "Recording of pregnancy loss events.":
      "Registro de eventos de pérdida de gestación.",
    "Beginning of milk production period.":
      "Inicio del período de producción de leche.",
    "End of milk production period.": "Fin del período de producción de leche.",
    "Routine health screening with positive findings.":
      "Examen de salud rutinario con hallazgos positivos.",
    "Routine health screening with no concerning findings.":
      "Examen de salud rutinario sin hallazgos preocupantes.",
    "Assessment of hoof condition and health.":
      "Evaluación de la condición y salud de las pezuñas.",
    "Planned end of lactation in dairy animals.":
      "Finalización planificada de la lactancia en animales lecheros.",
    "Separation of young animals from maternal feeding.":
      "Separación de animales jóvenes de la alimentación materna.",
    "Modification of feed type or feeding regime.":
      "Modificación del tipo de alimento o régimen de alimentación.",
    "Removal of wool or hair coat.": "Eliminación de lana o capa de pelo.",
    "Maintenance trimming of hooves.": "Recorte de mantenimiento de pezuñas.",
    "Application of identification tags.":
      "Aplicación de etiquetas de identificación.",
    "Surgical or chemical sterilization of male animals.":
      "Esterilización quirúrgica o química de animales machos.",
    "Removal or prevention of horn growth.":
      "Eliminación o prevención del crecimiento de cuernos.",
    "Scheduled or emergency veterinary examination.":
      "Examen veterinario programado o de emergencia.",
    "Documentation of animal behavior patterns or changes.":
      "Documentación de patrones de comportamiento animal o cambios.",
    tutorialsIntro:
      "Estamos encantados de que hayas elegido usar nuestra aplicación. Para\n" +
      "            ayudarte a familiarizarte con sus funcionalidades, hemos creado\n" +
      "            tutoriales en video detallados que explican paso a paso cómo utilizar\n" +
      "            nuestra aplicación.",
    NotFoundMessage: "La página que buscas no existe",
    goMainScreen: "Ir a la página principal",
    birdsGroups: "Grupos de Aves",
    newBirdGroup: "Nuevo Grupo de Aves",
    groupName: "Nombre del Grupo",
    Adult: "Adulto",
    Juvenile: "Juvenil",
    Hatchling: "Polluelo",
    Mixture: "Mixto",
    birdGroupAdultDescription:
      "Aves completamente maduras que han finalizado el desarrollo físico y han alcanzado la edad reproductiva. Generalmente, presentan plumaje adulto y patrones de coloración específicos de su especie.",
    birdGroupJuvenileDescription:
      "Aves jóvenes que han dejado el nido y pueden volar, pero no han alcanzado la madurez adulta. Pueden presentar patrones de plumaje intermedios y continúan creciendo. Generalmente entre 2-12 meses, dependiendo de la especie.",
    birdGroupHatchingDescription:
      "Aves recién eclosionadas hasta la etapa de polluelo. Generalmente, aún en el nido, requiriendo cuidado y alimentación parental. Pueden ser parcial o completamente dependientes de los padres para sobrevivir.",
    birdGroupUnknownDescription:
      "La edad no puede determinarse debido a marcadores visuales insuficientes, documentación o restricciones de observación.",
    birdGroupMixtureDescription:
      "Grupo de aves con categorías de edad mixtas, incluidos adultos, juveniles y polluelos.",
    ageGroup: "Grupo de Edad",
    birdsCount: "Número de Aves",
    birdGroups: "Grupos de Aves",
    creatingBirdsGroup: "Creando grupo de aves...",
    birdsGroupCreated: "¡El grupo de aves ha sido creado!",
    errorCreatingBirdsGroup: "Ha ocurrido un error al crear el grupo de aves",
    name100Characters: "El nombre no puede exceder 100 caracteres",
    description500Characters: "La descripción no puede exceder 500 caracteres",
    countMin1: "El número de aves debe ser mínimo 1",
    birdsSummary: "Resumen Datos Aves",
    count: "Número",
    deletingBirdsGroup: "Eliminando grupo de aves...",
    birdsGroupDeleted: "¡El grupo de aves ha sido eliminado!",
    birdsGroupDeleteError: "Ha ocurrido un error al eliminar el grupo de aves",
    deleteBirdsGroupConfirmation:
      "¿Estás seguro de que quieres eliminar el grupo de aves?",
    birdsGroupWillBeDeleted: "El grupo de aves será eliminado.",
    updatingBirdsGroup: "Actualizando grupo de aves...",
    birdsGroupUpdated: "¡El grupo de aves ha sido actualizado!",
    errorUpdatingBirdsGroup:
      "Ha ocurrido un error al actualizar el grupo de aves",
    deleteBirdsGroup: "Eliminar Grupo de Aves",
    Duck: "Pato",
    Turkey: "Pavo",
    Goose: "Ganso",
    Quail: "Codorniz",
    Ostrich: "Avestruz",
    "Rhode Island Red": "Rhode Island Rojo",
    "Plymouth Rock": "Plymouth Rock",
    Leghorn: "Leghorn",
    Australorp: "Australorp",
    Orpington: "Orpington",
    Sussex: "Sussex",
    Wyandotte: "Wyandotte",
    Brahma: "Brahma",
    Cochin: "Cochin",
    Silkie: "Sedosa",
    "New Hampshire": "New Hampshire",
    Ameraucana: "Ameraucana",
    Marans: "Marans",
    Pekin: "Pekin",
    Muscovy: "Muscovy",
    Runner: "Corredor",
    "Khaki Campbell": "Khaki Campbell",
    Cayuga: "Cayuga",
    "Swedish Blue": "Sueca Azul",
    Rouen: "Rouen",
    "Welsh Harlequin": "Arlequín Galés",
    "Broad Breasted White": "Pechuga Ancha Blanco",
    "Broad Breasted Bronze": "Pechuga Ancha Bronce",
    "Bourbon Red": "Bourbon Rojo",
    Narragansett: "Narragansett",
    "Royal Palm": "Palmera Real",
    "Black Spanish": "Español Negro",
    "Beltsville Small White": "Beltsville Blanco Pequeño",
    Toulouse: "Toulouse",
    Embden: "Embden",
    African: "Africano",
    Chinese: "Chino",
    Pilgrim: "Pilgrim",
    "American Buff": "Americano Beige",
    Sebastopol: "Sebastopol",
    "African Black": "Africano Negro",
    "Blue Neck": "Cuello Azul",
    "Red Neck": "Cuello Rojo",
    "Kenyan Red": "Keniano Rojo",
    "Zimbabwe Blue": "Zimbabwe Azul",
    "South African Black": "Sudafricano Negro",
    "Israeli Blue": "Israelí Azul",
    "Australian Black": "Australiano Negro",
    selectBirdGroups: "Seleccionar Grupos de Aves",
    noBirdGroupsAssociated: "No hay grupos de aves asociados",
    oneBirdGroupAssociated: "1 grupo de aves asociado",
    birdGroupsAssociated: "grupos de aves asociados",
    assocBirdGroups: "Asociar Grupos de Aves",
    birds: "Aves",
    animalGender: "Género del Animal",
    selectHiveType: "Seleccionar tipo de colmena",
    contactInfo:
      "Para cualquier pregunta o problema relacionado, ¡no dude en contactarnos y le responderemos lo antes posible!",
    appLanguage: "Idioma de la Aplicación",
    romanian: "Rumano",
    english: "Inglés",
    selectAppLanguage: "Seleccionar idioma de la aplicación",
    event: "Evento",
    birdsGroupsEvents: "Eventos de Aves",
    thereAreNoEventsYet: "No hay eventos todavía",
    selectBirdsGroup: "Seleccionar Grupo de Aves",
    creatingBirdsGroupEvent: "Creando evento del grupo de aves...",
    birdsGroupEventCreated: "¡El evento del grupo de aves ha sido creado!",
    errorCreatingBirdsGroupEvent:
      "Ha ocurrido un error al crear el evento del grupo de aves",
    birdsGroupRequired: "El grupo de aves es obligatorio",
    HealthEventType: "Eventos de Salud",
    Health: "Salud",
    HealthCheck: "Verificación de Salud",
    MedicationAdministration: "Administración de Medicamentos",
    "Generic medical and preventive health procedures for birds.":
      "Procedimientos médicos y preventivos genéricos para aves.",
    "Generic event related to recording and tracking of bird metrics.":
      "Evento genérico relacionado con el registro y seguimiento de métricas de aves.",
    "Generic events related to bird movement and location changes.":
      "Eventos genéricos relacionados con el movimiento de aves y cambios de ubicación.",
    "Routine health inspection and monitoring.":
      "Inspección y monitoreo rutinario de salud.",
    "Movement between different locations.":
      "Movimiento entre diferentes ubicaciones.",
    "Separation for health or management purposes.":
      "Separación por razones de salud o gestión.",
    "Habitat cleaning and maintenance.":
      "Limpieza y mantenimiento del hábitat.",
    "General monitoring and behavioral observation.":
      "Monitoreo general y observación conductual.",
    "Miscellaneous bird event that doesn't fit into any category.":
      "Evento diverso que no se encuadra en ninguna categoría.",
    "Administration of medication for disease prevention or treatment.":
      "Administración de medicamentos para la prevención o tratamiento de enfermedades.",
    birdsGroup: "Grupo de Aves",
    birdsGroupEvent: "Evento de Grupo de Aves",
    Cleaning: "Limpieza",
    Observation: "Observación",
    allBirdsGroups: "Todos los Grupos de Aves",
    AddHeads: "Añadir Cabezas",
    RemoveHeads: "Eliminar Cabezas",
    "Add birds into the group.": "Añadir aves al grupo.",
    "Remove birds into the group.": "Eliminar aves del grupo.",
    medicineDescription: "Descripción del Medicamento",
    "Broad-spectrum antibiotic effective against respiratory infections and bacterial diseases. Commonly used for treating bacterial infections in various bird species.":
      "Antibiótico de amplio espectro eficaz contra infecciones respiratorias y enfermedades bacterianas. Comúnmente utilizado para tratar infecciones bacterianas en diversas especies de aves.",
    "Antibiotic effective against mycoplasma infections and chronic respiratory disease. Often used in combination with other medications for respiratory treatments.":
      "Antibiótico eficaz contra infecciones por micoplasma y enfermedades respiratorias crónicas. A menudo se usa en combinación con otros medicamentos para tratamientos respiratorios.",
    "Anti-coccidial medication used to prevent and treat coccidiosis. Essential for maintaining digestive health in young birds.":
      "Medicamento anticoccidial utilizado para prevenir y tratar la coccidiosis. Esencial para mantener la salud digestiva en aves jóvenes.",
    "Essential vitamin supplement supporting bone health, immune function, and reproduction. Particularly important during growth and breeding periods.":
      "Suplemento vitamínico esencial que favorece la salud ósea, la función inmunitaria y la reproducción. Particularmente importante durante los períodos de crecimiento y reproducción.",
    "Vitamin supplement supporting metabolism, nerve function, and overall health. Often used during stress periods or recovery.":
      "Suplemento vitamínico que favorece el metabolismo, la función nerviosa y la salud general. A menudo utilizado durante períodos de estrés o recuperación.",
    "Probiotics for Birds": "Probióticos para Aves",
    "Beneficial bacteria supplement supporting digestive health and immune function. Especially useful after antibiotic treatment or during stress.":
      "Suplemento de bacterias beneficiosas que favorece la salud digestiva y la función inmunitaria. Especialmente útil después del tratamiento con antibióticos o durante períodos de estrés.",
    "Antifungal medication used to treat yeast infections and candidiasis. Common treatment for digestive tract fungal infections.":
      "Medicamento antifúngico utilizado para tratar infecciones por levaduras y candidiasis. Tratamiento común para infecciones fúngicas del tracto digestivo.",
    "Deworming medication effective against roundworms. Regular use helps maintain digestive health and prevent parasite buildup.":
      "Medicamento desparasitante eficaz contra lombrices intestinales. El uso regular ayuda a mantener la salud digestiva y prevenir la acumulación de parásitos.",
    "Electrolyte Solution": "Solución de Electrolitos",
    "Supplement for maintaining hydration and electrolyte balance. Critical during heat stress or illness recovery.":
      "Suplemento para mantener la hidratación y el equilibrio de electrolitos. Crítico durante el estrés por calor o la recuperación de enfermedades.",
    Milliliters: "Mililitros",
    Milligrams: "Miligramos",
    Grams: "Gramos",
    Pieces: "Piezas",
    measuringUnit: "Unidad de Medida",
    birdsGroupIntendedPurpose: "Propósito del Grupo de Aves",
    "Eggs and Meat": "Huevos y Carne",
    Eggs: "Huevos",
    Pet: "Mascotas",
    Breeding: "Reproducción",
    TrimmingFeathers: "Recorte de Alas",
    "Trimming of feathers or beaks.": "Recorte de plumas o picos.",
    EggsCounting: "Conteo de Huevos",
    "Counting the number of eggs produced by the group.":
      "Conteo del número de huevos producidos por el grupo.",
    eggsCount: "Número de Huevos",
    eggsProduction: "Producción de Huevos",
    totalEggsCount: "Número Total de Huevos",
    latestCount: "Último Conteo",
    recordedEvents: "Eventos Registrados",
    recentEvents: "Eventos Recientes",
    noBirdsGroupsFound: "No se encontraron grupos de aves",
    assocBirdGroup: "Asociar Grupo de Aves",
    intendedUseRequired: "El Propósito del Grupo de Aves es obligatorio",
    locationRequired: "La ubicación es obligatoria",
    updatingEvent: "Actualizando evento...",
    eventUpdated: "¡El evento ha sido actualizado!",
    eventUpdateError: "Ha ocurrido un error al actualizar el evento",
    deletingEvent: "Eliminando evento...",
    eventDeleted: "¡El evento ha sido eliminado!",
    eventDeleteError: "Ha ocurrido un error al eliminar el evento",
    hiveScale: "Báscula de Colmena",
    myScales: "Mis Básculas",
    addHiveScale: "Añadir Báscula de Colmena",
    creatingHiveScale: "Creando báscula de colmena...",
    hiveScaleCreated: "¡La báscula de colmena ha sido creada!",
    errorCreatingHiveScale:
      "Ha ocurrido un error al crear la báscula de colmena",
    phoneNumber: "Número de Teléfono",
    battery: "Batería",
    lastMeasurementTime: "Última Medición",
    humidity: "Humedad",
    pressure: "Presión",
    temperature: "Temperatura",
    extraWeight: "Peso Extra",
    signal: "Señal",
    noMeasurementsRecorded: "No se han registrado mediciones",
    oneDayImprovement: "Mejora 24h",
    lastEvents: "Últimos {{count}} eventos de {{total}} eventos",
    messageSent: "¡El mensaje ha sido enviado!",
    messageNotSent: "Ha ocurrido un error al enviar el mensaje",
    ainNotUnique: "El identificador debe ser único",
    temperatureStatistics: "Estadísticas de Temperatura",
    weightStatistics: "Estadísticas de Peso",
    period: "Período",
    lastMeasurement: "Última Medición",
    statistics: "Estadísticas",
    lastMeasurements: "Últimas {{count}} Mediciones",
    lastMeasurementsTotal: "Últimas {{count}} Mediciones de {{total}}",
    last_24h: "Últimas 24h",
    last_2_weeks: "Últimas 2 Semanas",
    minVal: "Valor Mínimo",
    maxVal: "Valor Máximo",
    avgVal: "Valor Medio",
    hiveLocation: "Ubicación de la Colmena",
    forBetterRepresentationAvg:
      "Para una mejor representación, los valores se han calculado como el promedio de intervalos de {{count}} horas.",
    showAllMeasurements: "Mostrar Todas las Mediciones",
    measurements: "Mediciones",
    measurementsForHive: "Mediciones para la Colmena",
    changeHive: "Cambiar Colmena",
    hiveScaleDetails: "Detalles de Báscula de Colmena",
    loadMore: "Cargar Más",
    lastMeasurementsForHive: "Últimas Mediciones para la Colmena",
    HoneyHarvest: "Cosecha de Miel",
    WaxHarvest: "Cosecha de Cera",
    PropolisHarvest: "Cosecha de Propóleo",
    HiveHarvest: "Cosecha de Colmena",
    "Events related to honey harvesting and processing or related yield.":
      "Eventos relacionados con la cosecha y procesamiento de miel o producción relacionada.",
    "Extracting honey from frames and processing it for storage or sale.":
      "Extracción de miel de los marcos y su procesamiento para almacenamiento o venta.",
    "Collecting propolis from hives for various uses or products.":
      "Recolección de propóleo de las colmenas para diversos usos o productos.",
    "Harvesting beeswax from frames or cappings for processing or sale.":
      "Recolección de cera de abeja de los marcos o tapas para procesamiento o venta.",
    HiveHarvestEventType: "Eventos de Cosecha de Colmena",
    addHoneyHarvest: "Añadir Cosecha de Miel",
    removeScaleFromHive: "Eliminar Báscula de la Colmena",
    changePhoneNumber: "Cambiar Número de Teléfono",
    removeScaleFromHiveConfirmation:
      "¿Estás seguro de que quieres eliminar la báscula de la colmena?",
    removingScale: "Eliminando báscula...",
    scaleRemoved: "¡La báscula ha sido eliminada!",
    scaleRemoveError: "Ha ocurrido un error al eliminar la báscula",
    hiveScales: "Básculas de Colmena",
    associatedHive: "Colmena Asociada",
    scaleForHive: "Báscula para la Colmena {{hive}}",
    scale: "Báscula {{phoneNumber}}",
    noAssocHive: "No existe ninguna Colmena Asociada",
    assocHiveScale: "Asociar Báscula de Colmena",
    assocHiveScaleConfirmation:
      "¿Estás seguro de que quieres asociar la báscula con la colmena {{hive_code}}?",
    updatingHiveScale: "Actualizando báscula de colmena...",
    hiveScaleUpdated: "¡La báscula de colmena ha sido actualizada!",
    hiveScaleUpdateError:
      "Ha ocurrido un error al actualizar la báscula de colmena",
    noHivesWithoutScales: "No existen colmenas sin básculas",
    hiveScaleEvents: "Eventos de Báscula de Colmena",
    ScaleRemoved: "Báscula Eliminada",
    ScaleAdded: "Báscula Añadida",
    PhoneNumberChanged: "Número de Teléfono Cambiado",
    scaleRemovedEventDescription:
      "La báscula {{scale}} ha sido eliminada de la colmena {{hive}}!",
    scaleAddedEventDescription:
      "La báscula {{scale}} ha sido añadida a la colmena {{hive}}!",
    phoneNumberChangedEventDescription:
      "El número de teléfono de la báscula {{scale}} ha sido cambiado de {{old_phone_number}} a {{new_phone_number}}!",
    noLocationData: "No existen datos de ubicación",
    noScalesWithoutHive: "No existen básculas sin colmena asociada",
    noHiveScalesFound: "No se encontraron básculas de colmena",
    language: "Idioma",
    changingLanguage: "Cambiando idioma...",
    languageChanged: "¡El idioma ha sido cambiado!",
    languageChangeError: "Ha ocurrido un error al cambiar el idioma",
    yourData: "Tus Datos",
    yourFarmDetails: "Detalles de tu Granja",
    billingData: "Datos de Facturación",
    farmDetails: "Detalles de la Granja",
    "Enter your phone number": "Introduce tu número de teléfono",
    "Legal Name": "Nombre Legal",
    "Enter the legal name of your business":
      "Introduce el nombre legal de tu empresa",
    "Tax Identification Number (TIN)": "Número de Identificación Fiscal (NIF)",
    "Enter your tax identification number":
      "Introduce tu número de identificación fiscal",
    Street: "Calle",
    "Street address": "Dirección",
    City: "Ciudad",
    "State/Province": "Estado/Provincia",
    "State or province": "Estado o provincia",
    Country: "País",
    Beekeeping: "Apicultura",
    "Choose at least one module that corresponds to your farm's activities":
      "Elija al menos un módulo que corresponda a las actividades de su granja",
    legalNameRequired: "El nombre de la empresa es obligatorio",
    tinRequired: "El número de identificación fiscal es obligatorio",
    streetRequired: "La calle es obligatoria",
    cityRequired: "La ciudad es obligatoria",
    spRequired: "El estado/provincia es obligatorio",
    countryRequired: "El país es obligatorio",
    "Farm Name": "Nombre de la Granja",
    "Enter the legal name of your farm": "Introduzca el nombre de su granja",
    pleaseEnterEmail:
      "Por favor, introduzca su dirección de correo electrónico",
    pleaseEnterPassword: "Por favor, introduzca su contraseña",
    passwordRequired: "La contraseña es obligatoria",
    invalidEmail: "La dirección de correo electrónico no es válida",
    "Enter the name of your farm": "Introduzca el nombre de su granja",
    selectCountryCode: "Seleccionar Código de País",
    updatingPreferences: "Actualizando preferencias...",
    preferencesUpdated: "¡Preferencias actualizadas!",
    preferencesUpdateError:
      "Se produjo un error al actualizar las preferencias",

    // Bees medications

    "Varroa Treatment": "Tratamiento Varroa",
    "Nosema Treatment": "Tratamiento Nosema",
    "Brood Disease Treatment": "Tratamiento enfermedades de cría",
    Supplement: "Suplemento",
    Antibiotic: "Antibiótico",
    Fungicide: "Fungicida",
    "General Health": "Salud general",
    "Treatments specifically designed to control Varroa mite infestations in bee colonies.":
      "Tratamientos específicamente diseñados para controlar las infestaciones del ácaro Varroa en colonias de abejas.",
    "Medications for treating Nosema apis and Nosema ceranae infections in honey bees.":
      "Medicamentos para tratar infecciones de Nosema apis y Nosema ceranae en abejas melíferas.",
    "Treatments for various brood diseases including American foulbrood, European foulbrood, and chalkbrood.":
      "Tratamientos para diversas enfermedades de cría, incluyendo loque americana, loque europea y pollo escayolado.",
    "Nutritional supplements to support colony health and development.":
      "Suplementos nutricionales para apoyar la salud y el desarrollo de la colonia.",
    "Antibiotics used to treat bacterial infections in bee colonies when legally permitted.":
      "Antibióticos utilizados para tratar infecciones bacterianas en colonias de abejas cuando está legalmente permitido.",
    "Treatments for fungal diseases in bee colonies.":
      "Tratamientos para enfermedades fúngicas en colonias de abejas.",
    "General treatments to support overall colony health and immunity.":
      "Tratamientos generales para apoyar la salud general e inmunidad de la colonia.",
    Amitraz: "Amitraz",
    "Oxalic Acid": "Ácido oxálico",
    "Formic Acid": "Ácido fórmico",
    ApiVar: "ApiVar",
    "ApiLife VAR": "ApiLife VAR",
    "Mite-Away Quick Strips": "Tiras Mite-Away Quick",
    Apistan: "Apistan",
    "CheckMite+": "CheckMite+",
    HopGuard: "HopGuard",
    Thymovar: "Thymovar",
    "Fumagilin-B": "Fumagilin-B",
    Nozevit: "Nozevit",
    Terramycin: "Terramicina",
    Tylan: "Tylan",
    "Bee Pro": "Bee Pro",
    HiveAlive: "HiveAlive",
    ProDFM: "ProDFM",
    "Vitamin B Complex": "Complejo de vitamina B",
    ApiHerb: "ApiHerb",
    "Essential Oil Blend": "Mezcla de aceites esenciales",
    "Honey-B-Healthy": "Honey-B-Healthy",
    "Synthetic acaricide used to control Varroa mites in honey bee colonies.":
      "Acaricida sintético utilizado para controlar ácaros Varroa en colonias de abejas melíferas.",
    "Natural organic acid treatment for Varroa mites, especially effective in broodless periods.":
      "Tratamiento con ácido orgánico natural para ácaros Varroa, especialmente efectivo en períodos sin cría.",
    "Organic acid treatment for Varroa mites that can be used during honey flow, affects mites under cell cappings.":
      "Tratamiento con ácido orgánico para ácaros Varroa que puede usarse durante el flujo de miel, afecta a los ácaros bajo los opérculos de las celdas.",
    "Amitraz-based Varroa treatment strips, typically used for 6-8 weeks for full efficacy.":
      "Tiras de tratamiento Varroa a base de amitraz, normalmente utilizadas durante 6-8 semanas para una eficacia completa.",
    "Thymol, eucalyptol, menthol, and camphor-based treatment for Varroa mites.":
      "Tratamiento para ácaros Varroa a base de timol, eucaliptol, mentol y alcanfor.",
    "Formic acid-based Varroa treatment delivered via gel strips, effective against mites under brood cappings.":
      "Tratamiento Varroa a base de ácido fórmico administrado a través de tiras de gel, eficaz contra ácaros bajo los opérculos de cría.",
    "Tau-fluvalinate based strips for Varroa control, though resistance is now common in many areas.":
      "Tiras a base de tau-fluvalinato para el control de Varroa, aunque la resistencia es ahora común en muchas áreas.",
    "Coumaphos-based strips for Varroa control, often used in rotation with other treatments.":
      "Tiras a base de cumafós para el control de Varroa, frecuentemente utilizadas en rotación con otros tratamientos.",
    "Beta acid hop compound strips for Varroa control, can be used during honey production.":
      "Tiras compuestas de ácido beta de lúpulo para el control de Varroa, pueden usarse durante la producción de miel.",
    "Thymol-based treatment for Varroa mites with lower concentration than ApiLife VAR.":
      "Tratamiento a base de timol para ácaros Varroa con menor concentración que ApiLife VAR.",
    "Treatment for Nosema disease, administered in sugar syrup to prevent and control infections.":
      "Tratamiento para la enfermedad de Nosema, administrado en jarabe de azúcar para prevenir y controlar infecciones.",
    "Natural plant-based supplement to help bees resist Nosema infections.":
      "Suplemento natural a base de plantas para ayudar a las abejas a resistir infecciones de Nosema.",
    "Oxytetracycline antibiotic for prevention and control of European foulbrood and American foulbrood (where legal).":
      "Antibiótico de oxitetraciclina para la prevención y control de loque europea y loque americana (donde es legal).",
    "Tylosin antibiotic used for American foulbrood control in some regions where legally permitted.":
      "Antibiótico de tilosina utilizado para el control de loque americana en algunas regiones donde está legalmente permitido.",
    "Protein supplement to support bee nutrition when natural pollen is scarce.":
      "Suplemento proteico para apoyar la nutrición de las abejas cuando el polen natural es escaso.",
    "Nutritional supplement with thymol, lemongrass, and seaweed extracts to support colony health.":
      "Suplemento nutricional con extractos de timol, hierba limón y algas marinas para apoyar la salud de la colonia.",
    "Probiotic supplement to support bee gut health and immune function.":
      "Suplemento probiótico para apoyar la salud intestinal y la función inmune de las abejas.",
    "Vitamin supplement added to sugar syrup to support bee health during stress periods.":
      "Suplemento vitamínico añadido al jarabe de azúcar para apoyar la salud de las abejas durante períodos de estrés.",
    "Herbal preparation to support overall colony health and vigor.":
      "Preparación herbal para apoyar la salud y vigor general de la colonia.",
    "Mixture of lemongrass, spearmint, and other essential oils to promote colony health.":
      "Mezcla de hierba limón, menta verde y otros aceites esenciales para promover la salud de la colonia.",
    "Essential oil-based feed supplement to stimulate feeding and support colony health.":
      "Suplemento alimenticio a base de aceites esenciales para estimular la alimentación y apoyar la salud de la colonia.",
    Drops: "Gotas",
    Strips: "Tiras",
    Doses: "Dosis",
    Packets: "Paquetes",

    // Animal medications

    Penicillin: "Penicilina",
    Oxytetracycline: "Oxitetraciclina",
    Ceftiofur: "Ceftiofur",
    Tylosin: "Tilosina",
    "Flunixin Meglumine": "Flunixin Meglumine",
    Meloxicam: "Meloxicam",
    Dexamethasone: "Dexametasona",
    Ivermectin: "Ivermectina",
    Fenbendazole: "Fenbendazol",
    Albendazole: "Albendazol",
    Levamisole: "Levamisol",
    Oxytocin: "Oxitocina",
    "Prostaglandin F2alpha": "Prostaglandina F2alfa",
    GnRH: "GnRH",
    "Calcium Borogluconate": "Boroglucanoato de Calcio",
    "Selenium/Vitamin E": "Selenio/Vitamina E",
    Lidocaine: "Lidocaína",
    "Propylene Glycol": "Propilenglicol",
    "Sodium Bicarbonate": "Bicarbonato de Sodio",
    Cephalexin: "Cefalexina",
    Cloxacillin: "Cloxacilina",
    Epinephrine: "Epinefrina",
    Monensin: "Monensina",
    Rumensin: "Rumensina",

    // animal medications descriptions

    "Basic antibiotic for treating bacterial infections in livestock. Commonly used for pneumonia, mastitis, and foot rot.":
      "Antibiótico básico para tratar infecciones bacterianas en ganado. Comúnmente usado para neumonía, mastitis y podredumbre del pie.",

    "Broad-spectrum antibiotic effective against respiratory diseases, pinkeye, and foot rot in cattle and sheep.":
      "Antibiótico de amplio espectro eficaz contra enfermedades respiratorias, ojo rosado y podredumbre del pie en ganado vacuno y ovino.",

    "Advanced antibiotic for treating severe bacterial infections, particularly respiratory disease in cattle.":
      "Antibiótico avanzado para tratar infecciones bacterianas graves, particularmente enfermedades respiratorias en ganado vacuno.",

    "Antibiotic primarily used for respiratory diseases in cattle and swine. Also effective against mastitis.":
      "Antibiótico usado principalmente para enfermedades respiratorias en ganado vacuno y porcino. También eficaz contra mastitis.",

    "NSAID used for pain, fever, and inflammation in livestock. Commonly used for mastitis and lameness.":
      "AINE utilizado para dolor, fiebre e inflamación en ganado. Comúnmente usado para mastitis y cojera.",

    "NSAID used for pain management in cattle, particularly after dehorning or castration.":
      "AINE utilizado para el manejo del dolor en ganado vacuno, particularmente después del descorne o castración.",

    "Corticosteroid used for severe inflammation, shock, and allergic reactions in livestock.":
      "Corticosteroide utilizado para inflamación severa, shock y reacciones alérgicas en ganado.",

    "Broad-spectrum parasiticide effective against internal and external parasites in cattle, sheep, and pigs.":
      "Parasiticida de amplio espectro eficaz contra parásitos internos y externos en ganado vacuno, ovino y porcino.",

    "Dewormer effective against gastrointestinal parasites in cattle, sheep, and goats.":
      "Desparasitante eficaz contra parásitos gastrointestinales en ganado vacuno, ovino y caprino.",

    "Broad-spectrum dewormer effective against internal parasites, including liver flukes in ruminants.":
      "Desparasitante de amplio espectro eficaz contra parásitos internos, incluyendo fasciola hepática en rumiantes.",

    "Dewormer effective against gastrointestinal roundworms in cattle, sheep, and goats.":
      "Desparasitante eficaz contra gusanos redondos gastrointestinales en ganado vacuno, ovino y caprino.",

    "Hormone used to induce labor, treat retained placenta, and stimulate milk letdown in dairy animals.":
      "Hormona utilizada para inducir el parto, tratar retención de placenta y estimular la bajada de leche en animales lecheros.",

    "Hormone used for estrus synchronization and treatment of retained corpus luteum in cattle.":
      "Hormona utilizada para sincronización del estro y tratamiento de cuerpo lúteo retenido en ganado vacuno.",

    "Hormone used for treating cystic ovaries and synchronizing ovulation in cattle breeding programs.":
      "Hormona utilizada para tratar ovarios quísticos y sincronizar la ovulación en programas de reproducción de ganado vacuno.",

    "Supplement used for treating vitamin B deficiencies and supporting metabolism in stressed animals.":
      "Suplemento utilizado para tratar deficiencias de vitamina B y apoyar el metabolismo en animales estresados.",

    "Used for treating milk fever (hypocalcemia) in dairy cattle.":
      "Utilizado para tratar fiebre de leche (hipocalcemia) en ganado lechero.",

    "Supplement used to prevent and treat white muscle disease in young livestock.":
      "Suplemento utilizado para prevenir y tratar la enfermedad del músculo blanco en ganado joven.",

    "Local anesthetic used for minor surgeries, dehorning, and castration procedures.":
      "Anestésico local utilizado para cirugías menores, descorne y procedimientos de castración.",

    "Used to treat ketosis in dairy cattle and as an energy supplement.":
      "Utilizado para tratar cetosis en ganado lechero y como suplemento energético.",

    "Buffer used to treat and prevent ruminal acidosis in cattle.":
      "Tampón utilizado para tratar y prevenir la acidosis ruminal en ganado vacuno.",

    "Intramammary antibiotic used for treating mastitis in dairy cattle.":
      "Antibiótico intramamario utilizado para tratar mastitis en ganado lechero.",

    "Intramammary antibiotic specifically designed for dry cow therapy.":
      "Antibiótico intramamario específicamente diseñado para terapia de vacas secas.",

    "Emergency medication used for treating anaphylactic reactions in livestock.":
      "Medicamento de emergencia utilizado para tratar reacciones anafilácticas en ganado.",

    "Feed additive used to prevent coccidiosis and improve feed efficiency in cattle.":
      "Aditivo para piensos utilizado para prevenir la coccidiosis y mejorar la eficiencia alimenticia en ganado vacuno.",

    "Feed additive to improve feed efficiency and prevent bloat in cattle.":
      "Aditivo para piensos para mejorar la eficiencia alimenticia y prevenir el timpanismo en ganado vacuno.",

    noIncomesOnSelectedPeriod: "No hay ingresos en el período seleccionado",
    noExpensesOnSelectedPeriod: "No hay gastos en el período seleccionado",
    failedToCreateAccount: "No se pudo crear la cuenta",
    deleteAccount: "Eliminar Cuenta",
    deleteAccountConfirmation:
      "¿Estás seguro de que quieres eliminar tu cuenta? Esta acción es irreversible. Perderás todos los datos asociados a tu cuenta.",
    deletingAccount: "Eliminando cuenta...",
    accountDeleted: "¡La cuenta ha sido eliminada!",
    errorOccurredWhileDeletingAccount: "Ocurrió un error al eliminar la cuenta",
    enterYourPasswordToContinue: "Introduce tu contraseña para continuar",
    yourPassword: "Tu contraseña",
    HONEY_SALES: "Venta de Miel",
    POLLINATION_SERVICES: "Servicios de Polinización",
    BEESWAX_SALES: "Venta de Cera de Abeja",
    BEE_COLONY_SALES: "Venta de Colonias de Abejas",
    BEE_FEED: "Alimentación para Abejas",
    QUEEN_PURCHASES: "Compra de Reinas",
    HIVE_TREATMENTS: "Tratamientos de Colmenas",
    BEE_COLONY_PURCHASES: "Compra de Colonias de Abejas",
    HONEY_PACKAGING: "Envasado de Miel",
    "Events related to providing supplemental nutrition to honey bee colonies.":
      "Eventos relacionados con el suministro de nutrición suplementaria a colonias de abejas.",
    "Providing solid candy (like Apifonda) for winter or dearth periods.":
      "Suministro de pasta de azúcar (como Apifonda) para invierno o períodos de escasez.",
    "Feeding with protein supplements to boost brood production.":
      "Alimentación con suplementos proteicos para estimular la producción de cría.",
    "Providing pollen substitutes or supplements.":
      "Suministro de sustitutos o suplementos de polen.",
    "Strategic feeding to stimulate colony growth and activity.":
      "Alimentación estratégica para estimular el crecimiento y la actividad de la colonia.",
    HiveFeeding: "Alimentación de Colmenas",
    CarbohydratesFeeding: "Alimentación con Carbohidratos",
    ProteinFeeding: "Alimentación Proteica",
    PollenSubstitute: "Sustituto de Polen",
    StimulativeFeeding: "Alimentación Estimulante",
    HiveFeedingEventType: "Eventos de Alimentación de Colmenas",
    MyParcels: "Mis Parcelas",
    addNewParcel: "Añadir Nueva Parcela",
    addCoordinates: "Añadir Coordenadas",
    parcelCoordinates: "Coordenadas de la Parcela",
    addParcelCoordinates: "Añadir Coordenadas de la Parcela",
    latitude: "Latitud",
    longitude: "Longitud",
    longitudeX: "Longitud (o X/E)",
    latitudeY: "Latitud (o Y/N)",
    latY: "Latitud (Y/N)",
    longX: "Longitud (X/E)",
    noCoordinatesYet: "Aún no hay coordenadas",
    addPointCoordinates: "Añadir Coordenadas del Punto",
    parcelName: "Nombre de la Parcela",
    parcelDescription: "Descripción de la Parcela",
    creatingParcel: "Creando parcela...",
    parcelCreated: "¡Parcela creada con éxito!",
    parcelCreationError: "Ocurrió un error al crear la parcela",
    noParcelsFound: "No se encontraron parcelas",
    deleteParcel: "Eliminar Parcela",
    deletingParcel: "Eliminando parcela...",
    parcelDeleted: "¡Parcela eliminada con éxito!",
    parcelDeletionError: "Ocurrió un error al eliminar la parcela",
    area: "Área",
    deleteParcelConfirmation:
      "¿Estás seguro de que quieres eliminar esta parcela?",
    deleteParcelDescription:
      "Esta acción es irreversible. Todos los datos asociados con esta parcela se perderán.",
    editParcelInfo: "Editar Información de la Parcela",
    editPointCoordinates: "Editar Coordenadas del Punto",
    deletePoint: "Eliminar Punto",
    deletePointConfirmation:
      "¿Estás seguro de que quieres eliminar este punto?",
    updatingParcel: "Actualizando parcela...",
    parcelUpdated: "¡Parcela actualizada con éxito!",
    parcelUpdateError: "Ocurrió un error al actualizar la parcela",
    parcelMap: "Mapa de la Parcela",
    ScaleAddedWithoutHive: "Báscula añadida directamente",
    "Hive was added directly, without having an associated hive.":
      "La colmena fue añadida directamente, sin tener una colmena asociada.",
    editHiveScale: "Editar Báscula de Colmena",
    parcelView: "Vista de Parcela",
    "How are my bee colonies doing?": "¿Cómo están mis colonias de abejas?",
    "What's the status of my queen bees?":
      "¿Cuál es el estado de mis abejas reinas?",
    "How is my honey production looking?": "¿Cómo se ve mi producción de miel?",
    "What do my hive scale measurements show?":
      "¿Qué muestran las mediciones de la báscula de mis colmenas?",
    "Do my hives need treatments soon?":
      "¿Mis colmenas necesitan tratamientos pronto?",
    "Which apiaries need attention?": "¿Qué apiarios necesitan atención?",
    "Are any of my hives at risk of swarming?":
      "¿Alguna de mis colmenas está en riesgo de enjambrazón?",
    "When should I move my apiaries for better nectar flow?":
      "¿Cuándo debo mover mis apiarios para un mejor flujo de néctar?",
    "Do I need to add or remove supers from my hives?":
      "¿Necesito añadir o quitar alzas de mis colmenas?",
    "Are my hives ready for winter?":
      "¿Están mis colmenas preparadas para el invierno?",

    "How are my bird flocks doing?": "¿Cómo están mis bandadas de aves?",
    "How is my egg production looking?": "¿Cómo se ve mi producción de huevos?",
    "How is the growth of my meat birds progressing?":
      "¿Cómo progresa el crecimiento de mis aves de carne?",
    "Do my birds need any treatments soon?":
      "¿Mis aves necesitan algún tratamiento pronto?",
    "Which bird locations need attention?":
      "¿Qué ubicaciones de aves necesitan atención?",
    "Are any of my bird groups overcrowded?":
      "¿Alguno de mis grupos de aves está superpoblado?",
    "When should I rotate my birds to fresh pasture?":
      "¿Cuándo debo rotar mis aves a pastos frescos?",
    "How is my bird feed consumption looking?":
      "¿Cómo se ve el consumo de alimento de mis aves?",
    "How are my breeding birds performing?":
      "¿Cómo se desempeñan mis aves reproductoras?",
    "Are my birds ready for the upcoming season?":
      "¿Están mis aves preparadas para la próxima temporada?",

    "How is my cattle herd doing?": "¿Cómo está mi rebaño de ganado?",
    "What's the reproductive status of my cows?":
      "¿Cuál es el estado reproductivo de mis vacas?",
    "How is my milk production looking?": "¿Cómo se ve mi producción de leche?",
    "Do my cattle need any treatments soon?":
      "¿Mi ganado necesita algún tratamiento pronto?",
    "What upcoming gestation events do I need to prepare for?":
      "¿Para qué eventos de gestación próximos debo prepararme?",
    "How is the growth of my calves and young stock?":
      "¿Cómo es el crecimiento de mis terneros y ganado joven?",
    "Which cattle locations need attention?":
      "¿Qué ubicaciones de ganado necesitan atención?",
    "How should I adjust my cattle feeding program?":
      "¿Cómo debo ajustar mi programa de alimentación del ganado?",
    "When should I breed my cows?": "¿Cuándo debo reproducir mis vacas?",
    "Which cows are due to calve soon?": "¿Qué vacas están próximas a parir?",
    "How should I manage my newborn calves?":
      "¿Cómo debo manejar mis terneros recién nacidos?",

    "How is my pig herd doing?": "¿Cómo está mi piara de cerdos?",
    "What's the reproductive status of my sows?":
      "¿Cuál es el estado reproductivo de mis cerdas?",
    "Which sows are due to farrow soon?": "¿Qué cerdas están próximas a parir?",
    "How should I manage my nursing piglets?":
      "¿Cómo debo manejar mis lechones lactantes?",
    "Which piglets are ready for weaning?":
      "¿Qué lechones están listos para el destete?",
    "How is the growth performance of my growing pigs?":
      "¿Cómo es el rendimiento de crecimiento de mis cerdos en desarrollo?",
    "Do my pigs need any treatments soon?":
      "¿Mis cerdos necesitan algún tratamiento pronto?",
    "Which pig locations need attention?":
      "¿Qué ubicaciones de cerdos necesitan atención?",
    "How should I adjust my pig feeding program?":
      "¿Cómo debo ajustar mi programa de alimentación de cerdos?",
    "When should I breed my sows?": "¿Cuándo debo reproducir mis cerdas?",
    "Which pigs are ready for market?":
      "¿Qué cerdos están listos para el mercado?",

    "How is my goat herd doing?": "¿Cómo está mi rebaño de cabras?",
    "What's the reproductive status of my does?":
      "¿Cuál es el estado reproductivo de mis cabras?",
    "How is my goat milk production looking?":
      "¿Cómo se ve mi producción de leche de cabra?",
    "Do my goats need any treatments soon?":
      "¿Mis cabras necesitan algún tratamiento pronto?",
    "How should I manage my young kids?":
      "¿Cómo debo manejar mis cabritos jóvenes?",
    "When should I breed my does?": "¿Cuándo debo reproducir mis cabras?",
    "Which does are due to kid soon?": "¿Qué cabras están próximas a parir?",
    "How should I manage parasites in my goat herd?":
      "¿Cómo debo manejar los parásitos en mi rebaño de cabras?",
    "How should I adjust my goat feeding program?":
      "¿Cómo debo ajustar mi programa de alimentación de cabras?",
    "How is my fiber production looking?":
      "¿Cómo se ve mi producción de fibra?",
    "Which goats need hoof trimming?":
      "¿Qué cabras necesitan recorte de pezuñas?",

    "How is my sheep flock doing?": "¿Cómo está mi rebaño de ovejas?",
    "What's the reproductive status of my ewes?":
      "¿Cuál es el estado reproductivo de mis ovejas?",
    "Which ewes are due to lamb soon?": "¿Qué ovejas están próximas a parir?",
    "How should I manage my young lambs?":
      "¿Cómo debo manejar mis corderos jóvenes?",
    "Do my sheep need any treatments soon?":
      "¿Mis ovejas necesitan algún tratamiento pronto?",
    "How should I manage parasites in my sheep flock?":
      "¿Cómo debo manejar los parásitos en mi rebaño de ovejas?",
    "When should I breed my ewes?": "¿Cuándo debo reproducir mis ovejas?",
    "How is my wool production looking?": "¿Cómo se ve mi producción de lana?",
    "How should I adjust my sheep feeding program?":
      "¿Cómo debo ajustar mi programa de alimentación de ovejas?",
    "Which sheep need hoof trimming?":
      "¿Qué ovejas necesitan recorte de pezuñas?",
    "Which lambs are ready for market?":
      "¿Qué corderos están listos para el mercado?",
    rateMessageExceeded: "La limitación de mensajes ha sido excedida",
    assocParcel: "Asociar Parcela",
    noParcelsAssociated: "No hay parcelas asociadas",
    oneParcelAssociated: "1 Parcela Asociada",
    parcelsAssociated: "{{count}} Parcelas Asociadas",
    selectParcels: "Seleccionar Parcelas",
    parcelEvents: "Eventos de Parcela",
    addParcelEvent: "Añadir Evento de Parcela",
    selectParcel: "Seleccionar Parcela",
    parcelEvent: "Evento de Parcela",
    deleteEvent: "Eliminar Evento",
    deleteEventConfirmation: "¿Estás seguro de que deseas eliminar el evento?",
    Irrigation: "Riego",
    "Events related to water management and irrigation systems for crop and land hydration.":
      "Eventos relacionados con la gestión del agua y sistemas de riego para la hidratación de cultivos y tierras.",
    "Pest & Weed Control": "Control de Plagas y Malezas",
    "Activities for managing weeds, pests, and diseases affecting crops and land.":
      "Actividades para gestionar malezas, plagas y enfermedades que afectan a cultivos y tierras.",
    "Soil Management": "Gestión del Suelo",
    "Practices for maintaining or improving soil health, structure, and fertility.":
      "Prácticas para mantener o mejorar la salud, estructura y fertilidad del suelo.",
    "Land Transaction": "Transacción de Terrenos",
    "Events related to buying, selling, leasing, or adjusting land boundaries.":
      "Eventos relacionados con la compra, venta, arrendamiento o ajuste de límites de terrenos.",
    Infrastructure: "Infraestructura",
    "Construction, maintenance, or modification of physical structures on the land.":
      "Construcción, mantenimiento o modificación de estructuras físicas en el terreno.",
    "Miscellaneous parcel management events not classified in other categories.":
      "Eventos diversos de gestión de parcelas no clasificados en otras categorías.",
    "Drip Irrigation": "Riego por Goteo",
    "Application of water directly to plant roots through a low-flow system to minimize water usage.":
      "Aplicación de agua directamente a las raíces de las plantas mediante un sistema de bajo flujo para minimizar el uso de agua.",
    "Sprinkler System": "Sistema de Aspersión",
    "Use of overhead sprinklers to distribute water across the field or parcel.":
      "Uso de aspersores elevados para distribuir agua por todo el campo o parcela.",
    "Flood Irrigation": "Riego por Inundación",
    "Traditional method of flooding fields with water for crop irrigation.":
      "Método tradicional de inundar campos con agua para el riego de cultivos.",
    "Irrigation Maintenance": "Mantenimiento de Riego",
    "Repair, cleaning, or general upkeep of irrigation equipment and systems.":
      "Reparación, limpieza o mantenimiento general de equipos y sistemas de riego.",
    "Water Source Change": "Cambio de Fuente de Agua",
    "Modification of water source used for irrigation, such as well, river, or rainwater collection.":
      "Modificación de la fuente de agua utilizada para riego, como pozo, río o recolección de agua de lluvia.",
    "Irrigation Schedule Change": "Cambio de Programa de Riego",
    "Adjustment to timing or frequency of irrigation events based on crop needs or water availability.":
      "Ajuste en el tiempo o frecuencia de los eventos de riego basado en las necesidades del cultivo o la disponibilidad de agua.",
    "Herbicide Application": "Aplicación de Herbicida",
    "Application of chemical substances to control or eliminate unwanted plants.":
      "Aplicación de sustancias químicas para controlar o eliminar plantas no deseadas.",
    "Pesticide Application": "Aplicación de Pesticida",
    "Use of chemicals to control insects or other pests damaging crops.":
      "Uso de productos químicos para controlar insectos u otras plagas que dañan los cultivos.",
    "Fungicide Application": "Aplicación de Fungicida",
    "Treatment with substances that control fungal diseases in crops.":
      "Tratamiento con sustancias que controlan enfermedades fúngicas en los cultivos.",
    "Manual Weeding": "Deshierbe Manual",
    "Physical removal of weeds by hand or with hand tools.":
      "Eliminación física de malezas a mano o con herramientas manuales.",
    "Soil Testing": "Análisis de Suelo",
    "Analysis of soil composition to assess nutrient levels, pH, and other characteristics.":
      "Análisis de la composición del suelo para evaluar niveles de nutrientes, pH y otras características.",
    "Fertilizer Application": "Aplicación de Fertilizante",
    "Addition of natural or synthetic substances to enrich soil with nutrients.":
      "Adición de sustancias naturales o sintéticas para enriquecer el suelo con nutrientes.",
    Composting: "Compostaje",
    "Creation or application of decomposed organic matter to improve soil structure and fertility.":
      "Creación o aplicación de materia orgánica descompuesta para mejorar la estructura y fertilidad del suelo.",
    "Cover Crop Planting": "Plantación de Cultivos de Cobertura",
    "Growing plants specifically to protect and enrich soil between main crop seasons.":
      "Cultivo de plantas específicamente para proteger y enriquecer el suelo entre temporadas de cultivos principales.",
    "Lime Application": "Aplicación de Cal",
    "Addition of materials containing calcium to neutralize soil acidity and improve structure.":
      "Adición de materiales que contienen calcio para neutralizar la acidez del suelo y mejorar su estructura.",
    Mulching: "Acolchado",
    "Application of material to soil surface to conserve moisture, suppress weeds, and regulate temperature.":
      "Aplicación de material en la superficie del suelo para conservar la humedad, suprimir malezas y regular la temperatura.",
    "Land Purchase": "Compra de Terreno",
    "Acquisition of new land parcels to expand farm operations.":
      "Adquisición de nuevas parcelas de terreno para expandir operaciones agrícolas.",
    "Land Sale": "Venta de Terreno",
    "Transfer of owned land to another party through sale.":
      "Transferencia de terreno en propiedad a otra parte mediante venta.",
    "Fence Installation": "Instalación de Cercado",
    "Construction of new fencing to define boundaries or contain livestock.":
      "Construcción de nuevos cercados para definir límites o contener ganado.",
    "Fence Repair": "Reparación de Cercado",
    "Maintenance or restoration of existing fence structures.":
      "Mantenimiento o restauración de estructuras de cercado existentes.",
    "Building Construction": "Construcción de Edificio",
    "Erection of structures such as barns, sheds, or processing facilities.":
      "Erección de estructuras como graneros, cobertizos o instalaciones de procesamiento.",
    "Weather Damage": "Daño por Clima",
    "Assessment and documentation of damage caused by extreme weather events.":
      "Evaluación y documentación de daños causados por eventos climáticos extremos.",
    "Soil Remediation": "Remediación de Suelo",
    "Processes to remove contaminants or restore degraded soil conditions.":
      "Procesos para eliminar contaminantes o restaurar condiciones de suelo degradado.",
    "Grant Application": "Solicitud de Subvención",
    "Submission of requests for financial assistance from government or private programs.":
      "Presentación de solicitudes de asistencia financiera de programas gubernamentales o privados.",
    cropPlantings: "Cultivos",
    myCropsPlantings: "Mis Cultivos",
    noCropsFound: "No se encontraron cultivos",
    newCropPlanting: "Nuevo Cultivo",
    seedingDate: "Fecha de Siembra",
    selectCrop: "Seleccionar Cultivo",
    plannedHarvestDate: "Fecha Planificada de Cosecha",
    expectedYield: "Rendimiento Esperado",
    savingCropPlanting: "Guardando cultivo...",
    cropPlantingSaved: "¡Cultivo guardado!",
    cropPlantingError: "Ha ocurrido un error al guardar el cultivo",
    cropPlantingDetails: "Detalles del Cultivo",
    editCropPlanting: "Editar Cultivo",
    deleteCropPlanting: "Eliminar Cultivo",
    deleteCropPlantingConfirmation:
      "¿Estás seguro de que quieres eliminar el cultivo?",
    deletingCropPlanting: "Eliminando cultivo...",
    cropPlantingDeleted: "¡Cultivo eliminado!",
    Wheat: "Trigo",
    Barley: "Cebada",
    Potatoes: "Patatas",
    "Sugar Beet": "Remolacha Azucarera",
    "Corn (Maize)": "Maíz",
    Tomatoes: "Tomates",
    Carrots: "Zanahorias",
    Cabbage: "Repollo",
    Onions: "Cebollas",
    Garlic: "Ajo",
    Beans: "Judías",
    Peas: "Guisantes",
    Lettuce: "Lechuga",
    Cucumber: "Pepino",
    Grapes: "Uvas",
    Olives: "Aceitunas",
    Rye: "Centeno",
    Oats: "Avena",
    daysSinceSeeding: "Días desde la Siembra",
    daysUntilHarvest: "Días hasta la Cosecha",
    assocCropPlanting: "Asociar Cultivo",
    seeOtherAssociated: "Ver resto",
    hideOtherAssociated: "Ocultar",
    selectCropPlantings: "Seleccionar Cultivos",
    cropPlantingsAssociated_zero: "Ningún cultivo asociado",
    cropPlantingsAssociated_one: "Un cultivo asociado",
    cropPlantingsAssociated: "{{count}} cultivos asociados",
    parcel: "Parcela",
    actualHarvestDate: "Fecha real de cosecha",
    actualYield: "Rendimiento real",
    notSet: "No establecido",
    cropGrowingDetails: "Detalles de cultivo",
    averageGrowingDays: "Días promedio de crecimiento",
    preferredTemperature: "Temperatura preferida",
    rowSpacing: "Espacio entre filas",
    plantSpacing: "Espacio entre plantas",
    plantingDepth: "Profundidad de siembra",
  },
}
