interface Props {
  number: number
  anchor?: boolean
}

export const MapPinNumber: React.FC<Props> = ({ number, anchor }) => {
  return (
    <div
      className="relative h-8 w-8 flex-shrink-0"
      style={{ transform: anchor ? "translateY(-70%)" : "" }}
    >
      <div className="absolute grid h-8 w-8 rotate-[-45deg] place-content-center rounded-bl-none rounded-br-full rounded-tl-full rounded-tr-full border border-black bg-neutral-100">
        <span className="grid h-6 w-6 rotate-45 place-content-center rounded-full border border-black bg-white text-black">
          {number}
        </span>
      </div>
    </div>
  )
}
