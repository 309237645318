import { Marker } from "@vis.gl/react-maplibre"
import React from "react"
import { useTranslation } from "react-i18next"
import HiveIcon from "@/assets/icons/bees/hive-icon.tsx"
import { LoadingAnimation } from "@/components/loading-animation/loading-animation.tsx"
import NoResultsFound from "@/components/not-found/no-results-found.tsx"
import { useGetHiveScaleByIdQuery } from "@/features/bees/api/hive-scales-api.ts"
import "maplibre-gl/dist/maplibre-gl.css"
import { MapFarmPlanner } from "@/features/bees/components/map-widget/map-farm-planner.tsx"
import WidgetCard from "@/features/home/components/widget-card/widget-card.tsx"

interface Props {
  scaleId: string
}

export const HiveMapWidget: React.FC<Props> = ({ scaleId }) => {
  const { t } = useTranslation()
  const { data, isLoading } = useGetHiveScaleByIdQuery(scaleId)
  const lastMeasurement = data?.last_measurement
  const title = t("hiveLocation")
  const longitude = lastMeasurement?.longitude
  const latitude = lastMeasurement?.latitude

  if (!longitude || !latitude || isLoading) {
    return (
      <WidgetCard title={title}>
        <div className="flex h-96 items-center justify-center">
          {isLoading ? (
            <LoadingAnimation />
          ) : (
            <NoResultsFound
              className={"h-full scale-75"}
              message={t("noLocationData")}
            />
          )}
        </div>
      </WidgetCard>
    )
  }

  return (
    <WidgetCard title={title}>
      <MapFarmPlanner longitude={longitude} latitude={latitude}>
        <Marker longitude={longitude} latitude={latitude}>
          <HiveIcon
            className={"h-10 w-10 rounded border border-black bg-amber-400 p-2"}
          />
        </Marker>
      </MapFarmPlanner>
    </WidgetCard>
  )
}
