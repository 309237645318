import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import AddNewCropIcon from "@/assets/svg/add-new-crop-icon.svg?react"
import { useGetCropsPlantingQuery } from "@/features/crops/api/crops-planting-api.ts"
import { CropPlantingCard } from "@/features/crops/components/crop-planting-card/crop-planting-card.tsx"
import EmptyContentCreateItemButton from "@/features/home/components/empty-content-create-item-button/empty-content-create-item-button.tsx"
import WidgetCard from "@/features/home/components/widget-card/widget-card.tsx"
import {
  ALL_CROPS_PLANTINGS_PATH,
  NEW_CROP_PLANTING_PATH,
} from "@/utils/constants/routes.ts"

interface Props {
  parcelId?: string
}

export const CropPlantingsWidget: React.FC<Props> = ({ parcelId }) => {
  const { t } = useTranslation()
  const { data: cropPlantingsData, isLoading } = useGetCropsPlantingQuery({
    parcel: parcelId,
  })
  const navigate = useNavigate()
  const noDataState = !isLoading && cropPlantingsData?.length === 0

  const newCropNavigationString = `${NEW_CROP_PLANTING_PATH}${
    parcelId ? `?parcelId=${parcelId}` : ""
  }`

  return (
    <WidgetCard
      title={t("cropPlantings")}
      navigateTo={ALL_CROPS_PLANTINGS_PATH}
      icon={<AddNewCropIcon />}
      addItemGoTo={
        cropPlantingsData && cropPlantingsData?.length > 0
          ? () => navigate(newCropNavigationString)
          : undefined
      }
    >
      <div className={"space-y-4"}>
        {!isLoading &&
          cropPlantingsData &&
          cropPlantingsData.map((cropPlanting, index) => (
            <CropPlantingCard cropPlanting={cropPlanting} key={index} />
          ))}
        {noDataState && (
          <EmptyContentCreateItemButton navigateTo={newCropNavigationString} />
        )}
      </div>
    </WidgetCard>
  )
}
