import { useTranslation } from "react-i18next"
import { LoadingAnimation } from "@/components/loading-animation/loading-animation.tsx"
import NoResultsFound from "@/components/not-found/no-results-found.tsx"
import PageContentWrapperLarge from "@/components/page-content-wrappers/page-content-wrapper-large.tsx"
import MobileTopBar from "@/components/top-bars/mobile-top-bar/mobile-top-bar.tsx"
import { useGetCropsPlantingQuery } from "@/features/crops/api/crops-planting-api.ts"
import { CropPlantingCard } from "@/features/crops/components/crop-planting-card/crop-planting-card.tsx"
import { NEW_CROP_PLANTING_PATH } from "@/utils/constants/routes.ts"

const AllCropsPlantings = () => {
  const { t } = useTranslation()
  const { data, isLoading } = useGetCropsPlantingQuery({})

  const noDataState = (!data || data.length === 0) && !isLoading

  return (
    <>
      <MobileTopBar
        title={t("myCropsPlantings")}
        navigateTo={NEW_CROP_PLANTING_PATH}
      />
      <PageContentWrapperLarge className={"flex flex-col gap-3"}>
        {isLoading && (
          <LoadingAnimation
            customStyle={{
              height: "calc(100vh - 60px)",
            }}
          />
        )}
        {noDataState && (
          <NoResultsFound
            image={"location"}
            imageClassName={"scale-50"}
            message={"noCropsFound"}
          />
        )}
        {!isLoading &&
          !noDataState &&
          data?.map((cropPlanting) => (
            <CropPlantingCard
              cropPlanting={cropPlanting}
              key={cropPlanting.id}
            />
          ))}
      </PageContentWrapperLarge>
    </>
  )
}

export default AllCropsPlantings
