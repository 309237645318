import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts"
import { HiveScaleChartSkeleton } from "@/features/bees/components/hive-scales/hive-scale-charts/hive-scale-skeleton-chart.tsx"
import {
  generateChartColors,
  getTickInterval,
  getTimeAxisConfig,
} from "@/features/bees/components/hive-scales/hive-scale-charts/utils.ts"
import { type DataPoint } from "@/features/bees/types/hive-scales.ts"

interface Props {
  data: DataPoint[]
  color: string
  title: string
  measureUnit?: string
  period: string
  isLoading?: boolean
}

export const HiveScaleGenericChart: React.FC<Props> = ({
  data,
  color,
  title,
  measureUnit,
  period,
  isLoading,
}) => {
  const colors = generateChartColors(color)
  const minValue = Math.floor(Math.min(...data.map((d) => d.value)) - 0.2)
  const maxValue = Math.ceil(Math.max(...data.map((d) => d.value)) + 0.2)
  const timeConfig = getTimeAxisConfig(period)

  const formatFunction = (value: number): string => {
    if (measureUnit) return `${value} ${measureUnit}`
    return `${value}`
  }

  if (isLoading) {
    return <HiveScaleChartSkeleton />
  }

  return (
    <ResponsiveContainer width="100%" height="100%">
      <AreaChart data={data} margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
        <CartesianGrid stroke={colors.grid} />
        <XAxis
          dataKey="timestamp"
          tickFormatter={timeConfig.format}
          className="text-sm text-muted-foreground"
          tick={{ fill: "hsl(var(--muted-foreground))" }}
          interval={getTickInterval(data.length, period)}
          padding={{ right: 20, left: 20 }}
        />
        <YAxis
          className="text-sm text-muted-foreground"
          tick={{ fill: "hsl(var(--muted-foreground))" }}
          tickFormatter={formatFunction}
          domain={[minValue, maxValue]}
          width={80}
          mirror={true}
          padding={{ bottom: 20 }}
        />
        <Tooltip
          labelFormatter={timeConfig.formatTooltip}
          formatter={(value: number) => [
            `${value.toFixed(1)}${measureUnit}`,
            title,
          ]}
        />
        <defs>
          <linearGradient id={title} x1="0" y1="0" x2="0" y2="1">
            <stop
              offset="5%"
              stopColor={colors.gradientStart}
              stopOpacity={colors.gradientStartOpacity}
            />
            <stop
              offset="95%"
              stopColor={colors.gradientEnd}
              stopOpacity={colors.gradientEndOpacity}
            />
          </linearGradient>
        </defs>
        <Area
          type="monotone"
          dataKey="value"
          stroke={colors.stroke}
          fill={`url(#${title})`}
          fillOpacity={0.2}
          dot={<CustomDot color={color} data={data} />}
        />
      </AreaChart>
    </ResponsiveContainer>
  )
}

const CustomDot = (props: any) => {
  const { cx, cy, index, data, color } = props
  if (index === data?.length - 1) {
    return (
      <g>
        <circle cx={cx} cy={cy} r={6} fill="white" filter="url(#glow)" />
        <circle cx={cx} cy={cy} r={4} fill={color} />
      </g>
    )
  }
  return null
}
