import { useTranslation } from "react-i18next"
import NoResultsFound from "@/components/not-found/no-results-found.tsx"
import { useGetHiveScaleStatisticsQuery } from "@/features/bees/api/hive-scales-api.ts"
import { HiveScaleGenericChart } from "@/features/bees/components/hive-scales/hive-scale-charts/hive-scale-generic-chart.tsx"
import {
  getMetricConfig,
  type HIVE_SCALE_METRICS,
  HIVE_SCALE_PERIODS,
} from "@/features/bees/types/hive-scales.ts"
import WidgetCard from "@/features/home/components/widget-card/widget-card.tsx"
import { HIVE_MEASUREMENT_VIEW_DETAIL } from "@/utils/constants/routes.ts"

interface Props {
  scaleId: string
  metric: HIVE_SCALE_METRICS
}

export const HiveMeasurementWidget: React.FC<Props> = ({ scaleId, metric }) => {
  const { t } = useTranslation()
  const period = HIVE_SCALE_PERIODS.DAY
  const navigateTo = HIVE_MEASUREMENT_VIEW_DETAIL.replace(
    ":hiveScaleId",
    scaleId,
  ).replace(":metric", metric)
  const config = getMetricConfig(metric as HIVE_SCALE_METRICS)

  const { data: statistics } = useGetHiveScaleStatisticsQuery(
    {
      scaleId: scaleId!,
      metric: metric,
      period: period,
    },
    {
      skip: !scaleId,
    },
  )

  return (
    <WidgetCard
      title={t(metric)}
      navigateTo={
        statistics && statistics.data_points.length > 0 ? navigateTo : undefined
      }
    >
      <div className="h-[300px] w-full">
        {statistics?.data_points.length ? (
          <HiveScaleGenericChart
            data={statistics?.data_points || []}
            color={config.color}
            title={t(metric)}
            measureUnit={config.unit}
            period={period}
          />
        ) : (
          <div className={"grid h-full place-content-center"}>
            <NoResultsFound
              image={"hive"}
              message={t("noMeasurementsRecorded")}
              className={"h-full scale-75"}
            />
          </div>
        )}
      </div>
    </WidgetCard>
  )
}
