import { PlusCircle } from "lucide-react"
import { useState, useRef } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import GenericTypeDrawerHeader from "@/components/generic-type-drawer/generic-type-drawer-header/generic-type-drawer-header.tsx"
import { LoadingAnimation } from "@/components/loading-animation/loading-animation.tsx"
import FieldPolygon from "@/components/maps-components/polygon.tsx"
import PageContentWrapperLarge from "@/components/page-content-wrappers/page-content-wrapper-large.tsx"
import { Button } from "@/components/shadcn/button.tsx"
import {
  Drawer,
  DrawerContent,
  DrawerTrigger,
} from "@/components/shadcn/drawer.tsx"
import { Input } from "@/components/shadcn/input.tsx"
import { Label } from "@/components/shadcn/label.tsx"
import { Textarea } from "@/components/shadcn/textarea.tsx"
import EditorTopBar from "@/components/top-bars/editor-top-bar/editor-top-bar.tsx"
import MapFarmPlanner, {
  type MapFarmPlannerHandle,
} from "@/features/bees/components/map-widget/map-farm-planner.tsx"
import { useCreateParcelMutation } from "@/features/parcels/api/parcels-api.ts"
import { CoordinateListUpdate } from "@/features/parcels/components/coodinate-card/coordinate-list-update.tsx"
import { useGetMapCenter } from "@/features/parcels/hooks/parcels-hooks.ts"
import {
  type ParcelCoordinate,
  type ParcelCreate,
  parcelCreateDefault,
} from "@/features/parcels/types/parcel.ts"
import { PARCELS_SCREEN_PATH } from "@/utils/constants/routes.ts"

const NewParcelView = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [createParcel] = useCreateParcelMutation()
  const [parcelCreate, setParcelCreate] =
    useState<ParcelCreate>(parcelCreateDefault)
  const [coordinates, setCoordinates] = useState<ParcelCoordinate[]>([])
  const { mapCenter, isLoading } = useGetMapCenter(coordinates)
  const [currentLatitude, setCurrentLatitude] = useState<string>("")
  const [currentLongitude, setCurrentLongitude] = useState<string>("")

  const mapRef = useRef<MapFarmPlannerHandle>(null)

  const hasChanges = Boolean(
    parcelCreate.name && parcelCreate.description && coordinates.length > 2,
  )

  const handleSaveParcel = async () => {
    if (!hasChanges) return

    try {
      let field = {
        ...parcelCreate,
        boundary_points: coordinates.map((coord, index) => ({
          latitude: coord.latitude,
          longitude: coord.longitude,
          sequence: index,
        })),
      }

      if (mapRef.current) {
        try {
          const imageDataUrl = await mapRef.current.takeSnapshot()

          field = {
            ...field,
            picture: imageDataUrl,
          }
        } catch (error) {
          console.error("Failed to capture map snapshot:", error)
        }
      }

      const promise = createParcel(field).unwrap()

      toast
        .promise(promise, {
          pending: t("creatingParcel"),
          success: t("parcelCreated"),
          error: t("parcelCreationError"),
        })
        .then(() => {
          navigate(PARCELS_SCREEN_PATH, { replace: true })
        })
    } catch (error) {
      console.error("Error creating parcel:", error)
    }
  }

  if (isLoading || !mapCenter) {
    return (
      <>
        <EditorTopBar text={t("addNewParcel")} isModified={true} />
        <PageContentWrapperLarge className={"space-y-4"}>
          <LoadingAnimation customStyle={{ height: "inherit" }} />
        </PageContentWrapperLarge>
      </>
    )
  }

  const handleSaveCoordinate = () => {
    const lat = parseFloat(currentLatitude)
    const lng = parseFloat(currentLongitude)
    setCoordinates([...coordinates, { latitude: lat, longitude: lng }])
    setCurrentLatitude("")
    setCurrentLongitude("")
  }

  return (
    <>
      <EditorTopBar
        text={t("addNewParcel")}
        onSave={handleSaveParcel}
        isModified={hasChanges}
      />
      <PageContentWrapperLarge className={"space-y-4"}>
        <div className={"widget-border w-full space-y-4 bg-white p-4"}>
          <div className={"space-y-2.5"}>
            <Label htmlFor="parcelName" required={!parcelCreate.name}>
              {t("parcelName")}
            </Label>
            <Input
              id="parcelName"
              type="text"
              value={parcelCreate.name}
              onChange={(e) =>
                setParcelCreate({ ...parcelCreate, name: e.target.value })
              }
              placeholder={t("parcelName")}
            />
          </div>

          <div className={"space-y-2.5"}>
            <Label
              required={!parcelCreate.description}
              htmlFor="parcelDescription"
            >
              {t("parcelDescription")}
            </Label>
            <Textarea
              rows={4}
              value={parcelCreate.description}
              onChange={(e) =>
                setParcelCreate({
                  ...parcelCreate,
                  description: e.target.value,
                })
              }
              id="parcelDescription"
              placeholder={t("parcelDescription")}
            />
          </div>
        </div>

        <MapFarmPlanner
          ref={mapRef}
          latitude={mapCenter.latitude}
          longitude={mapCenter.longitude}
          pitch={0}
          bearing={0}
        >
          <FieldPolygon coordinates={coordinates} showMarkers={true} />
        </MapFarmPlanner>

        <Drawer>
          <DrawerTrigger asChild>
            <Button size={"lg"} className={"w-full gap-3"}>
              <PlusCircle size={24} />
              {t("addCoordinates")}
            </Button>
          </DrawerTrigger>
          <DrawerContent className={"h-3/4"}>
            <GenericTypeDrawerHeader
              title={t("addPointCoordinates")}
              onSaveClick={
                currentLatitude && currentLongitude
                  ? handleSaveCoordinate
                  : undefined
              }
            />
            <div className="space-y-4 p-4">
              <div className="space-y-2">
                <Label htmlFor="latitude">{t("latitudeY")}</Label>
                <Input
                  id="latitude"
                  type="number"
                  step="0.000001"
                  placeholder="ex. 51.507351"
                  value={currentLatitude}
                  onChange={(e) => setCurrentLatitude(e.target.value)}
                />
              </div>

              <div className="space-y-2">
                <Label htmlFor="longitude">{t("longitudeX")}</Label>
                <Input
                  id="longitude"
                  type="number"
                  step="0.000001"
                  placeholder="ex. 0.127758"
                  value={currentLongitude}
                  onChange={(e) => setCurrentLongitude(e.target.value)}
                />
              </div>
            </div>
          </DrawerContent>
        </Drawer>

        <CoordinateListUpdate
          coordinates={coordinates}
          onUpdateCoordinate={(index, coordinate) => {
            const newCoordinates = [...coordinates]
            newCoordinates[index] = coordinate
            setCoordinates(newCoordinates)
          }}
          onDeleteCoordinate={(index) => {
            const newCoordinates = coordinates.filter((_, i) => i !== index)
            setCoordinates(newCoordinates)
          }}
        />
      </PageContentWrapperLarge>
    </>
  )
}

export default NewParcelView
