import { useState } from "react"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import GenericTypeDrawerHeader from "@/components/generic-type-drawer/generic-type-drawer-header/generic-type-drawer-header.tsx"
import {
  Drawer,
  DrawerContent,
  DrawerTrigger,
} from "@/components/shadcn/drawer.tsx"
import {
  useCreateHiveScaleMutation,
  useUpdateHiveScaleMutation,
} from "@/features/bees/api/hive-scales-api.ts"
import { HiveScaleEditor } from "@/features/bees/components/hive-scales/hive-scale-editor/hive-scale-editor.tsx"
import {
  HIVE_SCALE_INPUT_DEFAULTS,
  type HiveScale,
  type HiveScaleInput,
} from "@/features/bees/types/hive-scales.ts"

interface Props {
  trigger: React.ReactNode
  hive?: string
  hiveScale?: HiveScale
}

export const CreateUpdateHiveScaleDrawer: React.FC<Props> = ({
  trigger,
  hive,
  hiveScale,
}) => {
  const { t } = useTranslation()
  const [newHiveScale, setNewHiveScale] = useState<HiveScaleInput>(
    (hiveScale && {
      hive: hiveScale.hive?.id || "",
      phone_number: hiveScale.phone_number || "",
    }) || {
      ...HIVE_SCALE_INPUT_DEFAULTS,
      hive: hive || "",
    },
  )

  const [createHiveScale] = useCreateHiveScaleMutation()
  const [updateHiveScale] = useUpdateHiveScaleMutation()

  const changesForCreate =
    newHiveScale.hive !== HIVE_SCALE_INPUT_DEFAULTS.hive ||
    newHiveScale.phone_number !== HIVE_SCALE_INPUT_DEFAULTS.phone_number

  const changesForPhoneNumberUpdate =
    newHiveScale.phone_number !== hiveScale?.phone_number
  const hasChanges = hiveScale ? changesForPhoneNumberUpdate : changesForCreate

  const handleSave = async () => {
    // update hive scale
    if (hiveScale && newHiveScale.phone_number) {
      toast.promise(
        updateHiveScale({ ...newHiveScale, id: hiveScale.id }).unwrap(),
        {
          pending: t("updatingHiveScale"),
          success: t("hiveScaleUpdated"),
          error: t("errorUpdatingHiveScale"),
        },
      )
      // create hive scale
    } else if (newHiveScale.phone_number) {
      toast
        .promise(createHiveScale(newHiveScale).unwrap(), {
          pending: t("creatingHiveScale"),
          success: t("hiveScaleCreated"),
          error: t("errorCreatingHiveScale"),
        })
        .then(() => {
          setNewHiveScale(HIVE_SCALE_INPUT_DEFAULTS)
        })
    }
  }

  return (
    <Drawer shouldScaleBackground={true}>
      <DrawerTrigger asChild>{trigger}</DrawerTrigger>
      <DrawerContent className={"h-3/4"}>
        <GenericTypeDrawerHeader
          title={hiveScale ? t("editHiveScale") : t("addHiveScale")}
          onSaveClick={hasChanges ? handleSave : undefined}
        />
        <HiveScaleEditor
          hasHiveId={!!hive || !!hiveScale?.hive}
          hiveScale={newHiveScale}
          onHiveScaleChange={setNewHiveScale}
        />
      </DrawerContent>
    </Drawer>
  )
}
