import { type ParcelCoordinate } from "@/features/parcels/types/parcel.ts"
import { useGetMyFarmQuery } from "@/features/settings-account/api/account-api.ts"

export const useGetMapCenter = (
  coordinates: ParcelCoordinate[],
): {
  isLoading: boolean
  mapCenter: ParcelCoordinate | undefined
} => {
  const { data, isLoading } = useGetMyFarmQuery()

  if (isLoading || !data) {
    return {
      isLoading,
      mapCenter: undefined,
    }
  }

  if (coordinates.length === 0) {
    return {
      isLoading,
      mapCenter: {
        latitude: Number(data.address.latitude),
        longitude: Number(data.address.longitude),
      },
    }
  }

  if (coordinates.length === 1) {
    return {
      isLoading,
      mapCenter: {
        latitude: coordinates[0].latitude,
        longitude: coordinates[0].longitude,
      },
    }
  }

  const sumLat = coordinates.reduce((sum, coord) => sum + coord.latitude, 0)
  const sumLng = coordinates.reduce((sum, coord) => sum + coord.longitude, 0)

  return {
    isLoading,
    mapCenter: {
      latitude: sumLat / coordinates.length,
      longitude: sumLng / coordinates.length,
    },
  }
}
