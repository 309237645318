import { type Locale } from "date-fns"
import { enUS, fr, ro, pl, es } from "date-fns/locale"

export const LOCALE_MAP: Record<string, Locale> = {
  en: enUS,
  fr: fr,
  ro: ro,
  pl: pl,
  es: es,
}

export const LOCALES = Object.keys(LOCALE_MAP)

export const getLocaleFromLanguage = (language: string): Locale => {
  const baseLanguage = language.split("-")[0].toLowerCase()
  return LOCALE_MAP[baseLanguage] || enUS
}
