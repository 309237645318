import baby_goat_img from "@/assets/my-farm-widget/baby-goat.png"
import bee_img from "@/assets/my-farm-widget/bee.png"
import calf_img from "@/assets/my-farm-widget/calf.png"
import chicken_img from "@/assets/my-farm-widget/chicken.png"
import cock_img from "@/assets/my-farm-widget/cock.png"
import cow_img from "@/assets/my-farm-widget/cow.png"
import female_goat from "@/assets/my-farm-widget/female-goat.png"
import female_pork_img from "@/assets/my-farm-widget/female-pork.png"
import heifer_img from "@/assets/my-farm-widget/heifer.png"
import hen_img from "@/assets/my-farm-widget/hen.png"
import lamb_img from "@/assets/my-farm-widget/lamb.png"
import male_goat_img from "@/assets/my-farm-widget/male-goat.png"
import male_pork_img from "@/assets/my-farm-widget/male-pork.png"
import male_sheep_img from "@/assets/my-farm-widget/male-sheep.png"
import pork_img from "@/assets/my-farm-widget/pork.png"
import sheep_img from "@/assets/my-farm-widget/sheep.png"
import {
  ANIMAL_GENDERS,
  ANIMAL_SPECIES_ENUM,
  ANIMAL_SPECIES_NAME_TRANSLATION_ENUM,
  getAnimalsByAgeRange,
  getNumberOfAnimalsByAgeRange,
  type Animal,
  type AnimalLifeSpan,
} from "@/features/farm"
import {
  CALF_AGE,
  CHICKEN_AGE,
  FILTER_AGE,
  HEIFER_AGE,
  LAMB_AGE,
  MATURE_CHICKEN_AGE,
  MATURE_COW_AGE,
  MATURE_GOAT_AGE,
  MATURE_PORK_AGE,
  MATURE_SHEEP_AGE,
  PIGLET_AGE,
  YOUNG_GOAT_AGE,
} from "@/features/farm/types/animal-ages-constants.ts"

export interface FarmSpeciesObject {
  imgSrc: string
  name: string
  background: string
  count: number
  total: number
  gender?: string
  species?: string
  age?: AnimalLifeSpan
}

export enum ANIMALS_IMAGE_CARD_BACKGROUND_COLOR {
  CALF0 = "#EDCDAF",
  CALF1 = "#EEC7A3",
  HEIFER0 = "#B98663",
  HEIFER1 = "#AB7854",
  COW0 = "#A6674C",
  COW1 = "#9A5B40",
  PIGLET0 = "#EDBAAF",
  PIGLET1 = "#EEACA3",
  FEMALE_PIG0 = "#dea496",
  FEMALE_PIG1 = "#EEACA3",
  MALE_PIG0 = "#d7a89e",
  MALE_PIG1 = "#dc9c94",
  GOAT0 = "#95DAEC",
  GOAT1 = "#7CC5D8",
  SHEEP0 = "#AFE9ED",
  SHEEP1 = "#9BE4E8",
  LAMB0 = "#CDFCFF",
  LAMB1 = "#A9DCEC",
  BEEF0 = "#FADE79",
  BEEF1 = "#F3C257",
  COCOS0 = "#9ED07F",
  COCOS1 = "#5EAC51",
  HEN0 = "#66DA9C",
  HEN1 = "#4EB270",
  CHICKEN0 = "#B0C86B",
  CHICKEN1 = "#7EA54D",
}

export const getRadialBackground = (color0: string, color1: string) => {
  return `radial-gradient(circle,${color0},${color1})`
}

export const getSheepWidgetSummary = (animals: Animal[]) => {
  const filteredAnimals = animals.filter(
    (animal) => animal?.species_details?.name === ANIMAL_SPECIES_ENUM.SHEEP,
  )

  const total = filteredAnimals.length

  const age01 = getNumberOfAnimalsByAgeRange(filteredAnimals, LAMB_AGE)
  const age13 = getAnimalsByAgeRange(filteredAnimals, MATURE_SHEEP_AGE)

  const maleSheep = age13.filter(
    (sheep) => sheep.gender === ANIMAL_GENDERS.MALE.label,
  )
  const femaleSheep = age13.filter(
    (sheep) => sheep.gender === ANIMAL_GENDERS.FEMALE.label,
  )

  const lambObject: FarmSpeciesObject = {
    imgSrc: lamb_img,
    name: ANIMAL_SPECIES_NAME_TRANSLATION_ENUM.LAMB,
    background: getRadialBackground(
      ANIMALS_IMAGE_CARD_BACKGROUND_COLOR.LAMB0,
      ANIMALS_IMAGE_CARD_BACKGROUND_COLOR.LAMB1,
    ),
    count: age01,
    total: total,
    gender: ANIMAL_GENDERS.ALL_GENDERS.label,
    species: ANIMAL_SPECIES_ENUM.SHEEP,
    age: LAMB_AGE,
  }

  const femaleSheepObject: FarmSpeciesObject = {
    imgSrc: sheep_img,
    name: ANIMAL_SPECIES_NAME_TRANSLATION_ENUM.FEMALE_SHEEP,
    background: getRadialBackground(
      ANIMALS_IMAGE_CARD_BACKGROUND_COLOR.SHEEP0,
      ANIMALS_IMAGE_CARD_BACKGROUND_COLOR.SHEEP1,
    ),
    count: femaleSheep.length,
    total: total,
    gender: ANIMAL_GENDERS.FEMALE.label,
    species: ANIMAL_SPECIES_ENUM.SHEEP,
    age: MATURE_SHEEP_AGE,
  }

  const maleSheepObject: FarmSpeciesObject = {
    imgSrc: male_sheep_img,
    name: ANIMAL_SPECIES_NAME_TRANSLATION_ENUM.RAM,
    background: getRadialBackground(
      ANIMALS_IMAGE_CARD_BACKGROUND_COLOR.SHEEP0,
      ANIMALS_IMAGE_CARD_BACKGROUND_COLOR.SHEEP1,
    ),
    count: maleSheep.length,
    total: total,
    gender: ANIMAL_GENDERS.MALE.label,
    species: ANIMAL_SPECIES_ENUM.SHEEP,
    age: MATURE_SHEEP_AGE,
  }

  const animalsSummary = [lambObject, femaleSheepObject, maleSheepObject]
  return animalsSummary.filter((animal) => animal.count)
}

export const getPigsWidgetSummary = (animals: Animal[]) => {
  const filteredAnimals = animals.filter(
    (animal) => animal?.species_details?.name === ANIMAL_SPECIES_ENUM.PORK,
  )

  const total = filteredAnimals.length

  const piglets = getNumberOfAnimalsByAgeRange(filteredAnimals, PIGLET_AGE)
  const maturePigs = getAnimalsByAgeRange(filteredAnimals, MATURE_PORK_AGE)
  const malePigs = maturePigs.filter(
    (pig) => pig.gender === ANIMAL_GENDERS.MALE.label,
  )
  const femalePigs = maturePigs.filter(
    (pig) => pig.gender === ANIMAL_GENDERS.FEMALE.label,
  )

  const pigletObject: FarmSpeciesObject = {
    imgSrc: pork_img,
    name: ANIMAL_SPECIES_NAME_TRANSLATION_ENUM.PIGLET,
    background: getRadialBackground(
      ANIMALS_IMAGE_CARD_BACKGROUND_COLOR.PIGLET0,
      ANIMALS_IMAGE_CARD_BACKGROUND_COLOR.PIGLET1,
    ),
    count: piglets,
    total: total,
    gender: ANIMAL_GENDERS.ALL_GENDERS.label,
    species: ANIMAL_SPECIES_ENUM.PORK,
    age: PIGLET_AGE,
  }

  const malePigObject: FarmSpeciesObject = {
    imgSrc: male_pork_img,
    name: ANIMAL_SPECIES_NAME_TRANSLATION_ENUM.MALE_PIG,
    background: getRadialBackground(
      ANIMALS_IMAGE_CARD_BACKGROUND_COLOR.MALE_PIG0,
      ANIMALS_IMAGE_CARD_BACKGROUND_COLOR.MALE_PIG1,
    ),
    count: malePigs.length,
    total: total,
    gender: ANIMAL_GENDERS.MALE.label,
    species: ANIMAL_SPECIES_ENUM.PORK,
    age: MATURE_PORK_AGE,
  }

  const femalePigObject: FarmSpeciesObject = {
    imgSrc: female_pork_img,
    name: ANIMAL_SPECIES_NAME_TRANSLATION_ENUM.FEMALE_PIG,
    background: getRadialBackground(
      ANIMALS_IMAGE_CARD_BACKGROUND_COLOR.FEMALE_PIG0,
      ANIMALS_IMAGE_CARD_BACKGROUND_COLOR.FEMALE_PIG1,
    ),
    count: femalePigs.length,
    total: total,
    gender: ANIMAL_GENDERS.FEMALE.label,
    species: ANIMAL_SPECIES_ENUM.PORK,
    age: MATURE_PORK_AGE,
  }

  const animalsSummary = [pigletObject, femalePigObject, malePigObject]
  return animalsSummary.filter((animal) => animal.count)
}

export const getPoultryWidgetSummary = (animals: Animal[]) => {
  const filteredAnimals = animals.filter(
    (animal) => animal?.species_details?.name === ANIMAL_SPECIES_ENUM.BIRD,
  )

  const total = filteredAnimals.length

  const age005 = getNumberOfAnimalsByAgeRange(filteredAnimals, CHICKEN_AGE)
  const age051 = getAnimalsByAgeRange(filteredAnimals, MATURE_CHICKEN_AGE)

  const maleChicken = age051.filter(
    (animal) => (animal.gender = ANIMAL_GENDERS.MALE.label),
  )

  const femaleChicken = age051.filter(
    (animal) => (animal.gender = ANIMAL_GENDERS.FEMALE.label),
  )

  const chicken: FarmSpeciesObject = {
    imgSrc: chicken_img,
    name: ANIMAL_SPECIES_NAME_TRANSLATION_ENUM.CHICKEN,
    background: getRadialBackground(
      ANIMALS_IMAGE_CARD_BACKGROUND_COLOR.CHICKEN0,
      ANIMALS_IMAGE_CARD_BACKGROUND_COLOR.CHICKEN1,
    ),
    count: age005,
    total: animals.length,
    age: CHICKEN_AGE,
  }

  const hen: FarmSpeciesObject = {
    imgSrc: hen_img,
    name: ANIMAL_SPECIES_NAME_TRANSLATION_ENUM.HEN,
    background: getRadialBackground(
      ANIMALS_IMAGE_CARD_BACKGROUND_COLOR.HEN0,
      ANIMALS_IMAGE_CARD_BACKGROUND_COLOR.HEN1,
    ),
    count: femaleChicken.length,
    total: total,
    age: MATURE_CHICKEN_AGE,
  }

  const cock: FarmSpeciesObject = {
    imgSrc: cock_img,
    name: ANIMAL_SPECIES_NAME_TRANSLATION_ENUM.COCK,
    background: getRadialBackground(
      ANIMALS_IMAGE_CARD_BACKGROUND_COLOR.COCOS0,
      ANIMALS_IMAGE_CARD_BACKGROUND_COLOR.COCOS1,
    ),
    count: maleChicken.length,
    total: total,
    age: MATURE_CHICKEN_AGE,
  }

  const animalsSummary = [chicken, hen, cock]

  return animalsSummary.filter((animal) => animal.count)
}

export const getBeesWidgetSummary = (animals: Animal[]) => {
  const filteredAnimals = animals.filter(
    (animal) => animal?.species_details?.name === ANIMAL_SPECIES_ENUM.BEE,
  )

  const total = filteredAnimals.length

  const bees: FarmSpeciesObject = {
    imgSrc: bee_img,
    name: ANIMAL_SPECIES_NAME_TRANSLATION_ENUM.BEE,
    background: getRadialBackground(
      ANIMALS_IMAGE_CARD_BACKGROUND_COLOR.BEEF0,
      ANIMALS_IMAGE_CARD_BACKGROUND_COLOR.BEEF1,
    ),
    count: filteredAnimals.length,
    total: total,
    age: FILTER_AGE,
  }

  return [bees].filter((bee) => bee.count)
}

export const getGoatsWidgetSummary = (animals: Animal[]) => {
  const filteredAnimals = animals.filter(
    (animal) => animal?.species_details?.name === ANIMAL_SPECIES_ENUM.GOAT,
  )

  const total = filteredAnimals.length

  const youngGoats = getNumberOfAnimalsByAgeRange(
    filteredAnimals,
    YOUNG_GOAT_AGE,
  )
  const adultGoats = getAnimalsByAgeRange(filteredAnimals, MATURE_GOAT_AGE)

  const maleGoats = adultGoats.filter(
    (goat) => goat.gender === ANIMAL_GENDERS.MALE.label,
  )
  const femaleGoats = adultGoats.filter(
    (goat) => goat.gender === ANIMAL_GENDERS.FEMALE.label,
  )

  const youngGoatObject: FarmSpeciesObject = {
    imgSrc: baby_goat_img,
    name: ANIMAL_SPECIES_NAME_TRANSLATION_ENUM.YOUNG_GOAT,
    background: getRadialBackground(
      ANIMALS_IMAGE_CARD_BACKGROUND_COLOR.GOAT0,
      ANIMALS_IMAGE_CARD_BACKGROUND_COLOR.GOAT1,
    ),
    count: youngGoats,
    total: total,
    gender: ANIMAL_GENDERS.ALL_GENDERS.label,
    species: ANIMAL_SPECIES_ENUM.GOAT,
    age: YOUNG_GOAT_AGE,
  }

  const maleGoatObject: FarmSpeciesObject = {
    imgSrc: male_goat_img,
    name: ANIMAL_SPECIES_NAME_TRANSLATION_ENUM.MALE_GOAT,
    background: getRadialBackground(
      ANIMALS_IMAGE_CARD_BACKGROUND_COLOR.GOAT0,
      ANIMALS_IMAGE_CARD_BACKGROUND_COLOR.GOAT1,
    ),
    count: maleGoats.length,
    total: total,
    gender: ANIMAL_GENDERS.MALE.label,
    species: ANIMAL_SPECIES_ENUM.GOAT,
    age: MATURE_GOAT_AGE,
  }

  const femaleGoatObject: FarmSpeciesObject = {
    imgSrc: female_goat,
    name: ANIMAL_SPECIES_NAME_TRANSLATION_ENUM.FEMALE_GOAT,
    background: getRadialBackground(
      ANIMALS_IMAGE_CARD_BACKGROUND_COLOR.GOAT0,
      ANIMALS_IMAGE_CARD_BACKGROUND_COLOR.GOAT1,
    ),
    count: femaleGoats.length,
    total: total,
    gender: ANIMAL_GENDERS.FEMALE.label,
    species: ANIMAL_SPECIES_ENUM.GOAT,
    age: MATURE_GOAT_AGE,
  }

  const animalsSummary = [youngGoatObject, maleGoatObject, femaleGoatObject]
  return animalsSummary.filter((animal) => animal.count)
}

export const getCowsWidgetSummary = (animals: Animal[]) => {
  const filteredAnimals = animals.filter(
    (animal) => animal?.species_details?.name === ANIMAL_SPECIES_ENUM.COW,
  )

  const total = filteredAnimals.length

  const calfs = getNumberOfAnimalsByAgeRange(filteredAnimals, CALF_AGE)
  const heifers = getNumberOfAnimalsByAgeRange(filteredAnimals, HEIFER_AGE)
  const cows = getNumberOfAnimalsByAgeRange(filteredAnimals, MATURE_COW_AGE)

  const calf: FarmSpeciesObject = {
    imgSrc: calf_img,
    name: ANIMAL_SPECIES_NAME_TRANSLATION_ENUM.CALF,
    background: getRadialBackground(
      ANIMALS_IMAGE_CARD_BACKGROUND_COLOR.CALF0,
      ANIMALS_IMAGE_CARD_BACKGROUND_COLOR.CALF1,
    ),
    count: calfs,
    total: total,
    gender: ANIMAL_GENDERS.ALL_GENDERS.label,
    species: ANIMAL_SPECIES_ENUM.COW,
    age: CALF_AGE,
  }

  const heifer: FarmSpeciesObject = {
    imgSrc: heifer_img,
    name: ANIMAL_SPECIES_NAME_TRANSLATION_ENUM.VEAL,
    background: getRadialBackground(
      ANIMALS_IMAGE_CARD_BACKGROUND_COLOR.HEIFER0,
      ANIMALS_IMAGE_CARD_BACKGROUND_COLOR.HEIFER1,
    ),
    count: heifers,
    total: total,
    gender: ANIMAL_GENDERS.ALL_GENDERS.label,
    species: ANIMAL_SPECIES_ENUM.COW,
    age: HEIFER_AGE,
  }

  const cow: FarmSpeciesObject = {
    imgSrc: cow_img,
    name: ANIMAL_SPECIES_NAME_TRANSLATION_ENUM.COW,
    background: getRadialBackground(
      ANIMALS_IMAGE_CARD_BACKGROUND_COLOR.COW0,
      ANIMALS_IMAGE_CARD_BACKGROUND_COLOR.COW1,
    ),
    count: cows,
    total: total,
    gender: ANIMAL_GENDERS.ALL_GENDERS.label,
    species: ANIMAL_SPECIES_ENUM.COW,
    age: MATURE_COW_AGE,
  }

  const animalsSummary = [calf, heifer, cow]

  return animalsSummary.filter((animal) => animal.count)
}

export const getMammalsWidgetSummary = (animals: Animal[]) => {
  const cows = getCowsWidgetSummary(animals)
  const pigs = getPigsWidgetSummary(animals)
  const goats = getGoatsWidgetSummary(animals)
  const sheep = getSheepWidgetSummary(animals)

  return [...cows, ...pigs, ...goats, ...sheep]
}

export const getAllAnimalsSummary = (animals: Animal[]) => {
  const mammals = getMammalsWidgetSummary(animals)
  const bees = getBeesWidgetSummary(animals)
  const birds = getPoultryWidgetSummary(animals)

  return [...mammals, ...bees, ...birds]
}
