import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { type RenderFunctionDrawerItemComponentProps } from "@/components/render-function-drawer/render-function-drawer.tsx"
import { formatArea } from "@/features/parcels/types/parcel-utils.ts"
import { type Parcel } from "@/features/parcels/types/parcel.ts"
import { PARCEL_VIEW_SCREEN_PATH } from "@/utils/constants/routes.ts"
import { cn } from "@/utils/styles.ts"

interface Props {
  parcel: Parcel
  className?: string
}

const ParcelCard: React.FC<Props> = ({ parcel, className }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const navigateToFieldView = () => {
    navigate(PARCEL_VIEW_SCREEN_PATH.replace(":parcelId", parcel.id))
  }

  return (
    <div
      onClick={navigateToFieldView}
      key={parcel.id}
      className={cn(
        "rounded-lg border border-gray-200 bg-white p-4",
        className,
      )}
    >
      <div className={"flex gap-3"}>
        <img
          src={parcel.picture}
          alt=""
          className={"widget-border aspect-square w-20"}
        />
        <div className={"flex flex-col items-start justify-between"}>
          <div className={""}>
            <h3 className={"text-lg"}>{parcel.name}</h3>
            <p className={"text-left text-sm text-gray-500"}>
              {parcel.description}
            </p>
          </div>
          <p className={"space-x-2"}>
            <span className={"text-gray-500"}>{t("area")}:</span>
            <span className={"font-semibold"}>
              {formatArea(parcel.area, 2)}
            </span>
          </p>
        </div>
      </div>
    </div>
  )
}

const ParcelCardWrapper: React.FC<
  RenderFunctionDrawerItemComponentProps & { parcel: Parcel }
> = ({ isSelected, onSelect, parcel }) => {
  return (
    <div onClick={onSelect}>
      <ParcelCard
        parcel={parcel}
        className={cn(
          "pointer-events-none",
          isSelected ? "!border-brown-500" : "",
        )}
      />
    </div>
  )
}

export { ParcelCard, ParcelCardWrapper }
