import { skipToken } from "@reduxjs/toolkit/query"
import isEqual from "lodash.isequal"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"
import { toast } from "react-toastify"
import { LoadingAnimation } from "@/components/loading-animation/loading-animation.tsx"
import PageContentWrapperLarge from "@/components/page-content-wrappers/page-content-wrapper-large.tsx"
import { Button } from "@/components/shadcn/button.tsx"
import EditorTopBar from "@/components/top-bars/editor-top-bar/editor-top-bar.tsx"
import {
  useDeleteParcelEventMutation,
  useGetParcelEventByIdQuery,
  useUpdateParcelEventMutation,
} from "@/features/events/parcel-events/api/parcel-events-api.ts"
import { ParcelEventEditor } from "@/features/events/parcel-events/components/parcel-event-editor/parcel-event-editor.tsx"
import { type ParcelEventInput } from "@/features/events/parcel-events/types/parcel-events.ts"
import useModal from "@/features/modals/hooks/use-modal.ts"
import { MODALS } from "@/features/modals/types/modals.tsx"
import { PARCEL_EVENTS_SCREEN_PATH } from "@/utils/constants/routes.ts"

const ParcelEventView = () => {
  const { t } = useTranslation()
  const { parcelEventId } = useParams()
  const { openModal } = useModal()
  const navigate = useNavigate()

  const [updateParcelEventMutation] = useUpdateParcelEventMutation()
  const [deleteParcelEventMutation] = useDeleteParcelEventMutation()
  const { data, isLoading } = useGetParcelEventByIdQuery(
    parcelEventId || skipToken,
  )
  const [workingCopy, setWorkingCopy] = useState<ParcelEventInput | undefined>(
    data,
  )
  const hasChanges = !isEqual(data, workingCopy)

  useEffect(() => {
    if (!isLoading && data) {
      setWorkingCopy(data)
    }
  }, [data, isLoading])

  const handleUpdate = async () => {
    await toast
      .promise(
        updateParcelEventMutation({
          id: parcelEventId || "",
          ...workingCopy,
        }).unwrap(),
        {
          pending: t("updatingEvent"),
          success: t("eventUpdated"),
          error: t("eventUpdateError"),
        },
      )
      .then(() => {
        navigate(-1)
      })
  }

  const deleteParcelEvent = async () => {
    await toast
      .promise(
        deleteParcelEventMutation({
          id: parcelEventId || "",
        }).unwrap(),
        {
          pending: t("deletingEvent"),
          success: t("eventDeleted"),
          error: t("eventDeleteError"),
        },
      )
      .then(() => {
        navigate(PARCEL_EVENTS_SCREEN_PATH, { replace: true })
      })
  }

  const handleDelete = () => {
    openModal(MODALS.BASE_MODAL, {
      title: t("deleteEvent"),
      content: t("deleteEventConfirmation"),
      onActionClick: deleteParcelEvent,
    })
  }

  const onBackClick = () => {
    if (hasChanges) {
      openModal(MODALS.BASE_MODAL, {
        title: t("unsavedChanges"),
        content: t("youWillLoseChanges"),
        onActionClick: () => {
          navigate(-1)
        },
      })
    } else {
      navigate(-1)
    }
  }

  return (
    <>
      <EditorTopBar
        text={t("parcelEvent")}
        isModified={hasChanges}
        onSave={handleUpdate}
        onBackClick={onBackClick}
      />
      <PageContentWrapperLarge className={"space-y-5"}>
        {isLoading || !workingCopy ? (
          <LoadingAnimation />
        ) : (
          <>
            <ParcelEventEditor
              parcelEvent={workingCopy}
              onParcelEventChange={setWorkingCopy}
            />
            <Button
              size={"lg"}
              variant={"outline"}
              className={"w-full flex-shrink-0 border-neutral-200 text-red-500"}
              onClick={handleDelete}
            >
              <p>{t("deleteEvent")}</p>
            </Button>
          </>
        )}
      </PageContentWrapperLarge>
    </>
  )
}

export default ParcelEventView
