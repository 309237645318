import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"
import LogoBrown from "@/assets/icons/logos/logo-brown.tsx"
import { Button } from "@/components/shadcn/button.tsx"
import { useVerifyEmailMutation } from "@/features/settings-account/api/account-api.ts"
import { LOGIN_SCREEN_PATH } from "@/utils/constants/routes.ts"

const EmailVerification: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { key } = useParams<{ key: string }>()
  const [verificationSuccess, setVerificationSuccess] = useState<
    boolean | null
  >(null)
  const [isLoading, setIsLoading] = useState(true)
  const [errorMessage, setErrorMessage] = useState("")
  const [verifyEmail] = useVerifyEmailMutation()
  const [verificationAttempted, setVerificationAttempted] = useState(false)

  useEffect(() => {
    if (!key) {
      setErrorMessage(t("invalidVerificationLink"))
      setIsLoading(false)
      return
    }

    // Prevent duplicate verification attempts in React StrictMode
    if (verificationAttempted) {
      return
    }

    const verifyEmailAddress = async () => {
      setVerificationAttempted(true)

      try {
        await verifyEmail({ key }).unwrap()
        setVerificationSuccess(true)
      } catch (err: any) {
        if (isSuccessfulVerification(err)) {
          setVerificationSuccess(true)
        } else {
          setErrorMessage(err?.data?.detail || t("emailVerificationFailed"))
          setVerificationSuccess(false)
        }
      } finally {
        setIsLoading(false)
      }
    }

    verifyEmailAddress()
  }, [key, t, verifyEmail, verificationAttempted])

  const isSuccessfulVerification = (err: any): boolean => {
    // 401 means verified but needs authentication
    if (err.status === 401) {
      return true
    }

    // 400 with message about already being verified
    if (err.status === 400) {
      const errorDetail = (err?.data?.detail || "").toLowerCase()
      return errorDetail.includes("already") || errorDetail.includes("verified")
    }

    return false
  }

  if (isLoading) {
    return (
      <div className="flex min-h-screen w-full items-center justify-center bg-background">
        <div className="flex h-[calc(100vh-80px-env(safe-area-inset-bottom))] w-[300px] flex-col items-center justify-center gap-3">
          <LogoBrown width={150} height={150} />
          <div className="flex flex-col items-center justify-center text-center">
            <h1 className="m-0 text-2xl font-semibold text-neutral-800">
              {t("verifyingEmail")}
            </h1>
            <h5 className="m-0 text-base font-normal text-neutral-500">
              {t("pleaseWait")}
            </h5>
          </div>
        </div>
      </div>
    )
  }

  if (verificationSuccess) {
    return (
      <div className="flex min-h-screen w-full items-center justify-center bg-background">
        <div className="flex h-[calc(100vh-80px-env(safe-area-inset-bottom))] w-[300px] flex-col items-center justify-center gap-3">
          <LogoBrown width={150} height={150} />
          <div className="flex flex-col items-center justify-center text-center">
            <h1
              className="m-0 text-2xl font-semibold text-neutral-800"
              data-testid="verification-success-heading"
            >
              {t("emailVerificationSuccessful")}
            </h1>
            <h5 className="m-0 text-base font-normal text-neutral-500">
              {t("youCanNowLogin")}
            </h5>
            <Button
              className="mt-10 w-full"
              size={"lg"}
              onClick={() => navigate(LOGIN_SCREEN_PATH)}
              data-testid="proceed-to-login-button"
            >
              {t("proceedToLogin")}
            </Button>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="flex min-h-screen w-full items-center justify-center bg-background">
      <div className="flex h-[calc(100vh-80px-env(safe-area-inset-bottom))] w-[300px] flex-col items-center justify-center gap-3">
        <LogoBrown width={150} height={150} />
        <div className="flex flex-col items-center justify-center text-center">
          <h1
            className="m-0 text-2xl font-semibold text-neutral-800"
            data-testid="verification-failed-heading"
          >
            {t("emailVerificationFailed")}
          </h1>
          <h5 className="m-0 text-base font-normal text-neutral-500">
            {errorMessage || t("invalidOrExpiredLink")}
          </h5>
          <Button
            variant="outline"
            className="mt-20 h-12 w-full border-brown-500 text-brown-500"
            onClick={() => navigate(LOGIN_SCREEN_PATH)}
            data-testid="back-to-login-button"
          >
            {t("backToLogin")}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default EmailVerification
