import { Map, type MapRef } from "@vis.gl/react-maplibre"
import { LockIcon, MinusIcon, PlusIcon, LayersIcon } from "lucide-react"
import React, {
  useRef,
  useMemo,
  useCallback,
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react"
import "maplibre-gl/dist/maplibre-gl.css"

interface Props {
  longitude: number
  latitude: number
  children: React.ReactNode
  zoom?: number
  pitch?: number
  bearing?: number
  maxZoom?: number
  mapStyle?: any
  height?: string
}

// Define the handle type for imperative access
export interface MapFarmPlannerHandle {
  takeSnapshot: () => Promise<string>
  getMapInstance: () => MapRef | null
}

const DefaultMapInitialState = {
  longitude: 0,
  latitude: 0,
  zoom: 12,
  pitch: 45,
  bearing: 0,
  maxZoom: 15,
}

export const MapFarmPlanner = forwardRef<MapFarmPlannerHandle, Props>(
  (
    {
      latitude,
      longitude,
      children,
      zoom,
      pitch,
      bearing,
      maxZoom = DefaultMapInitialState.maxZoom,
      mapStyle,
      height = "24rem",
    },
    ref,
  ) => {
    const mapRef = useRef<MapRef>(null)
    const isLockedRef = useRef(true)
    const [styleIndex, setStyleIndex] = useState(0)

    const mapId = useRef(`map-${Math.random().toString(36).substr(2, 9)}`)

    const initialViewState = useMemo(
      () => ({
        latitude: Number(latitude),
        longitude: Number(longitude),
        zoom: Number(zoom !== undefined ? zoom : DefaultMapInitialState.zoom),
        pitch: Number(
          pitch !== undefined ? pitch : DefaultMapInitialState.pitch,
        ),
        bearing: Number(
          bearing !== undefined ? bearing : DefaultMapInitialState.bearing,
        ),
      }),
      [latitude, longitude, zoom, pitch, bearing],
    )

    // Define multiple map styles
    const mapStyles = useMemo(
      () => [
        // Topo style (original default)
        {
          version: 8,
          sources: {
            "terrain-source": {
              type: "raster",
              tiles: [
                "https://a.tile.opentopomap.org/{z}/{x}/{y}.png",
                "https://b.tile.opentopomap.org/{z}/{x}/{y}.png",
                "https://c.tile.opentopomap.org/{z}/{x}/{y}.png",
              ],
              tileSize: 256,
            },
          },
          layers: [
            {
              id: "terrain-layer",
              type: "raster",
              source: "terrain-source",
              minzoom: 0,
              maxzoom: 17,
            },
          ],
        },
        // Satellite style
        {
          version: 8,
          sources: {
            "satellite-source": {
              type: "raster",
              tiles: [
                "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
              ],
              tileSize: 256,
            },
          },
          layers: [
            {
              id: "satellite-layer",
              type: "raster",
              source: "satellite-source",
              minzoom: 0,
              maxzoom: 19,
            },
          ],
        },
        // Street style
        {
          version: 8,
          sources: {
            "osm-source": {
              type: "raster",
              tiles: ["https://tile.openstreetmap.org/{z}/{x}/{y}.png"],
              tileSize: 256,
            },
          },
          layers: [
            {
              id: "osm-layer",
              type: "raster",
              source: "osm-source",
              minzoom: 0,
              maxzoom: 19,
            },
          ],
        },
      ],
      [],
    )

    const styleNames = ["Topographic", "Satellite", "Street"]

    const effectiveMapStyle = useMemo(
      () => mapStyle || mapStyles[styleIndex],
      [mapStyle, mapStyles, styleIndex],
    )

    /**
     * Takes a snapshot of the current map view
     * @returns {Promise<string>} A promise that resolves to a data URL of the map image
     */
    const takeSnapshot = useCallback((): Promise<string> => {
      return new Promise((resolve, reject) => {
        if (!mapRef.current) {
          reject(new Error("Map is not initialized"))
          return
        }

        try {
          const map = mapRef.current.getMap()

          // Create an offscreen canvas to draw on
          const renderCanvas = document.createElement("canvas")
          const mapCanvas = map.getCanvas()
          renderCanvas.width = mapCanvas.width
          renderCanvas.height = mapCanvas.height

          const ctx = renderCanvas.getContext("2d", {
            willReadFrequently: true,
          })
          if (!ctx) {
            reject(new Error("Could not get 2d context"))
            return
          }

          // Make sure the context is reset
          ctx.globalAlpha = 1
          ctx.clearRect(0, 0, renderCanvas.width, renderCanvas.height)

          const captureMap = () => {
            try {
              // Force a redraw of the map before capturing
              map.triggerRepaint()

              // Use requestAnimationFrame to capture in the next paint cycle
              requestAnimationFrame(() => {
                // Draw the map canvas onto our offscreen canvas
                ctx.drawImage(mapCanvas, 0, 0)

                try {
                  const dataUrl = renderCanvas.toDataURL("image/jpeg", 0.5)
                  resolve(dataUrl)
                } catch (e) {
                  console.error("Error generating data URL:", e)
                }
              })
            } catch (innerError) {
              console.error("Error during canvas capture:", innerError)
              reject(innerError)
            }
          }

          if (!map.isStyleLoaded()) {
            console.log("Map style not fully loaded, waiting...")
            map.once("idle", () => {
              // Wait a bit more after idle to ensure all tiles are rendered
              setTimeout(captureMap, 300)
            })
          } else {
            // Still add a small delay to ensure complete rendering
            setTimeout(captureMap, 300)
          }
        } catch (error) {
          console.error("Error taking map snapshot:", error)
          reject(error)
        }
      })
    }, [])

    useImperativeHandle(ref, () => ({
      takeSnapshot,
      getMapInstance: () => mapRef.current,
    }))

    const toggleLock = useCallback(() => {
      if (!mapRef.current) return

      isLockedRef.current = !isLockedRef.current

      const lockButton = document.querySelector(`#${mapId.current}-lock-button`)
      if (lockButton) {
        const label = isLockedRef.current ? "Unlock map" : "Lock map"
        lockButton.setAttribute("aria-label", label)

        lockButton.innerHTML = isLockedRef.current
          ? '<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><rect width="18" height="11" x="3" y="11" rx="2" ry="2"/><path d="M7 11V7a5 5 0 0 1 10 0v4"/></svg>'
          : '<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><rect width="18" height="11" x="3" y="11" rx="2" ry="2"/><path d="M7 11V7a5 5 0 0 1 9.9-1"/></svg>'
      }

      const map = mapRef.current.getMap()

      if (isLockedRef.current) {
        map.dragPan.disable()
        map.scrollZoom.disable()
        map.doubleClickZoom.disable()
        map.touchPitch.disable()
        map.dragRotate.disable()
        map.keyboard.disable()
      } else {
        map.dragPan.enable()
        map.scrollZoom.enable()
        map.doubleClickZoom.enable()
        map.touchPitch.enable()
        map.dragRotate.enable()
        map.keyboard.enable()
      }
    }, [])

    const cycleMapStyle = useCallback(() => {
      setStyleIndex((prevIndex) => (prevIndex + 1) % mapStyles.length)
    }, [mapStyles.length])

    useEffect(() => {
      const handleMapLoad = () => {
        if (!mapRef.current) return

        try {
          const map = mapRef.current.getMap()

          if (isLockedRef.current) {
            map.dragPan.disable()
            map.scrollZoom.disable()
            map.doubleClickZoom.disable()
            map.touchPitch.disable()
            map.dragRotate.disable()
            map.keyboard.disable()
          }
        } catch (error) {
          console.error("Error setting initial map controls:", error)
        }
      }

      const mapContainer = document.getElementById(mapId.current)
      if (mapContainer) {
        mapContainer.addEventListener("load", handleMapLoad, { once: true })
      }

      const timer = setTimeout(() => {
        if (mapRef.current) {
          try {
            const map = mapRef.current.getMap()

            if (isLockedRef.current) {
              map.dragPan.disable()
              map.scrollZoom.disable()
              map.doubleClickZoom.disable()
              map.touchPitch.disable()
              map.dragRotate.disable()
              map.keyboard.disable()
            }
          } catch (error) {
            console.error("Error setting map controls after timeout:", error)
          }
        }
      }, 500)

      return () => {
        clearTimeout(timer)
        if (mapContainer) {
          mapContainer.removeEventListener("load", handleMapLoad)
        }
      }
    }, [])

    const handleZoom = useCallback(
      (direction: "in" | "out") => {
        if (!mapRef.current) return

        const map = mapRef.current.getMap()
        const currentZoom = map.getZoom()
        const newZoom =
          direction === "in"
            ? Math.min(currentZoom + 1, maxZoom)
            : Math.max(currentZoom - 1, 4)

        map.easeTo({
          zoom: newZoom,
          duration: 300,
        })
      },
      [maxZoom],
    )

    return (
      <div
        className="widget-border relative w-full overflow-hidden rounded border border-gray-200"
        style={{ height }}
      >
        <Map
          id={mapId.current}
          ref={mapRef}
          initialViewState={initialViewState}
          style={{ width: "100%", height: "100%" }}
          mapStyle={effectiveMapStyle}
          dragPan={false}
          scrollZoom={false}
          doubleClickZoom={false}
          touchPitch={false}
          dragRotate={false}
          keyboard={false}
          attributionControl={false}
          renderWorldCopies={false}
          reuseMaps
          onLoad={() => {
            if (mapRef.current && isLockedRef.current) {
              const map = mapRef.current.getMap()
              map.dragPan.disable()
              map.scrollZoom.disable()
              map.doubleClickZoom.disable()
              map.touchPitch.disable()
              map.dragRotate.disable()
              map.keyboard.disable()
            }
          }}
        >
          {children}
        </Map>

        <div className="absolute right-3 top-3 z-10 flex flex-col rounded border border-neutral-200 bg-white shadow-sm">
          <button
            id={`${mapId.current}-lock-button`}
            onClick={toggleLock}
            className="flex h-10 w-10 items-center justify-center border-b p-2"
            aria-label="Unlock map"
          >
            <LockIcon size={18} />
          </button>

          <button
            onClick={() => handleZoom("in")}
            className="flex h-10 w-10 items-center justify-center border-b p-2"
            aria-label="Zoom in"
          >
            <PlusIcon size={18} />
          </button>

          <button
            onClick={() => handleZoom("out")}
            className="flex h-10 w-10 items-center justify-center border-b p-2"
            aria-label="Zoom out"
          >
            <MinusIcon size={18} />
          </button>

          <button
            onClick={cycleMapStyle}
            className="group relative flex h-10 w-10 items-center justify-center border-b p-2"
            aria-label={`Switch map style (current: ${styleNames[styleIndex]})`}
          >
            <LayersIcon size={18} />
          </button>
        </div>
      </div>
    )
  },
)

MapFarmPlanner.displayName = "MapFarmPlanner"

export default MapFarmPlanner
