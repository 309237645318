import { format } from "date-fns"
import { useEffect, useRef, useState } from "react"
import { type DateRange } from "react-day-picker"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"
import ClockIcon from "@/assets/icons/misc/clock-icon.tsx"
import { LoadingAnimation } from "@/components/loading-animation/loading-animation.tsx"
import PageContentWrapperLarge from "@/components/page-content-wrappers/page-content-wrapper-large.tsx"
import { SelectPill } from "@/components/select-pill/select-pill.tsx"
import { SelectionRowDrawer } from "@/components/selection-row-drawer/selection-row-drawer.tsx"
import MobileTopBar from "@/components/top-bars/mobile-top-bar/mobile-top-bar.tsx"
import {
  useGetHiveScaleMeasurementsQuery,
  useLazyGetHiveScaleStatisticsQuery,
} from "@/features/bees/api/hive-scales-api.ts"
import { GroupedMeasurements } from "@/features/bees/components/hive-scales/grouped-measurements/grouped-measurements.tsx"
import { HiveScaleGenericChart } from "@/features/bees/components/hive-scales/hive-scale-charts/hive-scale-generic-chart.tsx"
import {
  getMetricConfig,
  type HIVE_SCALE_METRICS,
  HIVE_SCALE_PERIODS,
  hiveScaleTimePeriods,
  type ScaleMetric,
  type ScaleMetricKey,
  type TimePeriod,
} from "@/features/bees/types/hive-scales.ts"
import { DateRangePicker } from "@/features/events/common/components/date-filter-drawer/range-date-picker.tsx"
import { HIVE_MEASUREMENT_VIEW_LIST } from "@/utils/constants/routes.ts"
import { type Option } from "@/utils/types/misc-types.ts"

const HiveScaleMeasurementViewDetail = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const statsRef = useRef<HTMLDivElement>(null)
  const [showCustomPeriod, setShowCustomPeriod] = useState(false)
  const [selectedTimeFrame, setSelectedTimeFrame] = useState<
    DateRange | undefined
  >(undefined)
  const { hiveScaleId, metric } = useParams<{
    hiveScaleId: string
    metric: string
  }>()
  const validMetric = metric as ScaleMetricKey
  const title = t(metric || "loading...")
  const config = getMetricConfig(metric as HIVE_SCALE_METRICS)
  const [selectedPeriod, setSelectedPeriod] = useState<Option>(
    hiveScaleTimePeriods[1],
  )

  const [
    triggerStatisticsQuery,
    {
      data: statistics,
      isLoading: isLoadingStats,
      isFetching: isFetchingStats,
    },
  ] = useLazyGetHiveScaleStatisticsQuery()

  const lastMeasurement =
    statistics?.data_points[statistics.data_points.length - 1]?.value

  const { data: measurements } = useGetHiveScaleMeasurementsQuery(
    {
      scaleId: hiveScaleId!,
      page: 1,
    },
    {
      skip: !hiveScaleId || !metric,
    },
  )

  useEffect(() => {
    if (
      hiveScaleId &&
      metric &&
      selectedPeriod.value !== HIVE_SCALE_PERIODS.CUSTOM
    ) {
      triggerStatisticsQuery({
        scaleId: hiveScaleId,
        metric: metric as ScaleMetric,
        period: selectedPeriod.value as TimePeriod,
      })
    }
  }, [hiveScaleId, metric, selectedPeriod.value, triggerStatisticsQuery])

  const navigateToAllMeasurements = () => {
    hiveScaleId &&
      navigate(
        HIVE_MEASUREMENT_VIEW_LIST.replace(":hiveScaleId", hiveScaleId).replace(
          ":metric",
          validMetric,
        ),
      )
  }

  const handleCustomPeriodChange = (dateRange: DateRange | undefined) => {
    setSelectedTimeFrame(dateRange)

    if (
      dateRange?.from &&
      dateRange?.to &&
      selectedPeriod.value === HIVE_SCALE_PERIODS.CUSTOM &&
      hiveScaleId &&
      metric
    ) {
      triggerStatisticsQuery({
        scaleId: hiveScaleId,
        metric: metric as ScaleMetric,
        period: selectedPeriod.value as TimePeriod,
        start_date: format(dateRange.from, "yyyy-MM-dd"),
        end_date: format(dateRange.to, "yyyy-MM-dd"),
      })

      statsRef.current?.scrollIntoView({ behavior: "smooth", block: "center" })
    }
  }

  const handlePeriodChange = (period: string) => {
    if (period === HIVE_SCALE_PERIODS.CUSTOM) {
      setShowCustomPeriod(true)
    } else {
      setShowCustomPeriod(false)
    }
    setSelectedPeriod(hiveScaleTimePeriods.find((p) => p.value === period)!)
  }

  if (!statistics || !metric) {
    return (
      <>
        <MobileTopBar title={t(title)} />
        <PageContentWrapperLarge className={"flex flex-col"}>
          <LoadingAnimation customStyle={{ height: "100%" }} />
        </PageContentWrapperLarge>
      </>
    )
  }

  return (
    <>
      <MobileTopBar title={t(title)} />
      <PageContentWrapperLarge className={"flex flex-col"}>
        <div
          className={"flex w-full flex-col items-center justify-center gap-4"}
        >
          <SelectionRowDrawer
            title={t("period")}
            multiple={false}
            renderTrigger={
              <SelectPill
                isActive={selectedPeriod.label !== HIVE_SCALE_PERIODS.WEEK}
              >
                {t(selectedPeriod.label)}
              </SelectPill>
            }
            options={hiveScaleTimePeriods}
            selectedValues={selectedPeriod.label}
            onSelectionChange={handlePeriodChange}
          />
          {showCustomPeriod && (
            <div className={"widget-border w-full bg-white p-3"}>
              <DateRangePicker
                selectedTimeFrame={selectedTimeFrame}
                onSelectedTimeFrameChange={handleCustomPeriodChange}
              />
            </div>
          )}
        </div>

        <div ref={statsRef} className={"widget-border bg-white p-1.5"}>
          <div className={"mb-3 flex flex-col gap-3 p-3"}>
            {selectedPeriod.label !== HIVE_SCALE_PERIODS.DAY && statistics && (
              <p className="mb-1 flex justify-start text-body-xs font-semibold">
                {t("period")} {format(statistics?.start_time, "dd.MM.yyyy")} -{" "}
                {format(statistics?.end_time, "dd.MM.yyyy")}
              </p>
            )}
            {selectedPeriod.value === HIVE_SCALE_PERIODS.TWO_WEEKS && (
              <i className={"text-neutral-600"}>
                {t("forBetterRepresentationAvg", { count: 6 })}
              </i>
            )}
            {selectedPeriod.value === HIVE_SCALE_PERIODS.MONTH && (
              <i className={"text-neutral-600"}>
                {t("forBetterRepresentationAvg", { count: 12 })}
              </i>
            )}
            {lastMeasurement && (
              <p>
                <span className={"mt-3"}>{t("lastMeasurement")}</span>
                <div className={"widget-border flex justify-between p-3"}>
                  <div className={"flex items-center gap-2"}>
                    <ClockIcon className={"h-4 w-4"} />
                    {format(
                      statistics?.data_points[statistics.data_points.length - 1]
                        ?.timestamp,
                      "dd.MM.yyyy HH:mm",
                    )}
                  </div>
                  <div
                    style={{ color: config.color }}
                    className={"font-semibold"}
                  >
                    {lastMeasurement.toFixed(2)}
                    {config.unit}
                  </div>
                </div>
              </p>
            )}
          </div>
          <div className="h-[300px] w-full">
            <HiveScaleGenericChart
              data={statistics?.data_points || []}
              color={config.color}
              title={t(metric || "")}
              measureUnit={config.unit}
              period={selectedPeriod.value}
              isLoading={isLoadingStats || isFetchingStats}
            />
          </div>
        </div>

        <div className={"widget-border bg-white p-4"}>
          <p className={"mb-3 font-semibold"}>{t("statistics")}</p>
          {statistics && metric && (
            <div className={"flex flex-col gap-2"}>
              {[
                { label: "minVal", value: statistics.min },
                { label: "maxVal", value: statistics.max },
                { label: "avgVal", value: statistics.avg },
              ].map(({ label, value }) => (
                <span
                  key={label}
                  className={"flex items-center justify-between text-body-xs"}
                >
                  <span>
                    {t(label)} {t(metric)}
                  </span>
                  <span
                    style={{ color: config.color }}
                    className={"font-semibold"}
                  >
                    {value.toFixed(2)} {config.unit}
                  </span>
                </span>
              ))}
            </div>
          )}
        </div>

        {measurements && measurements.results && metric && (
          <GroupedMeasurements
            metric={metric}
            measurements={measurements}
            onButtonClick={navigateToAllMeasurements}
            title={"showAllMeasurements"}
          />
        )}
      </PageContentWrapperLarge>
    </>
  )
}

export default HiveScaleMeasurementViewDetail
