import { format } from "date-fns"

export interface Crop {
  id: string
  name: string
  farm: string | null
  scientific_name: string
  description: string
  average_growing_days: number
  planting_depth: number
  row_spacing: number
  plant_spacing: number
  preferred_temperature_min: number
  preferred_temperature_max: number
  picture: string
  created_at: string
  updated_at: string
}

export interface CropPlanting {
  id: string
  parcel_name: string
  parcel: string
  crop: string
  crop_name: string
  crop_picture: string | null
  seeding_date: string
  planned_harvest_date: string
  actual_harvest_date: string | null
  expected_yield: string | null
  actual_yield: number | null
  tasks: string[]
  notes: string[]
  crop_details?: Crop
  created_at: string
  updated_at: string
}

export interface CropPlantingCreateUpdateRequest {
  parcel: string
  crop: string
  seeding_date: string
  planned_harvest_date: string
  expected_yield: string
}

export const initialCropPlantingState: CropPlantingCreateUpdateRequest = {
  parcel: "",
  crop: "",
  seeding_date: format(new Date(), "yyyy-MM-dd"),
  planned_harvest_date: "",
  expected_yield: "",
}
