import { format } from "date-fns"

export interface ParcelEvent {
  id: string
  person_name?: string
  type: string
  date: string
  type_name: string
  type_picture?: string
  parcel_name: string
  parcel: string
  description?: string
  notes?: string
  created_at: string
  updated_at: string
}

export interface ParcelEventInput {
  type: string
  date: string
  parcel: string
  person_name?: string
  description?: string
  notes?: string
}

export const initialParcelEventState: ParcelEventInput = {
  type: "",
  date: format(new Date(), "yyyy-MM-dd"),
  parcel: "",
  person_name: "",
  description: "",
  notes: "",
}
