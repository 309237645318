import { useTranslation } from "react-i18next"
import { MapPinNumber } from "@/components/maps-components/map-pin-number.tsx"
import { type ParcelCoordinate } from "@/features/parcels/types/parcel.ts"

interface Props {
  coordinate: ParcelCoordinate
  index: number
}

export const CoordinateCard: React.FC<Props> = ({ coordinate, index }) => {
  const { t } = useTranslation()

  return (
    <div
      key={index}
      className={
        "widget-border flex w-full items-center justify-start gap-2 p-2"
      }
    >
      <MapPinNumber number={index + 1} />
      <div className={"w-full"}>
        <span className={"flex w-full justify-between"}>
          <p>{t("latY")}</p>
          <p>{coordinate.latitude}</p>
        </span>
        <span className={"flex w-full justify-between"}>
          <p>{t("longX")}</p>
          <p>{coordinate.longitude}</p>
        </span>
      </div>
    </div>
  )
}
