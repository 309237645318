import { type GenericType } from "@/features/events/animal-events/types/animal-events.ts"

export interface BaseEvent {
  id: string
  type: string
  date: string | Date
}

export interface Medication extends GenericType {
  unit: string
  dosage: string
  description: string
  created_at: string
  updated_at: string
  farm: string | null
}

export const MedicationUnit = {
  Milliliters: "Milliliters",
  Milligrams: "Milligrams",
  Grams: "Grams",
  Pieces: "Pieces",
} as const

export const BeesMedicationUnit = {
  Milliliters: "Milliliters",
  Milligrams: "Milligrams",
  Grams: "Grams",
  Pieces: "Pieces",
  Drops: "Drops",
  Strips: "Strips",
  Packets: "Packets",
  Doses: "Doses",
} as const

export const medicationUnitOptions = Object.entries(MedicationUnit).map(
  ([value, label]) => ({
    value,
    label,
  }),
)

export const beesMedicationUnitOptions = Object.entries(BeesMedicationUnit).map(
  ([value, label]) => ({
    value,
    label,
  }),
)
