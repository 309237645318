import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import { toast } from "react-toastify"
import EditIcon from "@/assets/icons/misc/edit-icon.tsx"
import { LoadingAnimation } from "@/components/loading-animation/loading-animation.tsx"
import PageContentWrapperLarge from "@/components/page-content-wrappers/page-content-wrapper-large.tsx"
import MobileTopBar from "@/components/top-bars/mobile-top-bar/mobile-top-bar.tsx"
import { WidgetButton } from "@/components/widget-button/widget-button.tsx"
import {
  useGetHiveScaleByIdQuery,
  useUpdateHiveScaleMutation,
} from "@/features/bees/api/hive-scales-api.ts"
import { AssocHiveDrawer } from "@/features/bees/components/hive-scales/assoc-hive-drawer/assoc-hive-drawer.tsx"
import { CreateUpdateHiveScaleDrawer } from "@/features/bees/components/hive-scales/create-update-hive-scale-drawer/create-update-hive-scale-drawer.tsx"
import { HiveScaleCard } from "@/features/bees/components/hive-scales/hive-scale-card/hive-scale-card.tsx"
import { HiveScaleEventsWidget } from "@/features/bees/components/hive-scales/hive-scale-events-widget/hive-scale-events-widget.tsx"
import { HiveCardLong } from "@/features/bees/components/hives/hive-card/hive-card-long.tsx"
import { HiveMeasurementWidget } from "@/features/bees/components/hives/hive-measurment-widget/hive-measurement-widget.tsx"
import { HIVE_SCALE_METRICS } from "@/features/bees/types/hive-scales.ts"
import WidgetCard from "@/features/home/components/widget-card/widget-card.tsx"
import useModal from "@/features/modals/hooks/use-modal.ts"
import { MODALS } from "@/features/modals/types/modals.tsx"

const HiveScaleView = () => {
  const { t } = useTranslation()
  const { openModal } = useModal()
  const [updateHiveScaleTrigger] = useUpdateHiveScaleMutation()
  const { hiveScaleId } = useParams<{
    hiveScaleId: string
  }>()

  const { data: scale, isLoading } = useGetHiveScaleByIdQuery(
    hiveScaleId as string,
    {
      skip: !hiveScaleId,
    },
  )

  const removeScaleFromHive = async () => {
    if (!scale || !scale.hive) {
      return
    }
    const promise = updateHiveScaleTrigger({ ...scale, hive: null }).unwrap()
    toast.promise(promise, {
      pending: t("removingScale"),
      success: t("scaleRemoved"),
      error: t("scaleRemoveError"),
    })
  }

  const handleRemoveScaleFromHive = () => {
    openModal(MODALS.BASE_MODAL, {
      title: t("removeScaleFromHive"),
      content: t("removeScaleFromHiveConfirmation"),
      onActionClick: () => {
        removeScaleFromHive()
      },
    })
  }

  return (
    <>
      <MobileTopBar
        title={t("hiveScale")}
        onIconClick={() => {}}
        icon={
          scale ? (
            <CreateUpdateHiveScaleDrawer
              hiveScale={scale}
              trigger={<EditIcon />}
            />
          ) : undefined
        }
      />
      <PageContentWrapperLarge className={"flex flex-col"}>
        {(isLoading || !scale) && (
          <LoadingAnimation customStyle={{ height: "100%" }} />
        )}
        {!isLoading && scale && hiveScaleId && (
          <>
            <div className={"mt-2 text-[14px] text-neutral-400"}>
              {t("hiveScaleDetails")}
            </div>
            <HiveScaleCard showNavigation={false} hiveScale={scale} />
            <WidgetCard title={t("hive")}>
              {scale.hive && <HiveCardLong hive={scale.hive} />}
              {scale.hive?.id ? (
                <WidgetButton
                  title={t("removeScaleFromHive")}
                  onClick={handleRemoveScaleFromHive}
                />
              ) : (
                <AssocHiveDrawer scale={scale} title={t("assocHive")} />
              )}
            </WidgetCard>
            <div className={"mt-2 text-[14px] text-neutral-400"}>
              {t("measurements")}
            </div>
            <HiveMeasurementWidget
              scaleId={hiveScaleId}
              metric={HIVE_SCALE_METRICS.WEIGHT}
            />
            <HiveMeasurementWidget
              scaleId={hiveScaleId}
              metric={HIVE_SCALE_METRICS.HUMIDITY}
            />
            <HiveMeasurementWidget
              scaleId={hiveScaleId}
              metric={HIVE_SCALE_METRICS.TEMPERATURE}
            />
            <HiveMeasurementWidget
              scaleId={hiveScaleId}
              metric={HIVE_SCALE_METRICS.PRESSURE}
            />
            <HiveScaleEventsWidget events={scale.events} />
          </>
        )}
      </PageContentWrapperLarge>
    </>
  )
}

export default HiveScaleView
