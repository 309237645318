export const RO_TRANSLATIONS: Record<string, any> = {
  translation: {
    hello: "Salut!",
    featureNotYetImplemented:
      "Această funcționalitate nu este încă implementată.",
    yes: "Da",
    no: "Nu",
    yesterday: "Ieri",
    today: "Astăzi",
    tomorrow: "Mâine",
    thisWeek: "Săptămâna aceasta",
    thisMonth: "Luna aceasta",
    lastMonth: "Luna trecută",
    thisYear: "Anul acesta",
    lastYear: "Anul trecut",
    pastYears: "Anii trecuți",
    homeTab: "Acasă",
    tasksTab: "Tasks",
    addBottomMenu: "Adaugă",
    farmTab: "Ferma mea",
    notifications: "Notificări",
    notification: "Notificare",
    menu: "Meniu",
    accountTab: "Contul meu",
    oneSecond: "Un moment...",
    weather: "Vremea",
    notes: "Notițe",
    addNote: "Adaugă o notiță",
    newNote: "Notiță nouă",
    myNote: "Notița mea",
    placeholderTitle: "Titlu",
    placeholderText: "Textul tău",
    deleteNote: "Șterge notiță",
    searchNote: "Caută notiță",
    noNotesFound: "Nu au fost găsite notițe",
    shareNote: "Distribuie notiță",
    formatNote: "Formatare",
    notesBackConfirmation:
      "Ați făcut modificări. Sigur doriți să părăsiți fără a salva?",
    sureWantToLeave: "Sigur doriți să părăsiți fără a salva?",
    youMadeChanges: "Ați făcut modificări.",
    unsavedChanges: "Modificări nesalvate!",
    continueWhereYouLeftOff: "Continuă de unde ai rămas",
    youHaveUnsavedChanges:
      "Aveți modificări nesalvate din sesiunea anterioară. Doriti sa continuati cu aceste modificari sau sa reununtati la ele?",
    youWillLoseChanges:
      "Veți pierde modificările nesalvate. Doriți să continuați?",
    backConfirmation: "Eşti sigur că vrei să te întorci la Notițe?",
    noteWillBeDeleted: "Notița în curs va fi ştearsă dacă da.",
    continue: "Continuă",
    leave: "Părăsește",
    discard: "Renunță",
    delete: "Șterge",
    stay: "Rămâi",
    save: "Salvează",
    goToNotes: "Mergi la notițe",
    seeAllNotes: "Vezi toate notițele",
    deleteNoteConfirmation: "Eşti sigur că vrei să ştergi Notița?",
    deletingNote: "Se şterge notița...",
    noteDeleted: "Notița a fost ştearsă!",
    errorDeletingNote: "A apărut o eroare la ştergerea notiței!",
    updatingNote: "Se actualizează notița...",
    noteUpdated: "Notița a fost actualizată!",
    errorUpdatingNote: "A apărut o eroare la actualizarea notiței!",
    postingNote: "Se creează notița...",
    notePosted: "Notița a fost creată!",
    notePostError: "A apărut o eroare la crearea notiței!",
    noNoteAssociated: "Nicio notiță asociată",
    oneNoteAssociated: "O notiță asociată",
    notesAssociated: "notițe asociate",
    noteContentIsMandatory: "Continutul notitei este obligatoriu!",
    seeOtherNotesAssociated: "Vezi celelalte notițe asociate",
    hideOtherNotesAssociated: "Ascunde celelalte notițe asociate",
    draft: "schiță",
    unsavedChangesIndicator: "modificări nesalvate",
    notEnoughData: "Nu există suficiente date pentru a afișa graficul",
    notesWillBeSetAsDraft:
      "Notițele vor fi setate ca şi schiță.Puteți revenii ulterior pentru a salva modificările.",
    noteWasLoadedWithPreviousData:
      "Notița a fost incarcată cu datele anterioare. Doriti sa pastrati datele si sa continuati?",

    //Tasks

    taskWasLoadedWithPreviousData:
      "Sarcina a fost incarcată cu datele anterioare. Doriti sa pastrati datele si sa continuati?",
    tasks: "Sarcini",
    dueTasks: "Sarcini Restante",
    currentTasks: "Sarcini Curente",
    futureTasks: "Sarcini Viitoare",
    searchTask: "Caută Sarcină",
    noTasksFound: "Nu au fost găsite sarcini",
    newTask: "Sarcină Nouă",
    myTask: "Sarcina Mea",
    title: "Titlu",
    description: "Descriere",
    deleteTask: "Şterge Sarcină",
    disinfestation: "Deparazitare",
    treatments: "Tratamente",
    status: "Status",
    category: "Categorie",
    chooseCategory: "Alege Categoria",
    ALL: "Toate Sarcinile",
    DONE: "Sarcini Finalizate",
    TODO: "Sarcini Curente",
    backConfirmationTasks: "Eşti sigur că vrei să te întorci la Sarcini?",
    backConfirmationSettings: "Eşti sigur că vrei să te întorci la Setări?",
    taskWillBeDeleted: "Sarcina în curs va fi ştearsă.",
    modificationsWillBeLost: "Modificările vor fi pierdute.",
    onTime: "La timp",
    before30: "30 min înainte",
    beforeHour: "1 oră înainte",
    beforeDay: "1 zi înainte",
    beforeWeek: "1 săptămână înainte",
    daily: "Zilnic",
    weekly: "Săptămânal",
    monthly: "Lunar",
    yearly: "Anual",
    Date: "Data",
    Hour: "Ora",
    Reminder: "Reminder",
    Recurrence: "Recurență",
    AssociateNote: "Asociază Notiță",
    AssociateAnimal: "Asociază Animal",
    AssociateTask: "Asociază Sarcină",
    dashboardCurrentTasks: "Curente",
    dashboardFutureTasks: "Viitoare",
    dashboardFinalizedTasks: "Finalizate",
    dashboardOverdueTasks: "Restante",
    seeAllTasks: "Vezi toate sarcinile",
    deleteTaskConfirmation: "Eşti sigur că vrei să ştergi Sarcina?",
    allCategories: "Toate categoriile",
    noNewTasks: "Nu ai sarcini noi",
    updatingTask: "Se actualizează sarcina...",
    taskUpdated: "Sarcina a fost actualizată!",
    errorUpdatingTask: "A apărut o eroare la actualizarea sarcinii!",
    creatingTask: "Se creează sarcina...",
    taskCreated: "Sarcina a fost creată!",
    errorCreatingTask: "A apărut o eroare la crearea sarcinii!",
    deletingTask: "Se şterge sarcina...",
    taskDeleted: "Sarcina a fost ştearsă!",
    errorDeletingTask: "A apărut o eroare la ştergerea sarcinii!",
    oneTaskAssociated: "O sarcină asociată",
    tasksAssociated: "sarcini asociate",
    taskNotFound: "Sarcina nu a fost găsită!",
    titleRequired: "Titlul este obligatoriu pentru a crea o sarcină",
    title5Characters: "Titlul trebuie să aibă cel puțin 5 caractere",
    title100Characters: "Titlul trebuie să aibă cel mult 100 de caractere",
    addCategory: "Adaugă Categorie",
    editCategory: "Editează Categoria",
    selectTaskCategory: "Selectează Categoria Sarcinii",
    taskWillBeSetAsDraft:
      "Sarcina va fi setată ca şi schiță. Puteți revenii ulterior pentru a salva modificările.",
    taskSetAsDraft: "Sarcina a fost setată ca şi schiță.",
    addTask: "Adaugă Sarcină",
    animalWasLoadedWithPreviousData:
      "Animalul a fost incarcat cu datele anterioare.Doriti sa pastrati datele si sa continuati?",
    animalWillBeSetAsDraft:
      "Animalul va fi setat ca şi schiță.Puteți revenii ulterior pentru a salva modificările.",
    ain: "Nr. Crotal",
    myFarm: "Ferma mea",
    searchAnimal: "Caută animal: Nume, nr crotal, status",
    outOfExploitation: "Ieşite din exploatație",
    removeAnimalFromExploitation: "Marchează ieşirea din fermă",
    searchBreed: "Caută Rasă",
    newAnimal: "Adaugă Animal",
    addCow: "Adaugă Bovină",
    myAnimal: "Animalul meu",
    addGoat: "Adaugă Capră",
    addSheep: "Adaugă Oaie",
    addPork: "Adaugă Porc",
    addBird: "Adaugă Pasăre",
    addMammal: "Adaugă Mamifer",
    addBee: "Adaugă Stup",
    statusCows: "Status Bovine",
    statusGoats: "Status Capre",
    statusSheep: "Status Oi",
    statusPork: "Status Porci",
    statusBirds: "Status Păsări",
    statusBee: "Status Stupine",
    statusAnimal: "Status Animale",
    backConfirmationAnimals: "Eşti sigur că vrei să te întorci la Ferma mea?",
    animalWillBeDeleted: "Animalul în curs va fi şters dacă da.",
    name: "Nume",
    surname: "Prenume",
    taskTitleError: "Titlul este obligatoriu pentru a crea o sarcină",
    crotal: "Crotal",
    Male: "Mascul",
    Female: "Femelă",
    allGenders: "Ambele Sexe",
    sex: "Sex",
    registryEntryWay: "Modalitate intrare în Registru",
    Birth: "Fătare",
    Beef: "Carne",
    Meat: "Carne",
    Purchase: "Achiziție",
    Production: "Producție",
    Dairy: "Lapte",
    Sale: "Vânzare",
    Sacrifice: "Sacrificare",
    Transfer: "Transfer",
    Loss: "Pierdere",
    Death: "Moarte",
    details: "Detalii",
    categories: "Categorii",
    deleteAnimal: "Şterge animal",
    birthDate: "Data naşterii",
    entryDate: "Data intrare în exploatare",
    ALL_ANIMALS: "Toate categoriile",
    ALL_GENDERS: "Toate sexele",
    Cows: "Bovine",
    Cow: "Vacă",
    singleSheep: "Ovină",
    Sheep: "Ovine",
    FEMALE_SHEEP: "Oaie",
    YOUNG_GOAT: "Ied",
    MALE_GOAT: "Țap",
    FEMALE_GOAT: "Capră",
    RAM: "Berbec",
    singlePork: "Porcină",
    Pork: "Suine",
    MALE_PIG: "Porc",
    FEMALE_PIG: "Scroafă",
    PIGLET: "Porcușor",
    singleGoat: "Caprină",
    Goat: "Caprine",
    breed: "Rasa",
    Lamb: "Miel",
    Chicken: "Găină",
    Cock: "Cocoş",
    Bee: "Albină",
    exploitEntryDate: "Dată intrare în exploatare",
    calves_count: "Fătări",
    gestation_count: "Confirmări de gestație",
    medical_treatments_count: "Tratamente",
    insemination_count: "Montă / Înseminări",
    lactation_count: "Lactații",
    nameAnimalError: "* Numele animalului este obligatoriu",
    categoryAnimalError: "* Categoria animalului este obligatorie",
    breedAnimalError: "* Rasa animalului este obligatorie",
    crotalAnimalError: "* Nr crotal animalului este obligatoriu",
    sexAnimalError: "* Sexul animalului este obligatoriu",
    birthDateAnimalError: "* Data nașterii animalului este obligatorie",
    entryDateAnimalError: "* Data intrării în exploatare este obligatorie",
    registryEntryWayAnimalError:
      "* Modalitatea de intrare în registru este obligatorie",
    productionAnimalError: "* Tipul de producție este obligatoriu",
    detailsAnimalError: "* Detaliile animalului sunt obligatorii",
    exploitEntryDateAnimalError:
      "* Data intrării în exploatare este obligatorie",
    fieldError: "* Acest câmp este obligatoriu",
    age: "Vârsta",
    years: "ani",
    year: "an",
    year_uppercase: "An",
    months: "luni",
    month: "lună",
    and: "şi",
    lessThanAMonth: "mai puțin de o lună",
    allAges: "Toate vârstele",
    age02cow: "Vițel",
    age23cow: "Junică",
    age3plusCow: "Matură",
    age510: "5 - 10 ani",
    species: "Specii",
    allSpecies: "Toate Speciile",
    Calf: "Vițel",
    Veal: "Junică",
    mature: "Matură",
    goats: "Capre",
    ALL_SPECIES: "Toate Speciile",
    heads: "Capete",
    addAnimal: "Adaugă animal",
    animalSummary: "Sumar Date Animal",
    animalMother: "Mama Animalului",
    animalFather: "Tatăl Animalului",
    seeFarm: "Vezi ferma mea",
    deleteAnimalConfirmation: "Eşti sigur că vrei să ştergi animalul?",
    deletingAnimal: "Se şterge animalul...",
    animalDeleted: "Animalul a fost şters!",
    errorDeletingAnimal: "A apărut o eroare la ştergerea animalului!",
    updatingAnimal: "Se actualizează animalul...",
    animalUpdated: "Animalul a fost actualizat!",
    animalUpdateError: "A apărut o eroare la actualizarea animalului!",
    creatingAnimal: "Se creează animalul...",
    animalCreated: "Animalul a fost creat!",
    animalCreateError: "A apărut o eroare la crearea animalului!",
    allAnimals: "Toate animalele",
    animals: "Animale",
    noAnimalAssociated: "Niciun animal asociat",
    oneAnimalAssociated: "Un animal asociat",
    oneAnimalSelected: "Un animal selectat",
    animalsAssociated: "animale asociate",
    seeOtherAnimalsAssociated: "Vezi celelalte animale asociate",
    hideOtherAnimalsAssociated: "Ascunde celelalte animale asociate",
    animalsSelected: "animale selectate",
    ainRequired: "Nr. Crotal este obligatoriu pentru a crea un animal",
    genderRequired: "Sexul este obligatoriu pentru a crea un animal",
    birthDateRequired: "Data nașterii este obligatorie pentru a crea un animal",
    entryDateRequired:
      "Data intrării în exploatare este obligatorie pentru a crea un animal",
    entryWayIsRequired: "Modalitatea de intrare în registru este obligatorie",
    intendedPurposeIsRequired: "Producția este obligatorie",
    speciesRequired: "Specia este obligatorie pentru a crea un animal",
    breedIsRequired: "Rasa este obligatorie pentru a crea un animal",
    entryDateMustBeLaterThanBirthDate:
      "Data intrării trebuie să fie ulterioară datei nașterii",
    birthDateCannotBeInTheFuture: "Data nașterii nu poate fi în viitor",
    entryDateCannotBeInTheFuture: "Data intrării nu poate fi în viitor",
    nameFarmRequired: "Numele fermei este obligatoriu!",
    nameMaxLength: "Numele trebuie să aibă cel mult 100 de caractere!",
    selectAnimalMother: "Selectează mama animalului",
    selectAnimalFather: "Selectează tatăl animalului",
    ALL_STATUSES: "Toate Statusurile",
    HEAT: "Călduri",
    GESTATING: "Gestante",
    LACTATING: "Lactație",
    ILL: "De investigat",
    HEALTHY: "Sănătoase",
    DRY: "Repaus",
    gestationPeriodCalendar: "Calendar Gestație",
    jurnal: "Jurnal",
    vocalAssistant: "Asistent Vocal",
    contactTheSpecialist: "Contactează Specialistul",
    contactSpecialist: "Contactează Specialistul",
    voiceAssistant: "Asistent Vocal",
    goodMorning: "Bună dimineața",
    goodAfternoon: "Bună ziua",
    goodEvening: "Bună seara",
    Monday: "Luni",
    Tuesday: "Marți",
    Wednesday: "Miercuri",
    Thursday: "Joi",
    Friday: "Vineri",
    Saturday: "Sâmbătă",
    Sunday: "Duminică",
    january: "Ianuarie",
    february: "Februarie",
    march: "Martie",
    april: "Aprilie",
    may: "Mai",
    june: "Iunie",
    july: "Iulie",
    august: "August",
    september: "Septembrie",
    october: "Octombrie",
    november: "Noiembrie",
    december: "Decembrie",
    dashboard: "Dashboard",
    allNotifications: "Toate notificările",
    ALL_NOTIFICATIONS: "Toate notificările",
    ALERTS: "Alerte",
    REMINDERS: "Remindere",
    INFO: "Informări",
    MANDATORY: "Obligatorii",
    allRead: "Toate",
    read: "Citite",
    unread: "Necitite",
    all: "Toate",
    seeAllNotifications: "Vezi toate notificările",
    noNewNotifications: "Nu ai notificări noi",
    notificationWasRead: "Notificarea a fost citită!",
    notificationsWereRead: "Notificările au fost citite!",
    updateNotificationError: "A apărut o eroare la actualizarea notificării!",
    updateNotificationsError:
      "A apărut o eroare la actualizarea notificărilor!",
    notificationError: "A apărut o eroare la notificare!",
    updatingNotification: "Se actualizează notificarea...",
    updatingNotifications: "Se actualizează notificările...",
    followLink: "Urmează link",
    youSureYouWantToReadThemAll:
      "Sunteți sigur că doriți să marcați toate notificările ca fiind citite?",
    markAllAsRead: "Marcați toate ca citite",
    cancel: "Anulează",
    settings: "Setări",
    farmProfile: "Profil Fermă",
    hiveProfile: "Profil Stupină",
    companyInfo: "Datele Firmei",
    farmName: "Numele Fermei",
    farmNameRequired: "Numele Fermei este obligatoriu",
    address: "Adresa",
    location: "Locație",
    selectLocation: "Locație (e.g., Clădirea A, Zona 4, Padoc 2)",
    setFarmImage: "Setează o nouă fotografie pentru fermă",
    takePhoto: "Fă o fotografie",
    choosePhoto: "Alege o fotografie",
    changePhoto: "Schimbă fotografia",
    deletePhoto: "Șterge fotografia",
    farmInfo: "Informații Firmă",
    addFarmName: "Numele Firmei",
    addCui: "CUI",
    accountSettings: "Setări Cont",
    myProfile: "Profilul Meu",
    resetPassword: "Resetare Parolă",
    notificationPreferences: "Preferințe Notificări",
    email: "Adresă de Email",
    pushNotifications: "Notificări Push",
    editPhone: "Editează Telefon",
    setUserImage: "Setează o nouă fotografie de profil",
    inputCurrentPassword: "Introduceți Parola Actuală",
    inputNewPassword: "Introduceți Parola Nouă",
    confirmNewPassword: "Confirmați Parola Nouă",
    information: "Informații",
    tutorials: "Tutoriale",
    faq: "Întrebări Frecvente",
    confidentiality: "Politica de Confidențialitate",
    termsAndServices: "Termeni și Condiții",
    appVersion: "Versiune Aplicație",
    whatIsFarmApp: "Ce este FARM APP?",
    lastUpdate: "Ultima Actualizare",
    yourOpinion: "Opinia Ta",
    recommend: "Recomandă",
    likeUs: "Apreciază-ne pe Social Media",
    contactUs: "Contactează-ne",
    followUs: "Urmărește-ne",
    recommendUsing: "Recomandă aplicația folosind:",
    subject: "Subiect",
    message: "Mesaj",
    signOut: "Deconectare",
    signOutConfirmation: "Ești sigur că vrei să ieși din aplicație?",
    deletePhotoConfirmation: "Ești sigur că vrei să ștergi fotografia?",
    linkWillBeHere: "Link-ul către aplicația noastră va fi aici",
    farmProfileUpdated: "Profilul fermei a fost actualizat!",
    userProfileUpdated: "Profilul a fost actualizat!",
    errorOccurred: "A apărut o eroare!",
    processing: "Procesare...",
    contact: "Contact",
    lostPassword: "Am uitat parola",
    password: "Parolă",
    lostYourPassword: "Ți-ai uitat parola?",
    dontWorryHappensToAllOfUs: "Nu-ți face griji, se întâmplă tuturor!",
    enterEmailResetPassword:
      "Introduceți adresa de email mai jos și vă vom trimite un link pentru resetarea parolei.",
    wrongLoginCredentials: "Email sau parolă greșită!",
    login: "Autentificare",
    logingIn: "Autentificare...",
    back: "Înapoi",
    welcome: "Bine ați venit!",
    nameUserRequired: "Numele este obligatoriu!",
    lastNameRequired: "Prenumele este obligatoriu!",
    lastNameMaxLength: "Prenumele trebuie să aibă maximum 100 de caractere",
    emailRequired: "Adresa de email este obligatorie!",
    phoneNumberRequired: "Numărul de telefon este obligatoriu!",
    send: "Trimite",
    urlCopiedSuccess: "URL-ul a fost copiat cu succes!",

    // Authentication
    createAccount: "Creează cont",
    creatingAccount: "Se creează contul...",
    signupSuccessful: "Înregistrare reușită!",
    verificationEmailSent: "Un email de verificare a fost trimis",
    pleaseCheckYourEmail: "Verificați-vă email-ul pentru a vă confirma contul",
    passwordResetSuccessful: "Resetare parolă reușită!",
    yourPasswordHasBeenReset: "Parola dumneavoastră a fost resetată cu succes",
    passwordChangeSuccessful: "Schimbare parolă reușită!",
    yourPasswordHasBeenChanged:
      "Parola dumneavoastră a fost schimbată cu succes",
    changePassword: "Schimbă parola",
    changing: "Se schimbă...",
    failedToChangePassword: "Schimbarea parolei a eșuat",
    incorrectCurrentPassword: "Parola actuală este incorectă",
    sessionExpired: "Sesiunea dumneavoastră a expirat",
    pleaseRefreshAndTryAgain:
      "Vă rugăm să reîmprospătați pagina și să încercați din nou",
    passwordsDoNotMatch: "Parolele nu coincid",
    passwordTooShort: "Parola trebuie să aibă cel puțin 8 caractere",
    proceedToLogin: "Mergeți la autentificare",
    backToLogin: "Înapoi la autentificare",
    failedToSignUp: "Înregistrarea a eșuat",
    failedToResetPassword: "Resetarea parolei a eșuat",
    dontHaveAccount: "Nu aveți cont?",
    alreadyHaveAccount: "Aveți deja cont?",
    firstName: "Prenume",
    lastName: "Nume",
    confirmPassword: "Confirmă parola",
    newPassword: "Parolă nouă",
    resetYourPassword: "Resetați-vă parola",
    enterNewPassword: "Introduceți noua parolă mai jos",
    passwordResetEmailSent: "Email de resetare a parolei trimis",
    checkYourEmailForResetLink:
      "Verificați-vă email-ul pentru link-ul de resetare a parolei",
    fillInDetailsBelow:
      "Completați detaliile de mai jos pentru a vă crea contul",
    invalidResetLink: "Link de resetare invalid",
    sending: "Se trimite...",
    resetting: "Se resetează...",

    // Email verification
    verifyingEmail: "Se verifică email-ul",
    pleaseWait: "Vă rugăm așteptați...",
    emailVerificationSuccessful: "Verificare email reușită!",
    youCanNowLogin:
      "Email-ul dumneavoastră a fost verificat. Acum vă puteți autentifica în cont.",
    emailVerificationFailed: "Verificarea email-ului a eșuat",
    invalidVerificationLink: "Link de verificare invalid",
    invalidOrExpiredLink: "Link-ul de verificare este invalid sau a expirat",

    // Gestation Period Calendar
    stepByStepGestationEvolution: "Evolutia sarcinii etapa cu etapa",
    computeProbableBirthDate: "Calculeaza data probabila a fatarii",
    testNow: "Testează acum",
    confirm: "Confirmă",
    underDevelopment: "Această funcționalitate este încă în dezvoltare",
    notAvailableInPackage: "Această funcționalitate nu este disponibilă",
    gestationalCalculator: "Calculator Gestațional",
    createNewCalendar: "Creează un nou calendar",
    generateCalendar: "Generează un calendar",
    saveCalendar: "Salvează calendarul",
    calendarSavedSuccessfully: "Calendarul a fost salvat cu succes!",
    animalMustBeFemale:
      "Pentru a crea un calendar gestațional animalul trebuie să fie femelă",
    animalMustBeCow: "Calendarul gestațional este disponibil doar pentru vaci",
    loadingCalendarPreview: "Se încarcă previzualizarea calendarului...",
    previewLoadedSuccessfully: "Previzualizarea a fost încărcată cu succes!",
    errorLoadingPreview: "A apărut o eroare la încărcarea previzualizării!",
    errorFetchingAnimal: "A apărut o eroare la preluarea animalului!",
    searchCalendar: "Caută calendar",
    selectAnimal: "Selecteaza Animalul",
    gestationalCalendar: "Calendar Gestațional",
    deleteCalendar: "Șterge Calendarul",
    deletingCalendar: "Se șterge calendarul...",
    calendarDeletedSuccessfully: "Calendarul a fost șters cu succes!",
    calendarDeleteError: "A apărut o eroare la ștergerea calendarului!",
    calendarWillBeDeleted: "Calendarul în curs va fi șters.",
    deleteCalendarConfirmation: "Ești sigur că vrei să ștergi calendarul?",
    deleteAssocAnimalEvents:
      "Doreşti să ştergi şi evenimentele asociate calendarului?",
    toCalving: "până la fătare",
    untilCalvingIntervalEnd: "până la finalul intervalului",
    days: "zile",
    toNextTask: "pâna la următoarea sarcină",
    hours: "ore",
    minutes: "minute",
    nextActivity: "Următoarea Activitate",
    gestationalCalendarText:
      "Calculează data fătării și generează sarcini utile pentru perioada de gestație.",
    calendarPreviewLoadedSuccessfully:
      "Previzualizarea calendarului a fost încărcată cu succes!",

    // Finance Widget
    financialJournal: "Jurnal Financiar",
    searchTransaction: "Caută Tranzacție",
    addTransaction: "Adaugă Tranzacție",
    transaction: "Tranzacție",
    transactions: "Tranzacții",
    EXPENSES: "Cheltuieli",
    Outflow: "Cheltuieli",
    INCOMES: "Venituri",
    Inflow: "Venituri",
    selectTransactionCategory: "Selectează categoria tranzacției",
    EU_FOUNDS: "Fonduri Europene",
    PROFIT: "Profit",
    SELLING_ANIMALS: "Vânzare Animale",
    DONATIONS: "Donații",
    SELLING_GOODS: "Vânzare Produse",
    EQUIPMENT_ACQUISITION: "Achiziție Echipamente",
    MAINTENANCE: "Întreținere",
    ANIMALS_FOOD: "Hrană Animale",
    TREATMENTS: "Tratamente",
    TRANSPORTATION: "Transport",
    VETERINARY: "Servicii Veterinare",
    SALARIES: "Salarii",
    SOFTWARE_ACQUISITION: "Achiziție Software",
    ADD_CATEGORY: "Adaugă Categorie",
    categoryName: "Numele Categoriei",
    loadingCategories: "Se încarcă categoriile...",
    categorySaved: "Categoria a fost salvată!",
    errorCreatingCategory: "A apărut o eroare la crearea categoriei!",
    savingCategory: "Se salvază categoria...",
    updatingCategory: "Se actualizează categoria...",
    categoryUpdated: "Categoria a fost actualizată!",
    errorUpdatingCategory: "A apărut o eroare la actualizarea categoriei!",
    deleteCategory: "Șterge Categoria",
    deletingCategory: "Se șterge categoria...",
    categoryDeleted: "Categoria a fost ștearsă!",
    errorDeletingCategory: "A apărut o eroare la ștergerea categoriei!",
    transactionHistory: "Istoric Tranzacții",
    savingTransaction: "Se salvează tranzacția...",
    transactionSaved: "Tranzacția a fost salvată!",
    transactionSaveError: "A apărut o eroare la salvarea tranzacției!",
    deleteTransactionConfirmation: "Ești sigur că vrei să ștergi tranzacția?",
    deleteTransactionCategoryConfirmation:
      "Ești sigur că vrei să ștergi categoria?",
    transactionCategoryWillBeDeleted: "Categoria tranzacției va fi ștearsă.",
    transactionWillBeDeleted: "Tranzacția în curs va fi ștearsă.",
    deleteTransaction: "Șterge Tranzacția",
    transactionDeleted: "Tranzacția a fost ștearsă!",
    deletingTransaction: "Se şterge tranzacția...",
    startDate: "Data de început",
    viewStats: "Vizualizează statisticile",
    endDate: "Data de final",
    last_week: "Săptămâna trecută",
    last_month: "Luna trecută",
    current_month: "Luna curentă",
    last_year: "Anul trecut",
    custom: "Perioadă personalizată",
    selectTimePeriod: "Selectează perioada de timp",
    all_time: "Toată perioada",
    current_week: "Săptamâna curentă",
    noTransactionsFound: "Upsss, nu s-au găsit tranzacții...",
    noAnimalsFound: "Upsss, nu s-au găsit animale...",
    lastTransactions: "Ultimele Tranzacții",

    amountRequired: "Suma este obligatorie pentru a crea o tranzacție",
    dateRequired: "Data este obligatorie pentru a crea o tranzacție",
    categoryRequired: "Categoria este obligatorie pentru a crea o tranzacție",
    balance: "Balanță",
    financialStats: "Statistici Financiare",
    weAreSorry: "Ne cerem scuze...",
    anErrorOccurred: "A apărut o eroare.",
    reloadApp: "Reîncarcă aplicația",
    events: "Evenimente",
    addEvent: "Adaugă Eveniment",
    addAnimalExit: "Ieșirea Animalului",
    confirmCalving: "Confirmară Fătarea",
    calvingConfirmed: "Fătarea a fost confirmată",
    selectEventType: "Selectează Tipul Evenimentului",
    selectMedication: "Selectează Medicamentul",
    Treatment: "Tratament",
    Measurement: "Măsurare",
    Movement: "Mișcare",
    Insemination: "Inseminare",
    NaturalMating: "Împerechere Naturală",
    PregnancyCheckPositive: "Verificare Sarcină Pozitivă",
    PregnancyCheckNegative: "Verificare Sarcină Negativă",
    HealthCheckPositive: "Control de Sănătate Pozitiv",
    HealthCheckNegative: "Control de Sănătate Negativ",
    HoofHealthCheck: "Control Sănătate Copită",
    HeatDetection: "Detectare Călduri",
    Abortion: "Avort",
    DryOff: "Oprire Lactație",
    Weaning: "Înțărcare",
    LactationStart: "Începere Lactație",
    LactationEnd: "Sfârșit Lactație",
    DietChange: "Schimbare Dietă",
    Shearing: "Tuns",
    HoofTrimming: "Tundere Copită",
    EarTagging: "Marcaj Ureche",
    AnimalTransferExitEventType: "Evenimente Ieşire",
    AnimalSacrifice: "Sacrificare",
    AnimalSale: "Vânzare Animal",
    AnimalDeath: "Moartea Animalului",
    AnimalLoss: "Pierderea Animalului",
    AnimalTransferExit: "Transfer Ieşire",
    Castration: "Castrare",
    Dehorning: "Decornare",
    VeterinaryVisit: "Vizită Veterinar",
    BehaviorObservation: "Observare Comportament",
    EntryIntoQuarantine: "Intrare în carantină",
    ExitFromQuarantine: "Ieșire din carantină",
    Isolation: "Izolare",
    Vaccination: "Vaccinare",
    IllnessTreatment: "Tratament Boală",
    Deworming: "Deparazitare",
    InjuryTreatment: "Tratament Rănire",
    Surgery: "Chirurgie",
    PostSurgeryFollowUp: "Urmărire Post-Operatorie",
    SupplementAdministration: "Administrare Supliment",
    WeightCheck: "Verificare Greutate",
    BodyConditionScoring: "Evaluare Condiție Corporală",
    Other: "Altele",
    addEventType: "Adaugă un nou tip de eveniment",
    addMedicationType: "Adaugă un nou tratament",
    eventName: "Numele Evenimentului",
    medicationName: "Numele Tratamentului",
    deleteEventType: "Şterge tipul evenimentului",
    fromLocation: "De la Locația",
    fromPosition: "De la Poziția",
    toPosition: "La Poziția",
    toLocation: "Către Locația",
    departureDate: "Data Plecării",
    arrivalDate: "Data Sosirii",
    HeightCheck: "Verificare Înălțime",
    weight: "Greutate",
    otherObservations: "Alte Observații",
    generalEventDetails: "Detalii Generale Eveniment",
    specificEventDetails: "Detalii Specifice Eveniment",
    transactionAmount: "Suma Tranzacției",
    value: "Valoare",
    savingEvent: "Se salvează evenimentul...",
    eventSaved: "Evenimentul a fost salvat!",
    viewEvent: "Vizualizează evenimentul",
    errorSavingEvent: "A apărut o eroare la salvarea evenimentului!",
    dosage: "Dozaj",
    deleteAnimalEvent: "Șterge Eveniment",
    animalEvent: "Evenimentul Animalului",
    deletingAnimalEvent: "Se șterge evenimentul...",
    animalEventDeleted: "Evenimentul a fost șters!",
    animalEventDeleteError: "A apărut o eroare la ștergerea evenimentului!",
    deleteAnimalEventConfirmation: "Ești sigur că vrei să ștergi Evenimentul?",
    eventWillBeDeleted: "Evenimentul în curs va fi șters dacă da.",
    updatingAnimalEvent: "Se actualizează evenimentul...",
    animalEventUpdated: "Evenimentul a fost actualizat!",
    animalEventUpdateError: "A apărut o eroare la actualizarea evenimentului!",
    typeRequired: "Tipul este obligatoriu pentru a crea un eveniment",
    dateRequiredEvent: "Data este obligatorie pentru a crea un eveniment",
    animalRequired: "Animalul este obligatoriu pentru a crea un eveniment",
    fromLocationRequired:
      "Locația de plecare este obligatorie pentru a crea acest eveniment",
    toLocationRequired:
      "Locația de sosire este obligatorie pentru a crea acest eveniment",
    valueRequired: "Valoarea este obligatorie pentru a crea acest eveniment",
    dosageRequired: "Dozajul este obligatoriu pentru a crea acest eveniment",
    medicationRequired:
      "Medicamentul este obligatoriu pentru a crea acest eveniment",
    quantityRequired:
      "Cantitatea este obligatorie pentru a crea acest eveniment",
    positionRequired: "Poziția este obligatorie pentru a crea acest eveniment",
    toApiaryRequired:
      "Stupina destinație este obligatorie pentru a crea acest eveniment",
    TreatmentEventType: "Evenimente de Tratament",
    MeasurementEventType: "Evenimente de Măsurare",
    MovementEventType: "Evenimente de Mișcare",
    ReproductiveEventType: "Evenimente de Reproducere",
    OtherEventType: "Alte Evenimente",
    searchAnimalEvent: "Caută Evenimentul Animalului",

    animalEvents: "Evenimente Animale",
    savingEventType: "Se salvază tipul evenimentului...",
    savingMedicationType: "Se salvează tipul de tratament...",
    eventTypeSaved: "Tipul evenimentului a fost salvat!",
    medicationTypeSaved: "Tipul de tratament a fost salvat!",
    errorCreatingEventType:
      "A apărut o eroare la crearea tipului evenimentului",
    errorCreatingMedicationType:
      "A apărut o eroare la crearea tipului de tratament",
    updatingEventType: "Se actualizează tipul evenimentului",
    updatingMedicationType: "Se actualizează tipul de tratament",
    eventTypeUpdated: "Actualizare cu success!",
    medicationTypeUpdated: "Actualizare cu success!",
    errorUpdatingEventType:
      "A apărut o eroare la actualizarea tipului evenimentului!",
    errorUpdatingMedicationType:
      "A apărut o eroare la actualizarea tipului de tratament!",
    deleteEventTypeConfirmation:
      "Ești sigur că vrei să ștergi tipul evenimentului?",
    deleteMedicationTypeConfirmation:
      "Ești sigur că vrei să ștergi tipul de tratament?",
    eventTypeWillBeDeleted: "Tipul evenimentului va fi ștears.",
    medicationTypeWillBeDeleted: "Tipul de tratament va fi șters.",
    deletingEventType: "Se șterge tipul evenimentului...",
    deletingMedicationType: "Se șterge tipul de tratament...",
    eventTypeDeleted: "Tipul evenimentului a fost şters!",
    medicationTypeDeleted: "Tipul de tratament a fost şters!",
    errorDeletingEventType:
      "A apărut o eroare la ștergerea tipului evenimentului",
    errorDeletingMedicationType:
      "A apărut o eroare la ștergerea tipului de tratament",
    chooseEventTypeParent: "Selectează categoria evenimentului",
    intelligentAssistant: "Asistent Inteligent",
    yourMessage: "Mesajul tău",
    generalHealthStatus:
      "Care este starea generală de sănătate a efectivului de bovine?",
    immediateMedicalAttention:
      "Care sunt vacile care necesită atenție medicală imediată?",
    optimizeFeeding:
      "Cum pot optimiza hrănirea pentru a crește producția de lapte?",
    closeToCalving: "Care sunt vacile care sunt aproape de fătare?",
    generalHealthStatusPigs:
      "Care este starea generală de sănătate a efectivului de porci?",
    growthRate: "Care este rata de creștere a porcilor în ultima lună?",
    pregnantSows:
      "Care sunt scroafele gestante care necesită atenție specială?",
    weaningStatus: "Care sunt purceii care urmează să fie înțărcați?",
    diseasePreventionStatus:
      "Care este statusul măsurilor de prevenire a bolilor în efectiv?",
    generalHealthStatusGoat:
      "Care este starea generală de sănătate a efectivului de caprine?",
    milkProduction: "Cum se prezintă producția de lapte în ultima perioadă?",
    pregnantGoats: "Care sunt caprele gestante care necesită monitorizare?",
    kidsHealth: "Care este starea de sănătate a iezilor?",
    generalHealthStatusSheep:
      "Care este starea generală de sănătate a efectivului de ovine?",
    lambingStatus: "Care sunt oile care urmează să fete în perioada următoare?",
    lambGrowth: "Care este rata de creștere a mieilor?",
    generalHealthStatusBees:
      "Care este starea generală de sănătate a familiilor de albine?",
    honeyProduction:
      "Cum se prezintă producția de miere față de perioada anterioară?",
    queenStatus: "Care este starea mătcilor în colonii?",
    welcomeToAssistant: "Bun venit la Asistentul Inteligent!",
    startConversation:
      "Începe conversația cu cateva dintre sugestiile noastre.",
    loading: "Se încarcă...",
    UNDER_TREATMENT: "Tratament",
    noResultsForAnimal: "Nu s-au găsit rezultate pentru animalul specificat.",
    askAssistant: "Întreabă Asistentul",
    virtualAssistant: "Asistent Virtual",
    virtualFarmAssistant: "Asistent Virtual Fermă",
    farmAdviceWithAI:
      "Sfaturi și informații personalizate pentru fermă cu noul chat AI integrat.",
    noAnimalsFoundForFilter: "Nu s-au găsit animale pentru filtrul selectat",
    gestationCalendars: "Calendare Gestaționale",
    activityCalendar: "Calendar Activități",
    inseminationDate: "Data însămânțării",
    possibleCalvingInterval: "Interval probabil de fătare",
    calvingDate: "Data fătării",
    openCalendar: "Deschide Calendar",
    seeEvents: "Arată evenimente",
    hideEvents: "Ascunde evenimente",
    thisFeatureIsNotEnabled: "Acest modul nu este activ",
    buyFeature: "Cumpără modulul",
    noGestationCalendarsPresent: "Nu ai niciun calendar gestațional",
    filterByAge: "Filtrare după vârstă",
    currentInterval: "Interval curent",
    resetFilter: "Resetează filtrul",
    quickSelection: "Selecție rapidă",
    myApiaries: "Stupinele Mele",
    myApiary: "Stupina Mea",
    myHives: "Stupii Mei",
    myQueens: "Mătcile Mele",
    addApiary: "Adaugă Stupină",
    addHive: "Adaugă Stup",
    isLoading: "Se încarcă...",
    hiveDetails: "Detalii Stup",
    apiaryDetails: "Detalii Stupină",
    hiveImage: "Imaginea Stupului",
    hiveCode: "Cod/Nume unic",
    hiveType: "Tipul stupului",
    queenBirthYear: "Anul de naștere al mătcii",
    queenOrigin: "Originea mătcii",
    queen: "Matcă",
    framesNumber: "Număr de rame",
    familyPower: "Puterea familiei",
    beeCount: "Număr de albine",
    broodFramesNumber: "Număr de rame cu puiet",
    honeyFramesNumber: "Număr de rame cu miere",
    emptyFramesNumber: "Număr de rame goale",
    apiaryName: "Denumirea stupinei",
    apiaryAddress: "Adresă",
    dsvsa: "Nr. înregistrare/ autorizare DSVSA",
    nameRequired: "Numele este obligatoriu!",
    nameMinLength: "Lungimea minima a numelui este de 2 caractere!",
    addressRequired: "Adresa este obligatorie!",
    addressMinLength: "Lungimea minima a adresei este de 5 caractere!",
    addressMaxLength: "Lungimea maxima a adresei este de 200 caractere!",
    registrationCodeRequired: "Codul de înregistrare este obligatoriu!",
    registrationCodeFormat:
      "Codul de înregistrare poate conține doar litere, cifre și cratime!",
    descriptionMaxLength:
      "Lungimea maxima a descrierii este de 1000 caractere!",
    creatingApiary: "Se creeazǎ stupina...",
    apiaryCreated: "Stupina a fost creeatǎ!",
    apiaryCreateError: "A apǎrut o eroare la creearea stupinei",
    updatingApiary: "Se actualizeazǎ stupina...",
    deletingApiary: "Se şterge stupina...",
    apiaryDeleted: "Stupina a fost ştearsǎ",
    apiaryUpdated: "Stupina a fost actualizatǎ!",
    apiaryUpdateError: "A apǎrut o eroare la actualizarea stupinei",
    apiaryDeleteError: "A apǎrut o eroare la ştergerea stupinei",
    registrationCode: "Codul de înregistrare",
    hivesNumber: "Numǎrul de stupi",
    deleteApiary: "Şterge stupina",
    apiaries: "Stupine",
    hives: "Stupi",
    deleteApiaryConfirmation: "Eşti sigur că vrei să ştergi stupina?",
    apiaryWillBeDeleted:
      "Stupina, împreuna cu toate datele asociate, precum stupii,reginele si evenimentele acestora vor fi şterse",
    hiveManufacturer: "Producatorul stupului",
    creatingHive: "Se creeazǎ stupul...",
    hiveCreatedSuccessfully: "Stupul a fost creeat cu succes!",
    errorCreatingHive: "A apǎrut o eroare la creearea stupului!",
    apiary: "Stupina",
    apiaryRequired: "Stupina este obligatorie",
    apiaryFormat: "Codul stupinei poate conține doar litere, cifre și cratimă",
    codeRequired: "Codul stupului este obligatoriu",
    codeFormat: "Codul stupului poate conține doar litere, cifre și cratimă",
    codeMinLength: "Codul stupului trebuie să aibă minim 2 caractere",
    codeMaxLength: "Codul stupului nu poate depăși 50 de caractere",
    hiveTypeRequired: "Tipul stupului este obligatoriu",
    invalidHiveType: "Tipul stupului selectat nu este valid",
    manufacturerRequired: "Producătorul stupului este obligatoriu",
    manufacturerMinLength:
      "Numele producătorului trebuie să aibă minim 2 caractere",
    manufacturerMaxLength:
      "Numele producătorului nu poate depăși 100 de caractere",
    broodFramesRequired: "Numărul ramelor cu puiet este obligatoriu",
    broodFramesFormat:
      "Numărul ramelor cu puiet trebuie să fie un număr întreg",
    broodFramesRange: "Numărul ramelor cu puiet trebuie să fie între 0 și 100",
    honeyFramesRequired: "Numărul ramelor cu miere este obligatoriu",
    honeyFramesFormat:
      "Numărul ramelor cu miere trebuie să fie un număr întreg",
    honeyFramesRange: "Numărul ramelor cu miere trebuie să fie între 0 și 100",
    emptyFramesRequired: "Numărul ramelor goale este obligatoriu",
    emptyFramesFormat: "Numărul ramelor goale trebuie să fie un număr întreg",
    emptyFramesRange: "Numărul ramelor goale trebuie să fie între 0 și 100",
    beeCountRequired: "Numărul de albine este obligatoriu",
    beeCountFormat: "Numărul de albine trebuie să fie un număr întreg",
    beeCountRange: "Numărul de albine trebuie să fie între 0 și 100000",
    addQueen: "Adaugǎ Matcǎ",
    deleteHive: "Şterge stupul",
    hiveUpdating: "Se actualizeazǎ stupul...",
    hiveUpdatedSuccessfully: "Stupul a fost actualizat!",
    errorUpdatingHive: "A apǎrut o eroare la actualizarea stupului",
    deleteHiveConfirmation: "Eşti sigur că vrei să ştergi stupul?",
    hiveWillBeDeleted:
      "Stupul, împreună cu toate datele asociate, precum regina și evenimentele acestora vor fi șterse",
    deletingHive: "Se şterge stupul...",
    deletingQueen: "Se şterge matca...",
    queenDeleted: "Matca a fost ştearsǎ!",
    hiveDeleted: "Stupul a fost şters!",
    queenDeleteError: "A apărut o eroare la ştergerea mǎtcii",
    hiveDeleteError: "A apărut o eroare la ştergerea stupului",
    yearOfBirth: "Anul nașterii",
    queenColor: "Culoarea mǎtcii",
    "Own Production": "Producție proprie",
    Purchased: "Achiziționare",
    Blue: "Albastru",
    Green: "Verde",
    Red: "Roșu",
    Yellow: "Galben",
    Black: "Negru",
    White: "Alb",
    nameFormat:
      "Numele reginei poate conține doar litere, cifre, spații și cratimă",
    yearRequired: "Anul este obligatoriu",
    yearFormat: "Anul trebuie să fie în format YYYY (ex: 2024)",
    yearRange: "Anul trebuie să fie între ultimii 5 ani și anul curent",
    colorRequired: "Culoarea reginei este obligatorie",
    invalidColor: "Culoarea selectată nu este validă",
    originRequired: "Originea reginei este obligatorie",
    invalidOrigin: "Originea selectată nu este validă",
    creatingQueen: "Se creeazǎ regina...",
    queenCreated: "Regina a fost creeatǎ!",
    queenCreateError: "A apǎrut o eroare la creearea reginei",
    hive: "Stup",
    deleteQueen: "Şterge matca",
    queenUpdating: "Se actualizeazǎ matca",
    queenUpdatedSuccessfully: "Matca a fost actualizatǎ!",
    errorUpdatingQueen: "A apǎrut o eroare la actualizarea reginei",
    deleteQueenConfirmation: "Eşti sigur că vrei să ştergi matca?",
    queenWillBeDeleted:
      "Matca, împreună cu toate datele asociate, notitele, sarciniile si evenimentele vor fi șterse",
    bees: "Albine",
    allApiaries: "Toate Stupinele",
    searchHive: "Cautǎ stup",
    allHiveTypes: "Toate tipurile de stup",
    apiaryWasSuccessfullyAdded: "Stupina a fost adăugată cu succes!",
    hiveStep:
      "Următorul pas este adăugarea stupilor în noua dumneavoastră stupină, doriți să continuați?",
    hiveWasSuccessfullyAdded: "Stupul a fost adăugat cu succes!",
    queenStep:
      "Următorul pas este adăugarea mătcii în noul dumneavoastră stup, doriți să continuați?",
    noHivesFoundForGivenQuery:
      "Nu au fost gǎsiți stupi pentru cǎutarea dumneavoastrǎ",
    noQueensFoundForGivenQuery:
      "Nu au fost gǎsite mǎtci pentru cǎutarea dumneavoastrǎ",
    allQueens: "Toate Mǎtcile",
    assocApiary: "Asociazǎ Stupinǎ",
    assocHive: "Asociazǎ Stup",
    noApiariesAssociated: "Nu exista stupine asociate",
    noHivesAssociated: "Nu exista stupi asociati",
    oneApiaryAssociated: "O stupina asociata",
    oneHiveAssociated: "Un stup asociat",
    apiariesAssociated: "stupine asociate",
    hivesAssociated: "stupi asociati",
    generalAnimalWelfare:
      "Care este starea generală de bunăstare a animalelor?",
    diseasePreventionPlan:
      "Care este planul de prevenție a bolilor pentru toate animalele?",
    feedingStrategyGeneral:
      "Este optimă strategia de hrănire pentru toate animalele?",
    reproductiveHealthGeneral:
      "Care este situația sănătății reproductive în efectiv?",
    udderHealth: "Care este starea de sănătate a ugerului la vaci?",
    heatDetection: "Care vaci prezintă semne de călduri?",
    pigletMortality: "Care este rata mortalității la purcei?",
    boarPerformance: "Care este performanța vierilor de reproducție?",
    generalHealthStatusGoats:
      "Care este starea generală de sănătate a efectivului de caprine?",
    hoofHealth: "Care este starea copitelor la caprine?",
    pasturePlanning: "Este eficient planul de pășunat?",
    flockBehavior: "Sunt modificări în comportamentul turmei?",
    sheepSorting: "Care oi necesită sortare pentru diferite grupuri?",
    hiveStrength: "Care este puterea familiilor de albine?",
    varoaStatus: "Care este nivelul de infestare cu varroa?",
    nectarSources: "Sunt suficiente surse de nectar în zonă?",
    biosecurityMeasures: "Care sunt măsurile de biosecuritate implementate?",
    selectApiaries: "Selectează stupinele",
    selectApiary: "Selectează stupina",
    selectHive: "Selectează stupul",
    selectHives: "Selectează stupii",
    noResultsFound: "Nu au fost găsite rezultate",
    hideOtherApiariesAssociated: "Ascunde celelalte stupine asociate",
    hideOtherHivesAssociated: "Ascunde ceilalți stupi asociati",
    seeOtherApiariesAssociated: "Vezi celelalte stupine asociate",
    seeOtherHivesAssociated: "Vezi ceilați stupi asociati",
    noApiaryFound: "Nu a fost găsită nicio stupină",
    noQueensAssociated: "Nu existǎ mǎtci ascociate",
    oneQueenAssociated: "O matcă asociată",
    queensAssociated: "mǎtci asociate",
    selectQueens: "Selectează mǎtcile",
    assocQueen: "Asociazǎ Matcă",
    theHive: "Stupul",
    addApiaryEvent: "Adaugă Eveniment Stupină",
    noApiarySelected: "Nu a fost selectată nicio stupină",
    noHiveSelected: "Nu a fost selectat niciun stup",
    PestControl: "Control Dăunători",
    VegetationManagement: "Managementul Vegetației",
    EquipmentMaintenance: "Întreținere Echipament",
    InfrastructureRepair: "Reparații Infrastructură",
    ApiaryMaintenance: "Întreținere Stupină",
    SpringAssessment: "Evaluare de Primăvară",
    WinterPreparationCheck: "Verificare Pregătire pentru Iarnă",
    AuthorityInspection: "Inspecție Autorități",
    OfficialVeterinaryInspection: "Inspecție Veterinară Oficială",
    ApiaryInspection: "Inspecție Stupină",
    PastoralMovement: "Deplasare Pastorală",
    PermanentLocationChange: "Schimbare Permanentă Locație",
    ApiaryMovement: "Deplasare Stupină",
    personName: "Numele Persoanei",
    eventTypeRequired: "Tipul evenimentului este obligatoriu",
    eventTypeFormat:
      "Tipul evenimentului trebuie să conțină doar litere, numere și cratime",
    personNameMaxLength: "Numele persoanei nu poate depăși 255 caractere",
    dateFormat: "Format dată invalid",
    notesMaxLength: "Notele nu pot depăși 1000 caractere",
    creatingApiaryEvent: "Se creează evenimentul stupinei...",
    apiaryEventCreatedSuccessfully:
      "Evenimentul stupinei a fost creat cu succes!",
    errorCreatingApiaryEvent:
      "A apărut o eroare la crearea evenimentului stupinei",
    apiariesEvents: "Evenimente Stupine",
    apiaryEvent: "Eveniment Stupină",
    deletingApiaryEvent: "Se șterge evenimentul stupinei...",
    apiaryEventDeleted: "Evenimentul stupinei a fost șters!",
    apiaryEventDeleteError:
      "A apărut o eroare la ștergerea evenimentului stupinei",
    ApiaryMovementEventType: "Evenimente de Deplasare a Stupinei",
    ApiaryInspectionEventType: "Evenimente de Inspecție Stupinei",
    ApiaryMaintenanceEventType: "Evenimente de Întreținere Stupinei",
    Acacia: "Salcâm",
    Linden: "Tei",
    Sunflower: "Floarea Soarelui",
    Rapeseed: "Rapiță",
    "Poly Floral": "Poliflor",
    selectFloraType: "Selectează Tipul de Floră",
    apiaryEvents: "Evenimente Stupină",
    apiaryEventsDescription: "Monitorizeaza activitatea stupinelor tale",
    hiveEventsDescription: "Monitorizeaza activitatea stupilor tăi",
    noEventsFound: "Nu au fost găsite evenimente",
    departureDateRequired: "Data plecării este obligatorie",
    floraTypeRequired: "Tipul de floră este obligatoriu",
    partners: "Parteneri",
    viewOffer: "Vezi Oferta",
    allTimes: "Toate perioadele",
    selectTimeFrame: "Selectează perioada",
    dateTo: "Data de final",
    dateFrom: "Data de început",
    present: "Prezent",
    selectDateTo: "Selectează data de final",
    selectDateFrom: "Selectează data de început",
    showResults: "Arată Rezultatele",
    hiveEvents: "Evenimente Stupi",
    queenEvents: "Evenimente Mătci",
    queenEventsDescription: "Monitorizeaza activitatea reginelor tale",
    allHives: "Toți Stupii",
    creatingHiveEvent: "Se creează evenimentul stupului...",
    hiveEventCreatedSuccessfully:
      "Evenimentul stupului a fost creat cu succes!",
    errorCreatingHiveEvent:
      "A apărut o eroare la crearea evenimentului stupului",
    "Hive must have a queen for this operation":
      "Pentru această operațiune stupul trebuie să aibă o matcă",
    ColonyDivision: "Divizarea Coloniei",
    SwarmPrevention: "Prevenirea Roirii",
    RemovingSuper: "Îndepărtarea Magaziei",
    AddingSuper: "Adăugarea Magaziei",
    AddingFrames: "Adăugarea Ramelor",
    QueenReplacement: "Înlocuirea Reginei",
    Feeding: "Hrănire",
    HiveManagement: "Gestionarea Stupului",
    WeightMeasurement: "Măsurarea Greutății",
    BroodFramesCount: "Actualizarea Nr. Ramelor cu Puiet",
    HoneyFramesCount: "Actualizarea Nr. Ramelor cu Miere",
    ColonyStrengthAssessment: "Evaluarea Puterii Coloniei",
    HiveMeasurement: "Măsurarea Stupului",
    PreventiveTreatment: "Tratament Preventiv",
    DiseaseTreatment: "Tratament Boli",
    NosemaTreatment: "Tratament Nosema",
    VarroaTreatment: "Tratament Varroa",
    HiveTreatment: "Tratament Stup",
    WinterCheck: "Verificare de Iarnă",
    BroodInspection: "Inspecția Puietului",
    QueenInspection: "Inspecția Reginei",
    DiseaseCheck: "Verificare Boli",
    RoutineCheck: "Verificare de Rutină",
    HiveInspection: "Inspecția Stupului",
    TransferToOtherApiary: "Transfer la Altă Stupină",
    TransferWithinApiary: "Transfer în Stupină",
    HiveMovement: "Mutarea Stupului",
    hiveEvent: "Eveniment Stup",
    HiveEquipment: "Echipament Stup",
    AddFrames: "Adaugă Rame",
    RemoveFrames: "Îndepărtează Rame",
    AddSuper: "Adaugă Magazie",
    RemoveSuper: "Îndepărtează Magazie",
    AddBroodBox: "Adaugă Cutie Puiet",
    RemoveBroodBox: "Îndepărtează Cutie Puiet",
    deletingHiveEvent: "Se șterge evenimentul stupului...",
    hiveEventDeleted: "Evenimentul stupului a fost șters!",
    hiveEventDeleteError:
      "A apărut o eroare la ștergerea evenimentului stupului",
    deleteHiveEvent: "Șterge Eveniment",
    deleteHiveEventConfirmation: "Ești sigur că vrei să ștergi evenimentul?",
    hiveEventWillBeDeleted: "Evenimentul în curs va fi șters dacă da.",
    updatingHiveEvent: "Se actualizează evenimentul stupului...",
    hiveEventUpdated: "Evenimentul stupului a fost actualizat!",
    hiveEventUpdateError:
      "A apărut o eroare la actualizarea evenimentului stupului",
    HiveMovementEventType: "Evenimente de Mutare a Stupului",
    HiveInspectionEventType: "Evenimente de Inspecție a Stupului",
    HiveTreatmentEventType: "Evenimente de Tratament al Stupului",
    HiveMeasurementEventType: "Evenimente de Măsurare a Stupului",
    HiveEquipmentEventType: "Evenimente de Echipare a Stupului",
    hiveRequired: "Stupul este obligatoriu!",
    beeKeepingEvents: "Evenimente Apicultură",
    pieces: "bucăți",
    grams: "grame",
    inHivePosition: "Poziția în stup",
    addNewLocation: "Adaugă o nouă locație",
    previousLocations: "Locații anterioare",
    noPreviousLocations: "Nu există locații anterioare",
    addLocation: "Adaugă Locația",
    currentLocation: "Locația curentă",
    noCurrentLocation: "Nu există locație curentă",
    currentPosition: "Poziția curentǎ",
    currentWeight: "Greutatea curentǎ",
    broodBoxCount: "Numărul de cutii de puiet",
    superCount: "Numărul de magazii",
    positionMaxLength: "Lungimea maximă a poziției este de 255 de caractere",
    fromAndToPositionMustBeDifferent:
      "Poziția de plecare și cea de sosire trebuie să fie diferite",
    "More empty frames are needed":
      "Pentru efectuarea operațiunii aveți nevoie de mai multe rame goale.",
    "No supers available to remove":
      "Nu există magazii disponibile pentru îndepărtare",
    "You can't delete more suppers than available":
      "Nu puteți șterge mai multe magazii decât sunt disponibile",
    "No brood boxes available to remove":
      "Nu există cutii de puiet disponibile pentru îndepărtare",
    "You can't remove more brood boxes than available":
      "Nu puteți îndepărta mai multe cutii de puiet decât sunt disponibile",
    HiveQueenEventsEventType: "Evenimentele Mătci",
    HiveQueenEvents: "Eveniment Matcǎ",
    QueenMarking: "Marcaj Matcă",
    QueenClipping: "Tăiere Aripioare Matcă",
    QueenCaging: "Izolarea Reginei (în cușcă)",
    eventInfoDescription: "Informații despre tipul evenimentului",
    "Events related to relocating the entire apiary, including permanent location changes and seasonal movements for better foraging opportunities.":
      "Evenimente legate de relocarea întregii stupine, inclusiv schimbări permanente de locație și mutări sezoniere pentru oportunități mai bune de cules.",
    "Official and routine inspections of the apiary as a whole, including regulatory checks and seasonal assessments.":
      "Inspecții oficiale și de rutină ale întregii stupine, inclusiv verificări obligatorii și evaluări sezoniere.",
    "Maintenance activities for the apiary infrastructure, equipment, and surrounding environment.":
      "Activități de întreținere a infrastructurii stupinei, a echipamentelor și a mediului înconjurător.",
    "Events related to moving individual hives within or between apiaries.":
      "Evenimente legate de mutarea stupilor individuali în cadrul aceleiași stupine sau între stupine diferite.",
    "Regular and specific inspections of individual hives to assess health, strength, and conditions.":
      "Inspecții regulate și specifice ale stupilor individuali pentru evaluarea sănătății, puterii și condițiilor generale.",
    "Medical and preventive treatments applied to hives for various health conditions.":
      "Tratamente medicale și preventive aplicate stupilor pentru diverse afecțiuni.",
    "Measurements and assessments of various hive parameters and colony strength indicators.":
      "Măsurători și evaluări ale diferiților parametri ai stupului și indicatori ai puterii coloniei.",
    "Addition or removal of hive equipment and components.":
      "Adăugarea sau îndepărtarea echipamentelor și componentelor stupului.",
    "Events related to queen bee management and maintenance.":
      "Evenimente legate de gestionarea și întreținerea mătcii.",
    "Permanent relocation of the entire apiary to a new location, requiring updates to official registration.":
      "Relocarea permanentă a întregii stupine într-o nouă locație, necesitând actualizarea înregistrărilor oficiale.",
    "Temporary relocation of hives to take advantage of specific nectar flows or pollination opportunities.":
      "Relocarea temporară a stupilor pentru a beneficia de culesuri specifice sau oportunități de polenizare.",
    "Mandatory veterinary inspection of the apiary for health certification and disease monitoring.":
      "Inspecție veterinară obligatorie a stupinei pentru certificarea sănătății și monitorizarea bolilor.",
    "Official inspection by beekeeping authorities for compliance with regulations and standards.":
      "Inspecție oficială de către autoritățile apicole pentru verificarea conformității cu reglementările și standardele.",
    "Assessment of apiary conditions and preparations before winter season.":
      "Evaluarea condițiilor stupinei și pregătirile înainte de sezonul de iarnă.",
    "Evaluation of apiary condition and colonies after winter, planning for the new season.":
      "Evaluarea stării stupinei și a coloniilor după iarnă, planificarea pentru noul sezon.",
    "Repairs and maintenance of apiary infrastructure like stands, fencing, or windbreaks.":
      "Reparații și întreținere a infrastructurii stupinei precum suporturi, garduri sau paravânturi.",
    "Maintenance of shared apiary equipment such as extractors, tools, or storage facilities.":
      "Întreținerea echipamentelor comune ale stupinei precum extractoare, unelte sau spații de depozitare.",
    "Management of vegetation around hives, including mowing, planting, or removing problematic plants.":
      "Gestionarea vegetației din jurul stupilor, inclusiv cosirea, plantarea sau îndepărtarea plantelor problematice.",
    "Control of apiary-level pests such as mice, ants, or wasps.":
      "Controlul dăunătorilor la nivelul stupinei precum șoareci, furnici sau viespi.",
    "Moving a hive to a different location within the same apiary.":
      "Mutarea unui stup într-o altă locație în cadrul aceleiași stupine.",
    "Transferring a hive to a different apiary location.":
      "Transferul unui stup într-o altă stupină.",
    "Regular inspection of hive conditions, including population, stores, and overall health.":
      "Inspecția regulată a condițiilor stupului, inclusiv populație, rezerve și starea generală de sănătate.",
    "Specific inspection focusing on signs of diseases or parasites.":
      "Inspecție specifică concentrată pe semnele de boli sau paraziți.",
    "Inspection to verify queen presence, performance, and quality.":
      "Inspecție pentru verificarea prezenței mătcii, a performanței și calității acesteia.",
    "Detailed inspection of brood patterns, health, and development.":
      "Inspecție detaliată a modelelor de puiet, sănătății și dezvoltării acestuia.",
    "Quick inspection during winter to assess colony survival and stores.":
      "Inspecție rapidă în timpul iernii pentru evaluarea supraviețuirii coloniei și a rezervelor.",
    "Application of treatments specifically targeting Varroa mite infestations.":
      "Aplicarea tratamentelor specifice împotriva infestărilor cu acarianul Varroa.",
    "Treatment for Nosema disease in affected colonies.":
      "Tratament pentru boala Nosema în coloniile afectate.",
    "Treatment for various bee diseases other than Varroa or Nosema.":
      "Tratament pentru diverse boli ale albinelor, altele decât Varroa sau Nosema.",
    "Preventive treatments applied to maintain colony health.":
      "Tratamente preventive aplicate pentru menținerea sănătății coloniei.",
    "Assessment of overall colony strength, including bee population and vitality.":
      "Evaluarea puterii generale a coloniei, inclusiv populația de albine și vitalitatea.",
    "Counting and assessment of frames containing honey stores.":
      "Numărarea și evaluarea ramelor care conțin rezerve de miere.",
    "Measurement of frames containing brood in different stages.":
      "Măsurarea ramelor care conțin puiet în diferite stadii.",
    "Recording the weight of the hive to track honey stores and colony development.":
      "Înregistrarea greutății stupului pentru urmărirea rezervelor de miere și dezvoltarea coloniei.",
    "Adding a honey super to provide more space for honey storage.":
      "Adăugarea unui magazin pentru a oferi mai mult spațiu pentru depozitarea mierii.",
    "Removing a honey super, typically for honey harvesting.":
      "Îndepărtarea unui magazin, de obicei pentru recoltarea mierii.",
    "Adding new frames to the hive for expansion or replacement.":
      "Adăugarea de rame noi în stup pentru extindere sau înlocuire.",
    "Removing frames for maintenance, replacement, or honey extraction.":
      "Îndepărtarea ramelor pentru întreținere, înlocuire sau extragerea mierii.",
    "Adding a new brood box to accommodate colony growth.":
      "Adăugarea unui nou corp de cuib pentru a acomoda creșterea coloniei.",
    "Removing a brood box for colony size management or maintenance.":
      "Îndepărtarea unui corp de cuib pentru gestionarea dimensiunii coloniei sau întreținere.",
    "Marking the queen with a color code for easy identification and age tracking.":
      "Marcarea mătcii cu un cod de culoare pentru identificare ușoară și urmărirea vârstei.",
    "Clipping the queen's wings to prevent swarming and aid in swarm management.":
      "Tăierea aripilor mătcii pentru prevenirea roirii și ajutor în gestionarea roiurilor.",
    "Temporarily confining the queen in a cage for various management purposes.":
      "Izolarea temporară a mătcii într-o cușcă pentru diverse scopuri de management.",
    beekeeping: "Apicultură",
    currentChats: "Conversații curente",
    whatCanIHelpWith: "Cu ce vă pot ajuta?",
    recentChats: "Conversații recente",
    suggestedQuestions: "Întrebări sugerate",
    startChat: "Începe conversația",
    viewAllChats: "Vezi toate conversațiile",
    noHivesAvailable: "Nu există stupi disponibili",
    noHive: "Fǎrǎ stup",
    noQueen: "Fǎrǎ matcǎ",
    queenHasNoHiveAssigned: "Matca nu are nici un stup asociat",
    queenHasNoHiveAssignedAndNoAvailableHives:
      "Matca nu are nici un stup asociat și nu există stupi disponibili",
    removeQueenFromHive: "Îndepărtează Matca din Stup",
    removeQueenFromHiveConfirmation:
      "Ești sigur că vrei să îndepărtezi matca din stup?",
    removingQueen: "Se îndepărtează matca...",
    queenRemovedFromHive: "Matca a fost îndepărtată din stup!",
    errorRemovingQueenFromHive:
      "A apărut o eroare la îndepărtarea mǎtcii din stup",
    hiveHasNoQueenAndQueensAvailable:
      "Stupul nu are nici o matcă asociată și există mătci disponibile",
    hiveHasNoQueenAndNoQueensAvailable:
      "Stupul nu are nici o matcă asociată și nu există mătci disponibile",
    queenHasNoHiveAndHivesAvailable:
      "Matca nu are nici un stup asociat și există stupi disponibili",
    removingThread: "Se șterge conversația...",
    threadRemoved: "Conversația a fost ștearsă!",
    errorRemovingThread: "A apărut o eroare la ștergerea conversației",
    removeThread: "Șterge Conversația",
    deleteThreadConfirmation: "Ești sigur că vrei să ștergi conversația?",
    allThreads: "Toate Conversațiile",
    messageRateLimitExceeded: "Limita de mesaje a fost depășită.",
    limitWillBeRenewedIn: "Limita va fi reînnoită în",
    messageSendError: "A apărut o eroare la trimiterea mesajului",
    youStillHave: "Mai aveți",
    messagesLeftThisMonth: "mesaje disponibile în aceastǎ lună",
    messageLeftThisMonth: "mesaj disponibil în aceastǎ lună",
    "Medical and preventive treatments applied to animals for various health conditions and maintenance.":
      "Tratamente medicale și preventive aplicate animalelor pentru diverse afecțiuni și întreținere.",
    "Recording and tracking of various animal metrics and physical parameters.":
      "Înregistrarea și urmărirea diverselor metrici și parametri fizici ai animalelor.",
    "Events related to animal movement between locations, including quarantine and isolation.":
      "Evenimente legate de mișcarea animalelor între locații, inclusiv carantină și izolare.",
    "Events related to breeding, pregnancy, birth, and reproductive health management.":
      "Evenimente legate de reproducere, gestație, fătare și gestionarea sănătății reproductive.",
    "Events marking the permanent departure of animals from the facility.":
      "Evenimente care marchează plecarea permanentă a animalelor din unitate.",
    "Miscellaneous animal management events including routine care and observations.":
      "Evenimente diverse de gestionare a animalelor, inclusiv îngrijirea de rutină și observațiile.",
    "Planned end of life for research or other approved purposes.":
      "Sfârșit planificat al vieții în scopuri de cercetare sau alte scopuri aprobate.",
    "Transfer of animal ownership through commercial transaction.":
      "Transferul proprietății animalului prin tranzacție comercială.",
    "Recording of natural or unexpected death of an animal.":
      "Înregistrarea morții naturale sau neașteptate a unui animal.",
    "Documentation of missing or unaccounted for animals.":
      "Documentarea animalelor dispărute sau necontabilizate.",
    "Administration of vaccines for disease prevention.":
      "Administrarea vaccinurilor pentru prevenirea bolilor.",
    "Medical intervention for diagnosed diseases or conditions.":
      "Intervenție medicală pentru boli sau afecțiuni diagnosticate.",
    "Administration of antiparasitic medications for internal parasite control.":
      "Administrarea medicamentelor antiparazitare pentru controlul paraziților interni.",
    "Medical care for physical injuries or trauma.":
      "Îngrijire medicală pentru leziuni fizice sau traume.",
    "Surgical procedures performed on animals.":
      "Proceduri chirurgicale efectuate pe animale.",
    "Monitoring and care following surgical procedures.":
      "Monitorizarea și îngrijirea după procedurile chirurgicale.",
    "Provision of additional nutrients or dietary supplements.":
      "Administrarea de nutrienți suplimentari sau suplimente alimentare.",
    "Recording of animal body weight for monitoring growth and health.":
      "Înregistrarea greutății corporale a animalului pentru monitorizarea creșterii și sănătății.",
    "Assessment of animal's physical condition and fat reserves.":
      "Evaluarea condiției fizice și a rezervelor de grăsime ale animalului.",
    "Movement of animals between different locations or facilities.":
      "Mișcarea animalelor între diferite locații sau unități.",
    "Placement of animals in quarantine for health monitoring.":
      "Plasarea animalelor în carantină pentru monitorizarea sănătății.",
    "Release of animals from quarantine after health clearance.":
      "Eliberarea animalelor din carantină după verificarea sănătății.",
    "Separation of animals for health or management purposes.":
      "Separarea animalelor din motive de sănătate sau gestionare.",
    "Artificial insemination procedure for breeding.":
      "Procedura de însămânțare artificială pentru reproducere.",
    "Recording of natural breeding events.":
      "Înregistrarea evenimentelor de împerechere naturală.",
    "Confirmation of pregnancy through testing.":
      "Confirmarea gestației prin testare.",
    "Documentation of negative pregnancy test results.":
      "Documentarea rezultatelor negative ale testului de gestație.",
    "Observation and recording of estrus signs.":
      "Observarea și înregistrarea semnelor de călduri.",
    "Documentation of birthing events and outcomes.":
      "Documentarea evenimentelor de fătare și a rezultatelor.",
    "Recording of pregnancy loss events.":
      "Înregistrarea evenimentelor de pierdere a gestației.",
    "Beginning of milk production period.":
      "Începutul perioadei de producție de lapte.",
    "End of milk production period.":
      "Sfârșitul perioadei de producție de lapte.",
    "Routine health screening with positive findings.":
      "Screening de sănătate de rutină cu constatări pozitive.",
    "Routine health screening with no concerning findings.":
      "Screening de sănătate de rutină fără constatări îngrijorătoare.",
    "Assessment of hoof condition and health.":
      "Evaluarea stării și sănătății copitelor.",
    "Planned end of lactation in dairy animals.":
      "Încheierea planificată a lactației la animalele de lapte.",
    "Separation of young animals from maternal feeding.":
      "Separarea animalelor tinere de la alăptarea maternă.",
    "Modification of feed type or feeding regime.":
      "Modificarea tipului de hrană sau a regimului de hrănire.",
    "Removal of wool or hair coat.": "Îndepărtarea lânii sau a blănii.",
    "Maintenance trimming of hooves.": "Tăierea de întreținere a copitelor.",
    "Application of identification tags.":
      "Aplicarea crotaliilor de identificare.",
    "Surgical or chemical sterilization of male animals.":
      "Sterilizarea chirurgicală sau chimică a animalelor mascule.",
    "Removal or prevention of horn growth.":
      "Îndepărtarea sau prevenirea creșterii coarnelor.",
    "Scheduled or emergency veterinary examination.":
      "Examinare veterinară programată sau de urgență.",
    "Documentation of animal behavior patterns or changes.":
      "Documentarea tiparelor de comportament sau a schimbărilor comportamentale ale animalelor.",
    tutorialsIntro:
      "Suntem încântați că ai ales să folosești aplicația noastră. Pentru a\n" +
      "            te ajuta să te familiarizezi cu funcționalitățile acesteia, am creat\n" +
      "            tutoriale video detaliate care explică pas cu pas cum să utilizezi\n" +
      "            aplicația noastră.",
    NotFoundMessage: "Pagina pe care o căutați nu există",
    goMainScreen: "Mergi la pagina principală",
    birdsGroups: "Grupuri de Păsări",
    newBirdGroup: "Grup Nou de Păsări",
    groupName: "Numele Grupului",
    Adult: "Adult",
    Juvenile: "Juvenil",
    Hatchling: "Pui",
    Mixture: "Mixt",
    birdGroupAdultDescription:
      "Păsări complet mature care au finalizat dezvoltarea fizică și au ajuns la vârsta reproductivă. De obicei, prezintă penaj adult și modele de colorare specifice speciei lor.",
    birdGroupJuvenileDescription:
      "Păsări tinere care au părăsit cuibul și pot zbura, dar nu au ajuns la maturitatea adultă. Pot prezenta modele de penaj intermediare și continuă să crească. De obicei între 2-12 luni, în funcție de specie.",
    birdGroupHatchingDescription:
      "Păsări nou eclozate până la stadiul de pui. În general, încă în cuib, necesitând îngrijire și hrănire parentală. Pot fi parțial sau complet dependente de părinți pentru supraviețuire.",
    birdGroupUnknownDescription:
      "Vârsta nu poate fi determinată din cauza markerilor vizuali insuficienți, documentației sau constrângerilor de observație.",
    birdGroupMixtureDescription:
      "Grup de păsări cu categorii de vârstă mixte, inclusiv adulți, juvenili și pui.",
    ageGroup: "Grup de Vârstă",
    birdsCount: "Numărul de Păsări",
    birdGroups: "Grupuri de Păsări",
    creatingBirdsGroup: "Se creează grupul de păsări...",
    birdsGroupCreated: "Grupul de păsări a fost creat!",
    errorCreatingBirdsGroup: "A apărut o eroare la crearea grupului de păsări",
    name100Characters: "Numele nu poate depăși 100 de caractere",
    description500Characters: "Descrierea nu poate depăși 500 de caractere",
    countMin1: "Numărul de păsări trebuie să fie minim 1",
    birdsSummary: "Sumar Date Păsări",
    count: "Număr",
    deletingBirdsGroup: "Se șterge grupul de păsări...",
    birdsGroupDeleted: "Grupul de păsări a fost șters!",
    birdsGroupDeleteError: "A apărut o eroare la ștergerea grupului de păsări",
    deleteBirdsGroupConfirmation:
      "Ești sigur că vrei să ștergi grupul de păsări?",
    birdsGroupWillBeDeleted: "Grupul de păsări va fi șters dacă da.",
    updatingBirdsGroup: "Se actualizează grupul de păsări...",
    birdsGroupUpdated: "Grupul de păsări a fost actualizat!",
    errorUpdatingBirdsGroup:
      "A apărut o eroare la actualizarea grupului de păsări",
    deleteBirdsGroup: "Șterge Grupul de Păsări",
    Duck: "Rață",
    Turkey: "Curcan",
    Goose: "Gâscă",
    Quail: "Prepeliță",
    Ostrich: "Struț",
    "Rhode Island Red": "Rhode Island Roșu",
    "Plymouth Rock": "Plymouth Rock",
    Leghorn: "Leghorn",
    Australorp: "Australorp",
    Orpington: "Orpington",
    Sussex: "Sussex",
    Wyandotte: "Wyandotte",
    Brahma: "Brahma",
    Cochin: "Cochin",
    Silkie: "Mătase",
    "New Hampshire": "New Hampshire",
    Ameraucana: "Ameraucana",
    Marans: "Marans",
    Pekin: "Pekin",
    Muscovy: "Muscovy",
    Runner: "Alergătoare",
    "Khaki Campbell": "Khaki Campbell",
    Cayuga: "Cayuga",
    "Swedish Blue": "Suedeză Albastră",
    Rouen: "Rouen",
    "Welsh Harlequin": "Arlechin Galez",
    "Broad Breasted White": "Piept Lat Alb",
    "Broad Breasted Bronze": "Piept Lat Bronz",
    "Bourbon Red": "Bourbon Roșu",
    Narragansett: "Narragansett",
    "Royal Palm": "Palmier Regal",
    "Black Spanish": "Spaniol Negru",
    "Beltsville Small White": "Beltsville Alb Mic",
    Toulouse: "Toulouse",
    Embden: "Embden",
    African: "Africană",
    Chinese: "Chineză",
    Pilgrim: "Pilgrim",
    "American Buff": "Americană Bej",
    Sebastopol: "Sebastopol",
    "African Black": "African Negru",
    "Blue Neck": "Gât Albastru",
    "Red Neck": "Gât Roșu",
    "Kenyan Red": "Kenian Roșu",
    "Zimbabwe Blue": "Zimbabwe Albastru",
    "South African Black": "Sud-African Negru",
    "Israeli Blue": "Israelian Albastru",
    "Australian Black": "Australian Negru",
    selectBirdGroups: "Selectează Grupuri de Păsări",
    noBirdGroupsAssociated: "Nu sunt grupuri de păsări asociate",
    oneBirdGroupAssociated: "1 grup de păsări asociat",
    birdGroupsAssociated: "grupuri de păsări asociate",
    assocBirdGroups: "Asociază Grupuri de Păsări",
    birds: "Păsări",
    animalGender: "Genul Animalului",
    selectHiveType: "Selecteaza tipul stupului",
    contactInfo:
      "Pentru orice întrebări sau probleme conexe, nu ezitați să ne contactați și vă vom răspunde cât mai curând posibil!",
    appLanguage: "Limba Aplicației",
    romanian: "Românǎ",
    english: "Englezǎ",
    selectAppLanguage: "Selecteazǎ limba aplicației",
    event: "Eveniment",
    birdsGroupsEvents: "Evenimente Păsări",
    thereAreNoEventsYet: "Nu există înca evenimente",
    selectBirdsGroup: "Selectează Grupul de Păsări",
    creatingBirdsGroupEvent: "Se creează evenimentul grupului de păsări...",
    birdsGroupEventCreated: "Evenimentul grupului de păsări a fost creat!",
    errorCreatingBirdsGroupEvent:
      "A apărut o eroare la crearea evenimentului grupului de păsări",
    birdsGroupRequired: "Grupul de păsări este obligatoriu",
    HealthEventType: "Evenimente de Sănătate",
    Health: "Sănătate",
    HealthCheck: "Verificare Sănătate",
    MedicationAdministration: "Administrare Medicamente",
    "Generic medical and preventive health procedures for birds.":
      "Proceduri medicale și preventive generice pentru păsări.",
    "Generic event related to recording and tracking of bird metrics.":
      "Eveniment generic legat de înregistrarea și urmărirea metricilor păsărilor.",
    "Generic events related to bird movement and location changes.":
      "Evenimente generice legate de mișcarea păsărilor și schimbările de locație.",
    "Routine health inspection and monitoring.":
      "Inspeție și monitorizare de rutină a sănătății.",
    "Movement between different locations.": "Mișcare între diferite locații.",
    "Separation for health or management purposes.":
      "Separare din motive de sănătate sau gestionare.",
    "Habitat cleaning and maintenance.":
      "Curățare și întreținere a habitatului.",
    "General monitoring and behavioral observation.":
      "Monitorizare generală și observație comportamentală.",
    "Miscellaneous bird event that doesn't fit into any category.":
      "Eveniment divers care nu se încadrează în nicio categorie.",
    "Administration of medication for disease prevention or treatment.":
      "Administrarea de medicamente pentru prevenirea sau tratarea bolilor.",
    birdsGroup: "Grup de Păsări",
    birdsGroupEvent: "Eveniment Grup de Păsări",
    Cleaning: "Curățare",
    Observation: "Observație",
    allBirdsGroups: "Toate Grupurile de Păsări",
    AddHeads: "Adaugă Capete",
    RemoveHeads: "Îndepărtează Capete",
    "Add birds into the group.": "Adaugă păsări în grup.",
    "Remove birds into the group.": "Îndepărtează păsări din grup.",
    medicineDescription: "Descriere Medicament",
    "Broad-spectrum antibiotic effective against respiratory infections and bacterial diseases. Commonly used for treating bacterial infections in various bird species.":
      " Antibiotic cu spectru larg eficient împotriva infecțiilor respiratorii și bolilor bacteriene. Folosit în mod obișnuit pentru tratarea infecțiilor bacteriene la diverse specii de păsări.",
    "Antibiotic effective against mycoplasma infections and chronic respiratory disease. Often used in combination with other medications for respiratory treatments.":
      "Antibiotic eficient împotriva infecțiilor cu micoplasme și a bolilor respiratorii cronice. Adesea folosit în combinație cu alte medicamente pentru tratamentele respiratorii.",
    "Anti-coccidial medication used to prevent and treat coccidiosis. Essential for maintaining digestive health in young birds.":
      "Medicație anti-coccidică folosită pentru prevenirea și tratarea coccidiozei. Esențială pentru menținerea sănătății digestive la păsările tinere.",
    "Essential vitamin supplement supporting bone health, immune function, and reproduction. Particularly important during growth and breeding periods.":
      "Supliment esențial de vitamine care susține sănătatea oaselor, funcția imunitară și reproducerea. Deosebit de important în perioadele de creștere și reproducere.",
    "Vitamin supplement supporting metabolism, nerve function, and overall health. Often used during stress periods or recovery.":
      "Supliment de vitamine care susține metabolismul, funcția nervoasă și sănătatea generală. Folosit adesea în perioadele de stres sau recuperare.",
    "Probiotics for Birds": "Probiotice pentru Păsări",
    "Beneficial bacteria supplement supporting digestive health and immune function. Especially useful after antibiotic treatment or during stress.":
      "Supliment de bacterii benefice care susține sănătatea digestivă și funcția imunitară. Deosebit de util după tratamentul cu antibiotice sau în perioadele de stres.",
    "Antifungal medication used to treat yeast infections and candidiasis. Common treatment for digestive tract fungal infections.":
      "Medicație antifungică folosită pentru tratarea infecțiilor cu drojdii și candidoză. Tratament comun pentru infecțiile fungice ale tractului digestiv.",
    "Deworming medication effective against roundworms. Regular use helps maintain digestive health and prevent parasite buildup.":
      "Medicație antiparazitară eficientă împotriva viermilor rotunzi. Utilizarea regulată ajută la menținerea sănătății digestive și previne acumularea de paraziți.",
    "Electrolyte Solution": "Soluție de Electroliți",
    "Supplement for maintaining hydration and electrolyte balance. Critical during heat stress or illness recovery.":
      "Supliment pentru menținerea hidratării și a echilibrului de electroliți. Critic în perioadele de stres termic sau recuperare după boală.",
    Milliliters: "Mililitri",
    Milligrams: "Miligrame",
    Grams: "Grame",
    Pieces: "Bucăți",
    measuringUnit: "Unitate de Măsură",
    birdsGroupIntendedPurpose: "Scopul Grupului de Păsări",
    "Eggs and Meat": "Ouă și Carne",
    Eggs: "Ouă",
    Pet: "Animale de Companie",
    Breeding: "Reproducere",
    TrimmingFeathers: "Tăiere Aripi",
    "Trimming of feathers or beaks.": "Tăierea penelor sau a ciocului.",
    EggsCounting: "Numărare Ouă",
    "Counting the number of eggs produced by the group.":
      "Numărarea numărului de ouă produse de grup.",
    eggsCount: "Număr de Ouă",
    eggsProduction: "Producție de Ouă",
    totalEggsCount: "Număr Total de Ouă",
    latestCount: "Ultima Numărare",
    recordedEvents: "Evenimente Înregistrate",
    recentEvents: "Evenimente Recente",
    noBirdsGroupsFound: "Nu s-au găsit grupuri de păsări",
    assocBirdGroup: "Asociază Grup de Păsări",
    intendedUseRequired: "Scopul Grupului de Păsări este obligatoriu",
    locationRequired: "Locația este obligatorie",
    updatingEvent: "Se actualizează evenimentul...",
    eventUpdated: "Evenimentul a fost actualizat!",
    eventUpdateError: "A apărut o eroare la actualizarea evenimentului",
    deletingEvent: "Se șterge evenimentul...",
    eventDeleted: "Evenimentul a fost șters!",
    eventDeleteError: "A apărut o eroare la ștergerea evenimentului",
    hiveScale: "Cantar Stup",
    myScales: "Cântarele Mele",
    addHiveScale: "Adaugă Cântar Stup",
    creatingHiveScale: "Se creează cântarul stupului...",
    hiveScaleCreated: "Cântarul stupului a fost creat!",
    errorCreatingHiveScale: "A apărut o eroare la crearea cântarului stupului",
    phoneNumber: "Număr de Telefon",
    battery: "Baterie",
    lastMeasurementTime: "Ultima Măsurare",
    humidity: "Umiditate",
    pressure: "Presiune",
    temperature: "Temperatură",
    extraWeight: "Greutate Extra",
    signal: "Semnal",
    noMeasurementsRecorded:
      "Nu s-au înregistrat măsurători, acestea vor fi disponibile în scurt timp!",
    oneDayImprovement: "Spor 24h",
    lastEvents: "Ultimele {{count}} evenimente din totalul de {{total}}",
    messageSent: "Mesajul a fost trimis!",
    messageNotSent: "A apărut o eroare la trimiterea mesajului",
    ainNotUnique: "Numărul de identificare al animalului trebuie să fie unic",
    temperatureStatistics: "Statistici Temperatură",
    weightStatistics: "Statistici Greutate",
    period: "Perioada",
    lastMeasurement: "Ultima Măsurare",
    statistics: "Statistici",
    lastMeasurements: "Ultimele {{count}} Măsurători",
    lastMeasurementsTotal: "Ultimele {{count}} Măsurători din {{total}}",
    last_24h: "Ultimele 24h",
    last_2_weeks: "Ultimele 2 Săptămâni",
    minVal: "Valoare Minimă",
    maxVal: "Valoare Maximă",
    avgVal: "Valoare Medie",
    hiveLocation: "Locația Stupului",
    forBetterRepresentationAvg:
      "Pentru o reprezentare mai bună, valorile au fost calculate ca si media a unor intervale de {{count}} ore.",
    showAllMeasurements: "Arată Toate Măsurătorile",
    measurements: "Măsurători",
    measurementsForHive: "Măsurători pentru Stupul",
    changeHive: "Schimbă Stupul",
    hiveScaleDetails: "Detalii Cântar Stup",
    loadMore: "Încarcă Mai Mult",
    lastMeasurementsForHive: "Ultimele Măsuratori pentru Stupul",
    HoneyHarvest: "Recoltare Miere",
    WaxHarvest: "Recoltare Ceară",
    PropolisHarvest: "Recoltare Propolis",
    HiveHarvest: "Recoltare Stup",
    "Events related to honey harvesting and processing or related yield.":
      "Evenimente legate de recoltarea și procesarea mierii sau a productiei aferente.",
    "Extracting honey from frames and processing it for storage or sale.":
      "Extragerea mierii din rame și procesarea acesteia pentru depozitare sau vânzare.",
    "Collecting propolis from hives for various uses or products.":
      "Colectarea propolisului din stupi pentru diverse utilizări sau produse.",
    "Harvesting beeswax from frames or cappings for processing or sale.":
      "Recoltarea cerii de albine din rame sau din capace pentru procesare sau vânzare.",
    HiveHarvestEventType: "Evenimente de Recoltare Stup",
    addHoneyHarvest: "Adaugă Recoltare Miere",
    removeScaleFromHive: "Îndepărtează Cântarul din Stup",
    changePhoneNumber: "Schimbă Numărul de Telefon",
    removeScaleFromHiveConfirmation:
      "Ești sigur că vrei să îndepărtezi cântarul din stup?",
    removingScale: "Se îndepărtează cântarul...",
    scaleRemoved: "Cântarul a fost îndepărtat!",
    scaleRemoveError: "A apărut o eroare la îndepărtarea cântarului",
    hiveScales: "Cântare Stup",
    associatedHive: "Stup Asociat",
    scaleForHive: "Cântar pentru Stupul {{hive}}",
    scale: "Cântar {{phoneNumber}}",
    noAssocHive: "Nu exista nici un Stup Asociat",
    assocHiveScale: "Asociază Cântar Stup",
    assocHiveScaleConfirmation:
      "Ești sigur că vrei să asociezi cântarul cu stupul {{hive_code}}?",
    updatingHiveScale: "Se actualizează cântarul stupului...",
    hiveScaleUpdated: "Cântarul stupului a fost actualizat!",
    hiveScaleUpdateError:
      "A apărut o eroare la actualizarea cântarului stupului",
    noHivesWithoutScales: "Nu există stupi fără cântare",
    hiveScaleEvents: "Evenimente Cântar Stup",
    ScaleRemoved: "Cântar Îndepărtat",
    ScaleAdded: "Cântar Adăugat",
    PhoneNumberChanged: "Număr de Telefon Schimbat",
    scaleRemovedEventDescription:
      "Cântarul {{scale}} a fost îndepărtat din stupul {{hive}}!",
    scaleAddedEventDescription:
      "Cântarul {{scale}} a fost adăugat la stupul {{hive}}!",
    phoneNumberChangedEventDescription:
      "Numărul de telefon al cântarului {{scale}} a fost schimbat din {{old_phone_number}} in {{new_phone_number}}!",
    noLocationData: "Nu există date ale locației",
    noScalesWithoutHive: "Nu există cântare fără stup asociat",
    noHiveScalesFound: "Nu s-au găsit cântare",
    language: "Limba",
    yourData: "Detaliile Dumneavoastră",
    yourFarmDetails: "Detaliile Fermei Dumneavoastră",
    Beekeeping: "Apicultură",
    "Choose at least one module that corresponds to your farm's activities":
      "Alegeți cel puțin un modul care corespunde activităților fermei dumneavoastră",
    billingData: "Date Facturare",
    farmDetails: "Detalii Fermă",
    "Legal Name": "Numele Firmei",
    "Enter the legal name of your business":
      "Introduceți numele firmei dumneavoastră",
    "Tax Identification Number (TIN)": "Codul de Identificare Fiscală (CIF)",
    "Enter your tax identification number":
      "Introduceți codul de identificare fiscală",
    Street: "Strada",
    City: "Oraș",
    "Street address": "Adresa Străzii",
    "State/Province": "Județul",
    "State or province": "Județul",
    Country: "Țara",
    signupSuccess: "Înregistrare cu Succes",
    "Enter your phone number": "Introduceți numărul de telefon",
    changingLanguage: "Se schimbă limba...",
    languageChanged: "Limba a fost schimbată!",
    languageChangeError: "A apărut o eroare la schimbarea limbii",
    legalNameRequired: "Numele firmei este obligatoriu",
    tinRequired: "Codul de identificare fiscală este obligatoriu",
    streetRequired: "Strada este obligatorie",
    cityRequired: "Orașul este obligatoriu",
    spRequired: "Județul este obligatoriu",
    countryRequired: "Țara este obligatorie",
    "Farm Name": "Numele Fermei",
    "Enter the legal name of your farm":
      "Introduceți numele fermei dumneavoastră",
    pleaseEnterEmail: "Introduceți adresa de email",
    pleaseEnterPassword: "Introduceți parola",
    passwordRequired: "Parola este obligatorie",
    invalidEmail: "Adresa de email nu este validă",
    "Enter the name of your farm": "Introduceți numele fermei dumneavoastră",
    selectCountryCode: "Selectați Codul Țării",
    updatingPreferences: "Se actualizează preferințele...",
    preferencesUpdated: "Preferințele au fost actualizate!",
    preferencesUpdateError: "A apărut o eroare la actualizarea preferințelor",
    Drops: "Picături",
    Strips: "Benzi",
    Packets: "Plicuri",
    Doses: "Doze",

    //   Bee medications

    "Varroa Treatment": "Tratament Varroa",
    "Nosema Treatment": "Tratament Nosema",
    "Brood Disease Treatment": "Tratament boli puiet",
    Supplement: "Supliment",
    Antibiotic: "Antibiotic",
    Fungicide: "Fungicid",
    "General Health": "Sănătate generală",
    "Treatments specifically designed to control Varroa mite infestations in bee colonies.":
      "Tratamente special concepute pentru controlul infestărilor cu acarianul Varroa în coloniile de albine.",
    "Medications for treating Nosema apis and Nosema ceranae infections in honey bees.":
      "Medicamente pentru tratarea infecțiilor cu Nosema apis și Nosema ceranae la albinele melifere.",
    "Treatments for various brood diseases including American foulbrood, European foulbrood, and chalkbrood.":
      "Tratamente pentru diverse boli ale puietului, inclusiv loca americană, loca europeană și puietul văros.",
    "Nutritional supplements to support colony health and development.":
      "Suplimente nutritive pentru a susține sănătatea și dezvoltarea coloniei.",
    "Antibiotics used to treat bacterial infections in bee colonies when legally permitted.":
      "Antibiotice utilizate pentru tratarea infecțiilor bacteriene în coloniile de albine, când este permis legal.",
    "Treatments for fungal diseases in bee colonies.":
      "Tratamente pentru boli fungice în coloniile de albine.",
    "General treatments to support overall colony health and immunity.":
      "Tratamente generale pentru susținerea sănătății și imunității generale a coloniei.",
    Amitraz: "Amitraz",
    "Oxalic Acid": "Acid oxalic",
    "Formic Acid": "Acid formic",
    ApiVar: "ApiVar",
    "ApiLife VAR": "ApiLife VAR",
    "Mite-Away Quick Strips": "Benzi Mite-Away Quick",
    Apistan: "Apistan",
    "CheckMite+": "CheckMite+",
    HopGuard: "HopGuard",
    Thymovar: "Thymovar",
    "Fumagilin-B": "Fumagilin-B",
    Nozevit: "Nozevit",
    Terramycin: "Terramicină",
    Tylan: "Tylan",
    "Bee Pro": "Bee Pro",
    HiveAlive: "HiveAlive",
    ProDFM: "ProDFM",
    "Vitamin B Complex": "Complex de vitamine B",
    ApiHerb: "ApiHerb",
    "Essential Oil Blend": "Amestec de uleiuri esențiale",
    "Honey-B-Healthy": "Honey-B-Healthy",
    "Synthetic acaricide used to control Varroa mites in honey bee colonies.":
      "Acaricid sintetic utilizat pentru controlul acarienilor Varroa în coloniile de albine.",
    "Natural organic acid treatment for Varroa mites, especially effective in broodless periods.":
      "Tratament cu acid organic natural pentru acarieni Varroa, deosebit de eficient în perioadele fără puiet.",
    "Organic acid treatment for Varroa mites that can be used during honey flow, affects mites under cell cappings.":
      "Tratament cu acid organic pentru acarieni Varroa care poate fi utilizat în timpul culesului de miere, afectează acarienii de sub căpăcelele celulelor.",
    "Amitraz-based Varroa treatment strips, typically used for 6-8 weeks for full efficacy.":
      "Benzi de tratament Varroa pe bază de amitraz, utilizate de obicei timp de 6-8 săptămâni pentru eficacitate completă.",
    "Thymol, eucalyptol, menthol, and camphor-based treatment for Varroa mites.":
      "Tratament pentru acarieni Varroa pe bază de timol, eucaliptol, mentol și camfor.",
    "Formic acid-based Varroa treatment delivered via gel strips, effective against mites under brood cappings.":
      "Tratament Varroa pe bază de acid formic livrat prin benzi de gel, eficient împotriva acarienilor de sub căpăcelele puietului.",
    "Tau-fluvalinate based strips for Varroa control, though resistance is now common in many areas.":
      "Benzi pe bază de tau-fluvalinate pentru controlul Varroa, deși rezistența este acum frecventă în multe zone.",
    "Coumaphos-based strips for Varroa control, often used in rotation with other treatments.":
      "Benzi pe bază de coumafos pentru controlul Varroa, adesea utilizate în rotație cu alte tratamente.",
    "Beta acid hop compound strips for Varroa control, can be used during honey production.":
      "Benzi compuse din acid beta de hamei pentru controlul Varroa, pot fi utilizate în timpul producției de miere.",
    "Thymol-based treatment for Varroa mites with lower concentration than ApiLife VAR.":
      "Tratament pe bază de timol pentru acarieni Varroa cu concentrație mai mică decât ApiLife VAR.",
    "Treatment for Nosema disease, administered in sugar syrup to prevent and control infections.":
      "Tratament pentru boala Nosema, administrat în sirop de zahăr pentru a preveni și controla infecțiile.",
    "Natural plant-based supplement to help bees resist Nosema infections.":
      "Supliment natural pe bază de plante pentru a ajuta albinele să reziste infecțiilor cu Nosema.",
    "Oxytetracycline antibiotic for prevention and control of European foulbrood and American foulbrood (where legal).":
      "Antibiotic oxitetraciclină pentru prevenirea și controlul locii europene și locii americane (unde este legal).",
    "Tylosin antibiotic used for American foulbrood control in some regions where legally permitted.":
      "Antibiotic tilosină utilizat pentru controlul locii americane în unele regiuni unde este permis legal.",
    "Protein supplement to support bee nutrition when natural pollen is scarce.":
      "Supliment proteic pentru susținerea nutriției albinelor atunci când polenul natural este insuficient.",
    "Nutritional supplement with thymol, lemongrass, and seaweed extracts to support colony health.":
      "Supliment nutritiv cu extracte de timol, lemongrass și alge marine pentru susținerea sănătății coloniei.",
    "Probiotic supplement to support bee gut health and immune function.":
      "Supliment probiotic pentru susținerea sănătății intestinale și a funcției imunitare a albinelor.",
    "Vitamin supplement added to sugar syrup to support bee health during stress periods.":
      "Supliment vitamínic adăugat în siropul de zahăr pentru a susține sănătatea albinelor în perioadele de stres.",
    "Herbal preparation to support overall colony health and vigor.":
      "Preparat pe bază de plante pentru susținerea sănătății și vigorii generale a coloniei.",
    "Mixture of lemongrass, spearmint, and other essential oils to promote colony health.":
      "Amestec de lemongrass, mentă și alte uleiuri esențiale pentru promovarea sănătății coloniei.",
    "Essential oil-based feed supplement to stimulate feeding and support colony health.":
      "Supliment alimentar pe bază de uleiuri esențiale pentru stimularea hrănirii și susținerea sănătății coloniei.",

    // Animal medications

    Penicillin: "Penicilină",
    Oxytetracycline: "Oxitetraciclină",
    Ceftiofur: "Ceftiofur",
    Tylosin: "Tilosină",
    "Flunixin Meglumine": "Flunixin Meglumine",
    Meloxicam: "Meloxicam",
    Dexamethasone: "Dexametazonă",
    Ivermectin: "Ivermectină",
    Fenbendazole: "Fenbendazol",
    Albendazole: "Albendazol",
    Levamisole: "Levamisol",
    Oxytocin: "Oxitocină",
    "Prostaglandin F2alpha": "Prostaglandină F2alfa",
    GnRH: "GnRH",
    "Calcium Borogluconate": "Boroglucanoat de Calciu",
    "Selenium/Vitamin E": "Seleniu/Vitamina E",
    Lidocaine: "Lidocaină",
    "Propylene Glycol": "Propilenglicol",
    "Sodium Bicarbonate": "Bicarbonat de Sodiu",
    Cephalexin: "Cefalexină",
    Cloxacillin: "Cloxacilină",
    Epinephrine: "Epinefrină",
    Monensin: "Monensin",
    Rumensin: "Rumensin",

    // animal medications descriptions

    "Basic antibiotic for treating bacterial infections in livestock. Commonly used for pneumonia, mastitis, and foot rot.":
      "Antibiotic de bază pentru tratarea infecțiilor bacteriene la animale. Utilizat frecvent pentru pneumonie, mastită și putregai al copitelor.",

    "Broad-spectrum antibiotic effective against respiratory diseases, pinkeye, and foot rot in cattle and sheep.":
      "Antibiotic cu spectru larg eficient împotriva bolilor respiratorii, conjunctivitei și putregaiului copitelor la bovine și ovine.",

    "Advanced antibiotic for treating severe bacterial infections, particularly respiratory disease in cattle.":
      "Antibiotic avansat pentru tratarea infecțiilor bacteriene severe, în special boli respiratorii la bovine.",

    "Antibiotic primarily used for respiratory diseases in cattle and swine. Also effective against mastitis.":
      "Antibiotic utilizat în principal pentru boli respiratorii la bovine și porcine. De asemenea, eficient împotriva mastitei.",

    "NSAID used for pain, fever, and inflammation in livestock. Commonly used for mastitis and lameness.":
      "AINS utilizat pentru durere, febră și inflamație la animale. Utilizat frecvent pentru mastită și șchiopături.",

    "NSAID used for pain management in cattle, particularly after dehorning or castration.":
      "AINS utilizat pentru gestionarea durerii la bovine, în special după decornitare sau castrare.",

    "Corticosteroid used for severe inflammation, shock, and allergic reactions in livestock.":
      "Corticosteroid utilizat pentru inflamații severe, șoc și reacții alergice la animale.",

    "Broad-spectrum parasiticide effective against internal and external parasites in cattle, sheep, and pigs.":
      "Parasiticide cu spectru larg eficient împotriva paraziților interni și externi la bovine, ovine și porcine.",

    "Dewormer effective against gastrointestinal parasites in cattle, sheep, and goats.":
      "Antiparazitar eficient împotriva paraziților gastrointestinali la bovine, ovine și caprine.",

    "Broad-spectrum dewormer effective against internal parasites, including liver flukes in ruminants.":
      "Antiparazitar cu spectru larg eficient împotriva paraziților interni, inclusiv fasciola hepatică la rumegătoare.",

    "Dewormer effective against gastrointestinal roundworms in cattle, sheep, and goats.":
      "Antiparazitar eficient împotriva viermilor rotunzi gastrointestinali la bovine, ovine și caprine.",

    "Hormone used to induce labor, treat retained placenta, and stimulate milk letdown in dairy animals.":
      "Hormon utilizat pentru inducerea travaliului, tratarea retenției de placentă și stimularea lactației la animalele de lapte.",

    "Hormone used for estrus synchronization and treatment of retained corpus luteum in cattle.":
      "Hormon utilizat pentru sincronizarea estrului și tratamentul corpului luteal persistent la bovine.",

    "Hormone used for treating cystic ovaries and synchronizing ovulation in cattle breeding programs.":
      "Hormon utilizat pentru tratarea chisturilor ovariene și sincronizarea ovulației în programele de reproducere a bovinelor.",

    "Supplement used for treating vitamin B deficiencies and supporting metabolism in stressed animals.":
      "Supliment utilizat pentru tratarea deficiențelor de vitamina B și susținerea metabolismului la animalele stresate.",

    "Used for treating milk fever (hypocalcemia) in dairy cattle.":
      "Utilizat pentru tratarea febrei de lapte (hipocalcemie) la vacile de lapte.",

    "Supplement used to prevent and treat white muscle disease in young livestock.":
      "Supliment utilizat pentru prevenirea și tratarea bolii musculare albe la animalele tinere.",

    "Local anesthetic used for minor surgeries, dehorning, and castration procedures.":
      "Anestezic local utilizat pentru intervenții chirurgicale minore, decornitare și proceduri de castrare.",

    "Used to treat ketosis in dairy cattle and as an energy supplement.":
      "Utilizat pentru tratarea cetozei la vacile de lapte și ca supliment energetic.",

    "Buffer used to treat and prevent ruminal acidosis in cattle.":
      "Tampon utilizat pentru tratarea și prevenirea acidozei ruminale la bovine.",

    "Intramammary antibiotic used for treating mastitis in dairy cattle.":
      "Antibiotic intramam utilizat pentru tratarea mastitei la vacile de lapte.",

    "Intramammary antibiotic specifically designed for dry cow therapy.":
      "Antibiotic intramam conceput special pentru terapia vacilor în perioada de uscare.",

    "Emergency medication used for treating anaphylactic reactions in livestock.":
      "Medicament de urgență utilizat pentru tratarea reacțiilor anafilactice la animale.",

    "Feed additive used to prevent coccidiosis and improve feed efficiency in cattle.":
      "Aditiv furajer utilizat pentru prevenirea coccidiozei și îmbunătățirea eficienței furajelor la bovine.",

    "Feed additive to improve feed efficiency and prevent bloat in cattle.":
      "Aditiv furajer pentru îmbunătățirea eficienței furajelor și prevenirea meteorismului la bovine.",
    incomesLei: "Venituri (RON)",
    expensesLei: "Cheltuieli (RON)",
    noIncomesOnSelectedPeriod: "Nu există venituri în perioada selectată",
    noExpensesOnSelectedPeriod: "Nu există cheltuieli în perioada selectată",
    failedToCreateAccount: "Crearea contului a eșuat",
    deleteAccount: "Șterge Contul",
    deleteAccountConfirmation:
      "Ești sigur că vrei să ștergi contul? Aceastǎ acțiune este ireversibilă. Vei pierde toate datele asociate contului.",
    deletingAccount: "Se șterge contul...",
    accountDeleted: "Contul a fost șters!",
    errorOccurredWhileDeletingAccount:
      "A apărut o eroare la ștergerea contului",
    enterYourPasswordToContinue: "Introduceți parola pentru a continua",
    yourPassword: "Parola dumneavoastră",
    HONEY_SALES: "Vânzare Miere",
    POLLINATION_SERVICES: "Servicii de Polenizare",
    BEESWAX_SALES: "Vânzare Ceară de Albine",
    BEE_COLONY_SALES: "Vânzare Colonii de Albine",
    BEE_FEED: "Hrană pentru Albine",
    QUEEN_PURCHASES: "Achiziție Regine",
    HIVE_TREATMENTS: "Tratamente pentru Stupi",
    BEE_COLONY_PURCHASES: "Achiziție Colonii de Albine",
    HONEY_PACKAGING: "Ambalare Miere",
    "Events related to providing supplemental nutrition to honey bee colonies.":
      "Evenimente legate de furnizarea de hrană suplimentară pentru coloniile de albine.",
    "Providing solid candy (like Apifonda) for winter or dearth periods.":
      "Furnizarea de turte de zahăr (precum Apifonda) pentru iarnă sau perioade de lipsă a nectarului.",
    "Feeding with protein supplements to boost brood production.":
      "Hrănirea cu suplimente proteice pentru stimularea creșterii puietului.",
    "Providing pollen substitutes or supplements.":
      "Furnizarea de înlocuitori sau suplimente de polen.",
    "Strategic feeding to stimulate colony growth and activity.":
      "Hrănire strategică pentru stimularea creșterii și activității coloniei.",
    HiveFeeding: "Hrănirea Albinelor",
    CarbohydratesFeeding: "Hrănire cu Carbohidrați",
    ProteinFeeding: "Hrănire cu Suplimente Proteice",
    PollenSubstitute: "Înlocuitor de Polen",
    StimulativeFeeding: "Hrănire Stimulativă",
    MyParcels: "Parcelele mele",
    addNewParcel: "Adaugă o Parcelă Nouă",
    addCoordinates: "Adaugă Coordonate",
    parcelCoordinates: "Coordonate Parcelă",
    addParcelCoordinates: "Adaugă Coordonate Parcelă",
    latitude: "Latitudine",
    longitude: "Longitudine",
    longitudeX: "Longitudine (sau X/E)",
    latitudeY: "Latitudine (sau Y/N)",
    latY: "Latitudine (Y/N)",
    longX: "Longitudine (X/E)",
    parcelView: "Vizualizare Parcelă",
    noCoordinatesYet: "Nu există coordonate încă",
    addPointCoordinates: "Adaugă Coordonate Punct",
    parcelName: "Numele Parcelei",
    parcelDescription: "Descrierea Parcelei",
    creatingParcel: "Se creează parcela...",
    parcelCreated: "Parcela a fost creată!",
    parcelCreationError: "A apărut o eroare la crearea parcelei",
    noParcelsFound: "Nu s-au găsit parcele",
    deleteParcel: "Șterge Parcela",
    deletingParcel: "Se șterge parcela...",
    parcelDeleted: "Parcela a fost ștearsă!",
    parcelDeletionError: "A apărut o eroare la ștergerea parcelei",
    area: "Suprafață",
    deleteParcelConfirmation: "Ești sigur că vrei să ștergi parcela?",
    deleteParcelDescription:
      "Această acțiune este ireversibilă. Toate datele asociate parcelei vor fi pierdute.",
    editParcelInfo: "Editează Informațiile Parcelei",
    editPointCoordinates: "Editează Coordonatele Punctului",
    deletePoint: "Șterge Punctul",
    deletePointConfirmation: "Ești sigur că vrei să ștergi punctul?",
    updatingParcel: "Se actualizează parcela...",
    parcelUpdated: "Parcela a fost actualizată!",
    parcelUpdateError: "A apărut o eroare la actualizarea parcelei",
    parcelMap: "Hartă Parcelă",
    HiveFeedingEventType: "Evenimente Hrǎnire",
    ScaleAddedWithoutHive: "Adǎugare directǎ a cântarului",
    "Hive was added directly, without having an associated hive.":
      "Stupul a fost adăugat direct, fără a avea un stup asociat.",
    editHiveScale: "Editează Cântar Stup",
    noConversationsFound: "Nu s-au găsit conversații",
    "How are my bee colonies doing?":
      "Cum se descurcă coloniile mele de albine?",
    "What's the status of my queen bees?": "Care este starea mătcilor mele?",
    "How is my honey production looking?": "Cum arată producția mea de miere?",
    "What do my hive scale measurements show?":
      "Ce arată măsurătorile cântarelor stupilor mei?",
    "Do my hives need treatments soon?":
      "Stupii mei au nevoie de tratamente în curând?",
    "Which apiaries need attention?": "Care stupine necesită atenție?",
    "Are any of my hives at risk of swarming?":
      "Există stupi care prezintă risc de roire?",
    "When should I move my apiaries for better nectar flow?":
      "Când ar trebui să mut stupinele pentru un flux mai bun de nectar?",
    "Do I need to add or remove supers from my hives?":
      "Trebuie să adaug sau să elimin magaziile din stupii mei?",
    "Are my hives ready for winter?": "Sunt stupii mei pregătiți pentru iarnă?",
    "How are my bird flocks doing?":
      "Cum se descurcă efectivele mele de păsări?",
    "How is my egg production looking?": "Cum arată producția mea de ouă?",
    "How is the growth of my meat birds progressing?":
      "Cum progresează creșterea păsărilor mele pentru carne?",
    "Do my birds need any treatments soon?":
      "Păsările mele au nevoie de tratamente în curând?",
    "Which bird locations need attention?":
      "Care locații de păsări necesită atenție?",
    "Are any of my bird groups overcrowded?":
      "Sunt unele dintre grupurile mele de păsări supraaglomerate?",
    "When should I rotate my birds to fresh pasture?":
      "Când ar trebui să rotesc păsările mele către pășuni proaspete?",
    "How is my bird feed consumption looking?":
      "Cum arată consumul de hrană al păsărilor mele?",
    "How are my breeding birds performing?":
      "Cum se descurcă păsările mele de reproducere?",
    "Are my birds ready for the upcoming season?":
      "Sunt păsările mele pregătite pentru sezonul care urmează?",
    "How is my cattle herd doing?": "Cum se descurcă cirezile mele de bovine?",
    "What's the reproductive status of my cows?":
      "Care este starea reproductivă a vacilor mele?",
    "How is my milk production looking?": "Cum arată producția mea de lapte?",
    "Do my cattle need any treatments soon?":
      "Bovinele mele au nevoie de tratamente în curând?",
    "What upcoming gestation events do I need to prepare for?":
      "Pentru ce evenimente de gestație trebuie să mă pregătesc în viitorul apropiat?",
    "How is the growth of my calves and young stock?":
      "Cum progresează creșterea vițeilor și a tineretului bovin?",
    "Which cattle locations need attention?":
      "Care locații de bovine necesită atenție?",
    "How should I adjust my cattle feeding program?":
      "Cum ar trebui să-mi ajustez programul de hrănire a bovinelor?",
    "When should I breed my cows?": "Când ar trebui să-mi reproduc vacile?",
    "Which cows are due to calve soon?": "Care vaci urmează să fete în curând?",
    "How should I manage my newborn calves?":
      "Cum ar trebui să-mi gestionez vițeii nou-născuți?",
    "How is my pig herd doing?": "Cum se descurcă efectivul meu de porci?",
    "What's the reproductive status of my sows?":
      "Care este starea reproductivă a scroafelor mele?",
    "Which sows are due to farrow soon?":
      "Care scroafe urmează să fete în curând?",
    "How should I manage my nursing piglets?":
      "Cum ar trebui să îmi gestionez purceii la supt?",
    "Which piglets are ready for weaning?":
      "Care purcei sunt pregătiți pentru înțărcare?",
    "How is the growth performance of my growing pigs?":
      "Cum este performanța de creștere a porcilor mei în dezvoltare?",
    "Do my pigs need any treatments soon?":
      "Porcii mei au nevoie de tratamente în curând?",
    "Which pig locations need attention?":
      "Care locații de porci necesită atenție?",
    "How should I adjust my pig feeding program?":
      "Cum ar trebui să-mi ajustez programul de hrănire a porcilor?",
    "When should I breed my sows?":
      "Când ar trebui să reproduc scroafele mele?",
    "Which pigs are ready for market?":
      "Care porci sunt pregătiți pentru piață?",
    "How is my goat herd doing?": "Cum se descurcă turma mea de capre?",
    "What's the reproductive status of my does?":
      "Care este starea reproductivă a caprelor mele?",
    "How is my goat milk production looking?":
      "Cum arată producția mea de lapte de capră?",
    "Do my goats need any treatments soon?":
      "Caprele mele au nevoie de tratamente în curând?",
    "How should I manage my young kids?":
      "Cum ar trebui să-mi gestionez iezii tineri?",
    "When should I breed my does?": "Când ar trebui să-mi reproduc caprele?",
    "Which does are due to kid soon?": "Care capre urmează să fete în curând?",
    "How should I manage parasites in my goat herd?":
      "Cum ar trebui să gestionez paraziții în turma mea de capre?",
    "How should I adjust my goat feeding program?":
      "Cum ar trebui să-mi ajustez programul de hrănire a caprelor?",
    "How is my fiber production looking?": "Cum arată producția mea de fibră?",
    "Which goats need hoof trimming?":
      "Care capre au nevoie de tăierea copitelor?",
    "How is my sheep flock doing?": "Cum se descurcă turma mea de oi?",
    "What's the reproductive status of my ewes?":
      "Care este starea reproductivă a oilor mele?",
    "Which ewes are due to lamb soon?": "Care oi urmează să fete în curând?",
    "How should I manage my young lambs?":
      "Cum ar trebui să-mi gestionez mieii tineri?",
    "Do my sheep need any treatments soon?":
      "Oile mele au nevoie de tratamente în curând?",
    "How should I manage parasites in my sheep flock?":
      "Cum ar trebui să gestionez paraziții în turma mea de oi?",
    "When should I breed my ewes?": "Când ar trebui să-mi reproduc oile?",
    "How is my wool production looking?": "Cum arată producția mea de lână?",
    "How should I adjust my sheep feeding program?":
      "Cum ar trebui să-mi ajustez programul de hrănire a oilor?",
    "Which sheep need hoof trimming?":
      "Care oi au nevoie de tăierea copitelor?",
    "Which lambs are ready for market?":
      "Care miei sunt pregătiți pentru piață?",
    rateMessageExceeded: "Limita de mesaje a fost depășită",
    assocParcel: "Asociază Parcela",
    noParcelsAssociated: "Nu există parcele asociate",
    oneParcelAssociated: "1 Parcelă Asociată",
    parcelsAssociated: "{{count}} Parcele Asociate",
    selectParcels: "Selectează Parcele",
    parcelEvents: "Evenimente Parcele",
    addParcelEvent: "Adaugǎ Eveniment Parcelǎ",
    selectParcel: "Selectează Parcela",
    parcelEvent: "Eveniment Parcelă",
    deleteEvent: "Șterge Evenimentul",
    deleteEventConfirmation: "Ești sigur că vrei să ștergi evenimentul?",
    Irrigation: "Irigații",
    "Events related to water management and irrigation systems for crop and land hydration.":
      "Evenimente legate de gestionarea apei și sistemele de irigații pentru hidratarea culturilor și terenurilor.",
    "Pest & Weed Control": "Controlul Dăunătorilor și Buruienilor",
    "Activities for managing weeds, pests, and diseases affecting crops and land.":
      "Activități pentru gestionarea buruienilor, dăunătorilor și bolilor care afectează culturile și terenurile.",
    "Soil Management": "Managementul Solului",
    "Practices for maintaining or improving soil health, structure, and fertility.":
      "Practici pentru menținerea sau îmbunătățirea sănătății, structurii și fertilității solului.",
    "Land Transaction": "Tranzacție Terenuri",
    "Events related to buying, selling, leasing, or adjusting land boundaries.":
      "Evenimente legate de cumpărarea, vânzarea, închirierea sau ajustarea granițelor terenurilor.",
    Infrastructure: "Infrastructură",
    "Construction, maintenance, or modification of physical structures on the land.":
      "Construcția, întreținerea sau modificarea structurilor fizice de pe teren.",
    "Miscellaneous parcel management events not classified in other categories.":
      "Evenimente diverse de gestionare a parcelelor neclasificate în alte categorii.",
    "Drip Irrigation": "Irigație prin Picurare",
    "Application of water directly to plant roots through a low-flow system to minimize water usage.":
      "Aplicarea apei direct la rădăcinile plantelor printr-un sistem cu debit redus pentru a minimiza utilizarea apei.",
    "Sprinkler System": "Sistem de Aspersoare",
    "Use of overhead sprinklers to distribute water across the field or parcel.":
      "Utilizarea aspersoarelor suspendate pentru a distribui apa pe întreaga suprafață a câmpului sau parcelei.",
    "Flood Irrigation": "Irigație prin Inundare",
    "Traditional method of flooding fields with water for crop irrigation.":
      "Metodă tradițională de inundare a câmpurilor cu apă pentru irigarea culturilor.",
    "Irrigation Maintenance": "Întreținere Sistem de Irigații",
    "Repair, cleaning, or general upkeep of irrigation equipment and systems.":
      "Repararea, curățarea sau întreținerea generală a echipamentelor și sistemelor de irigații.",
    "Water Source Change": "Schimbare Sursă de Apă",
    "Modification of water source used for irrigation, such as well, river, or rainwater collection.":
      "Modificarea sursei de apă utilizată pentru irigații, cum ar fi fântână, râu sau colectarea apei de ploaie.",
    "Irrigation Schedule Change": "Schimbare Program de Irigare",
    "Adjustment to timing or frequency of irrigation events based on crop needs or water availability.":
      "Ajustarea timpului sau frecvenței evenimentelor de irigare în funcție de nevoile culturilor sau disponibilitatea apei.",
    "Herbicide Application": "Aplicare Erbicid",
    "Application of chemical substances to control or eliminate unwanted plants.":
      "Aplicarea substanțelor chimice pentru controlul sau eliminarea plantelor nedorite.",
    "Pesticide Application": "Aplicare Pesticid",
    "Use of chemicals to control insects or other pests damaging crops.":
      "Utilizarea substanțelor chimice pentru controlul insectelor sau altor dăunători care afectează culturile.",
    "Fungicide Application": "Aplicare Fungicid",
    "Treatment with substances that control fungal diseases in crops.":
      "Tratament cu substanțe care controlează bolile fungice ale culturilor.",
    "Manual Weeding": "Plivire Manuală",
    "Physical removal of weeds by hand or with hand tools.":
      "Îndepărtarea fizică a buruienilor manual sau cu unelte manuale.",
    "Soil Testing": "Testare Sol",
    "Analysis of soil composition to assess nutrient levels, pH, and other characteristics.":
      "Analiza compoziției solului pentru evaluarea nivelului de nutrienți, pH și alte caracteristici.",
    "Fertilizer Application": "Aplicare Îngrășământ",
    "Addition of natural or synthetic substances to enrich soil with nutrients.":
      "Adăugarea de substanțe naturale sau sintetice pentru îmbogățirea solului cu nutrienți.",
    Composting: "Compostare",
    "Creation or application of decomposed organic matter to improve soil structure and fertility.":
      "Crearea sau aplicarea materiei organice descompuse pentru îmbunătățirea structurii și fertilității solului.",
    "Cover Crop Planting": "Plantare Culturi de Acoperire",
    "Growing plants specifically to protect and enrich soil between main crop seasons.":
      "Cultivarea plantelor special pentru a proteja și îmbogăți solul între sezoanele principale de cultură.",
    "Lime Application": "Aplicare Var",
    "Addition of materials containing calcium to neutralize soil acidity and improve structure.":
      "Adăugarea materialelor ce conțin calciu pentru neutralizarea acidității solului și îmbunătățirea structurii.",
    Mulching: "Mulcire",
    "Application of material to soil surface to conserve moisture, suppress weeds, and regulate temperature.":
      "Aplicarea materialului pe suprafața solului pentru conservarea umidității, suprimarea buruienilor și reglarea temperaturii.",
    "Land Purchase": "Cumpărare Teren",
    "Acquisition of new land parcels to expand farm operations.":
      "Achiziționarea de noi parcele de teren pentru extinderea operațiunilor agricole.",
    "Land Sale": "Vânzare Teren",
    "Transfer of owned land to another party through sale.":
      "Transferul terenului deținut către o altă parte prin vânzare.",
    "Fence Installation": "Instalare Gard",
    "Construction of new fencing to define boundaries or contain livestock.":
      "Construcția de garduri noi pentru a defini granițele sau a ține animalele.",
    "Fence Repair": "Reparație Gard",
    "Maintenance or restoration of existing fence structures.":
      "Întreținerea sau restaurarea structurilor de gard existente.",
    "Building Construction": "Construcție Clădire",
    "Erection of structures such as barns, sheds, or processing facilities.":
      "Ridicarea structurilor precum hambare, șoproane sau facilități de procesare.",
    "Weather Damage": "Daune Cauzate de Intemperii",
    "Assessment and documentation of damage caused by extreme weather events.":
      "Evaluarea și documentarea daunelor cauzate de evenimente meteorologice extreme.",
    "Soil Remediation": "Remediere Sol",
    "Processes to remove contaminants or restore degraded soil conditions.":
      "Procese pentru eliminarea contaminanților sau restaurarea condițiilor de sol degradat.",
    "Grant Application": "Aplicare pentru Finanțare",
    "Submission of requests for financial assistance from government or private programs.":
      "Depunerea cererilor pentru asistență financiară din partea programelor guvernamentale sau private.",
    cropPlantings: "Culturi",
    myCropsPlantings: "Culturile Mele",
    noCropsFound: "Nu s-au găsit culturi",
    newCropPlanting: "Cultivare Nouă",
    seedingDate: "Data Semănării",
    selectCrop: "Selectează Cultura",
    plannedHarvestDate: "Dată Recoltare Planificată",
    expectedYield: "Randament Așteptat",
    savingCropPlanting: "Se salvează cultivarea...",
    cropPlantingSaved: "Cultivarea a fost salvată!",
    cropPlantingError: "A apărut o eroare la salvarea cultivării",
    cropPlantingDetails: "Detalii Cultivare",
    editCropPlanting: "Editează Cultivare",
    deleteCropPlanting: "Șterge Cultivarea",
    deleteCropPlantingConfirmation: "Ești sigur că vrei să ștergi cultivarea?",
    deletingCropPlanting: "Se șterge cultivarea...",
    cropPlantingDeleted: "Cultivarea a fost ștearsă!",
    Wheat: "Grâu",
    Barley: "Orz",
    Potatoes: "Cartofi",
    "Sugar Beet": "Sfeclă de zahăr",
    "Corn (Maize)": "Porumb",
    Tomatoes: "Roșii",
    Carrots: "Morcovi",
    Cabbage: "Varză",
    Onions: "Ceapă",
    Garlic: "Usturoi",
    Beans: "Fasole",
    Peas: "Mazăre",
    Lettuce: "Salată",
    Cucumber: "Castravete",
    Grapes: "Struguri",
    Olives: "Măsline",
    Rye: "Secară",
    Oats: "Ovăz",
    daysSinceSeeding: " Zile de la Semănat",
    daysUntilHarvest: "Zile până la Recoltare",
    assocCropPlanting: "Asociază Cultura",
    seeOtherAssociated: "Vezi restul",
    hideOtherAssociated: "Ascunde",
    selectCropPlantings: "Selectează Culturi",
    cropPlantingsAssociated_zero: "Nicio cultură asociată",
    cropPlantingsAssociated_one: "O cultură asociată",
    cropPlantingsAssociated: "{{count}} culturi asociate",
    parcel: "Parcelă",
    actualHarvestDate: "Data efectivă a recoltei",
    actualYield: "Randament efectiv",
    notSet: "Nesetat",
    cropGrowingDetails: "Detalii de cultivare",
    averageGrowingDays: "Zile medii de creștere",
    preferredTemperature: "Temperatura preferată",
    rowSpacing: "Spațiul între rânduri",
    plantSpacing: "Spațiul între plante",
    plantingDepth: "Adâncimea de plantare",

    // Adding missing keys from English
    Hen: "Găină",
    errorRemovingScale: "A apărut o eroare la eliminarea cântarului",
    selectBirdsGroups: "Selectați grupul de păsări",
  },
}
