import { format } from "date-fns"
import React from "react"
import { type DateRange } from "react-day-picker"
import { useTranslation } from "react-i18next"
import DrawerContentWrapper from "@/components/drawer-content-wrapper/drawer-content-wrapper.tsx"
import { SelectPill } from "@/components/select-pill/select-pill.tsx"
import {
  Drawer,
  DrawerContent,
  DrawerTrigger,
} from "@/components/shadcn/drawer.tsx"
import { DateRangePicker } from "@/features/events/common/components/date-filter-drawer/range-date-picker.tsx"

interface Props {
  selectedTimeFrame: DateRange | undefined
  onSelectedTimeFrameChange: (dateRange: DateRange | undefined) => void
}

export const DateFilterDrawer: React.FC<Props> = ({
  selectedTimeFrame,
  onSelectedTimeFrameChange,
}) => {
  const { t } = useTranslation()

  const triggerContent = () => {
    if (!selectedTimeFrame?.from && !selectedTimeFrame?.to) {
      return t("allTimes")
    } else if (selectedTimeFrame?.from && !selectedTimeFrame?.to) {
      return `${format(selectedTimeFrame.from, "dd-MM-yyyy")} - ${t("present")}`
    } else if (!selectedTimeFrame?.from && selectedTimeFrame?.to) {
      return `${t("present")} - ${format(selectedTimeFrame.to, "dd-MM-yyyy")}`
    } else if (selectedTimeFrame?.from && selectedTimeFrame?.to) {
      return `${format(selectedTimeFrame.from, "dd-MM-yyyy")} - ${format(
        selectedTimeFrame.to,
        "dd-MM-yyyy",
      )}`
    }
  }

  const isActive = selectedTimeFrame?.from || selectedTimeFrame?.to

  return (
    <Drawer>
      <DrawerTrigger>
        <SelectPill isActive={!!isActive}>{triggerContent()}</SelectPill>
      </DrawerTrigger>
      <DrawerContent className={"h-fit p-4"}>
        <DrawerContentWrapper>
          <DateRangePicker
            onSelectedTimeFrameChange={onSelectedTimeFrameChange}
            selectedTimeFrame={selectedTimeFrame}
            inDrawer
          />
        </DrawerContentWrapper>
      </DrawerContent>
    </Drawer>
  )
}
