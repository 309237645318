import { useTranslation } from "react-i18next"
import DrawerContentWrapper from "@/components/drawer-content-wrapper/drawer-content-wrapper"
import GenericTypeDrawerHeader from "@/components/generic-type-drawer/generic-type-drawer-header/generic-type-drawer-header"
import {
  Drawer,
  DrawerContent,
  DrawerTrigger,
} from "@/components/shadcn/drawer"
import { type Option } from "@/utils/types/misc-types"

export interface RenderFunctionDrawerItemComponentProps {
  id: string
  onDelete?: (id: string) => void
  isSelected?: boolean
  onSelect?: () => void
}

interface Props<
  T extends boolean,
  ItemProps = RenderFunctionDrawerItemComponentProps,
> {
  renderTrigger: React.ReactNode
  title?: string
  options: Option[]
  selectedValues: T extends true ? string[] : string
  onSelectionChange: (value: T extends true ? string[] : string) => void
  multiple?: T
  open?: boolean
  setOpen?: (open: boolean) => void
  ItemComponent: React.ComponentType<ItemProps>
}

const RenderFunctionDrawer = <T extends boolean>({
  renderTrigger,
  title,
  options,
  selectedValues,
  onSelectionChange,
  multiple = false as T,
  open,
  setOpen,
  ItemComponent,
}: Props<T>) => {
  const { t } = useTranslation()

  const handleSelectionChange = (value: string) => {
    if (!multiple) {
      onSelectionChange(value as T extends true ? string[] : string)
    } else {
      const currentValues = selectedValues as string[]
      const newValues = currentValues.includes(value)
        ? currentValues.filter((v) => v !== value)
        : [...currentValues, value]
      onSelectionChange(newValues as T extends true ? string[] : string)
    }
  }

  const isSelected = (value: string): boolean => {
    if (!multiple) {
      return selectedValues === value
    }
    return (selectedValues as string[]).includes(value)
  }

  const sortedOptions = [
    ...options
      .filter((option) => isSelected(option.value))
      .sort((a, b) => a.label.localeCompare(b.label)),
    ...options
      .filter((option) => !isSelected(option.value))
      .sort((a, b) => a.label.localeCompare(b.label)),
  ]

  const handleOnSaveClick = () => {
    setOpen && setOpen(false)
  }

  return (
    <Drawer open={open}>
      <DrawerTrigger asChild>{renderTrigger}</DrawerTrigger>
      <DrawerContent className="h-3/4 overflow-hidden">
        {title && (
          <GenericTypeDrawerHeader
            title={t(title)}
            onSaveClick={handleOnSaveClick}
          />
        )}
        <DrawerContentWrapper className="flex flex-col gap-[10px] p-2 pb-5">
          {sortedOptions?.map((option) => (
            <ItemComponent
              key={option.value}
              id={option.value}
              isSelected={isSelected(option.value)}
              onSelect={() => handleSelectionChange(option.value)}
            />
          ))}
        </DrawerContentWrapper>
      </DrawerContent>
    </Drawer>
  )
}

export default RenderFunctionDrawer
