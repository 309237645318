import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import GenericItemsGroup from "@/components/grouping/generic-items-group.tsx"
import { LoadingAnimation } from "@/components/loading-animation/loading-animation.tsx"
import NoResultsFound from "@/components/not-found/no-results-found.tsx"
import PageContentWrapperLarge from "@/components/page-content-wrappers/page-content-wrapper-large.tsx"
import MobileTopBar from "@/components/top-bars/mobile-top-bar/mobile-top-bar.tsx"
import { useGetAllParcelEventsQuery } from "@/features/events/parcel-events/api/parcel-events-api.ts"
import { ParcelEventCardWrapper } from "@/features/events/parcel-events/components/parcel-event-card/parcel-event-card.tsx"
import { NEW_PARCEL_EVENT_SCREEN_PATH } from "@/utils/constants/routes.ts"
import { groupItemsByTimeStampWithField } from "@/utils/helpers/time-grouping.ts"

const AllParcelEventsView = () => {
  const { t } = useTranslation()
  const { data: events = [], isLoading } = useGetAllParcelEventsQuery()

  const groupedEvents = useMemo(
    () =>
      events?.length ? groupItemsByTimeStampWithField(events, "date") : null,
    [events],
  )

  if (isLoading) {
    return (
      <>
        <MobileTopBar title={t("parcelEvents")} />
        <LoadingAnimation />
      </>
    )
  }

  return (
    <>
      <MobileTopBar
        title={t("parcelEvents")}
        navigateTo={NEW_PARCEL_EVENT_SCREEN_PATH}
      />
      <PageContentWrapperLarge>
        {groupedEvents ? (
          <GenericItemsGroup
            items={groupedEvents}
            ItemComponent={ParcelEventCardWrapper}
          />
        ) : (
          <NoResultsFound
            message={t("thereAreNoEventsYet")}
            image={"location"}
            imageClassName={"scale-50"}
            className={"h-[calc(100%-120px)]"}
          />
        )}
      </PageContentWrapperLarge>
    </>
  )
}

export default AllParcelEventsView
