import { type HiveResponse } from "@/features/bees/types/hives.ts"
import { type CommonEventType } from "@/features/events/common/types/common-event-type.ts"

export interface HiveScale {
  id: string
  phone_number: string
  hive?: HiveResponse
  last_seen: string
  last_measurement?: ScaleMeasurement
  events: HiveScaleEvent[]
}

export interface HiveScaleInput {
  phone_number: string
  hive: string | null
}

export const HIVE_SCALE_INPUT_DEFAULTS: HiveScaleInput = {
  phone_number: "",
  hive: "",
}

export interface ScaleMeasurement {
  id: number
  scale: number
  measured_at: string
  recorded_at: string
  weight: number
  extra_weight: number
  temperature: number
  pressure: number
  humidity: number
  battery: number
  signal_quality: string
  altitude: number
  latitude: number
  longitude: number
  weight_change_24h?: number
}

export type ScaleMetricKey = keyof Pick<
  ScaleMeasurement,
  "weight" | "extra_weight" | "temperature" | "pressure" | "humidity"
>

export type ScaleMetric = "temperature" | "weight" | "pressure" | "humidity"
export type TimePeriod =
  | "last_24h"
  | "current_week"
  | "last_2_weeks"
  | "last_month"
  | "custom"

export interface ScaleStatisticsParams {
  metric: ScaleMetric
  period?: TimePeriod
  start_date?: string
  end_date?: string
}

export interface MeasurementsResponse {
  count: number
  next: string | null
  previous: string | null
  results: ScaleMeasurement[]
}

export interface MeasurementsParams {
  start_date?: string
  end_date?: string
  min_weight?: number
  max_weight?: number
  period?: TimePeriod
  page?: number
  page_size?: number
}

export interface DataPoint {
  timestamp: string
  value: number
}

export interface ScaleStatistics {
  start_time: string
  end_time: string
  min: number
  max: number
  avg: number
  data_points: DataPoint[]
}

export enum HIVE_SCALE_METRICS {
  TEMPERATURE = "temperature",
  WEIGHT = "weight",
  PRESSURE = "pressure",
  HUMIDITY = "humidity",
}

export const HIVE_SCALE_PERIODS = {
  DAY: "last_24h",
  WEEK: "current_week",
  TWO_WEEKS: "last_2_weeks",
  MONTH: "last_month",
  CUSTOM: "custom",
} as const satisfies Record<string, TimePeriod>

export const hiveScaleTimePeriods = Object.values(HIVE_SCALE_PERIODS).map(
  (period) => ({
    value: period,
    label: period,
  }),
)

type MetricUnit = "kg" | "°C" | "%" | "hPa" | "count"

interface MetricConfig {
  unit: MetricUnit
  color: string
}

export const HIVE_SCALE_METRIC_CONFIG: Record<
  HIVE_SCALE_METRICS,
  MetricConfig
> = {
  [HIVE_SCALE_METRICS.WEIGHT]: {
    unit: "kg",
    color: "#B5642D",
  },
  [HIVE_SCALE_METRICS.TEMPERATURE]: {
    unit: "°C",
    color: "#3B7DCB",
  },
  [HIVE_SCALE_METRICS.PRESSURE]: {
    unit: "hPa",
    color: "#9649AC",
  },
  [HIVE_SCALE_METRICS.HUMIDITY]: {
    unit: "%",
    color: "#FF4948",
  },
} as const

export function getMetricConfig(metric: HIVE_SCALE_METRICS): MetricConfig {
  return HIVE_SCALE_METRIC_CONFIG[metric]
}

export interface HiveScaleEvent {
  id: string
  user: string
  scale: string
  hive_code?: string
  phone_number_change?: PhoneNumberchangeEvent
  created_at: string
  updated_at: string
  type: CommonEventType
}

interface PhoneNumberchangeEvent {
  old_phone_number: string
  new_phone_number: string
}

export const SCALE_REMOVED_EVENT_TYPE = "ScaleRemoved"
export const SCALE_ADDED_EVENT_TYPE = "ScaleAdded"
export const PHONE_NUMBER_CHANGED_EVENT_TYPE = "PhoneNumberChanged"
