import { createSlice, type PayloadAction } from "@reduxjs/toolkit"
import { logout } from "./auth-slice"
import { type UserProfile } from "@/features/settings-account"
import { initialUserProfile } from "@/features/settings-account/utils/initialStates.ts"

type UserProfileDraftSlice = {
  draft: Omit<UserProfile, "is_contract">
}

const initialState: UserProfileDraftSlice = { draft: initialUserProfile }

const userProfileDraftSlice = createSlice({
  name: "userProfileDraft",
  initialState,
  reducers: {
    setUserDraft: (state, action: PayloadAction<UserProfile>) => {
      state.draft = action.payload
    },
    setDraftUserFirstName: (state, action: PayloadAction<string>) => {
      state.draft.first_name = action.payload
    },
    setDraftUserLastName: (state, action: PayloadAction<string>) => {
      state.draft.last_name = action.payload
    },
    setDraftUserPhone: (state, action: PayloadAction<string>) => {
      state.draft.phone_number = action.payload
    },
    setDraftUserPicture: (state, action: PayloadAction<string | undefined>) => {
      state.draft.picture = action.payload
    },
    setDraftUserLanguage: (state, action: PayloadAction<string>) => {
      state.draft.language = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logout.fulfilled, () => initialState)
  },
})

export default userProfileDraftSlice.reducer

export const {
  setUserDraft,
  setDraftUserFirstName,
  setDraftUserLastName,
  setDraftUserPhone,
  setDraftUserPicture,
  setDraftUserLanguage,
} = userProfileDraftSlice.actions
