import { format, parseISO } from "date-fns"
import { HIVE_SCALE_PERIODS } from "@/features/bees/types/hive-scales.ts"

export interface ChartColors {
  stroke: string
  gradientStart: string
  gradientEnd: string
  gradientStartOpacity: number
  gradientEndOpacity: number
  grid: string
}

export const hexToRgba = (hex: string, alpha: number): string => {
  const r = parseInt(hex.slice(1, 3), 16)
  const g = parseInt(hex.slice(3, 5), 16)
  const b = parseInt(hex.slice(5, 7), 16)
  return `rgba(${r},${g},${b},${alpha})`
}

export const generateChartColors = (strokeColor: string): ChartColors => {
  return {
    stroke: strokeColor,
    gradientStart: hexToRgba(strokeColor, 0.88),
    gradientEnd: hexToRgba(strokeColor, 0.1),
    gradientStartOpacity: 0.8,
    gradientEndOpacity: 0.1,
    grid: "rgba(82, 82, 82, 0.05)",
  }
}

export const getTimeAxisConfig = (period: string) => {
  switch (period) {
    case HIVE_SCALE_PERIODS.DAY:
      return {
        format: (timestamp: string) => format(parseISO(timestamp), "HH:00"),
        formatTooltip: (timestamp: string) =>
          format(parseISO(timestamp), "HH:mm"),
      }
    case HIVE_SCALE_PERIODS.WEEK:
      return {
        format: (timestamp: string) => format(parseISO(timestamp), "EEE"),
        formatTooltip: (timestamp: string) =>
          format(parseISO(timestamp), "MMM dd, HH:mm"),
      }
    default:
      return {
        format: (timestamp: string) => format(parseISO(timestamp), "MM dd"),
        formatTooltip: (timestamp: string) =>
          format(parseISO(timestamp), "MMM dd, HH:mm"),
      }
  }
}

export const getTickInterval = (dataLength: number, period: string) => {
  switch (period) {
    case HIVE_SCALE_PERIODS.DAY:
      return 1
    case HIVE_SCALE_PERIODS.WEEK:
      return Math.floor(dataLength / 8)
    case HIVE_SCALE_PERIODS.TWO_WEEKS:
      return Math.floor(dataLength / 7)
    case HIVE_SCALE_PERIODS.MONTH:
      return Math.floor(dataLength / 7)
    default:
      return undefined
  }
}
