import isEqual from "lodash.isequal"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useSearchParams } from "react-router-dom"
import { toast } from "react-toastify"
import PageContentWrapperLarge from "@/components/page-content-wrappers/page-content-wrapper-large.tsx"
import EditorTopBar from "@/components/top-bars/editor-top-bar/editor-top-bar.tsx"
import { useCreateParcelEventMutation } from "@/features/events/parcel-events/api/parcel-events-api.ts"
import { ParcelEventEditor } from "@/features/events/parcel-events/components/parcel-event-editor/parcel-event-editor.tsx"
import {
  initialParcelEventState,
  type ParcelEventInput,
} from "@/features/events/parcel-events/types/parcel-events.ts"
import useModal from "@/features/modals/hooks/use-modal.ts"
import { MODALS } from "@/features/modals/types/modals.tsx"
import { PARCEL_EVENTS_SCREEN_PATH } from "@/utils/constants/routes.ts"

const getInitialParcelEventState = (parcelId: string | null) => {
  if (!parcelId) return initialParcelEventState
  return {
    ...initialParcelEventState,
    parcel: parcelId,
  }
}

const NewParcelEventView = () => {
  const { t } = useTranslation()
  const { openModal } = useModal()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const [createParcelEvent] = useCreateParcelEventMutation()
  const parcelId = searchParams.get("parcelId")
  const [workingParcelEvent, setWorkingParcelEvent] =
    useState<ParcelEventInput>(getInitialParcelEventState(parcelId))
  const hasChanges = !isEqual(workingParcelEvent, initialParcelEventState)

  const onBackClick = () => {
    if (hasChanges) {
      openModal(MODALS.BASE_MODAL, {
        title: t("unsavedChanges"),
        content: t("youWillLoseChanges"),
        onActionClick: () => {
          navigate(-1)
        },
      })
    } else {
      navigate(-1)
    }
  }

  const handleSave = async () => {
    const promise = createParcelEvent(workingParcelEvent).unwrap()
    await toast
      .promise(promise, {
        pending: t("savingEvent"),
        success: t("eventSaved"),
        error: t("errorSavingEvent"),
      })
      .then(() => {
        navigate(PARCEL_EVENTS_SCREEN_PATH, { replace: true })
      })
  }

  return (
    <>
      <EditorTopBar
        text={t("addParcelEvent")}
        isModified={hasChanges}
        onBackClick={onBackClick}
        onSave={handleSave}
      />
      <PageContentWrapperLarge>
        <ParcelEventEditor
          parcelEvent={workingParcelEvent}
          onParcelEventChange={setWorkingParcelEvent}
        />
      </PageContentWrapperLarge>
    </>
  )
}

export default NewParcelEventView
