import { compareDesc, parseISO } from "date-fns"
import { apiSlice, TagType } from "@/features/api/apiSlice.ts"
import { type GenericAnimalEvent } from "@/features/events/animal-events/types/animal-events.ts"

const ANIMAL_EVENTS_API_BASE_URL = "/animal-events/"

const sortEvents = (events: GenericAnimalEvent[]) => {
  return events.sort((a, b) => {
    const dateComparison = compareDesc(parseISO(a.date), parseISO(b.date))
    if (dateComparison !== 0) {
      return dateComparison
    }
    return compareDesc(parseISO(a.created_at), parseISO(b.created_at))
  })
}

export const animalEventsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createAnimalEvent: builder.mutation<
      GenericAnimalEvent,
      Partial<GenericAnimalEvent>
    >({
      query: (animalEvent) => {
        return {
          url: ANIMAL_EVENTS_API_BASE_URL,
          method: "POST",
          body: animalEvent,
        }
      },
      invalidatesTags: [
        { type: TagType.AnimalEvents },
        { type: TagType.Animals },
        { type: TagType.GestationCalendars },
      ],
    }),
    getAnimalEventById: builder.query<GenericAnimalEvent, string>({
      query: (id) => ({
        url: `${ANIMAL_EVENTS_API_BASE_URL}${id}/`,
      }),
      providesTags: (_result, _error, id) => [
        { type: TagType.AnimalEvents, id },
      ],
    }),
    updateAnimalEvent: builder.mutation<
      GenericAnimalEvent,
      { id: GenericAnimalEvent["id"] } & Partial<GenericAnimalEvent>
    >({
      query: ({ id, ...patch }) => {
        return {
          url: `${ANIMAL_EVENTS_API_BASE_URL}${id}/`,
          method: "PATCH",
          body: patch,
        }
      },
      invalidatesTags: (_result, _error, { id }) => [
        { type: TagType.AnimalEvents, id },
        { type: TagType.AnimalEvents, id: "LIST" },
      ],
    }),
    deleteAnimalEvent: builder.mutation<void, Partial<GenericAnimalEvent>>({
      query: (event) => ({
        url: `${ANIMAL_EVENTS_API_BASE_URL}${event.id}/`,
        method: "DELETE",
      }),
      invalidatesTags: (_result, _error, event) => [
        { type: TagType.Animals, id: event.animal },
        { type: TagType.AnimalEvents, id: "LIST" },
      ],
    }),
    getAllAnimalEvents: builder.query<GenericAnimalEvent[], void>({
      query: () => ({
        url: ANIMAL_EVENTS_API_BASE_URL,
      }),
      providesTags: () => [{ type: TagType.AnimalEvents, id: "LIST" }],
      transformResponse: (response: GenericAnimalEvent[]) => {
        return sortEvents(response)
      },
    }),
    getEventsForAnimal: builder.query<GenericAnimalEvent[], string>({
      query: (animalId) => ({
        url: `${ANIMAL_EVENTS_API_BASE_URL}?animal_id=${animalId}`,
      }),
      providesTags: (_result, _error, animalId) => [
        { type: TagType.AnimalEvents, id: animalId },
      ],
      transformResponse: (response: GenericAnimalEvent[]) => {
        return sortEvents(response)
      },
    }),
  }),
})

export const {
  useCreateAnimalEventMutation,
  useGetEventsForAnimalQuery,
  useGetAnimalEventByIdQuery,
  useUpdateAnimalEventMutation,
  useDeleteAnimalEventMutation,
  useGetAllAnimalEventsQuery,
} = animalEventsApi
