import { skipToken } from "@reduxjs/toolkit/query"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import { ColorPalleteIcon } from "@/assets/icons/bees/color-pallete-icon.tsx"
import DetailsIcon from "@/assets/icons/bees/details-icon.tsx"
import HiveIcon from "@/assets/icons/bees/hive-icon.tsx"
import { MonthCalendarIcon } from "@/assets/icons/bees/month-calendar-icon.tsx"
import QueenIcon from "@/assets/icons/bees/queen-icon.tsx"
import { TagIcon } from "@/assets/icons/bees/tag-icon.tsx"
import { useGetHiveByIdQuery } from "@/features/bees/api/hives-api.ts"
import HiveDetailsRow from "@/features/bees/components/hives/hive-details/hive-details-row.tsx"
import { type QueenResponse } from "@/features/bees/types/queens.ts"
import { queenColorBackground } from "@/features/bees/utils/queen-utils.ts"

interface Props {
  queen: QueenResponse
}

export const QueenDetails: React.FC<Props> = ({ queen }) => {
  const { t } = useTranslation()
  const { hiveId } = useParams()
  const { data: hive } = useGetHiveByIdQuery(hiveId ?? skipToken)

  return (
    <div
      className={"widget-border box-border flex flex-col gap-4 bg-white p-4"}
    >
      <HiveDetailsRow
        text={t("name")}
        value={queen.name}
        iconClassName={"bg-yellow-300"}
      >
        <TagIcon />
      </HiveDetailsRow>
      {queen.year && (
        <HiveDetailsRow
          text={t("year_uppercase")}
          value={queen.year}
          iconClassName={"bg-green-500"}
        >
          <MonthCalendarIcon />
        </HiveDetailsRow>
      )}
      {queen.color && (
        <HiveDetailsRow
          text={t("queenColor")}
          value={queen.color}
          iconClassName={queenColorBackground(queen.color)}
        >
          <ColorPalleteIcon />
        </HiveDetailsRow>
      )}
      {hive && (
        <HiveDetailsRow
          text={t("hive")}
          value={hive.code}
          iconClassName={"bg-blue-300"}
        >
          <HiveIcon className={"scale-150"} />
        </HiveDetailsRow>
      )}
      <HiveDetailsRow
        text={t("queenOrigin")}
        value={t(queen.origin)}
        iconClassName={"bg-red-300"}
      >
        <QueenIcon />
      </HiveDetailsRow>
      {queen.description && (
        <HiveDetailsRow
          text={t("details")}
          value={t(queen.description)}
          enableExpand
          iconClassName={"bg-[var(--menu-financial-journal-color)]"}
        >
          <DetailsIcon className={"scale-150"} />
        </HiveDetailsRow>
      )}
    </div>
  )
}
