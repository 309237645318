import { format } from "date-fns"
import React from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import PlaceholderIcon from "@/assets/icons/misc/placeholder-icon.tsx"
import { type ParcelEvent } from "@/features/events/parcel-events/types/parcel-events.ts"
import { PARCEL_EVENT_VIEW_SCREEN_PATH } from "@/utils/constants/routes.ts"
import { type ItemsArray } from "@/utils/helpers/time-grouping.ts"

interface Props {
  event: ParcelEvent
}

const ParcelEventCardWrapper: React.FC<{ item: ItemsArray[number] }> = ({
  item,
}) => {
  return <ParcelEventCard event={item as ParcelEvent} />
}

const ParcelEventCard: React.FC<Props> = ({ event }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const navigateToEvent = () => {
    navigate(PARCEL_EVENT_VIEW_SCREEN_PATH.replace(":parcelEventId", event.id))
  }

  return (
    <div
      onClick={navigateToEvent}
      className="box-border flex min-h-[60px] w-full items-center justify-between gap-2.5 rounded-[--fp-border-radius] border border-neutral-200 bg-white px-[15px] py-[7px]"
    >
      <div className={"grid place-content-center"}>
        {event.type_picture ? (
          <img
            src={event.type_picture}
            alt={t("eventPicture")}
            className={"h-[30px] w-[30px] rounded-[8px] bg-brown-500 p-1"}
          />
        ) : (
          <PlaceholderIcon />
        )}
      </div>
      <div className="flex h-10 flex-1 flex-col justify-between">
        <div
          className={
            "grid grid-cols-[auto_auto] items-center justify-between gap-[10px]"
          }
        >
          <div className={"truncate text-body-sm"}>{t(event.type_name)}</div>
          <div>{format(new Date(event.date), "dd.MM.yyyy")}</div>
        </div>
        <div className="flex items-start justify-start gap-[5px] text-[--dark-grey] opacity-50">
          <p className={"font-semibold opacity-80"}>{t("parcel")}</p>
          <p>{event.parcel_name}</p>
        </div>
      </div>
    </div>
  )
}

export { ParcelEventCardWrapper, ParcelEventCard }
