import { useState } from "react"
import { useTranslation } from "react-i18next"
import GenericTypeDrawerHeader from "@/components/generic-type-drawer/generic-type-drawer-header/generic-type-drawer-header.tsx"
import { Button } from "@/components/shadcn/button.tsx"
import {
  Drawer,
  DrawerContent,
  DrawerTrigger,
} from "@/components/shadcn/drawer.tsx"
import { Input } from "@/components/shadcn/input.tsx"
import { Label } from "@/components/shadcn/label.tsx"
import useModal from "@/features/modals/hooks/use-modal.ts"
import { MODALS } from "@/features/modals/types/modals.tsx"
import { CoordinateCard } from "@/features/parcels/components/coodinate-card/coordinate-card.tsx"
import { type ParcelCoordinate } from "@/features/parcels/types/parcel.ts"

interface Props {
  coordinates: ParcelCoordinate[]
  onUpdateCoordinate: (index: number, coordinate: ParcelCoordinate) => void
  onDeleteCoordinate: (index: number) => void
}

export const CoordinateListUpdate: React.FC<Props> = ({
  coordinates,
  onUpdateCoordinate,
  onDeleteCoordinate,
}) => {
  const { t } = useTranslation()
  const { openModal } = useModal()
  const [editIndex, setEditIndex] = useState<number | null>(null)
  const [currentLatitude, setCurrentLatitude] = useState<string>("")
  const [currentLongitude, setCurrentLongitude] = useState<string>("")

  const handleDrawerOpen = (index: number) => {
    setEditIndex(index)
    setCurrentLatitude(coordinates[index].latitude.toString())
    setCurrentLongitude(coordinates[index].longitude.toString())
  }

  const handleDrawerClose = () => {
    setEditIndex(null)
    setCurrentLatitude("")
    setCurrentLongitude("")
  }

  const handleUpdateCoordinate = () => {
    if (editIndex === null || !currentLatitude || !currentLongitude) return

    onUpdateCoordinate(editIndex, {
      latitude: parseFloat(currentLatitude),
      longitude: parseFloat(currentLongitude),
    })

    setCurrentLatitude("")
    setCurrentLongitude("")
    setEditIndex(null)
  }

  const handleDeleteCoordinate = () => {
    openModal(MODALS.BASE_MODAL, {
      title: t("deletePoint"),
      content: t("deletePointConfirmation"),
      onActionClick: () => {
        if (editIndex !== null) {
          onDeleteCoordinate(editIndex)
          handleDrawerClose()
        }
      },
    })
  }

  const hasChanges =
    editIndex !== null &&
    (currentLatitude !== coordinates[editIndex].latitude.toString() ||
      currentLongitude !== coordinates[editIndex].longitude.toString())

  return (
    <>
      {coordinates.length > 0 && (
        <div className={"widget-border space-y-4 bg-white p-4"}>
          <i className={"text-neutral-600"}>{t("parcelCoordinates")}</i>
          <div className={"mt-2 flex flex-col gap-2"}>
            {coordinates.map((coordinate, index) => (
              <Drawer
                key={index}
                open={editIndex === index}
                onOpenChange={(open) => {
                  if (open) {
                    handleDrawerOpen(index)
                  } else {
                    handleDrawerClose()
                  }
                }}
              >
                <DrawerTrigger className="w-full">
                  <CoordinateCard coordinate={coordinate} index={index} />
                </DrawerTrigger>
                <DrawerContent className={"h-3/4"}>
                  <GenericTypeDrawerHeader
                    title={t("editPointCoordinates")}
                    onSaveClick={
                      hasChanges ? handleUpdateCoordinate : undefined
                    }
                  />
                  <div className="space-y-4 p-4">
                    <div className="space-y-2">
                      <Label htmlFor="latitude">{t("latitudeY")}</Label>
                      <Input
                        id="latitude"
                        type="number"
                        step="0.000001"
                        placeholder="ex. 51.507351"
                        value={currentLatitude}
                        onChange={(e) => setCurrentLatitude(e.target.value)}
                      />
                    </div>

                    <div className="space-y-2">
                      <Label htmlFor="longitude">{t("longitudeX")}</Label>
                      <Input
                        id="longitude"
                        type="number"
                        step="0.000001"
                        placeholder="ex. 0.127758"
                        value={currentLongitude}
                        onChange={(e) => setCurrentLongitude(e.target.value)}
                      />
                    </div>
                  </div>

                  <Button
                    onClick={handleDeleteCoordinate}
                    variant="outline"
                    size={"lg"}
                    className={"m-4 border-red-500 bg-white text-red-500"}
                  >
                    {t("delete")}
                  </Button>
                </DrawerContent>
              </Drawer>
            ))}
          </div>
        </div>
      )}
    </>
  )
}
