export const FR_TRANSLATIONS: Record<string, any> = {
  translation: {
    hello: "Salut !",
    featureNotYetImplemented:
      "Cette fonctionnalité n'est pas encore implémentée.",
    yes: "Oui",
    no: "Non",
    yesterday: "Hier",
    today: "Aujourd'hui",
    tomorrow: "Demain",
    thisWeek: "Cette semaine",
    thisMonth: "Ce mois",
    lastMonth: "Le mois dernier",
    thisYear: "Cette année",
    lastYear: "L'année dernière",
    pastYears: "Années précédentes",
    homeTab: "Accueil",
    tasksTab: "Tasks",
    addBottomMenu: "Ajouter",
    farmTab: "Ma ferme",
    notifications: "Notifications",
    notification: "Notification",
    menu: "Menu",
    accountTab: "Mon compte",
    oneSecond: "Un moment...",
    weather: "Météo",
    notes: "Notes",
    addNote: "Ajouter une note",
    newNote: "Nouvelle note",
    myNote: "Ma note",
    placeholderTitle: "Titre",
    placeholderText: "Votre texte",
    deleteNote: "Supprimer la note",
    searchNote: "Rechercher une note",
    noNotesFound: "Aucune note trouvée",
    shareNote: "Partager la note",
    formatNote: "Formatage",
    notesBackConfirmation:
      "Vous avez fait des modifications. Êtes-vous sûr de vouloir quitter sans sauvegarder ?",
    sureWantToLeave: "Êtes-vous sûr de vouloir quitter sans sauvegarder ?",
    youMadeChanges: "Vous avez fait des modifications.",
    unsavedChanges: "Modifications non sauvegardées !",
    continueWhereYouLeftOff: "Continuer où vous vous êtes arrêté",
    youHaveUnsavedChanges:
      "Vous avez des modifications non sauvegardées de la session précédente. Souhaitez-vous continuer avec ces modifications ou les abandonner ?",
    youWillLoseChanges:
      "Vous perdrez les modifications non sauvegardées. Voulez-vous continuer ?",
    backConfirmation: "Êtes-vous sûr de vouloir retourner aux Notes ?",
    noteWillBeDeleted: "La note en cours sera supprimée si oui.",
    continue: "Continuer",
    leave: "Quitter",
    discard: "Abandonner",
    delete: "Supprimer",
    stay: "Rester",
    save: "Sauvegarder",
    goToNotes: "Aller aux notes",
    seeAllNotes: "Voir toutes les notes",
    deleteNoteConfirmation: "Êtes-vous sûr de vouloir supprimer la Note ?",
    deletingNote: "Suppression de la note...",
    noteDeleted: "La note a été supprimée !",
    errorDeletingNote:
      "Une erreur s'est produite lors de la suppression de la note !",
    updatingNote: "Mise à jour de la note...",
    noteUpdated: "La note a été mise à jour !",
    errorUpdatingNote:
      "Une erreur s'est produite lors de la mise à jour de la note !",
    postingNote: "Création de la note...",
    notePosted: "La note a été créée !",
    notePostError: "Une erreur s'est produite lors de la création de la note !",
    noNoteAssociated: "Aucune note associée",
    oneNoteAssociated: "Une note associée",
    notesAssociated: "notes associées",
    noteContentIsMandatory: "Le contenu de la note est obligatoire !",
    seeOtherNotesAssociated: "Voir les autres notes associées",
    hideOtherNotesAssociated: "Masquer les autres notes associées",
    draft: "brouillon",
    unsavedChangesIndicator: "modifications non sauvegardées",
    notEnoughData: "Données insuffisantes pour afficher le graphique",
    notesWillBeSetAsDraft:
      "Les notes seront définies comme brouillon. Vous pourrez revenir plus tard pour sauvegarder les modifications.",
    noteWasLoadedWithPreviousData:
      "La note a été chargée avec les données précédentes. Voulez-vous conserver les données et continuer ?",

    //Tasks

    taskWasLoadedWithPreviousData:
      "La tâche a été chargée avec les données précédentes. Voulez-vous conserver les données et continuer ?",
    tasks: "Tâches",
    dueTasks: "Tâches en retard",
    currentTasks: "Tâches actuelles",
    futureTasks: "Tâches futures",
    searchTask: "Rechercher une tâche",
    noTasksFound: "Aucune tâche trouvée",
    newTask: "Nouvelle tâche",
    myTask: "Ma tâche",
    title: "Titre",
    description: "Description",
    deleteTask: "Supprimer la tâche",
    disinfestation: "Déparasitage",
    treatments: "Traitements",
    status: "Statut",
    category: "Catégorie",
    chooseCategory: "Choisir la catégorie",
    ALL: "Toutes les tâches",
    DONE: "Tâches terminées",
    TODO: "Tâches actuelles",
    backConfirmationTasks: "Êtes-vous sûr de vouloir retourner aux Tâches ?",
    backConfirmationSettings:
      "Êtes-vous sûr de vouloir retourner aux Paramètres ?",
    taskWillBeDeleted: "La tâche en cours sera supprimée.",
    modificationsWillBeLost: "Les modifications seront perdues.",
    onTime: "À l'heure",
    before30: "30 min avant",
    beforeHour: "1 heure avant",
    beforeDay: "1 jour avant",
    beforeWeek: "1 semaine avant",
    daily: "Quotidien",
    weekly: "Hebdomadaire",
    monthly: "Mensuel",
    yearly: "Annuel",
    Date: "Date",
    Hour: "Heure",
    Reminder: "Rappel",
    Recurrence: "Récurrence",
    AssociateNote: "Associer une note",
    AssociateAnimal: "Associer un animal",
    AssociateTask: "Associer une tâche",
    dashboardCurrentTasks: "Actuelles",
    dashboardFutureTasks: "Futures",
    dashboardFinalizedTasks: "Terminées",
    dashboardOverdueTasks: "En retard",
    seeAllTasks: "Voir toutes les tâches",
    deleteTaskConfirmation: "Êtes-vous sûr de vouloir supprimer la Tâche ?",
    allCategories: "Toutes les catégories",
    noNewTasks: "Vous n'avez pas de nouvelles tâches",
    updatingTask: "Mise à jour de la tâche...",
    taskUpdated: "La tâche a été mise à jour !",
    errorUpdatingTask:
      "Une erreur s'est produite lors de la mise à jour de la tâche !",
    creatingTask: "Création de la tâche...",
    taskCreated: "La tâche a été créée !",
    errorCreatingTask:
      "Une erreur s'est produite lors de la création de la tâche !",
    deletingTask: "Suppression de la tâche...",
    taskDeleted: "La tâche a été supprimée !",
    errorDeletingTask:
      "Une erreur s'est produite lors de la suppression de la tâche !",
    oneTaskAssociated: "Une tâche associée",
    tasksAssociated: "tâches associées",
    taskNotFound: "La tâche n'a pas été trouvée !",
    titleRequired: "Le titre est obligatoire pour créer une tâche",
    title5Characters: "Le titre doit comporter au moins 5 caractères",
    title100Characters: "Le titre doit comporter au maximum 100 caractères",
    addCategory: "Ajouter une catégorie",
    editCategory: "Modifier la catégorie",
    selectTaskCategory: "Sélectionner la catégorie de la tâche",
    taskWillBeSetAsDraft:
      "La tâche sera définie comme brouillon. Vous pourrez revenir plus tard pour sauvegarder les modifications.",
    taskSetAsDraft: "La tâche a été définie comme brouillon.",
    addTask: "Ajouter une tâche",
    animalWasLoadedWithPreviousData:
      "L'animal a été chargé avec les données précédentes. Voulez-vous conserver les données et continuer ?",
    animalWillBeSetAsDraft:
      "L'animal sera défini comme brouillon. Vous pourrez revenir plus tard pour sauvegarder les modifications.",
    ain: "N° d'identification",
    myFarm: "Ma ferme",
    searchAnimal: "Rechercher un animal : Nom, n° d'identification, statut",
    outOfExploitation: "Sortis d'exploitation",
    removeAnimalFromExploitation: "Marquer la sortie de la ferme",
    searchBreed: "Rechercher une race",
    newAnimal: "Ajouter un animal",
    addCow: "Ajouter un bovin",
    myAnimal: "Mon animal",
    addGoat: "Ajouter une chèvre",
    addSheep: "Ajouter un mouton",
    addPork: "Ajouter un porc",
    addBird: "Ajouter un oiseau",
    addMammal: "Ajouter un mammifère",
    addBee: "Ajouter une ruche",
    statusCows: "Statut bovins",
    statusGoats: "Statut chèvres",
    statusSheep: "Statut moutons",
    statusPork: "Statut porcs",
    statusBirds: "Statut oiseaux",
    statusBee: "Statut ruchers",
    statusAnimal: "Statut animaux",
    backConfirmationAnimals: "Êtes-vous sûr de vouloir retourner à Ma ferme ?",
    animalWillBeDeleted: "L'animal en cours sera supprimé si oui.",
    name: "Nom",
    surname: "Prénom",
    taskTitleError: "Le titre est obligatoire pour créer une tâche",
    crotal: "Boucle d'identification",
    Male: "Mâle",
    Female: "Femelle",
    allGenders: "Les deux sexes",
    sex: "Sexe",
    registryEntryWay: "Mode d'entrée dans le registre",
    Birth: "Naissance",
    Beef: "Viande",
    Meat: "Viande",
    Purchase: "Achat",
    Production: "Production",
    Dairy: "Lait",
    Sale: "Vente",
    Sacrifice: "Sacrifice",
    Transfer: "Transfert",
    Loss: "Perte",
    Death: "Mort",
    details: "Détails",
    categories: "Catégories",
    deleteAnimal: "Supprimer l'animal",
    birthDate: "Date de naissance",
    entryDate: "Date d'entrée en exploitation",
    ALL_ANIMALS: "Toutes les catégories",
    ALL_GENDERS: "Tous les sexes",
    Cows: "Bovins",
    Cow: "Vache",
    singleSheep: "Ovin",
    Sheep: "Ovins",
    FEMALE_SHEEP: "Brebis",
    YOUNG_GOAT: "Chevreau",
    MALE_GOAT: "Bouc",
    FEMALE_GOAT: "Chèvre",
    RAM: "Bélier",
    singlePork: "Porcin",
    Pork: "Porcins",
    MALE_PIG: "Porc",
    FEMALE_PIG: "Truie",
    PIGLET: "Porcelet",
    singleGoat: "Caprin",
    Goat: "Caprins",
    breed: "Race",
    Lamb: "Agneau",
    Chicken: "Poule",
    Cock: "Coq",
    Bee: "Abeille",
    exploitEntryDate: "Date d'entrée en exploitation",
    calves_count: "Naissances",
    gestation_count: "Confirmations de gestation",
    medical_treatments_count: "Traitements",
    insemination_count: "Monte / Inséminations",
    lactation_count: "Lactations",
    nameAnimalError: "* Le nom de l'animal est obligatoire",
    categoryAnimalError: "* La catégorie de l'animal est obligatoire",
    breedAnimalError: "* La race de l'animal est obligatoire",
    crotalAnimalError: "* Le n° d'identification de l'animal est obligatoire",
    sexAnimalError: "* Le sexe de l'animal est obligatoire",
    birthDateAnimalError: "* La date de naissance de l'animal est obligatoire",
    entryDateAnimalError: "* La date d'entrée en exploitation est obligatoire",
    registryEntryWayAnimalError:
      "* Le mode d'entrée dans le registre est obligatoire",
    productionAnimalError: "* Le type de production est obligatoire",
    detailsAnimalError: "* Les détails de l'animal sont obligatoires",
    exploitEntryDateAnimalError:
      "* La date d'entrée en exploitation est obligatoire",
    fieldError: "* Ce champ est obligatoire",
    age: "Âge",
    years: "ans",
    year: "an",
    year_uppercase: "An",
    months: "mois",
    month: "mois",
    and: "et",
    lessThanAMonth: "moins d'un mois",
    allAges: "Tous les âges",
    age02cow: "Veau",
    age23cow: "Génisse",
    age3plusCow: "Mature",
    age510: "5 - 10 ans",
    species: "Espèces",
    allSpecies: "Toutes les espèces",
    Calf: "Veau",
    Veal: "Génisse",
    mature: "Mature",
    goats: "Chèvres",
    ALL_SPECIES: "Toutes les espèces",
    heads: "Têtes",
    addAnimal: "Ajouter un animal",
    animalSummary: "Résumé des données de l'animal",
    animalMother: "Mère de l'animal",
    animalFather: "Père de l'animal",
    seeFarm: "Voir ma ferme",
    deleteAnimalConfirmation: "Êtes-vous sûr de vouloir supprimer l'animal ?",
    deletingAnimal: "Suppression de l'animal...",
    animalDeleted: "L'animal a été supprimé !",
    errorDeletingAnimal:
      "Une erreur s'est produite lors de la suppression de l'animal !",
    updatingAnimal: "Mise à jour de l'animal...",
    animalUpdated: "L'animal a été mis à jour !",
    animalUpdateError:
      "Une erreur s'est produite lors de la mise à jour de l'animal !",
    creatingAnimal: "Création de l'animal...",
    animalCreated: "L'animal a été créé !",
    animalCreateError:
      "Une erreur s'est produite lors de la création de l'animal !",
    allAnimals: "Tous les animaux",
    animals: "Animaux",
    noAnimalAssociated: "Aucun animal associé",
    oneAnimalAssociated: "Un animal associé",
    oneAnimalSelected: "Un animal sélectionné",
    animalsAssociated: "animaux associés",
    seeOtherAnimalsAssociated: "Voir les autres animaux associés",
    hideOtherAnimalsAssociated: "Masquer les autres animaux associés",
    animalsSelected: "animaux sélectionnés",
    ainRequired: "Le n° d'identification est obligatoire pour créer un animal",
    genderRequired: "Le sexe est obligatoire pour créer un animal",
    birthDateRequired:
      "La date de naissance est obligatoire pour créer un animal",
    entryDateRequired:
      "La date d'entrée en exploitation est obligatoire pour créer un animal",
    entryWayIsRequired: "Le mode d'entrée dans le registre est obligatoire",
    intendedPurposeIsRequired: "La production est obligatoire",
    speciesRequired: "L'espèce est obligatoire pour créer un animal",
    breedIsRequired: "La race est obligatoire pour créer un animal",
    entryDateMustBeLaterThanBirthDate:
      "La date d'entrée doit être postérieure à la date de naissance",
    birthDateCannotBeInTheFuture:
      "La date de naissance ne peut pas être dans le futur",
    entryDateCannotBeInTheFuture:
      "La date d'entrée ne peut pas être dans le futur",
    nameFarmRequired: "Le nom de la ferme est obligatoire !",
    nameMaxLength: "Le nom doit comporter au maximum 100 caractères !",
    selectAnimalMother: "Sélectionner la mère de l'animal",
    selectAnimalFather: "Sélectionner le père de l'animal",
    ALL_STATUSES: "Tous les statuts",
    HEAT: "Chaleurs",
    GESTATING: "Gestantes",
    LACTATING: "Lactation",
    ILL: "À examiner",
    HEALTHY: "Saines",
    DRY: "Repos",
    gestationPeriodCalendar: "Calendrier de gestation",
    jurnal: "Journal",
    vocalAssistant: "Assistant vocal",
    contactTheSpecialist: "Contacter le spécialiste",
    contactSpecialist: "Contacter le spécialiste",
    voiceAssistant: "Assistant vocal",
    goodMorning: "Bonjour",
    goodAfternoon: "Bonjour",
    goodEvening: "Bonsoir",
    Monday: "Lundi",
    Tuesday: "Mardi",
    Wednesday: "Mercredi",
    Thursday: "Jeudi",
    Friday: "Vendredi",
    Saturday: "Samedi",
    Sunday: "Dimanche",
    january: "Janvier",
    february: "Février",
    march: "Mars",
    april: "Avril",
    may: "Mai",
    june: "Juin",
    july: "Juillet",
    august: "Août",
    september: "Septembre",
    october: "Octobre",
    november: "Novembre",
    december: "Décembre",
    dashboard: "Tableau de bord",
    allNotifications: "Toutes les notifications",
    ALL_NOTIFICATIONS: "Toutes les notifications",
    ALERTS: "Alertes",
    REMINDERS: "Rappels",
    INFO: "Informations",
    MANDATORY: "Obligatoires",
    allRead: "Toutes",
    read: "Lues",
    unread: "Non lues",
    all: "Toutes",
    seeAllNotifications: "Voir toutes les notifications",
    noNewNotifications: "Vous n'avez pas de nouvelles notifications",
    notificationWasRead: "La notification a été lue !",
    notificationsWereRead: "Les notifications ont été lues !",
    updateNotificationError:
      "Une erreur s'est produite lors de la mise à jour de la notification !",
    updateNotificationsError:
      "Une erreur s'est produite lors de la mise à jour des notifications !",
    notificationError: "Une erreur s'est produite avec la notification !",
    updatingNotification: "Mise à jour de la notification...",
    updatingNotifications: "Mise à jour des notifications...",
    followLink: "Suivre le lien",
    youSureYouWantToReadThemAll:
      "Êtes-vous sûr de vouloir marquer toutes les notifications comme lues ?",
    markAllAsRead: "Marquer toutes comme lues",
    cancel: "Annuler",
    settings: "Paramètres",
    farmProfile: "Profil de la ferme",
    hiveProfile: "Profil du rucher",
    companyInfo: "Informations de l'entreprise",
    farmName: "Nom de la ferme",
    farmNameRequired: "Le nom de la ferme est obligatoire !",
    address: "Adresse",
    location: "Emplacement",
    selectLocation: "Emplacement (ex., Bâtiment A, Zone 4, Enclos 2)",
    setFarmImage: "Définir une nouvelle photo de la ferme",
    takePhoto: "Prendre une photo",
    choosePhoto: "Choisir une photo",
    changePhoto: "Changer la photo",
    deletePhoto: "Supprimer la photo",
    farmInfo: "Informations de l'entreprise",
    addFarmName: "Nom de l'entreprise",
    addCui: "N° SIRET",
    accountSettings: "Paramètres du compte",
    myProfile: "Mon profil",
    resetPassword: "Réinitialiser le mot de passe",
    notificationPreferences: "Préférences de notifications",
    email: "Adresse e-mail",
    pushNotifications: "Notifications push",
    editPhone: "Modifier le téléphone",
    setUserImage: "Définir une nouvelle photo de profil",
    inputCurrentPassword: "Saisir le mot de passe actuel",
    inputNewPassword: "Saisir le nouveau mot de passe",
    confirmNewPassword: "Confirmer le nouveau mot de passe",
    information: "Informations",
    tutorials: "Tutoriels",
    faq: "Questions fréquentes",
    confidentiality: "Politique de confidentialité",
    termsAndServices: "Conditions et services",
    appVersion: "Version de l'application",
    whatIsFarmApp: "Qu'est-ce que FARM APP ?",
    lastUpdate: "Dernière mise à jour",
    yourOpinion: "Votre avis",
    recommend: "Recommander",
    likeUs: "Like us on Social Media",
    contactUs: "Nous contacter",
    followUs: "Nous suivre",
    recommendUsing: "Recommander l'application en utilisant :",
    subject: "Sujet",
    message: "Message",
    signOut: "Sign out",
    signOutConfirmation: "Êtes-vous sûr de vouloir quitter l'application ?",
    deletePhotoConfirmation: "Êtes-vous sûr de vouloir supprimer la photo ?",
    linkWillBeHere: "Link to our app will be here",
    farmProfileUpdated: "Le profil de la ferme a été mis à jour !",
    userProfileUpdated: "Le profil a été mis à jour !",
    errorOccurred: "Une erreur s'est produite !",
    processing: "Traitement en cours...",
    contact: "Contact",
    lostPassword: "J'ai oublié mon mot de passe",
    password: "Mot de passe",
    lostYourPassword: "Vous avez oublié votre mot de passe ?",
    dontWorryHappensToAllOfUs:
      "Ne vous inquiétez pas, ça arrive à tout le monde !",
    enterEmailResetPassword:
      "Entrez votre adresse e-mail ci-dessous, et nous vous enverrons un lien pour réinitialiser votre mot de passe.",
    wrongLoginCredentials: "Adresse e-mail ou mot de passe incorrect !",
    login: "Connexion",
    logingIn: "Connexion en cours...",
    back: "Retour",
    welcome: "Bienvenue !",
    nameUserRequired: "Le nom est obligatoire !",
    lastNameRequired: "Le prénom est obligatoire !",
    lastNameMaxLength: "Le prénom doit comporter au maximum 100 caractères",
    emailRequired: "L'adresse e-mail est obligatoire !",
    phoneNumberRequired: "Le numéro de téléphone est obligatoire !",
    send: "Envoyer",
    urlCopiedSuccess: "L'URL a été copiée avec succès !",

    // Authentication
    createAccount: "Créer un compte",
    creatingAccount: "Création du compte...",
    signupSuccessful: "Inscription réussie !",
    verificationEmailSent: "Un e-mail de vérification a été envoyé",
    pleaseCheckYourEmail:
      "Veuillez vérifier votre e-mail pour confirmer votre compte",
    passwordResetSuccessful: "Réinitialisation du mot de passe réussie !",
    yourPasswordHasBeenReset:
      "Votre mot de passe a été réinitialisé avec succès",
    passwordChangeSuccessful: "Changement de mot de passe réussi !",
    yourPasswordHasBeenChanged: "Votre mot de passe a été changé avec succès",
    changePassword: "Changer le mot de passe",
    changing: "Changement en cours...",
    failedToChangePassword: "Échec du changement de mot de passe",
    incorrectCurrentPassword: "Le mot de passe actuel est incorrect",
    sessionExpired: "Votre session a expiré",
    pleaseRefreshAndTryAgain: "Veuillez rafraîchir la page et réessayer",
    proceedToLogin: "Procéder à la connexion",
    backToLogin: "Retour à la connexion",
    passwordsDoNotMatch: "Les mots de passe ne correspondent pas",
    passwordTooShort: "Le mot de passe doit comporter au moins 8 caractères",
    failedToSignUp: "Échec de l'inscription",
    failedToResetPassword: "Échec de la réinitialisation du mot de passe",
    dontHaveAccount: "Vous n'avez pas de compte ?",
    alreadyHaveAccount: "Vous avez déjà un compte ?",
    firstName: "Prénom",
    lastName: "Nom",
    confirmPassword: "Confirmer le mot de passe",
    newPassword: "Nouveau mot de passe",
    resetYourPassword: "Réinitialisez votre mot de passe",
    enterNewPassword: "Entrez votre nouveau mot de passe ci-dessous",
    passwordResetEmailSent: "E-mail de réinitialisation du mot de passe envoyé",
    checkYourEmailForResetLink:
      "Vérifiez votre e-mail pour le lien de réinitialisation du mot de passe",
    fillInDetailsBelow:
      "Remplissez les détails ci-dessous pour créer votre compte",
    invalidResetLink: "Lien de réinitialisation invalide",
    sending: "Envoi en cours...",
    resetting: "Réinitialisation en cours...",

    // Email verification
    verifyingEmail: "Vérification de l'e-mail",
    pleaseWait: "Veuillez patienter...",
    emailVerificationSuccessful: "Vérification de l'e-mail réussie !",
    youCanNowLogin:
      "Votre e-mail a été vérifié. Vous pouvez maintenant vous connecter à votre compte.",
    emailVerificationFailed: "Échec de la vérification de l'e-mail",
    invalidVerificationLink: "Lien de vérification invalide",
    invalidOrExpiredLink: "Le lien de vérification est invalide ou a expiré",

    stepByStepGestationEvolution: "Évolution de la gestation étape par étape",
    computeProbableBirthDate: "Calculer la date probable de mise bas",
    testNow: "Tester maintenant",
    confirm: "Confirmer",
    underDevelopment: "Cette fonctionnalité est encore en développement",
    notAvailableInPackage: "Cette fonctionnalité n'est pas disponible",
    gestationalCalculator: "Calculateur de Gestation",
    createNewCalendar: "Créer un nouveau calendrier",
    generateCalendar: "Générer un calendrier",
    saveCalendar: "Sauvegarder le calendrier",
    calendarSavedSuccessfully: "Le calendrier a été sauvegardé avec succès !",
    animalMustBeFemale:
      "Pour créer un calendrier de gestation, l'animal doit être femelle",
    animalMustBeCow:
      "Le calendrier de gestation est disponible uniquement pour les vaches",
    loadingCalendarPreview: "Chargement de l'aperçu du calendrier...",
    previewLoadedSuccessfully: "L'aperçu a été chargé avec succès !",
    errorLoadingPreview:
      "Une erreur s'est produite lors du chargement de l'aperçu !",
    errorFetchingAnimal:
      "Une erreur s'est produite lors de la récupération de l'animal !",
    searchCalendar: "Rechercher un calendrier",
    selectAnimal: "Sélectionner l'animal",
    gestationalCalendar: "Calendrier de Gestation",
    deleteCalendar: "Supprimer le calendrier",
    deletingCalendar: "Suppression du calendrier...",
    calendarDeletedSuccessfully: "Le calendrier a été supprimé avec succès !",
    calendarDeleteError:
      "Une erreur s'est produite lors de la suppression du calendrier !",
    calendarWillBeDeleted: "Le calendrier actuel sera supprimé.",
    deleteCalendarConfirmation:
      "Êtes-vous sûr de vouloir supprimer le calendrier ?",
    deleteAssocAnimalEvents:
      "Souhaitez-vous également supprimer les événements associés au calendrier ?",
    toCalving: "jusqu'à la mise bas",
    untilCalvingIntervalEnd: "jusqu'à la fin de l'intervalle",
    days: "jours",
    toNextTask: "jusqu'à la prochaine tâche",
    hours: "heures",
    minutes: "minutes",
    nextActivity: "Prochaine activité",
    gestationalCalendarText:
      "Calcule la date de mise bas et génère des tâches utiles pour la période de gestation.",
    calendarPreviewLoadedSuccessfully:
      "L'aperçu du calendrier a été chargé avec succès !",

    // Finance Widget
    financialJournal: "Journal Financier",
    searchTransaction: "Rechercher une transaction",
    addTransaction: "Ajouter une transaction",
    transaction: "Transaction",
    transactions: "Transactions",
    EXPENSES: "Dépenses",
    Outflow: "Dépenses",
    INCOMES: "Revenus",
    Inflow: "Revenus",
    selectTransactionCategory: "Sélectionner la catégorie de transaction",
    EU_FOUNDS: "Fonds Européens",
    PROFIT: "Profit",
    SELLING_ANIMALS: "Vente d'Animaux",
    DONATIONS: "Dons",
    SELLING_GOODS: "Vente de Produits",
    EQUIPMENT_ACQUISITION: "Acquisition d'Équipement",
    MAINTENANCE: "Entretien",
    ANIMALS_FOOD: "Alimentation Animale",
    TREATMENTS: "Traitements",
    TRANSPORTATION: "Transport",
    VETERINARY: "Services Vétérinaires",
    SALARIES: "Salaires",
    SOFTWARE_ACQUISITION: "Acquisition de Logiciels",
    ADD_CATEGORY: "Ajouter une catégorie",
    categoryName: "Nom de la catégorie",
    loadingCategories: "Chargement des catégories...",
    categorySaved: "La catégorie a été sauvegardée !",
    errorCreatingCategory:
      "Une erreur s'est produite lors de la création de la catégorie !",
    savingCategory: "Sauvegarde de la catégorie...",
    updatingCategory: "Mise à jour de la catégorie...",
    categoryUpdated: "La catégorie a été mise à jour !",
    errorUpdatingCategory:
      "Une erreur s'est produite lors de la mise à jour de la catégorie !",
    deleteCategory: "Supprimer la catégorie",
    deletingCategory: "Suppression de la catégorie...",
    categoryDeleted: "La catégorie a été supprimée !",
    errorDeletingCategory:
      "Une erreur s'est produite lors de la suppression de la catégorie !",
    transactionHistory: "Historique des transactions",
    savingTransaction: "Sauvegarde de la transaction...",
    transactionSaved: "La transaction a été sauvegardée !",
    transactionSaveError:
      "Une erreur s'est produite lors de la sauvegarde de la transaction !",
    deleteTransactionConfirmation:
      "Êtes-vous sûr de vouloir supprimer la transaction ?",
    deleteTransactionCategoryConfirmation:
      "Êtes-vous sûr de vouloir supprimer la catégorie ?",
    transactionCategoryWillBeDeleted:
      "La catégorie de transaction sera supprimée.",
    transactionWillBeDeleted: "La transaction actuelle sera supprimée.",
    deleteTransaction: "Supprimer la transaction",
    transactionDeleted: "La transaction a été supprimée !",
    deletingTransaction: "Suppression de la transaction...",
    startDate: "Date de début",
    viewStats: "Voir les statistiques",
    endDate: "Date de fin",
    last_week: "Semaine dernière",
    last_month: "Mois dernier",
    current_month: "Mois actuel",
    last_year: "Année dernière",
    custom: "Période personnalisée",
    selectTimePeriod: "Sélectionner la période",
    all_time: "Toute la période",
    current_week: "Semaine actuelle",
    noTransactionsFound: "Oups, aucune transaction trouvée...",
    noAnimalsFound: "Oups, aucun animal trouvé...",
    lastTransactions: "Dernières transactions",

    amountRequired: "Le montant est obligatoire pour créer une transaction",
    dateRequired: "La date est obligatoire pour créer une transaction",
    categoryRequired: "La catégorie est obligatoire pour créer une transaction",
    balance: "Solde",
    financialStats: "Statistiques Financières",
    weAreSorry: "Nous nous excusons...",
    anErrorOccurred: "Une erreur s'est produite.",
    reloadApp: "Recharger l'application",
    events: "Événements",
    addEvent: "Ajouter un événement",
    addAnimalExit: "Sortie de l'animal",
    confirmCalving: "Confirmer la mise bas",
    calvingConfirmed: "La mise bas a été confirmée",
    selectEventType: "Sélectionner le type d'événement",
    selectMedication: "Sélectionner le médicament",
    Treatment: "Traitement",
    Measurement: "Mesure",
    Movement: "Mouvement",
    Insemination: "Insémination",
    NaturalMating: "Accouplement Naturel",
    PregnancyCheckPositive: "Vérification de Gestation Positive",
    PregnancyCheckNegative: "Vérification de Gestation Négative",
    HealthCheckPositive: "Contrôle de Santé Positif",
    HealthCheckNegative: "Contrôle de Santé Négatif",
    HoofHealthCheck: "Contrôle Santé des Sabots",
    HeatDetection: "Détection des Chaleurs",
    Abortion: "Avortement",
    DryOff: "Arrêt de Lactation",
    Weaning: "Sevrage",
    LactationStart: "Début de Lactation",
    LactationEnd: "Fin de Lactation",
    DietChange: "Changement de Régime Alimentaire",
    Shearing: "Tonte",
    HoofTrimming: "Taille des Sabots",
    EarTagging: "Marquage Auriculaire",
    AnimalTransferExitEventType: "Événements de Sortie",
    AnimalSacrifice: "Sacrifice",
    AnimalSale: "Vente d'Animal",
    AnimalDeath: "Mort de l'Animal",
    AnimalLoss: "Perte de l'Animal",
    AnimalTransferExit: "Transfert de Sortie",
    Castration: "Castration",
    Dehorning: "Écornage",
    VeterinaryVisit: "Visite Vétérinaire",
    BehaviorObservation: "Observation du Comportement",
    EntryIntoQuarantine: "Entrée en Quarantaine",
    ExitFromQuarantine: "Sortie de Quarantaine",
    Isolation: "Isolement",
    Vaccination: "Vaccination",
    IllnessTreatment: "Traitement de Maladie",
    Deworming: "Vermifugation",
    InjuryTreatment: "Traitement de Blessure",
    Surgery: "Chirurgie",
    PostSurgeryFollowUp: "Suivi Post-Opératoire",
    SupplementAdministration: "Administration de Supplément",
    WeightCheck: "Vérification du Poids",
    BodyConditionScoring: "Évaluation de la Condition Corporelle",
    Other: "Autres",
    addEventType: "Ajouter un nouveau type d'événement",
    addMedicationType: "Ajouter un nouveau traitement",
    eventName: "Nom de l'événement",
    medicationName: "Nom du traitement",
    deleteEventType: "Supprimer le type d'événement",
    fromLocation: "De l'emplacement",
    fromPosition: "De la position",
    toPosition: "À la position",
    toLocation: "Vers l'emplacement",
    departureDate: "Date de départ",
    arrivalDate: "Date d'arrivée",
    HeightCheck: "Vérification de la hauteur",
    weight: "Poids",
    otherObservations: "Autres observations",
    generalEventDetails: "Détails généraux de l'événement",
    specificEventDetails: "Détails spécifiques de l'événement",
    transactionAmount: "Montant de la transaction",
    value: "Valeur",
    savingEvent: "Sauvegarde de l'événement...",
    eventSaved: "L'événement a été sauvegardé !",
    viewEvent: "Voir l'événement",
    errorSavingEvent:
      "Une erreur s'est produite lors de la sauvegarde de l'événement !",
    dosage: "Dosage",
    deleteAnimalEvent: "Supprimer l'événement",
    animalEvent: "Événement de l'animal",
    deletingAnimalEvent: "Suppression de l'événement...",
    animalEventDeleted: "L'événement a été supprimé !",
    animalEventDeleteError:
      "Une erreur s'est produite lors de la suppression de l'événement !",
    deleteAnimalEventConfirmation:
      "Êtes-vous sûr de vouloir supprimer l'événement ?",
    eventWillBeDeleted: "L'événement actuel sera supprimé.",
    updatingAnimalEvent: "Mise à jour de l'événement...",
    animalEventUpdated: "L'événement a été mis à jour !",
    animalEventUpdateError:
      "Une erreur s'est produite lors de la mise à jour de l'événement !",
    typeRequired: "Le type est obligatoire pour créer un événement",
    dateRequiredEvent: "La date est obligatoire pour créer un événement",
    animalRequired: "L'animal est obligatoire pour créer un événement",
    fromLocationRequired:
      "L'emplacement de départ est obligatoire pour créer cet événement",
    toLocationRequired:
      "L'emplacement d'arrivée est obligatoire pour créer cet événement",
    valueRequired: "La valeur est obligatoire pour créer cet événement",
    dosageRequired: "Le dosage est obligatoire pour créer cet événement",
    medicationRequired:
      "Le médicament est obligatoire pour créer cet événement",
    quantityRequired: "La quantité est obligatoire pour créer cet événement",
    positionRequired: "La position est obligatoire pour créer cet événement",
    toApiaryRequired:
      "Le rucher de destination est obligatoire pour créer cet événement",
    TreatmentEventType: "Événements de Traitement",
    MeasurementEventType: "Événements de Mesure",
    MovementEventType: "Événements de Mouvement",
    ReproductiveEventType: "Événements de Reproduction",
    OtherEventType: "Autres Événements",
    searchAnimalEvent: "Rechercher un événement d'animal",

    animalEvents: "Événements d'Animaux",
    savingEventType: "Sauvegarde du type d'événement...",
    savingMedicationType: "Sauvegarde du type de traitement...",
    eventTypeSaved: "Le type d'événement a été sauvegardé !",
    medicationTypeSaved: "Le type de traitement a été sauvegardé !",
    errorCreatingEventType:
      "Une erreur s'est produite lors de la création du type d'événement",
    errorCreatingMedicationType:
      "Une erreur s'est produite lors de la création du type de traitement",
    updatingEventType: "Mise à jour du type d'événement",
    updatingMedicationType: "Mise à jour du type de traitement",
    eventTypeUpdated: "Mise à jour réussie !",
    medicationTypeUpdated: "Mise à jour réussie !",
    errorUpdatingEventType:
      "Une erreur s'est produite lors de la mise à jour du type d'événement !",
    errorUpdatingMedicationType:
      "Une erreur s'est produite lors de la mise à jour du type de traitement !",
    deleteEventTypeConfirmation:
      "Êtes-vous sûr de vouloir supprimer le type d'événement ?",
    deleteMedicationTypeConfirmation:
      "Êtes-vous sûr de vouloir supprimer le type de traitement ?",
    eventTypeWillBeDeleted: "Le type d'événement sera supprimé.",
    medicationTypeWillBeDeleted: "Le type de traitement sera supprimé.",
    deletingEventType: "Suppression du type d'événement...",
    deletingMedicationType: "Suppression du type de traitement...",
    eventTypeDeleted: "Le type d'événement a été supprimé !",
    medicationTypeDeleted: "Le type de traitement a été supprimé !",
    errorDeletingEventType:
      "Une erreur s'est produite lors de la suppression du type d'événement",
    errorDeletingMedicationType:
      "Une erreur s'est produite lors de la suppression du type de traitement",
    chooseEventTypeParent: "Sélectionner la catégorie d'événement",
    intelligentAssistant: "Assistant Intelligent",
    yourMessage: "Votre message",
    generalHealthStatus: "Quel est l'état général de santé du troupeau bovin ?",
    immediateMedicalAttention:
      "Quelles vaches nécessitent une attention médicale immédiate ?",
    optimizeFeeding:
      "Comment puis-je optimiser l'alimentation pour augmenter la production de lait ?",
    closeToCalving: "Quelles vaches sont proches de la mise bas ?",
    generalHealthStatusPigs:
      "Quel est l'état général de santé du troupeau porcin ?",
    growthRate:
      "Quel est le taux de croissance des porcs au cours du dernier mois ?",
    pregnantSows:
      "Quelles truies gestantes nécessitent une attention particulière ?",
    weaningStatus: "Quels porcelets doivent être sevrés ?",
    diseasePreventionStatus:
      "Quel est l'état des mesures de prévention des maladies dans le troupeau ?",
    generalHealthStatusGoat:
      "Quel est l'état général de santé du troupeau caprin ?",
    milkProduction: "Comment se présente la production de lait récemment ?",
    pregnantGoats: "Quelles chèvres gestantes nécessitent une surveillance ?",
    kidsHealth: "Quel est l'état de santé des chevreaux ?",
    generalHealthStatusSheep:
      "Quel est l'état général de santé du troupeau ovin ?",
    lambingStatus: "Quelles brebis vont mettre bas dans la période à venir ?",
    lambGrowth: "Quel est le taux de croissance des agneaux ?",
    generalHealthStatusBees:
      "Quel est l'état général de santé des familles d'abeilles ?",
    honeyProduction:
      "Comment se présente la production de miel par rapport à la période précédente ?",
    queenStatus: "Quel est l'état des reines dans les colonies ?",
    welcomeToAssistant: "Bienvenue sur l'Assistant Intelligent !",
    startConversation:
      "Commencez la conversation avec quelques-unes de nos suggestions.",
    loading: "Chargement...",
    UNDER_TREATMENT: "Traitement",
    noResultsForAnimal: "Aucun résultat trouvé pour l'animal spécifié.",
    askAssistant: "Demander à l'Assistant",
    virtualAssistant: "Assistant Virtuel",
    virtualFarmAssistant: "Assistant Virtuel de la Ferme",
    farmAdviceWithAI:
      "Conseils et informations personnalisés pour votre ferme avec la nouvelle IA intégrée.",
    noAnimalsFoundForFilter: "Aucun animal trouvé pour le filtre sélectionné",
    gestationCalendars: "Calendriers de gestation",
    activityCalendar: "Calendrier d'activités",
    inseminationDate: "Date d'insémination",
    possibleCalvingInterval: "Intervalle probable de mise bas",
    calvingDate: "Date de mise bas",
    openCalendar: "Ouvrir le calendrier",
    seeEvents: "Afficher les événements",
    hideEvents: "Masquer les événements",
    thisFeatureIsNotEnabled: "Ce module n'est pas actif",
    buyFeature: "Acheter le module",
    noGestationCalendarsPresent: "Vous n'avez aucun calendrier de gestation",
    filterByAge: "Filtrer par âge",
    currentInterval: "Intervalle actuel",
    resetFilter: "Réinitialiser le filtre",
    quickSelection: "Sélection rapide",
    myApiaries: "Mes ruchers",
    myApiary: "Mon rucher",
    myHives: "Mes ruches",
    myQueens: "Mes reines",
    addApiary: "Ajouter un rucher",
    addHive: "Ajouter une ruche",
    isLoading: "Chargement...",
    hiveDetails: "Détails de la ruche",
    apiaryDetails: "Détails du rucher",
    hiveImage: "Image de la ruche",
    hiveCode: "Code/Nom unique",
    hiveType: "Type de ruche",
    queenBirthYear: "Année de naissance de la reine",
    queenOrigin: "Origine de la reine",
    queen: "Reine",
    framesNumber: "Nombre de cadres",
    familyPower: "Force de la famille",
    beeCount: "Nombre d'abeilles",
    broodFramesNumber: "Nombre de cadres avec couvain",
    honeyFramesNumber: "Nombre de cadres avec miel",
    emptyFramesNumber: "Nombre de cadres vides",
    apiaryName: "Nom du rucher",
    apiaryAddress: "Adresse",
    dsvsa: "N° d'enregistrement/autorisation sanitaire",
    nameRequired: "Le nom est obligatoire !",
    nameMinLength: "La longueur minimale du nom est de 2 caractères !",
    addressRequired: "L'adresse est obligatoire !",
    addressMinLength: "La longueur minimale de l'adresse est de 5 caractères !",
    addressMaxLength:
      "La longueur maximale de l'adresse est de 200 caractères !",
    registrationCodeRequired: "Le code d'enregistrement est obligatoire !",
    registrationCodeFormat:
      "Le code d'enregistrement peut contenir uniquement des lettres, des chiffres et des tirets !",
    descriptionMaxLength:
      "La longueur maximale de la description est de 1000 caractères !",
    creatingApiary: "Création du rucher...",
    apiaryCreated: "Le rucher a été créé !",
    apiaryCreateError:
      "Une erreur s'est produite lors de la création du rucher",
    updatingApiary: "Mise à jour du rucher...",
    deletingApiary: "Suppression du rucher...",
    apiaryDeleted: "Le rucher a été supprimé",
    apiaryUpdated: "Le rucher a été mis à jour !",
    apiaryUpdateError:
      "Une erreur s'est produite lors de la mise à jour du rucher",
    apiaryDeleteError:
      "Une erreur s'est produite lors de la suppression du rucher",
    registrationCode: "Code d'enregistrement",
    hivesNumber: "Nombre de ruches",
    deleteApiary: "Supprimer le rucher",
    apiaries: "Ruchers",
    hives: "Ruches",
    deleteApiaryConfirmation: "Êtes-vous sûr de vouloir supprimer le rucher ?",
    apiaryWillBeDeleted:
      "Le rucher, ainsi que toutes les données associées, comme les ruches, les reines et leurs événements seront supprimés",
    hiveManufacturer: "Fabricant de la ruche",
    creatingHive: "Création de la ruche...",
    hiveCreatedSuccessfully: "La ruche a été créée avec succès !",
    errorCreatingHive:
      "Une erreur s'est produite lors de la création de la ruche !",
    apiary: "Rucher",
    apiaryRequired: "Le rucher est obligatoire",
    apiaryFormat:
      "Le code du rucher peut contenir uniquement des lettres, des chiffres et des tirets",
    codeRequired: "Le code de la ruche est obligatoire",
    codeFormat:
      "Le code de la ruche peut contenir uniquement des lettres, des chiffres et des tirets",
    codeMinLength: "Le code de la ruche doit avoir au minimum 2 caractères",
    codeMaxLength: "Le code de la ruche ne peut pas dépasser 50 caractères",
    hiveTypeRequired: "Le type de ruche est obligatoire",
    invalidHiveType: "Le type de ruche sélectionné n'est pas valide",
    manufacturerRequired: "Le fabricant de la ruche est obligatoire",
    manufacturerMinLength:
      "Le nom du fabricant doit avoir au minimum 2 caractères",
    manufacturerMaxLength:
      "Le nom du fabricant ne peut pas dépasser 100 caractères",
    broodFramesRequired: "Le nombre de cadres avec couvain est obligatoire",
    broodFramesFormat:
      "Le nombre de cadres avec couvain doit être un nombre entier",
    broodFramesRange:
      "Le nombre de cadres avec couvain doit être entre 0 et 100",
    honeyFramesRequired: "Le nombre de cadres avec miel est obligatoire",
    honeyFramesFormat:
      "Le nombre de cadres avec miel doit être un nombre entier",
    honeyFramesRange: "Le nombre de cadres avec miel doit être entre 0 et 100",
    emptyFramesRequired: "Le nombre de cadres vides est obligatoire",
    emptyFramesFormat: "Le nombre de cadres vides doit être un nombre entier",
    emptyFramesRange: "Le nombre de cadres vides doit être entre 0 et 100",
    beeCountRequired: "Le nombre d'abeilles est obligatoire",
    beeCountFormat: "Le nombre d'abeilles doit être un nombre entier",
    beeCountRange: "Le nombre d'abeilles doit être entre 0 et 100000",
    addQueen: "Ajouter une reine",
    deleteHive: "Supprimer la ruche",
    hiveUpdating: "Mise à jour de la ruche...",
    hiveUpdatedSuccessfully: "La ruche a été mise à jour !",
    errorUpdatingHive:
      "Une erreur s'est produite lors de la mise à jour de la ruche",
    deleteHiveConfirmation: "Êtes-vous sûr de vouloir supprimer la ruche ?",
    hiveWillBeDeleted:
      "La ruche, ainsi que toutes les données associées, comme la reine et ses événements seront supprimés",
    deletingHive: "Suppression de la ruche...",
    deletingQueen: "Suppression de la reine...",
    queenDeleted: "La reine a été supprimée !",
    hiveDeleted: "La ruche a été supprimée !",
    queenDeleteError:
      "Une erreur s'est produite lors de la suppression de la reine",
    hiveDeleteError:
      "Une erreur s'est produite lors de la suppression de la ruche",
    yearOfBirth: "Année de naissance",
    queenColor: "Couleur de la reine",
    "Own Production": "Production propre",
    Purchased: "Achat",
    Blue: "Bleu",
    Green: "Vert",
    Red: "Rouge",
    Yellow: "Jaune",
    Black: "Noir",
    White: "Blanc",
    nameFormat:
      "Le nom de la reine peut contenir uniquement des lettres, des chiffres, des espaces et des tirets",
    yearRequired: "L'année est obligatoire",
    yearFormat: "L'année doit être au format AAAA (ex : 2024)",
    yearRange:
      "L'année doit être entre les 5 dernières années et l'année actuelle",
    colorRequired: "La couleur de la reine est obligatoire",
    invalidColor: "La couleur sélectionnée n'est pas valide",
    originRequired: "L'origine de la reine est obligatoire",
    invalidOrigin: "L'origine sélectionnée n'est pas valide",
    creatingQueen: "Création de la reine...",
    queenCreated: "La reine a été créée !",
    queenCreateError:
      "Une erreur s'est produite lors de la création de la reine",
    hive: "Ruche",
    deleteQueen: "Supprimer la reine",
    queenUpdating: "Mise à jour de la reine",
    queenUpdatedSuccessfully: "La reine a été mise à jour !",
    errorUpdatingQueen:
      "Une erreur s'est produite lors de la mise à jour de la reine",
    deleteQueenConfirmation: "Êtes-vous sûr de vouloir supprimer la reine ?",
    queenWillBeDeleted:
      "La reine, ainsi que toutes les données associées, notes, tâches et événements seront supprimés",
    bees: "Abeilles",
    allApiaries: "Tous les ruchers",
    searchHive: "Rechercher une ruche",
    allHiveTypes: "Tous les types de ruche",
    apiaryWasSuccessfullyAdded: "Le rucher a été ajouté avec succès !",
    hiveStep:
      "L'étape suivante est l'ajout de ruches dans votre nouveau rucher, souhaitez-vous continuer ?",
    hiveWasSuccessfullyAdded: "La ruche a été ajoutée avec succès !",
    queenStep:
      "L'étape suivante est l'ajout de la reine dans votre nouvelle ruche, souhaitez-vous continuer ?",
    noHivesFoundForGivenQuery: "Aucune ruche trouvée pour votre recherche",
    noQueensFoundForGivenQuery: "Aucune reine trouvée pour votre recherche",
    allQueens: "Toutes les reines",
    assocApiary: "Associer un rucher",
    assocHive: "Associer une ruche",
    noApiariesAssociated: "Aucun rucher associé",
    noHivesAssociated: "Aucune ruche associée",
    oneApiaryAssociated: "Un rucher associé",
    oneHiveAssociated: "Une ruche associée",
    apiariesAssociated: "ruchers associés",
    hivesAssociated: "ruches associées",
    generalAnimalWelfare: "Quel est l'état général de bien-être des animaux ?",
    diseasePreventionPlan:
      "Quel est le plan de prévention des maladies pour tous les animaux ?",
    feedingStrategyGeneral:
      "La stratégie d'alimentation est-elle optimale pour tous les animaux ?",
    reproductiveHealthGeneral:
      "Quelle est la situation de la santé reproductive dans le troupeau ?",
    udderHealth: "Quel est l'état de santé des pis chez les vaches ?",
    heatDetection: "Quelles vaches présentent des signes de chaleur ?",
    pigletMortality: "Quel est le taux de mortalité chez les porcelets ?",
    boarPerformance: "Quelle est la performance des verrats reproducteurs ?",
    hoofHealth: "Quel est l'état des sabots chez les chèvres ?",
    pasturePlanning: "Le plan de pâturage est-il efficace ?",
    flockBehavior:
      "Y a-t-il des changements dans le comportement du troupeau ?",
    sheepSorting: "Quelles brebis nécessitent un tri pour différents groupes ?",
    hiveStrength: "Quelle est la force des familles d'abeilles ?",
    varoaStatus: "Quel est le niveau d'infestation par varroa ?",
    nectarSources: "Y a-t-il suffisamment de sources de nectar dans la zone ?",
    biosecurityMeasures:
      "Quelles sont les mesures de biosécurité mises en place ?",
    selectApiaries: "Sélectionner les ruchers",
    selectApiary: "Sélectionner le rucher",
    selectHive: "Sélectionner la ruche",
    selectHives: "Sélectionner les ruches",
    noResultsFound: "Aucun résultat trouvé",
    hideOtherApiariesAssociated: "Masquer les autres ruchers associés",
    hideOtherHivesAssociated: "Masquer les autres ruches associées",
    seeOtherApiariesAssociated: "Voir les autres ruchers associés",
    seeOtherHivesAssociated: "Voir les autres ruches associées",
    noApiaryFound: "Aucun rucher trouvé",
    noQueensAssociated: "Aucune reine associée",
    oneQueenAssociated: "Une reine associée",
    queensAssociated: "reines associées",
    selectQueens: "Sélectionner les reines",
    assocQueen: "Associer une reine",
    theHive: "La ruche",
    addApiaryEvent: "Ajouter un événement au rucher",
    noApiarySelected: "Aucun rucher sélectionné",
    noHiveSelected: "Aucune ruche sélectionnée",
    PestControl: "Contrôle des nuisibles",
    VegetationManagement: "Gestion de la végétation",
    EquipmentMaintenance: "Entretien des équipements",
    InfrastructureRepair: "Réparation d'infrastructure",
    ApiaryMaintenance: "Entretien du rucher",
    SpringAssessment: "Évaluation de printemps",
    WinterPreparationCheck: "Vérification de préparation pour l'hiver",
    AuthorityInspection: "Inspection des autorités",
    OfficialVeterinaryInspection: "Inspection vétérinaire officielle",
    ApiaryInspection: "Inspection du rucher",
    PastoralMovement: "Déplacement pastoral",
    PermanentLocationChange: "Changement permanent de localisation",
    ApiaryMovement: "Déplacement du rucher",
    personName: "Nom de la personne",
    eventTypeRequired: "Le type d'événement est obligatoire",
    eventTypeFormat:
      "Le type d'événement doit contenir uniquement des lettres, des chiffres et des tirets",
    personNameMaxLength:
      "Le nom de la personne ne peut pas dépasser 255 caractères",
    dateFormat: "Format de date invalide",
    notesMaxLength: "Les notes ne peuvent pas dépasser 1000 caractères",
    creatingApiaryEvent: "Création de l'événement du rucher...",
    apiaryEventCreatedSuccessfully:
      "L'événement du rucher a été créé avec succès !",
    errorCreatingApiaryEvent:
      "Une erreur s'est produite lors de la création de l'événement du rucher",
    apiariesEvents: "Événements des ruchers",
    apiaryEvent: "Événement du rucher",
    deletingApiaryEvent: "Suppression de l'événement du rucher...",
    apiaryEventDeleted: "L'événement du rucher a été supprimé !",
    apiaryEventDeleteError:
      "Une erreur s'est produite lors de la suppression de l'événement du rucher",
    ApiaryMovementEventType: "Événements de déplacement du rucher",
    ApiaryInspectionEventType: "Événements d'inspection du rucher",
    ApiaryMaintenanceEventType: "Événements d'entretien du rucher",
    Acacia: "Acacia",
    Linden: "Tilleul",
    Sunflower: "Tournesol",
    Rapeseed: "Colza",
    "Poly Floral": "Polyfloral",
    selectFloraType: "Sélectionner le type de flore",
    apiaryEvents: "Événements du rucher",
    apiaryEventsDescription: "Surveiller l'activité de vos ruchers",
    hiveEventsDescription: "Surveiller l'activité de vos ruches",
    noEventsFound: "Aucun événement trouvé",
    departureDateRequired: "La date de départ est obligatoire",
    floraTypeRequired: "Le type de flore est obligatoire",
    partners: "Partenaires",
    viewOffer: "Voir l'offre",
    allTimes: "Toutes les périodes",
    selectTimeFrame: "Sélectionner la période",
    dateTo: "Date de fin",
    dateFrom: "Date de début",
    present: "Présent",
    selectDateTo: "Sélectionner la date de fin",
    selectDateFrom: "Sélectionner la date de début",
    showResults: "Afficher les résultats",
    hiveEvents: "Événements des ruches",
    queenEvents: "Événements des reines",
    queenEventsDescription: "Surveiller l'activité de vos reines",
    allHives: "Toutes les ruches",
    creatingHiveEvent: "Création de l'événement de la ruche...",
    hiveEventCreatedSuccessfully:
      "L'événement de la ruche a été créé avec succès !",
    errorCreatingHiveEvent:
      "Une erreur s'est produite lors de la création de l'événement de la ruche",
    "Hive must have a queen for this operation":
      "Pour cette opération, la ruche doit avoir une reine",
    ColonyDivision: "Division de colonie",
    SwarmPrevention: "Prévention d'essaimage",
    RemovingSuper: "Retrait de hausse",
    AddingSuper: "Ajout de hausse",
    AddingFrames: "Ajout de cadres",
    QueenReplacement: "Remplacement de reine",
    Feeding: "Nourrissement",
    HiveManagement: "Gestion de la ruche",
    WeightMeasurement: "Mesure du poids",
    BroodFramesCount: "Mise à jour du nombre de cadres de couvain",
    HoneyFramesCount: "Mise à jour du nombre de cadres de miel",
    ColonyStrengthAssessment: "Évaluation de la force de la colonie",
    HiveMeasurement: "Mesure de la ruche",
    PreventiveTreatment: "Traitement préventif",
    DiseaseTreatment: "Traitement des maladies",
    NosemaTreatment: "Traitement contre Nosema",
    VarroaTreatment: "Traitement contre Varroa",
    HiveTreatment: "Traitement de la ruche",
    WinterCheck: "Vérification hivernale",
    BroodInspection: "Inspection du couvain",
    QueenInspection: "Inspection de la reine",
    DiseaseCheck: "Vérification des maladies",
    RoutineCheck: "Vérification de routine",
    HiveInspection: "Inspection de la ruche",
    TransferToOtherApiary: "Transfert vers un autre rucher",
    TransferWithinApiary: "Transfert au sein du rucher",
    HiveMovement: "Déplacement de la ruche",
    hiveEvent: "Événement de la ruche",
    HiveEquipment: "Équipement de la ruche",
    AddFrames: "Ajouter des cadres",
    RemoveFrames: "Retirer des cadres",
    AddSuper: "Ajouter une hausse",
    RemoveSuper: "Retirer une hausse",
    AddBroodBox: "Ajouter un corps de ruche",
    RemoveBroodBox: "Retirer un corps de ruche",
    deletingHiveEvent: "Suppression de l'événement de la ruche...",
    hiveEventDeleted: "L'événement de la ruche a été supprimé !",
    hiveEventDeleteError:
      "Une erreur s'est produite lors de la suppression de l'événement de la ruche",
    deleteHiveEvent: "Supprimer l'événement",
    deleteHiveEventConfirmation:
      "Êtes-vous sûr de vouloir supprimer l'événement ?",
    hiveEventWillBeDeleted: "L'événement actuel sera supprimé.",
    updatingHiveEvent: "Mise à jour de l'événement de la ruche...",
    hiveEventUpdated: "L'événement de la ruche a été mis à jour !",
    hiveEventUpdateError:
      "Une erreur s'est produite lors de la mise à jour de l'événement de la ruche",
    HiveMovementEventType: "Événements de déplacement de la ruche",
    HiveInspectionEventType: "Événements d'inspection de la ruche",
    HiveTreatmentEventType: "Événements de traitement de la ruche",
    HiveMeasurementEventType: "Événements de mesure de la ruche",
    HiveEquipmentEventType: "Événements d'équipement de la ruche",
    hiveRequired: "La ruche est obligatoire !",
    beeKeepingEvents: "Événements d'apiculture",
    pieces: "pièces",
    grams: "grammes",
    inHivePosition: "Position dans la ruche",
    addNewLocation: "Ajouter un nouvel emplacement",
    previousLocations: "Emplacements précédents",
    noPreviousLocations: "Aucun emplacement précédent",
    addLocation: "Ajouter un emplacement",
    currentLocation: "Emplacement actuel",
    noCurrentLocation: "Aucun emplacement actuel",
    currentPosition: "Position actuelle",
    currentWeight: "Poids actuel",
    broodBoxCount: "Nombre de corps de ruche",
    superCount: "Nombre de hausses",
    positionMaxLength:
      "La longueur maximale de la position est de 255 caractères",
    fromAndToPositionMustBeDifferent:
      "Les positions de départ et d'arrivée doivent être différentes",
    "More empty frames are needed":
      "Pour réaliser cette opération, vous avez besoin de plus de cadres vides.",
    "No supers available to remove": "Aucune hausse disponible à retirer",
    "You can't delete more suppers than available":
      "Vous ne pouvez pas supprimer plus de hausses que celles disponibles",
    "No brood boxes available to remove":
      "Aucun corps de ruche disponible à retirer",
    "You can't remove more brood boxes than available":
      "Vous ne pouvez pas retirer plus de corps de ruche que ceux disponibles",
    HiveQueenEventsEventType: "Événements liés aux reines",
    HiveQueenEvents: "Événement de reine",
    QueenMarking: "Marquage de reine",
    QueenClipping: "Clippage des ailes de la reine",
    QueenCaging: "Isolation de la reine (en cage)",
    eventInfoDescription: "Informations sur le type d'événement",
    "Events related to relocating the entire apiary, including permanent location changes and seasonal movements for better foraging opportunities.":
      "Événements liés à la relocalisation de l'ensemble du rucher, y compris les changements permanents de lieu et les mouvements saisonniers pour de meilleures opportunités de butinage.",
    "Official and routine inspections of the apiary as a whole, including regulatory checks and seasonal assessments.":
      "Inspections officielles et de routine de l'ensemble du rucher, y compris les contrôles réglementaires et les évaluations saisonnières.",
    "Maintenance activities for the apiary infrastructure, equipment, and surrounding environment.":
      "Activités d'entretien de l'infrastructure du rucher, des équipements et de l'environnement environnant.",
    "Events related to moving individual hives within or between apiaries.":
      "Événements liés au déplacement de ruches individuelles au sein d'un même rucher ou entre différents ruchers.",
    "Regular and specific inspections of individual hives to assess health, strength, and conditions.":
      "Inspections régulières et spécifiques des ruches individuelles pour évaluer la santé, la force et les conditions générales.",
    "Medical and preventive treatments applied to hives for various health conditions.":
      "Traitements médicaux et préventifs appliqués aux ruches pour diverses conditions de santé.",
    "Measurements and assessments of various hive parameters and colony strength indicators.":
      "Mesures et évaluations de différents paramètres de la ruche et indicateurs de force de la colonie.",
    "Addition or removal of hive equipment and components.":
      "Ajout ou retrait d'équipements et de composants de la ruche.",
    "Events related to queen bee management and maintenance.":
      "Événements liés à la gestion et à l'entretien des reines.",
    "Permanent relocation of the entire apiary to a new location, requiring updates to official registration.":
      "Relocalisation permanente de l'ensemble du rucher vers un nouvel emplacement, nécessitant des mises à jour de l'enregistrement officiel.",
    "Temporary relocation of hives to take advantage of specific nectar flows or pollination opportunities.":
      "Relocalisation temporaire des ruches pour profiter de flux spécifiques de nectar ou d'opportunités de pollinisation.",
    "Mandatory veterinary inspection of the apiary for health certification and disease monitoring.":
      "Inspection vétérinaire obligatoire du rucher pour la certification sanitaire et le suivi des maladies.",
    "Official inspection by beekeeping authorities for compliance with regulations and standards.":
      "Inspection officielle par les autorités apicoles pour vérifier la conformité aux réglementations et aux normes.",
    "Assessment of apiary conditions and preparations before winter season.":
      "Évaluation des conditions du rucher et préparations avant la saison hivernale.",
    "Evaluation of apiary condition and colonies after winter, planning for the new season.":
      "Évaluation de l'état du rucher et des colonies après l'hiver, planification pour la nouvelle saison.",
    "Repairs and maintenance of apiary infrastructure like stands, fencing, or windbreaks.":
      "Réparations et entretien de l'infrastructure du rucher comme les supports, les clôtures ou les brise-vent.",
    "Maintenance of shared apiary equipment such as extractors, tools, or storage facilities.":
      "Entretien des équipements communs du rucher tels que les extracteurs, les outils ou les installations de stockage.",
    "Management of vegetation around hives, including mowing, planting, or removing problematic plants.":
      "Gestion de la végétation autour des ruches, y compris la tonte, la plantation ou l'élimination des plantes problématiques.",
    "Control of apiary-level pests such as mice, ants, or wasps.":
      "Contrôle des nuisibles au niveau du rucher comme les souris, les fourmis ou les guêpes.",
    "Moving a hive to a different location within the same apiary.":
      "Déplacement d'une ruche vers un autre emplacement au sein du même rucher.",
    "Transferring a hive to a different apiary location.":
      "Transfert d'une ruche vers un autre rucher.",
    "Regular inspection of hive conditions, including population, stores, and overall health.":
      "Inspection régulière des conditions de la ruche, y compris la population, les réserves et la santé générale.",
    "Specific inspection focusing on signs of diseases or parasites.":
      "Inspection spécifique concentrée sur les signes de maladies ou de parasites.",
    "Inspection to verify queen presence, performance, and quality.":
      "Inspection pour vérifier la présence, les performances et la qualité de la reine.",
    "Detailed inspection of brood patterns, health, and development.":
      "Inspection détaillée des modèles de couvain, de la santé et du développement.",
    "Quick inspection during winter to assess colony survival and stores.":
      "Inspection rapide pendant l'hiver pour évaluer la survie de la colonie et ses réserves.",
    "Application of treatments specifically targeting Varroa mite infestations.":
      "Application de traitements ciblant spécifiquement les infestations d'acariens Varroa.",
    "Treatment for Nosema disease in affected colonies.":
      "Traitement pour la maladie de Nosema dans les colonies affectées.",
    "Treatment for various bee diseases other than Varroa or Nosema.":
      "Traitement pour diverses maladies des abeilles autres que Varroa ou Nosema.",
    "Preventive treatments applied to maintain colony health.":
      "Traitements préventifs appliqués pour maintenir la santé de la colonie.",
    "Assessment of overall colony strength, including bee population and vitality.":
      "Évaluation de la force globale de la colonie, y compris la population d'abeilles et la vitalité.",
    "Counting and assessment of frames containing honey stores.":
      "Comptage et évaluation des cadres contenant des réserves de miel.",
    "Measurement of frames containing brood in different stages.":
      "Mesure des cadres contenant du couvain à différents stades.",
    "Recording the weight of the hive to track honey stores and colony development.":
      "Enregistrement du poids de la ruche pour suivre les réserves de miel et le développement de la colonie.",
    "Adding a honey super to provide more space for honey storage.":
      "Ajout d'une hausse pour fournir plus d'espace pour le stockage du miel.",
    "Removing a honey super, typically for honey harvesting.":
      "Retrait d'une hausse, généralement pour la récolte du miel.",
    "Adding new frames to the hive for expansion or replacement.":
      "Ajout de nouveaux cadres à la ruche pour l'expansion ou le remplacement.",
    "Removing frames for maintenance, replacement, or honey extraction.":
      "Retrait de cadres pour l'entretien, le remplacement ou l'extraction du miel.",
    "Adding a new brood box to accommodate colony growth.":
      "Ajout d'un nouveau corps de ruche pour accueillir la croissance de la colonie.",
    "Removing a brood box for colony size management or maintenance.":
      "Retrait d'un corps de ruche pour la gestion de la taille de la colonie ou l'entretien.",
    "Marking the queen with a color code for easy identification and age tracking.":
      "Marquage de la reine avec un code couleur pour faciliter l'identification et le suivi de l'âge.",
    "Clipping the queen's wings to prevent swarming and aid in swarm management.":
      "Clippage des ailes de la reine pour empêcher l'essaimage et aider à la gestion des essaims.",
    "Temporarily confining the queen in a cage for various management purposes.":
      "Confinement temporaire de la reine dans une cage à diverses fins de gestion.",
    beekeeping: "Apiculture",
    currentChats: "Conversations actuelles",
    whatCanIHelpWith: "Comment puis-je vous aider ?",
    recentChats: "Conversations récentes",
    suggestedQuestions: "Questions suggérées",
    startChat: "Commencer la conversation",
    viewAllChats: "Voir toutes les conversations",
    noHivesAvailable: "Aucune ruche disponible",
    noHive: "Sans ruche",
    noQueen: "Sans reine",
    queenHasNoHiveAssigned: "La reine n'a aucune ruche associée",
    queenHasNoHiveAssignedAndNoAvailableHives:
      "La reine n'a aucune ruche associée et il n'y a pas de ruches disponibles",
    removeQueenFromHive: "Retirer la reine de la ruche",
    removeQueenFromHiveConfirmation:
      "Êtes-vous sûr de vouloir retirer la reine de la ruche ?",
    removingQueen: "Retrait de la reine...",
    queenRemovedFromHive: "La reine a été retirée de la ruche !",
    errorRemovingQueenFromHive:
      "Une erreur s'est produite lors du retrait de la reine de la ruche",
    hiveHasNoQueenAndQueensAvailable:
      "La ruche n'a pas de reine associée et il y a des reines disponibles",
    hiveHasNoQueenAndNoQueensAvailable:
      "La ruche n'a pas de reine associée et il n'y a pas de reines disponibles",
    queenHasNoHiveAndHivesAvailable:
      "La reine n'a aucune ruche associée et il y a des ruches disponibles",
    removingThread: "Suppression de la conversation...",
    threadRemoved: "La conversation a été supprimée !",
    errorRemovingThread:
      "Une erreur s'est produite lors de la suppression de la conversation",
    removeThread: "Supprimer la conversation",
    deleteThreadConfirmation:
      "Êtes-vous sûr de vouloir supprimer la conversation ?",
    allThreads: "Toutes les conversations",
    messageRateLimitExceeded: "La limite de messages a été dépassée.",
    limitWillBeRenewedIn: "La limite sera renouvelée dans",
    messageSendError: "Une erreur s'est produite lors de l'envoi du message",
    youStillHave: "Vous avez encore",
    messagesLeftThisMonth: "messages disponibles ce mois-ci",
    messageLeftThisMonth: "message disponible ce mois-ci",
    "Medical and preventive treatments applied to animals for various health conditions and maintenance.":
      "Traitements médicaux et préventifs appliqués aux animaux pour diverses conditions de santé et maintenance.",
    "Recording and tracking of various animal metrics and physical parameters.":
      "Enregistrement et suivi de diverses métriques et paramètres physiques des animaux.",
    "Events related to animal movement between locations, including quarantine and isolation.":
      "Événements liés au déplacement des animaux entre différents lieux, y compris la quarantaine et l'isolement.",
    "Events related to breeding, pregnancy, birth, and reproductive health management.":
      "Événements liés à la reproduction, la gestation, la mise bas et la gestion de la santé reproductive.",
    "Events marking the permanent departure of animals from the facility.":
      "Événements marquant le départ permanent des animaux de l'établissement.",
    "Miscellaneous animal management events including routine care and observations.":
      "Événements divers de gestion des animaux, y compris les soins de routine et les observations.",
    "Planned end of life for research or other approved purposes.":
      "Fin de vie planifiée à des fins de recherche ou autres fins approuvées.",
    "Transfer of animal ownership through commercial transaction.":
      "Transfert de propriété de l'animal par transaction commerciale.",
    "Recording of natural or unexpected death of an animal.":
      "Enregistrement de la mort naturelle ou inattendue d'un animal.",
    "Documentation of missing or unaccounted for animals.":
      "Documentation des animaux disparus ou non comptabilisés.",
    "Administration of vaccines for disease prevention.":
      "Administration de vaccins pour la prévention des maladies.",
    "Medical intervention for diagnosed diseases or conditions.":
      "Intervention médicale pour des maladies ou affections diagnostiquées.",
    "Administration of antiparasitic medications for internal parasite control.":
      "Administration de médicaments antiparasitaires pour le contrôle des parasites internes.",
    "Medical care for physical injuries or trauma.":
      "Soins médicaux pour les blessures physiques ou traumatismes.",
    "Surgical procedures performed on animals.":
      "Procédures chirurgicales effectuées sur les animaux.",
    "Monitoring and care following surgical procedures.":
      "Surveillance et soins après les procédures chirurgicales.",
    "Provision of additional nutrients or dietary supplements.":
      "Administration de nutriments supplémentaires ou de compléments alimentaires.",
    "Recording of animal body weight for monitoring growth and health.":
      "Enregistrement du poids corporel de l'animal pour surveiller la croissance et la santé.",
    "Assessment of animal's physical condition and fat reserves.":
      "Évaluation de la condition physique et des réserves de graisse de l'animal.",
    "Movement of animals between different locations or facilities.":
      "Déplacement des animaux entre différents lieux ou installations.",
    "Placement of animals in quarantine for health monitoring.":
      "Placement des animaux en quarantaine pour surveillance sanitaire.",
    "Release of animals from quarantine after health clearance.":
      "Libération des animaux de la quarantaine après validation sanitaire.",
    "Separation of animals for health or management purposes.":
      "Séparation des animaux pour des raisons de santé ou de gestion.",
    "Artificial insemination procedure for breeding.":
      "Procédure d'insémination artificielle pour la reproduction.",
    "Recording of natural breeding events.":
      "Enregistrement des événements d'accouplement naturel.",
    "Confirmation of pregnancy through testing.":
      "Confirmation de gestation par test.",
    "Documentation of negative pregnancy test results.":
      "Documentation des résultats négatifs au test de gestation.",
    "Observation and recording of estrus signs.":
      "Observation et enregistrement des signes de chaleurs.",
    "Documentation of birthing events and outcomes.":
      "Documentation des événements de mise bas et leurs résultats.",
    "Recording of pregnancy loss events.":
      "Enregistrement des événements de perte de gestation.",
    "Beginning of milk production period.":
      "Début de la période de production de lait.",
    "End of milk production period.":
      "Fin de la période de production de lait.",
    "Routine health screening with positive findings.":
      "Dépistage sanitaire de routine avec résultats positifs.",
    "Routine health screening with no concerning findings.":
      "Dépistage sanitaire de routine sans résultats préoccupants.",
    "Assessment of hoof condition and health.":
      "Évaluation de l'état et de la santé des sabots.",
    "Planned end of lactation in dairy animals.":
      "Fin planifiée de la lactation chez les animaux laitiers.",
    "Separation of young animals from maternal feeding.":
      "Séparation des jeunes animaux de l'alimentation maternelle.",
    "Modification of feed type or feeding regime.":
      "Modification du type d'alimentation ou du régime alimentaire.",
    "Removal of wool or hair coat.": "Retrait de la laine ou du pelage.",
    "Maintenance trimming of hooves.": "Taille d'entretien des sabots.",
    "Application of identification tags.":
      "Application de boucles d'identification.",
    "Surgical or chemical sterilization of male animals.":
      "Stérilisation chirurgicale ou chimique des animaux mâles.",
    "Removal or prevention of horn growth.":
      "Élimination ou prévention de la croissance des cornes.",
    "Scheduled or emergency veterinary examination.":
      "Examen vétérinaire programmé ou d'urgence.",
    "Documentation of animal behavior patterns or changes.":
      "Documentation des modèles de comportement animal ou des changements.",
    tutorialsIntro:
      "Nous sommes ravis que vous ayez choisi d'utiliser notre application. Pour\n" +
      "            vous aider à vous familiariser avec ses fonctionnalités, nous avons créé\n" +
      "            des tutoriels vidéo détaillés qui expliquent étape par étape comment utiliser\n" +
      "            notre application.",
    NotFoundMessage: "La page que vous recherchez n'existe pas",
    goMainScreen: "Aller à la page principale",
    birdsGroups: "Groupes d'Oiseaux",
    newBirdGroup: "Nouveau Groupe d'Oiseaux",
    groupName: "Nom du Groupe",
    Adult: "Adulte",
    Juvenile: "Juvénile",
    Hatchling: "Poussin",
    Mixture: "Mixte",
    birdGroupAdultDescription:
      "Oiseaux complètement matures qui ont achevé leur développement physique et atteint l'âge reproductif. Ils présentent généralement un plumage adulte et des motifs de coloration spécifiques à leur espèce.",
    birdGroupJuvenileDescription:
      "Jeunes oiseaux qui ont quitté le nid et peuvent voler, mais n'ont pas atteint la maturité adulte. Ils peuvent présenter des motifs de plumage intermédiaires et continuent à grandir. Généralement entre 2 et 12 mois, selon l'espèce.",
    birdGroupHatchingDescription:
      "Oiseaux nouvellement éclos jusqu'au stade de poussin. Généralement encore au nid, nécessitant des soins et une alimentation parentale. Ils peuvent être partiellement ou totalement dépendants des parents pour leur survie.",
    birdGroupUnknownDescription:
      "L'âge ne peut pas être déterminé en raison de marqueurs visuels insuffisants, de documentation ou de contraintes d'observation.",
    birdGroupMixtureDescription:
      "Groupe d'oiseaux avec des catégories d'âge mixtes, y compris des adultes, des juvéniles et des poussins.",
    ageGroup: "Groupe d'Âge",
    birdsCount: "Nombre d'Oiseaux",
    birdGroups: "Groupes d'Oiseaux",
    creatingBirdsGroup: "Création du groupe d'oiseaux...",
    birdsGroupCreated: "Le groupe d'oiseaux a été créé !",
    errorCreatingBirdsGroup:
      "Une erreur s'est produite lors de la création du groupe d'oiseaux",
    name100Characters: "Le nom ne peut pas dépasser 100 caractères",
    description500Characters:
      "La description ne peut pas dépasser 500 caractères",
    countMin1: "Le nombre d'oiseaux doit être au minimum 1",
    birdsSummary: "Résumé des Données d'Oiseaux",
    count: "Nombre",
    deletingBirdsGroup: "Suppression du groupe d'oiseaux...",
    birdsGroupDeleted: "Le groupe d'oiseaux a été supprimé !",
    birdsGroupDeleteError:
      "Une erreur s'est produite lors de la suppression du groupe d'oiseaux",
    deleteBirdsGroupConfirmation:
      "Êtes-vous sûr de vouloir supprimer le groupe d'oiseaux ?",
    birdsGroupWillBeDeleted: "Le groupe d'oiseaux sera supprimé.",
    updatingBirdsGroup: "Mise à jour du groupe d'oiseaux...",
    birdsGroupUpdated: "Le groupe d'oiseaux a été mis à jour !",
    errorUpdatingBirdsGroup:
      "Une erreur s'est produite lors de la mise à jour du groupe d'oiseaux",
    deleteBirdsGroup: "Supprimer le Groupe d'Oiseaux",
    Duck: "Canard",
    Turkey: "Dinde",
    Goose: "Oie",
    Quail: "Caille",
    Ostrich: "Autruche",
    "Rhode Island Red": "Rhode Island Rouge",
    "Plymouth Rock": "Plymouth Rock",
    Leghorn: "Leghorn",
    Australorp: "Australorp",
    Orpington: "Orpington",
    Sussex: "Sussex",
    Wyandotte: "Wyandotte",
    Brahma: "Brahma",
    Cochin: "Cochin",
    Silkie: "Soie",
    "New Hampshire": "New Hampshire",
    Ameraucana: "Ameraucana",
    Marans: "Marans",
    Pekin: "Pékin",
    Muscovy: "Muscovy",
    Runner: "Coureur",
    "Khaki Campbell": "Khaki Campbell",
    Cayuga: "Cayuga",
    "Swedish Blue": "Suédoise Bleue",
    Rouen: "Rouen",
    "Welsh Harlequin": "Arlequin Gallois",
    "Broad Breasted White": "Poitrine Large Blanc",
    "Broad Breasted Bronze": "Poitrine Large Bronze",
    "Bourbon Red": "Bourbon Rouge",
    Narragansett: "Narragansett",
    "Royal Palm": "Palmier Royal",
    "Black Spanish": "Espagnol Noir",
    "Beltsville Small White": "Beltsville Petit Blanc",
    Toulouse: "Toulouse",
    Embden: "Embden",
    African: "Africaine",
    Chinese: "Chinoise",
    Pilgrim: "Pilgrim",
    "American Buff": "Américaine Beige",
    Sebastopol: "Sebastopol",
    "African Black": "Africain Noir",
    "Blue Neck": "Cou Bleu",
    "Red Neck": "Cou Rouge",
    "Kenyan Red": "Kenyan Rouge",
    "Zimbabwe Blue": "Zimbabwe Bleu",
    "South African Black": "Sud-Africain Noir",
    "Israeli Blue": "Israélien Bleu",
    "Australian Black": "Australien Noir",
    selectBirdGroups: "Sélectionner les Groupes d'Oiseaux",
    noBirdGroupsAssociated: "Aucun groupe d'oiseaux associé",
    oneBirdGroupAssociated: "1 groupe d'oiseaux associé",
    birdGroupsAssociated: "groupes d'oiseaux associés",
    assocBirdGroups: "Associer des Groupes d'Oiseaux",
    birds: "Oiseaux",
    animalGender: "Genre de l'Animal",
    selectHiveType: "Sélectionner le type de ruche",
    contactInfo:
      "Pour toute question ou problème connexe, n'hésitez pas à nous contacter et nous vous répondrons dès que possible !",
    appLanguage: "Langue de l'Application",
    romanian: "Roumain",
    english: "Anglais",
    selectAppLanguage: "Sélectionner la langue de l'application",
    event: "Événement",
    birdsGroupsEvents: "Événements des Oiseaux",
    thereAreNoEventsYet: "Il n'y a pas encore d'événements",
    selectBirdsGroup: "Sélectionner le Groupe d'Oiseaux",
    creatingBirdsGroupEvent: "Création de l'événement du groupe d'oiseaux...",
    birdsGroupEventCreated: "L'événement du groupe d'oiseaux a été créé !",
    errorCreatingBirdsGroupEvent:
      "Une erreur s'est produite lors de la création de l'événement du groupe d'oiseaux",
    birdsGroupRequired: "Le groupe d'oiseaux est obligatoire",
    HealthEventType: "Événements de Santé",
    Health: "Santé",
    HealthCheck: "Vérification de Santé",
    MedicationAdministration: "Administration de Médicaments",
    "Generic medical and preventive health procedures for birds.":
      "Procédures médicales et préventives génériques pour les oiseaux.",
    "Generic event related to recording and tracking of bird metrics.":
      "Événement générique lié à l'enregistrement et au suivi des métriques des oiseaux.",
    "Generic events related to bird movement and location changes.":
      "Événements génériques liés au déplacement des oiseaux et aux changements de lieu.",
    "Routine health inspection and monitoring.":
      "Inspection et surveillance sanitaire de routine.",
    "Movement between different locations.":
      "Déplacement entre différents lieux.",
    "Separation for health or management purposes.":
      "Séparation pour des raisons de santé ou de gestion.",
    "Habitat cleaning and maintenance.": "Nettoyage et entretien de l'habitat.",
    "General monitoring and behavioral observation.":
      "Surveillance générale et observation comportementale.",
    "Miscellaneous bird event that doesn't fit into any category.":
      "Événement divers qui ne rentre dans aucune catégorie.",
    "Administration of medication for disease prevention or treatment.":
      "Administration de médicaments pour la prévention ou le traitement des maladies.",
    birdsGroup: "Groupe d'Oiseaux",
    birdsGroupEvent: "Événement du Groupe d'Oiseaux",
    Cleaning: "Nettoyage",
    Observation: "Observation",
    allBirdsGroups: "Tous les Groupes d'Oiseaux",
    AddHeads: "Ajouter des Têtes",
    RemoveHeads: "Retirer des Têtes",
    "Add birds into the group.": "Ajouter des oiseaux au groupe.",
    "Remove birds into the group.": "Retirer des oiseaux du groupe.",
    medicineDescription: "Description du Médicament",
    "Broad-spectrum antibiotic effective against respiratory infections and bacterial diseases. Commonly used for treating bacterial infections in various bird species.":
      "Antibiotique à large spectre efficace contre les infections respiratoires et les maladies bactériennes. Couramment utilisé pour traiter les infections bactériennes chez diverses espèces d'oiseaux.",
    "Antibiotic effective against mycoplasma infections and chronic respiratory disease. Often used in combination with other medications for respiratory treatments.":
      "Antibiotique efficace contre les infections à mycoplasmes et les maladies respiratoires chroniques. Souvent utilisé en combinaison avec d'autres médicaments pour les traitements respiratoires.",
    "Anti-coccidial medication used to prevent and treat coccidiosis. Essential for maintaining digestive health in young birds.":
      "Médicament anticoccidien utilisé pour prévenir et traiter la coccidiose. Essentiel pour maintenir la santé digestive chez les jeunes oiseaux.",
    "Essential vitamin supplement supporting bone health, immune function, and reproduction. Particularly important during growth and breeding periods.":
      "Supplément vitaminique essentiel soutenant la santé osseuse, la fonction immunitaire et la reproduction. Particulièrement important pendant les périodes de croissance et de reproduction.",
    "Vitamin supplement supporting metabolism, nerve function, and overall health. Often used during stress periods or recovery.":
      "Supplément vitaminique soutenant le métabolisme, la fonction nerveuse et la santé générale. Souvent utilisé pendant les périodes de stress ou de récupération.",
    "Probiotics for Birds": "Probiotiques pour Oiseaux",
    "Beneficial bacteria supplement supporting digestive health and immune function. Especially useful after antibiotic treatment or during stress.":
      "Supplément de bactéries bénéfiques soutenant la santé digestive et la fonction immunitaire. Particulièrement utile après un traitement antibiotique ou pendant un stress.",
    "Antifungal medication used to treat yeast infections and candidiasis. Common treatment for digestive tract fungal infections.":
      "Médicament antifongique utilisé pour traiter les infections à levures et la candidose. Traitement courant pour les infections fongiques du tractus digestif.",
    "Deworming medication effective against roundworms. Regular use helps maintain digestive health and prevent parasite buildup.":
      "Médicament vermifuge efficace contre les vers ronds. L'utilisation régulière aide à maintenir la santé digestive et à prévenir l'accumulation de parasites.",
    "Electrolyte Solution": "Solution d'Électrolytes",
    "Supplement for maintaining hydration and electrolyte balance. Critical during heat stress or illness recovery.":
      "Supplément pour maintenir l'hydratation et l'équilibre électrolytique. Critique pendant le stress thermique ou la récupération après maladie.",
    Milliliters: "Millilitres",
    Milligrams: "Milligrammes",
    Grams: "Grammes",
    Pieces: "Pièces",
    measuringUnit: "Unité de Mesure",
    birdsGroupIntendedPurpose: "Objectif du Groupe d'Oiseaux",
    "Eggs and Meat": "Œufs et Viande",
    Eggs: "Œufs",
    Pet: "Animal de Compagnie",
    Breeding: "Reproduction",
    TrimmingFeathers: "Taille des Plumes",
    "Trimming of feathers or beaks.": "Taille des plumes ou des becs.",
    EggsCounting: "Comptage des Œufs",
    "Counting the number of eggs produced by the group.":
      "Comptage du nombre d'œufs produits par le groupe.",
    eggsCount: "Nombre d'Œufs",
    eggsProduction: "Production d'Œufs",
    totalEggsCount: "Nombre Total d'Œufs",
    latestCount: "Dernier Comptage",
    recordedEvents: "Événements Enregistrés",
    recentEvents: "Événements Récents",
    noBirdsGroupsFound: "Aucun groupe d'oiseaux trouvé",
    assocBirdGroup: "Associer un Groupe d'Oiseaux",
    intendedUseRequired: "L'objectif du Groupe d'Oiseaux est obligatoire",
    locationRequired: "L'emplacement est obligatoire",
    updatingEvent: "Mise à jour de l'événement...",
    eventUpdated: "L'événement a été mis à jour !",
    eventUpdateError:
      "Une erreur s'est produite lors de la mise à jour de l'événement",
    deletingEvent: "Suppression de l'événement...",
    eventDeleted: "L'événement a été supprimé !",
    eventDeleteError:
      "Une erreur s'est produite lors de la suppression de l'événement",
    hiveScale: "Balance de Ruche",
    myScales: "Mes Balances",
    addHiveScale: "Ajouter une Balance de Ruche",
    creatingHiveScale: "Création de la balance de ruche...",
    hiveScaleCreated: "La balance de ruche a été créée !",
    errorCreatingHiveScale:
      "Une erreur s'est produite lors de la création de la balance de ruche",
    phoneNumber: "Numéro de Téléphone",
    battery: "Batterie",
    lastMeasurementTime: "Dernière Mesure",
    humidity: "Humidité",
    pressure: "Pression",
    temperature: "Température",
    extraWeight: "Poids Supplémentaire",
    signal: "Signal",
    noMeasurementsRecorded: "Aucune mesure enregistrée",
    oneDayImprovement: "Gain sur 24h",
    lastEvents: "Les derniers {{count}} événements sur {{total}} événements",
    messageSent: "Le message a été envoyé !",
    messageNotSent: "Une erreur s'est produite lors de l'envoi du message",
    ainNotUnique: "L'identifiant doit être unique",
    temperatureStatistics: "Statistiques de Température",
    weightStatistics: "Statistiques de Poids",
    period: "Période",
    lastMeasurement: "Dernière Mesure",
    statistics: "Statistiques",
    lastMeasurements: "Les dernières {{count}} mesures",
    lastMeasurementsTotal: "Les dernières {{count}} mesures sur {{total}}",
    last_24h: "Dernières 24h",
    last_2_weeks: "2 Dernières Semaines",
    minVal: "Valeur Minimale",
    maxVal: "Valeur Maximale",
    avgVal: "Valeur Moyenne",
    hiveLocation: "Emplacement de la Ruche",
    forBetterRepresentationAvg:
      "Pour une meilleure représentation, les valeurs ont été calculées comme la moyenne d'intervalles de {{count}} heures.",
    showAllMeasurements: "Afficher Toutes les Mesures",
    measurements: "Mesures",
    measurementsForHive: "Mesures pour la Ruche",
    changeHive: "Changer de Ruche",
    hiveScaleDetails: "Détails de la Balance de Ruche",
    loadMore: "Charger Plus",
    lastMeasurementsForHive: "Dernières Mesures pour la Ruche",
    HoneyHarvest: "Récolte de Miel",
    WaxHarvest: "Récolte de Cire",
    PropolisHarvest: "Récolte de Propolis",
    HiveHarvest: "Récolte de Ruche",
    "Events related to honey harvesting and processing or related yield.":
      "Événements liés à la récolte et au traitement du miel ou au rendement associé.",
    "Extracting honey from frames and processing it for storage or sale.":
      "Extraction du miel des cadres et traitement pour le stockage ou la vente.",
    "Collecting propolis from hives for various uses or products.":
      "Collecte de propolis des ruches pour diverses utilisations ou produits.",
    "Harvesting beeswax from frames or cappings for processing or sale.":
      "Récolte de cire d'abeille des cadres ou des opercules pour traitement ou vente.",
    HiveHarvestEventType: "Événements de Récolte de Ruche",
    addHoneyHarvest: "Ajouter une Récolte de Miel",
    removeScaleFromHive: "Retirer la Balance de la Ruche",
    changePhoneNumber: "Changer le Numéro de Téléphone",
    removeScaleFromHiveConfirmation:
      "Êtes-vous sûr de vouloir retirer la balance de la ruche ?",
    removingScale: "Retrait de la balance...",
    scaleRemoved: "La balance a été retirée !",
    scaleRemoveError: "Une erreur s'est produite lors du retrait de la balance",
    hiveScales: "Balances de Ruche",
    associatedHive: "Ruche Associée",
    scaleForHive: "Balance pour la Ruche {{hive}}",
    scale: "Balance {{phoneNumber}}",
    noAssocHive: "Aucune Ruche Associée",
    assocHiveScale: "Associer une Balance de Ruche",
    assocHiveScaleConfirmation:
      "Êtes-vous sûr de vouloir associer la balance à la ruche {{hive_code}} ?",
    updatingHiveScale: "Mise à jour de la balance de ruche...",
    hiveScaleUpdated: "La balance de ruche a été mise à jour !",
    hiveScaleUpdateError:
      "Une erreur s'est produite lors de la mise à jour de la balance de ruche",
    noHivesWithoutScales: "Aucune ruche sans balance",
    hiveScaleEvents: "Événements de Balance de Ruche",
    ScaleRemoved: "Balance Retirée",
    ScaleAdded: "Balance Ajoutée",
    PhoneNumberChanged: "Numéro de Téléphone Modifié",
    scaleRemovedEventDescription:
      "La balance {{scale}} a été retirée de la ruche {{hive}} !",
    scaleAddedEventDescription:
      "La balance {{scale}} a été ajoutée à la ruche {{hive}} !",
    phoneNumberChangedEventDescription:
      "Le numéro de téléphone de la balance {{scale}} a été changé de {{old_phone_number}} à {{new_phone_number}} !",
    noLocationData: "Pas de données de localisation",
    noScalesWithoutHive: "Pas de balances sans ruche associée",
    noHiveScalesFound: "Aucune balance de ruche trouvée",
    language: "Langue",
    yourData: "Vos Informations",
    yourFarmDetails: "Détails de Votre Ferme",
    Beekeeping: "Apiculture",
    "Choose at least one module that corresponds to your farm's activities":
      "Choisissez au moins un module correspondant aux activités de votre ferme",
    billingData: "Informations de Facturation",
    farmDetails: "Détails de la Ferme",
    "Legal Name": "Raison Sociale",
    "Enter the legal name of your business":
      "Entrez la raison sociale de votre entreprise",
    "Tax Identification Number (TIN)": "Numéro d'Identification Fiscale",
    "Enter your tax identification number":
      "Entrez votre numéro d'identification fiscale",
    Street: "Rue",
    City: "Ville",
    "Street address": "Adresse",
    "State/Province": "État/Province",
    "State or province": "État ou province",
    Country: "Pays",
    signupSuccess: "Inscription Réussie",
    "Enter your phone number": "Entrez votre numéro de téléphone",
    changingLanguage: "Changement de langue en cours...",
    languageChanged: "La langue a été changée !",
    languageChangeError: "Une erreur est survenue lors du changement de langue",
    legalNameRequired: "La raison sociale est obligatoire",
    tinRequired: "Le numéro d'identification fiscale est obligatoire",
    streetRequired: "La rue est obligatoire",
    cityRequired: "La ville est obligatoire",
    spRequired: "L'état/province est obligatoire",
    countryRequired: "Le pays est obligatoire",
    "Farm Name": "Nom de la Ferme",
    "Enter the legal name of your farm": "Entrez le nom de votre ferme",
    pleaseEnterEmail: "Veuillez entrer votre adresse e-mail",
    pleaseEnterPassword: "Veuillez entrer votre mot de passe",
    passwordRequired: "Le mot de passe est obligatoire",
    invalidEmail: "L'adresse e-mail n'est pas valide",
    "Enter the name of your farm": "Entrez le nom de votre ferme",
    selectCountryCode: "Sélectionnez le code pays",
    updatingPreferences: "Mise à jour des préférences...",
    preferencesUpdated: "Préférences mises à jour !",
    preferencesUpdateError:
      "Une erreur s'est produite lors de la mise à jour des préférences",

    // Bees medications

    "Varroa Treatment": "Traitement Varroa",
    "Nosema Treatment": "Traitement Nosema",
    "Brood Disease Treatment": "Traitement des maladies du couvain",
    Supplement: "Supplément",
    Antibiotic: "Antibiotique",
    Fungicide: "Fongicide",
    "General Health": "Santé générale",
    "Treatments specifically designed to control Varroa mite infestations in bee colonies.":
      "Traitements spécifiquement conçus pour contrôler les infestations d'acariens Varroa dans les colonies d'abeilles.",
    "Medications for treating Nosema apis and Nosema ceranae infections in honey bees.":
      "Médicaments pour traiter les infections à Nosema apis et Nosema ceranae chez les abeilles mellifères.",
    "Treatments for various brood diseases including American foulbrood, European foulbrood, and chalkbrood.":
      "Traitements pour diverses maladies du couvain, notamment la loque américaine, la loque européenne et le couvain plâtré.",
    "Nutritional supplements to support colony health and development.":
      "Suppléments nutritionnels pour soutenir la santé et le développement de la colonie.",
    "Antibiotics used to treat bacterial infections in bee colonies when legally permitted.":
      "Antibiotiques utilisés pour traiter les infections bactériennes dans les colonies d'abeilles lorsque cela est légalement autorisé.",
    "Treatments for fungal diseases in bee colonies.":
      "Traitements pour les maladies fongiques dans les colonies d'abeilles.",
    "General treatments to support overall colony health and immunity.":
      "Traitements généraux pour soutenir la santé globale et l'immunité de la colonie.",
    Amitraz: "Amitraz",
    "Oxalic Acid": "Acide oxalique",
    "Formic Acid": "Acide formique",
    ApiVar: "ApiVar",
    "ApiLife VAR": "ApiLife VAR",
    "Mite-Away Quick Strips": "Bandelettes Mite-Away Quick",
    Apistan: "Apistan",
    "CheckMite+": "CheckMite+",
    HopGuard: "HopGuard",
    Thymovar: "Thymovar",
    "Fumagilin-B": "Fumagilin-B",
    Nozevit: "Nozevit",
    Terramycin: "Terramycine",
    Tylan: "Tylan",
    "Bee Pro": "Bee Pro",
    HiveAlive: "HiveAlive",
    ProDFM: "ProDFM",
    "Vitamin B Complex": "Complexe de vitamines B",
    ApiHerb: "ApiHerb",
    "Essential Oil Blend": "Mélange d'huiles essentielles",
    "Honey-B-Healthy": "Honey-B-Healthy",
    "Synthetic acaricide used to control Varroa mites in honey bee colonies.":
      "Acaricide synthétique utilisé pour contrôler les acariens Varroa dans les colonies d'abeilles mellifères.",
    "Natural organic acid treatment for Varroa mites, especially effective in broodless periods.":
      "Traitement à base d'acide organique naturel contre les acariens Varroa, particulièrement efficace pendant les périodes sans couvain.",
    "Organic acid treatment for Varroa mites that can be used during honey flow, affects mites under cell cappings.":
      "Traitement à base d'acide organique contre les acariens Varroa qui peut être utilisé pendant la miellée, affecte les acariens sous les opercules des cellules.",
    "Amitraz-based Varroa treatment strips, typically used for 6-8 weeks for full efficacy.":
      "Bandelettes de traitement Varroa à base d'amitraz, généralement utilisées pendant 6 à 8 semaines pour une efficacité complète.",
    "Thymol, eucalyptol, menthol, and camphor-based treatment for Varroa mites.":
      "Traitement contre les acariens Varroa à base de thymol, d'eucalyptol, de menthol et de camphre.",
    "Formic acid-based Varroa treatment delivered via gel strips, effective against mites under brood cappings.":
      "Traitement Varroa à base d'acide formique délivré via des bandelettes de gel, efficace contre les acariens sous les opercules du couvain.",
    "Tau-fluvalinate based strips for Varroa control, though resistance is now common in many areas.":
      "Bandelettes à base de tau-fluvalinate pour le contrôle de Varroa, bien que la résistance soit maintenant courante dans de nombreuses régions.",
    "Coumaphos-based strips for Varroa control, often used in rotation with other treatments.":
      "Bandelettes à base de coumaphos pour le contrôle de Varroa, souvent utilisées en rotation avec d'autres traitements.",
    "Beta acid hop compound strips for Varroa control, can be used during honey production.":
      "Bandelettes composées d'acides bêta de houblon pour le contrôle de Varroa, peuvent être utilisées pendant la production de miel.",
    "Thymol-based treatment for Varroa mites with lower concentration than ApiLife VAR.":
      "Traitement à base de thymol pour les acariens Varroa avec une concentration plus faible que ApiLife VAR.",
    "Treatment for Nosema disease, administered in sugar syrup to prevent and control infections.":
      "Traitement pour la maladie de Nosema, administré dans du sirop de sucre pour prévenir et contrôler les infections.",
    "Natural plant-based supplement to help bees resist Nosema infections.":
      "Supplément naturel à base de plantes pour aider les abeilles à résister aux infections à Nosema.",
    "Oxytetracycline antibiotic for prevention and control of European foulbrood and American foulbrood (where legal).":
      "Antibiotique à base d'oxytétracycline pour la prévention et le contrôle de la loque européenne et de la loque américaine (là où c'est légal).",
    "Tylosin antibiotic used for American foulbrood control in some regions where legally permitted.":
      "Antibiotique à base de tylosine utilisé pour le contrôle de la loque américaine dans certaines régions où cela est légalement autorisé.",
    "Protein supplement to support bee nutrition when natural pollen is scarce.":
      "Supplément protéique pour soutenir la nutrition des abeilles lorsque le pollen naturel est rare.",
    "Nutritional supplement with thymol, lemongrass, and seaweed extracts to support colony health.":
      "Supplément nutritionnel avec extraits de thymol, de citronnelle et d'algues pour soutenir la santé de la colonie.",
    "Probiotic supplement to support bee gut health and immune function.":
      "Supplément probiotique pour soutenir la santé intestinale et la fonction immunitaire des abeilles.",
    "Vitamin supplement added to sugar syrup to support bee health during stress periods.":
      "Supplément vitaminique ajouté au sirop de sucre pour soutenir la santé des abeilles pendant les périodes de stress.",
    "Herbal preparation to support overall colony health and vigor.":
      "Préparation à base de plantes pour soutenir la santé générale et la vigueur de la colonie.",
    "Mixture of lemongrass, spearmint, and other essential oils to promote colony health.":
      "Mélange de citronnelle, de menthe verte et d'autres huiles essentielles pour favoriser la santé de la colonie.",
    "Essential oil-based feed supplement to stimulate feeding and support colony health.":
      "Supplément alimentaire à base d'huiles essentielles pour stimuler l'alimentation et soutenir la santé de la colonie.",
    Drops: "Gouttes",
    Strips: "Bandelettes",
    Doses: "Doses",
    Packets: "Sachets",

    // Animal medications

    Penicillin: "Pénicilline",
    Oxytetracycline: "Oxytétracycline",
    Ceftiofur: "Ceftiofur",
    Tylosin: "Tylosine",
    "Flunixin Meglumine": "Flunixine Méglumine",
    Meloxicam: "Méloxicam",
    Dexamethasone: "Dexaméthasone",
    Ivermectin: "Ivermectine",
    Fenbendazole: "Fenbendazole",
    Albendazole: "Albendazole",
    Levamisole: "Lévamisole",
    Oxytocin: "Ocytocine",
    "Prostaglandin F2alpha": "Prostaglandine F2alpha",
    GnRH: "GnRH",
    "Calcium Borogluconate": "Borogluconate de Calcium",
    "Selenium/Vitamin E": "Sélénium/Vitamine E",
    Lidocaine: "Lidocaïne",
    "Propylene Glycol": "Propylène Glycol",
    "Sodium Bicarbonate": "Bicarbonate de Sodium",
    Cephalexin: "Céphalexine",
    Cloxacillin: "Cloxacilline",
    Epinephrine: "Épinéphrine",
    Monensin: "Monensine",
    Rumensin: "Rumensine",

    // animal medications descriptions

    "Basic antibiotic for treating bacterial infections in livestock. Commonly used for pneumonia, mastitis, and foot rot.":
      "Antibiotique de base pour traiter les infections bactériennes chez le bétail. Couramment utilisé pour la pneumonie, la mammite et le piétin.",

    "Broad-spectrum antibiotic effective against respiratory diseases, pinkeye, and foot rot in cattle and sheep.":
      "Antibiotique à large spectre efficace contre les maladies respiratoires, la kératoconjonctivite et le piétin chez les bovins et les ovins.",

    "Advanced antibiotic for treating severe bacterial infections, particularly respiratory disease in cattle.":
      "Antibiotique avancé pour traiter les infections bactériennes graves, en particulier les maladies respiratoires chez les bovins.",

    "Antibiotic primarily used for respiratory diseases in cattle and swine. Also effective against mastitis.":
      "Antibiotique principalement utilisé pour les maladies respiratoires chez les bovins et les porcins. Également efficace contre la mammite.",

    "NSAID used for pain, fever, and inflammation in livestock. Commonly used for mastitis and lameness.":
      "AINS utilisé pour la douleur, la fièvre et l'inflammation chez le bétail. Couramment utilisé pour la mammite et la boiterie.",

    "NSAID used for pain management in cattle, particularly after dehorning or castration.":
      "AINS utilisé pour la gestion de la douleur chez les bovins, particulièrement après l'écornage ou la castration.",

    "Corticosteroid used for severe inflammation, shock, and allergic reactions in livestock.":
      "Corticostéroïde utilisé pour l'inflammation sévère, le choc et les réactions allergiques chez le bétail.",

    "Broad-spectrum parasiticide effective against internal and external parasites in cattle, sheep, and pigs.":
      "Parasiticide à large spectre efficace contre les parasites internes et externes chez les bovins, les ovins et les porcins.",

    "Dewormer effective against gastrointestinal parasites in cattle, sheep, and goats.":
      "Vermifuge efficace contre les parasites gastro-intestinaux chez les bovins, les ovins et les caprins.",

    "Broad-spectrum dewormer effective against internal parasites, including liver flukes in ruminants.":
      "Vermifuge à large spectre efficace contre les parasites internes, y compris les douves du foie chez les ruminants.",

    "Dewormer effective against gastrointestinal roundworms in cattle, sheep, and goats.":
      "Vermifuge efficace contre les nématodes gastro-intestinaux chez les bovins, les ovins et les caprins.",

    "Hormone used to induce labor, treat retained placenta, and stimulate milk letdown in dairy animals.":
      "Hormone utilisée pour déclencher le travail, traiter la rétention placentaire et stimuler la descente de lait chez les animaux laitiers.",

    "Hormone used for estrus synchronization and treatment of retained corpus luteum in cattle.":
      "Hormone utilisée pour la synchronisation des chaleurs et le traitement du corps jaune persistant chez les bovins.",

    "Hormone used for treating cystic ovaries and synchronizing ovulation in cattle breeding programs.":
      "Hormone utilisée pour traiter les ovaires kystiques et synchroniser l'ovulation dans les programmes de reproduction bovine.",

    "Supplement used for treating vitamin B deficiencies and supporting metabolism in stressed animals.":
      "Supplément utilisé pour traiter les carences en vitamine B et soutenir le métabolisme chez les animaux stressés.",

    "Used for treating milk fever (hypocalcemia) in dairy cattle.":
      "Utilisé pour traiter la fièvre de lait (hypocalcémie) chez les bovins laitiers.",

    "Supplement used to prevent and treat white muscle disease in young livestock.":
      "Supplément utilisé pour prévenir et traiter la maladie du muscle blanc chez les jeunes animaux d'élevage.",

    "Local anesthetic used for minor surgeries, dehorning, and castration procedures.":
      "Anesthésique local utilisé pour les chirurgies mineures, l'écornage et les procédures de castration.",

    "Used to treat ketosis in dairy cattle and as an energy supplement.":
      "Utilisé pour traiter la cétose chez les bovins laitiers et comme supplément énergétique.",

    "Buffer used to treat and prevent ruminal acidosis in cattle.":
      "Tampon utilisé pour traiter et prévenir l'acidose ruminale chez les bovins.",

    "Intramammary antibiotic used for treating mastitis in dairy cattle.":
      "Antibiotique intramammaire utilisé pour traiter la mammite chez les bovins laitiers.",

    "Intramammary antibiotic specifically designed for dry cow therapy.":
      "Antibiotique intramammaire spécialement conçu pour le traitement des vaches taries.",

    "Emergency medication used for treating anaphylactic reactions in livestock.":
      "Médicament d'urgence utilisé pour traiter les réactions anaphylactiques chez le bétail.",

    "Feed additive used to prevent coccidiosis and improve feed efficiency in cattle.":
      "Additif alimentaire utilisé pour prévenir la coccidiose et améliorer l'efficacité alimentaire chez les bovins.",

    "Feed additive to improve feed efficiency and prevent bloat in cattle.":
      "Additif alimentaire pour améliorer l'efficacité alimentaire et prévenir la météorisation chez les bovins.",

    noExpensesOnSelectedPeriod: "Pas de dépenses sur la période sélectionnée",
    noIncomesOnSelectedPeriod: "Pas de revenus sur la période sélectionnée",
    failedToCreateAccount: "Échec de la création du compte",
    deleteAccount: "Supprimer le Compte",
    deleteAccountConfirmation:
      "Êtes-vous sûr de vouloir supprimer votre compte ? Cette action est irréversible. Vous perdrez toutes les données associées à votre compte.",
    deletingAccount: "Suppression du compte en cours...",
    accountDeleted: "Le compte a été supprimé !",
    errorOccurredWhileDeletingAccount:
      "Une erreur s'est produite lors de la suppression du compte",
    enterYourPasswordToContinue: "Entrez votre mot de passe pour continuer",
    yourPassword: "Votre mot de passe",
    HONEY_SALES: "Vente de Miel",
    POLLINATION_SERVICES: "Services de Pollinisation",
    BEESWAX_SALES: "Vente de Cire d'Abeille",
    BEE_COLONY_SALES: "Vente de Colonies d'Abeilles",
    BEE_FEED: "Nourriture pour Abeilles",
    QUEEN_PURCHASES: "Achat de Reines",
    HIVE_TREATMENTS: "Traitements de Ruches",
    BEE_COLONY_PURCHASES: "Achat de Colonies d'Abeilles",
    HONEY_PACKAGING: "Conditionnement du Miel",
    "Events related to providing supplemental nutrition to honey bee colonies.":
      "Événements liés à l'apport de nutrition supplémentaire aux colonies d'abeilles.",
    "Providing solid candy (like Apifonda) for winter or dearth periods.":
      "Fourniture de candi (comme l'Apifonda) pour l'hiver ou les périodes de disette.",
    "Feeding with protein supplements to boost brood production.":
      "Nourrissement avec des compléments protéiques pour stimuler la production de couvain.",
    "Providing pollen substitutes or supplements.":
      "Fourniture de substituts ou compléments de pollen.",
    "Strategic feeding to stimulate colony growth and activity.":
      "Nourrissement stratégique pour stimuler la croissance et l'activité de la colonie.",
    HiveFeeding: "Nourrissement des Ruches",
    CarbohydratesFeeding: "Nourrissement Glucidique",
    ProteinFeeding: "Nourrissement Protéique",
    PollenSubstitute: "Substitut de Pollen",
    StimulativeFeeding: "Nourrissement Stimulant",
    MyParcels: "Mes Parcelles",
    addNewParcel: "Ajouter une Nouvelle Parcelle",
    addCoordinates: "Ajouter des Coordonnées",
    parcelCoordinates: "Coordonnées de la Parcelle",
    addParcelCoordinates: "Ajouter des Coordonnées de la Parcelle",
    latitude: "Latitude",
    longitude: "Longitude",
    longitudeX: "Longitude (ou X/E)",
    latitudeY: "Latitude (ou Y/N)",
    latY: "Latitude (Y/N)",
    longX: "Longitude (X/E)",
    noCoordinatesYet: "Pas encore de coordonnées",
    addPointCoordinates: "Ajouter des Coordonnées de Point",
    parcelName: "Nom de la Parcelle",
    parcelDescription: "Description de la Parcelle",
    creatingParcel: "Création de la parcelle en cours...",
    parcelCreated: "Parcelle créée avec succès !",
    parcelCreationError:
      "Une erreur est survenue lors de la création de la parcelle",
    noParcelsFound: "Aucune parcelle trouvée",
    deleteParcel: "Supprimer la Parcelle",
    deletingParcel: "Suppression de la parcelle en cours...",
    parcelDeleted: "Parcelle supprimée avec succès !",
    parcelDeletionError:
      "Une erreur est survenue lors de la suppression de la parcelle",
    area: "Superficie",
    deleteParcelConfirmation:
      "Êtes-vous sûr de vouloir supprimer cette parcelle ?",
    deleteParcelDescription:
      "Cette action est irréversible. Toutes les données associées à cette parcelle seront perdues.",
    editParcelInfo: "Modifier les Informations de la Parcelle",
    editPointCoordinates: "Modifier les Coordonnées du Point",
    deletePoint: "Supprimer le Point",
    deletePointConfirmation: "Êtes-vous sûr de vouloir supprimer ce point ?",
    updatingParcel: "Mise à jour de la parcelle en cours...",
    parcelUpdated: "Parcelle mise à jour avec succès !",
    parcelUpdateError:
      "Une erreur est survenue lors de la mise à jour de la parcelle",
    parcelMap: "Carte de la Parcelle",
    HiveFeedingEventType: "Événements de Nourrissement des Ruches",
    ScaleAddedWithoutHive: "Balance ajoutée directement",
    "Hive was added directly, without having an associated hive.":
      "La ruche a été ajoutée directement, sans avoir une ruche associée.",
    editHiveScale: "Modifier la Balance de Ruche",
    "How are my bee colonies doing?":
      "Comment se portent mes colonies d'abeilles?",
    "What's the status of my queen bees?": "Quel est l'état de mes reines?",
    "How is my honey production looking?":
      "Comment se présente ma production de miel?",
    "What do my hive scale measurements show?":
      "Que montrent les mesures de poids de mes ruches?",
    "Do my hives need treatments soon?":
      "Mes ruches ont-elles besoin de traitements bientôt?",
    "Which apiaries need attention?":
      "Quels ruchers nécessitent de l'attention?",
    "Are any of my hives at risk of swarming?":
      "Certaines de mes ruches présentent-elles un risque d'essaimage?",
    "When should I move my apiaries for better nectar flow?":
      "Quand devrais-je déplacer mes ruchers pour un meilleur flux de nectar?",
    "Do I need to add or remove supers from my hives?":
      "Dois-je ajouter ou retirer des hausses de mes ruches?",
    "Are my hives ready for winter?":
      "Mes ruches sont-elles prêtes pour l'hiver?",

    "How are my bird flocks doing?": "Comment se portent mes volailles?",
    "How is my egg production looking?":
      "Comment se présente ma production d'œufs?",
    "How is the growth of my meat birds progressing?":
      "Comment progresse la croissance de mes volailles de chair?",
    "Do my birds need any treatments soon?":
      "Mes oiseaux ont-ils besoin de traitements bientôt?",
    "Which bird locations need attention?":
      "Quels emplacements de volailles nécessitent de l'attention?",
    "Are any of my bird groups overcrowded?":
      "Certains de mes groupes d'oiseaux sont-ils surpeuplés?",
    "When should I rotate my birds to fresh pasture?":
      "Quand devrais-je faire tourner mes volailles vers des pâturages frais?",
    "How is my bird feed consumption looking?":
      "Comment se présente la consommation d'aliments de mes volailles?",
    "How are my breeding birds performing?":
      "Comment performent mes oiseaux reproducteurs?",
    "Are my birds ready for the upcoming season?":
      "Mes oiseaux sont-ils prêts pour la saison à venir?",

    "How is my cattle herd doing?": "Comment se porte mon troupeau de bovins?",
    "What's the reproductive status of my cows?":
      "Quel est l'état reproductif de mes vaches?",
    "How is my milk production looking?":
      "Comment se présente ma production laitière?",
    "Do my cattle need any treatments soon?":
      "Mon bétail a-t-il besoin de traitements bientôt?",
    "What upcoming gestation events do I need to prepare for?":
      "Pour quels événements de gestation à venir dois-je me préparer?",
    "How is the growth of my calves and young stock?":
      "Comment évolue la croissance de mes veaux et jeunes bovins?",
    "Which cattle locations need attention?":
      "Quels emplacements de bovins nécessitent de l'attention?",
    "How should I adjust my cattle feeding program?":
      "Comment devrais-je ajuster mon programme d'alimentation des bovins?",
    "When should I breed my cows?":
      "Quand devrais-je mettre mes vaches à la reproduction?",
    "Which cows are due to calve soon?":
      "Quelles vaches doivent vêler bientôt?",
    "How should I manage my newborn calves?":
      "Comment devrais-je gérer mes veaux nouveau-nés?",

    "How is my pig herd doing?": "Comment se porte mon élevage de porcs?",
    "What's the reproductive status of my sows?":
      "Quel est l'état reproductif de mes truies?",
    "Which sows are due to farrow soon?":
      "Quelles truies doivent mettre bas bientôt?",
    "How should I manage my nursing piglets?":
      "Comment devrais-je gérer mes porcelets allaitants?",
    "Which piglets are ready for weaning?":
      "Quels porcelets sont prêts pour le sevrage?",
    "How is the growth performance of my growing pigs?":
      "Comment sont les performances de croissance de mes porcs en développement?",
    "Do my pigs need any treatments soon?":
      "Mes porcs ont-ils besoin de traitements bientôt?",
    "Which pig locations need attention?":
      "Quels emplacements de porcs nécessitent de l'attention?",
    "How should I adjust my pig feeding program?":
      "Comment devrais-je ajuster mon programme d'alimentation des porcs?",
    "When should I breed my sows?":
      "Quand devrais-je mettre mes truies à la reproduction?",
    "Which pigs are ready for market?":
      "Quels porcs sont prêts pour le marché?",

    "How is my goat herd doing?": "Comment se porte mon troupeau de chèvres?",
    "What's the reproductive status of my does?":
      "Quel est l'état reproductif de mes chèvres?",
    "How is my goat milk production looking?":
      "Comment se présente ma production de lait de chèvre?",
    "Do my goats need any treatments soon?":
      "Mes chèvres ont-elles besoin de traitements bientôt?",
    "How should I manage my young kids?":
      "Comment devrais-je gérer mes jeunes chevreaux?",
    "When should I breed my does?":
      "Quand devrais-je mettre mes chèvres à la reproduction?",
    "Which does are due to kid soon?":
      "Quelles chèvres doivent mettre bas bientôt?",
    "How should I manage parasites in my goat herd?":
      "Comment devrais-je gérer les parasites dans mon troupeau de chèvres?",
    "How should I adjust my goat feeding program?":
      "Comment devrais-je ajuster mon programme d'alimentation des chèvres?",
    "How is my fiber production looking?":
      "Comment se présente ma production de fibre?",
    "Which goats need hoof trimming?":
      "Quelles chèvres ont besoin d'un parage des onglons?",

    "How is my sheep flock doing?": "Comment se porte mon troupeau de moutons?",
    "What's the reproductive status of my ewes?":
      "Quel est l'état reproductif de mes brebis?",
    "Which ewes are due to lamb soon?":
      "Quelles brebis doivent agneler bientôt?",
    "How should I manage my young lambs?":
      "Comment devrais-je gérer mes jeunes agneaux?",
    "Do my sheep need any treatments soon?":
      "Mes moutons ont-ils besoin de traitements bientôt?",
    "How should I manage parasites in my sheep flock?":
      "Comment devrais-je gérer les parasites dans mon troupeau de moutons?",
    "When should I breed my ewes?":
      "Quand devrais-je mettre mes brebis à la reproduction?",
    "How is my wool production looking?":
      "Comment se présente ma production de laine?",
    "How should I adjust my sheep feeding program?":
      "Comment devrais-je ajuster mon programme d'alimentation des moutons?",
    "Which sheep need hoof trimming?":
      "Quels moutons ont besoin d'un parage des onglons?",
    "Which lambs are ready for market?":
      "Quels agneaux sont prêts pour le marché?",
    rateMessageExceeded: "Vous avez déjà noté cet événement",
    parcelView: "Vue de la Parcelle",
    assocParcel: "Associer Parcelle",
    noParcelsAssociated: "Aucune parcelle associée",
    oneParcelAssociated: "1 Parcelle Associée",
    parcelsAssociated: "{{count}} Parcelles Associées",
    selectParcels: "Sélectionner Parcelles",
    parcelEvents: "Événements de Parcelle",
    addParcelEvent: "Ajouter un Événement de Parcelle",
    selectParcel: "Sélectionner une Parcelle",
    parcelEvent: "Événement de Parcelle",
    deleteEvent: "Supprimer l'Événement",
    deleteEventConfirmation:
      "Êtes-vous sûr de vouloir supprimer cet événement ?",
    Irrigation: "Irrigation",
    "Events related to water management and irrigation systems for crop and land hydration.":
      "Événements liés à la gestion de l'eau et aux systèmes d'irrigation pour l'hydratation des cultures et des terres.",
    "Pest & Weed Control": "Contrôle des Ravageurs et des Mauvaises Herbes",
    "Activities for managing weeds, pests, and diseases affecting crops and land.":
      "Activités de gestion des mauvaises herbes, des ravageurs et des maladies affectant les cultures et les terres.",
    "Soil Management": "Gestion du Sol",
    "Practices for maintaining or improving soil health, structure, and fertility.":
      "Pratiques pour maintenir ou améliorer la santé, la structure et la fertilité du sol.",
    "Land Transaction": "Transaction Foncière",
    "Events related to buying, selling, leasing, or adjusting land boundaries.":
      "Événements liés à l'achat, la vente, la location ou l'ajustement des limites foncières.",
    Infrastructure: "Infrastructure",
    "Construction, maintenance, or modification of physical structures on the land.":
      "Construction, entretien ou modification des structures physiques sur le terrain.",
    "Miscellaneous parcel management events not classified in other categories.":
      "Événements divers de gestion de parcelles non classés dans d'autres catégories.",
    "Drip Irrigation": "Irrigation Goutte à Goutte",
    "Application of water directly to plant roots through a low-flow system to minimize water usage.":
      "Application d'eau directement aux racines des plantes via un système à faible débit pour minimiser l'utilisation d'eau.",
    "Sprinkler System": "Système d'Arrosage par Aspersion",
    "Use of overhead sprinklers to distribute water across the field or parcel.":
      "Utilisation d'arroseurs suspendus pour distribuer l'eau sur l'ensemble du champ ou de la parcelle.",
    "Flood Irrigation": "Irrigation par Submersion",
    "Traditional method of flooding fields with water for crop irrigation.":
      "Méthode traditionnelle d'inondation des champs avec de l'eau pour l'irrigation des cultures.",
    "Irrigation Maintenance": "Entretien des Systèmes d'Irrigation",
    "Repair, cleaning, or general upkeep of irrigation equipment and systems.":
      "Réparation, nettoyage ou entretien général des équipements et systèmes d'irrigation.",
    "Water Source Change": "Changement de Source d'Eau",
    "Modification of water source used for irrigation, such as well, river, or rainwater collection.":
      "Modification de la source d'eau utilisée pour l'irrigation, comme un puits, une rivière ou la collecte d'eau de pluie.",
    "Irrigation Schedule Change": "Changement de Calendrier d'Irrigation",
    "Adjustment to timing or frequency of irrigation events based on crop needs or water availability.":
      "Ajustement du moment ou de la fréquence des événements d'irrigation en fonction des besoins des cultures ou de la disponibilité de l'eau.",
    "Herbicide Application": "Application d'Herbicide",
    "Application of chemical substances to control or eliminate unwanted plants.":
      "Application de substances chimiques pour contrôler ou éliminer les plantes indésirables.",
    "Pesticide Application": "Application de Pesticide",
    "Use of chemicals to control insects or other pests damaging crops.":
      "Utilisation de produits chimiques pour contrôler les insectes ou autres ravageurs endommageant les cultures.",
    "Fungicide Application": "Application de Fongicide",
    "Treatment with substances that control fungal diseases in crops.":
      "Traitement avec des substances qui contrôlent les maladies fongiques dans les cultures.",
    "Manual Weeding": "Désherbage Manuel",
    "Physical removal of weeds by hand or with hand tools.":
      "Élimination physique des mauvaises herbes à la main ou avec des outils manuels.",
    "Soil Testing": "Analyse de Sol",
    "Analysis of soil composition to assess nutrient levels, pH, and other characteristics.":
      "Analyse de la composition du sol pour évaluer les niveaux de nutriments, le pH et d'autres caractéristiques.",
    "Fertilizer Application": "Application d'Engrais",
    "Addition of natural or synthetic substances to enrich soil with nutrients.":
      "Ajout de substances naturelles ou synthétiques pour enrichir le sol en nutriments.",
    Composting: "Compostage",
    "Creation or application of decomposed organic matter to improve soil structure and fertility.":
      "Création ou application de matière organique décomposée pour améliorer la structure et la fertilité du sol.",
    "Cover Crop Planting": "Plantation de Culture de Couverture",
    "Growing plants specifically to protect and enrich soil between main crop seasons.":
      "Culture de plantes spécifiquement pour protéger et enrichir le sol entre les saisons de cultures principales.",
    "Lime Application": "Application de Chaux",
    "Addition of materials containing calcium to neutralize soil acidity and improve structure.":
      "Ajout de matériaux contenant du calcium pour neutraliser l'acidité du sol et améliorer sa structure.",
    Mulching: "Paillage",
    "Application of material to soil surface to conserve moisture, suppress weeds, and regulate temperature.":
      "Application de matériau à la surface du sol pour conserver l'humidité, supprimer les mauvaises herbes et réguler la température.",
    "Land Purchase": "Achat de Terrain",
    "Acquisition of new land parcels to expand farm operations.":
      "Acquisition de nouvelles parcelles de terrain pour étendre les opérations agricoles.",
    "Land Sale": "Vente de Terrain",
    "Transfer of owned land to another party through sale.":
      "Transfert de terrain possédé à une autre partie par vente.",
    "Fence Installation": "Installation de Clôture",
    "Construction of new fencing to define boundaries or contain livestock.":
      "Construction de nouvelles clôtures pour définir les limites ou contenir le bétail.",
    "Fence Repair": "Réparation de Clôture",
    "Maintenance or restoration of existing fence structures.":
      "Entretien ou restauration des structures de clôture existantes.",
    "Building Construction": "Construction de Bâtiment",
    "Erection of structures such as barns, sheds, or processing facilities.":
      "Érection de structures telles que des granges, des hangars ou des installations de traitement.",
    "Weather Damage": "Dommages Météorologiques",
    "Assessment and documentation of damage caused by extreme weather events.":
      "Évaluation et documentation des dommages causés par des événements météorologiques extrêmes.",
    "Soil Remediation": "Réhabilitation du Sol",
    "Processes to remove contaminants or restore degraded soil conditions.":
      "Processus pour éliminer les contaminants ou restaurer les conditions de sol dégradé.",
    "Grant Application": "Demande de Subvention",
    "Submission of requests for financial assistance from government or private programs.":
      "Soumission de demandes d'aide financière auprès de programmes gouvernementaux ou privés.",
    cropPlantings: "Cultures",
    myCropsPlantings: "Mes Cultures",
    noCropsFound: "Aucune culture trouvée",
    newCropPlanting: "Nouvelle Culture",
    seedingDate: "Date de Semis",
    selectCrop: "Sélectionner une Culture",
    plannedHarvestDate: "Date de Récolte Prévue",
    expectedYield: "Rendement Attendu",
    savingCropPlanting: "Enregistrement de la culture...",
    cropPlantingSaved: "Culture enregistrée !",
    cropPlantingError:
      "Une erreur est survenue lors de l'enregistrement de la culture",
    cropPlantingDetails: "Détails de la Culture",
    editCropPlanting: "Modifier la Culture",
    deleteCropPlanting: "Supprimer la Culture",
    deleteCropPlantingConfirmation:
      "Êtes-vous sûr de vouloir supprimer cette culture ?",
    deletingCropPlanting: "Suppression de la culture...",
    cropPlantingDeleted: "Culture supprimée !",
    Wheat: "Blé",
    Barley: "Orge",
    Potatoes: "Pommes de terre",
    "Sugar Beet": "Betterave Sucrière",
    "Corn (Maize)": "Maïs",
    Tomatoes: "Tomates",
    Carrots: "Carottes",
    Cabbage: "Chou",
    Onions: "Oignons",
    Garlic: "Ail",
    Beans: "Haricots",
    Peas: "Pois",
    Lettuce: "Laitue",
    Cucumber: "Concombre",
    Grapes: "Raisins",
    Olives: "Olives",
    Rye: "Seigle",
    Oats: "Avoine",
    daysSinceSeeding: "Jours depuis le semis",
    daysUntilHarvest: "Jours jusqu'à la récolte",
    assocCropPlanting: "Associer une Culture",
    seeOtherAssociated: "Voir les autres",
    hideOtherAssociated: "Masquer",
    selectCropPlantings: "Sélectionner des Cultures",
    cropPlantingsAssociated_zero: "Aucune culture associée",
    cropPlantingsAssociated_one: "Une culture associée",
    cropPlantingsAssociated: "{{count}} cultures associées",
    parcel: "Parcelle",
    actualHarvestDate: "Date réelle de récolte",
    actualYield: "Rendement réel",
    notSet: "Non défini",
    cropGrowingDetails: "Détails de croissance",
    averageGrowingDays: "Nombre moyen de jours de croissance",
    preferredTemperature: "Température préférée",
    rowSpacing: "Espacement entre les rangs",
    plantSpacing: "Espacement entre les plants",
    plantingDepth: "Profondeur de plantation",
  },
}
