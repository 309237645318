import { useState, useEffect, useRef, useMemo } from "react"
import { useTranslation } from "react-i18next"
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts"
import { type DataPoint } from "@/features/bees/types/hive-scales.ts"

const ANIMATION_INTERVAL = 50
const DATA_POINTS = 12
const BASE_VALUE = 25
const Y_DOMAIN: [number, number] = [20, 30]

const colors = {
  stroke: "#525252",
  gradientStart: `rgba(82, 82, 82, 0.88)`,
  gradientEnd: `rgba(82, 82, 82, 0.1)`,
  grid: "rgba(82, 82, 82, 0.05)",
} as const

const generateData = (offset: number): DataPoint[] =>
  Array.from({ length: DATA_POINTS }, (_, i) => ({
    value: BASE_VALUE + Math.sin(i / 2 + offset),
    timestamp: new Date().toISOString(),
  }))

export const HiveScaleChartSkeleton = () => {
  const [data, setData] = useState<DataPoint[]>([])
  const animationOffset = useRef(0)
  const { t } = useTranslation()

  const chartMargin = useMemo(
    () => ({ top: 0, right: 0, left: 0, bottom: 0 }),
    [],
  )
  const areaStyle = useMemo(() => ({ filter: "opacity(0.3)" }), [])

  useEffect(() => {
    const animate = () => {
      animationOffset.current += 0.1
      setData(generateData(animationOffset.current))
    }

    const interval = setInterval(animate, ANIMATION_INTERVAL)
    setData(generateData(animationOffset.current))

    return () => clearInterval(interval)
  }, [])

  return (
    <div className="relative h-full w-full">
      <div className="absolute inset-0 flex items-center justify-center">
        <span className="text-sm text-muted-foreground">{t("loading")}...</span>
      </div>

      <ResponsiveContainer width="100%" height="100%">
        <AreaChart data={data} margin={chartMargin}>
          <CartesianGrid stroke={colors.grid} opacity={0.5} />
          <XAxis dataKey="timestamp" tick={false} />
          <YAxis tick={false} mirror domain={Y_DOMAIN} />
          <defs>
            <linearGradient id="loading" x1="0" y1="0" x2="0" y2="1">
              <stop
                offset="5%"
                stopColor={colors.gradientStart}
                stopOpacity={0.8}
              />
              <stop
                offset="95%"
                stopColor={colors.gradientEnd}
                stopOpacity={0.1}
              />
            </linearGradient>
          </defs>
          <Area
            style={areaStyle}
            type="monotone"
            dataKey="value"
            stroke={colors.stroke}
            fill="url(#loading)"
            fillOpacity={0.2}
            strokeOpacity={0.5}
            isAnimationActive={false}
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  )
}

export default HiveScaleChartSkeleton
