import { differenceInCalendarDays, format } from "date-fns"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import ParcelIcon from "@/assets/svg/parcel-icon.svg?react"
import MultiStepProgressBar from "@/components/multi-step-progress-bar/multi-step-progress-bar.tsx"
import Skeleton from "@/components/skeleton/skeleton.tsx"
import { type CropPlanting } from "@/features/crops/types/crop-planting.ts"
import { CROP_PLANTING_VIEW_PATH } from "@/utils/constants/routes.ts"

interface Props {
  cropPlanting: CropPlanting
}

export const CropPlantingCard: React.FC<Props> = ({ cropPlanting }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const handleCardClick = () => {
    navigate(
      CROP_PLANTING_VIEW_PATH.replace(":cropPlantingId", cropPlanting.id),
    )
  }

  const differenceInDaysFromToday = differenceInCalendarDays(
    new Date(cropPlanting.seeding_date),
    new Date(),
  )

  const differenceInDaysSinceSeeding = differenceInCalendarDays(
    new Date(cropPlanting.seeding_date),
    new Date(cropPlanting.planned_harvest_date),
  )

  return (
    <div
      className={"widget-border flex flex-col gap-3 bg-white p-4"}
      onClick={handleCardClick}
    >
      <div className={"flex gap-3"}>
        {cropPlanting.crop_picture ? (
          <img
            src={cropPlanting.crop_picture}
            alt=""
            className={"widget-border aspect-square h-[70px]"}
          />
        ) : (
          <Skeleton className={"widget-border aspect-square h-[70px]"} />
        )}
        <div className={"flex flex-col justify-between"}>
          <p className={"text-base font-semibold text-neutral-700"}>
            {t(cropPlanting.crop_name)}
          </p>
          <div
            className={
              "flex w-fit items-center gap-3 rounded-sm border border-brown-500 p-1.5 px-4 font-medium"
            }
          >
            <ParcelIcon className={"h-4 w-4 text-brown-600"} />
            <p className={"truncate text-brown-600"}>
              {cropPlanting.parcel_name}
            </p>
          </div>
        </div>
      </div>
      <MultiStepProgressBar
        steps={differenceInDaysSinceSeeding}
        currentStep={differenceInDaysFromToday}
        colorScheme={"brown"}
      />
      <div>
        <table className="w-full border-separate border-spacing-0 overflow-hidden rounded-b-[6px] border border-gray-300">
          <tbody>
            <tr>
              <td className="border-b border-r border-gray-300 bg-gray-50 px-4 py-2">
                {t("seedingDate")}
              </td>
              <td className="border-b border-gray-300 px-4 py-2 font-medium">
                {format(cropPlanting.seeding_date, "dd.MM.yyyy")}
              </td>
            </tr>
            <tr>
              <td className="border-b border-r border-gray-300 bg-gray-50 px-4 py-2">
                {t("plannedHarvestDate")}
              </td>
              <td className="border-b border-gray-300 px-4 py-2 font-medium">
                {cropPlanting.planned_harvest_date
                  ? format(cropPlanting.planned_harvest_date, "dd.MM.yyyy")
                  : "-"}
              </td>
            </tr>
            <tr>
              <td className="border-b border-r border-gray-300 bg-gray-50 px-4 py-2">
                {t("daysUntilHarvest")}
              </td>
              <td className="border-b border-gray-300 px-4 py-2 font-medium">
                {differenceInDaysFromToday - differenceInDaysSinceSeeding}
              </td>
            </tr>
            <tr>
              <td className="rounded-bl-lg border-r border-gray-300 bg-gray-50 px-4 py-2">
                {t("daysSinceSeeding")}
              </td>
              <td className="rounded-br-lg px-4 py-2 font-medium">
                {-differenceInDaysFromToday}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}
