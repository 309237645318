import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import useModal from "@/features/modals/hooks/use-modal.ts"
import { cn } from "@/utils/styles.ts"

interface IInputModalProps {
  title: string
  content?: string
  onBackText?: string
  onBackClick?: () => void
  onActionText?: string
  onActionClick: (inputValue: string) => void
  inputProps?: {
    placeholder?: string
    type?: string
    required?: boolean
    label?: string
    labelClassName?: string
  }
}

const InputModal: React.FC<IInputModalProps> = ({
  onActionClick,
  title,
  content,
  onActionText,
  onBackText,
  onBackClick,
  inputProps = {
    placeholder: "",
    type: "text",
    required: false,
    label: "",
    labelClassName: "",
  },
}) => {
  const { t } = useTranslation()
  const { closeModal } = useModal()
  const [inputValue, setInputValue] = useState("")
  const [error, setError] = useState("")

  const handleActionClick = () => {
    if (inputProps.required && !inputValue) {
      setError("This field is required")
      return
    }

    closeModal()
    onActionClick(inputValue)
  }

  const getActionText = () => {
    return onActionText || t("confirm")
  }

  const getBackText = () => {
    return onBackText || t("back")
  }

  const handleBackClick = () => {
    if (onBackClick) {
      const result = onBackClick()
      closeModal()
      return result
    } else {
      return closeModal()
    }
  }

  const handleOutsideClick = (e: React.MouseEvent) => {
    e.stopPropagation()
    closeModal()
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value)
    if (error) setError("")
  }

  return (
    <div
      className="pointer-events-auto fixed inset-0 z-50 bg-[rgba(3,3,15,0.4)]"
      onClick={handleOutsideClick}
    >
      <div
        className="absolute left-1/2 top-1/2 w-[calc(100vw-100px)] -translate-x-1/2 -translate-y-1/2 transform rounded-xl bg-white shadow-md"
        onClick={(e) => {
          e.stopPropagation()
        }}
      >
        <div className="mb-4 mt-8 px-5 text-center text-lg font-semibold">
          {title}
        </div>

        {content && (
          <div className="mb-8 px-5 text-center text-sm">{content}</div>
        )}

        <div className="mb-6 px-5">
          {inputProps.label && (
            <label
              className={cn(
                "mb-1 block text-sm font-medium text-gray-700",
                inputProps.labelClassName,
              )}
            >
              {inputProps.label}
            </label>
          )}
          <input
            type={inputProps.type || "text"}
            placeholder={inputProps.placeholder || ""}
            value={inputValue}
            onChange={handleInputChange}
            className={`w-full rounded-lg border p-3 ${
              error ? "border-red-500" : "border-gray-300"
            } focus:outline-none focus:ring-1 focus:ring-brown-500`}
          />
          {error && <p className="mt-1 text-xs text-red-500">{error}</p>}
        </div>

        <div className="mt-5 flex w-full flex-row">
          <button
            className="box-border flex h-[70px] w-full items-center justify-center border-t border-[rgba(112,128,144,0.51)] text-base font-semibold text-[#7c7c7c]"
            onClick={handleBackClick}
          >
            {getBackText()}
          </button>
          <button
            className="box-border flex h-[70px] w-full items-center justify-center border-l border-t border-[rgba(112,128,144,0.51)] text-center text-base font-semibold text-[var(--brown)]"
            onClick={handleActionClick}
          >
            {getActionText()}
          </button>
        </div>
      </div>
    </div>
  )
}

export default InputModal
