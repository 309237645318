import React from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { AddHiveIcon } from "@/assets/widgets/add-hive-icon.tsx"
import { ScrollableApiaryHiveList } from "@/features/bees/components/apiaries/apiary-widget/scrollable-apiary-hive-list.tsx"
import { type ApiaryResponse } from "@/features/bees/types/apiaries.ts"
import EmptyContentCreateItemButton from "@/features/home/components/empty-content-create-item-button/empty-content-create-item-button.tsx"
import WidgetHeader from "@/features/home/components/widget-header/widget-header.tsx"
import {
  ALL_HIVES_PATH,
  APIARIES_SCREEN_PATH,
} from "@/utils/constants/routes.ts"

interface Props {
  apiary: ApiaryResponse
}

const ApiaryHivesWidget: React.FC<Props> = ({ apiary }) => {
  const { t } = useTranslation()
  const newHiveForApiaryNavigationString = `${APIARIES_SCREEN_PATH}/${apiary.id}${ALL_HIVES_PATH}/new/`
  const navigate = useNavigate()
  const allHivesForApiary = ALL_HIVES_PATH + `/?apiary=${apiary.id}`

  return (
    <div
      className={
        "widget-border box-border flex w-full flex-col gap-5 bg-white px-4 py-4"
      }
    >
      <WidgetHeader
        navigateTo={allHivesForApiary}
        title={t("myHives")}
        icon={<AddHiveIcon />}
        addItemGoTo={
          apiary.hives.length > 0
            ? () => navigate(newHiveForApiaryNavigationString)
            : undefined
        }
      />
      {apiary.hives.length > 0 ? (
        <ScrollableApiaryHiveList hives={apiary.hives} apiaryId={apiary.id} />
      ) : (
        <EmptyContentCreateItemButton
          navigateTo={newHiveForApiaryNavigationString}
        />
      )}{" "}
    </div>
  )
}

export default ApiaryHivesWidget
