import { useTranslation } from "react-i18next"
import {
  SUPPORTED_LANGUAGES,
  type SupportedLanguageCode,
} from "@/features/settings-account/utils/language-options.ts"
import { type Option } from "@/utils/types/misc-types"

export const useLanguage = () => {
  const { t } = useTranslation()

  const getLanguageString = (languageCode: SupportedLanguageCode) => {
    const language = SUPPORTED_LANGUAGES[languageCode]
    return language ? `${language.flag} ${t(language.nameKey)}` : languageCode
  }

  const getLanguageOptions = (): Option[] => {
    return Object.values(SUPPORTED_LANGUAGES).map((lang) => ({
      value: lang.value,
      label: getLanguageString(lang.value as SupportedLanguageCode),
    }))
  }

  return {
    getLanguageString,
    getLanguageOptions,
    supportedLanguages: SUPPORTED_LANGUAGES,
  }
}
