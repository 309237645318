import { apiSlice, TagType } from "@/features/api/apiSlice.ts"
import {
  type Parcel,
  type ParcelCreate,
  type ParcelUpdate,
} from "@/features/parcels/types/parcel.ts"

const PARCELS_API_BASE_URL = "/parcels/"

export const parcelsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getParcels: builder.query<Parcel[], void>({
      query: () => PARCELS_API_BASE_URL,
      providesTags: [{ type: TagType.Parcels, id: "LIST" }],
    }),

    getParcelById: builder.query<Parcel, string>({
      query: (id) => PARCELS_API_BASE_URL + `${id}/`,
      providesTags: (_result, _error, id) => [{ type: TagType.Parcels, id }],
    }),

    createParcel: builder.mutation<Parcel, ParcelCreate>({
      query: (parcel) => ({
        url: PARCELS_API_BASE_URL,
        method: "POST",
        body: parcel,
      }),
      invalidatesTags: [{ type: TagType.Parcels, id: "LIST" }],
    }),

    updateParcel: builder.mutation<Parcel, ParcelUpdate>({
      query: (parcel) => ({
        url: PARCELS_API_BASE_URL + `${parcel.id}/`,
        method: "PATCH",
        body: parcel,
      }),
      invalidatesTags: (_result, _error, field) => [
        { type: TagType.Parcels, id: field.id },
        { type: TagType.Parcels, id: "LIST" },
      ],
    }),

    deleteParcel: builder.mutation<void, string>({
      query: (id) => ({
        url: PARCELS_API_BASE_URL + `${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: (_result, _error, id) => [
        { type: TagType.Parcels, id },
        { type: TagType.Parcels, id: "LIST" },
      ],
    }),
  }),
})

export const {
  useGetParcelsQuery,
  useGetParcelByIdQuery,
  useCreateParcelMutation,
  useUpdateParcelMutation,
  useDeleteParcelMutation,
} = parcelsApi
