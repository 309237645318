import { Source, Layer, Marker, useMap } from "@vis.gl/react-maplibre"
import {
  type Feature,
  type Polygon,
  type LineString,
  type Point,
  type GeoJsonProperties,
} from "geojson"
import maplibregl from "maplibre-gl"
import React, { useEffect, useMemo } from "react"
import { MapPinNumber } from "@/components/maps-components/map-pin-number.tsx"

interface FieldCoordinate {
  latitude: number
  longitude: number
}

interface FieldPolygonProps {
  coordinates: FieldCoordinate[]
  showMarkers?: boolean
  polygonColor?: string
  polygonOpacity?: number
  outlineColor?: string
  outlineWidth?: number
}

export const FieldPolygon: React.FC<FieldPolygonProps> = ({
  coordinates,
  showMarkers = true,
  polygonColor = "#3B82F6",
  polygonOpacity = 0.4,
  outlineColor = "#1D4ED8",
  outlineWidth = 2,
}) => {
  const { current: map } = useMap()

  const geojsonData = useMemo(() => {
    if (!coordinates || coordinates.length === 0) {
      return null
    }

    const geoCoordinates = coordinates.map((coord) => [
      coord.longitude,
      coord.latitude,
    ])

    if (coordinates.length === 1) {
      const pointFeature: Feature<Point, GeoJsonProperties> = {
        type: "Feature",
        properties: {},
        geometry: {
          type: "Point",
          coordinates: geoCoordinates[0],
        },
      }
      return pointFeature
    } else if (coordinates.length === 2) {
      const lineFeature: Feature<LineString, GeoJsonProperties> = {
        type: "Feature",
        properties: {},
        geometry: {
          type: "LineString",
          coordinates: geoCoordinates,
        },
      }
      return lineFeature
    } else {
      // Polygon (3 or more points)
      // Close the polygon by adding the first point at the end
      const polygonCoordinates = [...geoCoordinates, geoCoordinates[0]]

      const polygonFeature: Feature<Polygon, GeoJsonProperties> = {
        type: "Feature",
        properties: {},
        geometry: {
          type: "Polygon",
          coordinates: [polygonCoordinates],
        },
      }
      return polygonFeature
    }
  }, [coordinates])

  useEffect(() => {
    if (map && coordinates && coordinates.length > 0) {
      try {
        if (coordinates.length === 1) {
          map.flyTo({
            center: [coordinates[0].longitude, coordinates[0].latitude],
            zoom: 15,
            essential: true,
          })
        } else {
          const bounds = new maplibregl.LngLatBounds()
          coordinates.forEach((coord) => {
            bounds.extend([coord.longitude, coord.latitude])
          })

          map.fitBounds(bounds, {
            padding: 50,
            maxZoom: 17,
          })
        }
      } catch (error) {
        console.error("Error adjusting map view:", error)
      }
    }
  }, [map, coordinates])
  if (!geojsonData) {
    return null
  }

  const renderLayers = () => {
    if (coordinates.length === 1) {
      return null
    }

    if (coordinates.length === 2) {
      return (
        <Source id="field-feature" type="geojson" data={geojsonData}>
          <Layer
            id="field-line"
            type="line"
            paint={{
              "line-color": outlineColor,
              "line-width": outlineWidth,
            }}
          />
        </Source>
      )
    }

    if (coordinates.length > 2) {
      return (
        <Source id="field-feature" type="geojson" data={geojsonData}>
          <Layer
            id="field-polygon-fill"
            type="fill"
            paint={{
              "fill-color": polygonColor,
              "fill-opacity": polygonOpacity,
            }}
          />
          <Layer
            id="field-polygon-outline"
            type="line"
            paint={{
              "line-color": outlineColor,
              "line-width": outlineWidth,
            }}
          />
        </Source>
      )
    }
  }

  return (
    <>
      {renderLayers()}

      {showMarkers &&
        coordinates.map((coord, index) => (
          <Marker
            key={`marker-${index}`}
            longitude={coord.longitude}
            latitude={coord.latitude}
          >
            <MapPinNumber number={index + 1} anchor />
          </Marker>
        ))}
    </>
  )
}

export default FieldPolygon
