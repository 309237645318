import React from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { AddEventIcon } from "@/assets/widgets/add-event-icon"
import { EventTable } from "@/features/events/common/components/events-table/events-table.tsx"
import { APIARY_QUERY_PARAMS } from "@/features/events/common/types/apiary-querry-params.ts"
import { useGetAllHiveEventsQuery } from "@/features/events/hive-events/api/hive-events-api.ts"
import { APIARY_EVENTS_FILTER_ENUM } from "@/features/events/hive-events/hooks/use-filter-hive-events.ts"
import EmptyContentCreateItemButton from "@/features/home/components/empty-content-create-item-button/empty-content-create-item-button.tsx"
import WidgetCard from "@/features/home/components/widget-card/widget-card.tsx"
import {
  HIVE_EVENTS_ROUTE,
  NEW_HIVE_EVENT_ROUTE,
} from "@/utils/constants/routes.ts"

interface Props {
  hiveId: string
}

export const HiveEventsWidget: React.FC<Props> = ({ hiveId }) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { data: events } = useGetAllHiveEventsQuery({ hive_id: hiveId })
  const workingEvents = events?.slice(0, 10)
  const allEventsForHiveNavigationString =
    HIVE_EVENTS_ROUTE + `?${APIARY_EVENTS_FILTER_ENUM.HIVE_ID}=${hiveId}`
  const addEventNavigationString =
    NEW_HIVE_EVENT_ROUTE +
    (hiveId ? `?${APIARY_QUERY_PARAMS.HIVE_ID}=${hiveId}` : "")
  const addItemGoTo = () => {
    navigate(addEventNavigationString)
  }
  return (
    <WidgetCard
      icon={<AddEventIcon />}
      title={"events"}
      navigateTo={allEventsForHiveNavigationString}
      {...(workingEvents && workingEvents.length > 0 && { addItemGoTo })}
    >
      {workingEvents && workingEvents.length > 0 && (
        <>
          {events && events?.length > workingEvents.length && (
            <p>
              {t("lastEvents", {
                count: workingEvents.length,
                total: events?.length,
              })}{" "}
            </p>
          )}
          <EventTable events={workingEvents} />
        </>
      )}
      {!workingEvents ||
        (workingEvents.length === 0 && (
          <EmptyContentCreateItemButton navigateTo={addEventNavigationString} />
        ))}
    </WidgetCard>
  )
}
