import {
  isSameDay,
  isSameMonth,
  isSameWeek,
  isSameYear,
  parseISO,
  subMonths,
  subYears,
} from "date-fns"
import {
  type AssistantThread,
  type AssistantThreadSummary,
} from "@/features/assistant/types/assistant.ts"
import { type GenericAnimalEvent } from "@/features/events/animal-events/types/animal-events.ts"
import { type ApiaryEvent } from "@/features/events/apiary-events/types/apiary-event.ts"
import { type BirdsGroupsEvent } from "@/features/events/birds-groups-events/types/birds-groups-events.ts"
import { type HiveEvent } from "@/features/events/hive-events/types/hive-event.ts"
import { type ParcelEvent } from "@/features/events/parcel-events/types/parcel-events.ts"
import { type Transaction } from "@/features/finance/types/transaction.ts"
import { type Note } from "@/features/notes/types/note.ts"
import { type Notification } from "@/features/notifications/types/notifications.ts"
import { type Task } from "@/features/tasks"

export const initialItemsTimeGroups: ItemsTimeGroups = {
  todayItems: [],
  thisWeekItems: [],
  thisMonthItems: [],
  pastMonthItems: [],
  thisYearItems: [],
  pastYearItems: [],
  pastYearsItems: [],
}

export type ItemsTimeGroups = {
  todayItems: ItemsArray
  thisWeekItems: ItemsArray
  thisMonthItems: ItemsArray
  pastMonthItems: ItemsArray
  thisYearItems: ItemsArray
  pastYearItems: ItemsArray
  pastYearsItems: ItemsArray
}

type ItemTypes =
  | Note
  | Notification
  | Task
  | GenericAnimalEvent
  | Transaction
  | ApiaryEvent
  | HiveEvent
  | AssistantThread
  | AssistantThreadSummary
  | BirdsGroupsEvent
  | ParcelEvent

export type ItemsArray = ItemTypes[]

export function groupItemsByTimeStampWithField<T extends ItemTypes>(
  items: T[],
  field: keyof T,
): ItemsTimeGroups {
  const initialArray: ItemsTimeGroups = {
    todayItems: [],
    thisWeekItems: [],
    thisMonthItems: [],
    pastMonthItems: [],
    thisYearItems: [],
    pastYearItems: [],
    pastYearsItems: [],
  }

  const currentDate = new Date()
  currentDate.setHours(0, 0, 0, 0)

  items.forEach((item) => {
    if (!item.hasOwnProperty(field)) {
      return
    }

    const itemDate = item[field]
      ? parseISO(item[field] as unknown as string)
      : new Date()

    if (isNaN(itemDate.getTime())) {
      return
    }

    if (isSameDay(itemDate, currentDate)) {
      initialArray.todayItems.push(item)
    } else if (
      isSameWeek(itemDate, currentDate, {
        weekStartsOn: 1,
      })
    ) {
      initialArray.thisWeekItems.push(item)
    } else if (isSameMonth(itemDate, currentDate)) {
      initialArray.thisMonthItems.push(item)
    } else if (isSameMonth(itemDate, subMonths(currentDate, 1))) {
      initialArray.pastMonthItems.push(item)
    } else if (isSameYear(itemDate, currentDate)) {
      initialArray.thisYearItems.push(item)
    } else if (isSameYear(itemDate, subYears(currentDate, 1))) {
      initialArray.pastYearItems.push(item)
    } else {
      initialArray.pastYearsItems.push(item)
    }
  })

  return initialArray
}
