import React from "react"
import { useTranslation } from "react-i18next"
import { useParams, useSearchParams } from "react-router-dom"
import UserIcon from "@/assets/icons/account/user-icon.tsx"
import DetailIcon from "@/assets/icons/farm/details-icon.tsx"
import NoteThumbnailIcon from "@/assets/icons/notes/note-thumbnail-icon.tsx"
import FieldIcon from "@/assets/svg/parcel-icon.svg?react"
import GenericDatePicker from "@/components/generic-date-picker/generic-date-picker.tsx"
import { SelectionRowDrawer } from "@/components/selection-row-drawer/selection-row-drawer.tsx"
import OverflowMenuItem from "@/components/task-card/overflow-menu-item.tsx"
import InputFieldWrapperWithIcon from "@/components/text-card-wrapper-with-icon/input-field-wrapper-with-icon.tsx"
import TextAreaFieldWrapperWithIcon from "@/components/text-card-wrapper-with-icon/text-area-field-wrapper-with-icon.tsx"
import { EventTypeDrawer } from "@/features/events/common/components/event-type-drawer/event-type-drawer.tsx"
import { useGetAllParcelEventTypesQuery } from "@/features/events/parcel-events/api/parcel-event-types-api.ts"
import { type ParcelEventInput } from "@/features/events/parcel-events/types/parcel-events.ts"
import RequiredIndicator from "@/features/farm/components/required-indicator/required-indicator.tsx"
import { useGetParcelsQuery } from "@/features/parcels/api/parcels-api.ts"

interface Props {
  parcelEvent: ParcelEventInput
  onParcelEventChange: (parcelEvent: ParcelEventInput) => void
}

export const ParcelEventEditor: React.FC<Props> = ({
  parcelEvent,
  onParcelEventChange,
}) => {
  const { t } = useTranslation()
  const { data: parcels = [] } = useGetParcelsQuery()
  const { data: parcelEventTypes } = useGetAllParcelEventTypesQuery()
  const [searchParams] = useSearchParams()
  const parcelId = searchParams.get("parcelId")
  const { parcelEventId } = useParams()

  const parcelOptions =
    (parcels &&
      parcels.map((parcel) => ({
        value: parcel.id,
        label: parcel.name,
      }))) ||
    []

  const selectedParcel = parcels.find(
    (parcel) => parcel.id === parcelEvent.parcel,
  )

  const handleParcelSelection = (parcelId: string) => {
    onParcelEventChange({
      ...parcelEvent,
      parcel: parcelId,
    })
  }

  const handleDateChange = (date: string) => {
    onParcelEventChange({
      ...parcelEvent,
      date,
    })
  }

  const handlePersonNameChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    onParcelEventChange({
      ...parcelEvent,
      person_name: event.target.value,
    })
  }

  const handleDescriptionChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    onParcelEventChange({
      ...parcelEvent,
      description: event.target.value,
    })
  }

  const handleNotesChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    onParcelEventChange({ ...parcelEvent, notes: event.target.value })
  }

  const getDescription = () => {
    if (!parcelEvent.type) return null
    const selectedEventType = parcelEventTypes?.find(
      (eventType) => eventType.id === parcelEvent.type,
    )
    return t(selectedEventType?.description || "")
  }

  return (
    <div className={"flex flex-col gap-[10px]"}>
      <div className={"mt-2 text-[14px] text-neutral-400"}>
        {t("generalEventDetails")}
      </div>
      <OverflowMenuItem isDisabled={!!parcelId || !!parcelEventId}>
        <SelectionRowDrawer
          title={t("selectParcel")}
          multiple={false}
          selectedValues={parcelEvent.parcel}
          options={parcelOptions}
          onSelectionChange={handleParcelSelection}
          renderTrigger={
            <div className={"flex items-center gap-[10px]"}>
              <FieldIcon className="h-[30px] w-[30px] rounded bg-brown-500 p-1 text-white" />
              {!parcelEvent.parcel && <RequiredIndicator />}
              {parcelEvent.parcel ? (
                <p className={"text-brown-500"}>{selectedParcel?.name}</p>
              ) : (
                <p className={"text-[14px]"}>{t("selectParcel")}</p>
              )}
            </div>
          }
        />
      </OverflowMenuItem>
      <OverflowMenuItem
        isLoading={!parcelEventTypes}
        isDisabled={!!parcelEventId}
      >
        {parcelEventTypes && (
          <EventTypeDrawer
            selectedValue={parcelEvent.type}
            eventTypes={parcelEventTypes}
            onEventTypeChange={(eventType) =>
              onParcelEventChange({ ...parcelEvent, type: eventType })
            }
          />
        )}
      </OverflowMenuItem>
      {parcelEvent.type && (
        <OverflowMenuItem className={"text-[14px] text-neutral-500"}>
          <i>{t("eventInfoDescription")}</i>
          {getDescription()}
        </OverflowMenuItem>
      )}
      <GenericDatePicker
        isDisabled={!!parcelEventId}
        value={parcelEvent.date}
        onChange={handleDateChange}
      />
      <InputFieldWrapperWithIcon
        icon={<UserIcon size={30} />}
        align={"row"}
        name={t("personName")}
        isDisabled={false}
        value={parcelEvent.person_name}
        onChange={handlePersonNameChange}
      />
      <div className={"mt-2 text-[14px] text-neutral-400"}>
        {t("description")}
      </div>
      <TextAreaFieldWrapperWithIcon
        icon={<DetailIcon />}
        name={t("details")}
        isReadOnly={false}
        value={parcelEvent.description}
        onChange={handleDescriptionChange}
      />
      <TextAreaFieldWrapperWithIcon
        icon={<NoteThumbnailIcon size={30} />}
        name={t("otherObservations")}
        isReadOnly={false}
        value={parcelEvent.notes}
        onChange={handleNotesChange}
      />
    </div>
  )
}
