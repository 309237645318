import React, { useState, useEffect, type FormEvent } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams, useLocation } from "react-router-dom"
import LogoBrown from "@/assets/icons/logos/logo-brown.tsx"
import { Button } from "@/components/shadcn/button.tsx"
import { PasswordInput } from "@/components/shadcn/input.tsx"
import { usePasswordResetConfirmMutation } from "@/features/settings-account/api/account-api.ts"
import { LOGIN_SCREEN_PATH } from "@/utils/constants/routes.ts"

const PasswordResetConfirm: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { key } = useParams<{ key: string }>()
  const location = useLocation()
  const [password1, setPassword1] = useState("")
  const [password2, setPassword2] = useState("")
  const [resetSuccess, setResetSuccess] = useState(false)
  const [passwordResetConfirm, { isLoading }] =
    usePasswordResetConfirmMutation()
  const [error, setError] = useState("")

  // Extract key from URL if not in params
  useEffect(() => {
    if (!key) {
      const searchParams = new URLSearchParams(location.search)
      const keyFromQuery = searchParams.get("key")
      if (!keyFromQuery) {
        setError(t("invalidResetLink"))
      }
    }
  }, [key, location.search, t])

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault()

    const resetKey = key || new URLSearchParams(location.search).get("key")

    if (!resetKey) {
      setError(t("invalidResetLink"))
      return
    }

    if (password1 !== password2) {
      setError(t("passwordsDoNotMatch"))
      return
    }

    if (password1.length < 8) {
      setError(t("passwordTooShort"))
      return
    }

    try {
      await passwordResetConfirm({
        key: resetKey,
        password1,
        password2,
      }).unwrap()

      setResetSuccess(true)
      setError("")
    } catch (err: any) {
      console.log("Password reset error:", err) // Debug the error structure

      // Handle different possible 401 success response structures
      if (err.status === 401) {
        setResetSuccess(true)
        setError("")
        return
      }

      if (err.data && typeof err.data === "object") {
        const fieldErrors = []
        for (const field in err.data) {
          if (Array.isArray(err.data[field])) {
            fieldErrors.push(err.data[field].join(" "))
          }
        }

        if (fieldErrors.length > 0) {
          setError(fieldErrors.join(". "))
        } else if (err.data.errors && Array.isArray(err.data.errors)) {
          const messages = err.data.errors.map((e: any) => e.message).join(". ")
          setError(messages)
        } else {
          setError(t("failedToResetPassword"))
        }
      } else {
        setError(t("failedToResetPassword"))
      }
    }
  }

  // Add automatic navigation on successful reset
  useEffect(() => {
    if (resetSuccess) {
      // Automatically navigate to login after 3 seconds
      const timer = setTimeout(() => navigate(LOGIN_SCREEN_PATH), 2000)
      return () => clearTimeout(timer)
    }
  }, [resetSuccess, navigate])

  return (
    <div className={"flex h-full w-full flex-col items-center justify-center"}>
      <div
        className={
          "flex h-[calc(100vh-80px-env(safe-area-inset-bottom))] w-[300px] flex-col items-center justify-center gap-6"
        }
      >
        <LogoBrown width={150} height={150} />

        {resetSuccess ? (
          <div className={"flex flex-col items-center justify-center gap-4"}>
            <h1 className={"text-body-xl font-medium"}>
              {t("passwordResetSuccessful")}
            </h1>
            <h5
              className={"text-center text-base font-semibold text-neutral-600"}
            >
              {t("yourPasswordHasBeenReset")}
            </h5>
            <Button
              className={"w-full"}
              size={"lg"}
              onClick={() => navigate(LOGIN_SCREEN_PATH)}
              data-testid="proceed-to-login-button"
            >
              {t("proceedToLogin")}
            </Button>
          </div>
        ) : (
          <>
            <div className={"flex flex-col items-center justify-center gap-4"}>
              <h1 className={"text-body-xl font-medium"}>
                {t("resetYourPassword")}
              </h1>
              <h5
                className={
                  "text-center text-base font-semibold text-neutral-600"
                }
              >
                {t("enterNewPassword")}
              </h5>
            </div>
            <form
              onSubmit={handleSubmit}
              className={
                "flex w-full flex-col items-center justify-center gap-5 p-3"
              }
            >
              <div className={"w-full space-y-3.5"}>
                <PasswordInput
                  id="password1"
                  name="password1"
                  placeholder={t("newPassword") + "*"}
                  value={password1}
                  onChange={(e) => setPassword1(e.target.value)}
                  disabled={isLoading}
                  required
                  data-testid="reset-password-input"
                />
                <PasswordInput
                  id="password2"
                  name="password2"
                  placeholder={t("confirmNewPassword") + "*"}
                  value={password2}
                  onChange={(e) => setPassword2(e.target.value)}
                  disabled={isLoading}
                  required
                  data-testid="reset-confirm-password-input"
                />
              </div>
              {error && (
                <div
                  style={{ backgroundColor: "rgba(252, 165, 165, 0.5)" }}
                  className={
                    "mt-5 w-full rounded-lg p-4 text-center font-medium text-red-500"
                  }
                  data-testid="reset-error-message"
                >
                  {error}
                </div>
              )}
              <Button
                type="submit"
                className={"w-full"}
                size={"lg"}
                disabled={isLoading}
                data-testid="reset-password-button"
              >
                {isLoading ? t("resetting") : t("resetPassword")}
              </Button>
              <Button
                type="button"
                variant={"outline"}
                className={"w-full text-brown-500"}
                size={"lg"}
                onClick={() => navigate(LOGIN_SCREEN_PATH)}
                data-testid="back-to-login-button"
              >
                {t("backToLogin")}
              </Button>
            </form>
          </>
        )}
      </div>
    </div>
  )
}

export default PasswordResetConfirm
