import { useTranslation } from "react-i18next"
import DrawerContentWrapper from "@/components/drawer-content-wrapper/drawer-content-wrapper.tsx"
import GenericTypeDrawerHeader from "@/components/generic-type-drawer/generic-type-drawer-header/generic-type-drawer-header.tsx"
import NoResultsFound from "@/components/not-found/no-results-found.tsx"
import {
  Drawer,
  DrawerContent,
  DrawerTrigger,
} from "@/components/shadcn/drawer.tsx"
import { WidgetButton } from "@/components/widget-button/widget-button.tsx"

interface Props {
  title: string
  message?: string
}

export const NoHivesOrScalesAvailableDrawer: React.FC<Props> = ({
  title,
  message,
}) => {
  const { t } = useTranslation()

  return (
    <Drawer>
      <DrawerTrigger asChild>
        <WidgetButton isActive title={title} onClick={() => {}} />
      </DrawerTrigger>
      <DrawerContent className={"h-3/4"}>
        <DrawerContentWrapper>
          <GenericTypeDrawerHeader title={title || t("assocHiveScale")} />
          <NoResultsFound
            className={"h-[calc(100%-54px)]"}
            message={message || t("noHivesWithoutScales")}
            image={"hive"}
          />
        </DrawerContentWrapper>
      </DrawerContent>
    </Drawer>
  )
}
