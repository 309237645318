import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"
import { toast } from "react-toastify"
import EditIcon from "@/assets/icons/misc/edit-icon.tsx"
import { LoadingAnimation } from "@/components/loading-animation/loading-animation.tsx"
import FieldPolygon from "@/components/maps-components/polygon.tsx"
import PageContentWrapperLarge from "@/components/page-content-wrappers/page-content-wrapper-large.tsx"
import { Button } from "@/components/shadcn/button.tsx"
import MobileTopBar from "@/components/top-bars/mobile-top-bar/mobile-top-bar.tsx"
import MapFarmPlanner from "@/features/bees/components/map-widget/map-farm-planner.tsx"
import { ParcelEventsSummaryWidget } from "@/features/events/parcel-events/components/parcel-events-summary-widget/parcel-events-summary-widget.tsx"
import TasksWidget from "@/features/home/components/tasks-widget/tasks-widget.tsx"
import WidgetCard from "@/features/home/components/widget-card/widget-card.tsx"
import useModal from "@/features/modals/hooks/use-modal.ts"
import { MODALS } from "@/features/modals/types/modals.tsx"
import NotesWidget from "@/features/notes/components/notes-widget/notes-widget.tsx"
import {
  useDeleteParcelMutation,
  useGetParcelByIdQuery,
} from "@/features/parcels/api/parcels-api.ts"
import { CropPlantingsWidget } from "@/features/parcels/components/crop-plantings-widget/crop-plantings-widget.tsx"
import { formatArea } from "@/features/parcels/types/parcel-utils.ts"
import {
  EDIT_PARCEL_SCREEN_PATH,
  PARCELS_SCREEN_PATH,
} from "@/utils/constants/routes.ts"

const ParcelView = () => {
  const { t } = useTranslation()
  const params = useParams()
  const parcelId = params.parcelId
  const navigate = useNavigate()
  const { openModal } = useModal()

  const { data: parcel, isLoading } = useGetParcelByIdQuery(
    parcelId as string,
    {
      skip: !parcelId,
    },
  )
  const [deleteParcelMutation] = useDeleteParcelMutation()

  const handleDeleteParcel = () => {
    openModal(MODALS.BASE_MODAL, {
      title: t("deleteParcelConfirmation"),
      content: t("deleteParcelDescription"),
      onActionClick: deleteParcel,
    })
  }

  const deleteParcel = async () => {
    if (parcelId) {
      const promise = deleteParcelMutation(parcelId).unwrap()
      toast
        .promise(promise, {
          pending: t("deletingParcel"),
          success: t("parcelDeleted"),
          error: t("parcelDeletionError"),
        })
        .then(() => {
          navigate(PARCELS_SCREEN_PATH, { replace: true })
        })
    }
  }

  if (isLoading || !parcel) {
    return (
      <>
        <MobileTopBar title={t("parcelView")} />
        <PageContentWrapperLarge className={"space-y-4"}>
          <LoadingAnimation customStyle={{ height: "inherit" }} />
        </PageContentWrapperLarge>
      </>
    )
  }

  const coordinates = parcel.boundary_points.map((point) => ({
    latitude: point.latitude,
    longitude: point.longitude,
  }))

  const mapCenter = {
    latitude: parcel.center_latitude,
    longitude: parcel.center_longitude,
  }

  return (
    <>
      <MobileTopBar
        title={parcel.name || t("parcelView")}
        navigateTo={EDIT_PARCEL_SCREEN_PATH.replace(":parcelId", parcel.id)}
        icon={<EditIcon />}
      />
      <PageContentWrapperLarge className={"flex flex-col gap-4"}>
        <div className={"widget-border w-full space-y-4 bg-white p-4"}>
          <div className={"space-y-2.5"}>
            <h2 className="text-xl font-bold">{parcel.name}</h2>
            <p className="text-neutral-600">{parcel.description}</p>
          </div>

          <div className={"space-y-2.5"}>
            <p className="text-sm text-neutral-500">
              {t("area")}: {formatArea(parcel.area, 4)}
            </p>
          </div>
        </div>

        <WidgetCard title={t("parcelMap")}>
          <MapFarmPlanner
            latitude={mapCenter.latitude}
            longitude={mapCenter.longitude}
            pitch={0}
            bearing={0}
          >
            <FieldPolygon coordinates={coordinates} showMarkers={true} />
          </MapFarmPlanner>
        </WidgetCard>
        <NotesWidget notes={parcel.notes} parcelId={parcel.id} />
        <TasksWidget tasks={parcel.tasks} parcelId={parcel.id} />
        <ParcelEventsSummaryWidget parcelId={parcel.id} />
        <CropPlantingsWidget parcelId={parcel.id} />
        <Button
          size={"lg"}
          variant={"outline"}
          className={"w-full flex-shrink-0 border-neutral-200 text-red-500"}
          onClick={handleDeleteParcel}
        >
          <p>{t("deleteParcel")}</p>
        </Button>
      </PageContentWrapperLarge>
    </>
  )
}

export default ParcelView
