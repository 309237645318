import React from "react"
import { useTranslation } from "react-i18next"
import { useSearchParams } from "react-router-dom"
import UserIcon from "@/assets/icons/account/user-icon.tsx"
import HiveIcon from "@/assets/icons/bees/hive-icon.tsx"
import DetailIcon from "@/assets/icons/farm/details-icon.tsx"
import NoteThumbnailIcon from "@/assets/icons/notes/note-thumbnail-icon.tsx"
import GenericDatePicker from "@/components/generic-date-picker/generic-date-picker.tsx"
import { SelectionRowDrawer } from "@/components/selection-row-drawer/selection-row-drawer.tsx"
import { DrawerTrigger } from "@/components/shadcn/drawer.tsx"
import Skeleton from "@/components/skeleton/skeleton.tsx"
import OverflowMenuItem from "@/components/task-card/overflow-menu-item.tsx"
import InputFieldWrapperWithIcon from "@/components/text-card-wrapper-with-icon/input-field-wrapper-with-icon.tsx"
import TextAreaFieldWrapperWithIcon from "@/components/text-card-wrapper-with-icon/text-area-field-wrapper-with-icon.tsx"
import { useGetApiariesQuery } from "@/features/bees/api/apiaries-api.ts"
import { useGetAllHivesQuery } from "@/features/bees/api/hives-api.ts"
import EventsTypeDrawer from "@/features/events/animal-events/components/event-type-drawer/event-type-drawer.tsx"
import useMapEventTypesToParents from "@/features/events/animal-events/hooks/use-map-event-types-to-parents.ts"
import { type AnimalEventType } from "@/features/events/animal-events/types/animal-events.ts"
import { APIARY_QUERY_PARAMS } from "@/features/events/common/types/apiary-querry-params.ts"
import { useGetAllHiveEventTypesQuery } from "@/features/events/hive-events/api/hive-event-types-api.ts"
import { HiveEventEquipmentEditor } from "@/features/events/hive-events/components/hive-event-equipment-editor/hive-event-equipment-editor.tsx"
import { HiveEventMeasurementEditor } from "@/features/events/hive-events/components/hive-event-measurement-editor/hive-event-measurement-editor.tsx"
import { HiveEventMovementEditor } from "@/features/events/hive-events/components/hive-event-movment-editor/hive-event-movement-editor.tsx"
import HiveEventTreatmentEditor from "@/features/events/hive-events/components/hive-event-treatment-editor/hive-event-treatment-editor.tsx"
import useCheckHiveEventType from "@/features/events/hive-events/hooks/use-check-hive-event-type.ts"
import {
  HIVE_EVENT_TYPES_ENUM,
  HIVE_MOVEMENT_EVENTS_ENUM,
} from "@/features/events/hive-events/types/hive-events-constants.ts"
import RequiredIndicator from "@/features/farm/components/required-indicator/required-indicator.tsx"
import usePrefetchImages from "@/hooks/use-prefetch-images.ts"
import { useAppDispatch } from "@/redux/hooks.ts"
import {
  resetHiveEventSubData,
  setDraftHiveEventDate,
  setDraftHiveEventDescription,
  setDraftHiveEventHive,
  setDraftHiveEventNotes,
  setDraftHiveEventPersonName,
  setDraftHiveEventType,
  type HiveEventDraft,
} from "@/redux/slices/hive-events-draft-slice.ts"

interface Props {
  draftHiveEvent: HiveEventDraft
  eventId?: string
}

export const HiveEventEditor: React.FC<Props> = ({
  draftHiveEvent,
  eventId,
}) => {
  const { data: hives } = useGetAllHivesQuery()
  const { data: apiaries } = useGetApiariesQuery()
  const { data: hivesEventTypes } = useGetAllHiveEventTypesQuery()

  const getFilteredEventTypes = () => {
    if (apiaries && apiaries?.length < 2) {
      return hivesEventTypes?.filter(
        (eventType) =>
          eventType.name !== HIVE_MOVEMENT_EVENTS_ENUM.TransferToOtherApiary,
      )
    } else return hivesEventTypes
  }

  const { t } = useTranslation()
  const checkEventType = useCheckHiveEventType()
  const dispatch = useAppDispatch()
  const [searchParams] = useSearchParams()
  const hiveId = searchParams.get(APIARY_QUERY_PARAMS.HIVE_ID)
  const typeId = searchParams.get(APIARY_QUERY_PARAMS.TYPE_ID)
  const hiveOptions =
    (hives &&
      hives.map((hive) => ({
        value: hive.id,
        label: hive.code,
      }))) ||
    []
  const imagesArray = hivesEventTypes
    ?.map((eventType) => eventType.picture)
    .filter(Boolean)
  usePrefetchImages(imagesArray)

  const eventTypesMap = getFilteredEventTypes()?.reduce<
    Record<string, AnimalEventType>
  >((acc, eventType) => {
    return { ...acc, [eventType.id]: eventType as AnimalEventType }
  }, {})

  const parentEventTypes = useMapEventTypesToParents(eventTypesMap)

  const getAssociatedHiveEventTypeDescription = () => {
    if (!hivesEventTypes) return ""
    const selectedEventType = hivesEventTypes.find(
      (a) => a.id == draftHiveEvent.type,
    )
    return t(selectedEventType?.description ?? "")
  }

  const getAssociatedHiveInfo = () => {
    if (!hives) return ""
    const selectedHive = hives.find((a) => a.id === draftHiveEvent.hive)
    return selectedHive?.code ?? t("noHiveSelected")
  }

  const handleHiveChange = (hiveId: string) => {
    dispatch(setDraftHiveEventHive(hiveId))
  }

  const handleEventTypeChange = (eventType: string | undefined) => {
    if (!eventType) return
    dispatch(setDraftHiveEventType(eventType))
    dispatch(resetHiveEventSubData())
  }

  const handlePersonNameChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    dispatch(setDraftHiveEventPersonName(event.target.value))
  }

  const handleDescriptionChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    dispatch(setDraftHiveEventDescription(event.target.value))
  }

  const handleNotesChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    dispatch(setDraftHiveEventNotes(event.target.value))
  }

  const handleDateChange = (date: string) => {
    dispatch(setDraftHiveEventDate(date))
  }

  const isTreatmentEvent = checkEventType(
    draftHiveEvent.type,
    HIVE_EVENT_TYPES_ENUM.HiveTreatment,
  )

  const isMeasurementEvent = checkEventType(
    draftHiveEvent.type,
    HIVE_EVENT_TYPES_ENUM.HiveMeasurement,
  )

  const isEquipmentEvent = checkEventType(
    draftHiveEvent.type,
    HIVE_EVENT_TYPES_ENUM.HiveEquipment,
  )

  const isMovementEvent = checkEventType(
    draftHiveEvent.type,
    HIVE_EVENT_TYPES_ENUM.HiveMovement,
  )

  return (
    <div className={"flex flex-col gap-[10px]"}>
      <div className={"mt-2 text-[14px] text-neutral-400"}>
        {t("generalEventDetails")}
      </div>
      <OverflowMenuItem isDisabled={!!hiveId || !!eventId}>
        <SelectionRowDrawer
          multiple={false}
          renderTrigger={
            <DrawerTrigger>
              <div className={"grid grid-cols-[30px_auto] items-center gap-2"}>
                <div
                  className={
                    "grid h-[30px] w-[30px] place-content-center rounded-[4px] bg-blue-300 text-white"
                  }
                >
                  <HiveIcon className={"scale-150"} />
                  {!draftHiveEvent.hive && (
                    <RequiredIndicator className={"absolute top-3.5"} />
                  )}
                </div>
                <div className={"flex flex-col items-start"}>
                  {!draftHiveEvent.hive && (
                    <div className={"text-[14px]"}>{t("assocHive")}</div>
                  )}
                  {draftHiveEvent.hive && (
                    <p className={"text-brown-500"}>
                      {getAssociatedHiveInfo()}
                    </p>
                  )}
                </div>
              </div>
            </DrawerTrigger>
          }
          title={t("selectHive")}
          options={hiveOptions}
          selectedValues={draftHiveEvent.hive}
          onSelectionChange={handleHiveChange}
        />
      </OverflowMenuItem>
      {parentEventTypes ? (
        <OverflowMenuItem isDisabled={!!eventId || !!typeId}>
          <EventsTypeDrawer
            addEventEnabled={false}
            eventTypesMap={parentEventTypes}
            fallbackText={t("selectEventType")}
            onEventSelect={handleEventTypeChange}
            selectedValue={draftHiveEvent.type}
          />
        </OverflowMenuItem>
      ) : (
        <Skeleton className={"h-[56px] w-full rounded-[8px]"} />
      )}
      {draftHiveEvent.type && (
        <OverflowMenuItem className={"text-[14px] text-neutral-500"}>
          <i>{t("eventInfoDescription")}</i>
          {getAssociatedHiveEventTypeDescription()}
        </OverflowMenuItem>
      )}
      {isTreatmentEvent && (
        <HiveEventTreatmentEditor draftEvent={draftHiveEvent} />
      )}
      {isMeasurementEvent && (
        <HiveEventMeasurementEditor draftEvent={draftHiveEvent} />
      )}
      {isEquipmentEvent && (
        <HiveEventEquipmentEditor draftEvent={draftHiveEvent} />
      )}
      {isMovementEvent && (
        <HiveEventMovementEditor draftEvent={draftHiveEvent} />
      )}
      <GenericDatePicker
        isDisabled={!!eventId}
        value={draftHiveEvent.date}
        onChange={handleDateChange}
      />
      <InputFieldWrapperWithIcon
        icon={<UserIcon size={30} />}
        align={"row"}
        name={t("personName")}
        isDisabled={false}
        value={draftHiveEvent.person_name}
        onChange={handlePersonNameChange}
      />
      <div className={"mt-2 text-[14px] text-neutral-400"}>
        {t("description")}
      </div>
      <TextAreaFieldWrapperWithIcon
        icon={<DetailIcon />}
        name={t("details")}
        isReadOnly={false}
        value={draftHiveEvent.description}
        onChange={handleDescriptionChange}
      />
      <TextAreaFieldWrapperWithIcon
        icon={<NoteThumbnailIcon size={30} />}
        name={t("otherObservations")}
        isReadOnly={false}
        value={draftHiveEvent.notes}
        onChange={handleNotesChange}
      />
    </div>
  )
}
