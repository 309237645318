export const TRANSACTION_CATEGORY_TYPE = {
  INCOMES: "INCOMES",
  EXPENSES: "EXPENSES",
}

export const CURRENCY_ENUM = {
  RON: "RON",
  EUR: "EUR",
  USD: "USD",
}

export const TIME_PERIOD = {
  PERIOD_ALL_TIME: "all_time",
  PERIOD_CURRENT_WEEK: "current_week",
  PERIOD_LAST_WEEK: "last_week",
  PERIOD_CURRENT_MONTH: "current_month",
  PERIOD_LAST_MONTH: "last_month",
  PERIOD_LAST_YEAR: "last_year",
  PERIOD_CUSTOM: "custom",
}

export const timePeriodOptions = Object.entries(TIME_PERIOD).map(
  ([_key, value]) => ({
    value: value,
    label: value,
  }),
)

export enum INFLOW_TRANSACTION_CATEGORY {
  EU_FOUNDS = "EU_FOUNDS",
  PROFIT = "PROFIT",
  DONATIONS = "DONATIONS",
  SELLING_GOODS = "SELLING_GOODS",
  SELLING_ANIMALS = "SELLING_ANIMALS",
}

export enum OUTFLOW_TRANSACTION_CATEGORY {
  EQUIPMENT_ACQUISITION = "EQUIPMENT_ACQUISITION",
  MAINTENANCE = "MAINTENANCE",
  ANIMALS_FOOD = "ANIMALS_FOOD",
  TREATMENTS = "TREATMENTS",
  TRANSPORTATION = "TRANSPORTATION",
  VETERINARY = "VETERINARY",
  SALARIES = "SALARIES",
  SOFTWARE_ACQUISITION = "SOFTWARE_ACQUISITION",
}
