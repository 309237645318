import { apiSlice, TagType } from "@/features/api/apiSlice.ts"
import { type CommonEventType } from "@/features/events/common/types/common-event-type.ts"

const PARCEL_EVENT_TYPES_BASE_URL = "/parcels-event-types/"

export const ParcelEventTypesApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllParcelEventTypes: builder.query<CommonEventType[], void>({
      query: () => ({
        url: PARCEL_EVENT_TYPES_BASE_URL,
      }),
      providesTags: () => [{ type: TagType.ParcelEventTypes, id: "LIST" }],
    }),
  }),
})

export const { useGetAllParcelEventTypesQuery } = ParcelEventTypesApi
