import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { SelectionRowDrawer } from "@/components/selection-row-drawer/selection-row-drawer.tsx"
import { Input } from "@/components/shadcn/input.tsx"
import { Label } from "@/components/shadcn/label.tsx"
import {
  type CountryCodeKey,
  countryCodeOptions,
  countryCodes,
} from "@/features/settings-account/utils/language-options.ts"

interface PhoneNumberInputProps {
  phoneNumber: string
  onPhoneNumberChange: (phoneNumber: string) => void
  required?: boolean
}

const initialPhoneNumber = (phoneNumber: string) => {
  if (phoneNumber) {
    const foundCountry = Object.entries(countryCodes).find(([_, country]) =>
      phoneNumber?.startsWith(country.code),
    )
    if (foundCountry) {
      return phoneNumber.substring(
        countryCodes[foundCountry[0] as CountryCodeKey].code.length,
      )
    }
    return phoneNumber
  }
  return ""
}

const initialCountryKey = (phoneNumber: string) => {
  if (phoneNumber) {
    const foundCountry = Object.entries(countryCodes).find(([_, country]) =>
      phoneNumber?.startsWith(country.code),
    )
    return foundCountry ? (foundCountry[0] as CountryCodeKey) : "ro"
  }
  return "ro"
}

export const PhoneNumberInput: React.FC<PhoneNumberInputProps> = ({
  phoneNumber,
  onPhoneNumberChange,
  required,
}) => {
  const { t } = useTranslation()

  useEffect(() => {
    setSelectedCountryKey(initialCountryKey(phoneNumber))
    setPhoneNumberUI(initialPhoneNumber(phoneNumber))
  }, [phoneNumber])

  const [selectedCountryKey, setSelectedCountryKey] = useState<CountryCodeKey>(
    initialCountryKey(phoneNumber),
  )
  const [phoneNumberUI, setPhoneNumberUI] = useState(
    initialPhoneNumber(phoneNumber),
  )

  const handlePhoneChange = (value: string) => {
    const cleanValue = value.replace(/\D/g, "")
    setPhoneNumberUI(cleanValue)

    const fullPhoneNumber = `${countryCodes[selectedCountryKey].code}${cleanValue}`
    onPhoneNumberChange(fullPhoneNumber)
  }

  const handleCountryChange = (key: string) => {
    const countryKey = key as CountryCodeKey
    setSelectedCountryKey(countryKey)

    const fullPhoneNumber = `${countryCodes[countryKey].code}${phoneNumberUI}`
    onPhoneNumberChange(fullPhoneNumber)
  }

  return (
    <div className="grid gap-2">
      <Label htmlFor="phone_number" required={required}>
        {t("phoneNumber")}
      </Label>
      <div className="flex gap-2">
        <div className="w-fit">
          <SelectionRowDrawer
            multiple={false}
            title={t("selectCountryCode")}
            renderTrigger={
              <div className="flex h-12 w-full items-center gap-3 rounded-sm border border-input bg-white p-2 px-3 text-base">
                <span>{countryCodes[selectedCountryKey].flag}</span>
                <span>{countryCodes[selectedCountryKey].code}</span>
              </div>
            }
            options={countryCodeOptions}
            selectedValues={selectedCountryKey}
            onSelectionChange={handleCountryChange}
          />
        </div>
        <Input
          maxLength={9}
          id="phone_number"
          value={phoneNumberUI}
          onChange={(e) => handlePhoneChange(e.target.value)}
          className="flex-1 font-medium tracking-[2px] text-neutral-700 placeholder:font-light placeholder:italic placeholder:tracking-normal"
          type="tel"
          placeholder={t("Enter your phone number")}
        />
      </div>
    </div>
  )
}
