import React from "react"
import { useNavigate } from "react-router-dom"
import { AddTaskIcon } from "@/assets/widgets/add-task-icon.tsx"
import Skeleton from "@/components/skeleton/skeleton.tsx"
import EmptyContentCreateItemButton from "@/features/home/components/empty-content-create-item-button/empty-content-create-item-button.tsx"
import WidgetCard from "@/features/home/components/widget-card/widget-card.tsx"
import { type Task } from "@/features/tasks"
import { useGetTasksQuery } from "@/features/tasks/api/tasks-api.ts"
import TaskCard from "@/features/tasks/components/task-card/task-card.tsx"
import {
  NEW_TASK_SCREEN_PATH,
  TASKS_SCREEN_PATH,
} from "@/utils/constants/routes.ts"
import { TEST_IDS } from "@/utils/enums/test-ids.ts"

interface TasksWidgetProps {
  maxTasksNumberToDisplay?: number
  tasks?: string[]
  animalId?: string
  hiveId?: string
  apiaryId?: string
  queenId?: string
  birdGroupId?: string
  parcelId?: string
  cropPlantingId?: string
}

const DEFAULT_STYLE = {
  height: "fit-content",
  marginInline: 0,
  marginBlock: 0,
  width: "100%",
}

const renderSkeletons = () =>
  Array.from({ length: 3 }).map((_, index) => (
    <Skeleton
      key={index}
      className="border-[var(--fp-border-radius) min-h-[60px] w-full rounded-[var(--fp-border-radius)]"
    />
  ))

const TasksWidget: React.FC<TasksWidgetProps> = ({
  maxTasksNumberToDisplay = 6,
  tasks,
  animalId,
  hiveId,
  apiaryId,
  queenId,
  birdGroupId,
  parcelId,
  cropPlantingId,
}) => {
  const navigate = useNavigate()
  const { data: allTasks = [], isLoading } = useGetTasksQuery()

  const getNavigationString = () => {
    const searchParams = new URLSearchParams()

    if (apiaryId) {
      searchParams.set("apiaryId", apiaryId)
    }
    if (hiveId) {
      searchParams.set("hiveId", hiveId)
    }
    if (animalId) {
      searchParams.set("animalId", animalId)
    }
    if (queenId) {
      searchParams.set("queenId", queenId)
    }
    if (birdGroupId) {
      searchParams.set("birdGroupId", birdGroupId)
    }
    if (parcelId) {
      searchParams.set("parcelId", parcelId)
    }
    if (cropPlantingId) {
      searchParams.set("cropPlantingId", cropPlantingId)
    }

    const params = searchParams.toString()
    return params ? `${NEW_TASK_SCREEN_PATH}?${params}` : NEW_TASK_SCREEN_PATH
  }

  const navigationString = getNavigationString()

  const getWorkingTasks = (tasks: Task[], filterIds?: string[]): Task[] => {
    const incompleteTasks = tasks.filter((task) => !task.is_done)

    if (filterIds) {
      return incompleteTasks.filter((task) => filterIds.includes(task.id))
    }

    return incompleteTasks.slice(0, maxTasksNumberToDisplay)
  }

  const workingTasks = getWorkingTasks(
    allTasks,
    animalId ||
      hiveId ||
      apiaryId ||
      queenId ||
      birdGroupId ||
      parcelId ||
      cropPlantingId
      ? tasks
      : undefined,
  )
  const hasWorkingTasks = workingTasks.length > 0

  const handleAddItem = () => {
    navigate(navigationString)
  }

  const getCustomStyles = () => {
    if (!animalId) {
      return DEFAULT_STYLE
    }
    return {}
  }

  const renderTasks = () =>
    workingTasks.map((task) => <TaskCard key={task.id} task={task} />)

  const renderContent = () => {
    if (isLoading) {
      return renderSkeletons()
    }

    if (hasWorkingTasks) {
      return renderTasks()
    }

    return <EmptyContentCreateItemButton navigateTo={navigationString} />
  }

  return (
    <WidgetCard
      testId={TEST_IDS.TASKS_WIDGET}
      icon={<AddTaskIcon />}
      title="tasks"
      navigateTo={TASKS_SCREEN_PATH}
      addItemGoTo={hasWorkingTasks ? handleAddItem : undefined}
      customStyles={getCustomStyles()}
    >
      <div className="flex flex-col gap-[10px]">{renderContent()}</div>
    </WidgetCard>
  )
}

export default TasksWidget
