import { useTranslation } from "react-i18next"
import HiveIcon from "@/assets/icons/bees/hive-icon.tsx"
import { TagIcon } from "@/assets/icons/bees/tag-icon.tsx"
import { HamburgerIcon } from "@/assets/icons/misc/hamburger-icon.tsx"
import RenderFunctionDrawer from "@/components/render-function-drawer/render-function-drawer.tsx"
import { DrawerClose, DrawerTrigger } from "@/components/shadcn/drawer.tsx"
import OverflowMenuItem from "@/components/task-card/overflow-menu-item.tsx"
import InputFieldWrapperWithIcon from "@/components/text-card-wrapper-with-icon/input-field-wrapper-with-icon.tsx"
import { useGetAllHivesQuery } from "@/features/bees/api/hives-api.ts"
import { HiveItem } from "@/features/bees/components/hive-scales/assoc-hive-drawer/assoc-hive-drawer.tsx"
import { type HiveScaleInput } from "@/features/bees/types/hive-scales.ts"
import { type HiveResponse } from "@/features/bees/types/hives.ts"

interface Props {
  hasHiveId?: boolean
  hiveScale: HiveScaleInput
  onHiveScaleChange: (hiveScale: HiveScaleInput) => void
}

export const HiveScaleEditor = ({
  hiveScale,
  onHiveScaleChange,
  hasHiveId,
}: Props) => {
  const { data: hives } = useGetAllHivesQuery()
  const { t } = useTranslation()

  const hiveOptions =
    hives
      ?.filter((hive) => !hive.scale)
      .map((hive) => ({
        label: hive.code,
        value: hive.id,
      })) || []
  const currentHive = hives?.find((hive) => hive.id === hiveScale.hive)

  const hivesMap = hives?.reduce(
    (acc, hive) => {
      acc[hive.id] = hive
      return acc
    },
    {} as Record<string, HiveResponse>,
  )

  const DTrigger = () => {
    return (
      <div className={"grid grid-cols-[30px_auto] items-center gap-2"}>
        <div
          className={
            "grid h-[30px] w-[30px] place-content-center rounded-[4px] bg-blue-300 text-white"
          }
        >
          <HiveIcon className={"scale-150"} />
        </div>
        <div className={"flex w-full justify-between"}>
          {currentHive ? (
            <span className={"text-[14px]"}>{currentHive.code}</span>
          ) : (
            <span className={"text-[14px] text-neutral-700"}>{t("hive")}</span>
          )}
          <HamburgerIcon className={"scale-150"} />
        </div>
      </div>
    )
  }

  return (
    <div className={"flex flex-col gap-[10px] p-4 pb-10"}>
      <InputFieldWrapperWithIcon
        isRequired={!hiveScale.phone_number}
        name={t("phoneNumber")}
        value={hiveScale.phone_number}
        onChange={(e) =>
          onHiveScaleChange({ ...hiveScale, phone_number: e.target.value })
        }
        inputMode={"tel"}
        icon={
          <div
            className={
              "grid h-[30px] w-[30px] place-content-center rounded-[4px] bg-yellow-300 text-transparent"
            }
          >
            <TagIcon />
          </div>
        }
        align={"row"}
        isDisabled={false}
      />
      <OverflowMenuItem isDisabled={!!hasHiveId}>
        <RenderFunctionDrawer
          multiple={false}
          renderTrigger={
            <DrawerTrigger>
              <DTrigger />
            </DrawerTrigger>
          }
          title={t("hive")}
          options={hiveOptions}
          selectedValues={hiveScale.hive || ""}
          onSelectionChange={(value) =>
            onHiveScaleChange({ ...hiveScale, hive: value })
          }
          ItemComponent={(props) => (
            <DrawerClose>
              <HiveItem {...props} hive={hivesMap?.[props.id]} />
            </DrawerClose>
          )}
        />
      </OverflowMenuItem>
    </div>
  )
}
