import { format } from "date-fns"
import { useTranslation } from "react-i18next"
import {
  type HiveScaleEvent,
  PHONE_NUMBER_CHANGED_EVENT_TYPE,
  SCALE_ADDED_EVENT_TYPE,
  SCALE_REMOVED_EVENT_TYPE,
} from "@/features/bees/types/hive-scales.ts"
import WidgetCard from "@/features/home/components/widget-card/widget-card.tsx"

interface Props {
  events: HiveScaleEvent[]
}

const getDisplayText = (event: HiveScaleEvent) => {
  if (event.type.name === SCALE_REMOVED_EVENT_TYPE) {
    return "scaleRemovedEventDescription"
  } else if (event.type.name === SCALE_ADDED_EVENT_TYPE) {
    return "scaleAddedEventDescription"
  } else if (event.type.name === PHONE_NUMBER_CHANGED_EVENT_TYPE) {
    return "phoneNumberChangedEventDescription"
  } else {
    return event.type.description
  }
}

export const HiveScaleEventsWidget: React.FC<Props> = ({ events }) => {
  const { t } = useTranslation()

  return (
    <WidgetCard title={t("hiveScaleEvents")}>
      {events.map((event) => (
        <div key={event.id} className={"widget-border flex flex-col gap-2 p-2"}>
          <div className={"flex gap-2"}>
            {event.type.picture && (
              <img
                src={event.type.picture}
                alt=""
                className={"h-10 w-10 p-1"}
              />
            )}
            <div className={"flex flex-col justify-between"}>
              <p className={"font-semibold"}>{t(event.type.name)}</p>
              <div>{format(event.created_at, "dd.MM.yyyy HH:mm")}</div>
            </div>
          </div>
          <p>
            {t(getDisplayText(event), {
              hive: event.hive_code,
              scale: event.scale,
              new_phone_number: event.phone_number_change?.new_phone_number,
              old_phone_number: event.phone_number_change?.old_phone_number,
            })}
          </p>
        </div>
      ))}
    </WidgetCard>
  )
}
