import { apiSlice, TagType } from "@/features/api/apiSlice.ts"
import {
  type CropPlanting,
  type CropPlantingCreateUpdateRequest,
} from "@/features/crops/types/crop-planting.ts"

const CROP_PLANTINGS_API_BASE_URL = "/crops-plantings/"

interface CropsPlanningFilter {
  parcel?: string
  crop?: string
  is_active?: boolean
}

export const cropPlantingsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCropsPlanting: builder.query<CropPlanting[], CropsPlanningFilter>({
      query: (params) => ({
        url: CROP_PLANTINGS_API_BASE_URL,
        params: { parcel: params.parcel ?? undefined },
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: TagType.CropsPlanting, id })),
              { type: TagType.CropsPlanting, id: "LIST" },
            ]
          : [{ type: TagType.CropsPlanting, id: "LIST" }],
    }),
    getCropPlanting: builder.query<CropPlanting, string>({
      query: (id) => `${CROP_PLANTINGS_API_BASE_URL}${id}/`,
      providesTags: (_result, _error, id) => [
        { type: TagType.CropsPlanting, id },
      ],
    }),
    getCropPlantingsByParcelId: builder.query<CropPlanting[], string>({
      query: (parcelId) => ({
        url: CROP_PLANTINGS_API_BASE_URL,
        params: { parcel: parcelId },
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: TagType.CropsPlanting, id })),
              { type: TagType.CropsPlanting, id: "LIST" },
            ]
          : [{ type: TagType.CropsPlanting, id: "LIST" }],
    }),
    createCropPlanting: builder.mutation<
      CropPlanting,
      CropPlantingCreateUpdateRequest
    >({
      query: (cropPlanting) => ({
        url: CROP_PLANTINGS_API_BASE_URL,
        method: "POST",
        body: cropPlanting,
      }),
      invalidatesTags: [{ type: TagType.CropsPlanting, id: "LIST" }],
    }),
    updateCropPlanting: builder.mutation<
      CropPlanting,
      CropPlantingCreateUpdateRequest & { id: string }
    >({
      query: (cropPlanting) => ({
        url: `${CROP_PLANTINGS_API_BASE_URL}${cropPlanting.id}/`,
        method: "PATCH",
        body: cropPlanting,
      }),
      invalidatesTags: (_result, _error, { id }) => [
        { type: TagType.CropsPlanting, id },
        { type: TagType.CropsPlanting, id: "LIST" },
      ],
    }),
    deleteCropPlanting: builder.mutation<void, string>({
      query: (id) => ({
        url: `${CROP_PLANTINGS_API_BASE_URL}${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: (_result, _error, id) => [
        { type: TagType.CropsPlanting, id },
        { type: TagType.CropsPlanting, id: "LIST" },
      ],
    }),
  }),
})

export const {
  useGetCropsPlantingQuery,
  useGetCropPlantingQuery,
  useCreateCropPlantingMutation,
  useUpdateCropPlantingMutation,
  useDeleteCropPlantingMutation,
} = cropPlantingsApi
