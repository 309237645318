export interface Address {
  street: string
  city: string
  state: string
  country: string
  extra: string
  latitude: number
  longitude: number
}

export interface AppPackage {
  name: string
  description: string
  ai_assistant_module: boolean
  gestational_calendar_module: boolean
  finance_journal_module: boolean
  cow_module: boolean
  pork_module: boolean
  sheep_module: boolean
  goat_module: boolean
  bee_module: boolean
  bird_module: boolean
  ads_enabled: boolean
  parcels_module: boolean
}

export interface FarmProfile {
  id: string
  name: string
  description: string
  legal_name: string
  tin: string
  picture: string | undefined
  created_at: string
  updated_at: string
  address: Address
  app_package: AppPackage
}

export interface BillingData {
  legal_name: string
  farm_name: string
  tin: string
  address: {
    street: string
    city: string
    state: string
    country: string
  }
}

export const initialBillingData: BillingData = {
  legal_name: "",
  tin: "",
  farm_name: "",
  address: {
    street: "",
    city: "",
    state: "",
    country: "",
  },
}

export type FarmProfileDraft = Omit<
  FarmProfile,
  "created_at" | "updated_at" | "app_package"
>

export interface FarmProfileSummary {
  id: string
  name: string
  picture: string
}

export interface UserProfile {
  id: string
  first_name: string
  last_name: string
  email: string
  phone_number: string
  is_contract: boolean
  farm: FarmProfileSummary
  picture: string | undefined
  language: string
}

export type UserProfileUpdate = Omit<UserProfile, "id" | "email" | "farm">

export type UserProfileCreation = Omit<
  UserProfileUpdate,
  "picture" | "is_contract"
>

export const initialUserProfileCreation: UserProfileCreation = {
  first_name: "",
  last_name: "",
  phone_number: "",
  language: "ro",
}
