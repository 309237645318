import { cn } from "@/utils/styles.ts"

interface Props {
  title: string
  onClick?: () => void
  isActive?: boolean
}

export const WidgetButton: React.FC<Props> = ({ title, onClick, isActive }) => {
  return (
    <div
      onClick={onClick}
      className={cn(
        "flex h-[60px] items-center justify-center gap-2 rounded-[15px] border border-neutral-200 text-neutral-700",
        isActive && "border-brown-500 text-brown-500",
      )}
    >
      <p className={"font-medium"}>{title}</p>
    </div>
  )
}
