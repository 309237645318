import { useTranslation } from "react-i18next"
import PageContentWrapperLarge from "@/components/page-content-wrappers/page-content-wrapper-large.tsx"
import MobileTopBar from "@/components/top-bars/mobile-top-bar/mobile-top-bar.tsx"
import { AnimalEventsSummaryWidget } from "@/features/events/animal-events/components/animal-events-summary-widget/animal-events-summary-widget.tsx"
import { BirdsGroupsSummaryWidget } from "@/features/events/birds-groups-events/components/birds-groups-summary-card/birds-groups-summary-widget.tsx"
import { ApiaryEventsSummaryCard } from "@/features/events/common/components/apiary-events-summary-card/apiary-events-summary-card.tsx"
import { HiveEventsSummaryCard } from "@/features/events/common/components/hive-events-summary-card/hive-events-summary-card.tsx"
import { ParcelEventsSummaryWidget } from "@/features/events/parcel-events/components/parcel-events-summary-widget/parcel-events-summary-widget.tsx"
import useFarmHasMammals from "@/features/farm/hooks/use-farm-has-mammals.ts"
import useFarmPermissions from "@/features/permissions/hooks/use-farm-permissions.ts"

const AllEventsView = () => {
  const { t } = useTranslation()
  const { permissions } = useFarmPermissions()
  const isMammal = useFarmHasMammals()

  return (
    <>
      <MobileTopBar title={t("events")} />
      <PageContentWrapperLarge className={"flex flex-col"}>
        {isMammal && <AnimalEventsSummaryWidget />}
        {permissions?.bee_module && (
          <>
            <ApiaryEventsSummaryCard />
            <HiveEventsSummaryCard />
          </>
        )}
        {permissions?.parcels_module && <ParcelEventsSummaryWidget />}
        {permissions?.bird_module && <BirdsGroupsSummaryWidget />}
      </PageContentWrapperLarge>
    </>
  )
}

export default AllEventsView
