import { format } from "date-fns"
import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import ClockIcon from "@/assets/icons/misc/clock-icon.tsx"
import NoResultsFound from "@/components/not-found/no-results-found.tsx"
import Skeleton from "@/components/skeleton/skeleton.tsx"
import { WidgetButton } from "@/components/widget-button/widget-button.tsx"
import {
  getMetricConfig,
  type HIVE_SCALE_METRICS,
  type MeasurementsResponse,
  type ScaleMetricKey,
} from "@/features/bees/types/hive-scales.ts"

interface Props {
  metric: string
  measurements: MeasurementsResponse
  onButtonClick: () => void
  title: string
  isFetching?: boolean
}

export const GroupedMeasurements: React.FC<Props> = ({
  metric,
  measurements,
  onButtonClick,
  title,
  isFetching,
}) => {
  const validMetric = metric as ScaleMetricKey
  const config = getMetricConfig(metric as HIVE_SCALE_METRICS)
  const { t, i18n } = useTranslation()

  const groupedMeasurements = useMemo(() => {
    if (!measurements?.results) return {}

    return measurements.results.reduce<
      Record<string, MeasurementsResponse["results"]>
    >((groups, point) => {
      const dateStr = format(new Date(point.measured_at), "dd.MM.yyyy")

      if (!groups[dateStr]) {
        groups[dateStr] = []
      }

      groups[dateStr].push(point)
      return groups
    }, {})
  }, [measurements?.results])

  const sortedDates = useMemo(() => {
    return Object.keys(groupedMeasurements).sort((a, b) => {
      const dateA = new Date(a.split(".").reverse().join("-")).getTime()
      const dateB = new Date(b.split(".").reverse().join("-")).getTime()
      return dateB - dateA
    })
  }, [groupedMeasurements])

  const formatDateWithDay = (dateStr: string) => {
    const date = new Date(dateStr.split(".").reverse().join("-"))
    const dayName = date.toLocaleDateString(i18n.language, { weekday: "long" })
    const capitalizedDay = dayName.charAt(0).toUpperCase() + dayName.slice(1)
    return `${dateStr}, ${capitalizedDay}`
  }

  return (
    <div className="widget-border flex flex-col bg-white p-4">
      <p
        className="mb-3 text-body-md font-semibold"
        style={{ color: config.color }}
      >
        {t(metric)}
      </p>
      <p className="mb-3.5">
        {t("lastMeasurementsTotal", {
          count: measurements?.results.length,
          total: measurements?.count,
        })}
      </p>
      {measurements && measurements.results.length && metric ? (
        <div className="mb-6 flex flex-col gap-6">
          {sortedDates.map((dateStr, dateIndex) => (
            <div key={dateStr} className="flex flex-col">
              <div className="mb-2 text-sm font-medium text-gray-600">
                {formatDateWithDay(dateStr)}
              </div>
              <div className="flex flex-col">
                {groupedMeasurements[dateStr].map((point, index) => {
                  const isLastInGroup =
                    index === groupedMeasurements[dateStr].length - 1
                  const isFirstInGroup = index === 0
                  const rowClass = `
          flex w-[100% + 16px] justify-between text-body-xs p-3 mx-[-16px] px-4
          ${index % 2 === 0 ? "bg-gray-50" : ""}
          ${isLastInGroup && dateIndex !== sortedDates.length - 1 ? "border-b border-gray-200" : ""}
          ${isFirstInGroup ? "border-t border-gray-200" : ""}
        `.trim()

                  return (
                    <span key={point.id} className={rowClass}>
                      <span className="flex items-center gap-1.5">
                        <ClockIcon className="h-4 w-4" />
                        {format(new Date(point.measured_at), "HH:mm")}
                      </span>
                      <span
                        style={{ color: config.color }}
                        className="font-semibold"
                      >
                        {point[validMetric].toFixed(2)} {config.unit}
                      </span>
                    </span>
                  )
                })}
              </div>
            </div>
          ))}
        </div>
      ) : (
        <NoResultsFound
          image={"hive"}
          className={"scale-75 pt-14"}
          message={t("noMeasurementsRecorded")}
        />
      )}
      {!isFetching && measurements && measurements.next && (
        <WidgetButton onClick={onButtonClick} title={t(title)} />
      )}
      {isFetching && (
        <>
          {Array.from({ length: 15 }).map((_, index) => (
            <Skeleton key={index} className={"mb-2 h-10 w-full rounded"} />
          ))}
        </>
      )}
    </div>
  )
}
