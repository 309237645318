import { CalendarCog } from "lucide-react"
import React, { type ChangeEvent } from "react"
import { useTranslation } from "react-i18next"
import AinIcon from "@/assets/icons/farm/ain-icon.tsx"
import BreedIcon from "@/assets/icons/farm/breed-icon.tsx"
import DetailIcon from "@/assets/icons/farm/details-icon.tsx"
import LocationIcon from "@/assets/icons/farm/location-icon.tsx"
import MeatIcon from "@/assets/icons/farm/meat-icon.tsx"
import ChooseSomethingIcon from "@/assets/icons/misc/choose-something-icon.tsx"
import { AttachImageFieldBase64 } from "@/components/attach-image-field/attach-image-field-base-64.tsx"
import RenderFunctionDrawer from "@/components/render-function-drawer/render-function-drawer.tsx"
import { SelectionRowDrawer } from "@/components/selection-row-drawer/selection-row-drawer.tsx"
import OverflowMenuItem from "@/components/task-card/overflow-menu-item.tsx"
import InputFieldWrapperWithIcon from "@/components/text-card-wrapper-with-icon/input-field-wrapper-with-icon.tsx"
import TextAreaFieldWrapperWithIcon from "@/components/text-card-wrapper-with-icon/text-area-field-wrapper-with-icon.tsx"
import { AgeGroupCard } from "@/features/birds-groups/components/age-group-card/age-group-card.tsx"
import { BirdsCounter } from "@/features/birds-groups/components/birds-counter/birds-counter.tsx"
import { BirdsCounterIconSet } from "@/features/birds-groups/components/birds-counter/counter-icon-sets.tsx"
import { useGetBirdSpecies } from "@/features/birds-groups/hooks/use-get-bird-species.ts"
import {
  AGE_GROUPS_OPTIONS,
  type BirdsGroupInput,
  birdsIntendedUseOptions,
} from "@/features/birds-groups/types/birds-groups.ts"
import RequiredIndicator from "@/features/farm/components/required-indicator/required-indicator.tsx"

interface BirdsGroupsEditorProps {
  birdsGroup: BirdsGroupInput
  onBirdsGroupChange: (birdsGroup: BirdsGroupInput) => void
}

export const BirdsGroupsEditor: React.FC<BirdsGroupsEditorProps> = ({
  birdsGroup,
  onBirdsGroupChange,
}) => {
  const { t } = useTranslation()
  const birdSpecies = useGetBirdSpecies()
  const speciesOptions =
    birdSpecies?.map((species) => ({
      value: species.id,
      label: species.name,
    })) || []

  const breeds = birdSpecies?.find(
    (species) => species.id === birdsGroup.species,
  )?.breeds
  const breedOptions =
    breeds?.map((breed) => ({
      value: breed.id,
      label: breed.name,
    })) || []
  const handleDescriptionChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const description = event.target.value
    onBirdsGroupChange({ ...birdsGroup, description })
  }
  const handlePictureChange = (picture: string | null) => {
    onBirdsGroupChange({ ...birdsGroup, picture: picture ? picture : "" })
  }
  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    const name = event.target.value
    onBirdsGroupChange({ ...birdsGroup, name })
  }
  const handleAgeGroupChange = (age_group: string) => {
    onBirdsGroupChange({ ...birdsGroup, age_group })
  }

  const handleSpeciesChange = (species: string) => {
    onBirdsGroupChange({ ...birdsGroup, species })
  }
  const handleBreedChange = (breed: string) => {
    onBirdsGroupChange({ ...birdsGroup, breed })
  }

  const handleCountChange = (count: number) => {
    onBirdsGroupChange({ ...birdsGroup, count })
  }

  const handleCurrentPositionChange = (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    const location = event.target.value
    onBirdsGroupChange({ ...birdsGroup, location })
  }

  const handleIntendedUseChange = (intended_use: string) => {
    onBirdsGroupChange({ ...birdsGroup, intended_use })
  }

  const selectedSpecies = birdSpecies?.find(
    (species) => species.id === birdsGroup.species,
  )
  const selectedBreed = selectedSpecies?.breeds.find(
    (breed) => breed.id === birdsGroup.breed,
  )

  return (
    <div className={"flex flex-col gap-[10px] pb-10"}>
      <InputFieldWrapperWithIcon
        icon={<AinIcon />}
        align={"row"}
        name={t("groupName")}
        isRequired={!birdsGroup.name}
        isDisabled={false}
        value={birdsGroup.name}
        onChange={handleNameChange}
      />
      <RenderFunctionDrawer
        renderTrigger={
          <OverflowMenuItem>
            <div
              className={
                "flex items-center gap-2 border-b border-b-gray-100 pb-2"
              }
            >
              <div
                className={
                  "grid h-[30px] w-[30px] place-content-center rounded bg-brown-500"
                }
              >
                <CalendarCog className={"h-[20px] w-[20px] text-white"} />
              </div>
              <p className={"text-neutral-600"}>{t("ageGroup")}</p>
            </div>
            <AgeGroupCard id={birdsGroup.age_group} isSelected />
          </OverflowMenuItem>
        }
        multiple={false}
        title={t("ageGroup")}
        options={AGE_GROUPS_OPTIONS}
        selectedValues={birdsGroup.age_group}
        onSelectionChange={handleAgeGroupChange}
        ItemComponent={AgeGroupCard}
      />
      <SelectionRowDrawer
        multiple={false}
        renderTrigger={
          <OverflowMenuItem>
            <div
              className={
                "grid h-[30px] w-full grid-cols-[30px_auto] items-center gap-2"
              }
            >
              <div
                className={
                  "grid h-[30px] w-[30px] place-content-center rounded bg-red-500"
                }
              >
                <MeatIcon className={"text-white"} />
                {!birdsGroup.intended_use && <RequiredIndicator />}
              </div>
              <p>
                {birdsGroup.intended_use
                  ? t(birdsGroup.intended_use)
                  : t("birdsGroupIntendedPurpose")}
              </p>
            </div>
          </OverflowMenuItem>
        }
        title={t("birdsGroupIntendedPurpose")}
        options={birdsIntendedUseOptions}
        selectedValues={birdsGroup.intended_use}
        onSelectionChange={handleIntendedUseChange}
      />
      <InputFieldWrapperWithIcon
        icon={<LocationIcon />}
        align={"row"}
        name={t("currentPosition")}
        isDisabled={false}
        isRequired={!birdsGroup.location}
        value={birdsGroup.location}
        onChange={handleCurrentPositionChange}
      />
      <OverflowMenuItem>
        <SelectionRowDrawer
          renderTrigger={
            <div
              className={
                "grid h-[30px] w-full grid-cols-[30px_auto] items-center gap-2"
              }
            >
              <ChooseSomethingIcon />
              <p>{selectedSpecies ? t(selectedSpecies.name) : t("species")}</p>
            </div>
          }
          options={speciesOptions}
          title={t("species")}
          multiple={false}
          selectedValues={birdsGroup.species}
          onSelectionChange={handleSpeciesChange}
        />
        {!birdsGroup.species && <RequiredIndicator />}
      </OverflowMenuItem>
      <OverflowMenuItem isDisabled={!birdsGroup.species}>
        <SelectionRowDrawer
          renderTrigger={
            <div
              className={
                "grid h-[30px] w-full grid-cols-[30px_auto] items-center gap-2"
              }
            >
              <BreedIcon />
              <p>{selectedBreed ? t(selectedBreed.name) : t("breed")}</p>
            </div>
          }
          options={breedOptions}
          multiple={false}
          title={t("breed")}
          selectedValues={birdsGroup.breed}
          onSelectionChange={handleBreedChange}
        />
        {!birdsGroup.breed && <RequiredIndicator />}
      </OverflowMenuItem>
      <BirdsCounter
        iconSet={<BirdsCounterIconSet />}
        count={birdsGroup.count}
        onCountChange={handleCountChange}
      />
      <TextAreaFieldWrapperWithIcon
        icon={<DetailIcon />}
        name={t("details")}
        isReadOnly={false}
        value={birdsGroup.description}
        onChange={handleDescriptionChange}
      />
      <AttachImageFieldBase64
        picture={birdsGroup.picture}
        onChoosePicture={handlePictureChange}
      />
    </div>
  )
}
