export const PL_TRANSLATIONS = {
  translation: {
    hello: "Cześć!",
    featureNotYetImplemented:
      "Ta funkcja nie została jeszcze zaimplementowana.",
    yes: "Tak",
    no: "Nie",
    yesterday: "Wczoraj",
    today: "Dzisiaj",
    tomorrow: "Jutro",
    thisWeek: "W tym tygodniu",
    thisMonth: "W tym miesiącu",
    lastMonth: "W zeszłym miesiącu",
    thisYear: "W tym roku",
    lastYear: "W zeszłym roku",
    pastYears: "Minione lata",
    homeTab: "Strona główna",
    tasksTab: "Zadania",
    addBottomMenu: "Dodaj",
    farmTab: "Moja farma",
    notifications: "Powiadomienia",
    notification: "Powiadomienie",
    menu: "Menu",
    accountTab: "Moje konto",
    oneSecond: "Chwileczkę...",
    weather: "Pogoda",
    notes: "Notatki",
    addNote: "Dodaj notatkę",
    newNote: "Nowa notatka",
    myNote: "Moja notatka",
    placeholderTitle: "Tytuł",
    placeholderText: "Twój tekst",
    deleteNote: "Usuń notatkę",
    searchNote: "Szukaj notatki",
    noNotesFound: "Nie znaleziono notatek",
    shareNote: "Udostępnij notatkę",
    formatNote: "Formatowanie",
    notesBackConfirmation:
      "Wprowadziłeś zmiany. Czy na pewno chcesz wyjść bez zapisywania?",
    sureWantToLeave: "Czy na pewno chcesz wyjść bez zapisywania?",
    youMadeChanges: "Wprowadziłeś zmiany.",
    unsavedChanges: "Niezapisane zmiany!",
    continueWhereYouLeftOff: "Kontynuuj od miejsca, w którym skończyłeś",
    youHaveUnsavedChanges:
      "Masz niezapisane zmiany z poprzedniej sesji. Czy chcesz kontynuować z tymi zmianami czy je odrzucić?",
    youWillLoseChanges: "Utracisz niezapisane zmiany. Czy chcesz kontynuować?",
    backConfirmation: "Czy na pewno chcesz wrócić do Notatek?",
    noteWillBeDeleted: "Bieżąca notatka zostanie usunięta, jeśli się zgodzisz.",
    continue: "Kontynuuj",
    leave: "Wyjdź",
    discard: "Odrzuć",
    delete: "Usuń",
    stay: "Zostań",
    save: "Zapisz",
    goToNotes: "Przejdź do notatek",
    seeAllNotes: "Zobacz wszystkie notatki",
    deleteNoteConfirmation: "Czy na pewno chcesz usunąć notatkę?",
    deletingNote: "Usuwanie notatki...",
    noteDeleted: "Notatka została usunięta!",
    errorDeletingNote: "Wystąpił błąd podczas usuwania notatki!",
    updatingNote: "Aktualizowanie notatki...",
    noteUpdated: "Notatka została zaktualizowana!",
    errorUpdatingNote: "Wystąpił błąd podczas aktualizacji notatki!",
    postingNote: "Tworzenie notatki...",
    notePosted: "Notatka została utworzona!",
    notePostError: "Wystąpił błąd podczas tworzenia notatki!",
    noNoteAssociated: "Brak powiązanych notatek",
    oneNoteAssociated: "Jedna powiązana notatka",
    notesAssociated: "powiązane notatki",
    noteContentIsMandatory: "Treść notatki jest obowiązkowa!",
    seeOtherNotesAssociated: "Zobacz inne powiązane notatki",
    hideOtherNotesAssociated: "Ukryj inne powiązane notatki",
    draft: "szkic",
    unsavedChangesIndicator: "niezapisane zmiany",
    notEnoughData: "Niewystarczająca ilość danych, aby wyświetlić wykres",
    notesWillBeSetAsDraft:
      "Notatki zostaną zapisane jako szkice. Możesz wrócić później, aby zapisać zmiany.",
    noteWasLoadedWithPreviousData:
      "Notatka została załadowana z poprzednimi danymi. Czy chcesz zachować dane i kontynuować?",

    //Tasks

    taskWasLoadedWithPreviousData:
      "Zadanie zostało załadowane z poprzednimi danymi. Czy chcesz zachować dane i kontynuować?",
    tasks: "Zadania",
    dueTasks: "Zaległe zadania",
    currentTasks: "Bieżące zadania",
    futureTasks: "Przyszłe zadania",
    searchTask: "Szukaj zadania",
    noTasksFound: "Nie znaleziono zadań",
    newTask: "Nowe zadanie",
    myTask: "Moje zadanie",
    title: "Tytuł",
    description: "Opis",
    deleteTask: "Usuń zadanie",
    disinfestation: "Odrobaczanie",
    treatments: "Leczenie",
    status: "Status",
    category: "Kategoria",
    chooseCategory: "Wybierz kategorię",
    ALL: "Wszystkie zadania",
    DONE: "Zadania zakończone",
    TODO: "Bieżące zadania",
    backConfirmationTasks: "Czy na pewno chcesz wrócić do Zadań?",
    backConfirmationSettings: "Czy na pewno chcesz wrócić do Ustawień?",
    taskWillBeDeleted: "Bieżące zadanie zostanie usunięte.",
    modificationsWillBeLost: "Zmiany zostaną utracone.",
    onTime: "Na czas",
    before30: "30 min wcześniej",
    beforeHour: "1 godzinę wcześniej",
    beforeDay: "1 dzień wcześniej",
    beforeWeek: "1 tydzień wcześniej",
    daily: "Codziennie",
    weekly: "Tygodniowo",
    monthly: "Miesięcznie",
    yearly: "Rocznie",
    Date: "Data",
    Hour: "Godzina",
    Reminder: "Przypomnienie",
    Recurrence: "Powtarzalność",
    AssociateNote: "Powiąż notatkę",
    AssociateAnimal: "Powiąż zwierzę",
    AssociateTask: "Powiąż zadanie",
    dashboardCurrentTasks: "Bieżące",
    dashboardFutureTasks: "Przyszłe",
    dashboardFinalizedTasks: "Zakończone",
    dashboardOverdueTasks: "Zaległe",
    seeAllTasks: "Zobacz wszystkie zadania",
    deleteTaskConfirmation: "Czy na pewno chcesz usunąć zadanie?",
    allCategories: "Wszystkie kategorie",
    noNewTasks: "Nie masz nowych zadań",
    updatingTask: "Aktualizowanie zadania...",
    taskUpdated: "Zadanie zostało zaktualizowane!",
    errorUpdatingTask: "Wystąpił błąd podczas aktualizacji zadania!",
    creatingTask: "Tworzenie zadania...",
    taskCreated: "Zadanie zostało utworzone!",
    errorCreatingTask: "Wystąpił błąd podczas tworzenia zadania!",
    deletingTask: "Usuwanie zadania...",
    taskDeleted: "Zadanie zostało usunięte!",
    errorDeletingTask: "Wystąpił błąd podczas usuwania zadania!",
    oneTaskAssociated: "Jedno powiązane zadanie",
    tasksAssociated: "powiązane zadania",
    taskNotFound: "Zadanie nie zostało znalezione!",
    titleRequired: "Tytuł jest wymagany do utworzenia zadania",
    title5Characters: "Tytuł musi mieć co najmniej 5 znaków",
    title100Characters: "Tytuł nie może przekraczać 100 znaków",
    addCategory: "Dodaj kategorię",
    editCategory: "Edytuj kategorię",
    selectTaskCategory: "Wybierz kategorię zadania",
    taskWillBeSetAsDraft:
      "Zadanie zostanie zapisane jako szkic. Możesz wrócić później, aby zapisać zmiany.",
    taskSetAsDraft: "Zadanie zostało zapisane jako szkic.",
    addTask: "Dodaj zadanie",
    animalWasLoadedWithPreviousData:
      "Zwierzę zostało załadowane z poprzednimi danymi. Czy chcesz zachować dane i kontynuować?",
    animalWillBeSetAsDraft:
      "Zwierzę zostanie zapisane jako szkic. Możesz wrócić później, aby zapisać zmiany.",
    ain: "Nr kolczyka",
    myFarm: "Moja farma",
    searchAnimal: "Szukaj zwierzęcia: Nazwa, nr kolczyka, status",
    outOfExploitation: "Wyszły z hodowli",
    removeAnimalFromExploitation: "Oznacz jako wyszłe z farmy",
    searchBreed: "Szukaj rasy",
    newAnimal: "Dodaj zwierzę",
    addCow: "Dodaj bydło",
    myAnimal: "Moje zwierzę",
    addGoat: "Dodaj kozę",
    addSheep: "Dodaj owcę",
    addPork: "Dodaj świnię",
    addBird: "Dodaj ptaka",
    addMammal: "Dodaj ssaka",
    addBee: "Dodaj ul",
    statusCows: "Status bydła",
    statusGoats: "Status kóz",
    statusSheep: "Status owiec",
    statusPork: "Status świń",
    statusBirds: "Status ptaków",
    statusBee: "Status pasiek",
    statusAnimal: "Status zwierząt",
    backConfirmationAnimals: "Czy na pewno chcesz wrócić do Mojej farmy?",
    animalWillBeDeleted:
      "Bieżące zwierzę zostanie usunięte, jeśli się zgodzisz.",
    name: "Imię",
    surname: "Nazwisko",
    taskTitleError: "Tytuł jest wymagany do utworzenia zadania",
    crotal: "Kolczyk",
    Male: "Samiec",
    Female: "Samica",
    allGenders: "Obie płcie",
    sex: "Płeć",
    registryEntryWay: "Sposób wprowadzenia do rejestru",
    Birth: "Poród",
    Beef: "Mięso",
    Meat: "Mięso",
    Purchase: "Zakup",
    Production: "Produkcja",
    Dairy: "Mleko",
    Sale: "Sprzedaż",
    Sacrifice: "Ubój",
    Transfer: "Transfer",
    Loss: "Strata",
    Death: "Śmierć",
    details: "Szczegóły",
    categories: "Kategorie",
    deleteAnimal: "Usuń zwierzę",
    birthDate: "Data urodzenia",
    entryDate: "Data wprowadzenia do hodowli",
    ALL_ANIMALS: "Wszystkie kategorie",
    ALL_GENDERS: "Wszystkie płcie",
    Cows: "Bydło",
    Cow: "Krowa",
    singleSheep: "Owca",
    Sheep: "Owce",
    FEMALE_SHEEP: "Owca",
    YOUNG_GOAT: "Koźlę",
    MALE_GOAT: "Kozioł",
    FEMALE_GOAT: "Koza",
    RAM: "Baran",
    singlePork: "Świnia",
    Pork: "Świnie",
    MALE_PIG: "Wieprz",
    FEMALE_PIG: "Locha",
    PIGLET: "Prosiak",
    singleGoat: "Koza",
    Goat: "Kozy",
    breed: "Rasa",
    Lamb: "Jagnię",
    Chicken: "Kura",
    Cock: "Kogut",
    Bee: "Pszczoła",
    exploitEntryDate: "Data wprowadzenia do hodowli",
    calves_count: "Porody",
    gestation_count: "Potwierdzenia ciąży",
    medical_treatments_count: "Leczenie",
    insemination_count: "Krycie / Inseminacje",
    lactation_count: "Laktacje",
    nameAnimalError: "* Nazwa zwierzęcia jest wymagana",
    categoryAnimalError: "* Kategoria zwierzęcia jest wymagana",
    breedAnimalError: "* Rasa zwierzęcia jest wymagana",
    crotalAnimalError: "* Nr kolczyka zwierzęcia jest wymagany",
    sexAnimalError: "* Płeć zwierzęcia jest wymagana",
    birthDateAnimalError: "* Data urodzenia zwierzęcia jest wymagana",
    entryDateAnimalError: "* Data wprowadzenia do hodowli jest wymagana",
    registryEntryWayAnimalError:
      "* Sposób wprowadzenia do rejestru jest wymagany",
    productionAnimalError: "* Typ produkcji jest wymagany",
    detailsAnimalError: "* Szczegóły zwierzęcia są wymagane",
    exploitEntryDateAnimalError: "* Data wprowadzenia do hodowli jest wymagana",
    fieldError: "* To pole jest wymagane",
    age: "Wiek",
    years: "lata",
    year: "rok",
    year_uppercase: "Rok",
    months: "miesiące",
    month: "miesiąc",
    and: "i",
    lessThanAMonth: "mniej niż miesiąc",
    allAges: "Wszystkie wieki",
    age02cow: "Cielę",
    age23cow: "Jałówka",
    age3plusCow: "Dorosła",
    age510: "5 - 10 lat",
    species: "Gatunki",
    allSpecies: "Wszystkie gatunki",
    Calf: "Cielę",
    Veal: "Jałówka",
    mature: "Dorosła",
    goats: "Kozy",
    ALL_SPECIES: "Wszystkie gatunki",
    heads: "Sztuki",
    addAnimal: "Dodaj zwierzę",
    animalSummary: "Podsumowanie danych zwierzęcia",
    animalMother: "Matka zwierzęcia",
    animalFather: "Ojciec zwierzęcia",
    seeFarm: "Zobacz moją farmę",
    deleteAnimalConfirmation: "Czy na pewno chcesz usunąć zwierzę?",
    deletingAnimal: "Usuwanie zwierzęcia...",
    animalDeleted: "Zwierzę zostało usunięte!",
    errorDeletingAnimal: "Wystąpił błąd podczas usuwania zwierzęcia!",
    updatingAnimal: "Aktualizowanie zwierzęcia...",
    animalUpdated: "Zwierzę zostało zaktualizowane!",
    animalUpdateError: "Wystąpił błąd podczas aktualizacji zwierzęcia!",
    creatingAnimal: "Tworzenie zwierzęcia...",
    animalCreated: "Zwierzę zostało utworzone!",
    animalCreateError: "Wystąpił błąd podczas tworzenia zwierzęcia!",
    allAnimals: "Wszystkie zwierzęta",
    animals: "Zwierzęta",
    noAnimalAssociated: "Brak powiązanych zwierząt",
    oneAnimalAssociated: "Jedno powiązane zwierzę",
    oneAnimalSelected: "Jedno wybrane zwierzę",
    animalsAssociated: "powiązane zwierzęta",
    seeOtherAnimalsAssociated: "Zobacz inne powiązane zwierzęta",
    hideOtherAnimalsAssociated: "Ukryj inne powiązane zwierzęta",
    animalsSelected: "wybrane zwierzęta",
    ainRequired: "Nr kolczyka jest wymagany do utworzenia zwierzęcia",
    genderRequired: "Płeć jest wymagana do utworzenia zwierzęcia",
    birthDateRequired: "Data urodzenia jest wymagana do utworzenia zwierzęcia",
    entryDateRequired:
      "Data wprowadzenia do hodowli jest wymagana do utworzenia zwierzęcia",
    entryWayIsRequired: "Sposób wprowadzenia do rejestru jest wymagany",
    intendedPurposeIsRequired: "Produkcja jest wymagana",
    speciesRequired: "Gatunek jest wymagany do utworzenia zwierzęcia",
    breedIsRequired: "Rasa jest wymagana do utworzenia zwierzęcia",
    entryDateMustBeLaterThanBirthDate:
      "Data wprowadzenia musi być późniejsza niż data urodzenia",
    birthDateCannotBeInTheFuture: "Data urodzenia nie może być w przyszłości",
    entryDateCannotBeInTheFuture:
      "Data wprowadzenia nie może być w przyszłości",
    nameFarmRequired: "Nazwa farmy jest wymagana!",
    nameMaxLength: "Nazwa nie może przekraczać 100 znaków!",
    selectAnimalMother: "Wybierz matkę zwierzęcia",
    selectAnimalFather: "Wybierz ojca zwierzęcia",
    ALL_STATUSES: "Wszystkie statusy",
    HEAT: "Ruja",
    GESTATING: "Ciąża",
    LACTATING: "Laktacja",
    ILL: "Do zbadania",
    HEALTHY: "Zdrowe",
    DRY: "Odpoczynek",
    gestationPeriodCalendar: "Kalendarz ciąży",
    jurnal: "Dziennik",
    vocalAssistant: "Asystent głosowy",
    contactTheSpecialist: "Skontaktuj się ze specjalistą",
    contactSpecialist: "Skontaktuj się ze specjalistą",
    voiceAssistant: "Asystent głosowy",
    goodMorning: "Dzień dobry",
    goodAfternoon: "Dzień dobry",
    goodEvening: "Dobry wieczór",
    Monday: "Poniedziałek",
    Tuesday: "Wtorek",
    Wednesday: "Środa",
    Thursday: "Czwartek",
    Friday: "Piątek",
    Saturday: "Sobota",
    Sunday: "Niedziela",
    january: "Styczeń",
    february: "Luty",
    march: "Marzec",
    april: "Kwiecień",
    may: "Maj",
    june: "Czerwiec",
    july: "Lipiec",
    august: "Sierpień",
    september: "Wrzesień",
    october: "Październik",
    november: "Listopad",
    december: "Grudzień",
    dashboard: "Dashboard",
    allNotifications: "Wszystkie powiadomienia",
    ALL_NOTIFICATIONS: "Wszystkie powiadomienia",
    ALERTS: "Alerty",
    REMINDERS: "Przypomnienia",
    INFO: "Informacje",
    MANDATORY: "Obowiązkowe",
    allRead: "Wszystkie",
    read: "Przeczytane",
    unread: "Nieprzeczytane",
    all: "Wszystkie",
    seeAllNotifications: "Zobacz wszystkie powiadomienia",
    noNewNotifications: "Nie masz nowych powiadomień",
    notificationWasRead: "Powiadomienie zostało przeczytane!",
    notificationsWereRead: "Powiadomienia zostały przeczytane!",
    updateNotificationError:
      "Wystąpił błąd podczas aktualizacji powiadomienia!",
    updateNotificationsError: "Wystąpił błąd podczas aktualizacji powiadomień!",
    notificationError: "Wystąpił błąd w powiadomieniu!",
    updatingNotification: "Aktualizowanie powiadomienia...",
    updatingNotifications: "Aktualizowanie powiadomień...",
    followLink: "Przejdź do linku",
    youSureYouWantToReadThemAll:
      "Czy na pewno chcesz oznaczyć wszystkie powiadomienia jako przeczytane?",
    markAllAsRead: "Oznacz wszystkie jako przeczytane",
    cancel: "Anuluj",
    settings: "Ustawienia",
    farmProfile: "Profil farmy",
    hiveProfile: "Profil pasieki",
    companyInfo: "Dane firmy",
    farmName: "Nazwa farmy",
    farmNameRequired: "Nazwa farmy jest wymagana!",
    address: "Adres",
    location: "Lokalizacja",
    selectLocation: "Lokalizacja (np. Budynek A, Strefa 4, Zagroda 2)",
    setFarmImage: "Ustaw nowe zdjęcie farmy",
    takePhoto: "Zrób zdjęcie",
    choosePhoto: "Wybierz zdjęcie",
    changePhoto: "Zmień zdjęcie",
    deletePhoto: "Usuń zdjęcie",
    farmInfo: "Dane firmy",
    addFarmName: "Nazwa firmy",
    addCui: "NIP",
    accountSettings: "Ustawienia konta",
    myProfile: "Mój profil",
    resetPassword: "Zresetuj hasło",
    notificationPreferences: "Preferencje powiadomień",
    email: "Adres e-mail",
    pushNotifications: "Powiadomienia push",
    editPhone: "Edytuj telefon",
    setUserImage: "Ustaw nowe zdjęcie profilowe",
    inputCurrentPassword: "Wprowadź aktualne hasło",
    inputNewPassword: "Wprowadź nowe hasło",
    confirmNewPassword: "Potwierdź nowe hasło",
    information: "Informacje",
    tutorials: "Poradniki",
    faq: "Często zadawane pytania",
    confidentiality: "Polityka prywatności",
    termsAndServices: "Warunki i usługi",
    appVersion: "Wersja aplikacji",
    whatIsFarmApp: "Czym jest FARM APP?",
    lastUpdate: "Ostatnia aktualizacja",
    yourOpinion: "Twoja opinia",
    recommend: "Poleć",
    likeUs: "Polub nas w mediach społecznościowych",
    contactUs: "Skontaktuj się z nami",
    followUs: "Obserwuj nas",
    recommendUsing: "Poleć aplikację za pomocą:",
    subject: "Temat",
    message: "Wiadomość",
    signOut: "Wyloguj się",
    signOutConfirmation: "Czy na pewno chcesz opuścić aplikację?",
    deletePhotoConfirmation: "Czy na pewno chcesz usunąć zdjęcie?",
    linkWillBeHere: "Link do naszej aplikacji będzie tutaj",
    farmProfileUpdated: "Profil farmy został zaktualizowany!",
    userProfileUpdated: "Profil został zaktualizowany!",
    errorOccurred: "Wystąpił błąd!",
    processing: "Przetwarzanie...",
    contact: "Kontakt",
    lostPassword: "Zapomniałem hasła",
    password: "Hasło",
    lostYourPassword: "Zapomniałeś hasła?",
    dontWorryHappensToAllOfUs: "Nie martw się, to się zdarza wszystkim!",
    enterEmailResetPassword:
      "Wprowadź swój adres e-mail poniżej, a wyślemy ci link do resetowania hasła.",
    wrongLoginCredentials: "Nieprawidłowy adres e-mail lub hasło!",
    login: "Logowanie",
    logingIn: "Logowanie...",
    back: "Wstecz",
    welcome: "Witaj!",
    nameUserRequired: "Imię jest wymagane!",
    lastNameRequired: "Nazwisko jest wymagane!",
    lastNameMaxLength: "Nazwisko nie może przekraczać 100 znaków",
    emailRequired: "Adres e-mail jest wymagany!",
    phoneNumberRequired: "Numer telefonu jest wymagany!",
    send: "Wyślij",
    urlCopiedSuccess: "URL został skopiowany pomyślnie!",

    // Authentication
    createAccount: "Utwórz konto",
    creatingAccount: "Tworzenie konta...",
    signupSuccessful: "Rejestracja zakończona pomyślnie!",
    verificationEmailSent: "Wysłano e-mail weryfikacyjny",
    pleaseCheckYourEmail:
      "Sprawdź swoją skrzynkę e-mail, aby zweryfikować konto",
    passwordResetSuccessful: "Resetowanie hasła zakończone pomyślnie!",
    yourPasswordHasBeenReset: "Twoje hasło zostało pomyślnie zresetowane",
    passwordChangeSuccessful: "Zmiana hasła zakończona pomyślnie!",
    yourPasswordHasBeenChanged: "Twoje hasło zostało pomyślnie zmienione",
    changePassword: "Zmień hasło",
    changing: "Zmienianie...",
    failedToChangePassword: "Nie udało się zmienić hasła",
    incorrectCurrentPassword: "Aktualne hasło jest nieprawidłowe",
    sessionExpired: "Twoja sesja wygasła",
    pleaseRefreshAndTryAgain: "Odśwież stronę i spróbuj ponownie",
    proceedToLogin: "Przejdź do logowania",
    backToLogin: "Powrót do logowania",
    passwordsDoNotMatch: "Hasła nie są zgodne",
    passwordTooShort: "Hasło musi mieć co najmniej 8 znaków",
    failedToSignUp: "Nie udało się zarejestrować",
    failedToResetPassword: "Nie udało się zresetować hasła",
    dontHaveAccount: "Nie masz konta?",
    alreadyHaveAccount: "Masz już konto?",
    firstName: "Imię",
    lastName: "Nazwisko",
    confirmPassword: "Potwierdź hasło",
    newPassword: "Nowe hasło",
    resetYourPassword: "Zresetuj swoje hasło",
    enterNewPassword: "Wprowadź nowe hasło poniżej",
    passwordResetEmailSent: "Wysłano e-mail do resetowania hasła",
    checkYourEmailForResetLink:
      "Sprawdź swoją skrzynkę e-mail, aby znaleźć link do resetowania hasła",
    fillInDetailsBelow: "Wypełnij poniższe dane, aby utworzyć konto",
    invalidResetLink: "Nieprawidłowy link do resetowania",
    sending: "Wysyłanie...",
    resetting: "Resetowanie...",

    // Email verification
    verifyingEmail: "Weryfikacja e-maila",
    pleaseWait: "Proszę czekać...",
    emailVerificationSuccessful: "Weryfikacja e-maila zakończona pomyślnie!",
    youCanNowLogin:
      "Twój e-mail został zweryfikowany. Możesz teraz zalogować się na swoje konto.",
    emailVerificationFailed: "Weryfikacja e-maila nie powiodła się",
    invalidVerificationLink: "Nieprawidłowy link weryfikacyjny",
    invalidOrExpiredLink: "Link weryfikacyjny jest nieprawidłowy lub wygasł",

    // Gestation Period Calendar
    stepByStepGestationEvolution: "Rozwój ciąży etap po etapie",
    computeProbableBirthDate: "Oblicz prawdopodobną datę porodu",
    testNow: "Przetestuj teraz",
    confirm: "Potwierdź",
    underDevelopment: "Ta funkcjonalność jest jeszcze w fazie rozwoju",
    notAvailableInPackage: "Ta funkcjonalność nie jest dostępna",
    gestationalCalculator: "Kalkulator ciąży",
    createNewCalendar: "Stwórz nowy kalendarz",
    generateCalendar: "Wygeneruj kalendarz",
    saveCalendar: "Zapisz kalendarz",
    calendarSavedSuccessfully: "Kalendarz został pomyślnie zapisany!",
    animalMustBeFemale: "Aby stworzyć kalendarz ciąży zwierzę musi być samicą",
    animalMustBeCow: "Kalendarz ciąży jest dostępny tylko dla krów",
    loadingCalendarPreview: "Ładowanie podglądu kalendarza...",
    previewLoadedSuccessfully: "Podgląd został pomyślnie załadowany!",
    errorLoadingPreview: "Wystąpił błąd podczas ładowania podglądu!",
    errorFetchingAnimal: "Wystąpił błąd podczas pobierania danych zwierzęcia!",
    searchCalendar: "Szukaj kalendarza",
    selectAnimal: "Wybierz zwierzę",
    gestationalCalendar: "Kalendarz ciąży",
    deleteCalendar: "Usuń kalendarz",
    deletingCalendar: "Usuwanie kalendarza...",
    calendarDeletedSuccessfully: "Kalendarz został pomyślnie usunięty!",
    calendarDeleteError: "Wystąpił błąd podczas usuwania kalendarza!",
    calendarWillBeDeleted: "Bieżący kalendarz zostanie usunięty.",
    deleteCalendarConfirmation: "Czy na pewno chcesz usunąć kalendarz?",
    deleteAssocAnimalEvents:
      "Czy chcesz również usunąć wydarzenia związane z kalendarzem?",
    toCalving: "do porodu",
    untilCalvingIntervalEnd: "do końca interwału",
    days: "dni",
    toNextTask: "do następnego zadania",
    hours: "godziny",
    minutes: "minuty",
    nextActivity: "Następna aktywność",
    gestationalCalendarText:
      "Oblicz datę porodu i wygeneruj przydatne zadania na okres ciąży.",
    calendarPreviewLoadedSuccessfully:
      "Podgląd kalendarza został pomyślnie załadowany!",
    // Finance Widget
    financialJournal: "Dziennik finansowy",
    searchTransaction: "Szukaj transakcji",
    addTransaction: "Dodaj transakcję",
    transaction: "Transakcja",
    transactions: "Transakcje",
    EXPENSES: "Wydatki",
    Outflow: "Wydatki",
    INCOMES: "Przychody",
    Inflow: "Przychody",
    selectTransactionCategory: "Wybierz kategorię transakcji",
    EU_FOUNDS: "Fundusze unijne",
    PROFIT: "Zysk",
    SELLING_ANIMALS: "Sprzedaż zwierząt",
    DONATIONS: "Darowizny",
    SELLING_GOODS: "Sprzedaż produktów",
    EQUIPMENT_ACQUISITION: "Zakup sprzętu",
    MAINTENANCE: "Utrzymanie",
    ANIMALS_FOOD: "Karma dla zwierząt",
    TREATMENTS: "Leczenie",
    TRANSPORTATION: "Transport",
    VETERINARY: "Usługi weterynaryjne",
    SALARIES: "Wynagrodzenia",
    SOFTWARE_ACQUISITION: "Zakup oprogramowania",
    ADD_CATEGORY: "Dodaj kategorię",
    categoryName: "Nazwa kategorii",
    loadingCategories: "Ładowanie kategorii...",
    categorySaved: "Kategoria została zapisana!",
    errorCreatingCategory: "Wystąpił błąd podczas tworzenia kategorii!",
    savingCategory: "Zapisywanie kategorii...",
    updatingCategory: "Aktualizowanie kategorii...",
    categoryUpdated: "Kategoria została zaktualizowana!",
    errorUpdatingCategory: "Wystąpił błąd podczas aktualizacji kategorii!",
    deleteCategory: "Usuń kategorię",
    deletingCategory: "Usuwanie kategorii...",
    categoryDeleted: "Kategoria została usunięta!",
    errorDeletingCategory: "Wystąpił błąd podczas usuwania kategorii!",
    transactionHistory: "Historia transakcji",
    savingTransaction: "Zapisywanie transakcji...",
    transactionSaved: "Transakcja została zapisana!",
    transactionSaveError: "Wystąpił błąd podczas zapisywania transakcji!",
    deleteTransactionConfirmation: "Czy na pewno chcesz usunąć transakcję?",
    deleteTransactionCategoryConfirmation:
      "Czy na pewno chcesz usunąć kategorię?",
    transactionCategoryWillBeDeleted: "Kategoria transakcji zostanie usunięta.",
    transactionWillBeDeleted: "Bieżąca transakcja zostanie usunięta.",
    deleteTransaction: "Usuń transakcję",
    transactionDeleted: "Transakcja została usunięta!",
    deletingTransaction: "Usuwanie transakcji...",
    startDate: "Data początkowa",
    viewStats: "Zobacz statystyki",
    endDate: "Data końcowa",
    last_week: "Zeszły tydzień",
    last_month: "Zeszły miesiąc",
    current_month: "Bieżący miesiąc",
    last_year: "Zeszły rok",
    custom: "Niestandardowy okres",
    selectTimePeriod: "Wybierz okres czasu",
    all_time: "Cały okres",
    current_week: "Bieżący tydzień",
    noTransactionsFound: "Ups, nie znaleziono żadnych transakcji...",
    noAnimalsFound: "Ups, nie znaleziono żadnych zwierząt...",
    lastTransactions: "Ostatnie transakcje",

    amountRequired: "Kwota jest wymagana do utworzenia transakcji",
    dateRequired: "Data jest wymagana do utworzenia transakcji",
    categoryRequired: "Kategoria jest wymagana do utworzenia transakcji",
    balance: "Saldo",
    financialStats: "Statystyki finansowe",
    weAreSorry: "Przepraszamy...",
    anErrorOccurred: "Wystąpił błąd.",
    reloadApp: "Przeładuj aplikację",
    events: "Wydarzenia",
    addEvent: "Dodaj wydarzenie",
    addAnimalExit: "Wyjście zwierzęcia",
    confirmCalving: "Potwierdź poród",
    calvingConfirmed: "Poród został potwierdzony",
    selectEventType: "Wybierz typ wydarzenia",
    selectMedication: "Wybierz lek",
    Treatment: "Leczenie",
    Measurement: "Pomiar",
    Movement: "Przemieszczenie",
    Insemination: "Inseminacja",
    NaturalMating: "Krycie naturalne",
    PregnancyCheckPositive: "Pozytywne badanie ciąży",
    PregnancyCheckNegative: "Negatywne badanie ciąży",
    HealthCheckPositive: "Pozytywne badanie zdrowia",
    HealthCheckNegative: "Negatywne badanie zdrowia",
    HoofHealthCheck: "Kontrola zdrowia kopyta",
    HeatDetection: "Wykrywanie rui",
    Abortion: "Poronienie",
    DryOff: "Zakończenie laktacji",
    Weaning: "Odsadzenie",
    LactationStart: "Rozpoczęcie laktacji",
    LactationEnd: "Zakończenie laktacji",
    DietChange: "Zmiana diety",
    Shearing: "Strzyżenie",
    HoofTrimming: "Przycinanie kopyt",
    EarTagging: "Kolczykowanie uszu",
    AnimalTransferExitEventType: "Wydarzenia wyjścia",
    AnimalSacrifice: "Ubój",
    AnimalSale: "Sprzedaż zwierzęcia",
    AnimalDeath: "Śmierć zwierzęcia",
    AnimalLoss: "Utrata zwierzęcia",
    AnimalTransferExit: "Transfer wyjścia",
    Castration: "Kastracja",
    Dehorning: "Dekornizacja",
    VeterinaryVisit: "Wizyta weterynaryjna",
    BehaviorObservation: "Obserwacja zachowania",
    EntryIntoQuarantine: "Wejście do kwarantanny",
    ExitFromQuarantine: "Wyjście z kwarantanny",
    Isolation: "Izolacja",
    Vaccination: "Szczepienie",
    IllnessTreatment: "Leczenie choroby",
    Deworming: "Odrobaczanie",
    InjuryTreatment: "Leczenie urazu",
    Surgery: "Operacja",
    PostSurgeryFollowUp: "Kontrola pooperacyjna",
    SupplementAdministration: "Podawanie suplementów",
    WeightCheck: "Kontrola wagi",
    BodyConditionScoring: "Ocena kondycji ciała",
    Other: "Inne",
    addEventType: "Dodaj nowy typ wydarzenia",
    addMedicationType: "Dodaj nowy rodzaj leczenia",
    eventName: "Nazwa wydarzenia",
    medicationName: "Nazwa leku",
    deleteEventType: "Usuń typ wydarzenia",
    fromLocation: "Z lokalizacji",
    fromPosition: "Z pozycji",
    toPosition: "Do pozycji",
    toLocation: "Do lokalizacji",
    departureDate: "Data wyjazdu",
    arrivalDate: "Data przyjazdu",
    HeightCheck: "Kontrola wysokości",
    weight: "Waga",
    otherObservations: "Inne obserwacje",
    generalEventDetails: "Ogólne szczegóły wydarzenia",
    specificEventDetails: "Szczegółowe informacje o wydarzeniu",
    transactionAmount: "Kwota transakcji",
    value: "Wartość",
    savingEvent: "Zapisywanie wydarzenia...",
    eventSaved: "Wydarzenie zostało zapisane!",
    viewEvent: "Zobacz wydarzenie",
    errorSavingEvent: "Wystąpił błąd podczas zapisywania wydarzenia!",
    dosage: "Dawkowanie",
    deleteAnimalEvent: "Usuń wydarzenie",
    animalEvent: "Wydarzenie zwierzęcia",
    deletingAnimalEvent: "Usuwanie wydarzenia...",
    animalEventDeleted: "Wydarzenie zostało usunięte!",
    animalEventDeleteError: "Wystąpił błąd podczas usuwania wydarzenia!",
    deleteAnimalEventConfirmation: "Czy na pewno chcesz usunąć wydarzenie?",
    eventWillBeDeleted:
      "Bieżące wydarzenie zostanie usunięte, jeśli się zgodzisz.",
    updatingAnimalEvent: "Aktualizowanie wydarzenia...",
    animalEventUpdated: "Wydarzenie zostało zaktualizowane!",
    animalEventUpdateError: "Wystąpił błąd podczas aktualizacji wydarzenia!",
    typeRequired: "Typ jest wymagany do utworzenia wydarzenia",
    dateRequiredEvent: "Data jest wymagana do utworzenia wydarzenia",
    animalRequired: "Zwierzę jest wymagane do utworzenia wydarzenia",
    fromLocationRequired:
      "Lokalizacja wyjściowa jest wymagana do utworzenia tego wydarzenia",
    toLocationRequired:
      "Lokalizacja docelowa jest wymagana do utworzenia tego wydarzenia",
    valueRequired: "Wartość jest wymagana do utworzenia tego wydarzenia",
    dosageRequired: "Dawkowanie jest wymagane do utworzenia tego wydarzenia",
    medicationRequired: "Lek jest wymagany do utworzenia tego wydarzenia",
    quantityRequired: "Ilość jest wymagana do utworzenia tego wydarzenia",
    positionRequired: "Pozycja jest wymagana do utworzenia tego wydarzenia",
    toApiaryRequired:
      "Docelowa pasieka jest wymagana do utworzenia tego wydarzenia",
    TreatmentEventType: "Wydarzenia leczenia",
    MeasurementEventType: "Wydarzenia pomiarów",
    MovementEventType: "Wydarzenia przemieszczenia",
    ReproductiveEventType: "Wydarzenia reprodukcyjne",
    OtherEventType: "Inne wydarzenia",
    searchAnimalEvent: "Szukaj wydarzenia zwierzęcia",

    animalEvents: "Wydarzenia zwierząt",
    savingEventType: "Zapisywanie typu wydarzenia...",
    savingMedicationType: "Zapisywanie rodzaju leczenia...",
    eventTypeSaved: "Typ wydarzenia został zapisany!",
    medicationTypeSaved: "Rodzaj leczenia został zapisany!",
    errorCreatingEventType: "Wystąpił błąd podczas tworzenia typu wydarzenia",
    errorCreatingMedicationType:
      "Wystąpił błąd podczas tworzenia rodzaju leczenia",
    updatingEventType: "Aktualizowanie typu wydarzenia",
    updatingMedicationType: "Aktualizowanie rodzaju leczenia",
    eventTypeUpdated: "Pomyślnie zaktualizowano!",
    medicationTypeUpdated: "Pomyślnie zaktualizowano!",
    errorUpdatingEventType:
      "Wystąpił błąd podczas aktualizacji typu wydarzenia!",
    errorUpdatingMedicationType:
      "Wystąpił błąd podczas aktualizacji rodzaju leczenia!",
    deleteEventTypeConfirmation: "Czy na pewno chcesz usunąć typ wydarzenia?",
    deleteMedicationTypeConfirmation:
      "Czy na pewno chcesz usunąć rodzaj leczenia?",
    eventTypeWillBeDeleted: "Typ wydarzenia zostanie usunięty.",
    medicationTypeWillBeDeleted: "Rodzaj leczenia zostanie usunięty.",
    deletingEventType: "Usuwanie typu wydarzenia...",
    deletingMedicationType: "Usuwanie rodzaju leczenia...",
    eventTypeDeleted: "Typ wydarzenia został usunięty!",
    medicationTypeDeleted: "Rodzaj leczenia został usunięty!",
    errorDeletingEventType: "Wystąpił błąd podczas usuwania typu wydarzenia",
    errorDeletingMedicationType:
      "Wystąpił błąd podczas usuwania rodzaju leczenia",
    chooseEventTypeParent: "Wybierz kategorię wydarzenia",
    intelligentAssistant: "Inteligentny asystent",
    yourMessage: "Twoja wiadomość",
    generalHealthStatus: "Jaki jest ogólny stan zdrowia stada bydła?",
    immediateMedicalAttention:
      "Które krowy wymagają natychmiastowej opieki weterynaryjnej?",
    optimizeFeeding:
      "Jak mogę zoptymalizować karmienie, aby zwiększyć produkcję mleka?",
    closeToCalving: "Które krowy są bliskie porodu?",
    generalHealthStatusPigs: "Jaki jest ogólny stan zdrowia stada świń?",
    growthRate: "Jaki jest wskaźnik wzrostu świń w ostatnim miesiącu?",
    pregnantSows: "Które ciężarne lochy wymagają szczególnej uwagi?",
    weaningStatus: "Które prosięta mają zostać odsadzone?",
    diseasePreventionStatus:
      "Jaki jest status środków zapobiegania chorobom w stadzie?",
    generalHealthStatusGoat: "Jaki jest ogólny stan zdrowia stada kóz?",
    milkProduction: "Jak wygląda produkcja mleka w ostatnim okresie?",
    pregnantGoats: "Które ciężarne kozy wymagają monitorowania?",
    kidsHealth: "Jaki jest stan zdrowia koźląt?",
    generalHealthStatusSheep: "Jaki jest ogólny stan zdrowia stada owiec?",
    lambingStatus: "Które owce będą rodzić w najbliższym czasie?",
    lambGrowth: "Jaki jest wskaźnik wzrostu jagniąt?",
    generalHealthStatusBees: "Jaki jest ogólny stan zdrowia rodzin pszczelich?",
    honeyProduction:
      "Jak wygląda produkcja miodu w porównaniu do poprzedniego okresu?",
    queenStatus: "Jaki jest stan matek w koloniach?",
    welcomeToAssistant: "Witaj w Inteligentnym Asystencie!",
    startConversation: "Rozpocznij rozmowę od jednej z naszych sugestii.",
    loading: "Ładowanie...",
    UNDER_TREATMENT: "Leczenie",
    noResultsForAnimal: "Nie znaleziono wyników dla określonego zwierzęcia.",
    askAssistant: "Zapytaj asystenta",
    virtualAssistant: "Wirtualny asystent",
    virtualFarmAssistant: "Wirtualny asystent farmy",
    farmAdviceWithAI:
      "Spersonalizowane porady i informacje dla farmy z nowym zintegrowanym czatem AI.",
    noAnimalsFoundForFilter: "Nie znaleziono zwierząt dla wybranego filtru",
    gestationCalendars: "Kalendarze ciąży",
    activityCalendar: "Kalendarz aktywności",
    inseminationDate: "Data inseminacji",
    possibleCalvingInterval: "Prawdopodobny przedział porodu",
    calvingDate: "Data porodu",
    openCalendar: "Otwórz kalendarz",
    seeEvents: "Pokaż wydarzenia",
    hideEvents: "Ukryj wydarzenia",
    thisFeatureIsNotEnabled: "Ten moduł nie jest aktywny",
    buyFeature: "Kup moduł",
    noGestationCalendarsPresent: "Nie masz żadnego kalendarza ciąży",
    filterByAge: "Filtruj według wieku",
    currentInterval: "Bieżący przedział",
    resetFilter: "Resetuj filtr",
    quickSelection: "Szybki wybór",
    myApiaries: "Moje pasieki",
    myApiary: "Moja pasieka",
    myHives: "Moje ule",
    myQueens: "Moje matki",
    addApiary: "Dodaj pasiekę",
    addHive: "Dodaj ul",
    isLoading: "Ładowanie...",
    hiveDetails: "Szczegóły ula",
    apiaryDetails: "Szczegóły pasieki",
    hiveImage: "Obraz ula",
    hiveCode: "Kod/Unikalna nazwa",
    hiveType: "Typ ula",
    queenBirthYear: "Rok urodzenia matki",
    queenOrigin: "Pochodzenie matki",
    queen: "Matka",
    framesNumber: "Liczba ramek",
    familyPower: "Siła rodziny",
    beeCount: "Liczba pszczół",
    broodFramesNumber: "Liczba ramek z czerwiem",
    honeyFramesNumber: "Liczba ramek z miodem",
    emptyFramesNumber: "Liczba pustych ramek",
    apiaryName: "Nazwa pasieki",
    apiaryAddress: "Adres",
    dsvsa: "Nr rejestracyjny/autoryzacyjny",
    nameRequired: "Nazwa jest wymagana!",
    nameMinLength: "Minimalna długość nazwy to 2 znaki!",
    addressRequired: "Adres jest wymagany!",
    addressMinLength: "Minimalna długość adresu to 5 znaków!",
    addressMaxLength: "Maksymalna długość adresu to 200 znaków!",
    registrationCodeRequired: "Kod rejestracyjny jest wymagany!",
    registrationCodeFormat:
      "Kod rejestracyjny może zawierać tylko litery, cyfry i myślniki!",
    descriptionMaxLength: "Maksymalna długość opisu to 1000 znaków!",
    creatingApiary: "Tworzenie pasieki...",
    apiaryCreated: "Pasieka została utworzona!",
    apiaryCreateError: "Wystąpił błąd podczas tworzenia pasieki",
    updatingApiary: "Aktualizowanie pasieki...",
    deletingApiary: "Usuwanie pasieki...",
    apiaryDeleted: "Pasieka została usunięta",
    apiaryUpdated: "Pasieka została zaktualizowana!",
    apiaryUpdateError: "Wystąpił błąd podczas aktualizacji pasieki",
    apiaryDeleteError: "Wystąpił błąd podczas usuwania pasieki",
    registrationCode: "Kod rejestracyjny",
    hivesNumber: "Liczba uli",
    deleteApiary: "Usuń pasiekę",
    apiaries: "Pasieki",
    hives: "Ule",
    deleteApiaryConfirmation: "Czy na pewno chcesz usunąć pasiekę?",
    apiaryWillBeDeleted:
      "Pasieka wraz ze wszystkimi powiązanymi danymi, w tym ulami, matkami i ich wydarzeniami zostanie usunięta",
    hiveManufacturer: "Producent ula",
    creatingHive: "Tworzenie ula...",
    hiveCreatedSuccessfully: "Ul został pomyślnie utworzony!",
    errorCreatingHive: "Wystąpił błąd podczas tworzenia ula!",
    apiary: "Pasieka",
    apiaryRequired: "Pasieka jest wymagana",
    apiaryFormat: "Kod pasieki może zawierać tylko litery, cyfry i myślniki",
    codeRequired: "Kod ula jest wymagany",
    codeFormat: "Kod ula może zawierać tylko litery, cyfry i myślniki",
    codeMinLength: "Kod ula musi mieć co najmniej 2 znaki",
    codeMaxLength: "Kod ula nie może przekraczać 50 znaków",
    hiveTypeRequired: "Typ ula jest wymagany",
    invalidHiveType: "Wybrany typ ula jest nieprawidłowy",
    manufacturerRequired: "Producent ula jest wymagany",
    manufacturerMinLength: "Nazwa producenta musi mieć co najmniej 2 znaki",
    manufacturerMaxLength: "Nazwa producenta nie może przekraczać 100 znaków",
    broodFramesRequired: "Liczba ramek z czerwiem jest wymagana",
    broodFramesFormat: "Liczba ramek z czerwiem musi być liczbą całkowitą",
    broodFramesRange: "Liczba ramek z czerwiem musi być między 0 a 100",
    honeyFramesRequired: "Liczba ramek z miodem jest wymagana",
    honeyFramesFormat: "Liczba ramek z miodem musi być liczbą całkowitą",
    honeyFramesRange: "Liczba ramek z miodem musi być między 0 a 100",
    emptyFramesRequired: "Liczba pustych ramek jest wymagana",
    emptyFramesFormat: "Liczba pustych ramek musi być liczbą całkowitą",
    emptyFramesRange: "Liczba pustych ramek musi być między 0 a 100",
    beeCountRequired: "Liczba pszczół jest wymagana",
    beeCountFormat: "Liczba pszczół musi być liczbą całkowitą",
    beeCountRange: "Liczba pszczół musi być między 0 a 100000",
    addQueen: "Dodaj matkę",
    deleteHive: "Usuń ul",
    hiveUpdating: "Aktualizowanie ula...",
    hiveUpdatedSuccessfully: "Ul został zaktualizowany!",
    errorUpdatingHive: "Wystąpił błąd podczas aktualizacji ula",
    deleteHiveConfirmation: "Czy na pewno chcesz usunąć ul?",
    hiveWillBeDeleted:
      "Ul wraz ze wszystkimi powiązanymi danymi, w tym matką i wydarzeniami zostanie usunięty",
    deletingHive: "Usuwanie ula...",
    deletingQueen: "Usuwanie matki...",
    queenDeleted: "Matka została usunięta!",
    hiveDeleted: "Ul został usunięty!",
    queenDeleteError: "Wystąpił błąd podczas usuwania matki",
    hiveDeleteError: "Wystąpił błąd podczas usuwania ula",
    yearOfBirth: "Rok urodzenia",
    queenColor: "Kolor matki",
    "Own Production": "Własna produkcja",
    Purchased: "Zakupiona",
    Blue: "Niebieski",
    Green: "Zielony",
    Red: "Czerwony",
    Yellow: "Żółty",
    Black: "Czarny",
    White: "Biały",
    nameFormat:
      "Nazwa matki może zawierać tylko litery, cyfry, spacje i myślniki",
    yearRequired: "Rok jest wymagany",
    yearFormat: "Rok musi być w formacie RRRR (np.: 2024)",
    yearRange: "Rok musi być między ostatnimi 5 latami a rokiem bieżącym",
    colorRequired: "Kolor matki jest wymagany",
    invalidColor: "Wybrany kolor jest nieprawidłowy",
    originRequired: "Pochodzenie matki jest wymagane",
    invalidOrigin: "Wybrane pochodzenie jest nieprawidłowe",
    creatingQueen: "Tworzenie matki...",
    queenCreated: "Matka została utworzona!",
    queenCreateError: "Wystąpił błąd podczas tworzenia matki",
    hive: "Ul",
    deleteQueen: "Usuń matkę",
    queenUpdating: "Aktualizowanie matki",
    queenUpdatedSuccessfully: "Matka została zaktualizowana!",
    errorUpdatingQueen: "Wystąpił błąd podczas aktualizacji matki",
    deleteQueenConfirmation: "Czy na pewno chcesz usunąć matkę?",
    queenWillBeDeleted:
      "Matka wraz ze wszystkimi powiązanymi danymi, notatkami, zadaniami i wydarzeniami zostanie usunięta",
    bees: "Pszczoły",
    allApiaries: "Wszystkie pasieki",
    searchHive: "Szukaj ula",
    allHiveTypes: "Wszystkie typy uli",
    apiaryWasSuccessfullyAdded: "Pasieka została pomyślnie dodana!",
    hiveStep:
      "Następnym krokiem jest dodanie uli do twojej nowej pasieki, czy chcesz kontynuować?",
    hiveWasSuccessfullyAdded: "Ul został pomyślnie dodany!",
    queenStep:
      "Następnym krokiem jest dodanie matki do twojego nowego ula, czy chcesz kontynuować?",
    noHivesFoundForGivenQuery: "Nie znaleziono uli dla twojego wyszukiwania",
    noQueensFoundForGivenQuery: "Nie znaleziono matek dla twojego wyszukiwania",
    allQueens: "Wszystkie matki",
    assocApiary: "Powiąż pasiekę",
    assocHive: "Powiąż ul",
    noApiariesAssociated: "Brak powiązanych pasiek",
    noHivesAssociated: "Brak powiązanych uli",
    oneApiaryAssociated: "Jedna powiązana pasieka",
    oneHiveAssociated: "Jeden powiązany ul",
    apiariesAssociated: "powiązane pasieki",
    hivesAssociated: "powiązane ule",
    generalAnimalWelfare: "Jaki jest ogólny stan dobrostanu zwierząt?",
    diseasePreventionPlan:
      "Jaki jest plan zapobiegania chorobom dla wszystkich zwierząt?",
    feedingStrategyGeneral:
      "Czy strategia karmienia wszystkich zwierząt jest optymalna?",
    reproductiveHealthGeneral:
      "Jaka jest sytuacja zdrowia reprodukcyjnego w stadzie?",
    udderHealth: "Jaki jest stan zdrowia wymion u krów?",
    heatDetection: "Które krowy wykazują oznaki rui?",
    pigletMortality: "Jaki jest wskaźnik śmiertelności prosiąt?",
    boarPerformance: "Jaka jest wydajność knurów rozpłodowych?",
    hoofHealth: "Jaki jest stan kopyt kóz?",
    pasturePlanning: "Czy plan wypasu jest efektywny?",
    flockBehavior: "Czy są zmiany w zachowaniu stada?",
    sheepSorting: "Które owce wymagają sortowania do różnych grup?",
    hiveStrength: "Jaka jest siła rodzin pszczelich?",
    varoaStatus: "Jaki jest poziom zarażenia warrozą?",
    nectarSources: "Czy w okolicy jest wystarczająco dużo źródeł nektaru?",
    biosecurityMeasures: "Jakie środki bioasekuracji są wdrożone?",
    selectApiaries: "Wybierz pasieki",
    selectApiary: "Wybierz pasiekę",
    selectHive: "Wybierz ul",
    selectHives: "Wybierz ule",
    noResultsFound: "Nie znaleziono wyników",
    hideOtherApiariesAssociated: "Ukryj inne powiązane pasieki",
    hideOtherHivesAssociated: "Ukryj inne powiązane ule",
    seeOtherApiariesAssociated: "Zobacz inne powiązane pasieki",
    seeOtherHivesAssociated: "Zobacz inne powiązane ule",
    noApiaryFound: "Nie znaleziono żadnej pasieki",
    noQueensAssociated: "Brak powiązanych matek",
    oneQueenAssociated: "Jedna powiązana matka",
    queensAssociated: "powiązane matki",
    selectQueens: "Wybierz matki",
    assocQueen: "Powiąż matkę",
    theHive: "Ul",
    addApiaryEvent: "Dodaj wydarzenie pasieki",
    noApiarySelected: "Nie wybrano żadnej pasieki",
    noHiveSelected: "Nie wybrano żadnego ula",
    PestControl: "Kontrola szkodników",
    VegetationManagement: "Zarządzanie roślinnością",
    EquipmentMaintenance: "Konserwacja sprzętu",
    InfrastructureRepair: "Naprawa infrastruktury",
    ApiaryMaintenance: "Konserwacja pasieki",
    SpringAssessment: "Ocena wiosenna",
    WinterPreparationCheck: "Sprawdzenie przygotowania do zimy",
    AuthorityInspection: "Inspekcja władz",
    OfficialVeterinaryInspection: "Oficjalna inspekcja weterynaryjna",
    ApiaryInspection: "Inspekcja pasieki",
    PastoralMovement: "Przemieszczenie pasterskie",
    PermanentLocationChange: "Stała zmiana lokalizacji",
    ApiaryMovement: "Przemieszczenie pasieki",
    personName: "Imię i nazwisko osoby",
    eventTypeRequired: "Typ wydarzenia jest wymagany",
    eventTypeFormat:
      "Typ wydarzenia powinien zawierać tylko litery, cyfry i myślniki",
    personNameMaxLength: "Imię i nazwisko nie może przekraczać 255 znaków",
    dateFormat: "Nieprawidłowy format daty",
    notesMaxLength: "Notatki nie mogą przekraczać 1000 znaków",
    creatingApiaryEvent: "Tworzenie wydarzenia pasieki...",
    apiaryEventCreatedSuccessfully:
      "Wydarzenie pasieki zostało pomyślnie utworzone!",
    errorCreatingApiaryEvent:
      "Wystąpił błąd podczas tworzenia wydarzenia pasieki",
    apiariesEvents: "Wydarzenia pasiek",
    apiaryEvent: "Wydarzenie pasieki",
    deletingApiaryEvent: "Usuwanie wydarzenia pasieki...",
    apiaryEventDeleted: "Wydarzenie pasieki zostało usunięte!",
    apiaryEventDeleteError: "Wystąpił błąd podczas usuwania wydarzenia pasieki",
    ApiaryMovementEventType: "Wydarzenia przemieszczenia pasieki",
    ApiaryInspectionEventType: "Wydarzenia inspekcji pasieki",
    ApiaryMaintenanceEventType: "Wydarzenia konserwacji pasieki",
    Acacia: "Akacja",
    Linden: "Lipa",
    Sunflower: "Słonecznik",
    Rapeseed: "Rzepak",
    "Poly Floral": "Wielokwiatowy",
    selectFloraType: "Wybierz typ flory",
    apiaryEvents: "Wydarzenia pasieki",
    apiaryEventsDescription: "Monitoruj działalność swoich pasiek",
    hiveEventsDescription: "Monitoruj działalność swoich uli",
    noEventsFound: "Nie znaleziono wydarzeń",
    departureDateRequired: "Data wyjazdu jest wymagana",
    floraTypeRequired: "Typ flory jest wymagany",
    partners: "Partnerzy",
    viewOffer: "Zobacz ofertę",
    allTimes: "Wszystkie okresy",
    selectTimeFrame: "Wybierz okres",
    dateTo: "Data końcowa",
    dateFrom: "Data początkowa",
    present: "Obecny",
    selectDateTo: "Wybierz datę końcową",
    selectDateFrom: "Wybierz datę początkową",
    showResults: "Pokaż wyniki",
    hiveEvents: "Wydarzenia uli",
    queenEvents: "Wydarzenia matek",
    queenEventsDescription: "Monitoruj działalność swoich matek",
    allHives: "Wszystkie ule",
    creatingHiveEvent: "Tworzenie wydarzenia ula...",
    hiveEventCreatedSuccessfully: "Wydarzenie ula zostało pomyślnie utworzone!",
    errorCreatingHiveEvent: "Wystąpił błąd podczas tworzenia wydarzenia ula",
    "Hive must have a queen for this operation":
      "Dla tej operacji ul musi mieć matkę",
    ColonyDivision: "Podział kolonii",
    SwarmPrevention: "Zapobieganie rojeniu",
    RemovingSuper: "Usuwanie nadstawki",
    AddingSuper: "Dodawanie nadstawki",
    AddingFrames: "Dodawanie ramek",
    QueenReplacement: "Wymiana matki",
    Feeding: "Dokarmianie",
    HiveManagement: "Zarządzanie ulem",
    WeightMeasurement: "Pomiar wagi",
    BroodFramesCount: "Aktualizacja liczby ramek z czerwiem",
    HoneyFramesCount: "Aktualizacja liczby ramek z miodem",
    ColonyStrengthAssessment: "Ocena siły kolonii",
    HiveMeasurement: "Pomiar ula",
    PreventiveTreatment: "Leczenie profilaktyczne",
    DiseaseTreatment: "Leczenie chorób",
    NosemaTreatment: "Leczenie nosemozy",
    VarroaTreatment: "Leczenie warrozy",
    HiveTreatment: "Leczenie ula",
    WinterCheck: "Kontrola zimowa",
    BroodInspection: "Inspekcja czerwiu",
    QueenInspection: "Inspekcja matki",
    DiseaseCheck: "Kontrola chorób",
    RoutineCheck: "Rutynowa kontrola",
    HiveInspection: "Inspekcja ula",
    TransferToOtherApiary: "Przeniesienie do innej pasieki",
    TransferWithinApiary: "Przeniesienie w obrębie pasieki",
    HiveMovement: "Przemieszczenie ula",
    hiveEvent: "Wydarzenie ula",
    HiveEquipment: "Wyposażenie ula",
    AddFrames: "Dodaj ramki",
    RemoveFrames: "Usuń ramki",
    AddSuper: "Dodaj nadstawkę",
    RemoveSuper: "Usuń nadstawkę",
    AddBroodBox: "Dodaj korpus na czerw",
    RemoveBroodBox: "Usuń korpus na czerw",
    deletingHiveEvent: "Usuwanie wydarzenia ula...",
    hiveEventDeleted: "Wydarzenie ula zostało usunięte!",
    hiveEventDeleteError: "Wystąpił błąd podczas usuwania wydarzenia ula",
    deleteHiveEvent: "Usuń wydarzenie",
    deleteHiveEventConfirmation: "Czy na pewno chcesz usunąć wydarzenie?",
    hiveEventWillBeDeleted:
      "Bieżące wydarzenie zostanie usunięte, jeśli się zgodzisz.",
    updatingHiveEvent: "Aktualizowanie wydarzenia ula...",
    hiveEventUpdated: "Wydarzenie ula zostało zaktualizowane!",
    hiveEventUpdateError: "Wystąpił błąd podczas aktualizacji wydarzenia ula",
    HiveMovementEventType: "Wydarzenia przemieszczenia ula",
    HiveInspectionEventType: "Wydarzenia inspekcji ula",
    HiveTreatmentEventType: "Wydarzenia leczenia ula",
    HiveMeasurementEventType: "Wydarzenia pomiaru ula",
    HiveEquipmentEventType: "Wydarzenia wyposażenia ula",
    hiveRequired: "Ul jest wymagany!",
    beeKeepingEvents: "Wydarzenia pszczelarskie",
    pieces: "sztuki",
    grams: "gramy",
    inHivePosition: "Pozycja w ulu",
    addNewLocation: "Dodaj nową lokalizację",
    previousLocations: "Poprzednie lokalizacje",
    noPreviousLocations: "Brak poprzednich lokalizacji",
    addLocation: "Dodaj lokalizację",
    currentLocation: "Aktualna lokalizacja",
    noCurrentLocation: "Brak aktualnej lokalizacji",
    currentPosition: "Aktualna pozycja",
    currentWeight: "Aktualna waga",
    broodBoxCount: "Liczba korpusów na czerw",
    superCount: "Liczba nadstawek",
    positionMaxLength: "Maksymalna długość pozycji to 255 znaków",
    fromAndToPositionMustBeDifferent:
      "Pozycja początkowa i docelowa muszą być różne",
    "More empty frames are needed":
      "Do wykonania operacji potrzebujesz więcej pustych ramek.",
    "No supers available to remove": "Brak dostępnych nadstawek do usunięcia",
    "You can't delete more suppers than available":
      "Nie możesz usunąć więcej nadstawek niż jest dostępnych",
    "No brood boxes available to remove":
      "Brak dostępnych korpusów na czerw do usunięcia",
    "You can't remove more brood boxes than available":
      "Nie możesz usunąć więcej korpusów na czerw niż jest dostępnych",
    HiveQueenEventsEventType: "Wydarzenia matek",
    HiveQueenEvents: "Wydarzenie matki",
    QueenMarking: "Znakowanie matki",
    QueenClipping: "Przycinanie skrzydełek matki",
    QueenCaging: "Izolacja matki (w klateczce)",
    eventInfoDescription: "Informacje o typie wydarzenia",
    "Events related to relocating the entire apiary, including permanent location changes and seasonal movements for better foraging opportunities.":
      "Wydarzenia związane z przemieszczeniem całej pasieki, w tym stałe zmiany lokalizacji i sezonowe przemieszczenia w celu lepszych możliwości zbierania pożytku.",
    "Official and routine inspections of the apiary as a whole, including regulatory checks and seasonal assessments.":
      "Oficjalne i rutynowe inspekcje całej pasieki, w tym kontrole regulacyjne i oceny sezonowe.",
    "Maintenance activities for the apiary infrastructure, equipment, and surrounding environment.":
      "Czynności konserwacyjne infrastruktury pasieki, sprzętu i otaczającego środowiska.",
    "Events related to moving individual hives within or between apiaries.":
      "Wydarzenia związane z przemieszczaniem poszczególnych uli w obrębie lub między pasiekami.",
    "Regular and specific inspections of individual hives to assess health, strength, and conditions.":
      "Regularne i specjalistyczne inspekcje poszczególnych uli w celu oceny zdrowia, siły i warunków.",
    "Medical and preventive treatments applied to hives for various health conditions.":
      "Leczenie medyczne i profilaktyczne stosowane w ulach w przypadku różnych problemów zdrowotnych.",
    "Measurements and assessments of various hive parameters and colony strength indicators.":
      "Pomiary i oceny różnych parametrów ula oraz wskaźników siły kolonii.",
    "Addition or removal of hive equipment and components.":
      "Dodawanie lub usuwanie wyposażenia i komponentów ula.",
    "Events related to queen bee management and maintenance.":
      "Wydarzenia związane z zarządzaniem i utrzymaniem matki pszczelej.",
    "Permanent relocation of the entire apiary to a new location, requiring updates to official registration.":
      "Stałe przeniesienie całej pasieki do nowej lokalizacji, wymagające aktualizacji oficjalnej rejestracji.",
    "Temporary relocation of hives to take advantage of specific nectar flows or pollination opportunities.":
      "Tymczasowe przemieszczenie uli w celu skorzystania z konkretnych pożytków lub możliwości zapylania.",
    "Mandatory veterinary inspection of the apiary for health certification and disease monitoring.":
      "Obowiązkowa inspekcja weterynaryjna pasieki w celu certyfikacji zdrowotnej i monitorowania chorób.",
    "Official inspection by beekeeping authorities for compliance with regulations and standards.":
      "Oficjalna inspekcja przeprowadzana przez władze pszczelarskie w celu sprawdzenia zgodności z przepisami i standardami.",
    "Assessment of apiary conditions and preparations before winter season.":
      "Ocena warunków pasieki i przygotowań przed sezonem zimowym.",
    "Evaluation of apiary condition and colonies after winter, planning for the new season.":
      "Ocena stanu pasieki i kolonii po zimie, planowanie na nowy sezon.",
    "Repairs and maintenance of apiary infrastructure like stands, fencing, or windbreaks.":
      "Naprawy i konserwacja infrastruktury pasieki, takiej jak stojaki, ogrodzenia lub wiatrochrony.",
    "Maintenance of shared apiary equipment such as extractors, tools, or storage facilities.":
      "Konserwacja wspólnego sprzętu pasiecznego, takiego jak miodarki, narzędzia lub pomieszczenia magazynowe.",
    "Management of vegetation around hives, including mowing, planting, or removing problematic plants.":
      "Zarządzanie roślinnością wokół uli, w tym koszenie, sadzenie lub usuwanie problematycznych roślin.",
    "Control of apiary-level pests such as mice, ants, or wasps.":
      "Kontrola szkodników na poziomie pasieki, takich jak myszy, mrówki lub osy.",
    "Moving a hive to a different location within the same apiary.":
      "Przeniesienie ula do innej lokalizacji w obrębie tej samej pasieki.",
    "Transferring a hive to a different apiary location.":
      "Przeniesienie ula do innej pasieki.",
    "Regular inspection of hive conditions, including population, stores, and overall health.":
      "Regularna inspekcja warunków ula, w tym populacji, zapasów i ogólnego stanu zdrowia.",
    "Specific inspection focusing on signs of diseases or parasites.":
      "Specjalistyczna inspekcja skupiająca się na oznakach chorób lub pasożytów.",
    "Inspection to verify queen presence, performance, and quality.":
      "Inspekcja w celu weryfikacji obecności matki, jej wydajności i jakości.",
    "Detailed inspection of brood patterns, health, and development.":
      "Szczegółowa inspekcja wzorców czerwiu, zdrowia i rozwoju.",
    "Quick inspection during winter to assess colony survival and stores.":
      "Szybka inspekcja w okresie zimowym w celu oceny przetrwania kolonii i zapasów.",
    "Application of treatments specifically targeting Varroa mite infestations.":
      "Stosowanie zabiegów skierowanych konkretnie przeciwko inwazji roztoczy Varroa.",
    "Treatment for Nosema disease in affected colonies.":
      "Leczenie choroby nosemowej w zakażonych koloniach.",
    "Treatment for various bee diseases other than Varroa or Nosema.":
      "Leczenie różnych chorób pszczół innych niż Varroa lub nosemoza.",
    "Preventive treatments applied to maintain colony health.":
      "Zabiegi profilaktyczne stosowane w celu utrzymania zdrowia kolonii.",
    "Assessment of overall colony strength, including bee population and vitality.":
      "Ocena ogólnej siły kolonii, w tym populacji pszczół i żywotności.",
    "Counting and assessment of frames containing honey stores.":
      "Liczenie i ocena ramek zawierających zapasy miodu.",
    "Measurement of frames containing brood in different stages.":
      "Pomiar ramek zawierających czerw w różnych stadiach.",
    "Recording the weight of the hive to track honey stores and colony development.":
      "Rejestrowanie wagi ula w celu śledzenia zapasów miodu i rozwoju kolonii.",
    "Adding a honey super to provide more space for honey storage.":
      "Dodawanie nadstawki miodowej, aby zapewnić więcej miejsca do przechowywania miodu.",
    "Removing a honey super, typically for honey harvesting.":
      "Usuwanie nadstawki miodowej, zazwyczaj w celu pozyskania miodu.",
    "Adding new frames to the hive for expansion or replacement.":
      "Dodawanie nowych ramek do ula w celu rozbudowy lub wymiany.",
    "Removing frames for maintenance, replacement, or honey extraction.":
      "Usuwanie ramek w celu konserwacji, wymiany lub ekstrakcji miodu.",
    "Adding a new brood box to accommodate colony growth.":
      "Dodawanie nowego korpusu na czerw w celu dostosowania do wzrostu kolonii.",
    "Removing a brood box for colony size management or maintenance.":
      "Usuwanie korpusu na czerw w celu zarządzania wielkością kolonii lub konserwacji.",
    "Marking the queen with a color code for easy identification and age tracking.":
      "Znakowanie matki kolorowym kodem dla łatwej identyfikacji i śledzenia wieku.",
    "Clipping the queen's wings to prevent swarming and aid in swarm management.":
      "Przycinanie skrzydełek matki, aby zapobiec rojeniu i pomóc w zarządzaniu rojem.",
    "Temporarily confining the queen in a cage for various management purposes.":
      "Tymczasowe zamknięcie matki w klateczce dla różnych celów zarządzania.",
    beekeeping: "Pszczelarstwo",
    currentChats: "Bieżące rozmowy",
    whatCanIHelpWith: "W czym mogę pomóc?",
    recentChats: "Ostatnie rozmowy",
    suggestedQuestions: "Sugerowane pytania",
    startChat: "Rozpocznij rozmowę",
    viewAllChats: "Zobacz wszystkie rozmowy",
    noHivesAvailable: "Brak dostępnych uli",
    noHive: "Brak ula",
    noQueen: "Brak matki",
    queenHasNoHiveAssigned: "Matka nie ma przypisanego ula",
    queenHasNoHiveAssignedAndNoAvailableHives:
      "Matka nie ma przypisanego ula i nie ma dostępnych uli",
    removeQueenFromHive: "Usuń matkę z ula",
    removeQueenFromHiveConfirmation: "Czy na pewno chcesz usunąć matkę z ula?",
    removingQueen: "Usuwanie matki...",
    queenRemovedFromHive: "Matka została usunięta z ula!",
    errorRemovingQueenFromHive: "Wystąpił błąd podczas usuwania matki z ula",
    hiveHasNoQueenAndQueensAvailable:
      "Ul nie ma przypisanej matki, a są dostępne matki",
    hiveHasNoQueenAndNoQueensAvailable:
      "Ul nie ma przypisanej matki i nie ma dostępnych matek",
    queenHasNoHiveAndHivesAvailable:
      "Matka nie ma przypisanego ula, a są dostępne ule",
    removingThread: "Usuwanie rozmowy...",
    threadRemoved: "Rozmowa została usunięta!",
    errorRemovingThread: "Wystąpił błąd podczas usuwania rozmowy",
    removeThread: "Usuń rozmowę",
    deleteThreadConfirmation: "Czy na pewno chcesz usunąć rozmowę?",
    allThreads: "Wszystkie rozmowy",
    messageRateLimitExceeded: "Przekroczono limit wiadomości.",
    limitWillBeRenewedIn: "Limit zostanie odnowiony za",
    messageSendError: "Wystąpił błąd podczas wysyłania wiadomości",
    youStillHave: "Nadal masz",
    messagesLeftThisMonth: "wiadomości dostępnych w tym miesiącu",
    messageLeftThisMonth: "wiadomość dostępną w tym miesiącu",
    "Medical and preventive treatments applied to animals for various health conditions and maintenance.":
      "Leczenie medyczne i profilaktyczne stosowane u zwierząt w przypadku różnych problemów zdrowotnych i konserwacji.",
    "Recording and tracking of various animal metrics and physical parameters.":
      "Rejestrowanie i śledzenie różnych metrycznych danych zwierząt i parametrów fizycznych.",
    "Events related to animal movement between locations, including quarantine and isolation.":
      "Wydarzenia związane z przemieszczaniem zwierząt między lokalizacjami, w tym kwarantanną i izolacją.",
    "Events related to breeding, pregnancy, birth, and reproductive health management.":
      "Wydarzenia związane z rozmnażaniem, ciążą, porodem i zarządzaniem zdrowiem reprodukcyjnym.",
    "Events marking the permanent departure of animals from the facility.":
      "Wydarzenia oznaczające trwałe opuszczenie obiektu przez zwierzęta.",
    "Miscellaneous animal management events including routine care and observations.":
      "Różne wydarzenia związane z zarządzaniem zwierzętami, w tym rutynowa opieka i obserwacje.",
    "Planned end of life for research or other approved purposes.":
      "Planowany koniec życia w celach badawczych lub innych zatwierdzonych celach.",
    "Transfer of animal ownership through commercial transaction.":
      "Przeniesienie własności zwierzęcia poprzez transakcję handlową.",
    "Recording of natural or unexpected death of an animal.":
      "Rejestrowanie naturalnej lub nieoczekiwanej śmierci zwierzęcia.",
    "Documentation of missing or unaccounted for animals.":
      "Dokumentowanie zaginionych lub nierozliczonych zwierząt.",
    "Administration of vaccines for disease prevention.":
      "Podawanie szczepionek w celu zapobiegania chorobom.",
    "Medical intervention for diagnosed diseases or conditions.":
      "Interwencja medyczna w przypadku zdiagnozowanych chorób lub stanów.",
    "Administration of antiparasitic medications for internal parasite control.":
      "Podawanie leków przeciwpasożytniczych w celu kontroli pasożytów wewnętrznych.",
    "Medical care for physical injuries or trauma.":
      "Opieka medyczna w przypadku obrażeń fizycznych lub urazów.",
    "Surgical procedures performed on animals.":
      "Procedury chirurgiczne wykonywane na zwierzętach.",
    "Monitoring and care following surgical procedures.":
      "Monitorowanie i opieka po zabiegach chirurgicznych.",
    "Provision of additional nutrients or dietary supplements.":
      "Dostarczanie dodatkowych składników odżywczych lub suplementów diety.",
    "Recording of animal body weight for monitoring growth and health.":
      "Rejestrowanie masy ciała zwierzęcia w celu monitorowania wzrostu i zdrowia.",
    "Assessment of animal's physical condition and fat reserves.":
      "Ocena kondycji fizycznej zwierzęcia i zapasów tłuszczu.",
    "Movement of animals between different locations or facilities.":
      "Przemieszczanie zwierząt między różnymi lokalizacjami lub obiektami.",
    "Placement of animals in quarantine for health monitoring.":
      "Umieszczanie zwierząt w kwarantannie w celu monitorowania zdrowia.",
    "Release of animals from quarantine after health clearance.":
      "Zwolnienie zwierząt z kwarantanny po weryfikacji zdrowia.",
    "Separation of animals for health or management purposes.":
      "Oddzielenie zwierząt ze względów zdrowotnych lub zarządzania.",
    "Artificial insemination procedure for breeding.":
      "Procedura sztucznej inseminacji do celów hodowlanych.",
    "Recording of natural breeding events.":
      "Rejestrowanie naturalnych wydarzeń rozrodczych.",
    "Confirmation of pregnancy through testing.":
      "Potwierdzenie ciąży poprzez badanie.",
    "Documentation of negative pregnancy test results.":
      "Dokumentacja negatywnych wyników testu ciążowego.",
    "Observation and recording of estrus signs.":
      "Obserwacja i rejestrowanie oznak rui.",
    "Documentation of birthing events and outcomes.":
      "Dokumentowanie wydarzeń związanych z porodem i ich wyników.",
    "Recording of pregnancy loss events.":
      "Rejestrowanie wydarzeń związanych z utratą ciąży.",
    "Beginning of milk production period.": "Początek okresu produkcji mleka.",
    "End of milk production period.": "Koniec okresu produkcji mleka.",
    "Routine health screening with positive findings.":
      "Rutynowe badanie zdrowia z pozytywnymi wynikami.",
    "Routine health screening with no concerning findings.":
      "Rutynowe badanie zdrowia bez niepokojących wyników.",
    "Assessment of hoof condition and health.": "Ocena stanu i zdrowia kopyt.",
    "Planned end of lactation in dairy animals.":
      "Planowane zakończenie laktacji u zwierząt mlecznych.",
    "Separation of young animals from maternal feeding.":
      "Oddzielenie młodych zwierząt od karmienia matczynego.",
    "Modification of feed type or feeding regime.":
      "Modyfikacja typu karmy lub systemu żywienia.",
    "Removal of wool or hair coat.": "Usuwanie wełny lub okrywy włosowej.",
    "Maintenance trimming of hooves.": "Konserwacyjne przycinanie kopyt.",
    "Application of identification tags.":
      "Zakładanie kolczyków identyfikacyjnych.",
    "Surgical or chemical sterilization of male animals.":
      "Sterylizacja chirurgiczna lub chemiczna samców.",
    "Removal or prevention of horn growth.":
      "Usuwanie lub zapobieganie wzrostowi rogów.",
    "Scheduled or emergency veterinary examination.":
      "Planowane lub awaryjne badanie weterynaryjne.",
    "Documentation of animal behavior patterns or changes.":
      "Dokumentowanie wzorców zachowań zwierząt lub ich zmian.",
    tutorialsIntro:
      "Cieszymy się, że wybrałeś naszą aplikację. Aby\n" +
      "            pomóc Ci zapoznać się z jej funkcjami, stworzyliśmy\n" +
      "            szczegółowe samouczki wideo, które krok po kroku wyjaśniają, jak korzystać\n" +
      "            z naszej aplikacji.",
    NotFoundMessage: "Strona, której szukasz, nie istnieje",
    goMainScreen: "Przejdź do strony głównej",
    birdsGroups: "Grupy ptaków",
    newBirdGroup: "Nowa grupa ptaków",
    groupName: "Nazwa grupy",
    Adult: "Dorosły",
    Juvenile: "Młody",
    Hatchling: "Pisklę",
    Mixture: "Mieszany",
    birdGroupAdultDescription:
      "W pełni dojrzałe ptaki, które zakończyły rozwój fizyczny i osiągnęły wiek reprodukcyjny. Zwykle mają dorosłe upierzenie i wzory kolorystyczne charakterystyczne dla swojego gatunku.",
    birdGroupJuvenileDescription:
      "Młode ptaki, które opuściły gniazdo i potrafią latać, ale nie osiągnęły dorosłości. Mogą mieć pośrednie wzory upierzenia i nadal rosną. Zwykle w wieku od 2 do 12 miesięcy, w zależności od gatunku.",
    birdGroupHatchingDescription:
      "Ptaki od świeżo wyklutych do stadium piskląt. Zazwyczaj nadal w gnieździe, wymagające opieki rodzicielskiej i karmienia. Mogą być częściowo lub całkowicie zależne od rodziców, aby przetrwać.",
    birdGroupUnknownDescription:
      "Wiek nie może być określony z powodu niewystarczających markerów wizualnych, dokumentacji lub ograniczeń obserwacyjnych.",
    birdGroupMixtureDescription:
      "Grupa ptaków o mieszanych kategoriach wiekowych, w tym dorosłe, młode i pisklęta.",
    ageGroup: "Grupa wiekowa",
    birdsCount: "Liczba ptaków",
    birdGroups: "Grupy ptaków",
    creatingBirdsGroup: "Tworzenie grupy ptaków...",
    birdsGroupCreated: "Grupa ptaków została utworzona!",
    errorCreatingBirdsGroup: "Wystąpił błąd podczas tworzenia grupy ptaków",
    name100Characters: "Nazwa nie może przekraczać 100 znaków",
    description500Characters: "Opis nie może przekraczać 500 znaków",
    countMin1: "Liczba ptaków musi wynosić co najmniej 1",
    birdsSummary: "Podsumowanie danych ptaków",
    count: "Liczba",
    deletingBirdsGroup: "Usuwanie grupy ptaków...",
    birdsGroupDeleted: "Grupa ptaków została usunięta!",
    birdsGroupDeleteError: "Wystąpił błąd podczas usuwania grupy ptaków",
    deleteBirdsGroupConfirmation: "Czy na pewno chcesz usunąć grupę ptaków?",
    birdsGroupWillBeDeleted:
      "Grupa ptaków zostanie usunięta, jeśli się zgodzisz.",
    updatingBirdsGroup: "Aktualizowanie grupy ptaków...",
    birdsGroupUpdated: "Grupa ptaków została zaktualizowana!",
    errorUpdatingBirdsGroup: "Wystąpił błąd podczas aktualizacji grupy ptaków",
    deleteBirdsGroup: "Usuń grupę ptaków",
    Duck: "Kaczka",
    Turkey: "Indyk",
    Goose: "Gęś",
    Quail: "Przepiórka",
    Ostrich: "Struś",
    "Rhode Island Red": "Rhode Island Red",
    "Plymouth Rock": "Plymouth Rock",
    Leghorn: "Leghorn",
    Australorp: "Australorp",
    Orpington: "Orpington",
    Sussex: "Sussex",
    Wyandotte: "Wyandotte",
    Brahma: "Brahma",
    Cochin: "Cochin",
    Silkie: "Jedwabista",
    "New Hampshire": "New Hampshire",
    Ameraucana: "Ameraucana",
    Marans: "Marans",
    Pekin: "Pekin",
    Muscovy: "Piżmowa",
    Runner: "Biegacz",
    "Khaki Campbell": "Khaki Campbell",
    Cayuga: "Cayuga",
    "Swedish Blue": "Szwedzka Niebieska",
    Rouen: "Rouen",
    "Welsh Harlequin": "Walijski Arlekin",
    "Broad Breasted White": "Szerokopierśny Biały",
    "Broad Breasted Bronze": "Szerokopierśny Brązowy",
    "Bourbon Red": "Bourbon Czerwony",
    Narragansett: "Narragansett",
    "Royal Palm": "Królewski Palmowy",
    "Black Spanish": "Czarny Hiszpański",
    "Beltsville Small White": "Beltsville Mały Biały",
    Toulouse: "Tuluska",
    Embden: "Embden",
    African: "Afrykańska",
    Chinese: "Chińska",
    Pilgrim: "Pielgrzym",
    "American Buff": "Amerykańska Płowa",
    Sebastopol: "Sebastopol",
    "African Black": "Afrykańska Czarna",
    "Blue Neck": "Niebieskokarczna",
    "Red Neck": "Czerwonokarczna",
    "Kenyan Red": "Kenijska Czerwona",
    "Zimbabwe Blue": "Zimbabwe Niebieska",
    "South African Black": "Południowoafrykańska Czarna",
    "Israeli Blue": "Izraelska Niebieska",
    "Australian Black": "Australijska Czarna",
    selectBirdGroups: "Wybierz grupy ptaków",
    noBirdGroupsAssociated: "Brak powiązanych grup ptaków",
    oneBirdGroupAssociated: "1 powiązana grupa ptaków",
    birdGroupsAssociated: "powiązane grupy ptaków",
    assocBirdGroups: "Powiąż grupy ptaków",
    birds: "Ptaki",
    animalGender: "Płeć zwierzęcia",
    selectHiveType: "Wybierz typ ula",
    contactInfo:
      "W przypadku jakichkolwiek pytań lub problemów, nie wahaj się skontaktować z nami, a odpowiemy najszybciej jak to możliwe!",
    appLanguage: "Język aplikacji",
    romanian: "Rumuński",
    english: "Angielski",
    selectAppLanguage: "Wybierz język aplikacji",
    event: "Wydarzenie",
    birdsGroupsEvents: "Wydarzenia grup ptaków",
    thereAreNoEventsYet: "Nie ma jeszcze żadnych wydarzeń",
    selectBirdsGroup: "Wybierz grupę ptaków",
    creatingBirdsGroupEvent: "Tworzenie wydarzenia grupy ptaków...",
    birdsGroupEventCreated: "Wydarzenie grupy ptaków zostało utworzone!",
    errorCreatingBirdsGroupEvent:
      "Wystąpił błąd podczas tworzenia wydarzenia grupy ptaków",
    birdsGroupRequired: "Grupa ptaków jest wymagana",
    HealthEventType: "Wydarzenia zdrowotne",
    Health: "Zdrowie",
    HealthCheck: "Kontrola zdrowia",
    MedicationAdministration: "Podawanie leków",
    "Generic medical and preventive health procedures for birds.":
      "Ogólne procedury medyczne i profilaktyczne dla ptaków.",
    "Generic event related to recording and tracking of bird metrics.":
      "Ogólne wydarzenie związane z rejestrowaniem i śledzeniem parametrów ptaków.",
    "Generic events related to bird movement and location changes.":
      "Ogólne wydarzenia związane z przemieszczaniem ptaków i zmianami lokalizacji.",
    "Routine health inspection and monitoring.":
      "Rutynowa kontrola i monitorowanie zdrowia.",
    "Movement between different locations.":
      "Przemieszczanie między różnymi lokalizacjami.",
    "Separation for health or management purposes.":
      "Separacja ze względów zdrowotnych lub zarządzania.",
    "Habitat cleaning and maintenance.": "Czyszczenie i konserwacja siedliska.",
    "General monitoring and behavioral observation.":
      "Ogólne monitorowanie i obserwacja zachowania.",
    "Miscellaneous bird event that doesn't fit into any category.":
      "Różne wydarzenia ptaków, które nie pasują do żadnej kategorii.",
    "Administration of medication for disease prevention or treatment.":
      "Podawanie leków w celu zapobiegania lub leczenia chorób.",
    birdsGroup: "Grupa ptaków",
    birdsGroupEvent: "Wydarzenie grupy ptaków",
    Cleaning: "Czyszczenie",
    Observation: "Obserwacja",
    allBirdsGroups: "Wszystkie grupy ptaków",
    AddHeads: "Dodaj osobniki",
    RemoveHeads: "Usuń osobniki",
    "Add birds into the group.": "Dodaj ptaki do grupy.",
    "Remove birds into the group.": "Usuń ptaki z grupy.",
    medicineDescription: "Opis leku",
    "Broad-spectrum antibiotic effective against respiratory infections and bacterial diseases. Commonly used for treating bacterial infections in various bird species.":
      " Antybiotyk o szerokim spektrum działania, skuteczny przeciwko infekcjom dróg oddechowych i chorobom bakteryjnym. Powszechnie stosowany do leczenia infekcji bakteryjnych u różnych gatunków ptaków.",
    "Antibiotic effective against mycoplasma infections and chronic respiratory disease. Often used in combination with other medications for respiratory treatments.":
      "Antybiotyk skuteczny przeciwko zakażeniom mykoplazmą i przewlekłym chorobom układu oddechowego. Często stosowany w połączeniu z innymi lekami w leczeniu chorób układu oddechowego.",
    "Anti-coccidial medication used to prevent and treat coccidiosis. Essential for maintaining digestive health in young birds.":
      "Lek przeciwkokcydiozowy stosowany w zapobieganiu i leczeniu kokcydiozy. Niezbędny do utrzymania zdrowia układu pokarmowego u młodych ptaków.",
    "Essential vitamin supplement supporting bone health, immune function, and reproduction. Particularly important during growth and breeding periods.":
      "Niezbędny suplement witaminowy wspierający zdrowie kości, funkcje odpornościowe i reprodukcję. Szczególnie ważny w okresach wzrostu i rozrodu.",
    "Vitamin supplement supporting metabolism, nerve function, and overall health. Often used during stress periods or recovery.":
      "Suplement witaminowy wspierający metabolizm, funkcje nerwowe i ogólny stan zdrowia. Często stosowany w okresach stresu lub rekonwalescencji.",
    "Probiotics for Birds": "Probiotyki dla ptaków",
    "Beneficial bacteria supplement supporting digestive health and immune function. Especially useful after antibiotic treatment or during stress.":
      "Suplement zawierający korzystne bakterie wspierające zdrowie układu pokarmowego i funkcje odpornościowe. Szczególnie przydatny po leczeniu antybiotykami lub w okresach stresu.",
    "Antifungal medication used to treat yeast infections and candidiasis. Common treatment for digestive tract fungal infections.":
      "Lek przeciwgrzybiczy stosowany w leczeniu zakażeń drożdżakami i kandydozy. Powszechne leczenie grzybiczych zakażeń przewodu pokarmowego.",
    "Deworming medication effective against roundworms. Regular use helps maintain digestive health and prevent parasite buildup.":
      "Lek odrobaczający skuteczny przeciwko nicieniom. Regularne stosowanie pomaga utrzymać zdrowie układu pokarmowego i zapobiec nagromadzeniu się pasożytów.",
    "Electrolyte Solution": "Roztwór elektrolitów",
    "Supplement for maintaining hydration and electrolyte balance. Critical during heat stress or illness recovery.":
      "Suplement do utrzymania nawodnienia i równowagi elektrolitowej. Kluczowy podczas stresu cieplnego lub rekonwalescencji po chorobie.",
    Milliliters: "Mililitry",
    Milligrams: "Miligramy",
    Grams: "Gramy",
    Pieces: "Sztuki",
    measuringUnit: "Jednostka miary",
    birdsGroupIntendedPurpose: "Cel grupy ptaków",
    "Eggs and Meat": "Jaja i mięso",
    Eggs: "Jaja",
    Pet: "Zwierzęta domowe",
    Breeding: "Hodowla",
    TrimmingFeathers: "Przycinanie skrzydeł",
    "Trimming of feathers or beaks.": "Przycinanie piór lub dziobów.",
    EggsCounting: "Liczenie jaj",
    "Counting the number of eggs produced by the group.":
      "Liczenie ilości jaj wyprodukowanych przez grupę.",
    eggsCount: "Liczba jaj",
    eggsProduction: "Produkcja jaj",
    totalEggsCount: "Całkowita liczba jaj",
    latestCount: "Ostatnie liczenie",
    recordedEvents: "Zarejestrowane wydarzenia",
    recentEvents: "Ostatnie wydarzenia",
    noBirdsGroupsFound: "Nie znaleziono grup ptaków",
    assocBirdGroup: "Powiąż grupę ptaków",
    intendedUseRequired: "Cel grupy ptaków jest wymagany",
    locationRequired: "Lokalizacja jest wymagana",
    updatingEvent: "Aktualizowanie wydarzenia...",
    eventUpdated: "Wydarzenie zostało zaktualizowane!",
    eventUpdateError: "Wystąpił błąd podczas aktualizacji wydarzenia",
    deletingEvent: "Usuwanie wydarzenia...",
    eventDeleted: "Wydarzenie zostało usunięte!",
    eventDeleteError: "Wystąpił błąd podczas usuwania wydarzenia",
    hiveScale: "Waga ula",
    myScales: "Moje wagi",
    addHiveScale: "Dodaj wagę ula",
    creatingHiveScale: "Tworzenie wagi ula...",
    hiveScaleCreated: "Waga ula została utworzona!",
    errorCreatingHiveScale: "Wystąpił błąd podczas tworzenia wagi ula",
    phoneNumber: "Numer telefonu",
    battery: "Bateria",
    lastMeasurementTime: "Czas ostatniego pomiaru",
    humidity: "Wilgotność",
    pressure: "Ciśnienie",
    temperature: "Temperatura",
    extraWeight: "Dodatkowa waga",
    signal: "Sygnał",
    noMeasurementsRecorded: "Nie zarejestrowano pomiarów",
    oneDayImprovement: "Przyrost 24h",
    lastEvents: "Ostatnie {{count}} wydarzeń z {{total}} wydarzeń",
    messageSent: "Wiadomość została wysłana!",
    messageNotSent: "Wystąpił błąd podczas wysyłania wiadomości",
    ainNotUnique: "Identyfikator musi być unikalny",
    temperatureStatistics: "Statystyki temperatury",
    weightStatistics: "Statystyki wagi",
    period: "Okres",
    lastMeasurement: "Ostatni pomiar",
    statistics: "Statystyki",
    lastMeasurements: "Ostatnie {{count}} pomiary",
    lastMeasurementsTotal: "Ostatnie {{count}} pomiary z {{total}}",
    last_24h: "Ostatnie 24h",
    last_2_weeks: "Ostatnie 2 tygodnie",
    minVal: "Wartość minimalna",
    maxVal: "Wartość maksymalna",
    avgVal: "Wartość średnia",
    hiveLocation: "Lokalizacja ula",
    forBetterRepresentationAvg:
      "Dla lepszej reprezentacji, wartości zostały obliczone jako średnia z przedziałów {{count}}-godzinnych.",
    showAllMeasurements: "Pokaż wszystkie pomiary",
    measurements: "Pomiary",
    measurementsForHive: "Pomiary dla ula",
    changeHive: "Zmień ul",
    hiveScaleDetails: "Szczegóły wagi ula",
    loadMore: "Wczytaj więcej",
    lastMeasurementsForHive: "Ostatnie pomiary dla ula",
    HoneyHarvest: "Zbiór miodu",
    WaxHarvest: "Zbiór wosku",
    PropolisHarvest: "Zbiór propolisu",
    HiveHarvest: "Zbiór z ula",
    "Events related to honey harvesting and processing or related yield.":
      "Wydarzenia związane ze zbieraniem i przetwarzaniem miodu lub związaną z tym produkcją.",
    "Extracting honey from frames and processing it for storage or sale.":
      "Pozyskiwanie miodu z ramek i przetwarzanie go do przechowywania lub sprzedaży.",
    "Collecting propolis from hives for various uses or products.":
      "Zbieranie propolisu z uli do różnych zastosowań lub produktów.",
    "Harvesting beeswax from frames or cappings for processing or sale.":
      "Pozyskiwanie wosku pszczelego z ramek lub zasklepów do przetwarzania lub sprzedaży.",
    HiveHarvestEventType: "Wydarzenia zbioru z ula",
    addHoneyHarvest: "Dodaj zbiór miodu",
    removeScaleFromHive: "Usuń wagę z ula",
    changePhoneNumber: "Zmień numer telefonu",
    removeScaleFromHiveConfirmation: "Czy na pewno chcesz usunąć wagę z ula?",
    removingScale: "Usuwanie wagi...",
    scaleRemoved: "Waga została usunięta!",
    scaleRemoveError: "Wystąpił błąd podczas usuwania wagi",
    hiveScales: "Wagi uli",
    associatedHive: "Powiązany ul",
    scaleForHive: "Waga dla ula {{hive}}",
    scale: "Waga {{phoneNumber}}",
    noAssocHive: "Brak powiązanego ula",
    assocHiveScale: "Powiąż wagę ula",
    assocHiveScaleConfirmation:
      "Czy na pewno chcesz powiązać wagę z ulem {{hive_code}}?",
    updatingHiveScale: "Aktualizowanie wagi ula...",
    hiveScaleUpdated: "Waga ula została zaktualizowana!",
    hiveScaleUpdateError: "Wystąpił błąd podczas aktualizacji wagi ula",
    noHivesWithoutScales: "Nie ma uli bez wag",
    hiveScaleEvents: "Wydarzenia wagi ula",
    ScaleRemoved: "Waga usunięta",
    ScaleAdded: "Waga dodana",
    PhoneNumberChanged: "Zmieniono numer telefonu",
    scaleRemovedEventDescription:
      "Waga {{scale}} została usunięta z ula {{hive}}!",
    scaleAddedEventDescription:
      "Waga {{scale}} została dodana do ula {{hive}}!",
    phoneNumberChangedEventDescription:
      "Numer telefonu wagi {{scale}} został zmieniony z {{old_phone_number}} na {{new_phone_number}}!",
    noLocationData: "Brak danych lokalizacji",
    noScalesWithoutHive: "Nie ma wag bez przypisanego ula",
    noHiveScalesFound: "Nie znaleziono wag ula",
    language: "Język",
    yourData: "Twoje Dane",
    yourFarmDetails: "Szczegóły Twojego Gospodarstwa",
    Beekeeping: "Pszczelarstwo",
    "Choose at least one module that corresponds to your farm's activities":
      "Wybierz co najmniej jeden moduł odpowiadający działalności Twojego gospodarstwa",
    billingData: "Dane do Faktury",
    farmDetails: "Dane Gospodarstwa",
    "Legal Name": "Nazwa Firmy",
    "Enter the legal name of your business":
      "Wprowadź oficjalną nazwę swojej firmy",
    "Tax Identification Number (TIN)": "Numer Identyfikacji Podatkowej (NIP)",
    "Enter your tax identification number":
      "Wprowadź swój numer identyfikacji podatkowej",
    Street: "Ulica",
    City: "Miasto",
    "Street address": "Adres ulicy",
    "State/Province": "Województwo",
    "State or province": "Województwo",
    Country: "Kraj",
    signupSuccess: "Rejestracja Zakończona Powodzeniem",
    "Enter your phone number": "Wprowadź swój numer telefonu",
    changingLanguage: "Zmiana języka...",
    languageChanged: "Język został zmieniony!",
    languageChangeError: "Wystąpił błąd podczas zmiany języka",
    legalNameRequired: "Nazwa firmy jest wymagana",
    tinRequired: "Numer identyfikacji podatkowej jest wymagany",
    streetRequired: "Ulica jest wymagana",
    cityRequired: "Miasto jest wymagane",
    spRequired: "Województwo jest wymagane",
    countryRequired: "Kraj jest wymagany",
    "Farm Name": "Nazwa Gospodarstwa",
    "Enter the legal name of your farm": "Wprowadź nazwę swojego gospodarstwa",
    pleaseEnterEmail: "Wprowadź adres e-mail",
    pleaseEnterPassword: "Wprowadź hasło",
    passwordRequired: "Hasło jest wymagane",
    invalidEmail: "Adres e-mail jest nieprawidłowy",
    "Enter the name of your farm": "Wprowadź nazwę swojego gospodarstwa",
    selectCountryCode: "Wybierz kod kraju",
    updatingPreferences: "Aktualizacja preferencji...",
    preferencesUpdated: "Preferencje zostały zaktualizowane!",
    preferencesUpdateError: "Wystąpił błąd podczas aktualizacji preferencji",

    // Bees medications

    "Varroa Treatment": "Leczenie Varroa",
    "Nosema Treatment": "Leczenie Nosema",
    "Brood Disease Treatment": "Leczenie chorób czerwiu",
    Supplement: "Suplement",
    Antibiotic: "Antybiotyk",
    Fungicide: "Fungicyd",
    "General Health": "Ogólne zdrowie",
    "Treatments specifically designed to control Varroa mite infestations in bee colonies.":
      "Zabiegi specjalnie zaprojektowane do kontroli zarażeń roztoczami Varroa w koloniach pszczelich.",
    "Medications for treating Nosema apis and Nosema ceranae infections in honey bees.":
      "Leki do leczenia zakażeń Nosema apis i Nosema ceranae u pszczół miodnych.",
    "Treatments for various brood diseases including American foulbrood, European foulbrood, and chalkbrood.":
      "Zabiegi na różne choroby czerwiu, w tym zgnilec amerykański, zgnilec europejski i grzybicę wapienną.",
    "Nutritional supplements to support colony health and development.":
      "Suplementy odżywcze wspomagające zdrowie i rozwój kolonii.",
    "Antibiotics used to treat bacterial infections in bee colonies when legally permitted.":
      "Antybiotyki stosowane do leczenia zakażeń bakteryjnych w koloniach pszczelich, gdy jest to prawnie dozwolone.",
    "Treatments for fungal diseases in bee colonies.":
      "Zabiegi na choroby grzybicze w koloniach pszczelich.",
    "General treatments to support overall colony health and immunity.":
      "Ogólne zabiegi wspierające ogólne zdrowie i odporność kolonii.",
    Amitraz: "Amitraz",
    "Oxalic Acid": "Kwas szczawiowy",
    "Formic Acid": "Kwas mrówkowy",
    ApiVar: "ApiVar",
    "ApiLife VAR": "ApiLife VAR",
    "Mite-Away Quick Strips": "Paski Mite-Away Quick",
    Apistan: "Apistan",
    "CheckMite+": "CheckMite+",
    HopGuard: "HopGuard",
    Thymovar: "Thymovar",
    "Fumagilin-B": "Fumagilin-B",
    Nozevit: "Nozevit",
    Terramycin: "Terramycyna",
    Tylan: "Tylan",
    "Bee Pro": "Bee Pro",
    HiveAlive: "HiveAlive",
    ProDFM: "ProDFM",
    "Vitamin B Complex": "Kompleks witamin B",
    ApiHerb: "ApiHerb",
    "Essential Oil Blend": "Mieszanka olejków eterycznych",
    "Honey-B-Healthy": "Honey-B-Healthy",
    "Synthetic acaricide used to control Varroa mites in honey bee colonies.":
      "Syntetyczny akarycyd stosowany do kontroli roztoczy Varroa w koloniach pszczół miodnych.",
    "Natural organic acid treatment for Varroa mites, especially effective in broodless periods.":
      "Naturalny zabieg kwasem organicznym na roztocza Varroa, szczególnie skuteczny w okresach bez czerwiu.",
    "Organic acid treatment for Varroa mites that can be used during honey flow, affects mites under cell cappings.":
      "Zabieg kwasem organicznym na roztocza Varroa, który może być stosowany podczas pożytku, oddziałuje na roztocza pod zasklepami komórek.",
    "Amitraz-based Varroa treatment strips, typically used for 6-8 weeks for full efficacy.":
      "Paski do leczenia Varroa na bazie amitrazu, zwykle stosowane przez 6-8 tygodni dla pełnej skuteczności.",
    "Thymol, eucalyptol, menthol, and camphor-based treatment for Varroa mites.":
      "Leczenie roztoczy Varroa na bazie tymolu, eukaliptolu, mentolu i kamfory.",
    "Formic acid-based Varroa treatment delivered via gel strips, effective against mites under brood cappings.":
      "Leczenie Varroa na bazie kwasu mrówkowego dostarczane za pomocą pasków żelowych, skuteczne przeciwko roztoczom pod zasklepami czerwiu.",
    "Tau-fluvalinate based strips for Varroa control, though resistance is now common in many areas.":
      "Paski na bazie tau-fluwalinatu do kontroli Varroa, choć odporność jest teraz powszechna w wielu obszarach.",
    "Coumaphos-based strips for Varroa control, often used in rotation with other treatments.":
      "Paski na bazie kumafosu do kontroli Varroa, często używane w rotacji z innymi zabiegami.",
    "Beta acid hop compound strips for Varroa control, can be used during honey production.":
      "Paski z związkami kwasów beta z chmielu do kontroli Varroa, mogą być stosowane podczas produkcji miodu.",
    "Thymol-based treatment for Varroa mites with lower concentration than ApiLife VAR.":
      "Zabieg na bazie tymolu na roztocza Varroa o niższym stężeniu niż ApiLife VAR.",
    "Treatment for Nosema disease, administered in sugar syrup to prevent and control infections.":
      "Leczenie choroby nosemowej, podawane w syropie cukrowym w celu zapobiegania i kontroli zakażeń.",
    "Natural plant-based supplement to help bees resist Nosema infections.":
      "Naturalny suplement roślinny pomagający pszczołom odpierać zakażenia Nosema.",
    "Oxytetracycline antibiotic for prevention and control of European foulbrood and American foulbrood (where legal).":
      "Antybiotyk oksytetracyklina do zapobiegania i kontroli zgnilca europejskiego i zgnilca amerykańskiego (gdzie jest to legalne).",
    "Tylosin antibiotic used for American foulbrood control in some regions where legally permitted.":
      "Antybiotyk tylozyna stosowany do kontroli zgnilca amerykańskiego w niektórych regionach, gdzie jest to prawnie dozwolone.",
    "Protein supplement to support bee nutrition when natural pollen is scarce.":
      "Suplement białkowy wspomagający odżywianie pszczół, gdy naturalny pyłek jest rzadki.",
    "Nutritional supplement with thymol, lemongrass, and seaweed extracts to support colony health.":
      "Suplement odżywczy z ekstraktami tymolu, trawy cytrynowej i wodorostów wspomagający zdrowie kolonii.",
    "Probiotic supplement to support bee gut health and immune function.":
      "Suplement probiotyczny wspomagający zdrowie układu pokarmowego i funkcję odpornościową pszczół.",
    "Vitamin supplement added to sugar syrup to support bee health during stress periods.":
      "Suplement witaminowy dodawany do syropu cukrowego w celu wsparcia zdrowia pszczół w okresach stresu.",
    "Herbal preparation to support overall colony health and vigor.":
      "Preparat ziołowy wspomagający ogólne zdrowie i wigor kolonii.",
    "Mixture of lemongrass, spearmint, and other essential oils to promote colony health.":
      "Mieszanka trawy cytrynowej, mięty zielonej i innych olejków eterycznych w celu promowania zdrowia kolonii.",
    "Essential oil-based feed supplement to stimulate feeding and support colony health.":
      "Suplement paszowy na bazie olejków eterycznych stymulujący odżywianie i wspierający zdrowie kolonii.",
    Drops: "Krople",
    Strips: "Paski",
    Doses: "Dawki",
    Packets: "Saszetki",

    // Animal medications

    Penicillin: "Penicylina",
    Oxytetracycline: "Oksytetracyklina",
    Ceftiofur: "Ceftiofur",
    Tylosin: "Tylozyna",
    "Flunixin Meglumine": "Flunixyna Megluminowa",
    Meloxicam: "Meloksykam",
    Dexamethasone: "Deksametazon",
    Ivermectin: "Iwermektyna",
    Fenbendazole: "Fenbendazol",
    Albendazole: "Albendazol",
    Levamisole: "Lewamizol",
    Oxytocin: "Oksytocyna",
    "Prostaglandin F2alpha": "Prostaglandyna F2alfa",
    GnRH: "GnRH",
    "Calcium Borogluconate": "Boroglukonian Wapnia",
    "Selenium/Vitamin E": "Selen/Witamina E",
    Lidocaine: "Lidokaina",
    "Propylene Glycol": "Glikol Propylenowy",
    "Sodium Bicarbonate": "Wodorowęglan Sodu",
    Cephalexin: "Cefaleksyna",
    Cloxacillin: "Kloksacylina",
    Epinephrine: "Epinefryna",
    Monensin: "Monenzyna",
    Rumensin: "Rumenzyna",

    // animal medications descriptions

    "Basic antibiotic for treating bacterial infections in livestock. Commonly used for pneumonia, mastitis, and foot rot.":
      "Podstawowy antybiotyk do leczenia infekcji bakteryjnych u zwierząt hodowlanych. Powszechnie stosowany w zapaleniu płuc, zapaleniu wymienia i zgniciu racic.",

    "Broad-spectrum antibiotic effective against respiratory diseases, pinkeye, and foot rot in cattle and sheep.":
      "Antybiotyk o szerokim spektrum działania skuteczny przeciwko chorobom układu oddechowego, zapaleniu spojówek i zgniciu racic u bydła i owiec.",

    "Advanced antibiotic for treating severe bacterial infections, particularly respiratory disease in cattle.":
      "Zaawansowany antybiotyk do leczenia ciężkich infekcji bakteryjnych, szczególnie chorób układu oddechowego u bydła.",

    "Antibiotic primarily used for respiratory diseases in cattle and swine. Also effective against mastitis.":
      "Antybiotyk stosowany głównie w chorobach układu oddechowego u bydła i świń. Skuteczny również przeciwko zapaleniu wymienia.",

    "NSAID used for pain, fever, and inflammation in livestock. Commonly used for mastitis and lameness.":
      "NLPZ stosowany przeciwko bólowi, gorączce i stanom zapalnym u zwierząt hodowlanych. Powszechnie stosowany w zapaleniu wymienia i kulawizny.",

    "NSAID used for pain management in cattle, particularly after dehorning or castration.":
      "NLPZ stosowany do kontroli bólu u bydła, szczególnie po odrogu lub kastracji.",

    "Corticosteroid used for severe inflammation, shock, and allergic reactions in livestock.":
      "Kortykosteroid stosowany w ciężkich stanach zapalnych, wstrząsie i reakcjach alergicznych u zwierząt hodowlanych.",

    "Broad-spectrum parasiticide effective against internal and external parasites in cattle, sheep, and pigs.":
      "Środek przeciwpasożytniczy o szerokim spektrum działania skuteczny przeciwko pasożytom wewnętrznym i zewnętrznym u bydła, owiec i świń.",

    "Dewormer effective against gastrointestinal parasites in cattle, sheep, and goats.":
      "Środek przeciw robakom skuteczny przeciwko pasożytom przewodu pokarmowego u bydła, owiec i kóz.",

    "Broad-spectrum dewormer effective against internal parasites, including liver flukes in ruminants.":
      "Środek przeciw robakom o szerokim spektrum działania skuteczny przeciwko pasożytom wewnętrznym, w tym motylicy wątrobowej u przeżuwaczy.",

    "Dewormer effective against gastrointestinal roundworms in cattle, sheep, and goats.":
      "Środek przeciw robakom skuteczny przeciwko nicieniom przewodu pokarmowego u bydła, owiec i kóz.",

    "Hormone used to induce labor, treat retained placenta, and stimulate milk letdown in dairy animals.":
      "Hormon stosowany do wywołania porodu, leczenia zatrzymania łożyska i stymulacji wydzielania mleka u zwierząt mlecznych.",

    "Hormone used for estrus synchronization and treatment of retained corpus luteum in cattle.":
      "Hormon stosowany do synchronizacji rui i leczenia zatrzymanego ciałka żółtego u bydła.",

    "Hormone used for treating cystic ovaries and synchronizing ovulation in cattle breeding programs.":
      "Hormon stosowany do leczenia torbieli jajników i synchronizacji owulacji w programach hodowli bydła.",

    "Supplement used for treating vitamin B deficiencies and supporting metabolism in stressed animals.":
      "Suplement stosowany do leczenia niedoborów witaminy B i wspomagania metabolizmu u zwierząt w stresie.",

    "Used for treating milk fever (hypocalcemia) in dairy cattle.":
      "Stosowany do leczenia gorączki mlecznej (hipokalcemii) u bydła mlecznego.",

    "Supplement used to prevent and treat white muscle disease in young livestock.":
      "Suplement stosowany do zapobiegania i leczenia choroby białych mięśni u młodych zwierząt hodowlanych.",

    "Local anesthetic used for minor surgeries, dehorning, and castration procedures.":
      "Miejscowy środek znieczulający stosowany przy drobnych zabiegach chirurgicznych, odrogu i kastracji.",

    "Used to treat ketosis in dairy cattle and as an energy supplement.":
      "Stosowany do leczenia ketozy u bydła mlecznego i jako suplement energetyczny.",

    "Buffer used to treat and prevent ruminal acidosis in cattle.":
      "Bufor stosowany do leczenia i zapobiegania kwasicy żwacza u bydła.",

    "Intramammary antibiotic used for treating mastitis in dairy cattle.":
      "Antybiotyk dowymieniowy stosowany do leczenia zapalenia wymienia u bydła mlecznego.",

    "Intramammary antibiotic specifically designed for dry cow therapy.":
      "Antybiotyk dowymieniowy zaprojektowany specjalnie do terapii krów w okresie zasuszenia.",

    "Emergency medication used for treating anaphylactic reactions in livestock.":
      "Lek awaryjny stosowany do leczenia reakcji anafilaktycznych u zwierząt hodowlanych.",

    "Feed additive used to prevent coccidiosis and improve feed efficiency in cattle.":
      "Dodatek paszowy stosowany do zapobiegania kokcydiozie i poprawy wydajności paszy u bydła.",

    "Feed additive to improve feed efficiency and prevent bloat in cattle.":
      "Dodatek paszowy do poprawy wydajności paszy i zapobiegania wzdęciom u bydła.",

    noExpensesOnSelectedPeriod: "Brak wy datków w wybranym okresie",
    noIncomesOnSelectedPeriod: "Brak dochodów w wybranym okresie",
    failedToCreateAccount: "Nie udało się utworzyć konta",
    deleteAccount: "Usuń Konto",
    deleteAccountConfirmation:
      "Czy na pewno chcesz usunąć swoje konto? Ta akcja jest nieodwracalna. Utracisz wszystkie dane powiązane z kontem.",
    deletingAccount: "Usuwanie konta...",
    accountDeleted: "Konto zostało usunięte!",
    errorOccurredWhileDeletingAccount: "Wystąpił błąd podczas usuwania konta",
    enterYourPasswordToContinue: "Wprowadź hasło, aby kontynuować",
    yourPassword: "Twoje hasło",
    HONEY_SALES: "Sprzedaż Miodu",
    POLLINATION_SERVICES: "Usługi Zapylania",
    BEESWAX_SALES: "Sprzedaż Wosku Pszczelego",
    BEE_COLONY_SALES: "Sprzedaż Kolonii Pszczelich",
    BEE_FEED: "Karma dla Pszczół",
    QUEEN_PURCHASES: "Zakup Matek Pszczelich",
    HIVE_TREATMENTS: "Zabiegi Ulowe",
    BEE_COLONY_PURCHASES: "Zakup Kolonii Pszczelich",
    HONEY_PACKAGING: "Pakowanie Miodu",
    "Events related to providing supplemental nutrition to honey bee colonies.":
      "Wydarzenia związane z dostarczaniem dodatkowego pożywienia rodzinom pszczelim.",
    "Providing solid candy (like Apifonda) for winter or dearth periods.":
      "Dostarczanie ciasta cukrowego (jak Apifonda) na zimę lub okresy niedoboru pokarmu.",
    "Feeding with protein supplements to boost brood production.":
      "Karmienie suplementami białkowymi w celu zwiększenia produkcji czerwiu.",
    "Providing pollen substitutes or supplements.":
      "Dostarczanie substytutów lub suplementów pyłku.",
    "Strategic feeding to stimulate colony growth and activity.":
      "Strategiczne dokarmianie w celu stymulacji wzrostu i aktywności rodziny pszczelej.",
    HiveFeeding: "Dokarmianie Uli",
    CarbohydratesFeeding: "Karmienie Węglowodanami",
    ProteinFeeding: "Karmienie Białkowe",
    PollenSubstitute: "Substytut Pyłku",
    StimulativeFeeding: "Karmienie Stymulacyjne",
    HiveFeedingEventType: "Wydarzenia dokarmiania uli",
    ScaleAddedWithoutHive: "Waga dodana bezpośrednio",
    "Hive was added directly, without having an associated hive.":
      "Ul został dodany bezpośrednio, bez przypisanego ula.",
    editHiveScale: "Edytuj Wagę Ula",
    MyParcels: "Moje Działki",
    addNewParcel: "Dodaj Nową Działkę",
    addCoordinates: "Dodaj Współrzędne",
    parcelCoordinates: "Współrzędne Działki",
    addParcelCoordinates: "Dodaj Współrzędne Działki",
    latitude: "Szerokość",
    longitude: "Długość",
    longitudeX: "Długość (lub X/E)",
    latitudeY: "Szerokość (lub Y/N)",
    latY: "Szerokość (Y/N)",
    longX: "Długość (X/E)",
    noCoordinatesYet: "Brak współrzędnych",
    addPointCoordinates: "Dodaj Współrzędne Punktu",
    parcelName: "Nazwa Działki",
    parcelDescription: "Opis Działki",
    creatingParcel: "Tworzenie działki...",
    parcelCreated: "Działka została utworzona pomyślnie!",
    parcelCreationError: "Wystąpił błąd podczas tworzenia działki",
    noParcelsFound: "Nie znaleziono działek",
    deleteParcel: "Usuń Działkę",
    deletingParcel: "Usuwanie działki...",
    parcelDeleted: "Działka została usunięta pomyślnie!",
    parcelDeletionError: "Wystąpił błąd podczas usuwania działki",
    area: "Powierzchnia",
    deleteParcelConfirmation: "Czy na pewno chcesz usunąć tę działkę?",
    deleteParcelDescription:
      "Ta akcja jest nieodwracalna. Wszystkie dane związane z tą działką zostaną utracone.",
    editParcelInfo: "Edytuj Informacje o Działce",
    editPointCoordinates: "Edytuj Współrzędne Punktu",
    deletePoint: "Usuń Punkt",
    deletePointConfirmation: "Czy na pewno chcesz usunąć ten punkt?",
    updatingParcel: "Aktualizowanie działki...",
    parcelUpdated: "Działka została zaktualizowana pomyślnie!",
    parcelUpdateError: "Wystąpił błąd podczas aktualizacji działki",
    parcelMap: "Mapa Działki",
    parcelView: "Widok Działki",
    "How are my bee colonies doing?": "Jak radzą sobie moje kolonie pszczół?",
    "What's the status of my queen bees?":
      "Jaki jest stan moich matek pszczelich?",
    "How is my honey production looking?": "Jak wygląda moja produkcja miodu?",
    "What do my hive scale measurements show?":
      "Co pokazują pomiary wagi moich uli?",
    "Do my hives need treatments soon?":
      "Czy moje ule wkrótce będą potrzebować zabiegów?",
    "Which apiaries need attention?": "Które pasieki wymagają uwagi?",
    "Are any of my hives at risk of swarming?":
      "Czy któreś z moich uli są zagrożone rojeniem?",
    "When should I move my apiaries for better nectar flow?":
      "Kiedy powinienem przenieść moje pasieki dla lepszego przepływu nektaru?",
    "Do I need to add or remove supers from my hives?":
      "Czy powinienem dodać lub usunąć nadstawki z moich uli?",
    "Are my hives ready for winter?": "Czy moje ule są gotowe na zimę?",

    "How are my bird flocks doing?": "Jak radzą sobie moje stada ptaków?",
    "How is my egg production looking?": "Jak wygląda moja produkcja jaj?",
    "How is the growth of my meat birds progressing?":
      "Jak postępuje wzrost moich ptaków mięsnych?",
    "Do my birds need any treatments soon?":
      "Czy moje ptaki wkrótce będą potrzebować jakichś zabiegów?",
    "Which bird locations need attention?":
      "Które lokalizacje ptaków wymagają uwagi?",
    "Are any of my bird groups overcrowded?":
      "Czy któreś z moich grup ptaków są przeludnione?",
    "When should I rotate my birds to fresh pasture?":
      "Kiedy powinienem rotować moje ptaki na świeże pastwisko?",
    "How is my bird feed consumption looking?":
      "Jak wygląda zużycie paszy przez moje ptaki?",
    "How are my breeding birds performing?":
      "Jak radzą sobie moje ptaki hodowlane?",
    "Are my birds ready for the upcoming season?":
      "Czy moje ptaki są gotowe na nadchodzący sezon?",

    "How is my cattle herd doing?": "Jak radzi sobie moje stado bydła?",
    "What's the reproductive status of my cows?":
      "Jaki jest stan reprodukcyjny moich krów?",
    "How is my milk production looking?": "Jak wygląda moja produkcja mleka?",
    "Do my cattle need any treatments soon?":
      "Czy moje bydło wkrótce będzie potrzebować jakichś zabiegów?",
    "What upcoming gestation events do I need to prepare for?":
      "Do jakich nadchodzących wydarzeń związanych z ciążą powinienem się przygotować?",
    "How is the growth of my calves and young stock?":
      "Jak wygląda wzrost moich cieląt i młodego bydła?",
    "Which cattle locations need attention?":
      "Które lokalizacje bydła wymagają uwagi?",
    "How should I adjust my cattle feeding program?":
      "Jak powinienem dostosować program żywienia mojego bydła?",
    "When should I breed my cows?": "Kiedy powinienem rozmnażać moje krowy?",
    "Which cows are due to calve soon?": "Które krowy wkrótce się ocielą?",
    "How should I manage my newborn calves?":
      "Jak powinienem zarządzać moimi nowonarodzonymi cielętami?",

    "How is my pig herd doing?": "Jak radzi sobie moje stado świń?",
    "What's the reproductive status of my sows?":
      "Jaki jest stan reprodukcyjny moich loch?",
    "Which sows are due to farrow soon?": "Które lochy wkrótce się oproszą?",
    "How should I manage my nursing piglets?":
      "Jak powinienem zarządzać moimi ssącymi prosiętami?",
    "Which piglets are ready for weaning?":
      "Które prosięta są gotowe do odsadzenia?",
    "How is the growth performance of my growing pigs?":
      "Jak wygląda tempo wzrostu moich rosnących świń?",
    "Do my pigs need any treatments soon?":
      "Czy moje świnie wkrótce będą potrzebować jakichś zabiegów?",
    "Which pig locations need attention?":
      "Które lokalizacje świń wymagają uwagi?",
    "How should I adjust my pig feeding program?":
      "Jak powinienem dostosować program żywienia moich świń?",
    "When should I breed my sows?": "Kiedy powinienem rozmnażać moje lochy?",
    "Which pigs are ready for market?": "Które świnie są gotowe na rynek?",

    "How is my goat herd doing?": "Jak radzi sobie moje stado kóz?",
    "What's the reproductive status of my does?":
      "Jaki jest stan reprodukcyjny moich kóz?",
    "How is my goat milk production looking?":
      "Jak wygląda moja produkcja mleka koziego?",
    "Do my goats need any treatments soon?":
      "Czy moje kozy wkrótce będą potrzebować jakichś zabiegów?",
    "How should I manage my young kids?":
      "Jak powinienem zarządzać moimi młodymi koźlętami?",
    "When should I breed my does?": "Kiedy powinienem rozmnażać moje kozy?",
    "Which does are due to kid soon?": "Które kozy wkrótce się okocą?",
    "How should I manage parasites in my goat herd?":
      "Jak powinienem zarządzać pasożytami w moim stadzie kóz?",
    "How should I adjust my goat feeding program?":
      "Jak powinienem dostosować program żywienia moich kóz?",
    "How is my fiber production looking?": "Jak wygląda moja produkcja włókna?",
    "Which goats need hoof trimming?":
      "Które kozy potrzebują przycięcia kopyt?",

    "How is my sheep flock doing?": "Jak radzi sobie moje stado owiec?",
    "What's the reproductive status of my ewes?":
      "Jaki jest stan reprodukcyjny moich owiec?",
    "Which ewes are due to lamb soon?": "Które owce wkrótce się wykocą?",
    "How should I manage my young lambs?":
      "Jak powinienem zarządzać moimi młodymi jagniętami?",
    "Do my sheep need any treatments soon?":
      "Czy moje owce wkrótce będą potrzebować jakichś zabiegów?",
    "How should I manage parasites in my sheep flock?":
      "Jak powinienem zarządzać pasożytami w moim stadzie owiec?",
    "When should I breed my ewes?": "Kiedy powinienem rozmnażać moje owce?",
    "How is my wool production looking?": "Jak wygląda moja produkcja wełny?",
    "How should I adjust my sheep feeding program?":
      "Jak powinienem dostosować program żywienia moich owiec?",
    "Which sheep need hoof trimming?":
      "Które owce potrzebują przycięcia kopyt?",
    "Which lambs are ready for market?": "Które jagnięta są gotowe na rynek?",
    assocParcel: "Powiąż Działkę",
    noParcelsAssociated: "Brak powiązanych działek",
    oneParcelAssociated: "1 Powiązana Działka",
    parcelsAssociated: "{{count}} Powiązanych Działek",
    selectParcels: "Wybierz Działki",
    parcelEvents: "Wydarzenia Działki",
    addParcelEvent: "Dodaj Wydarzenie Działki",
    selectParcel: "Wybierz Działkę",
    parcelEvent: "Wydarzenie Działki",
    deleteEvent: "Usuń Wydarzenie",
    deleteEventConfirmation: "Czy na pewno chcesz usunąć to wydarzenie?",
    Irrigation: "Nawadnianie",
    "Events related to water management and irrigation systems for crop and land hydration.":
      "Wydarzenia związane z gospodarką wodną i systemami nawadniania upraw i gruntów.",
    "Pest & Weed Control": "Kontrola Szkodników i Chwastów",
    "Activities for managing weeds, pests, and diseases affecting crops and land.":
      "Działania związane z zarządzaniem chwastami, szkodnikami i chorobami wpływającymi na uprawy i grunty.",
    "Soil Management": "Zarządzanie Glebą",
    "Practices for maintaining or improving soil health, structure, and fertility.":
      "Praktyki utrzymania lub poprawy zdrowia, struktury i żyzności gleby.",
    "Land Transaction": "Transakcje Gruntowe",
    "Events related to buying, selling, leasing, or adjusting land boundaries.":
      "Wydarzenia związane z kupnem, sprzedażą, dzierżawą lub korygowaniem granic gruntów.",
    Infrastructure: "Infrastruktura",
    "Construction, maintenance, or modification of physical structures on the land.":
      "Budowa, konserwacja lub modyfikacja struktur fizycznych na gruncie.",
    "Miscellaneous parcel management events not classified in other categories.":
      "Różne wydarzenia zarządzania działkami niesklasyfikowane w innych kategoriach.",
    "Drip Irrigation": "Nawadnianie Kroplowe",
    "Application of water directly to plant roots through a low-flow system to minimize water usage.":
      "Aplikacja wody bezpośrednio do korzeni roślin przez system niskiego przepływu, aby zminimalizować zużycie wody.",
    "Sprinkler System": "System Zraszaczy",
    "Use of overhead sprinklers to distribute water across the field or parcel.":
      "Użycie zraszaczy podwieszanych do rozprowadzania wody po polu lub działce.",
    "Flood Irrigation": "Nawadnianie Zalewowe",
    "Traditional method of flooding fields with water for crop irrigation.":
      "Tradycyjna metoda zalewania pól wodą w celu nawadniania upraw.",
    "Irrigation Maintenance": "Konserwacja Systemu Nawadniania",
    "Repair, cleaning, or general upkeep of irrigation equipment and systems.":
      "Naprawa, czyszczenie lub ogólna konserwacja sprzętu i systemów nawadniających.",
    "Water Source Change": "Zmiana Źródła Wody",
    "Modification of water source used for irrigation, such as well, river, or rainwater collection.":
      "Modyfikacja źródła wody używanej do nawadniania, takiego jak studnia, rzeka lub zbieranie wody deszczowej.",
    "Irrigation Schedule Change": "Zmiana Harmonogramu Nawadniania",
    "Adjustment to timing or frequency of irrigation events based on crop needs or water availability.":
      "Dostosowanie czasu lub częstotliwości nawadniania w zależności od potrzeb upraw lub dostępności wody.",
    "Herbicide Application": "Aplikacja Herbicydu",
    "Application of chemical substances to control or eliminate unwanted plants.":
      "Zastosowanie substancji chemicznych do kontroli lub eliminacji niepożądanych roślin.",
    "Pesticide Application": "Aplikacja Pestycydu",
    "Use of chemicals to control insects or other pests damaging crops.":
      "Używanie środków chemicznych do kontroli owadów lub innych szkodników uszkadzających uprawy.",
    "Fungicide Application": "Aplikacja Fungicydu",
    "Treatment with substances that control fungal diseases in crops.":
      "Leczenie substancjami kontrolującymi choroby grzybowe w uprawach.",
    "Manual Weeding": "Ręczne Plewienie",
    "Physical removal of weeds by hand or with hand tools.":
      "Fizyczne usuwanie chwastów ręcznie lub za pomocą narzędzi ręcznych.",
    "Soil Testing": "Badanie Gleby",
    "Analysis of soil composition to assess nutrient levels, pH, and other characteristics.":
      "Analiza składu gleby w celu oceny poziomu składników odżywczych, pH i innych charakterystyk.",
    "Fertilizer Application": "Aplikacja Nawozu",
    "Addition of natural or synthetic substances to enrich soil with nutrients.":
      "Dodawanie naturalnych lub syntetycznych substancji w celu wzbogacenia gleby w składniki odżywcze.",
    Composting: "Kompostowanie",
    "Creation or application of decomposed organic matter to improve soil structure and fertility.":
      "Tworzenie lub aplikacja rozłożonej materii organicznej w celu poprawy struktury i żyzności gleby.",
    "Cover Crop Planting": "Sadzenie Roślin Okrywowych",
    "Growing plants specifically to protect and enrich soil between main crop seasons.":
      "Uprawa roślin specjalnie w celu ochrony i wzbogacenia gleby między głównymi sezonami upraw.",
    "Lime Application": "Aplikacja Wapna",
    "Addition of materials containing calcium to neutralize soil acidity and improve structure.":
      "Dodawanie materiałów zawierających wapń w celu neutralizacji kwasowości gleby i poprawy jej struktury.",
    Mulching: "Ściółkowanie",
    "Application of material to soil surface to conserve moisture, suppress weeds, and regulate temperature.":
      "Aplikacja materiału na powierzchnię gleby w celu zachowania wilgoci, tłumienia chwastów i regulacji temperatury.",
    "Land Purchase": "Zakup Gruntu",
    "Acquisition of new land parcels to expand farm operations.":
      "Nabycie nowych działek gruntowych w celu rozszerzenia działalności gospodarstwa.",
    "Land Sale": "Sprzedaż Gruntu",
    "Transfer of owned land to another party through sale.":
      "Przekazanie posiadanej ziemi innej stronie poprzez sprzedaż.",
    "Fence Installation": "Instalacja Ogrodzenia",
    "Construction of new fencing to define boundaries or contain livestock.":
      "Budowa nowego ogrodzenia w celu określenia granic lub przechowywania zwierząt hodowlanych.",
    "Fence Repair": "Naprawa Ogrodzenia",
    "Maintenance or restoration of existing fence structures.":
      "Konserwacja lub odnowa istniejących struktur ogrodzeniowych.",
    "Building Construction": "Budowa Budynku",
    "Erection of structures such as barns, sheds, or processing facilities.":
      "Wznoszenie struktur takich jak stodoły, szopy lub obiekty przetwórcze.",
    "Weather Damage": "Szkody Pogodowe",
    "Assessment and documentation of damage caused by extreme weather events.":
      "Ocena i dokumentacja szkód spowodowanych ekstremalnymi zjawiskami pogodowymi.",
    "Soil Remediation": "Rekultywacja Gleby",
    "Processes to remove contaminants or restore degraded soil conditions.":
      "Procesy usuwania zanieczyszczeń lub przywracania zdegradowanych warunków glebowych.",
    "Grant Application": "Wniosek o Dotację",
    "Submission of requests for financial assistance from government or private programs.":
      "Składanie wniosków o pomoc finansową z programów rządowych lub prywatnych.",
    cropPlantings: "Uprawy",
    myCropsPlantings: "Moje Uprawy",
    noCropsFound: "Nie znaleziono upraw",
    newCropPlanting: "Nowa Uprawa",
    seedingDate: "Data Siewu",
    selectCrop: "Wybierz Uprawę",
    plannedHarvestDate: "Planowana Data Zbioru",
    expectedYield: "Oczekiwany Plon",
    savingCropPlanting: "Zapisywanie uprawy...",
    cropPlantingSaved: "Uprawa została zapisana!",
    cropPlantingError: "Wystąpił błąd podczas zapisywania uprawy",
    cropPlantingDetails: "Szczegóły Uprawy",
    editCropPlanting: "Edytuj Uprawę",
    deleteCropPlanting: "Usuń Uprawę",
    deleteCropPlantingConfirmation: "Czy na pewno chcesz usunąć uprawę?",
    deletingCropPlanting: "Usuwanie uprawy...",
    cropPlantingDeleted: "Uprawa została usunięta!",
    Wheat: "Pszenica",
    Barley: "Jęczmień",
    Potatoes: "Ziemniaki",
    "Sugar Beet": "Burak Cukrowy",
    "Corn (Maize)": "Kukurydza",
    Tomatoes: "Pomidory",
    Carrots: "Marchew",
    Cabbage: "Kapusta",
    Onions: "Cebula",
    Garlic: "Czosnek",
    Beans: "Fasola",
    Peas: "Groch",
    Lettuce: "Sałata",
    Cucumber: "Ogórek",
    Grapes: "Winogrona",
    Olives: "Oliwki",
    Rye: "Żyto",
    Oats: "Owies",
    daysSinceSeeding: "Dni od siewu",
    daysUntilHarvest: "Dni do zbioru",
    assocCropPlanting: "Powiąż uprawę",
    seeOtherAssociated: "Zobacz pozostałe",
    hideOtherAssociated: "Ukryj",
    selectCropPlantings: "Wybierz uprawy",
    cropPlantingsAssociated_zero: "Brak powiązanych upraw",
    cropPlantingsAssociated_one: "Jedna powiązana uprawa",
    cropPlantingsAssociated: "{{count}} powiązanych upraw",
    parcel: "Działka",
    actualHarvestDate: "Rzeczywista data zbioru",
    actualYield: "Rzeczywisty plon",
    notSet: "Nie ustawiono",
    cropGrowingDetails: "Szczegóły uprawy",
    averageGrowingDays: "Średnia liczba dni wzrostu",
    preferredTemperature: "Preferowana temperatura",
    rowSpacing: "Odstęp między rzędami",
    plantSpacing: "Odstęp między roślinami",
    plantingDepth: "Głębokość sadzenia",
  },
}
