import { createSlice, type PayloadAction } from "@reduxjs/toolkit"
import { format } from "date-fns"
import { type GenericAnimalEvent } from "@/features/events/animal-events/types/animal-events.ts"

export type AnimalEventDraft = Omit<
  GenericAnimalEvent,
  "id" | "created_at" | "updated_at"
>

export const initialAnimalEventState: AnimalEventDraft = {
  animal: "",
  date: format(new Date(), "yyyy-MM-dd"),
}

type EventsDraftSlice = {
  draft: AnimalEventDraft
}

const initialEventsDraftState: EventsDraftSlice = {
  draft: initialAnimalEventState,
}

const eventsDraftSlice = createSlice({
  name: "eventsDraft",
  initialState: initialEventsDraftState,
  reducers: {
    setEventsDraft: (state, action: PayloadAction<AnimalEventDraft>) => {
      state.draft = action.payload
    },
    setDraftEventType: (state, action: PayloadAction<string | undefined>) => {
      state.draft.type = action.payload
      delete state.draft.event_data
    },
    setDraftEventUser: (state, action: PayloadAction<string>) => {
      state.draft.user = action.payload
    },
    setDraftEventDate: (state, action: PayloadAction<string>) => {
      state.draft.date = action.payload
    },
    setDraftEventNotes: (state, action: PayloadAction<string>) => {
      state.draft.notes = action.payload
    },
    setDraftEventAnimal: (state, action: PayloadAction<string>) => {
      state.draft.animal = action.payload
    },
    setDraftEventDescription: (state, action: PayloadAction<string>) => {
      state.draft.description = action.payload
    },
    setDraftEventGestationCalendarEntry: (
      state,
      action: PayloadAction<string>,
    ) => {
      state.draft.gestation_calendar_entry = action.payload
    },
    setDraftEventSubDataFromLocation: (
      state,
      action: PayloadAction<string>,
    ) => {
      state.draft = {
        ...state.draft,
        event_data: {
          ...state.draft.event_data,
          from_location: action.payload,
        },
      }
    },
    setDraftEventSubDataToLocation: (state, action: PayloadAction<string>) => {
      state.draft = {
        ...state.draft,
        event_data: {
          ...state.draft.event_data,
          to_location: action.payload,
        },
      }
    },
    setDraftEventSubDataValue: (state, action: PayloadAction<string>) => {
      state.draft = {
        ...state.draft,
        event_data: {
          ...state.draft.event_data,
          value: action.payload,
        },
      }
    },
    setDraftEventSubDataMedication: (
      state,
      action: PayloadAction<string | undefined>,
    ) => {
      state.draft = {
        ...state.draft,
        event_data: {
          ...state.draft.event_data,
          medication: action.payload,
        },
      }
    },
    setDraftEventSubDataMedicationUnit: (
      state,
      action: PayloadAction<string>,
    ) => {
      state.draft = {
        ...state.draft,
        event_data: {
          ...state.draft.event_data,
          unit: action.payload,
        },
      }
    },
    setDraftEventSubDataDosage: (state, action: PayloadAction<string>) => {
      state.draft = {
        ...state.draft,
        event_data: {
          ...state.draft.event_data,
          dosage: action.payload,
        },
      }
    },
    resetEventDraft: (
      state,
      action: PayloadAction<Partial<AnimalEventDraft>>,
    ) => {
      state.draft = { ...initialAnimalEventState, ...action.payload }
    },
  },
})

export const {
  setEventsDraft,
  setDraftEventType,
  setDraftEventDate,
  setDraftEventUser,
  setDraftEventNotes,
  setDraftEventAnimal,
  setDraftEventSubDataFromLocation,
  setDraftEventSubDataToLocation,
  resetEventDraft,
  setDraftEventDescription,
  setDraftEventGestationCalendarEntry,
  setDraftEventSubDataValue,
  setDraftEventSubDataMedication,
  setDraftEventSubDataDosage,
  setDraftEventSubDataMedicationUnit,
} = eventsDraftSlice.actions

export default eventsDraftSlice.reducer
