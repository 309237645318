import type React from "react"
import BaseModal from "@/features/modals/base-modal/base-modal.tsx"
import DrawerModal from "@/features/modals/drawer-modal/drawer-modal.tsx"
import InputModal from "@/features/modals/input-modal/input-modal.tsx"
import ModalConfirmNavigation from "@/features/modals/redux-modals/modal-confirm-navigation.tsx"
import ShareAppModal from "@/features/modals/redux-modals/modal-share-app.tsx"

export enum MODALS {
  BASE_MODAL = "BASE_MODAL",
  CONFIRM_NAVIGATION_MODAL = "CONFIRM_NAVIGATION_MODAL",
  SHARE_APP_MODAL = "SHARE_APP_MODAL",
  TWO_ACTION_DRAWER_MODAL = "TWO_ACTION_DRAWER_MODAL",
  INPUT_MODAL = "INPUT_MODAL",
}

export const MODAL_COMPONENTS: { [key: string]: React.FC<any> } = {
  [MODALS.BASE_MODAL]: BaseModal,
  [MODALS.CONFIRM_NAVIGATION_MODAL]: ModalConfirmNavigation,
  [MODALS.SHARE_APP_MODAL]: ShareAppModal,
  [MODALS.TWO_ACTION_DRAWER_MODAL]: DrawerModal,
  [MODALS.INPUT_MODAL]: InputModal,
}
