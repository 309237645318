import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import NewPlusIcon from "@/assets/icons/misc/new-plus-icon.tsx"
import { LoadingAnimation } from "@/components/loading-animation/loading-animation.tsx"
import NoResultsFound from "@/components/not-found/no-results-found.tsx"
import PageContentWrapperLarge from "@/components/page-content-wrappers/page-content-wrapper-large.tsx"
import MobileTopBar from "@/components/top-bars/mobile-top-bar/mobile-top-bar.tsx"
import { useGetAllHiveScalesQuery } from "@/features/bees/api/hive-scales-api.ts"
import { CreateUpdateHiveScaleDrawer } from "@/features/bees/components/hive-scales/create-update-hive-scale-drawer/create-update-hive-scale-drawer.tsx"
import { HiveScaleCard } from "@/features/bees/components/hive-scales/hive-scale-card/hive-scale-card.tsx"
import { HOME_SCREEN_PATH } from "@/utils/constants/routes.ts"

const AllHiveScales = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const {
    data: allHiveScales,
    isLoading,
    isFetching,
  } = useGetAllHiveScalesQuery()

  const loadingState = isLoading || isFetching
  const noResultsFoundState =
    !isLoading && !isFetching && allHiveScales && allHiveScales.length === 0
  const showResultsState =
    !isLoading && !isFetching && allHiveScales && allHiveScales.length > 0

  return (
    <>
      <MobileTopBar
        title={t("myScales")}
        onBackClick={() => navigate(HOME_SCREEN_PATH)}
        icon={<CreateUpdateHiveScaleDrawer trigger={<NewPlusIcon />} />}
        onIconClick={() => {}}
      />
      <PageContentWrapperLarge className={"flex flex-col"}>
        {loadingState && (
          <LoadingAnimation customStyle={{ height: "inherit" }} />
        )}
        {noResultsFoundState && (
          <NoResultsFound
            image={"hive"}
            className={"h-full"}
            message={t("noHiveScalesFound")}
          />
        )}
        {showResultsState &&
          allHiveScales.map((hiveScale, index) => (
            <HiveScaleCard hiveScale={hiveScale} key={index} showHive={true} />
          ))}
      </PageContentWrapperLarge>
    </>
  )
}

export default AllHiveScales
