import { apiSlice, TagType } from "@/features/api/apiSlice.ts"
import {
  type AssistantMessage,
  type AssistantMessageInput,
  type AssistantThread,
  type AssistantThreadsList,
} from "@/features/assistant/types/assistant.ts"

const ASSISTANT_BASE_URL = "ai-assistant/"

export const assistantApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllAssistantThreadsForUser: builder.query<AssistantThreadsList, void>({
      query: () => ({
        url: ASSISTANT_BASE_URL,
      }),
      providesTags: () => [{ type: TagType.Assistant, id: "LIST" }],
    }),
    getAiAssistantThread: builder.query<AssistantThread, string>({
      query: (threadId) => ({
        url: ASSISTANT_BASE_URL + `${threadId}/`,
      }),
      providesTags: (_result, _error, threadId) => [
        { type: TagType.Assistant, id: threadId },
      ],
    }),
    createAssistant: builder.mutation<AssistantThread, { title: string }>({
      query: ({ title }) => ({
        url: ASSISTANT_BASE_URL,
        method: "POST",
        body: { title },
      }),
      invalidatesTags: [{ type: TagType.Assistant, id: "LIST" }],
    }),
    deleteThread: builder.mutation<void, string>({
      query: (threadId) => ({
        url: ASSISTANT_BASE_URL + `${threadId}/`,
        method: "DELETE",
      }),
      invalidatesTags: (_result, _error, threadId) => [
        { type: TagType.Assistant, id: threadId },
        { type: TagType.Assistant, id: "LIST" },
      ],
    }),
    postMessageToAssistant: builder.mutation<
      AssistantMessage,
      AssistantMessageInput
    >({
      query: ({ threadId, message, suggested_question_id }) => ({
        url: ASSISTANT_BASE_URL + `${threadId}/say/`,
        method: "POST",
        body: { message, suggested_question_id },
      }),
    }),
  }),

  overrideExisting: false,
})

export const {
  useGetAiAssistantThreadQuery,
  useGetAllAssistantThreadsForUserQuery,
  useCreateAssistantMutation,
  usePostMessageToAssistantMutation,
  useDeleteThreadMutation,
} = assistantApi
