import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { AddEventIcon } from "@/assets/widgets/add-event-icon.tsx"
import { useGetAllParcelEventsQuery } from "@/features/events/parcel-events/api/parcel-events-api.ts"
import { ParcelEventCard } from "@/features/events/parcel-events/components/parcel-event-card/parcel-event-card.tsx"
import EmptyContentCreateItemButton from "@/features/home/components/empty-content-create-item-button/empty-content-create-item-button.tsx"
import WidgetHeader from "@/features/home/components/widget-header/widget-header.tsx"
import {
  NEW_PARCEL_EVENT_SCREEN_PATH,
  PARCEL_EVENTS_SCREEN_PATH,
} from "@/utils/constants/routes.ts"

interface Props {
  parcelId?: string
}

export const ParcelEventsSummaryWidget: React.FC<Props> = ({ parcelId }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { data: events = [] } = useGetAllParcelEventsQuery()

  const workingEvents = parcelId
    ? events.filter((event) => event.parcel === parcelId)
    : events

  const newEventString =
    NEW_PARCEL_EVENT_SCREEN_PATH + (parcelId ? `?parcelId=${parcelId}` : "")

  const addItemsGoToAction =
    workingEvents && workingEvents?.length > 0
      ? () => navigate(newEventString)
      : undefined

  return (
    <div
      className={
        "widget-border box-border flex w-full flex-col gap-5 bg-white px-4 py-4"
      }
    >
      <WidgetHeader
        title={t("parcelEvents")}
        navigateTo={PARCEL_EVENTS_SCREEN_PATH}
        icon={<AddEventIcon />}
        addItemGoTo={addItemsGoToAction}
      />
      {workingEvents && workingEvents.length > 0 ? (
        <div className={"flex flex-col gap-2"}>
          {workingEvents.slice(0, 3).map((event, index) => (
            <ParcelEventCard event={event} key={index} />
          ))}
        </div>
      ) : (
        <EmptyContentCreateItemButton navigateTo={newEventString} />
      )}
    </div>
  )
}
