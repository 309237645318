import React from "react"
import { useTranslation } from "react-i18next"
import bee from "@/assets/images/bee-hive-honey.png"
import cowIcon from "@/assets/my-farm-widget/cow.png"
import goat from "@/assets/my-farm-widget/female-goat.png"
import hen from "@/assets/my-farm-widget/hen.png"
import pork from "@/assets/my-farm-widget/pork.png"
import sheep from "@/assets/my-farm-widget/sheep.png"
import { Button } from "@/components/shadcn/button.tsx"
import {
  ANIMALS_IMAGE_CARD_BACKGROUND_COLOR,
  getRadialBackground,
} from "@/features/farm/utils/farm-species-utils.ts"
import { cn } from "@/utils/styles.ts"

interface AppPackageSelectionStepProps {
  onPackageSelect: (selectedModules: string[]) => void
  selectedPackages: string[]
  onContinue: () => void
  onBack: () => void
}

interface ModuleCardProps {
  title: string
  selected: boolean
  onClick: () => void
  icon: string
  color0: string
  color1: string
}

const ModuleCard: React.FC<ModuleCardProps> = ({
  title,
  selected,
  onClick,
  icon,
  color0,
  color1,
}) => {
  return (
    <div
      className={`flex w-full cursor-pointer flex-col items-center justify-center gap-2 rounded-sm border bg-white p-2 transition-all ${
        selected ? "border-2 border-brown-500" : "border-gray-300"
      }`}
      onClick={onClick}
    >
      <div
        className={cn(
          "relative aspect-square w-full flex-grow overflow-hidden rounded-[6px]",
        )}
        style={{ background: getRadialBackground(color0, color1) }}
      >
        <img
          className={"absolute bottom-[-3px] right-[-3px] h-full object-cover"}
          src={icon}
          alt=""
        />
      </div>
      <div className={"flex h-10 w-full items-center justify-center p-2"}>
        <h3
          className={cn("text-center text-base font-medium text-neutral-700")}
        >
          {title}
        </h3>
      </div>
    </div>
  )
}

export const AppPackageSelectionStep: React.FC<
  AppPackageSelectionStepProps
> = ({ onPackageSelect, selectedPackages, onContinue, onBack }) => {
  const { t } = useTranslation()

  const moduleDetails: Record<
    string,
    {
      title: string
      icon: string
      color0: string
      color1: string
    }
  > = {
    cow_module: {
      title: t("Cows"),
      icon: cowIcon,
      color0: ANIMALS_IMAGE_CARD_BACKGROUND_COLOR.HEIFER0,
      color1: ANIMALS_IMAGE_CARD_BACKGROUND_COLOR.HEIFER1,
    },
    pork_module: {
      title: t("Pork"),
      icon: pork,
      color0: ANIMALS_IMAGE_CARD_BACKGROUND_COLOR.MALE_PIG0,
      color1: ANIMALS_IMAGE_CARD_BACKGROUND_COLOR.MALE_PIG1,
    },
    sheep_module: {
      title: t("Sheep"),
      icon: sheep,
      color0: ANIMALS_IMAGE_CARD_BACKGROUND_COLOR.SHEEP0,
      color1: ANIMALS_IMAGE_CARD_BACKGROUND_COLOR.SHEEP1,
    },
    goat_module: {
      title: t("Goat"),
      icon: goat,
      color0: ANIMALS_IMAGE_CARD_BACKGROUND_COLOR.GOAT0,
      color1: ANIMALS_IMAGE_CARD_BACKGROUND_COLOR.GOAT1,
    },
    bee_module: {
      title: t("Beekeeping"),
      icon: bee,
      color0: ANIMALS_IMAGE_CARD_BACKGROUND_COLOR.BEEF0,
      color1: ANIMALS_IMAGE_CARD_BACKGROUND_COLOR.BEEF1,
    },
    bird_module: {
      title: t("birds"),
      icon: hen,
      color0: ANIMALS_IMAGE_CARD_BACKGROUND_COLOR.HEN0,
      color1: ANIMALS_IMAGE_CARD_BACKGROUND_COLOR.HEN1,
    },
  }

  const toggleModule = (module: string) => {
    const newSelection = [...selectedPackages]

    if (newSelection.includes(module)) {
      const index = newSelection.indexOf(module)
      newSelection.splice(index, 1)
    } else {
      newSelection.push(module)
    }

    onPackageSelect(newSelection)
  }

  return (
    <div className="w-full overflow-y-scroll">
      <p className="mb-6 border-b border-t py-3 text-center text-base text-neutral-700">
        {t(
          "Choose at least one module that corresponds to your farm's activities",
        )}
      </p>

      <div className="grid w-full grid-cols-2 gap-2">
        {Object.entries(moduleDetails).map(([module, details]) => (
          <ModuleCard
            key={module}
            title={details.title}
            selected={selectedPackages.includes(module)}
            onClick={() => toggleModule(module)}
            icon={details.icon}
            color0={details.color0}
            color1={details.color1}
          />
        ))}
      </div>

      <div className={"space-y-4"}>
        <Button
          size={"lg"}
          disabled={!selectedPackages.length}
          className={"mt-16 w-full bg-brown-500"}
          onClick={onContinue}
        >
          {t("continue")}
        </Button>
        <Button
          size={"lg"}
          variant={"outline"}
          className={"mt-16 w-full text-brown-500"}
          onClick={onBack}
        >
          {t("back")}
        </Button>
      </div>
    </div>
  )
}

export default AppPackageSelectionStep
