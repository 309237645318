import { apiSlice, TagType } from "@/features/api/apiSlice.ts"
import { type Medication } from "@/features/events/common/types/common-events-types.ts"

const BEE_MEDICATION_TYPES_BASE_URL = "/bee-medications/"

export const BeeMedicationTypesApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllBeeMedicationTypes: builder.query<Record<string, Medication>, void>({
      query: () => ({
        url: BEE_MEDICATION_TYPES_BASE_URL,
      }),
      transformResponse: (response: Medication[]) => {
        return response.reduce<Record<string, Medication>>((acc, eventType) => {
          return { ...acc, [eventType.id]: eventType }
        }, {})
      },
      providesTags: () => [{ type: TagType.MedicationTypes, id: "LIST" }],
    }),
  }),
})

export const { useGetAllBeeMedicationTypesQuery } = BeeMedicationTypesApi
