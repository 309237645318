import Hotjar from "@hotjar/browser"
import * as Sentry from "@sentry/react"
import i18n from "i18next"
import LanguageDetector from "i18next-browser-languagedetector"
import { createRoot } from "react-dom/client"
import { initReactI18next } from "react-i18next"
import { Provider } from "react-redux"
import { PersistGate } from "redux-persist/integration/react"
import App from "./App.tsx"
import "./style/tailwind.css"
import "./style/index.css"
import "./style/theme.css"
import "./style/animations.css"
import { EN_TRANSLATIONS } from "./locales/en/translation.ts"
import { RO_TRANSLATIONS } from "./locales/ro/translation.ts"
import { persistor, store } from "./redux/store.ts"
import ErrorBoundary from "@/components/error-boundary/error-boundary.tsx"
import { ES_TRANSLATIONS } from "@/locales/es/translations.ts"
import { FR_TRANSLATIONS } from "@/locales/fr/translations.ts"
import { LOCALES } from "@/locales/language-utils.ts"
import { PL_TRANSLATIONS } from "@/locales/pl/translations.ts"

Hotjar.init(5039634, 6)

const element = document.getElementById("root")

if (!element) {
  throw new Error("Root element not present")
}

const root = createRoot(element)

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      ro: RO_TRANSLATIONS,
      en: EN_TRANSLATIONS,
      pl: PL_TRANSLATIONS,
      es: ES_TRANSLATIONS,
      fr: FR_TRANSLATIONS,
    },
    debug: false,
    fallbackLng: "ro",
    supportedLngs: LOCALES,
    detection: {
      order: ["localStorage", "navigator"],
      lookupLocalStorage: "i18nextLng",
      caches: ["localStorage"],
    },
    interpolation: {
      escapeValue: false,
    },
  })
  .then(() => {
    root.render(
      <Provider store={store}>
        <Sentry.ErrorBoundary fallback={<ErrorBoundary />}>
          <PersistGate loading={null} persistor={persistor}>
            <App />
          </PersistGate>
        </Sentry.ErrorBoundary>
      </Provider>,
    )
  })
