export const SUPPORTED_LANGUAGES = {
  en: {
    value: "en",
    flag: "🇬🇧",
    nameKey: "English",
  },
  ro: {
    value: "ro",
    flag: "🇷🇴",
    nameKey: "romanian",
  },
  pl: {
    value: "pl",
    flag: "🇵🇱",
    nameKey: "Polski",
  },
  es: {
    value: "es",
    flag: "🇪🇸",
    nameKey: "Español",
  },
  fr: {
    value: "fr",
    flag: "🇫🇷",
    nameKey: "Français",
  },
} as const

export type SupportedLanguageCode = keyof typeof SUPPORTED_LANGUAGES

export const countryCodes = {
  en: { code: "+44", flag: "🇬🇧", name: "United Kingdom" },
  pl: { code: "+48", flag: "🇵🇱", name: "Poland" },
  es: { code: "+34", flag: "🇪🇸", name: "Spain" },
  ro: { code: "+40", flag: "🇷🇴", name: "Romania" },
  fr: { code: "+33", flag: "🇫🇷", name: "France" },
}

export type CountryCodeKey = keyof typeof countryCodes

export const countryCodeOptions = Object.entries(countryCodes).map(
  ([key, value]) => ({
    value: key,
    label: `${value.flag} ${value.code}`,
  }),
)
