import isEqual from "lodash.isequal"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useSearchParams } from "react-router-dom"
import { toast } from "react-toastify"
import PageContentWrapperLarge from "@/components/page-content-wrappers/page-content-wrapper-large.tsx"
import EditorTopBar from "@/components/top-bars/editor-top-bar/editor-top-bar.tsx"
import { useCreateCropPlantingMutation } from "@/features/crops/api/crops-planting-api.ts"
import { CropPlantingEditor } from "@/features/crops/components/crop-planting-editor/crop-planting-editor.tsx"
import {
  type CropPlantingCreateUpdateRequest,
  initialCropPlantingState,
} from "@/features/crops/types/crop-planting.ts"
import useModal from "@/features/modals/hooks/use-modal.ts"
import { MODALS } from "@/features/modals/types/modals.tsx"
import { ALL_CROPS_PLANTINGS_PATH } from "@/utils/constants/routes.ts"

const getInitialCropPlantingState = (parcelId: string | null) => {
  if (!parcelId) return initialCropPlantingState
  return {
    ...initialCropPlantingState,
    parcel: parcelId,
  }
}

const NewCropPlanting = () => {
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()
  const parcelId = searchParams.get("parcelId")
  const [workingCropPlanting, setWorkingCropPlanting] =
    useState<CropPlantingCreateUpdateRequest>(
      getInitialCropPlantingState(parcelId),
    )
  const isModified = !isEqual(workingCropPlanting, initialCropPlantingState)
  const [createCropPlanting] = useCreateCropPlantingMutation()
  const navigate = useNavigate()
  const { openModal } = useModal()

  const handleSave = async () => {
    const promise = createCropPlanting(workingCropPlanting).unwrap()
    await toast
      .promise(promise, {
        pending: t("savingCropPlanting"),
        success: t("cropPlantingSaved"),
        error: t("cropPlantingError"),
      })
      .then(() => {
        navigate(ALL_CROPS_PLANTINGS_PATH, { replace: true })
      })
  }

  const onBackClick = () => {
    if (isModified) {
      openModal(MODALS.BASE_MODAL, {
        title: t("unsavedChanges"),
        content: t("youWillLoseChanges"),
        onActionClick: () => {
          navigate(-1)
        },
      })
    } else {
      navigate(-1)
    }
  }

  return (
    <>
      <EditorTopBar
        text={t("newCropPlanting")}
        onBackClick={onBackClick}
        onSave={handleSave}
        isModified={isModified}
      />
      <PageContentWrapperLarge>
        <CropPlantingEditor
          isEdit={!!parcelId}
          cropPlanting={workingCropPlanting}
          onCropPlantingChange={setWorkingCropPlanting}
        />
      </PageContentWrapperLarge>
    </>
  )
}

export default NewCropPlanting
