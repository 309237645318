import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { LoadingAnimation } from "@/components/loading-animation/loading-animation.tsx"
import NoResultsFound from "@/components/not-found/no-results-found.tsx"
import PageContentWrapperLarge from "@/components/page-content-wrappers/page-content-wrapper-large.tsx"
import MobileTopBar from "@/components/top-bars/mobile-top-bar/mobile-top-bar.tsx"
import { useGetParcelsQuery } from "@/features/parcels/api/parcels-api.ts"
import { ParcelCard } from "@/features/parcels/components/parcel-card/parcel-card.tsx"
import { MENU_PATH, NEW_PARCEL_SCREEN_PATH } from "@/utils/constants/routes.ts"

export const MyParcelsView: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { data, isLoading } = useGetParcelsQuery()

  return (
    <>
      <MobileTopBar
        title={t("MyParcels")}
        onBackClick={() => navigate(MENU_PATH)}
        navigateTo={NEW_PARCEL_SCREEN_PATH}
      />
      <PageContentWrapperLarge className={"space-y-4 p-4"}>
        {(isLoading || !data) && (
          <LoadingAnimation customStyle={{ height: "inherit" }} />
        )}
        {!isLoading && data && data.length === 0 && (
          <NoResultsFound
            image={"location"}
            className={"h-full"}
            imageClassName={"w-1/3 opacity-50"}
            message={t("noParcelsFound")}
          />
        )}
        {!isLoading && data && data.length > 0 && (
          <>
            {data.map((field) => (
              <ParcelCard parcel={field} key={field.id} />
            ))}
          </>
        )}
      </PageContentWrapperLarge>
    </>
  )
}
