import React from "react"
import { useNavigate } from "react-router-dom"
import { TopBarContainer } from "../top-bar-container"
import BackIcon from "@/assets/icons/misc/back-icon.tsx"
import CircleTickIcon from "@/assets/icons/task/circle-tick-icon.tsx"
import styles from "@/components/top-bars/top-bars-common.module.css"

interface SettingsTopBarProps {
  title: string
  onSaveChanges?: () => void
  onBackClick?: () => void
  isModified?: boolean
}

const SettingsTopBar: React.FC<SettingsTopBarProps> = ({
  title,
  onSaveChanges,
  onBackClick,
  isModified,
}) => {
  const navigate = useNavigate()
  const handleBackClick = () => {
    if (onBackClick) {
      onBackClick()
    } else {
      navigate(-1)
    }
  }

  return (
    <TopBarContainer>
      <div className={styles.left}>
        <div className={styles.actionIcon} onClick={() => handleBackClick()}>
          <BackIcon />
        </div>
        <div className={styles.title}>{title}</div>
      </div>
      <div>
        {isModified && onSaveChanges && (
          <div className={styles.actionIcon}>
            <CircleTickIcon size={30} onClick={() => onSaveChanges()} />
          </div>
        )}
      </div>
    </TopBarContainer>
  )
}

export default SettingsTopBar
