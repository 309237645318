import React, { useEffect, useState, type FormEvent } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import LogoBrown from "@/assets/icons/logos/logo-brown.tsx"
import TikTokIcon from "@/assets/icons/misc/tik-tok-icon.tsx"
import FacebookIcon from "@/assets/icons/settings/facebook-icon.tsx"
import InstagramIcon from "@/assets/icons/settings/instagram-icon.tsx"
import { Button } from "@/components/shadcn/button.tsx"
import { Input, PasswordInput } from "@/components/shadcn/input.tsx"
import {
  useLazyGetProfileQuery,
  useLoginMutation,
} from "@/features/settings-account/api/account-api.ts"
import { useAppDispatch } from "@/redux/hooks.ts"
import { setAuth, setToken } from "@/redux/slices/auth-slice.ts"
import {
  HOME_SCREEN_PATH,
  LOST_PASSWORD_PATH,
  ONBOARDING_PATH,
  SIGNUP_SCREEN_PATH,
} from "@/utils/constants/routes.ts"

const LoginView: React.FC = () => {
  const { t } = useTranslation()
  const { i18n } = useTranslation()
  const navigate = useNavigate()
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const [login, { isLoading }] = useLoginMutation()
  const [fetchUser] = useLazyGetProfileQuery()
  const [error, setError] = useState(false)
  const errorRef = React.createRef<HTMLDivElement>()
  const dispatch = useAppDispatch()

  useEffect(() => {
    const token = localStorage.getItem("token")
    localStorage.removeItem("token")
    if (!token) return
    dispatch(setToken(token))
    navigate(HOME_SCREEN_PATH)
  }, [dispatch, navigate])

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault()
    login({ username, password })
      .unwrap()
      .then(({ token, farm_id }) => {
        dispatch(setAuth({ token, farmId: farm_id }))
        return fetchUser()
      })
      .then((response) => {
        if (response.data) {
          i18n.changeLanguage(response.data.language)
          if (response.data.farm) {
            navigate(HOME_SCREEN_PATH)
          } else {
            navigate(ONBOARDING_PATH)
          }
        }
      })
      .catch(() => setError(true))
  }

  useEffect(() => {
    if (error && errorRef.current) {
      errorRef.current.focus()
    }
  }, [error, errorRef])

  return (
    <div className={"flex h-full w-full flex-col items-center justify-center"}>
      <div
        className={
          "flex h-[calc(100vh-80px-env(safe-area-inset-bottom))] w-[300px] flex-col items-center justify-center gap-6"
        }
      >
        <LogoBrown width={150} height={150} />

        <div className={"relative flex w-full flex-col gap-5"}>
          <Input
            id="email"
            type="email"
            name="email"
            className={"text-base"}
            data-testid="email-input"
            autoComplete="true"
            autoCapitalize={"none"}
            placeholder={t("pleaseEnterEmail")}
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            disabled={isLoading}
          />
          <PasswordInput
            id="password"
            className={"text-base"}
            data-testid="password-input"
            name={"password"}
            placeholder={t("pleaseEnterPassword")}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            disabled={isLoading}
          />
          <Button
            className={
              "-mt-3 ml-auto mr-0 font-normal text-brown-500 underline"
            }
            variant={"link"}
            onClick={() => navigate(LOST_PASSWORD_PATH)}
            data-testid="forgot-password-link"
          >
            {t("lostPassword")}
          </Button>
        </div>

        <span className={"mt-5 w-full space-y-5"}>
          <Button
            onClick={handleSubmit}
            size={"lg"}
            className={"w-full bg-brown-500"}
            data-testid="login-button"
            disabled={isLoading || !username || !password}
          >
            {isLoading ? t("logingIn") : t("login")}
          </Button>
          <Button
            type={"button"}
            data-testid="signup-link"
            variant={"outline"}
            className={"h-12 w-full text-brown-500"}
            onClick={() => navigate(SIGNUP_SCREEN_PATH)}
          >
            {t("createAccount")}
          </Button>
        </span>

        {error && (
          <div
            ref={errorRef}
            style={{ backgroundColor: "rgba(252, 165, 165, 0.5)" }}
            className={
              "mt-5 w-full rounded-lg p-4 text-center font-medium text-red-500"
            }
            data-testid="login-error-message"
          >
            {t("wrongLoginCredentials")}
          </div>
        )}
      </div>

      <div className={"flex flex-col items-center justify-center gap-2"}>
        <p className={"font-medium"}>{t("followUs")}:</p>
        <div className={"flex items-center justify-center gap-5"}>
          <a href="https://www.facebook.com/FarmPlannerApp">
            <FacebookIcon size={30} />
          </a>
          <a href="https://www.tiktok.com/@farm.planner">
            <TikTokIcon size={30} />
          </a>
          <a href="https://www.instagram.com/farmplannerapp/">
            <InstagramIcon size={30} />
          </a>
        </div>
      </div>
    </div>
  )
}

export default LoginView
