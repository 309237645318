/**
 * Formats an area value with appropriate units
 * @param {number} areaInHectares - The area in hectares
 * @param {number} [precision=2] - Number of decimal places to display
 * @returns {string} Formatted area with appropriate unit
 */
export const formatArea = (areaInHectares: number, precision = 2) => {
  // Convert to appropriate unit
  if (areaInHectares >= 1) {
    // If area is 1 hectare or more, keep as hectares
    return `${areaInHectares.toFixed(precision)} ha`
  } else if (areaInHectares >= 0.01) {
    // If area is between 0.01 ha and 1 ha, convert to ares (1 are = 0.01 hectare)
    const areaInAres = areaInHectares * 100
    return `${areaInAres.toFixed(precision)} a`
  } else {
    // If area is less than 0.01 ha, convert to square meters (1 ha = 10,000 sq m)
    const areaInSquareMeters = areaInHectares * 10000
    return `${areaInSquareMeters.toFixed(precision)} m²`
  }
}

// Usage examples:
// formatArea(5.6789);       // "5.68 ha"
// formatArea(0.5);          // "50.00 a" (50 ares)
// formatArea(0.0075);       // "75.00 m²" (75 square meters)
// formatArea(25.3, 1);      // "25.3 ha" (with 1 decimal place)
