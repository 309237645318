import { useState, type FormEvent } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import PasswordIcon from "@/assets/icons/settings/password-icon.tsx"
import PageContentWrapperLarge from "@/components/page-content-wrappers/page-content-wrapper-large.tsx"
import { Button } from "@/components/shadcn/button.tsx"
import { PasswordInput } from "@/components/shadcn/input.tsx"
import SettingsTopBar from "@/components/top-bars/settings-top-bar/settings-top-bar.tsx"
import { useChangePasswordMutation } from "@/features/settings-account/api/account-api.ts"

/**
 * Component for changing user password in settings
 * Uses the custom API endpoint that works with token authentication
 */
const SettingsResetPassword = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [currentPassword, setCurrentPassword] = useState("")
  const [newPassword, setNewPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")

  const [changePassword, { isLoading, reset }] = useChangePasswordMutation()

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault()
    reset()

    if (newPassword !== confirmPassword) {
      toast.error(t("passwordsDoNotMatch"))
      return
    }

    if (newPassword.length < 8) {
      toast.error(t("passwordTooShort"))
      return
    }

    const promise = changePassword({
      current_password: currentPassword,
      new_password: newPassword,
    }).unwrap()

    toast
      .promise(promise, {
        pending: t("changing"),
        success: t("passwordChangeSuccessful"),
        error: t("failedToChangePassword"),
      })
      .then(() => {
        setCurrentPassword("")
        setNewPassword("")
        setConfirmPassword("")

        const timer = setTimeout(() => navigate(-1), 1500)
        return () => clearTimeout(timer)
      })
      .catch((error) => {
        let errorMessage = getErrorMessage(error)
        toast.error(errorMessage)
      })
  }

  // @ts-ignore
  function getErrorMessage(error) {
    // Default error message
    let errorMessage = t("failedToChangePassword")

    if (!("data" in error)) {
      return t("pleaseRefreshAndTryAgain")
    }

    const errorData = error.data

    // Case 1: Handle 'detail' field
    if (errorData?.detail) {
      errorMessage = handleDetailField(errorData.detail)
    }
    // Case 2: Handle current_password validation errors
    else if (errorData?.current_password) {
      errorMessage = handleFieldErrors(errorData.current_password, {
        "Current password is incorrect.": "incorrectCurrentPassword",
      })
    }
    // Case 3: Handle new_password validation errors
    else if (errorData?.new_password) {
      errorMessage = handleFieldErrors(errorData.new_password, {
        "Password must be at least 8 characters": "passwordTooShort",
      })
    }
    return errorMessage
  }

  // @ts-ignore
  function handleDetailField(detail) {
    if (typeof detail === "string") {
      return t(detail) !== detail ? t(detail) : detail
    }

    if (Array.isArray(detail)) {
      //@ts-ignore
      return detail.map((msg) => (t(msg) !== msg ? t(msg) : msg)).join(". ")
    }

    if (typeof detail === "object") {
      // Extract and translate nested messages
      const errorMap = {
        "Current password is incorrect.": "incorrectCurrentPassword",
        "Password must be at least 8 characters": "passwordTooShort",
        // Add more mappings as needed
      }

      const errorMessages = Object.values(detail)
        .flat()
        .filter((msg) => typeof msg === "string")
        .map((msg) => {
          // @ts-ignore
          const translationKey = errorMap[msg]
          return translationKey ? t(translationKey) : msg
        })

      return errorMessages.length > 0
        ? errorMessages.join(". ")
        : t("failedToChangePassword")
    }

    return t("failedToChangePassword")
  }

  // @ts-ignore
  function handleFieldErrors(fieldErrors, errorMap = {}) {
    const messages = Array.isArray(fieldErrors) ? fieldErrors : [fieldErrors]

    return messages
      .map((msg) => {
        // @ts-ignore
        if (errorMap[msg]) {
          // @ts-ignore
          return t(errorMap[msg])
        }
        return t(msg) !== msg ? t(msg) : msg
      })
      .join(". ")
  }

  return (
    <>
      <SettingsTopBar title={t("changePassword")} />
      <PageContentWrapperLarge className={"flex flex-col gap-[10px]"}>
        <div className="relative mx-auto my-5 box-border flex h-20 w-20 flex-shrink-0 items-center justify-center overflow-hidden rounded-full border border-neutral-700 bg-white">
          <PasswordIcon />
        </div>

        <form
          onSubmit={handleSubmit}
          className="widget-border box-border flex w-full flex-col gap-5 bg-white p-4"
        >
          <div className={"space-y-2"}>
            <label
              htmlFor="currentPassword"
              className={"text-base font-[500] text-neutral-700"}
            >
              {t("inputCurrentPassword")}
            </label>
            <PasswordInput
              id="currentPassword"
              name="currentPassword"
              placeholder={t("inputCurrentPassword")}
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
              disabled={isLoading}
              required
            />
          </div>

          <div className={"space-y-2"}>
            <label
              htmlFor="newPassword"
              className={"text-base font-[500] text-neutral-700"}
            >
              {t("newPassword")}
            </label>
            <div className="relative">
              <PasswordInput
                id={"newPassword"}
                name={"newPassword"}
                placeholder={t("newPassword")}
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                disabled={isLoading}
                required
              />
            </div>
          </div>

          <div className={"flex flex-col gap-2"}>
            <label
              htmlFor="confirmPassword"
              className={"text-base font-[500] text-neutral-700"}
            >
              {t("confirmPassword")}
            </label>
            <PasswordInput
              id="confirmPassword"
              name="confirmPassword"
              placeholder={t("confirmPassword")}
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              disabled={isLoading}
              required
            />
          </div>

          <Button
            type="submit"
            className="w-full"
            size={"lg"}
            disabled={isLoading}
          >
            {isLoading ? t("changing") : t("changePassword")}
          </Button>
        </form>
      </PageContentWrapperLarge>
    </>
  )
}

export default SettingsResetPassword
