import { format } from "date-fns"
import React from "react"
import { useTranslation } from "react-i18next"
import ParcelIcon from "@/assets/svg/parcel-icon.svg?react"
import Skeleton from "@/components/skeleton/skeleton.tsx"
import { type CropPlanting } from "@/features/crops/types/crop-planting.ts"

interface CropPlantingDetailsProps {
  cropPlanting: CropPlanting
}

const CalendarIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect>
    <line x1="16" y1="2" x2="16" y2="6"></line>
    <line x1="8" y1="2" x2="8" y2="6"></line>
    <line x1="3" y1="10" x2="21" y2="10"></line>
  </svg>
)

const HarvestIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
    <polyline points="9 22 9 12 15 12 15 22"></polyline>
  </svg>
)

const YieldIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <line x1="12" y1="20" x2="12" y2="10"></line>
    <line x1="18" y1="20" x2="18" y2="4"></line>
    <line x1="6" y1="20" x2="6" y2="16"></line>
  </svg>
)

const TemperatureIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M14 14.76V3.5a2.5 2.5 0 0 0-5 0v11.26a4.5 4.5 0 1 0 5 0z"></path>
  </svg>
)

const SpacingIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <polyline points="7 8 3 12 7 16"></polyline>
    <polyline points="17 8 21 12 17 16"></polyline>
    <line x1="3" y1="12" x2="21" y2="12"></line>
  </svg>
)

const DepthIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <line x1="12" y1="5" x2="12" y2="19"></line>
    <polyline points="5 12 12 19 19 12"></polyline>
  </svg>
)

const CropPlantingDetails: React.FC<CropPlantingDetailsProps> = ({
  cropPlanting,
}) => {
  const { t } = useTranslation()

  const cropDetails = cropPlanting.crop_details

  const formatDate = (dateString: string | null) => {
    if (!dateString) return t("notSet")
    return format(new Date(dateString), "dd.MM.yyyy")
  }

  return (
    <div className="widget-border bg-white p-4">
      <div className="mb-4 flex items-center">
        {cropPlanting.crop_picture ? (
          <img
            src={cropPlanting.crop_picture}
            alt={cropPlanting.crop_name}
            className="mr-4 h-16 w-16 rounded-full object-cover"
          />
        ) : (
          <Skeleton className={"mr-4 flex h-16 w-16 rounded-full"} />
        )}
        <div>
          <h2 className="text-xl font-bold">{t(cropPlanting.crop_name)}</h2>
          {cropDetails && (
            <p className="italic text-gray-600">
              {cropDetails.scientific_name}
            </p>
          )}
        </div>
      </div>

      {cropDetails && (
        <div className="mb-4">
          <p className="text-gray-700">{cropDetails.description}</p>
        </div>
      )}

      <div className="mb-4 grid grid-cols-1 gap-4 md:grid-cols-2">
        <div className="flex items-center">
          <ParcelIcon className={"h-[20px] w-[20px]"} />
          <div className="ml-2">
            <p className="text-sm text-gray-500">{t("parcel")}</p>
            <p className="font-medium">{cropPlanting.parcel_name}</p>
          </div>
        </div>

        <div className="flex items-center">
          <CalendarIcon />
          <div className="ml-2">
            <p className="text-sm text-gray-500">{t("seedingDate")}</p>
            <p className="font-medium">
              {formatDate(cropPlanting.seeding_date)}
            </p>
          </div>
        </div>

        <div className="flex items-center">
          <HarvestIcon />
          <div className="ml-2">
            <p className="text-sm text-gray-500">{t("plannedHarvestDate")}</p>
            <p className="font-medium">
              {formatDate(cropPlanting.planned_harvest_date)}
            </p>
          </div>
        </div>

        <div className="flex items-center">
          <HarvestIcon />
          <div className="ml-2">
            <p className="text-sm text-gray-500">{t("actualHarvestDate")}</p>
            <p className="font-medium">
              {formatDate(cropPlanting.actual_harvest_date)}
            </p>
          </div>
        </div>

        <div className="flex items-center">
          <YieldIcon />
          <div className="ml-2">
            <p className="text-sm text-gray-500">{t("expectedYield")}</p>
            <p className="font-medium">
              {cropPlanting.expected_yield
                ? `${cropPlanting.expected_yield} kg/ha`
                : t("notSet")}
            </p>
          </div>
        </div>

        <div className="flex items-center">
          <YieldIcon />
          <div className="ml-2">
            <p className="text-sm text-gray-500">{t("actualYield")}</p>
            <p className="font-medium">
              {cropPlanting.actual_yield
                ? `${cropPlanting.actual_yield} kg/ha`
                : t("notSet")}
            </p>
          </div>
        </div>
      </div>

      {/* Additional Crop Details */}
      {cropDetails && (
        <div className="mt-6 border-t border-gray-200 pt-4">
          <h3 className="mb-3 text-lg font-semibold">
            {t("cropGrowingDetails")}
          </h3>

          <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
            <div className="flex items-center">
              <CalendarIcon />
              <div className="ml-2">
                <p className="text-sm text-gray-500">
                  {t("averageGrowingDays")}
                </p>
                <p className="font-medium">
                  {cropDetails.average_growing_days} {t("days")}
                </p>
              </div>
            </div>

            <div className="flex items-center">
              <TemperatureIcon />
              <div className="ml-2">
                <p className="text-sm text-gray-500">
                  {t("preferredTemperature")}
                </p>
                <p className="font-medium">
                  {cropDetails.preferred_temperature_min}°C -{" "}
                  {cropDetails.preferred_temperature_max}°C
                </p>
              </div>
            </div>

            <div className="flex items-center">
              <SpacingIcon />
              <div className="ml-2">
                <p className="text-sm text-gray-500">{t("rowSpacing")}</p>
                <p className="font-medium">{cropDetails.row_spacing} cm</p>
              </div>
            </div>

            <div className="flex items-center">
              <SpacingIcon />
              <div className="ml-2">
                <p className="text-sm text-gray-500">{t("plantSpacing")}</p>
                <p className="font-medium">{cropDetails.plant_spacing} cm</p>
              </div>
            </div>

            <div className="flex items-center">
              <DepthIcon />
              <div className="ml-2">
                <p className="text-sm text-gray-500">{t("plantingDepth")}</p>
                <p className="font-medium">{cropDetails.planting_depth} cm</p>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default CropPlantingDetails
