import { useState } from "react"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import RenderFunctionDrawer, {
  type RenderFunctionDrawerItemComponentProps,
} from "@/components/render-function-drawer/render-function-drawer.tsx"
import { WidgetButton } from "@/components/widget-button/widget-button.tsx"
import { useUpdateHiveScaleMutation } from "@/features/bees/api/hive-scales-api.ts"
import { useGetAllHivesQuery } from "@/features/bees/api/hives-api.ts"
import { NoHivesOrScalesAvailableDrawer } from "@/features/bees/components/hive-scales/assoc-hive-drawer/no-hives-or-scales-available-drawer.tsx"
import {
  HiveCardLong,
  HiveCardLongSkeleton,
} from "@/features/bees/components/hives/hive-card/hive-card-long.tsx"
import { type HiveScale } from "@/features/bees/types/hive-scales.ts"
import { type HiveResponse } from "@/features/bees/types/hives.ts"
import useModal from "@/features/modals/hooks/use-modal.ts"
import { MODALS } from "@/features/modals/types/modals.tsx"
import { cn } from "@/utils/styles.ts"
import { type Option } from "@/utils/types/misc-types.ts"

interface Props {
  scale: HiveScale
  title: string
}

export const HiveItem: React.FC<
  RenderFunctionDrawerItemComponentProps & { hive: HiveResponse | undefined }
> = ({ isSelected, onSelect, hive }) => {
  if (!hive) {
    return <HiveCardLongSkeleton />
  }

  return (
    <div onClick={onSelect}>
      <HiveCardLong
        hive={hive}
        className={cn(
          "pointer-events-none",
          isSelected ? "!border-amber-500" : "",
        )}
      />
    </div>
  )
}

export const AssocHiveDrawer: React.FC<Props> = ({ scale, title }) => {
  const { t } = useTranslation()
  const { data: hives } = useGetAllHivesQuery()
  const { openModal } = useModal()
  const [updateHiveScaleTrigger] = useUpdateHiveScaleMutation()
  const [newHive, setNewHive] = useState<string | null>(null)

  const updateHiveScale = async () => {
    if (!newHive) {
      return
    }
    const promise = updateHiveScaleTrigger({
      id: scale.id,
      hive: newHive,
      phone_number: scale.phone_number,
    }).unwrap()
    toast.promise(promise, {
      pending: t("updatingHiveScale"),
      success: t("hiveScaleUpdated"),
      error: t("hiveScaleUpdateError"),
    })
  }

  const handleUpdateHiveScale = () => {
    openModal(MODALS.BASE_MODAL, {
      title: t("assocHiveScale"),
      content: t("assocHiveScaleConfirmation", {
        hive_code: selectedHive?.code,
      }),
      onActionClick: updateHiveScale,
    })
  }

  const hivesWithoutScale = hives?.filter((hive) => !hive.scale)

  const hiveOptions: Option[] =
    hivesWithoutScale?.map(
      (hive) =>
        ({
          value: hive.id,
          label: hive.code || hive.id,
        }) as Option,
    ) ?? []

  const selectedHive = hives?.find((hive) => hive.id === newHive)

  const hivesMap = hives?.reduce(
    (acc, hive) => {
      acc[hive.id] = hive
      return acc
    },
    {} as Record<string, HiveResponse>,
  )

  if (!hivesMap || !hiveOptions.length) {
    return <NoHivesOrScalesAvailableDrawer title={t("assocHive")} />
  }

  return (
    <RenderFunctionDrawer
      renderTrigger={<WidgetButton isActive title={title} />}
      title={title}
      options={hiveOptions}
      multiple={false}
      setOpen={() => {
        handleUpdateHiveScale()
      }}
      selectedValues={selectedHive?.id ?? ""}
      onSelectionChange={setNewHive}
      ItemComponent={(props) => (
        <HiveItem {...props} hive={hivesMap[props.id]} />
      )}
    />
  )
}
