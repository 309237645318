import { apiSlice, TagType } from "@/features/api/apiSlice.ts"
import {
  type HiveScale,
  type HiveScaleInput,
  type MeasurementsParams,
  type MeasurementsResponse,
  type ScaleStatistics,
  type ScaleStatisticsParams,
} from "@/features/bees/types/hive-scales.ts"

const HIVE_SCALES_API = `/hive-scales/`

export const hiveScalesApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createHiveScale: builder.mutation<HiveScale, HiveScaleInput>({
      query: (scaleInput) => ({
        url: HIVE_SCALES_API + "add_scale/",
        method: "POST",
        body: scaleInput,
      }),
      invalidatesTags: (_result, _error, scaleInput) => [
        { type: TagType.HiveScales, id: "LIST" },
        { type: TagType.Hives, id: scaleInput.hive as string },
      ],
    }),
    updateHiveScale: builder.mutation<
      HiveScale,
      HiveScaleInput & { id: string }
    >({
      query: (scaleInput) => ({
        url: `${HIVE_SCALES_API}${scaleInput.id}/`,
        method: "PATCH",
        body: scaleInput,
      }),
      invalidatesTags: (_result, _error, scaleInput) => [
        { type: TagType.HiveScales, id: "LIST" },
        { type: TagType.HiveScales, id: scaleInput.id },
        { type: TagType.Hives },
      ],
    }),
    getHiveScaleById: builder.query<HiveScale, string>({
      query: (scaleId) => `${HIVE_SCALES_API}${scaleId}/`,
      providesTags: (_result, _error, scaleId) => [
        { type: TagType.HiveScales, id: scaleId },
      ],
    }),
    getAllHiveScales: builder.query<HiveScale[], void>({
      query: () => `${HIVE_SCALES_API}`,
      providesTags: [{ type: TagType.HiveScales, id: "LIST" }],
    }),
    deleteHiveScale: builder.mutation<void, string>({
      query: (scaleId) => ({
        url: `${HIVE_SCALES_API}${scaleId}/`,
        method: "DELETE",
      }),
      invalidatesTags: (_result, _error, scaleId) => [
        { type: TagType.HiveScales, id: "LIST" },
        { type: TagType.HiveScales, id: scaleId },
      ],
    }),
    getHiveScaleStatistics: builder.query<
      ScaleStatistics,
      { scaleId: string } & ScaleStatisticsParams
    >({
      query: ({ scaleId, metric, period, start_date, end_date }) => {
        const params = new URLSearchParams({
          metric: metric,
          ...(period && { period }),
          ...(start_date && { start_date }),
          ...(end_date && { end_date }),
        })

        return `${HIVE_SCALES_API}${scaleId}/statistics/?${params.toString()}`
      },
      providesTags: (_result, _error, { scaleId, metric, period }) => [
        {
          type: TagType.HiveScales,
          id: `${scaleId}-stats-${metric}-${period}`,
        },
      ],
    }),
    getHiveScaleMeasurements: builder.query<
      MeasurementsResponse,
      { scaleId: string } & MeasurementsParams
    >({
      query: ({ scaleId, ...params }) => {
        const queryParams = new URLSearchParams()

        if (params.start_date) queryParams.set("start_date", params.start_date)
        if (params.end_date) queryParams.set("end_date", params.end_date)
        if (params.period) queryParams.set("period", params.period)
        if (params.min_weight)
          queryParams.set("min_weight", params.min_weight.toString())
        if (params.max_weight)
          queryParams.set("max_weight", params.max_weight.toString())
        if (params.page) queryParams.set("page", params.page.toString())
        if (params.page_size)
          queryParams.set("page_size", params.page_size.toString())

        return `${HIVE_SCALES_API}${scaleId}/measurements/?${queryParams.toString()}`
      },
      serializeQueryArgs: ({ queryArgs }) => {
        const { page, ...restArgs } = queryArgs
        return JSON.stringify(restArgs)
      },
      merge: (currentCache, newItems, { arg: { page = 1 } }) => {
        if (page === 1) return newItems

        return {
          ...newItems,
          results: [...(currentCache?.results || []), ...newItems.results],
          count: newItems.count,
        }
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg?.page !== previousArg?.page
      },
      providesTags: (_result, _error, { scaleId }) => [
        { type: TagType.HiveScales, id: `${scaleId}-measurements` },
      ],
    }),
  }),
})

export const {
  useCreateHiveScaleMutation,
  useUpdateHiveScaleMutation,
  useGetHiveScaleStatisticsQuery,
  useLazyGetHiveScaleStatisticsQuery,
  useGetHiveScaleByIdQuery,
  useGetAllHiveScalesQuery,
  useDeleteHiveScaleMutation,
  useGetHiveScaleMeasurementsQuery,
  useLazyGetHiveScaleMeasurementsQuery,
} = hiveScalesApi
