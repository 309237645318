import { format } from "date-fns"
import { useEffect, useRef, useState } from "react"
import { type DateRange } from "react-day-picker"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import { BatteryIcon } from "@/assets/hive-scales/battery-icon.tsx"
import { SignalIcon } from "@/assets/hive-scales/signal-icon.tsx"
import { LoadingAnimation } from "@/components/loading-animation/loading-animation.tsx"
import PageContentWrapperLarge from "@/components/page-content-wrappers/page-content-wrapper-large.tsx"
import { SelectPill } from "@/components/select-pill/select-pill.tsx"
import { SelectionRowDrawer } from "@/components/selection-row-drawer/selection-row-drawer.tsx"
import MobileTopBar from "@/components/top-bars/mobile-top-bar/mobile-top-bar.tsx"
import {
  useGetHiveScaleByIdQuery,
  useLazyGetHiveScaleMeasurementsQuery,
} from "@/features/bees/api/hive-scales-api.ts"
import { GroupedMeasurements } from "@/features/bees/components/hive-scales/grouped-measurements/grouped-measurements.tsx"
import { MeasurementCard } from "@/features/bees/components/hive-scales/measurment-card/measurment-card.tsx"
import {
  HIVE_SCALE_PERIODS,
  hiveScaleTimePeriods,
  type TimePeriod,
} from "@/features/bees/types/hive-scales.ts"
import { DateRangePicker } from "@/features/events/common/components/date-filter-drawer/range-date-picker.tsx"
import { type Option } from "@/utils/types/misc-types.ts"

const HiveScaleMeasurementViewList = () => {
  const { t } = useTranslation()
  const { hiveScaleId, metric } = useParams<{
    hiveScaleId: string
    metric: string
  }>()
  const [page, setPage] = useState(1)
  const statsRef = useRef<HTMLDivElement>(null)
  const [showCustomPeriod, setShowCustomPeriod] = useState(false)
  const [selectedTimeFrame, setSelectedTimeFrame] = useState<
    DateRange | undefined
  >(undefined)
  const [selectedPeriod, setSelectedPeriod] = useState<Option>(
    hiveScaleTimePeriods[1],
  )

  const { data: scale } = useGetHiveScaleByIdQuery(hiveScaleId as string, {
    skip: !hiveScaleId,
  })
  const [triggerMetric, { data: measurements, isLoading, isFetching }] =
    useLazyGetHiveScaleMeasurementsQuery()

  useEffect(() => {
    if (
      hiveScaleId &&
      metric &&
      selectedPeriod.value !== HIVE_SCALE_PERIODS.CUSTOM
    ) {
      triggerMetric({
        scaleId: hiveScaleId,
        page: page,
        page_size: 100,
        period: selectedPeriod.value as TimePeriod,
      })
    }
  }, [hiveScaleId, metric, page, selectedPeriod.value, triggerMetric])

  const nextPage = () => {
    if (measurements?.next) {
      setPage((prev) => prev + 1)
    }
  }

  const handleCustomPeriodChange = (timeFrame: DateRange | undefined) => {
    setSelectedTimeFrame(timeFrame)
    if (
      timeFrame?.from &&
      timeFrame?.to &&
      selectedPeriod.value === HIVE_SCALE_PERIODS.CUSTOM &&
      hiveScaleId &&
      metric
    ) {
      triggerMetric({
        scaleId: hiveScaleId!,
        page: 1,
        page_size: 100,
        period: HIVE_SCALE_PERIODS.CUSTOM,
        start_date: format(timeFrame.from, "yyyy-MM-dd"),
        end_date: format(timeFrame.to, "yyyy-MM-dd"),
      })
      statsRef.current?.scrollIntoView({ behavior: "smooth", block: "start" })
    }
  }

  const handlePeriodChange = (period: string) => {
    if (period === HIVE_SCALE_PERIODS.CUSTOM) {
      setShowCustomPeriod(true)
    } else {
      setShowCustomPeriod(false)
    }
    setSelectedPeriod(hiveScaleTimePeriods.find((p) => p.value === period)!)
  }

  if (!scale || !measurements || !metric || isLoading) {
    return (
      <>
        <MobileTopBar title={t("measurements")} />
        <PageContentWrapperLarge className={"flex flex-col"}>
          <LoadingAnimation customStyle={{ height: "100%" }} />
        </PageContentWrapperLarge>
      </>
    )
  }

  return (
    <>
      <MobileTopBar title={t("measurements")} />
      <PageContentWrapperLarge className={"flex flex-col"}>
        <div
          className={"flex w-full flex-col items-center justify-center gap-4"}
        >
          <SelectionRowDrawer
            title={t("period")}
            multiple={false}
            renderTrigger={
              <SelectPill
                isActive={selectedPeriod.label !== HIVE_SCALE_PERIODS.WEEK}
              >
                {t(selectedPeriod.label)}
              </SelectPill>
            }
            options={hiveScaleTimePeriods}
            selectedValues={selectedPeriod.label}
            onSelectionChange={handlePeriodChange}
          />
          {showCustomPeriod && (
            <div className={"widget-border w-full bg-white p-3"}>
              <DateRangePicker
                selectedTimeFrame={selectedTimeFrame}
                onSelectedTimeFrameChange={handleCustomPeriodChange}
              />
            </div>
          )}
        </div>
        <div ref={statsRef} className={"widget-border bg-white p-3"}>
          <div className={"my-2 text-[14px] text-neutral-400"}>
            {t("hiveScale")}
          </div>
          <div className={"flex flex-col items-center justify-between gap-2"}>
            <div className={"flex w-full justify-between"}>
              <span className={"text-body-sm font-semibold"}>
                {t("phoneNumber")}
              </span>
              <span className={"text-body-sm font-semibold text-neutral-700"}>
                {scale.phone_number.substring(2)}
              </span>
            </div>
            {scale.last_measurement && (
              <div className={"grid w-full grid-cols-2 gap-2"}>
                <MeasurementCard
                  value={`${scale.last_measurement.signal_quality}`}
                  label={t("signal")}
                >
                  <SignalIcon className={"h-[25px]"} />
                </MeasurementCard>
                <MeasurementCard
                  value={`${scale.last_measurement.battery} %`}
                  label={t("battery")}
                >
                  <BatteryIcon className={"h-[25px]"} />
                </MeasurementCard>
              </div>
            )}
          </div>
        </div>
        <GroupedMeasurements
          measurements={measurements}
          metric={metric}
          onButtonClick={nextPage}
          title={"loadMore"}
          isFetching={isFetching}
        />
      </PageContentWrapperLarge>
    </>
  )
}

export default HiveScaleMeasurementViewList
