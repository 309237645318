import isEqual from "lodash.isequal"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"
import { toast } from "react-toastify"
import { LoadingAnimation } from "@/components/loading-animation/loading-animation.tsx"
import PageContentWrapperLarge from "@/components/page-content-wrappers/page-content-wrapper-large.tsx"
import { Button } from "@/components/shadcn/button.tsx"
import EditorTopBar from "@/components/top-bars/editor-top-bar/editor-top-bar.tsx"
import {
  useDeleteCropPlantingMutation,
  useGetCropPlantingQuery,
  useUpdateCropPlantingMutation,
} from "@/features/crops/api/crops-planting-api.ts"
import { CropPlantingEditor } from "@/features/crops/components/crop-planting-editor/crop-planting-editor.tsx"
import { type CropPlantingCreateUpdateRequest } from "@/features/crops/types/crop-planting.ts"
import useModal from "@/features/modals/hooks/use-modal.ts"
import { MODALS } from "@/features/modals/types/modals.tsx"
import { ALL_CROPS_PLANTINGS_PATH } from "@/utils/constants/routes.ts"

const EditCropPlantingView = () => {
  const { t } = useTranslation()
  const params = useParams()
  const { openModal } = useModal()
  const cropPlantingId = params.cropPlantingId
  const { data: cropPlanting, isLoading } = useGetCropPlantingQuery(
    cropPlantingId as string,
    {
      skip: !cropPlantingId,
    },
  )
  const [workingCropPlanting, setWorkingCropPlanting] =
    useState<CropPlantingCreateUpdateRequest>(
      cropPlanting as CropPlantingCreateUpdateRequest,
    )
  const isModified = !isEqual(workingCropPlanting, cropPlanting)
  const [updateCropPlanting] = useUpdateCropPlantingMutation()
  const navigate = useNavigate()
  const [deleteCropPlantingMutation] = useDeleteCropPlantingMutation()

  const onBackClick = () => {
    if (isModified) {
      openModal(MODALS.BASE_MODAL, {
        title: t("unsavedChanges"),
        content: t("youWillLoseChanges"),
        onActionClick: () => {
          navigate(-1)
        },
      })
    } else {
      navigate(-1)
    }
  }

  const handleUpdate = async () => {
    if (!cropPlanting) return
    const promise = updateCropPlanting({
      ...(workingCropPlanting as CropPlantingCreateUpdateRequest),
      id: cropPlanting.id,
    }).unwrap()
    await toast
      .promise(promise, {
        pending: t("savingCropPlanting"),
        success: t("cropPlantingSaved"),
        error: t("cropPlantingError"),
      })
      .then(() => {
        navigate(ALL_CROPS_PLANTINGS_PATH, { replace: true })
      })
  }

  const deleteCropPlanting = async () => {
    if (!cropPlanting) return
    const promise = deleteCropPlantingMutation(cropPlanting.id).unwrap()
    await toast
      .promise(promise, {
        pending: t("deletingCropPlanting"),
        success: t("cropPlantingDeleted"),
        error: t("cropPlantingError"),
      })
      .then(() => {
        navigate(ALL_CROPS_PLANTINGS_PATH, { replace: true })
      })
  }

  const handleDelete = () => {
    openModal(MODALS.BASE_MODAL, {
      title: t("deleteCropPlanting"),
      content: t("deleteCropPlantingConfirmation"),
      onActionClick: deleteCropPlanting,
    })
  }

  return (
    <>
      <EditorTopBar
        text={t("editCropPlanting")}
        onBackClick={onBackClick}
        isModified={isModified}
        onSave={handleUpdate}
      />
      <PageContentWrapperLarge className={"flex flex-col gap-3"}>
        {isLoading && cropPlanting && (
          <LoadingAnimation customStyle={{ height: "inherit" }} />
        )}
        {!isLoading && (
          <>
            <CropPlantingEditor
              cropPlanting={workingCropPlanting}
              onCropPlantingChange={setWorkingCropPlanting}
            />
            <Button
              size={"lg"}
              variant={"outline"}
              className={"w-full flex-shrink-0 border-neutral-200 text-red-500"}
              onClick={handleDelete}
            >
              <p>{t("deleteCropPlanting")}</p>
            </Button>
          </>
        )}
      </PageContentWrapperLarge>
    </>
  )
}

export default EditCropPlantingView
