import { addDays, format } from "date-fns"
import React from "react"
import { useTranslation } from "react-i18next"
import WeightScaleIcon from "@/assets/icons/misc/weight-scale-icon.tsx"
import CropIcon from "@/assets/svg/crop-planting-icon.svg?react"
import ParcelIcon from "@/assets/svg/parcel-icon.svg?react"
import GenericDatePicker from "@/components/generic-date-picker/generic-date-picker.tsx"
import RenderFunctionDrawer from "@/components/render-function-drawer/render-function-drawer.tsx"
import { SelectionRowDrawer } from "@/components/selection-row-drawer/selection-row-drawer.tsx"
import { DrawerClose } from "@/components/shadcn/drawer.tsx"
import OverflowMenuItem from "@/components/task-card/overflow-menu-item.tsx"
import InputFieldWrapperWithIcon from "@/components/text-card-wrapper-with-icon/input-field-wrapper-with-icon.tsx"
import { useGetCropsQuery } from "@/features/crops/api/crops-api.ts"
import {
  type Crop,
  type CropPlantingCreateUpdateRequest,
} from "@/features/crops/types/crop-planting.ts"
import { useGetParcelsQuery } from "@/features/parcels/api/parcels-api.ts"
import { ParcelCardWrapper } from "@/features/parcels/components/parcel-card/parcel-card.tsx"
import { type Parcel } from "@/features/parcels/types/parcel.ts"

interface Props {
  cropPlanting: CropPlantingCreateUpdateRequest
  onCropPlantingChange: (cropPlanting: CropPlantingCreateUpdateRequest) => void
  isEdit?: boolean
}

export const CropPlantingEditor: React.FC<Props> = ({
  cropPlanting,
  onCropPlantingChange,
  isEdit = false,
}) => {
  const { t } = useTranslation()
  const { data: parcels = [] } = useGetParcelsQuery()
  const { data: crops = [] } = useGetCropsQuery()

  const parcelOptions =
    (parcels &&
      parcels.map((parcel) => ({
        value: parcel.id,
        label: parcel.name,
      }))) ||
    []
  const parcelsMap = parcels.reduce(
    (acc, parcel) => {
      acc[parcel.id] = parcel
      return acc
    },
    {} as Record<string, Parcel>,
  )

  const cropOptions =
    (crops &&
      crops.map((crop) => ({
        value: crop.id,
        label: crop.name,
      }))) ||
    []

  const cropsMap = crops.reduce(
    (acc, crop) => {
      acc[crop.id] = crop
      return acc
    },
    {} as Record<string, Crop>,
  )

  const handleSeedingDateChange = (date: string) => {
    const plannedHarvestDate = addDays(
      new Date(date),
      cropsMap[cropPlanting.crop].average_growing_days,
    )
    onCropPlantingChange({
      ...cropPlanting,
      seeding_date: date,
      planned_harvest_date: format(plannedHarvestDate, "yyyy-MM-dd"),
    })
  }

  const handleHarvestDateChange = (date: string) => {
    onCropPlantingChange({
      ...cropPlanting,
      planned_harvest_date: date,
    })
  }

  const handleParcelSelection = (parcelId: string) => {
    onCropPlantingChange({
      ...cropPlanting,
      parcel: parcelId,
    })
  }

  const handleCropSelection = (cropId: string) => {
    const plannedHarvestDate = addDays(
      cropPlanting.seeding_date
        ? new Date(cropPlanting.seeding_date)
        : new Date(),
      cropsMap[cropId].average_growing_days,
    )
    onCropPlantingChange({
      ...cropPlanting,
      crop: cropId,
      planned_harvest_date: format(plannedHarvestDate, "yyyy-MM-dd"),
    })
  }

  const setExpectedYeld = (event: React.ChangeEvent<HTMLInputElement>) => {
    onCropPlantingChange({
      ...cropPlanting,
      expected_yield: event.target.value,
    })
  }

  return (
    <div className={"flex flex-col gap-[10px]"}>
      <RenderFunctionDrawer
        multiple={false}
        renderTrigger={
          <OverflowMenuItem className={"p-3 px-4"} isDisabled={isEdit}>
            <div className={"flex items-center gap-2"}>
              <ParcelIcon
                className={
                  "h-[30px] w-[30px] rounded-[6px] bg-brown-500 p-1 text-white"
                }
              />
              <p>
                {cropPlanting.parcel && parcelsMap[cropPlanting.parcel]
                  ? parcelsMap[cropPlanting.parcel].name
                  : t("selectParcel")}
              </p>
            </div>
          </OverflowMenuItem>
        }
        title={t("selectParcel")}
        options={parcelOptions}
        selectedValues={cropPlanting.parcel}
        onSelectionChange={handleParcelSelection}
        ItemComponent={(props) => (
          <DrawerClose>
            <ParcelCardWrapper {...props} parcel={parcelsMap[props.id]} />
          </DrawerClose>
        )}
      />
      <SelectionRowDrawer
        multiple={false}
        title={t("selectCrop")}
        renderTrigger={
          <OverflowMenuItem className={"p-3 px-4"}>
            <div className={"flex items-center gap-2"}>
              <CropIcon
                className={
                  "h-[30px] w-[30px] rounded-[6px] bg-green-600 p-1 text-white"
                }
              />
              <p>
                {cropPlanting.crop && cropsMap[cropPlanting.crop]
                  ? t(cropsMap[cropPlanting.crop].name)
                  : t("selectCrop")}
              </p>
            </div>
          </OverflowMenuItem>
        }
        options={cropOptions}
        selectedValues={cropPlanting.crop}
        onSelectionChange={handleCropSelection}
      />
      <GenericDatePicker
        placeholder={t("seedingDate")}
        value={cropPlanting.seeding_date}
        onChange={handleSeedingDateChange}
      />
      <GenericDatePicker
        placeholder={t("plannedHarvestDate")}
        value={cropPlanting.planned_harvest_date}
        onChange={handleHarvestDateChange}
      />
      <div className={"grid grid-cols-[auto_auto] gap-2"}>
        <InputFieldWrapperWithIcon
          icon={<WeightScaleIcon className={"h-[30px] text-white"} />}
          align={"row"}
          name={t("expectedYield")}
          maxLength={14}
          inputMode={"numeric"}
          isDisabled={false}
          value={cropPlanting.expected_yield}
          isRequired={!cropPlanting.expected_yield}
          onChange={setExpectedYeld}
        />
        <div
          className={
            "widget-border grid place-content-center bg-white text-[14px] text-neutral-700"
          }
        >
          kg/ha
        </div>
      </div>
    </div>
  )
}
