import type GeneralSvgProps from "@/utils/types/sgv/general-svg-props.ts"

export const PressureIcon: React.FC<GeneralSvgProps> = (props) => {
  return (
    <svg
      {...props}
      width="16"
      height="22"
      viewBox="0 0 16 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.3064 1H1M14.3064 21H1M7.65321 4V9M7.65321 9L10.9798 6M7.65321 9L4.3266 6M7.65321 18V13M7.65321 13L10.9798 16M7.65321 13L4.3266 16"
        stroke="#601774"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  )
}
