import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { AddEventIcon } from "@/assets/widgets/add-event-icon.tsx"
import { BirdsGroupsEventCard } from "@/features/events/birds-groups-events/components/birds-group-event-card/birds-group-event-card.tsx"
import { useBirdsGroupsEventsFilter } from "@/features/events/birds-groups-events/hooks/use-birds-groups-events-filter.ts"
import EmptyContentCreateItemButton from "@/features/home/components/empty-content-create-item-button/empty-content-create-item-button.tsx"
import WidgetHeader from "@/features/home/components/widget-header/widget-header.tsx"
import {
  BIRDS_GROUPS_EVENTS_SCREEN_PATH,
  NEW_BIRDS_GROUPS_EVENTS_VIEW,
} from "@/utils/constants/routes.ts"

export const BirdsGroupsSummaryWidget = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { events } = useBirdsGroupsEventsFilter()

  const addItemsGoToAction =
    events && events?.length > 0
      ? () => navigate(NEW_BIRDS_GROUPS_EVENTS_VIEW)
      : undefined

  return (
    <div
      className={
        "widget-border box-border flex w-full flex-col gap-5 bg-white px-4 py-4"
      }
    >
      <WidgetHeader
        title={t("birdsGroupsEvents")}
        navigateTo={BIRDS_GROUPS_EVENTS_SCREEN_PATH}
        icon={<AddEventIcon />}
        addItemGoTo={addItemsGoToAction}
      />
      {events && events.length > 0 ? (
        <div className={"flex flex-col gap-2"}>
          {events.slice(0, 3).map((event, index) => (
            <BirdsGroupsEventCard event={event} key={index} />
          ))}
        </div>
      ) : (
        <EmptyContentCreateItemButton
          navigateTo={NEW_BIRDS_GROUPS_EVENTS_VIEW}
        />
      )}
    </div>
  )
}
