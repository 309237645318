interface MultiStepProgressProps {
  labels: string[]
  currentStep: number
  onStepChange?: (step: number) => void
}

const MultiStepProgress: React.FC<MultiStepProgressProps> = ({
  labels,
  currentStep,
  onStepChange,
}) => {
  return (
    <div className="relative mx-auto grid h-[120px] w-full max-w-3xl place-content-center p-4">
      <div className="relative flex items-center justify-center gap-10">
        {labels.map((_label, index) => {
          const stepNumber = index + 1

          return (
            <div key={index} className="flex flex-col items-center">
              <button
                onClick={() => onStepChange && onStepChange(stepNumber)}
                className={`relative z-10 flex h-10 w-10 items-center justify-center rounded-full border-2 text-sm font-bold outline outline-offset-2 ${
                  currentStep === stepNumber
                    ? "border-brown-500 bg-brown-500 text-white outline-2 outline-brown-500"
                    : currentStep > stepNumber
                      ? "border-green-600 bg-white text-green-600 outline-transparent"
                      : "border-gray-300 bg-white text-gray-500 outline-transparent"
                }`}
              >
                {currentStep > stepNumber ? (
                  <svg
                    className="h-5 w-5"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M5 13l4 4L19 7"
                    ></path>
                  </svg>
                ) : (
                  stepNumber
                )}
              </button>
            </div>
          )
        })}
      </div>

      <div className="mt-6 text-center">
        <div className="text-sm font-semibold text-brown-500">
          {labels[currentStep - 1]}
        </div>
      </div>
    </div>
  )
}

export default MultiStepProgress
