interface MeasurementCardProps {
  children: React.ReactNode
  value: string
  label: string
  onClick?: () => void
}

export const MeasurementCard: React.FC<MeasurementCardProps> = ({
  children,
  value,
  label,
  onClick,
}) => {
  return (
    <div
      onClick={onClick}
      className={
        "flex flex-col items-start gap-1 rounded-sm border border-neutral-300 p-2"
      }
    >
      <p>{label}</p>
      <div className={"flex items-center gap-2"}>
        <div className={"flex h-[25px] w-[25px] items-center justify-center"}>
          {children}
        </div>
        <p className={"font-semibold"}>{value || 0}</p>
      </div>
    </div>
  )
}
