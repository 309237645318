import type GeneralSvgProps from "@/utils/types/sgv/general-svg-props.ts"

export const ExtraWightIcon: React.FC<GeneralSvgProps> = (props) => {
  return (
    <svg
      {...props}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21 6L14 13L11 10L3 18"
        stroke="#B5642D"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M21 10V6H17"
        stroke="#B5642D"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  )
}
