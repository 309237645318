import type GeneralSvgProps from "@/utils/types/sgv/general-svg-props.ts"

export const SignalIcon: React.FC<GeneralSvgProps> = (props) => {
  return (
    <svg
      {...props}
      width="18"
      height="22"
      viewBox="0 0 18 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.125 11C10.125 11.2984 10.0065 11.5845 9.79549 11.7955C9.58452 12.0065 9.29837 12.125 9 12.125C8.70163 12.125 8.41548 12.0065 8.20451 11.7955C7.99353 11.5845 7.875 11.2984 7.875 11C7.875 10.7016 7.99353 10.4155 8.20451 10.2045C8.41548 9.99353 8.70163 9.875 9 9.875C9.29837 9.875 9.58452 9.99353 9.79549 10.2045C10.0065 10.4155 10.125 10.7016 10.125 11Z"
        fill="#6E0A5A"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.8899 7.9175C11.0481 7.7595 11.2626 7.67075 11.4862 7.67075C11.7098 7.67075 11.9242 7.7595 12.0824 7.9175C12.8999 8.73504 13.3592 9.84385 13.3592 11C13.3592 12.1562 12.8999 13.265 12.0824 14.0825C12.0052 14.1654 11.912 14.2319 11.8085 14.278C11.705 14.3241 11.5933 14.3489 11.48 14.3509C11.3667 14.3529 11.2542 14.3321 11.1491 14.2896C11.0441 14.2472 10.9486 14.184 10.8685 14.1039C10.7884 14.0238 10.7252 13.9284 10.6828 13.8233C10.6403 13.7182 10.6195 13.6057 10.6215 13.4924C10.6235 13.3791 10.6483 13.2674 10.6944 13.1639C10.7405 13.0604 10.807 12.9672 10.8899 12.89C11.1382 12.6419 11.3352 12.3472 11.4696 12.0229C11.604 11.6986 11.6732 11.351 11.6732 11C11.6732 10.649 11.604 10.3014 11.4696 9.97706C11.3352 9.65277 11.1382 9.35814 10.8899 9.11C10.7319 8.9518 10.6432 8.73735 10.6432 8.51375C10.6432 8.29016 10.7319 8.07571 10.8899 7.9175ZM7.10991 7.9175C7.26792 8.07571 7.35667 8.29016 7.35667 8.51375C7.35667 8.73735 7.26792 8.9518 7.10991 9.11C6.8616 9.35814 6.66461 9.65277 6.53021 9.97706C6.39581 10.3014 6.32664 10.649 6.32664 11C6.32664 11.351 6.39581 11.6986 6.53021 12.0229C6.66461 12.3472 6.8616 12.6419 7.10991 12.89C7.19281 12.9672 7.2593 13.0604 7.30542 13.1639C7.35153 13.2674 7.37633 13.3791 7.37833 13.4924C7.38033 13.6057 7.35949 13.7182 7.31705 13.8233C7.27461 13.9284 7.21145 14.0238 7.13133 14.1039C7.05121 14.184 6.95577 14.2472 6.85071 14.2896C6.74565 14.3321 6.63312 14.3529 6.51982 14.3509C6.40653 14.3489 6.29481 14.3241 6.19131 14.278C6.08781 14.2319 5.99466 14.1654 5.91741 14.0825C5.0999 13.265 4.64062 12.1562 4.64062 11C4.64062 9.84385 5.0999 8.73504 5.91741 7.9175C6.07562 7.7595 6.29007 7.67075 6.51366 7.67075C6.73726 7.67075 6.95171 7.7595 7.10991 7.9175Z"
        fill="#6E0A5A"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.3763 5.43125C13.5345 5.27324 13.749 5.18449 13.9726 5.18449C14.1962 5.18449 14.4106 5.27324 14.5688 5.43125C15.3002 6.16252 15.8803 7.03069 16.2761 7.98618C16.6719 8.94168 16.8756 9.96577 16.8756 11C16.8756 12.0342 16.6719 13.0583 16.2761 14.0138C15.8803 14.9693 15.3002 15.8375 14.5688 16.5688C14.4916 16.6516 14.3984 16.7181 14.2949 16.7643C14.1914 16.8104 14.0797 16.8352 13.9664 16.8372C13.8531 16.8392 13.7406 16.8183 13.6355 16.7759C13.5305 16.7335 13.435 16.6703 13.3549 16.5902C13.2748 16.51 13.2116 16.4146 13.1692 16.3095C13.1268 16.2045 13.1059 16.092 13.1079 15.9787C13.1099 15.8654 13.1347 15.7536 13.1808 15.6501C13.2269 15.5466 13.2934 15.4535 13.3763 15.3763C13.9512 14.8017 14.4072 14.1194 14.7184 13.3685C15.0295 12.6176 15.1896 11.8128 15.1896 11C15.1896 10.1872 15.0295 9.38237 14.7184 8.63148C14.4072 7.88059 13.9512 7.19835 13.3763 6.62375C13.2183 6.46555 13.1296 6.2511 13.1296 6.0275C13.1296 5.80391 13.2183 5.58946 13.3763 5.43125ZM4.62383 5.43125C4.78184 5.58946 4.87059 5.80391 4.87059 6.0275C4.87059 6.2511 4.78184 6.46555 4.62383 6.62375C4.04896 7.19835 3.59293 7.88059 3.2818 8.63148C2.97067 9.38237 2.81053 10.1872 2.81053 11C2.81053 11.8128 2.97067 12.6176 3.2818 13.3685C3.59293 14.1194 4.04896 14.8017 4.62383 15.3763C4.70673 15.4535 4.77322 15.5466 4.81933 15.6501C4.86545 15.7536 4.89025 15.8654 4.89225 15.9787C4.89424 16.092 4.8734 16.2045 4.83097 16.3095C4.78853 16.4146 4.72537 16.51 4.64525 16.5902C4.56513 16.6703 4.46969 16.7335 4.36463 16.7759C4.25957 16.8183 4.14703 16.8392 4.03374 16.8372C3.92045 16.8352 3.80872 16.8104 3.70522 16.7643C3.60173 16.7181 3.50857 16.6516 3.43133 16.5688C2.69998 15.8375 2.11984 14.9693 1.72404 14.0138C1.32823 13.0583 1.12451 12.0342 1.12451 11C1.12451 9.96577 1.32823 8.94168 1.72404 7.98618C2.11984 7.03069 2.69998 6.16252 3.43133 5.43125C3.58953 5.27324 3.80399 5.18449 4.02758 5.18449C4.25117 5.18449 4.46563 5.27324 4.62383 5.43125Z"
        fill="#6E0A5A"
      />
    </svg>
  )
}
