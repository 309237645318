import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import styles from "./event-type-drawer.module.css"
import BackIcon from "@/assets/icons/misc/back-icon.tsx"
import PlaceholderIcon from "@/assets/icons/misc/placeholder-icon.tsx"
import DrawerContentWrapper from "@/components/drawer-content-wrapper/drawer-content-wrapper.tsx"
import GenericTypeSelectTrigger from "@/components/drawer-triggers/generic-type-select-trigger.tsx"
import GenericPicture from "@/components/generic-picture/generic-picture.tsx"
import GenericTypeDrawerHeader from "@/components/generic-type-drawer/generic-type-drawer-header/generic-type-drawer-header.tsx"
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerTrigger,
} from "@/components/shadcn/drawer.tsx"
import { type CommonEventType } from "@/features/events/common/types/common-event-type.ts"
import { cn } from "@/utils/styles.ts"

const EventTypeItem: React.FC<{
  isSelected?: boolean
  eventType: CommonEventType
  onClick: () => void
  isParent?: boolean
}> = ({ eventType, onClick, isParent, isSelected }) => {
  const { t } = useTranslation()
  const Wrapper = isParent ? "div" : DrawerClose
  const getClassName = () => {
    return cn(
      "mb-3 flex w-full cursor-pointer items-center justify-between rounded-[var(--fp-border-radius)] border border-neutral-200 bg-white p-3.5 text-neutral-700",
      isSelected && !isParent && "border-brown-500 text-brown-500",
    )
  }

  return (
    <Wrapper className={getClassName()} onClick={onClick}>
      <div className="flex items-center gap-3">
        <div>
          {eventType?.picture ? (
            <GenericPicture
              src={eventType.picture}
              alt=""
              size={30}
              borderRadius={8}
            />
          ) : (
            <PlaceholderIcon />
          )}
        </div>
        <p className="font-medium">{t(eventType.name)}</p>
        {isSelected && isParent && (
          <div className="h-4 w-4 rounded-full bg-[rgba(229,101,15,0.4)]" />
        )}
      </div>
      {isParent && <BackIcon className="rotate-[180deg]" />}
    </Wrapper>
  )
}

interface Props {
  selectedValue?: string
  eventTypes: CommonEventType[]
  onEventTypeChange: (eventType: string) => void
}

export const EventTypeDrawer: React.FC<Props> = ({
  selectedValue,
  eventTypes,
  onEventTypeChange,
}) => {
  const { t } = useTranslation()
  const selectedEventType = eventTypes.find(
    (eventType) => eventType.id === selectedValue,
  )

  const parentEventTypes = eventTypes.filter((eventType) => !eventType.parent)

  // State to track if we're showing parents or children
  const [selectedParentId, setSelectedParentId] = useState<string | null>(null)

  // Animation state
  const [parentListClass, setParentListClass] = useState(styles.drawerPage)
  const [childListClass, setChildListClass] = useState(
    `${styles.drawerPage} ${styles.drawerPageRight}`,
  )

  // Get child event types for the selected parent
  const childEventTypes = eventTypes.filter(
    (eventType) => eventType.parent === selectedParentId,
  )

  const selectedParent = eventTypes.find(
    (eventType) => eventType.id === selectedParentId,
  )

  const handleParentClick = (eventType: CommonEventType) => {
    setParentListClass(`${styles.drawerPage} ${styles.drawerPageAnimateLeft}`)
    setChildListClass(
      `${styles.drawerPage} ${styles.drawerPageRight} ${styles.drawerPageAnimateCenter}`,
    )
    setSelectedParentId(eventType.id)
  }

  const handleBackClick = () => {
    setParentListClass(
      `${styles.drawerPage} ${styles.drawerPageLeft} ${styles.drawerPageAnimateCenter}`,
    )
    setChildListClass(`${styles.drawerPage} ${styles.drawerPageAnimateRight}`)
    setTimeout(() => {
      setSelectedParentId(null)
    }, 300)
  }

  const handleSelectEventType = (eventType: CommonEventType) => {
    onEventTypeChange(eventType.id)
  }

  // Reset classes when parent ID changes
  useEffect(() => {
    if (selectedParentId === null) {
      setParentListClass(styles.drawerPage)
      setChildListClass(`${styles.drawerPage} ${styles.drawerPageRight}`)
    } else {
      setParentListClass(`${styles.drawerPage} ${styles.drawerPageLeft}`)
      setChildListClass(styles.drawerPage)
    }
  }, [selectedParentId])

  return (
    <Drawer>
      <DrawerTrigger asChild>
        <GenericTypeSelectTrigger
          currentValue={selectedEventType}
          fallbackText={t("selectEventType")}
          isRequired
        />
      </DrawerTrigger>
      <DrawerContent className="h-4/5">
        <GenericTypeDrawerHeader
          title={t("selectEventType")}
          onSaveClick={() => {}}
        />
        <DrawerContentWrapper className="flex flex-col gap-3 overflow-y-auto overflow-x-hidden bg-neutral-50">
          <div className={styles.drawerContainer}>
            {/* Parent event types */}
            <div className={cn(parentListClass, "p-3")}>
              {parentEventTypes.map((eventType) => (
                <EventTypeItem
                  isSelected={selectedEventType?.parent == eventType.id}
                  key={eventType.id}
                  eventType={eventType}
                  onClick={() => handleParentClick(eventType)}
                  isParent
                />
              ))}
            </div>

            {/* Child event types */}
            <div className={cn(childListClass, "p-3")}>
              <div
                className="widget-border mb-3 flex h-[60px] cursor-pointer items-center justify-between bg-white p-3.5"
                onClick={handleBackClick}
              >
                <div className="flex items-center gap-3">
                  <BackIcon className="rotate-[0deg]" />
                  <p className="font-medium text-neutral-700">
                    {selectedParent ? t(selectedParent.name) : t("back")}
                  </p>
                </div>
              </div>

              {childEventTypes.map((eventType) => (
                <EventTypeItem
                  isSelected={selectedValue == eventType.id}
                  key={eventType.id}
                  eventType={eventType}
                  onClick={() => handleSelectEventType(eventType)}
                />
              ))}
            </div>
          </div>
        </DrawerContentWrapper>
      </DrawerContent>
    </Drawer>
  )
}
