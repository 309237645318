import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { MeasurementCard } from "../measurment-card/measurment-card"
import { BatteryIcon } from "@/assets/hive-scales/battery-icon.tsx"
import { ExtraWightIcon } from "@/assets/hive-scales/extra-wight-icon.tsx"
import { HumidityIcon } from "@/assets/hive-scales/humidity-icon.tsx"
import { PressureIcon } from "@/assets/hive-scales/pressure-icon.tsx"
import { SignalIcon } from "@/assets/hive-scales/signal-icon.tsx"
import { TemperatureIcon } from "@/assets/hive-scales/temperature-icon.tsx"
import { TwentyFourHours } from "@/assets/hive-scales/twenty-four-hours.tsx"
import { WeightIcon } from "@/assets/hive-scales/weight-icon.tsx"
import {
  type HiveScale,
  type ScaleMeasurement,
} from "@/features/bees/types/hive-scales.ts"
import { HIVE_MEASUREMENT_VIEW_DETAIL } from "@/utils/constants/routes.ts"

interface Props {
  hiveScale: HiveScale
}

export const MeasurementsCard: React.FC<Props> = ({ hiveScale }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const lastMeasurement = hiveScale.last_measurement as ScaleMeasurement

  const navigateToMeasurements = (measurement: string) => {
    navigate(
      HIVE_MEASUREMENT_VIEW_DETAIL.replace(
        ":hiveScaleId",
        hiveScale.id,
      ).replace(":metric", measurement),
    )
  }

  return (
    <div className={"grid grid-cols-2 gap-1"}>
      <MeasurementCard
        value={`${lastMeasurement.battery} %`}
        label={t("battery")}
      >
        <BatteryIcon className={"h-[25px]"} />
      </MeasurementCard>
      <MeasurementCard
        value={`${lastMeasurement.humidity} %`}
        label={t("humidity")}
        onClick={() => navigateToMeasurements("humidity")}
      >
        <HumidityIcon className={"h-[25px]"} />
      </MeasurementCard>
      <MeasurementCard
        value={`${lastMeasurement.pressure.toFixed(2)} hPa`}
        label={t("pressure")}
        onClick={() => navigateToMeasurements("pressure")}
      >
        <PressureIcon className={"h-[25px]"} />
      </MeasurementCard>
      <MeasurementCard
        value={`${lastMeasurement.temperature.toFixed(2)} C`}
        label={t("temperature")}
        onClick={() => navigateToMeasurements("temperature")}
      >
        <TemperatureIcon className={"h-[25px]"} />
      </MeasurementCard>
      <MeasurementCard
        value={`${lastMeasurement.weight.toFixed(2)} kg`}
        label={t("weight")}
        onClick={() => navigateToMeasurements("weight")}
      >
        <WeightIcon className={"h-[25px]"} />
      </MeasurementCard>
      <MeasurementCard
        value={`${lastMeasurement.extra_weight.toFixed(2)} kg`}
        label={t("extraWeight")}
      >
        <ExtraWightIcon className={"h-[25px]"} />
      </MeasurementCard>
      <MeasurementCard
        value={`${lastMeasurement.signal_quality}`}
        label={t("signal")}
      >
        <SignalIcon className={"h-[25px]"} />
      </MeasurementCard>
      <MeasurementCard
        value={`${lastMeasurement.weight_change_24h?.toFixed(2) || "0.00"} kg`}
        label={t("oneDayImprovement")}
      >
        <TwentyFourHours className={"h-[25px]"} />
      </MeasurementCard>
    </div>
  )
}
