import { apiSlice, TagType } from "@/features/api/apiSlice.ts"
import {
  type AuthRequest,
  type AuthResponse,
  type FarmProfileUpdateRequest,
  type UserProfileUpdateRequest,
  type SignupRequest,
  type SignupResponse,
  type PasswordResetRequest,
  type PasswordResetResponse,
  type PasswordResetConfirmRequest,
  type PasswordResetConfirmResponse,
  type UserCreationCompleteRequest,
  type UserDeletionRequest,
  type ChangePasswordRequest,
  type ChangePasswordResponse,
} from "@/features/settings-account/api/types.ts"
import {
  type FarmProfile,
  type UserProfile,
} from "@/features/settings-account/utils/types.ts"
import { setToken } from "@/redux/slices/auth-slice.ts"

const ALLAUTH_BASE_URL = `_allauth/app/v1`

export const accountApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation<AuthResponse, AuthRequest>({
      query: (credentials) => ({
        url: "auth-token/",
        method: "POST",
        body: credentials,
      }),
    }),
    signup: builder.mutation<SignupResponse, SignupRequest>({
      query: (userData) => ({
        url: `${ALLAUTH_BASE_URL}/auth/signup`,
        method: "POST",
        body: userData,
      }),
    }),
    completeSignup: builder.mutation<UserProfile, UserCreationCompleteRequest>({
      query: (userData) => ({
        url: `users/complete_signup/`,
        method: "PATCH",
        body: userData,
      }),
    }),
    verifyEmail: builder.mutation<any, { key: string }>({
      query: (verificationData) => ({
        url: `${ALLAUTH_BASE_URL}/auth/email/verify`,
        method: "POST",
        body: verificationData,
      }),
    }),
    passwordReset: builder.mutation<
      PasswordResetResponse,
      PasswordResetRequest
    >({
      query: (emailData) => ({
        url: `${ALLAUTH_BASE_URL}/auth/password/request`,
        method: "POST",
        body: emailData,
      }),
    }),
    passwordResetConfirm: builder.mutation<
      PasswordResetConfirmResponse,
      PasswordResetConfirmRequest
    >({
      query: (resetData) => ({
        url: `${ALLAUTH_BASE_URL}/auth/password/reset`,
        method: "POST",
        body: {
          key: resetData.key,
          password: resetData.password1,
        },
      }),
    }),
    changePassword: builder.mutation<
      ChangePasswordResponse,
      ChangePasswordRequest
    >({
      query: (passwordData) => ({
        url: "change-password/",
        method: "POST",
        body: passwordData,
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // Update token in Redux state if a new token is returned
          if ("token" in data && typeof data.token === "string") {
            dispatch(setToken(data.token))
          }
        } catch {
          // Query will handle its own errors through the error handling middleware
        }
      },
    }),
    getProfile: builder.query<UserProfile, void>({
      query: () => "users/me/",
      providesTags: [TagType.UserProfile, TagType.FarmProfile],
    }),
    updateProfile: builder.mutation<
      UserProfile,
      Partial<UserProfileUpdateRequest>
    >({
      query: ({ id, userProfileUpdate }) => {
        return {
          url: "users/" + `${id}/`,
          method: "PATCH",
          body: userProfileUpdate,
        }
      },
      invalidatesTags: [TagType.UserProfile],
    }),
    deleteProfile: builder.mutation<void, UserDeletionRequest>({
      query: ({ id, password }) => {
        return {
          url: `users/${id}/`,
          method: "DELETE",
          body: { password },
        }
      },
      invalidatesTags: [TagType.UserProfile],
    }),
    getMyFarm: builder.query<FarmProfile, void>({
      query: () => "farms/my/",
      providesTags: [TagType.FarmProfile],
    }),
    getUsersForFarm: builder.query<Array<UserProfile>, void>({
      query: () => "farms/users/",
    }),
    updateMyFarmProfile: builder.mutation<
      FarmProfile,
      FarmProfileUpdateRequest
    >({
      query: ({ id, farmProfileUpdate }) => {
        return {
          url: "farms/" + `${id}/`,
          method: "PATCH",
          body: farmProfileUpdate,
        }
      },
      invalidatesTags: [TagType.FarmProfile],
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetUsersForFarmQuery,
  useLoginMutation,
  useSignupMutation,
  useVerifyEmailMutation,
  usePasswordResetMutation,
  usePasswordResetConfirmMutation,
  useChangePasswordMutation,
  useGetProfileQuery,
  useUpdateProfileMutation,
  useGetMyFarmQuery,
  useLazyGetProfileQuery,
  useLazyGetMyFarmQuery,
  useDeleteProfileMutation,
  useUpdateMyFarmProfileMutation,
  useCompleteSignupMutation,
} = accountApi
