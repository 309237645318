import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import EditIcon from "@/assets/icons/misc/edit-icon.tsx"
import { LoadingAnimation } from "@/components/loading-animation/loading-animation.tsx"
import PageContentWrapperLarge from "@/components/page-content-wrappers/page-content-wrapper-large.tsx"
import MobileTopBar from "@/components/top-bars/mobile-top-bar/mobile-top-bar.tsx"
import { useGetCropPlantingQuery } from "@/features/crops/api/crops-planting-api.ts"
import CropPlantingDetails from "@/features/crops/components/crop-planting-details/crop-planting-details.tsx"
import TasksWidget from "@/features/home/components/tasks-widget/tasks-widget.tsx"
import NotesWidget from "@/features/notes/components/notes-widget/notes-widget.tsx"
import { EDIT_CROP_PLANTING_VIEW_PATH } from "@/utils/constants/routes.ts"

const CropPlantingView = () => {
  const { t } = useTranslation()
  const params = useParams()
  const cropPlantingId = params.cropPlantingId
  const { data: cropPlanting, isLoading } = useGetCropPlantingQuery(
    cropPlantingId as string,
    {
      skip: !cropPlantingId,
    },
  )
  const editCropPlantingViewNavigationString =
    cropPlanting &&
    EDIT_CROP_PLANTING_VIEW_PATH.replace(":cropPlantingId", cropPlanting.id)

  return (
    <>
      <MobileTopBar
        title={t("cropPlantingDetails")}
        icon={<EditIcon />}
        navigateTo={editCropPlantingViewNavigationString}
      />
      <PageContentWrapperLarge className={"flex flex-col gap-4"}>
        {isLoading && (
          <LoadingAnimation
            customStyle={{
              height: "calc(100vh - 60px)",
            }}
          />
        )}
        {cropPlanting && !isLoading && (
          <>
            <CropPlantingDetails cropPlanting={cropPlanting} />
            <NotesWidget
              notes={cropPlanting.notes}
              cropPlantingId={cropPlanting.id}
            />
            <TasksWidget
              tasks={cropPlanting.tasks}
              cropPlantingId={cropPlanting.id}
            />
          </>
        )}
      </PageContentWrapperLarge>
    </>
  )
}

export default CropPlantingView
