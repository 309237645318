import { zodResolver } from "@hookform/resolvers/zod"
import React from "react"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import * as z from "zod"
import { SelectionRowDrawer } from "@/components/selection-row-drawer/selection-row-drawer.tsx"
import { Button } from "@/components/shadcn/button.tsx"
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/shadcn/form.tsx"
import { Input } from "@/components/shadcn/input.tsx"
import { Label } from "@/components/shadcn/label.tsx"
import { type UserProfileCreation } from "@/features/settings-account"
import { PhoneNumberInput } from "@/features/settings-account/components/phone-number-input/phone-number-input.tsx"
import { useLanguage } from "@/features/settings-account/hooks/use-language.ts"
import { type SupportedLanguageCode } from "@/features/settings-account/utils/language-options.ts"

interface UserDetailsStepProps {
  user: UserProfileCreation
  onUserChange: (user: UserProfileCreation) => void
  onComplete: () => void
}

export const UserDetailsStep: React.FC<UserDetailsStepProps> = ({
  user,
  onUserChange,
  onComplete,
}) => {
  const { t } = useTranslation()
  const { i18n } = useTranslation()
  const { getLanguageString, getLanguageOptions } = useLanguage()

  const formSchema = z.object({
    first_name: z.string().min(1, { message: t("nameRequired") }),
    last_name: z.string().min(1, { message: t("lastNameRequired") }),
    phone_number: z.string().min(5, { message: t("phoneNumberRequired") }),
    language: z.string(),
  })

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    mode: "onChange",
    defaultValues: {
      first_name: user.first_name || "",
      last_name: user.last_name || "",
      phone_number: user.phone_number || "",
      language: i18n.language,
    },
  })

  const { isValid } = form.formState

  const handleFormChange = (field: keyof typeof user, value: string) => {
    form.setValue(field as any, value)

    const currentValues = form.getValues()
    onUserChange({
      ...user,
      ...currentValues,
    })
  }

  const handleLanguageChange = (language: string) => {
    handleFormChange("language", language)

    toast.promise(i18n.changeLanguage(language), {
      pending: t("changingLanguage"),
      success: t("languageChanged"),
      error: t("languageChangeError"),
    })
  }

  const onSubmit = (data: z.infer<typeof formSchema>) => {
    onUserChange({
      ...user,
      ...data,
    })

    if (onComplete) {
      onComplete()
    }
  }

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="flex min-h-[calc(100dvh-120px-32px)] flex-col justify-between"
      >
        <span className={"space-y-3"}>
          <FormField
            control={form.control}
            name="first_name"
            render={({ field }) => {
              const isFieldValid = !form.formState.errors.first_name
              return (
                <FormItem>
                  <Label htmlFor={field.name} required={!isFieldValid}>
                    {t("firstName")}
                  </Label>
                  <FormControl>
                    <Input id={field.name} {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )
            }}
          />

          <FormField
            control={form.control}
            name="last_name"
            render={({ field }) => {
              const isFieldValid = !form.formState.errors.last_name
              return (
                <FormItem>
                  <Label htmlFor={field.name} required={!isFieldValid}>
                    {t("lastName")}
                  </Label>
                  <FormControl>
                    <Input id={field.name} {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )
            }}
          />

          <FormField
            control={form.control}
            name="phone_number"
            render={({ field }) => {
              const isFieldValid = !form.formState.errors.phone_number
              return (
                <FormItem>
                  <FormControl>
                    <PhoneNumberInput
                      required={!isFieldValid}
                      phoneNumber={field.value}
                      onPhoneNumberChange={(value) => {
                        field.onChange(value)
                        handleFormChange("phone_number", value)
                      }}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )
            }}
          />

          <FormField
            control={form.control}
            name="language"
            render={({ field }) => (
              <FormItem>
                <Label htmlFor="language">{t("language")}</Label>
                <FormControl>
                  <SelectionRowDrawer
                    multiple={false}
                    title={t("selectAppLanguage")}
                    renderTrigger={
                      <div className="flex h-12 w-full items-center rounded-sm border border-neutral-200 bg-white p-2 pl-4 text-base">
                        {getLanguageString(
                          field.value as SupportedLanguageCode,
                        )}
                      </div>
                    }
                    options={getLanguageOptions()}
                    selectedValues={field.value}
                    onSelectionChange={handleLanguageChange}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </span>

        <Button
          size={"lg"}
          disabled={!isValid}
          className={"mt-8 w-full bg-brown-500"}
          type="submit"
        >
          {t("continue")}
        </Button>
      </form>
    </Form>
  )
}
