import React, { useState, type FormEvent } from "react"
import { useTranslation } from "react-i18next"
import { Link, useNavigate } from "react-router-dom"
import LogoBrown from "@/assets/icons/logos/logo-brown.tsx"
import BackIcon from "@/assets/icons/misc/back-icon.tsx"
import { Button } from "@/components/shadcn/button.tsx"
import { Input, PasswordInput } from "@/components/shadcn/input.tsx"
import { useSignupMutation } from "@/features/settings-account/api/account-api.ts"
import { LOGIN_SCREEN_PATH } from "@/utils/constants/routes.ts"

const SignupView: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [email, setEmail] = useState("")
  const [password1, setPassword1] = useState("")
  const [password2, setPassword2] = useState("")
  const [signupSuccess, setSignupSuccess] = useState(false)
  const [signup, { isLoading }] = useSignupMutation()
  const [error, setError] = useState("")

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault()

    if (!email) {
      setError(t("emailRequired"))
      return
    }

    if (password1 !== password2) {
      setError(t("passwordsDoNotMatch"))
      return
    }

    if (password1.length < 8) {
      setError(t("passwordTooShort"))
      return
    }

    try {
      // Create a modified payload that meets the API requirements
      const apiPayload = {
        email,
        password: password1, // Use password1 as password for the API
      }

      try {
        // @ts-ignore - We're sending a modified payload that doesn't match SignupRequest type
        const response = await signup(apiPayload).unwrap()
        // If we reach here, signup succeeded
        setSignupSuccess(true)
        setError("")
      } catch (err: any) {
        // Check if this is a 401 with email verification flow
        if (err.status === 401) {
          // This is actually a success case - email verification is pending
          setSignupSuccess(true)
          setError("")
          return
        }

        if (err.data && typeof err.data === "object") {
          const fieldErrors = []
          for (const field in err.data) {
            if (Array.isArray(err.data[field])) {
              fieldErrors.push(err.data[field].join(" "))
            } else if (err.data.errors && Array.isArray(err.data.errors)) {
              err.data.errors.forEach((error: any) => {
                if (error.message) {
                  fieldErrors.push(error.message)
                }
              })
            }
          }

          if (fieldErrors.length > 0) {
            setError(fieldErrors.join(". "))
          } else {
            setError(t("failedToCreateAccount"))
          }
        } else {
          setError(t("failedToCreateAccount"))
        }
      }
    } catch (e) {
      console.error("Unexpected error during signup:", e)
      setError(t("failedToCreateAccount"))
    }
  }

  return (
    <div className={"flex h-full w-full flex-col items-center justify-center"}>
      <Link
        to={LOGIN_SCREEN_PATH}
        className={"absolute left-0 top-0 p-[30px] text-brown-500"}
        data-testid="back-to-login-link"
      >
        {" "}
        <BackIcon />
      </Link>
      <div
        className={
          "flex h-[calc(100vh-80px-env(safe-area-inset-bottom))] w-[300px] flex-col items-center justify-center gap-6"
        }
      >
        <LogoBrown width={150} height={150} />

        {signupSuccess ? (
          <div className={"flex flex-col items-center justify-center"}>
            <h1 className={"text-body-xl font-medium"}>
              {t("signupSuccessful")}
            </h1>
            <h5
              className={"text-center text-base font-semibold text-neutral-600"}
            >
              {t("verificationEmailSent")}
            </h5>
            <h5
              className={"text-center text-base font-semibold text-neutral-600"}
            >
              {t("pleaseCheckYourEmail")}
            </h5>
            <Button
              className={"mt-10 w-full"}
              size={"lg"}
              onClick={() => navigate(LOGIN_SCREEN_PATH)}
              data-testid="success-back-to-login-button"
            >
              {t("backToLogin")}
            </Button>
          </div>
        ) : (
          <>
            <div className={"flex flex-col items-center justify-center"}>
              <h1 className={"text-center text-body-xl font-medium"}>
                {t("createAccount")}
              </h1>
              <h5
                className={
                  "text-center text-base font-semibold text-neutral-600"
                }
              >
                {t("fillInDetailsBelow")}
              </h5>
            </div>
            <form
              onSubmit={handleSubmit}
              className={
                "flex w-full flex-col items-center justify-center gap-20 p-3"
              }
            >
              <span className={"w-full space-y-2.5"}>
                <Input
                  id="email"
                  type="email"
                  name="email"
                  placeholder="E-mail*"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  disabled={isLoading}
                  required
                  data-testid="signup-email-input"
                />
                <PasswordInput
                  id="password1"
                  name="password1"
                  placeholder={t("password") + "*"}
                  value={password1}
                  onChange={(e) => setPassword1(e.target.value)}
                  disabled={isLoading}
                  required
                  data-testid="signup-password-input"
                />
                <PasswordInput
                  id="password2"
                  name="password2"
                  placeholder={t("confirmPassword") + "*"}
                  value={password2}
                  onChange={(e) => setPassword2(e.target.value)}
                  disabled={isLoading}
                  required
                  data-testid="signup-confirm-password-input"
                />
              </span>

              {error && (
                <div
                  style={{ backgroundColor: "rgba(252, 165, 165, 0.5)" }}
                  className={
                    "mt-5 w-full rounded-lg p-4 text-center font-medium text-red-500"
                  }
                  data-testid="signup-error-message"
                >
                  {error}
                </div>
              )}
              <Button
                type="submit"
                className={"w-full"}
                size={"lg"}
                disabled={isLoading || !email || !password1 || !password2}
                data-testid="signup-button"
              >
                {isLoading ? t("creatingAccount") : t("createAccount")}
              </Button>
            </form>
          </>
        )}
      </div>
    </div>
  )
}

export default SignupView
