import { format } from "date-fns"
import { useTranslation } from "react-i18next"
import { AssocHiveDrawer } from "@/features/bees/components/hive-scales/assoc-hive-drawer/assoc-hive-drawer.tsx"
import { MeasurementsCard } from "@/features/bees/components/hive-scales/measuremnts-card/measurements-card.tsx"
import { HiveCardLong } from "@/features/bees/components/hives/hive-card/hive-card-long.tsx"
import { type HiveScale } from "@/features/bees/types/hive-scales.ts"
import WidgetHeader from "@/features/home/components/widget-header/widget-header.tsx"
import { HIVE_SCALE_VIEW } from "@/utils/constants/routes.ts"

interface Props {
  hiveScale: HiveScale
  showHive?: boolean
  showNavigation?: boolean
}

export const HiveScaleCard: React.FC<Props> = ({
  hiveScale,
  showHive = false,
  showNavigation = true,
}) => {
  const { t } = useTranslation()
  const navigationPath = HIVE_SCALE_VIEW.replace(":hiveScaleId", hiveScale.id)
  const getTitle = () => {
    if (hiveScale.hive) {
      return t("scaleForHive", { hive: hiveScale.hive.code })
    } else {
      return t("scale", { phoneNumber: hiveScale.phone_number })
    }
  }

  const HiveInfo = () => {
    return (
      <>
        {hiveScale.hive ? (
          <HiveCardLong hive={hiveScale.hive} />
        ) : (
          <AssocHiveDrawer scale={hiveScale} title={t("assocHive")} />
        )}
      </>
    )
  }

  return (
    <div
      className={"widget-border box-border flex flex-col gap-5 bg-white p-4"}
    >
      <WidgetHeader
        className={"w-full"}
        navigateTo={showNavigation ? navigationPath : undefined}
        title={getTitle()}
      />
      {hiveScale.hive && (
        <div className={"-mb-3 flex items-center justify-between"}>
          <span className={"text-body-sm"}>{t("phoneNumber")}</span>
          <span className={"text-body-sm font-semibold text-neutral-700"}>
            {hiveScale.phone_number.substring(2)}
          </span>
        </div>
      )}
      {showHive && <HiveInfo />}

      {hiveScale.last_measurement ? (
        <>
          <div className={"flex items-center justify-between"}>
            <p>{t("lastMeasurementTime")}</p>
            <p className={"font-medium text-neutral-700"}>
              {format(
                hiveScale.last_measurement.measured_at,
                "dd.MM.yyyy : HH:mm:ss",
              )}
            </p>
          </div>
          <MeasurementsCard hiveScale={hiveScale} />
        </>
      ) : (
        <div
          className={"flex min-h-[60px] w-full items-center justify-center p-4"}
        >
          <p className={"text-center"}>{t("noMeasurementsRecorded")}</p>
        </div>
      )}
    </div>
  )
}
