import { apiSlice, TagType } from "@/features/api/apiSlice.ts"
import { type Queen, type QueenResponse } from "@/features/bees/types/queens.ts"

const QUEENS_API_BASE_URL = `/queens/`

export const queensApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createQueen: builder.mutation<QueenResponse, Partial<Queen>>({
      query: (queen) => ({
        url: `${QUEENS_API_BASE_URL}`,
        method: "POST",
        body: queen,
      }),
      invalidatesTags: (_result, _error, queen) => [
        { type: TagType.Queens, id: "LIST" },
        { type: TagType.Hives, id: queen.hive },
        { type: TagType.Hives, id: "LIST" },
      ],
    }),
    getQueenForHive: builder.query<QueenResponse[], { hiveId: string }>({
      query: ({ hiveId }) => ({
        url: QUEENS_API_BASE_URL,
        params: { hive_id: hiveId },
      }),
      providesTags: (_result, _error, { hiveId }) => [
        { type: TagType.Queens, id: `${hiveId}-LIST` },
      ],
    }),
    getAllQueens: builder.query<QueenResponse[], void>({
      query: () => `${QUEENS_API_BASE_URL}`,
      providesTags: [{ type: TagType.Queens, id: "LIST" }],
    }),
    getQueenById: builder.query<QueenResponse, string>({
      query: (queenId) => `${QUEENS_API_BASE_URL}${queenId}/`,
      providesTags: (_result, _error, queenId) => [
        { type: TagType.Queens, id: queenId },
      ],
    }),
    updateQueen: builder.mutation<
      Queen,
      {
        hiveId: string
        queenId: string
        queen: Partial<Queen>
      }
    >({
      query: ({ queenId, queen }) => ({
        url: `${QUEENS_API_BASE_URL}${queenId}/`,
        method: "PATCH",
        body: queen,
      }),
      invalidatesTags: (_result, _error, { hiveId, queenId }) => {
        return [
          { type: TagType.Queens, id: queenId },
          { type: TagType.Queens, id: "LIST" },
          { type: TagType.Queens, id: `${hiveId}-LIST` },
          { type: TagType.Hives, id: hiveId },
          { type: TagType.Hives, id: "LIST" },
        ]
      },
    }),
    deleteQueen: builder.mutation<
      void,
      { apiaryId: string; hiveId: string; queenId: string }
    >({
      query: ({ queenId }) => ({
        url: `${QUEENS_API_BASE_URL}${queenId}/`,
        method: "DELETE",
      }),
      invalidatesTags: (_result, _error, { queenId, hiveId }) => [
        { type: TagType.Queens, id: queenId },
        { type: TagType.Queens, id: `${hiveId}-LIST` },
        { type: TagType.Hives, id: hiveId },
      ],
    }),
  }),
})

export const {
  useCreateQueenMutation,
  useGetQueenForHiveQuery,
  useGetAllQueensQuery,
  useGetQueenByIdQuery,
  useUpdateQueenMutation,
  useDeleteQueenMutation,
} = queensApi
