import { format } from "date-fns"
import React, { useState } from "react"
import { type DateRange } from "react-day-picker"
import { useTranslation } from "react-i18next"
import Calendar from "@/components/shadcn/calendar/calendar.tsx"
import { DrawerClose } from "@/components/shadcn/drawer.tsx"
import { cn } from "@/utils/styles.ts"

interface Props {
  selectedTimeFrame: DateRange | undefined
  onSelectedTimeFrameChange: (dateRange: DateRange | undefined) => void
  inDrawer?: boolean
}

export const DateRangePicker: React.FC<Props> = ({
  selectedTimeFrame,
  onSelectedTimeFrameChange,
  inDrawer,
}) => {
  const { t } = useTranslation()
  const [timeFrame, setTimeFrame] = useState<DateRange | undefined>(
    selectedTimeFrame,
  )

  const handleTimeFrameChange = (dateRange: DateRange | undefined) => {
    setTimeFrame(dateRange)
  }

  const handleFilterReset = () => {
    setTimeFrame(undefined)
    onSelectedTimeFrameChange(undefined)
  }

  const handleApplySelection = () => {
    onSelectedTimeFrameChange(timeFrame)
  }

  const isButtonDisabled = !timeFrame?.from
  const showResultsButtonClassName = cn(
    "flex h-[50px] w-full items-center justify-center rounded-[8px] bg-brown-500 text-[14px] font-semibold text-white",
    isButtonDisabled && "bg-neutral-100 text-neutral-700",
  )
  const clearButtonClassName = cn(
    "flex h-[50px] w-full items-center justify-center rounded-[8px] bg-neutral-100 text-[14px] font-semibold text-neutral-700",
    isButtonDisabled && "opacity-0",
  )

  const Button = inDrawer ? DrawerClose : "button"

  return (
    <div className={"flex w-full flex-col justify-between gap-2"}>
      <Calendar
        mode={"range"}
        selected={timeFrame}
        defaultMonth={timeFrame?.from}
        onSelect={handleTimeFrameChange}
        captionLayout={"dropdown-buttons"}
        fromDate={new Date(2020, 0, 1)}
        toDate={new Date(2100, 0, 1)}
      />
      <div className={"flex min-h-[40px] w-full items-center justify-between"}>
        <div
          className={
            "widget-border grid w-[150px] place-content-center bg-white p-4"
          }
        >
          {timeFrame && timeFrame?.from
            ? format(timeFrame?.from, "dd-MM-yyyy")
            : t("dateFrom")}
        </div>
        -
        <div
          className={
            "widget-border grid w-[150px] place-content-center bg-white p-4"
          }
        >
          {timeFrame && timeFrame.to
            ? format(timeFrame?.to, "dd-MM-yyyy")
            : t("dateTo")}
        </div>
      </div>
      <div className={"flex flex-col gap-[10px]"}>
        <Button onClick={handleFilterReset} className={clearButtonClassName}>
          {t("resetFilter")}
        </Button>
        <Button
          disabled={isButtonDisabled}
          onClick={handleApplySelection}
          className={showResultsButtonClassName}
        >
          {t("showResults")}
        </Button>
      </div>
    </div>
  )
}
