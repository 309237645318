import { zodResolver } from "@hookform/resolvers/zod"
import React from "react"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import * as z from "zod"
import { Button } from "@/components/shadcn/button.tsx"
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/shadcn/form.tsx"
import { Input } from "@/components/shadcn/input.tsx"
import { Label } from "@/components/shadcn/label.tsx"
import { type BillingData } from "@/features/settings-account"

interface FarmDetailsStepProps {
  onBillingDataChange: (data: BillingData) => void
  billingData: BillingData
  onComplete?: () => void
  onBack: () => void
}

export const FarmDetailsStep: React.FC<FarmDetailsStepProps> = ({
  onBillingDataChange,
  billingData,
  onComplete,
  onBack,
}) => {
  const { t } = useTranslation()

  const formSchema = z.object({
    farm_name: z.string().min(1, { message: t("farmNameRequired") }),
    address: z.object({
      street: z.string().min(1, { message: t("streetRequired") }),
      city: z.string().min(1, { message: t("cityRequired") }),
      state: z.string().min(1, { message: t("spRequired") }),
      country: z.string().min(1, { message: t("countryRequired") }),
    }),
  })

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    mode: "onChange",
    defaultValues: {
      farm_name: billingData.farm_name || "",
      address: {
        street: billingData.address?.street || "",
        city: billingData.address?.city || "",
        state: billingData.address?.state || "",
        country: billingData.address?.country || "",
      },
    },
  })

  const handleFieldChange = (field: string, value: string) => {
    if (["street", "city", "state", "country"].includes(field)) {
      form.setValue(`address.${field}` as any, value)

      const currentValues = form.getValues()
      onBillingDataChange({
        ...billingData,
        ...currentValues,
        legal_name: billingData.legal_name || "",
        tin: billingData.tin || "",
      })
    } else {
      form.setValue(field as any, value)

      const currentValues = form.getValues()
      onBillingDataChange({
        ...billingData,
        ...currentValues,
        legal_name: billingData.legal_name || "",
        tin: billingData.tin || "",
      })
    }
  }

  const onSubmit = (data: z.infer<typeof formSchema>) => {
    onBillingDataChange({
      ...billingData,
      ...data,
      legal_name: billingData.legal_name || "",
      tin: billingData.tin || "",
    })
    if (onComplete) {
      onComplete()
    }
  }

  const { isValid } = form.formState

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="flex min-h-[calc(100dvh-120px-32px)] flex-col justify-between"
      >
        <span className={"space-y-3"}>
          <FormField
            control={form.control}
            name="farm_name"
            render={({ field }) => {
              const isFieldValid = !form.formState.errors.farm_name
              return (
                <FormItem>
                  <Label htmlFor={field.name} required={!isFieldValid}>
                    {t("Farm Name")}
                  </Label>
                  <FormControl>
                    <Input
                      id={field.name}
                      placeholder={t("Enter the name of your farm")}
                      {...field}
                      onChange={(e) => {
                        field.onChange(e)
                        handleFieldChange("farm_name", e.target.value)
                      }}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )
            }}
          />

          <FormField
            control={form.control}
            name="address.street"
            render={({ field }) => {
              const isFieldValid = !form.formState.errors.address?.street
              return (
                <FormItem>
                  <Label htmlFor={field.name} required={!isFieldValid}>
                    {t("Street")}
                  </Label>
                  <FormControl>
                    <Input
                      id={field.name}
                      placeholder={t("Street address")}
                      {...field}
                      onChange={(e) => {
                        field.onChange(e)
                        handleFieldChange("street", e.target.value)
                      }}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )
            }}
          />

          <FormField
            control={form.control}
            name="address.city"
            render={({ field }) => {
              const isFieldValid = !form.formState.errors.address?.city
              return (
                <FormItem>
                  <Label htmlFor={field.name} required={!isFieldValid}>
                    {t("City")}
                  </Label>
                  <FormControl>
                    <Input
                      id={field.name}
                      placeholder={t("City")}
                      {...field}
                      onChange={(e) => {
                        field.onChange(e)
                        handleFieldChange("city", e.target.value)
                      }}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )
            }}
          />

          <FormField
            control={form.control}
            name="address.state"
            render={({ field }) => {
              const isFieldValid = !form.formState.errors.address?.state
              return (
                <FormItem>
                  <Label htmlFor={field.name} required={!isFieldValid}>
                    {t("State/Province")}
                  </Label>
                  <FormControl>
                    <Input
                      id={field.name}
                      placeholder={t("State or province")}
                      {...field}
                      onChange={(e) => {
                        field.onChange(e)
                        handleFieldChange("state", e.target.value)
                      }}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )
            }}
          />

          <FormField
            control={form.control}
            name="address.country"
            render={({ field }) => {
              const isFieldValid = !form.formState.errors.address?.country
              return (
                <FormItem>
                  <Label htmlFor={field.name} required={!isFieldValid}>
                    {t("Country")}
                  </Label>
                  <FormControl>
                    <Input
                      id={field.name}
                      placeholder={t("Country")}
                      {...field}
                      onChange={(e) => {
                        field.onChange(e)
                        handleFieldChange("country", e.target.value)
                      }}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )
            }}
          />
        </span>

        <div className={"space-y-4"}>
          <Button
            size={"lg"}
            disabled={!isValid}
            className={"mt-8 w-full bg-brown-500"}
            type="submit"
          >
            {t("continue")}
          </Button>
          <Button
            size={"lg"}
            variant={"outline"}
            className={"mt-16 w-full text-brown-500"}
            onClick={onBack}
          >
            {t("back")}
          </Button>
        </div>
      </form>
    </Form>
  )
}

export default FarmDetailsStep
