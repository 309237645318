import { apiSlice, TagType } from "@/features/api/apiSlice.ts"
import { type Crop } from "@/features/crops/types/crop-planting.ts"

const CROPS_API_BASE_URL = "/crops/"

export const cropsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCrops: builder.query<Crop[], void>({
      query: () => CROPS_API_BASE_URL,
      providesTags: [{ type: TagType.Crops, id: "LIST" }],
    }),
  }),
})

export const { useGetCropsQuery } = cropsApi
