import * as React from "react"
import styles from "./index.module.css"
import { NOTIFICATION_BLACK } from "@/utils/constants/colors-constants.ts"

interface SelectButtonProps extends React.ComponentPropsWithoutRef<"button"> {
  children?: React.ReactNode
  style?: React.CSSProperties
  isActive?: boolean
}

const selectedStyle = {
  color: "white",
  backgroundColor: NOTIFICATION_BLACK,
  border: "none",
  paddingInline: "15px",
}

const unSelectedStyle = {
  paddingInline: "15px",
}

const SelectPill = React.forwardRef<HTMLButtonElement, SelectButtonProps>(
  ({ children, isActive, ...props }, ref) => {
    return (
      <button
        style={isActive ? selectedStyle : unSelectedStyle}
        className={styles.selectPill}
        ref={ref}
        {...props}
      >
        {children}
      </button>
    )
  },
)

SelectPill.displayName = "SelectPill"

export { SelectPill }
