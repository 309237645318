import { motion, AnimatePresence } from "framer-motion"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import { LoadingAnimation } from "@/components/loading-animation/loading-animation.tsx"
import {
  type BillingData,
  initialBillingData,
  initialUserProfileCreation,
  type UserProfileCreation,
} from "@/features/settings-account"
import {
  useCompleteSignupMutation,
  useGetProfileQuery,
} from "@/features/settings-account/api/account-api.ts"
import MultiStepProgress from "@/features/settings-account/components/multi-step/multi-step.tsx"
import { AppPackageSelectionStep } from "@/features/settings-account/routes/onboarding/app-package-selection-step.tsx"
import FarmDetailsStep from "@/features/settings-account/routes/onboarding/farm-details-step.tsx"
import { UserDetailsStep } from "@/features/settings-account/routes/onboarding/user-details-step.tsx"
import { useAppDispatch } from "@/redux/hooks.ts"
import { setFarmId } from "@/redux/slices/auth-slice.ts"
import { HOME_SCREEN_PATH } from "@/utils/constants/routes.ts"

const OnboardingView = () => {
  const { t } = useTranslation()
  const naivgate = useNavigate()
  const dispatch = useAppDispatch()
  const [step, setStep] = useState(1)
  const { data, isLoading, isFetching } = useGetProfileQuery()
  const [completeSignup] = useCompleteSignupMutation()
  const [userDraft, setUserDraft] = useState<UserProfileCreation>(
    initialUserProfileCreation,
  )
  const [selectedPackages, setSelectedPackages] = useState<string[]>([])
  const [billingData, setBillingData] =
    useState<BillingData>(initialBillingData)

  const handleCompleteSignup = async () => {
    if (!data?.id) {
      return
    }
    toast
      .promise(
        completeSignup({
          user_fields: userDraft,
          selected_packages: selectedPackages,
          billing_data: billingData,
        }).unwrap(),
        {
          pending: t("loading"),
          success: t("signupSuccess"),
          error: t("errorOccurred"),
        },
      )
      .then((result) => {
        if (result.farm.id) {
          dispatch(setFarmId(result.farm.id))
          setTimeout(() => {
            naivgate(HOME_SCREEN_PATH)
          }, 400)
        } else {
          toast.error(t("errorOccurred"))
        }
      })
  }

  const handlePackageSelection = (packages: string[]) => {
    setSelectedPackages(packages)
  }

  const handleBillingDataChange = (data: BillingData) => {
    setBillingData((prev) => ({ ...prev, ...data }))
  }

  const handleContinue = async () => {
    if (step < 3) {
      setStep((prevStep) => prevStep + 1)
    } else {
      await handleCompleteSignup()
    }
  }

  const handleBack = () => {
    if (step > 1) {
      setStep((prevStep) => prevStep - 1)
    }
  }

  if (isFetching || isLoading) {
    return (
      <div className={"grid h-[100dvh] place-content-center"}>
        <LoadingAnimation />
      </div>
    )
  }

  const labels = [t("yourData"), t("yourFarmDetails"), t("farmDetails")]

  return (
    <div
      className={"h-[100dvh] overflow-y-auto overflow-x-hidden p-4 px-6 pb-10"}
    >
      <MultiStepProgress labels={labels} currentStep={step} />
      <AnimatePresence mode="wait">
        {step === 1 && (
          <motion.div
            key="user"
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -20 }}
            transition={{ duration: 0.3 }}
          >
            <UserDetailsStep
              onUserChange={setUserDraft}
              user={userDraft}
              onComplete={handleContinue}
            />
          </motion.div>
        )}

        {step === 2 && (
          <motion.div
            key="packages"
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -20 }}
            transition={{ duration: 0.3 }}
          >
            <AppPackageSelectionStep
              onPackageSelect={handlePackageSelection}
              selectedPackages={selectedPackages}
              onContinue={handleContinue}
              onBack={handleBack}
            />
          </motion.div>
        )}

        {step === 3 && (
          <motion.div
            key="biling"
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -20 }}
            transition={{ duration: 0.3 }}
          >
            <FarmDetailsStep
              onBillingDataChange={handleBillingDataChange}
              billingData={billingData}
              onComplete={handleContinue}
              onBack={handleBack}
            />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}

export default OnboardingView
