export interface PlotBoundaryPoint {
  latitude: number
  longitude: number
  sequence: number
}

export interface ParcelCoordinate {
  latitude: number
  longitude: number
}

export interface PlotBoundaryPointNested extends ParcelCoordinate {
  sequence: number
}

export interface Parcel {
  id: string
  name: string
  description: string
  area: number
  picture: string
  center_latitude: number
  center_longitude: number
  farm: number
  notes: string[]
  tasks: string[]
  boundary_points: PlotBoundaryPoint[]
  crop_plantings: string[]
}

export interface ParcelCreate {
  name: string
  description: string
  picture?: string | null
  boundary_points?: PlotBoundaryPointNested[]
}

export interface ParcelUpdate {
  id: string
  name?: string
  description?: string
  picture?: string | null
  boundary_points?: PlotBoundaryPointNested[]
}

export const parcelCreateDefault: ParcelCreate = {
  name: "",
  description: "",
  boundary_points: [],
  picture: null,
}
