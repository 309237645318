import React, { useState, type FormEvent } from "react"
import { useTranslation } from "react-i18next"
import { Link, useNavigate } from "react-router-dom"
import LogoBrown from "@/assets/icons/logos/logo-brown.tsx"
import BackIcon from "@/assets/icons/misc/back-icon.tsx"
import { Button } from "@/components/shadcn/button.tsx"
import { Input } from "@/components/shadcn/input.tsx"
import { usePasswordResetMutation } from "@/features/settings-account/api/account-api.ts"
import { LOGIN_SCREEN_PATH } from "@/utils/constants/routes.ts"

const LostPassword: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [email, setEmail] = useState("")
  const [resetSent, setResetSent] = useState(false)
  const [passwordReset, { isLoading }] = usePasswordResetMutation()
  const [error, setError] = useState("")

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault()
    if (!email) {
      setError(t("emailRequired"))
      return
    }

    try {
      await passwordReset({ email }).unwrap()
      setResetSent(true)
      setError("")
    } catch (err: any) {
      console.error("Failed to reset password:", err)

      // Handle different types of error responses
      if (err.data && typeof err.data === "object") {
        // Handle specific field errors
        const fieldErrors = []
        for (const field in err.data) {
          if (Array.isArray(err.data[field])) {
            fieldErrors.push(err.data[field].join(" "))
          }
        }

        if (fieldErrors.length > 0) {
          setError(fieldErrors.join(". "))
        } else {
          setError(t("failedToResetPassword"))
        }
      } else {
        setError(t("failedToResetPassword"))
      }
    }
  }

  return (
    <div className={"flex h-full w-full flex-col items-center justify-center"}>
      <Link
        to={LOGIN_SCREEN_PATH}
        className={"absolute left-0 top-0 p-[30px] text-brown-500"}
        data-testid="back-to-login-link"
      >
        <BackIcon />
      </Link>
      <div
        className={
          "flex h-[calc(100vh-80px-env(safe-area-inset-bottom))] w-[300px] flex-col items-center justify-center gap-6"
        }
      >
        <LogoBrown width={150} height={150} />

        {resetSent ? (
          <div
            className={"flex flex-col items-center justify-center gap-4"}
            data-testid="reset-success-message"
          >
            <h1 className={"text-center text-body-xl font-medium"}>
              {t("passwordResetEmailSent")}
            </h1>
            <h5
              className={"text-center text-base font-semibold text-neutral-600"}
            >
              {t("checkYourEmailForResetLink")}
            </h5>
            <Button
              className={"w-full"}
              size={"lg"}
              onClick={() => navigate(LOGIN_SCREEN_PATH)}
              data-testid="back-to-login-button"
            >
              {t("backToLogin")}
            </Button>
          </div>
        ) : (
          <>
            <div
              className={
                "flex flex-col items-center justify-center text-center"
              }
            >
              <h1 className={"text-body-xl font-medium"}>
                {t("lostYourPassword")}
              </h1>
              <h5 className={"text-base font-semibold text-neutral-600"}>
                {t("dontWorryHappensToAllOfUs")}
              </h5>
              <h5 className={"text-base font-semibold text-neutral-600"}>
                {t("enterEmailResetPassword")}
              </h5>
            </div>
            <form
              onSubmit={handleSubmit}
              className={
                "flex w-full flex-col items-center justify-center gap-5 p-3"
              }
            >
              <Input
                id="email"
                type="email"
                name="email"
                placeholder={"E-mail"}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                disabled={isLoading}
                data-testid="forgot-password-email-input"
              />
              {error && (
                <div
                  style={{ backgroundColor: "rgba(252, 165, 165, 0.5)" }}
                  className={
                    "mt-5 w-full rounded-lg p-4 text-center font-medium text-red-500"
                  }
                  data-testid="forgot-password-error-message"
                >
                  {error}
                </div>
              )}
              <Button
                className={"w-full"}
                size={"lg"}
                type="submit"
                disabled={isLoading || !email}
                data-testid="forgot-password-submit-button"
              >
                {isLoading ? t("sending") : t("resetPassword")}
              </Button>
            </form>
          </>
        )}
      </div>
    </div>
  )
}

export default LostPassword
