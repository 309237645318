import { skipToken } from "@reduxjs/toolkit/query"
import { useTranslation } from "react-i18next"
import { useLocation, useParams, useNavigate } from "react-router-dom"
import { HoneyPotIcon } from "@/assets/hive-scales/honey-pot-icon.tsx"
import EditIcon from "@/assets/icons/misc/edit-icon.tsx"
import genericHiveImg from "@/assets/images/generic_hive.webp"
import GenericPicture from "@/components/generic-picture/generic-picture.tsx"
import { LoadingAnimation } from "@/components/loading-animation/loading-animation.tsx"
import PageContentWrapperLarge from "@/components/page-content-wrappers/page-content-wrapper-large.tsx"
import MobileTopBar from "@/components/top-bars/mobile-top-bar/mobile-top-bar.tsx"
import { useGetHiveByIdQuery } from "@/features/bees/api/hives-api.ts"
import { useGetQueenByIdQuery } from "@/features/bees/api/queens-api.ts"
import { HiveScaleWidget } from "@/features/bees/components/hive-scales/hive-scale-widget/hive-scale-widget.tsx"
import HiveDetails from "@/features/bees/components/hives/hive-details/hive-details.tsx"
import { HiveMeasurementWidget } from "@/features/bees/components/hives/hive-measurment-widget/hive-measurement-widget.tsx"
import { HiveMapWidget } from "@/features/bees/components/map-widget/hive-map-widget.tsx"
import { QueenWidget } from "@/features/bees/components/queens/queen-widget/queen-widget.tsx"
import { HIVE_SCALE_METRICS } from "@/features/bees/types/hive-scales.ts"
import { HONEY_HARVEST_EVENT_NAME } from "@/features/bees/types/hives.ts"
import { APIARY_QUERY_PARAMS } from "@/features/events/common/types/apiary-querry-params.ts"
import { useGetAllHiveEventTypesQuery } from "@/features/events/hive-events/api/hive-event-types-api.ts"
import { HiveEventsWidget } from "@/features/events/hive-events/components/hive-events-widget/hive-events-widget.tsx"
import TasksWidget from "@/features/home/components/tasks-widget/tasks-widget.tsx"
import NotesWidget from "@/features/notes/components/notes-widget/notes-widget.tsx"
import {
  ALL_HIVES_PATH,
  APIARIES_SCREEN_PATH,
  NEW_HIVE_EVENT_ROUTE,
} from "@/utils/constants/routes.ts"

const HiveView = () => {
  const { t } = useTranslation()
  const { apiaryId, hiveId } = useParams()
  const navigate = useNavigate()
  const location = useLocation()
  const { data: hive } = useGetHiveByIdQuery(hiveId ? hiveId : skipToken)
  const queenId = hive?.queen ? hive?.queen.id : ""
  const { data: queen } = useGetQueenByIdQuery(queenId || skipToken)
  const { data: hivesEventTypes } = useGetAllHiveEventTypesQuery()

  const honeyHarvestEvent = hivesEventTypes?.find(
    (eventType) => eventType.name === HONEY_HARVEST_EVENT_NAME,
  )

  const navigateToNewHoneyHarvestEvent = () => {
    if (!hive || !honeyHarvestEvent) return
    const searchParams = new URLSearchParams()
    searchParams.set(APIARY_QUERY_PARAMS.HIVE_ID, hive.id)
    searchParams.set(APIARY_QUERY_PARAMS.TYPE_ID, honeyHarvestEvent?.id || "")

    const newHoneyHarvestEventNavigationString = `${NEW_HIVE_EVENT_ROUTE}?${searchParams.toString()}`

    navigate(newHoneyHarvestEventNavigationString)
  }

  const title = hive?.code || t("isLoading")
  const editHiveNavigationString = `${APIARIES_SCREEN_PATH}/${apiaryId}${ALL_HIVES_PATH}/${hiveId}/edit`

  const handleBackClick = () => {
    const isPrevANewRoute = location.state?.previousPath?.includes("new")
    const isPrevQueenView = location.state?.previousPath?.includes("queen")

    if (isPrevANewRoute || isPrevQueenView) {
      navigate(`${APIARIES_SCREEN_PATH}/${apiaryId}`, {
        state: { previousPath: location.pathname },
      })
    } else {
      navigate(-1)
    }
  }

  return (
    <>
      <MobileTopBar
        title={title}
        navigateTo={editHiveNavigationString}
        icon={<EditIcon />}
        onBackClick={handleBackClick}
      />
      <PageContentWrapperLarge className={"flex flex-col"}>
        {hive ? (
          <>
            <GenericPicture
              src={hive?.picture || genericHiveImg}
              alt={t("hiveImage")}
              borderRadius={12}
              height={200}
            />
            {hive && <HiveDetails hive={hive} queen={queen} />}
            <QueenWidget queen={queen} />
            <TasksWidget tasks={hive?.tasks} hiveId={hive?.id} />
            <NotesWidget notes={hive?.notes} hiveId={hive?.id} />
            <HiveScaleWidget hive={hive} />
            {hive.scale && (
              <>
                <HiveMeasurementWidget
                  scaleId={hive.scale}
                  metric={HIVE_SCALE_METRICS.TEMPERATURE}
                />
                <HiveMeasurementWidget
                  scaleId={hive.scale}
                  metric={HIVE_SCALE_METRICS.WEIGHT}
                />
                <HiveMeasurementWidget
                  scaleId={hive.scale}
                  metric={HIVE_SCALE_METRICS.HUMIDITY}
                />
                <HiveMeasurementWidget
                  scaleId={hive.scale}
                  metric={HIVE_SCALE_METRICS.PRESSURE}
                />
                <HiveMapWidget scaleId={hive.scale} />
              </>
            )}
            <HiveEventsWidget hiveId={hive.id} />
            <div
              onClick={navigateToNewHoneyHarvestEvent}
              className={
                "flex items-center justify-center gap-2 rounded-[15px] border border-brown-500 bg-white p-4"
              }
            >
              <HoneyPotIcon className={"h-10 w-10 p-1 text-brown-500"} />
              <p className={"font-semibold text-brown-500"}>
                {t("addHoneyHarvest")}
              </p>
            </div>
          </>
        ) : (
          <LoadingAnimation />
        )}
      </PageContentWrapperLarge>
    </>
  )
}

export default HiveView
