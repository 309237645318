import { isToday, toDate } from "date-fns"
import {
  DayPicker,
  type DateRange,
  type DayPickerProps,
} from "react-day-picker"
import "react-day-picker/dist/style.css"
import { useTranslation } from "react-i18next"
import { getLocaleFromLanguage } from "@/locales/language-utils.ts"
import {
  BROWN,
  TRANSPARENT_BROWN,
  WHITE,
} from "@/utils/constants/colors-constants.ts"

function isValidDateInput(obj: unknown): obj is string | number | Date {
  return (
    typeof obj === "string" || typeof obj === "number" || obj instanceof Date
  )
}

function Calendar({ showOutsideDays = true, ...props }: DayPickerProps) {
  const { i18n } = useTranslation()
  const isTodaySelected =
    isValidDateInput(props.selected) && isToday(toDate(props.selected))

  const rangeModifiers = {
    range_middle: {
      backgroundColor: TRANSPARENT_BROWN,
      borderRadius: 0,
    },
    range_start: {
      color: WHITE,
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
    range_end: {
      color: WHITE,
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },
    selected: {
      backgroundColor: BROWN,
      color: WHITE,
    },
  }

  const singleModifiers = {
    selected: {
      backgroundColor: BROWN,
      color: "white",
      borderRadius: "50%",
    },
  }

  const isRangeDifferent = (dateRange: DateRange) => {
    if (!dateRange.from || !dateRange.to) {
      return false
    }
    const date1 = new Date(dateRange.from)
    const date2 = new Date(dateRange.to)
    return date1.getTime() !== date2.getTime()
  }

  return (
    <DayPicker
      locale={getLocaleFromLanguage(i18n.language)}
      showOutsideDays={showOutsideDays}
      weekStartsOn={1}
      modifiersStyles={{
        ...(props.mode === "range" &&
        props.selected &&
        props.selected.to &&
        isRangeDifferent(props.selected)
          ? rangeModifiers
          : singleModifiers),
        today: {
          color: isTodaySelected ? "white" : BROWN,
        },
      }}
      styles={{
        root: {
          width: "100%",
          margin: 0,
          color: "hsl(var(--foreground))",
          background: "hsl(var(--background))",
          border: `1px solid hsl(var(--border))`,
          borderRadius: "var(--radius)",
          padding: "0.5rem",
          position: "relative",
          maxWidth: "none",
        },
        caption: {
          fontSize: "1rem",
          fontWeight: 500,
          color: "hsl(var(--foreground))",
          paddingBottom: "1rem",
        },
        table: {
          maxWidth: "100%",
          width: "100%",
          paddingTop: "1rem",
          tableLayout: "fixed",
        },
        head_row: {
          width: "100%",
          maxWidth: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        },
        head_cell: {
          color: "hsl(var(--muted-foreground))",
          fontSize: "0.8rem",
          fontWeight: 400,
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        },
        tbody: {
          width: "100%",
          display: "flex",
          flexDirection: "column",
        },
        row: {
          width: "100%",
          display: "flex",
          maxWidth: "100%",
        },
        day: {
          width: "100%",
          maxWidth: "100%",
          backgroundColor: "hsl(var(--background))",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          border: "none",
          padding: "0.5rem",
          margin: 0,
          fontSize: "0.8rem",
          height: "100%",
        },
        cell: {
          aspectRatio: "1 / 1",
          width: "100%",
          maxWidth: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          margin: 0,
          height: "100%",
        },
      }}
      {...props}
    />
  )
}

Calendar.displayName = "Calendar"

export default Calendar
