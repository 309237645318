import { skipToken } from "@reduxjs/toolkit/query"
import { useTranslation } from "react-i18next"
import { WidgetButton } from "@/components/widget-button/widget-button.tsx"
import { useGetHiveScaleByIdQuery } from "@/features/bees/api/hive-scales-api.ts"
import { CreateUpdateHiveScaleDrawer } from "@/features/bees/components/hive-scales/create-update-hive-scale-drawer/create-update-hive-scale-drawer.tsx"
import { HiveScaleCard } from "@/features/bees/components/hive-scales/hive-scale-card/hive-scale-card.tsx"
import { type Hive } from "@/features/bees/types/hives.ts"
import WidgetCard from "@/features/home/components/widget-card/widget-card.tsx"
import { HIVE_SCALE_VIEW } from "@/utils/constants/routes.ts"

interface Props {
  hive: Hive
}

export const HiveScaleWidget: React.FC<Props> = ({ hive }) => {
  const { t } = useTranslation()
  const { data: hiveScale, isLoading } = useGetHiveScaleByIdQuery(
    hive.scale ?? skipToken,
  )

  const hiveScalePath = HIVE_SCALE_VIEW.replace(
    ":hiveScaleId",
    hive.scale ?? "",
  )

  if (isLoading || !hiveScale) {
    return (
      <WidgetCard title={t("hiveScale")} navigateTo={hiveScalePath}>
        <CreateUpdateHiveScaleDrawer
          hive={hive.id}
          trigger={<WidgetButton isActive title={t("addHiveScale")} />}
        />
      </WidgetCard>
    )
  }

  return <HiveScaleCard hiveScale={hiveScale} />
}
