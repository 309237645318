import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import i18n from "i18next"
import { apiSlice } from "@/features/api/apiSlice"

type AuthState = {
  token: string | null
  farmId: string | null
}

const initialState: AuthState = {
  token: null,
  farmId: null,
}

export const logout = createAsyncThunk("auth/logout", (_, { dispatch }) => {
  dispatch(apiSlice.util.resetApiState())
  localStorage.clear()
  i18n.changeLanguage("ro")
})

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setToken: (state, action) => {
      state.token = action.payload
    },
    setFarmId: (state, action) => {
      state.farmId = action.payload
    },
    setAuth: (state, action) => {
      state.token = action.payload.token
      state.farmId = action.payload.farmId
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logout.fulfilled, () => initialState)
  },
})

export default authSlice.reducer

export const { setToken, setFarmId, setAuth } = authSlice.actions
