import { format } from "date-fns"
import React, { useState } from "react"
import { type DateInterval } from "react-day-picker"
import { useTranslation } from "react-i18next"
import DateIcon from "@/assets/icons/task/date-icon.tsx"
import Calendar from "@/components/shadcn/calendar/calendar.tsx"
import OverflowMenuItem from "@/components/task-card/overflow-menu-item.tsx"
import RequiredIndicator from "@/features/farm/components/required-indicator/required-indicator.tsx"

interface DatePickerProps {
  value: string
  onChange: (date: string) => void
  isDisabled?: boolean
  dateInterval?: DateInterval
  placeholder?: string
  withFooter?: boolean
  isRequired?: boolean
}

const GenericDatePicker: React.FC<DatePickerProps> = ({
  value,
  onChange,
  isDisabled = false,
  dateInterval,
  placeholder = "Data",
  withFooter = true,
  isRequired,
}) => {
  const { t } = useTranslation()
  const [isOpened, setIsOpened] = useState<boolean>(false)
  const receivedDate = value ? new Date(value) : undefined

  const handleSelect = (date: Date | undefined) => {
    if (date) {
      onChange(format(new Date(date), "yyyy-MM-dd"))
    } else {
      onChange("")
    }
  }

  return (
    <OverflowMenuItem isDisabled={isDisabled}>
      <div
        className={"flex h-[30px] items-center justify-between"}
        onClick={() => setIsOpened((prevState) => !prevState)}
      >
        <div className={"flex w-full items-center gap-[10px]"}>
          <DateIcon />
          <div>
            <p>{t(placeholder)}</p>
            {value && (
              <p className={"text-brown-500"}>{format(value, "dd.MM.yyyy")}</p>
            )}
          </div>
        </div>
        {isRequired && <RequiredIndicator />}
      </div>
      {isOpened && (
        <div>
          <Calendar
            mode={"single"}
            selected={receivedDate}
            defaultMonth={receivedDate}
            onSelect={handleSelect}
            disabled={
              dateInterval
                ? {
                    after: dateInterval.before,
                    before: dateInterval.after,
                  }
                : undefined
            }
            style={{
              margin: "0px",
            }}
          />
          {withFooter && (
            <div className={"box-border flex w-full items-center justify-end"}>
              <button
                className={
                  "w-fit rounded-3xl bg-transparent p-4 text-base text-brown-500"
                }
                onClick={() => setIsOpened(false)}
              >
                Ok
              </button>
            </div>
          )}
        </div>
      )}
    </OverflowMenuItem>
  )
}

export default GenericDatePicker
