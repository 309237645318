import { apiSlice, TagType } from "@/features/api/apiSlice.ts"
import {
  type ParcelEvent,
  type ParcelEventInput,
} from "@/features/events/parcel-events/types/parcel-events.ts"

const PARCEL_EVENTS_BASE_URL = "/parcels-events/"

export const ParcelEventsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllParcelEvents: builder.query<ParcelEvent[], void>({
      query: () => ({
        url: PARCEL_EVENTS_BASE_URL,
      }),
      providesTags: () => [{ type: TagType.ParcelEvents, id: "LIST" }],
    }),
    createParcelEvent: builder.mutation<ParcelEvent, ParcelEventInput>({
      query: (parcelEvent) => {
        return {
          url: PARCEL_EVENTS_BASE_URL,
          method: "POST",
          body: parcelEvent,
        }
      },
      invalidatesTags: [{ type: TagType.ParcelEvents, id: "LIST" }],
    }),
    getParcelEventById: builder.query<ParcelEvent, string>({
      query: (id) => ({
        url: `${PARCEL_EVENTS_BASE_URL}${id}/`,
      }),
      providesTags: (_result, _error, id) => [
        { type: TagType.ParcelEvents, id },
      ],
    }),
    updateParcelEvent: builder.mutation<
      ParcelEvent,
      { id: ParcelEvent["id"] } & Partial<ParcelEvent>
    >({
      query: ({ id, ...patch }) => {
        return {
          url: `${PARCEL_EVENTS_BASE_URL}${id}/`,
          method: "PATCH",
          body: patch,
        }
      },
      invalidatesTags: (_result, _error, { id }) => [
        { type: TagType.ParcelEvents, id },
        { type: TagType.ParcelEvents, id: "LIST" },
      ],
    }),
    deleteParcelEvent: builder.mutation<void, Partial<ParcelEvent>>({
      query: (parcelEvent) => {
        return {
          url: `${PARCEL_EVENTS_BASE_URL}${parcelEvent.id}/`,
          method: "DELETE",
        }
      },
      invalidatesTags: [{ type: TagType.ParcelEvents, id: "LIST" }],
    }),
  }),
})

export const {
  useGetAllParcelEventsQuery,
  useCreateParcelEventMutation,
  useGetParcelEventByIdQuery,
  useUpdateParcelEventMutation,
  useDeleteParcelEventMutation,
} = ParcelEventsApi
