import { useMemo } from "react"

interface LocationData {
  from_location?: string
  to_location?: string
  from_position?: string
  to_position?: string
}

interface EventWithLocation {
  event_data?: LocationData
}

interface UseGetEventsLocationOptions<T extends EventWithLocation> {
  events?: T[]
  paths?: {
    fromLocation?: string[]
    toLocation?: string[]
    fromPosition?: string[]
    toPosition?: string[]
  }
}

/**
 * Hook to extract unique location and position sets from events
 * @param options Configuration object containing events and optional paths to location/position parcels
 * @returns Object containing sets of from/to locations and positions
 */
const useGetEventsLocation = <T extends EventWithLocation>({
  events,
  paths = {
    fromLocation: ["event_data", "from_location"],
    toLocation: ["event_data", "to_location"],
    fromPosition: ["event_data", "from_position"],
    toPosition: ["event_data", "to_position"],
  },
}: UseGetEventsLocationOptions<T>) => {
  const { fromLocation, toLocation, fromPosition, toPosition } = useMemo(() => {
    const fromLocation = new Set<string>()
    const toLocation = new Set<string>()
    const fromPosition = new Set<string>()
    const toPosition = new Set<string>()

    const getNestedValue = (obj: any, path?: string[]) => {
      if (!path) return undefined
      return path.reduce((acc, key) => acc?.[key], obj)
    }

    events?.forEach((event) => {
      const fromLoc = getNestedValue(event, paths.fromLocation)
      const toLoc = getNestedValue(event, paths.toLocation)
      const fromPos = getNestedValue(event, paths.fromPosition)
      const toPos = getNestedValue(event, paths.toPosition)

      if (fromLoc) fromLocation.add(fromLoc)
      if (toLoc) toLocation.add(toLoc)
      if (fromPos) fromPosition.add(fromPos)
      if (toPos) toPosition.add(toPos)
    })

    return {
      fromLocation,
      toLocation,
      fromPosition,
      toPosition,
    }
  }, [events, paths])

  return {
    fromLocation,
    toLocation,
    fromPosition,
    toPosition,
  }
}

export default useGetEventsLocation
