export const BIRDS_GROUPS_CONSTS = {
  BIRDS_GROUP_EVENT_ID: "birdsGroupEventId",
  BIRDS_GROUP_ID: "birdsGroupId",
} as const

export const BIRDS_GROUPS_EVENTS_PARENTS_ENUM = {
  TREATMENT: "Health",
  MEASUREMENT: "Measurement",
  MOVEMENT: "Movement",
  OTHER: "Other",
} as const

export const BIRDS_GROUPS_MEASUREMENT_EVENTS_ENUM = {
  ADD_HEADS: "AddHeads",
  REMOVE_HEADS: "RemoveHeads",
  EGGS_COUNTING: "EggsCounting",
} as const

export const BIRDS_GROUPS_MOVEMENT_EVENTS_ENUM = {
  TRANSFER: "Transfer",
} as const

export const BIRDS_GROUPS_TREATMENT_EVENTS_ENUM = {
  MEDICATION_ADMINISTRATION: "MedicationAdministration",
  HEALTH_CHECK: "HealthCheck",
} as const
